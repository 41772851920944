export const calculateTextWidth = (text: string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (context) {
    context.font = '700 14px Montserrat';

    return context.measureText(text).width;
  } else {
    return 0;
  }
};
