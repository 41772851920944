import { useParams } from 'react-router-dom';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { deleteGroupTerminalPermissions } from 'api/permissions/permissions';
import { useSelectedGroupTerminalPermission } from 'hooks/useSelectedGroupTerminalPermission/useSelectedGroupTerminalPermission';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteGroupTerminalPermission } from './DeleteGroupTerminalPermission';
import { DeleteGroupTerminalPermissionContainerProps } from './DeleteGroupTerminalPermission.types';

export const DeleteGroupTerminalPermissionContainer = ({
  selectedPermission,
}: DeleteGroupTerminalPermissionContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const groupId = id || '';
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { setSelectedGroupTerminalPermission } = useSelectedGroupTerminalPermission();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteGroupTerminalPermissions, isLoading } = useMutation(deleteGroupTerminalPermissions, {
    onSuccess: () => {
      setSelectedGroupTerminalPermission(undefined);
      queryClient.invalidateQueries([QueryKeyEnum.GROUP_TERMINALS_PERMISSIONS_SERVERS]);
      queryClient.invalidateQueries([QueryKeyEnum.GROUP_TERMINALS_PERMISSIONS]);
      toast.dark(formatMessage({ id: AppMessages['group.reader.permission.delete.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteGroupTerminalPermissions({
      permissionId: selectedPermission.id,
      groupId,
    });
  };

  return (
    <DeleteGroupTerminalPermission
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
