export const getIsCursorInsideKeyword = (cursorPosition: number, value: string, keywordNames: string[]): boolean => {
  return keywordNames.some((keyword) => {
    let index = value.indexOf(keyword);

    while (index !== -1) {
      const start = index;
      const end = index + keyword.length;

      if (cursorPosition > start && cursorPosition < end) {
        return true;
      }

      index = value.indexOf(keyword, index + 1);
    }

    return false;
  });
};
