import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedGlc } from 'hooks/useSelectedGlc/useSelectedGlc';
import { useMutation } from 'hooks/useMutation/useMutation';
import { deleteGlcTerminals } from 'api/glc/glc';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteMultiGlcTerminals } from './DeleteMultiGlcTerminals';
import { DeleteMultiGlcTerminalsContainerProps } from './DeleteMultiGlcTerminals.types';

export const DeleteMultiGlcTerminalsContainer = ({
  checkedGlcTerminals,
  setCheckedGlcTerminals,
}: DeleteMultiGlcTerminalsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedGlc, setSelectedGlc } = useSelectedGlc();
  const { id } = useParams<{ id: string }>();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteGlcTerminals, isLoading } = useMutation(deleteGlcTerminals, {
    onSuccess: (_, { body }) => {
      if (selectedGlc && checkedGlcTerminals.some(({ id }) => id === selectedGlc?.id)) {
        setSelectedGlc(undefined);
      }

      body.length > 1
        ? toast.dark(formatMessage({ id: AppMessages['glc.terminals.delete.success.multi'] }))
        : toast.dark(formatMessage({ id: AppMessages['glc.terminals.delete.success'] }));

      queryClient.invalidateQueries([QueryKeyEnum.GLC_TERMINALS]);
      queryClient.invalidateQueries([QueryKeyEnum.TERMINALS]);
      setCheckedGlcTerminals([]);
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteGlcTerminals({ id: id || '', body: checkedGlcTerminals.map(({ id }) => id) });
  };

  return (
    <DeleteMultiGlcTerminals
      checkedGlcTerminals={checkedGlcTerminals}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
