import { APIError, ClientError, useAuth as useAuthBase } from 'shared-ui';

import { User } from 'api/users/users.types';
import { LoginBody, LoginErrorResponse } from 'api/auth/auth.types';

export const useAuth = () => {
  const context = useAuthBase<User, LoginBody, ClientError<APIError<LoginErrorResponse>>>();

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthContextController');
  }

  return context;
};
