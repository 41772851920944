import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { MAX_ANNOUNCEMENT_LENGTH } from 'core/global.variables';
import { countOccurrences } from 'helpers/countOccurrences';
import { removeOccurrences } from 'helpers/removeOccurrences';
import { announcementKeywords } from '../AnnouncementsList.types';

export const useAddAnnouncementValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    nazwa: yup
      .string()
      .max(60, formatMessage({ id: AppMessages['announcements.field.validation.name.max'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    tkd: yup
      .string()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      .typeError(formatMessage({ id: AppMessages['common.validation.required'] })),
    czasKomunikatu: yup
      .number()
      .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      .typeError(formatMessage({ id: AppMessages['common.validation.required'] }))
      .min(1, formatMessage({ id: AppMessages['announcements.field.validation.duration'] }))
      .max(60, formatMessage({ id: AppMessages['announcements.field.validation.duration'] })),
    mod: yup
      .string()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      .typeError(formatMessage({ id: AppMessages['common.validation.required'] })),
    tryb: yup.array().min(1),
    potwierdzenie: yup
      .string()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      .typeError(formatMessage({ id: AppMessages['common.validation.required'] })),
    komunikat: yup
      .string()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      .typeError(formatMessage({ id: AppMessages['common.validation.required'] }))
      .test(
        'check if tags are used once',
        formatMessage({ id: AppMessages['announcement.add.announcement.validation.multiple'] }),
        (val) => {
          if (!val) return true;

          const isKeywordUsedMoreThanOnce = announcementKeywords.some(({ name }) => {
            return countOccurrences(val, name) > 1;
          });

          return !isKeywordUsedMoreThanOnce;
        },
      )
      .test(
        'check length of announcement',
        formatMessage({ id: AppMessages['announcement.add.announcement.validation.length'] }),
        (val) => {
          if (!val) return true;

          let updatedValue = val;

          announcementKeywords.forEach(({ name }) => (updatedValue = removeOccurrences(updatedValue, name)));

          return updatedValue.length < MAX_ANNOUNCEMENT_LENGTH;
        },
      ),
    ids: yup.array(),
  });

  return { schema };
};
