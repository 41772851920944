import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { formatISO } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { useErrorHandler, APIError, ClientError, useQueryClient, useLoaderToast } from 'shared-ui';

import { addOwner } from 'api/owners/owners';
import { AddOwnerBody } from 'api/owners/owners.types';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useMutation } from 'hooks/useMutation/useMutation';
import { AppMessages } from 'i18n/messages';

import { useAddOwnerManuallyValidation } from './AddOwnerManually.validation';
import { AddOwnerManuallyContainerProps } from './AddOwnerManually.types';
import { AddOwnerManually } from './AddOwnerManually';

export const AddOwnerManuallyContainer = ({
  isModalOpen,
  onModalClose,
  onClickBackButton,
}: AddOwnerManuallyContainerProps) => {
  const { formatMessage } = useLocale();
  const { handleError } = useErrorHandler();
  const queryClient = useQueryClient();

  const { load: loadAddOwner, update: updateLoadAddOwner, close: closeLoadAddOwner } = useLoaderToast('addOwner');

  const { mutate: onAddOwner, isLoading: isLoadingAddOwner } = useMutation(addOwner, {
    onMutate: () => loadAddOwner(formatMessage({ id: AppMessages['owners.addOwner.loader'] })),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.OWNERS]);
      onModalClose();
      updateLoadAddOwner(formatMessage({ id: AppMessages['owners.addOwner.successMessage'] }));
    },
    onError: (error: ClientError<APIError>) => {
      const errorFields = error.response?.data.fields;

      const isRegistrationNumberExistsError = errorFields?.find(
        ({ type, field }) => type === 'exists' && field === 'nrEwidencyjny',
      );
      const isExternalRegistrationNumberExistsError = errorFields?.find(
        ({ type, field }) => type === 'exists' && field === 'nrEwidencyjnyZewnetrzny',
      );

      if (isRegistrationNumberExistsError) {
        closeLoadAddOwner();
        return methods.setError('nrEwidencyjny', {
          message: formatMessage({ id: AppMessages['owners.addOwner.field.registrationNumber.validation.exist'] }),
        });
      }
      if (isExternalRegistrationNumberExistsError) {
        closeLoadAddOwner();
        return methods.setError('nrEwidencyjnyZewnetrzny', {
          message: formatMessage({
            id: AppMessages['owners.addOwner.field.externalRegistrationNumber.validation.exist'],
          }),
        });
      }

      handleError({ error, updateToastCallback: updateLoadAddOwner });
    },
  });

  const minDate = useMemo(() => new Date(), []);
  const { schema } = useAddOwnerManuallyValidation(minDate);
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const onSubmit = (body: AddOwnerBody) => {
    onAddOwner({
      ...body,
      rcp: !!Number(body.rcp),
      dataUrodzenia: body.dataUrodzenia ? formatISO(new Date(body.dataUrodzenia)) : undefined,
    });
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...methods}>
      <AddOwnerManually
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onSubmit={onSubmit}
        isLoadingAddOwner={isLoadingAddOwner}
        onClickBackButton={onClickBackButton}
      />
    </FormProvider>
  );
};
