import { useLocale } from 'hooks/useLocale/useLocale';
import { Table } from 'ui/table/Table';
import { AppMessages } from 'i18n/messages';

import { OwnerEffectiveTerminalsPermissionsProps } from './OwnerEffectiveTerminalsPermissions.types';
import { OwnerEffectiveTerminalsPermissionsTableHeader } from './ownerEffectiveTerminalsPermissionsTableHeader/OwnerEffectivePermissionsTableHeader';
import { OwnerEffectiveTerminalsPermissionsTableRow } from './ownerEffectiveTerminalsPermissionsTableRow/OwnerEffectiveTerminalsPermissionsTableRow';

export const OwnerEffectiveTerminalsPermissions = ({ permissions }: OwnerEffectiveTerminalsPermissionsProps) => {
  const { formatMessage } = useLocale();

  return (
    <Table
      gridTemplateColumns="0.7fr 1fr 1fr 1fr 1fr"
      data={permissions}
      nothingToShowText={formatMessage({ id: AppMessages['permissions.server.nothingToShow'] })}
      customTableHeight={() => undefined}
      renderHeader={() => <OwnerEffectiveTerminalsPermissionsTableHeader />}
      renderRow={(permission) => <OwnerEffectiveTerminalsPermissionsTableRow permission={permission} />}
    />
  );
};
