import { Controller, useFormContext } from 'react-hook-form';
import { Datepicker } from 'shared-ui';

import { getEndOfToday } from 'helpers/getEndOfToday';
import { getNow } from 'helpers/getNow';
import { handleValidFromDate } from 'helpers/handleValidFromDate';
import { handleValidToDate } from 'helpers/handleValidToDate';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

import * as Styled from './ExpiryDateFields.styles';
import { ExpiryDateFieldsProps, ExpiryDateFormFields } from './ExpiryDateFields.types';

export const ExpiryDateFields = ({ isBlocked }: ExpiryDateFieldsProps) => {
  const minDate = new Date();
  const { formatMessage } = useLocale();

  const {
    control,
    formState: { errors },
    watch,
    trigger,
  } = useFormContext<ExpiryDateFormFields>();

  return (
    <div>
      <Controller
        name="waznoscOd"
        control={control}
        render={({ field }) => (
          <Datepicker
            {...field}
            required={!isBlocked}
            label={formatMessage({ id: AppMessages['card.assign.date.start'] })}
            minDate={minDate}
            withCalendarIcon
            disabled={isBlocked}
            withDatepickerPopover={!isBlocked}
            isClickable={!isBlocked}
            errorMessage={errors?.waznoscOd?.message}
            popoverOrigin={{
              vertical: -80,
              horizontal: 60,
            }}
            customSetDateFormat={handleValidFromDate}
            customDateFormatOnOpen={getNow}
          />
        )}
      />

      <Controller
        name="waznoscDo"
        control={control}
        render={({ field }) => (
          <Datepicker
            {...field}
            onChange={(date) => {
              field.onChange(date);
              trigger('waznoscOd');
            }}
            label={formatMessage({ id: AppMessages['card.assign.date.end'] })}
            minDate={watch('waznoscOd') || undefined}
            withCalendarIcon
            disabled={isBlocked}
            withDatepickerPopover={!isBlocked}
            isClickable={!isBlocked}
            errorMessage={errors?.waznoscDo?.message}
            popoverOrigin={{
              vertical: -80,
              horizontal: 60,
            }}
            customSetDateFormat={handleValidToDate}
            customDateFormatOnOpen={getEndOfToday}
          />
        )}
      />
      {!isBlocked && (
        <Styled.DateInfo>
          {formatMessage({
            id: AppMessages['card.assign.date.info'],
          })}
        </Styled.DateInfo>
      )}
    </div>
  );
};
