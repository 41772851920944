import { MutationFn, UseQueryFn } from 'shared-ui';

import { Organization, AddOrganizationBody, UpdateOrganizationBody } from './organizations.types';

export const fetchOrganizations: UseQueryFn<null, Organization[]> = () => {
  return {
    endpoint: `/organizacja`,
  };
};

export const addOrganization: MutationFn<AddOrganizationBody, Pick<Organization, 'id'>> = (body) => {
  return {
    endpoint: '/organizacja',
    method: 'POST',
    body,
  };
};

export const updateOrganization: MutationFn<
  {
    id: string;
    body: UpdateOrganizationBody;
  },
  Pick<Organization, 'id'>
> = ({ id, body }) => {
  return {
    endpoint: `/organizacja/${id}`,
    method: 'PUT',
    body,
  };
};

export const deleteOrganization: MutationFn<string, void> = (id) => {
  return {
    endpoint: `/organizacja/${id}`,
    method: 'DELETE',
  };
};

export const deleteOrganizationForce: MutationFn<string, void> = (id) => {
  return {
    endpoint: `/organizacja/${id}/force`,
    method: 'DELETE',
  };
};
