import { UseQueryFn, MutationFn } from 'shared-ui';

import { PaginatedResponse } from 'core/global.types';
import { Owner } from 'api/owners/owners.types';
import { Terminal } from 'api/terminals/terminals.types';
import { Server } from 'api/servers/servers.types';

import {
  AddWantedBody,
  AddWantedTerminalsBody,
  DeleteWantedTerminalsBody,
  ModifyWantedInformationBody,
  ModifyWantedInformationMultiBody,
  UpdateWantedBody,
  WantedHistory,
} from './wanted.types';

export const fetchWanted: UseQueryFn<unknown, PaginatedResponse<Owner>> = () => {
  return {
    endpoint: `/poszukiwany`,
  };
};

export const fetchWantedHistory: UseQueryFn<void, PaginatedResponse<WantedHistory>> = () => {
  return {
    endpoint: `/poszukiwany/historia`,
  };
};

export const addWanted: MutationFn<AddWantedBody, string> = (body) => {
  return {
    endpoint: '/poszukiwany',
    method: 'POST',
    body,
  };
};

export const modifyWantedInformation: MutationFn<{ id: string; body: ModifyWantedInformationBody }, void> = ({
  id,
  body,
}) => {
  return {
    endpoint: `/poszukiwany/informacja/${id}`,
    method: 'POST',
    body,
  };
};

export const deleteWanted: MutationFn<string, void> = (id) => {
  return {
    endpoint: `/poszukiwany/${id}`,
    method: 'DELETE',
  };
};

export const modifyWantedInformationMulti: MutationFn<{ body: ModifyWantedInformationMultiBody }, void> = ({
  body,
}) => {
  return {
    endpoint: '/batch/poszukiwany/informacja',
    method: 'POST',
    body,
  };
};

export const deleteWantedMulti: MutationFn<{ body: string[] }, null> = ({ body }) => {
  return {
    endpoint: '/batch/poszukiwany',
    method: 'DELETE',
    body,
  };
};

export const fetchServersForWanted: UseQueryFn<string, PaginatedResponse<Server>> = (id) => {
  return {
    endpoint: `/poszukiwany/${id}/rcp`,
  };
};

export const fetchWantedTerminals: UseQueryFn<
  {
    wantedId: string;
    serverId: string;
  },
  PaginatedResponse<Terminal>
> = (body) => {
  return {
    endpoint: `/poszukiwany/${body?.wantedId}/rcp/${body?.serverId}`,
  };
};

export const addWantedTerminals: MutationFn<AddWantedTerminalsBody, void> = ({ id, body }) => {
  return {
    endpoint: `batch/poszukiwany/${id}/tkd`,
    method: 'POST',
    body,
  };
};

export const deleteWantedTerminals: MutationFn<DeleteWantedTerminalsBody, void> = ({ id, body }) => {
  return {
    endpoint: `batch/poszukiwany/${id}/tkd`,
    method: 'DELETE',
    body,
  };
};

export const updateMultiWanted: MutationFn<UpdateWantedBody, string> = (body) => {
  return {
    endpoint: '/batch/poszukiwany',
    method: 'PUT',
    body,
  };
};
