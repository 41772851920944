import { useState } from 'react';
import { Typography } from '@mui/material';
import { PageLayout, Pagination, ShowUp, TopBar, DetailsDrawer } from 'shared-ui';

import { ServerStatusEnum } from 'api/servers/servers.eum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useSelectedServer } from 'hooks/useSelectedServer/useSelectedServer';
import { useSelectedTerminal } from 'hooks/useSelectedTerminal/useSelectedTerminal';
import { AppMessages } from 'i18n/messages';
import { ExpandableList } from 'ui/expandableList/ExpandableList';
import { ServerStatus } from 'ui/serverStatus/ServerStatus';
import { findServerToExpand } from 'helpers/findServerToExpand';
import { Server } from 'api/servers/servers.types';

import { TerminalsContainer } from './terminals/TerminalsContainer';
import { ServerDetails } from './serverDetails/ServerDetails';
import { TerminalDetailsContainer } from './terminalDetails/TerminalDetailsContainer';
import { AddServerContainer } from './addServer/AddServerContainer';
import { ServerDetailsEditContainer } from './serverDetailsEdit/ServerDetailsEditContainer';
import { DevicesProps } from './Devices.types';
import { DevicesMultiActions } from './devicesMultiActions/DevicesMultiActions';
import { ConnectServerActionsContainer } from './connectServerActions/ConnectServerActionsContainer';

export const Devices = ({
  servers,
  isLoading,
  checkedTerminals,
  setCheckedTerminals,
  terminalsCount,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setTerminalsCount,
}: DevicesProps) => {
  const { formatMessage } = useLocale();
  const { selectedServer, setSelectedServer } = useSelectedServer();
  const { selectedTerminal, setSelectedTerminal } = useSelectedTerminal();

  const [expandedServer, setExpandedServer] = useState<Server | undefined>(undefined);

  const terminalActionDisabled =
    servers &&
    servers.some(
      (server) =>
        server.id === expandedServer?.id &&
        (server.status === ServerStatusEnum.ODLACZONY || server.status === ServerStatusEnum.AWARIA),
    );

  const renderCorrectDetails = () => {
    const server = servers && selectedServer ? servers.find((server) => server.id === selectedServer.id) : undefined;

    if (server && server.status !== ServerStatusEnum.ODLACZONY) {
      return <ServerDetails selectedServer={server} />;
    }

    if (server && server.status === ServerStatusEnum.ODLACZONY) {
      return <ServerDetailsEditContainer key={server.id} selectedServer={server} servers={servers} />;
    }

    if (selectedTerminal) {
      return (
        <TerminalDetailsContainer
          selectedTerminal={selectedTerminal}
          setSelectedTerminal={setSelectedTerminal}
          terminalActionDisabled={!!terminalActionDisabled}
        />
      );
    }
  };

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['devices.title'] })}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="auto 1fr">
            <TopBar.Header isCentered isActive>
              {formatMessage({ id: AppMessages['devices.subtitle'] })}
            </TopBar.Header>

            <AddServerContainer servers={servers} />
          </TopBar>

          <ShowUp show={checkedTerminals.length > 1}>
            <DevicesMultiActions
              checkedTerminals={checkedTerminals}
              setCheckedTerminals={setCheckedTerminals}
              terminalActionDisabled={!!terminalActionDisabled}
            />
          </ShowUp>

          <Pagination
            page={page}
            setPage={setPage}
            count={terminalsCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />

          <ExpandableList
            data={servers || []}
            isLoading={isLoading}
            nothingToShowText={formatMessage({ id: AppMessages['devices.nothingToShow'] })}
            title={(server) => (
              <>
                <Typography variant="h6">{server.nazwa}</Typography>
                <ServerStatus status={server.status} />
              </>
            )}
            addonEnd={(server) => <ConnectServerActionsContainer server={server} />}
            selectedItem={selectedServer}
            toggleSelectedItem={(server) => {
              setSelectedServer(server);
              setSelectedTerminal(undefined);
            }}
            initiallyExpandedItem={findServerToExpand(servers || [])}
            toggleItemExpansion={(expandedServer) => {
              setExpandedServer(expandedServer);
              setSelectedTerminal(undefined);
              setCheckedTerminals([]);
              if (!expandedServer) setTerminalsCount(0);
            }}
            renderExpandableItemBody={(server, expandedServer) => (
              <TerminalsContainer
                serverStatus={server.status}
                expandedServer={expandedServer}
                checkedTerminals={checkedTerminals}
                setCheckedTerminals={setCheckedTerminals}
                setTerminalsCount={setTerminalsCount}
              />
            )}
          />
        </>
      )}
      renderDetails={() => (
        <DetailsDrawer
          isOpen={!!selectedServer || !!selectedTerminal}
          header={formatMessage({ id: AppMessages['devices.details.title'] })}
        >
          {renderCorrectDetails()}
        </DetailsDrawer>
      )}
    />
  );
};
