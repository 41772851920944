import { fetchPositions } from 'api/positions/positions';
import { OwnersQueryKeysEnum, PositionNameSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreOwnersQuery } from 'hooks/useLocaleStoreOwnersQuery/useLocaleStoreOwnersQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { Option } from '../HeaderSearchFilterItem.types';

export const usePositions = (searchQuery: string, isEnabled: boolean) => {
  const { data, isLoading } = useQuery(
    [QueryKeyEnum.POSITIONS, searchQuery],
    () => fetchPositions({ query: searchQuery, limit: 1000 }),
    { args: { sort: [PositionNameSortEnum.ASC] }, enabled: isEnabled },
  );

  const { ownersQuery, setOwnersQuery } = useLocaleStoreOwnersQuery();

  return {
    options: data || [],
    isLoading,
    selectedOptions: ownersQuery.stanowisko,
    setSearch: (options: Option[]) => setOwnersQuery({ [OwnersQueryKeysEnum.STANOWISKO]: options }),
    clearSearch: () =>
      setOwnersQuery({
        [OwnersQueryKeysEnum.STANOWISKO]: [],
      }),
    isOptionSelected: !!ownersQuery?.stanowisko?.length,
  };
};
