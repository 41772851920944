import { useState } from 'react';

import { Organization } from 'api/organizations/organizations.types';
import { SelectedOrganizationContext } from '../selectedOrganizationContext/SelectedOrganizationContext';

import { SelectedOrganizationContextControllerProps } from './SelectedOrganizationContextController.types';

export const SelectedOrganizationContextController = ({ children }: SelectedOrganizationContextControllerProps) => {
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | undefined>(undefined);

  return (
    <SelectedOrganizationContext.Provider value={{ selectedOrganization, setSelectedOrganization }}>
      {children}
    </SelectedOrganizationContext.Provider>
  );
};
