import { AuthContextController } from 'shared-ui';
import { PropsWithChildren } from 'react';

import { onLogin } from 'api/auth/auth';
import { fetchLoggedUserDetails } from 'api/users/users';
import { UserTypeEnum } from 'api/users/users.enum';
import { QueryKeyEnum } from 'core/global.enum';

export const AuthProvider = ({ children }: PropsWithChildren) => {
  return (
    <AuthContextController
      getIsAdmin={(user) => user?.typ === UserTypeEnum.ADMINISTRATOR}
      onLogin={onLogin}
      fetchUser={fetchLoggedUserDetails}
      fetchUserKey={QueryKeyEnum.LOGGED_USER_DETAILS}
    >
      {children}
    </AuthContextController>
  );
};
