import { Option } from 'shared-ui';

import { CardNumberSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { searchCard } from 'api/cards/cards';
import { useQuery } from 'hooks/useQuery/useQuery';
import * as Styled from '../TableSearch.styles';

import { useSearchByOwners } from './useSearchByOwners';

export const useSearchByCardsAndOwners = (
  optionsQuery: string,
  isEnabled: boolean,
  setTableSearchQuery?: (search: string) => void,
) => {
  const { formatMessage } = useLocale();
  const isSearchByCardNumber = !!Number(optionsQuery);

  const { data: cardsResponse, isInitialLoading } = useQuery([QueryKeyEnum.CARD_SEARCH, optionsQuery], searchCard, {
    args: { query: optionsQuery, offset: 0, limit: 100, sort: [CardNumberSortEnum.ASC] },
    enabled: isEnabled && isSearchByCardNumber,
  });
  const cards = cardsResponse?.data || [];

  const {
    createOptions: createOwnerOptions,
    createOptionLabel: createOwnerOptionLabel,
    setTableSearchQuery: setTableSearchQueryOwners,
  } = useSearchByOwners(optionsQuery, isEnabled && !isSearchByCardNumber, false, false, setTableSearchQuery, true);

  const createOptions = () => {
    return isSearchByCardNumber
      ? cards.map((card) => ({
          label: card.numerKarty,
          value: card.numerKarty,
        }))
      : createOwnerOptions();
  };

  const createOptionLabel = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <li {...optionProps}>
        <Styled.OptionNumber>{formatMessage({ id: AppMessages['tableSearch.card.number'] })} </Styled.OptionNumber>

        <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
      </li>
    );
  };

  return {
    isLoading: isInitialLoading,
    createOptions,
    createOptionLabel: isSearchByCardNumber ? createOptionLabel : createOwnerOptionLabel,
    ...(!isSearchByCardNumber && {
      setTableSearchQuery: setTableSearchQueryOwners,
    }),
  };
};
