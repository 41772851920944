import { useEffect, useState } from 'react';
import { useQueryClient, ClientError, useErrorHandler, APIError, useLoaderToast } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { addGroupGlcPermissions } from 'api/permissions/permissions';
import { fetchServers } from 'api/servers/servers';
import { fetchGlc } from 'api/glc/glc';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useMutation } from 'hooks/useMutation/useMutation';
import { DEFAULT_PERMISSIONS } from 'core/global.variables';
import { useResetPermissionsFormValues } from 'hooks/useResetPermissionsFormValues/useResetPermissionsFormValues';
import { GlcNumberSortEnum, GlcQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useModalState } from 'hooks/useModalState/useModalState';

import { useAddGroupGlcPermissionValidation } from './AddGroupGlcPermission.validation';
import { AddGroupGlcPermissionFormBody, AddGroupGlcPermissionContainerProps } from './AddGroupGlcPermission.types';
import { AddGroupGlcPermission } from './AddGroupGlcPermission';

export const AddGroupGlcPermissionContainer = ({ groupId }: AddGroupGlcPermissionContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { load: loadAddGroupGlcPermissions, update: updateAddGroupGlcPermissions } =
    useLoaderToast('addGroupGlcPermissions');
  const [selectedServerId, setSelectedServerId] = useState('');
  const { schema } = useAddGroupGlcPermissionValidation();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { handleError } = useErrorHandler();

  const methods = useForm<AddGroupGlcPermissionFormBody>({
    defaultValues: {
      ...DEFAULT_PERMISSIONS,
      idGlcs: [],
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { data: serversResponse, isLoading: isLoadingServers } = useQuery(
    [QueryKeyEnum.SERVERS, groupId],
    () => fetchServers(groupId),
    {
      onSuccess: (serversResponse) => {
        if (serversResponse.data.length) {
          setSelectedServerId(serversResponse.data[0].id);
        }
      },
    },
  );

  const { data: glcResponse, isInitialLoading: isLoadingGlcGroups } = useQuery([QueryKeyEnum.GLC], fetchGlc, {
    args: {
      sort: [GlcNumberSortEnum.ASC],
      withoutDirectGroupPermission: true,
      [GlcQueryKeysEnum.ARCHIVED]: false,
      [GlcQueryKeysEnum.READER]: true,
    },
    enabled: !!selectedServerId,
  });

  const { mutate: onAddGroupGlcPermissions, isLoading } = useMutation(addGroupGlcPermissions, {
    onMutate: ({ body }) => {
      const message =
        body.idGlcs.length > 1
          ? 'group.reader.permissions.addPermissionMulti.loader'
          : 'group.reader.permissions.addPermission.loader';

      loadAddGroupGlcPermissions(formatMessage({ id: AppMessages[message] }));
    },
    onSuccess: (_, { body }) => {
      queryClient.invalidateQueries([QueryKeyEnum.GROUP_GLC_PERMISSIONS]);
      queryClient.invalidateQueries([QueryKeyEnum.GROUP_GLC_PERMISSIONS_SERVERS]);

      const message =
        body.idGlcs.length > 1
          ? 'group.reader.permissions.addPermissionMulti.success'
          : 'group.reader.permissions.addPermission.success';

      updateAddGroupGlcPermissions(formatMessage({ id: AppMessages[message] }));
      onModalClose();
    },
    onError: (error: ClientError<APIError>) => {
      handleError({ error, updateToastCallback: updateAddGroupGlcPermissions });
    },
  });

  const onSubmit = (body: AddGroupGlcPermissionFormBody) => {
    onAddGroupGlcPermissions({
      id: groupId,
      body: {
        ...body,
        idGlcs: body.idGlcs.map(({ value }) => `${value}`),
      },
    });
  };

  useResetPermissionsFormValues({ selectedValues: methods.watch('idGlcs'), setFormValue: methods.setValue });

  useEffect(() => {
    methods.reset();
  }, [isModalOpen, selectedServerId]);

  return (
    <FormProvider {...methods}>
      <AddGroupGlcPermission
        onSubmit={onSubmit}
        isModalOpen={isModalOpen}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
        isLoading={isLoading}
        servers={serversResponse?.data || []}
        selectedServerId={selectedServerId}
        setSelectedServerId={setSelectedServerId}
        isLoadingGlcGroups={isLoadingGlcGroups}
        glcGroups={glcResponse?.data || []}
        isLoadingServers={isLoadingServers}
      />
    </FormProvider>
  );
};
