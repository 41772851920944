import { useContext } from 'react';

import { SelectedOrganizationContext } from 'context/selectedOrganization/selectedOrganizationContext/SelectedOrganizationContext';

export const useSelectedOrganization = () => {
  const context = useContext(SelectedOrganizationContext);

  if (context === undefined) {
    throw new Error(
      'SelectedOrganizationContext is unavailable, make sure you are using SelectedOrganizationContextController',
    );
  }

  return context;
};
