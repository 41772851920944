import { Tooltip } from 'shared-ui';

import { ServerStatusEnum } from 'api/servers/servers.eum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { ConnectServerActionsProps } from './ConnectServerActions.types';
import * as Styled from './ConnectServerActions.styles';

export const ConnectServerActions = ({ server, onConnect, onDisconnect }: ConnectServerActionsProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      {server.status === ServerStatusEnum.ODLACZONY ? (
        <Tooltip
          title={formatMessage({
            id: AppMessages['devices.tooltip.connect'],
          })}
        >
          <Styled.DisconnectButtonIcon onClick={() => onConnect(server.id)} />
        </Tooltip>
      ) : (
        <Tooltip
          title={formatMessage({
            id: AppMessages['devices.tooltip.disconnect'],
          })}
        >
          <Styled.ConnectButtonIcon onClick={() => onDisconnect(server.id)} />
        </Tooltip>
      )}
    </>
  );
};
