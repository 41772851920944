import { PageLayout, TopBar, DetailsDrawer } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';
import { useSelectedUser } from 'hooks/useSelectedUser/useSelectedUser';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';

import { AddUserContainer } from './addUser/AddUserContainer';
import { UsersTableHeader } from './usersTableHeader/UsersTableHeader';
import { UsersTableRow } from './usersTableRow/UsersTableRow';
import { UsersProps } from './Users.types';
import { UserDetailsContainer } from './userDetails/UserDetailsContainer';

export const Users = ({
  users,
  isLoadingUsers,
  page,
  setPage,
  usersCount,
  rowsPerPage,
  setRowsPerPage,
  setTableSearchQuery,
}: UsersProps) => {
  const { formatMessage } = useLocale();

  const { selectedUser, setSelectedUser } = useSelectedUser();

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['users.title'] })}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="1fr auto">
            <TopBar.Search>
              <TableSearchContainer
                searchIconTestId="page-search"
                inputPlaceholder={formatMessage({ id: AppMessages['users.search'] })}
                searchBy="users"
                setTableSearchQuery={setTableSearchQuery}
              />
            </TopBar.Search>
            <TopBar.ButtonsWrapper>
              <AddUserContainer />
            </TopBar.ButtonsWrapper>
          </TopBar>

          <Table
            isLoading={isLoadingUsers}
            pagination={{
              page,
              setPage,
              count: usersCount,
              rowsPerPage,
              setRowsPerPage,
            }}
            gridTemplateColumns="1fr 1fr 1fr 1fr"
            data={users}
            nothingToShowText={formatMessage({ id: AppMessages['users.nothingToShow'] })}
            renderHeader={() => <UsersTableHeader />}
            renderRow={(user) => <UsersTableRow user={user} />}
            onClickRow={(user) => setSelectedUser(selectedUser?.id === user.id ? undefined : user)}
            activeRowId={selectedUser?.id}
          />
        </>
      )}
      renderDetails={() => (
        <DetailsDrawer isOpen={!!selectedUser} header={formatMessage({ id: AppMessages['user.details.title'] })}>
          {selectedUser && <UserDetailsContainer selectedUser={selectedUser} />}
        </DetailsDrawer>
      )}
    />
  );
};
