import { useState, useEffect } from 'react';
import { FileWithPath } from 'react-dropzone';
import { ClientError, APIError, useQueryClient, useLoaderToast } from 'shared-ui';
import { toast } from 'react-toastify';

import { ValidateOwnersImportFileErrorResponse } from 'api/owners/owners.types';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { addOwnersFromFile, validateOwnersImportFile } from 'api/owners/owners';
import { AppMessages } from 'i18n/messages';
import { QueryKeyEnum } from 'core/global.enum';

import { AddOwnerImport } from './AddOwnerImport';
import { AddOwnerImportContainerProps } from './AddOwnerImport.types';

export const AddOwnerImportContainer = ({
  isModalOpen,
  onModalClose,
  onClickBackButton,
}: AddOwnerImportContainerProps) => {
  const [file, setFile] = useState<FileWithPath>();
  const [fileError, setFileError] = useState<string>();
  const [downloadLink, setDownloadLink] = useState('');
  const { formatMessage } = useLocale();
  const { load: loadAddOwnersFromFile, close: closeAddOwnersFromFile } = useLoaderToast('addOwnersFromFile');
  const queryClient = useQueryClient();

  const resetUploadErrors = () => {
    setFileError(undefined);
    setDownloadLink('');
  };

  const handleFileImportErrors = ({ response }: ClientError<APIError<ValidateOwnersImportFileErrorResponse>>) => {
    setFile(undefined);

    if (response?.data.type === 'pkds_limit') {
      setFileError(response?.data?.error);
      return;
    }

    createDownloadLink(response?.data?.message);
  };

  const { mutate: onValidateOwnersFromFile, isLoading: isValidatingFile } = useMutation(validateOwnersImportFile, {
    onMutate: resetUploadErrors,
    onSuccess: resetUploadErrors,
    onError: handleFileImportErrors,
  });

  const { mutate: onAddCardFromFile } = useMutation(addOwnersFromFile, {
    onMutate: () =>
      loadAddOwnersFromFile(formatMessage({ id: AppMessages['owners.addOwner.import.importFile.loader'] })),
    onSuccess: ({ liczbaDoDodania, liczbaDodanych, liczbaDoModyfikacji, liczbaZmodyfikowanych, bledyImportu }) => {
      if (!(liczbaDoDodania === 0 && liczbaDodanych === 0)) {
        toast.dark(
          formatMessage(
            { id: AppMessages['owners.addOwner.import.importFile.successMessage'] },
            {
              addedNumber: liczbaDodanych,
              fromNumber: liczbaDoDodania,
            },
          ),
        );
      }

      if (!(liczbaDoModyfikacji === 0 && liczbaZmodyfikowanych === 0)) {
        toast.dark(
          formatMessage(
            { id: AppMessages['owners.addOwner.import.importFile.modifiedMessage'] },
            {
              modifiedNumber: liczbaZmodyfikowanych,
              fromNumber: liczbaDoModyfikacji,
            },
          ),
        );
      }

      queryClient.invalidateQueries([QueryKeyEnum.OWNERS]);
      if (bledyImportu) {
        createDownloadLink(bledyImportu);
      } else {
        onModalClose();
      }
    },
    onError: handleFileImportErrors,
    onSettled: () => closeAddOwnersFromFile(),
  });

  useEffect(() => {
    file && onValidateOwnersFromFile(file);
  }, [file]);

  const createDownloadLink = (uploadErrors?: string) => {
    if (!uploadErrors) return;

    const data = new Blob([uploadErrors], { type: 'text/plain' });

    if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink);

    setDownloadLink(window.URL.createObjectURL(data));
  };

  return (
    <AddOwnerImport
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onClickBackButton={onClickBackButton}
      fileError={fileError}
      setFileError={setFileError}
      setFile={setFile}
      file={file}
      onAddFile={() => file && onAddCardFromFile({ body: file })}
      isLoadingAddFile={isValidatingFile}
      downloadLink={downloadLink}
    />
  );
};
