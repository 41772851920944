import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Modal } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AddOwnerSteps } from '../AddOwner.enum';

import { AddOwnerPreviewProps } from './AddownerPreview.types';
import * as Styled from './AddOwnerPreview.styles';

export const AddOwnerPreview = ({
  isModalOpen,
  onModalClose,
  setStep,
  choosenStep,
  setChoosenStep,
}: AddOwnerPreviewProps) => {
  const { formatMessage } = useLocale();

  const addOwnerOptionsList = [
    {
      step: AddOwnerSteps.MANUALLY,
      label: formatMessage({ id: AppMessages['owners.addOwner.preview.manually.label'] }),
      description: formatMessage({ id: AppMessages['owners.addOwner.preview.manually.description'] }),
    },
    {
      step: AddOwnerSteps.IMPORT,
      label: formatMessage({ id: AppMessages['owners.addOwner.preview.import.label'] }),
      description: formatMessage({ id: AppMessages['owners.addOwner.preview.import.description'] }),
    },
    {
      step: AddOwnerSteps.IMPORT_WITH_PHOTO,
      label: formatMessage({ id: AppMessages['owners.addOwner.preview.importWithPhoto.label'] }),
      description: formatMessage({ id: AppMessages['owners.addOwner.preview.importWithPhoto.description'] }),
    },
  ];

  return (
    <Modal
      header={formatMessage({ id: AppMessages['owners.addOwner.title'] })}
      submitButtonText={formatMessage({ id: AppMessages['owners.addOwner.preview.nextButton'] })}
      onClose={onModalClose}
      open={isModalOpen}
      size="medium"
      type="form"
      onSubmit={() => setStep(choosenStep)}
    >
      <Styled.Title variant="h5">{formatMessage({ id: AppMessages['owners.addOwner.preview.title'] })}</Styled.Title>

      <FormControl data-testid="add-owner-preview">
        <RadioGroup onChange={(_e, option) => setChoosenStep(option as AddOwnerSteps)} value={choosenStep}>
          {addOwnerOptionsList.map((option) => (
            <Styled.RadioWrapper key={option.step}>
              <FormControlLabel
                value={option.step}
                control={<Radio />}
                label={
                  <Styled.RadioLabelWrapper>
                    <Typography variant="h6">{option.label}</Typography>
                    <Typography>{option.description}</Typography>
                  </Styled.RadioLabelWrapper>
                }
              />
            </Styled.RadioWrapper>
          ))}
        </RadioGroup>
      </FormControl>
    </Modal>
  );
};
