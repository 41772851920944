import { Option } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { fetchUsers } from 'api/users/users';
import { useQuery } from 'hooks/useQuery/useQuery';
import * as Styled from '../TableSearch.styles';

export const useSearchByUsers = (optionsQuery: string, isEnabled: boolean) => {
  const { data: usersResponse, isInitialLoading } = useQuery([QueryKeyEnum.USERS, optionsQuery], fetchUsers, {
    args: { query: optionsQuery, limit: 100 },
    enabled: isEnabled,
  });

  const users = usersResponse?.data || [];

  const createOptions = () => {
    return users.map((user) => ({ value: user.username, label: `${user.imie} ${user.nazwisko}` }));
  };

  const createOptionLabel = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <li {...optionProps} key={`${option.value}-${optionProps.id}`}>
        <Styled.OptionNumber>{option.value}</Styled.OptionNumber>

        <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
      </li>
    );
  };

  return { isLoading: isInitialLoading, createOptions, createOptionLabel };
};
