import { useState } from 'react';

import { GroupPermission } from 'api/permissions/permissions.types';
import { SelectedGroupGlcPermissionContext } from '../selectedGroupGlcPermissionContext/SelectedGroupGlcPermissionContext';

import { SelectedGroupGlcPermissionContextControllerProps } from './SelectedGroupGlcPermissionContextController.types';

export const SelectedGroupGlcPermissionContextController = ({
  children,
}: SelectedGroupGlcPermissionContextControllerProps) => {
  const [selectedGroupGlcPermission, setSelectedGroupGlcPermission] = useState<GroupPermission | undefined>(undefined);

  return (
    <SelectedGroupGlcPermissionContext.Provider value={{ selectedGroupGlcPermission, setSelectedGroupGlcPermission }}>
      {children}
    </SelectedGroupGlcPermissionContext.Provider>
  );
};
