import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'shared-ui';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Input } from 'ui/input/Input';
import { Form } from 'ui/form/Form';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { KeywordsInput } from 'ui/keywordsInput/KeywordsInput';
import { announcementKeywords } from '../announcementsTable/AnnouncementsList.types';

import { AnnouncementDetailsProps, UpdateAnnouncementFormBody } from './AnnouncementDetails.types';
import * as Styled from './AnnouncementDetails.styles';
import { AnnouncementActions } from './announcementActions/AnnouncementActions';

export const AnnouncementDetails = ({ isLoading, selectedAnnouncement, onSubmit }: AnnouncementDetailsProps) => {
  const { formatMessage } = useLocale();
  const { yesNoOptions } = useAppSelectOptions();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isValid },
  } = useFormContext<UpdateAnnouncementFormBody>();

  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <AnnouncementActions selectedAnnouncement={selectedAnnouncement} />

        <Controller
          name="nazwa"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              label={formatMessage({ id: AppMessages['announcements.details.name.label'] })}
              placeholder={formatMessage({ id: AppMessages['announcements.details.name.placeholder'] })}
              errorMessage={error?.message}
            />
          )}
        />

        <Styled.DetailsItemWrapper>
          <Styled.DetailText htmlFor="confirmation">
            {formatMessage({ id: AppMessages['announcements.details.confirmation.label'] })}
          </Styled.DetailText>

          <Form.ControlledSelect
            name="potwierdzenie"
            control={control}
            data-testid="confirmation"
            inputProps={{ id: 'confirmation' }}
            options={yesNoOptions}
          />
        </Styled.DetailsItemWrapper>

        <Styled.DetailsItemWrapper>
          <Styled.DetailText>
            {formatMessage({ id: AppMessages['announcements.details.duration.label'] })}
          </Styled.DetailText>

          <Controller
            name="czasKomunikatu"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                placeholder={formatMessage({ id: AppMessages['announcements.details.announcements.label'] })}
                errorMessage={error?.message}
                withoutHelperText
                type="number"
              />
            )}
          />
        </Styled.DetailsItemWrapper>

        <Controller
          name="komunikat"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <KeywordsInput
              {...field}
              label={formatMessage({ id: AppMessages['announcements.details.announcements.label'] })}
              errorMessage={error?.message}
              keywords={announcementKeywords}
              placeholder={formatMessage({ id: AppMessages['announcement.add.announcement.placeholder'] })}
            />
          )}
        />
      </div>

      {isDirty && (
        <Styled.ButtonsWrapper>
          <Button
            size="small"
            onClick={() => {
              reset({
                ...selectedAnnouncement,
                potwierdzenie: selectedAnnouncement?.potwierdzenie ? 'true' : 'false',
              });
            }}
            color="primary"
          >
            {formatMessage({ id: AppMessages['common.cancel'] })}
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={isLoading}
            size="small"
            variant="contained"
            color="primary"
          >
            {formatMessage({ id: AppMessages['common.save'] })}
          </Button>
        </Styled.ButtonsWrapper>
      )}
    </Styled.Form>
  );
};
