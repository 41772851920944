import { Typography } from '@mui/material';
import styled from 'styled-components';

export const PinCodeAssignForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const PinCodesInputWrapper = styled.div`
  width: 45%;
`;

export const CardNumber = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  margin-top: 24px;
`;
