export enum EventEnum {
  TKD = 'TKD',
  MANUALNY = 'MANUALNY',
}

export enum EventModEnum {
  WEJSCIE = 'WEJSCIE',
  WYJSCIE = 'WYJSCIE',
}

export enum EventModeEnum {
  ZWYKLE = 1,
  PRYWATNE,
  SLUZBOWE,
  SPECJALNE,
  DEFINED_5,
  DEFINED_6,
  DEFINED_7,
  DEFINED_8,
  DEFINED_9,
  DEFINED_10,
  DEFINED_11,
}

export enum EventEntryStateEnum {
  BRAK = 'BRAK',
  DRZWI_OTWARTE = 'DRZWI_OTWARTE',
  DRZWI_NIEOTWARTE = 'DRZWI_NIEOTWARTE',
}
