import { ForwardedRef, forwardRef } from 'react';
import { InputAdornment } from '@mui/material';

import { NUMBER_REGEX } from 'core/global.variables';

import { InputProps } from './Input.types';
import * as Styled from './Input.styles';

export const Input = forwardRef(
  (
    {
      label,
      placeholder,
      errorMessage,
      helperText,
      withoutHelperText,
      required,
      rightIcon,
      isClickable,
      InputProps,
      variant = 'filled',
      smallPlaceholderFontSize,
      withoutErrorMessage,
      testId,
      ...props
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <div>
        {label && (
          <Styled.Label>
            {label} {required && <Styled.Required>*</Styled.Required>}
          </Styled.Label>
        )}
        <Styled.Input
          inputRef={ref}
          inputProps={{ ...props?.inputProps, 'data-testId': testId }}
          fullWidth
          label={props.value && !errorMessage ? undefined : placeholder}
          variant={variant}
          size="small"
          InputProps={{
            ...InputProps,
            endAdornment: rightIcon && <InputAdornment position="end">{rightIcon}</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: variant !== 'outlined' ? undefined : false,
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
            const isShortcutUsed = e.ctrlKey || e.nativeEvent.metaKey;
            const allowedKeys = ['Delete', 'Backspace', 'End', 'Home', 'Tab', 'ArrowRight', 'ArrowLeft'];

            if (
              props.type === 'number' &&
              !allowedKeys.includes(e.key) &&
              !isShortcutUsed &&
              !NUMBER_REGEX.test(e.key)
            ) {
              e.preventDefault();
              return;
            }

            props.onKeyDown && props.onKeyDown(e);
          }}
          {...props}
          $isError={!!errorMessage}
          $isValue={!!props.value}
          $isClickable={!!isClickable}
          $smallPlaceholderFontSize={!!smallPlaceholderFontSize}
        />
        {!withoutHelperText && (
          <Styled.FormHelperText
            $isError={!!errorMessage}
            $isHelperText={!!helperText}
            children={withoutErrorMessage ? ' ' : errorMessage ? errorMessage : helperText ? helperText : ' '}
          />
        )}
      </div>
    );
  },
);
