import { PageLayout, ShowUp, TopBar } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';
import { AppRoute } from 'routing/AppRoute.enum';

import { GroupAssignedOwnersProps } from './GroupAssignedOwners.types';
import { GroupAssignedOwnersTableHeader } from './groupAssignedOwnersTableHeader/GroupAssignedOwnersTableHeader';
import { GroupAssignedOwnersTableRow } from './groupAssignedOwnersTableRow/GroupAssignedOwnersTableRow';
import { GroupAssignedOwnersMultiActions } from './groupsAssignedOwnersMultiActions/GroupAssignedOwnersMultiActions';
import { AddGroupAssignedOwnersContainer } from './addGroupAssignedOwners/AddGroupAssignedOwnersContainer';

export const GroupAssignedOwners = ({
  groupAssignedOwnersResponse,
  isLoading,
  groupCheckedAssignedOwners,
  setGroupCheckedAssignedOwners,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  groupName,
}: GroupAssignedOwnersProps) => {
  const { formatMessage } = useLocale();

  return (
    <PageLayout
      header={`${formatMessage({ id: AppMessages['group.owners.title'] })} ${groupName}`}
      withBackButton={{
        onClick: (navigate) => navigate(AppRoute.groups),
      }}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="1fr">
            <TopBar.ButtonsWrapper>
              <AddGroupAssignedOwnersContainer />
            </TopBar.ButtonsWrapper>
          </TopBar>

          <Table
            isLoading={isLoading}
            renderMultiActions={() => (
              <ShowUp show={!!groupCheckedAssignedOwners.length}>
                <GroupAssignedOwnersMultiActions
                  checkedOwners={groupCheckedAssignedOwners}
                  setCheckedOwners={setGroupCheckedAssignedOwners}
                />
              </ShowUp>
            )}
            pagination={{
              page,
              setPage,
              count: groupAssignedOwnersResponse?.count || 0,
              rowsPerPage,
              setRowsPerPage,
            }}
            testIdKey="nrEwidencyjny"
            gridTemplateColumns="1fr 1fr 1.5fr 1fr 0.7fr"
            data={groupAssignedOwnersResponse?.data || []}
            nothingToShowText={formatMessage({ id: AppMessages['owners.nothingToShow'] })}
            checkboxSelection={{
              checked: groupCheckedAssignedOwners,
              toggleChecked: setGroupCheckedAssignedOwners,
            }}
            renderHeader={() => <GroupAssignedOwnersTableHeader />}
            renderRow={(owner) => <GroupAssignedOwnersTableRow owner={owner} />}
          />
        </>
      )}
    />
  );
};
