import { Controller, useFormContext } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { ActionModal } from 'ui/actionModal/ActionModal';
import { GroupTypeDescriptionEnum } from 'app/groups/Groups.enum';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { GroupTypeEnum } from 'api/groups/groups.enum';
import { ActionButton } from 'ui/actionButton/ActionButton';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';
import { Form } from 'ui/form/Form';

import { CopyGroupProps, CopyGroupFormBody } from './CopyGroup.types';
import * as Styled from './CopyGroup.styles';

export const CopyGroup = ({
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
  selectedGroup,
}: CopyGroupProps) => {
  const { formatMessage } = useLocale();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<CopyGroupFormBody>();

  return (
    <>
      <ActionButton type="copy" onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['group.copy.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid || isLoading}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['group.copy.submitButton'] })}
      >
        <Form.Grid>
          <Controller
            name="idGrupy"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                label={formatMessage({ id: AppMessages['group.details.id'] })}
                placeholder={formatMessage({ id: AppMessages['group.details.id.placeholder'] })}
                errorMessage={error?.message}
                required
              />
            )}
          />
          <Controller
            name="nazwa"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                label={formatMessage({ id: AppMessages['group.details.groupName.label'] })}
                placeholder={formatMessage({ id: AppMessages['group.details.groupName.placeholder'] })}
                errorMessage={error?.message}
                required
              />
            )}
          />
          <Input
            disabled
            value={formatMessage({ id: AppMessages[GroupTypeDescriptionEnum[selectedGroup.typ]] })}
            label={formatMessage({ id: AppMessages['group.details.groupType.label'] })}
            required
          />

          {selectedGroup.typ === GroupTypeEnum.STATYCZNA && (
            <MultiAutocomplete
              disabled
              withoutSearchIcon
              value={selectedGroup.pkd.map((owner) => ({ label: `${owner.imie} ${owner.nazwisko}`, value: owner.id }))}
              stopRotatePopupIndicator
              inputSize="big"
              limitTags={1}
              options={[]}
              loading={false}
              label={formatMessage({ id: AppMessages['group.details.owner.label'] })}
              placeholder={formatMessage({ id: AppMessages['group.details.owner.label'] })}
            />
          )}

          {selectedGroup.typ === GroupTypeEnum.DYNAMICZNA && (
            <OrganizationsTreeSelect
              multiple
              disabled
              value={selectedGroup.komorki}
              onChange={() => null}
              placeholder={formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.cell.label'] })}
              label={formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.cell.label'] })}
            />
          )}

          {selectedGroup.glc ? (
            <MultiAutocomplete
              disabled
              withoutSearchIcon
              value={selectedGroup?.glcs?.map((glc) => ({ label: glc.nazwa, value: glc.id }))}
              stopRotatePopupIndicator
              inputSize="big"
              limitTags={1}
              onChange={() => null}
              options={[]}
              loading={false}
              label={formatMessage({ id: AppMessages['group.details.glc.label'] })}
              placeholder={formatMessage({ id: AppMessages['group.details.glc.label'] })}
            />
          ) : (
            <MultiAutocomplete
              disabled
              withoutSearchIcon
              value={selectedGroup?.tkds?.map((tkd) => ({ label: tkd.nazwa, value: tkd.id }))}
              stopRotatePopupIndicator
              inputSize="big"
              limitTags={1}
              onChange={() => null}
              options={[]}
              loading={false}
              label={formatMessage({ id: AppMessages['group.details.reader.label'] })}
              placeholder={formatMessage({ id: AppMessages['group.details.reader.label'] })}
            />
          )}

          <Styled.DescriptionInputWrapper>
            <Controller
              name="opis"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  label={formatMessage({ id: AppMessages['group.details.description.label'] })}
                  placeholder={formatMessage({ id: AppMessages['group.details.description.placeholder'] })}
                  errorMessage={error?.message}
                />
              )}
            />
          </Styled.DescriptionInputWrapper>
        </Form.Grid>
      </ActionModal>
    </>
  );
};
