import { ClientError, APIError, useErrorHandler, useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLoaderToast } from 'shared-ui';

import { addCard, addManyCards } from 'api/cards/cards';
import { CardTypeEnum } from 'api/cards/cards.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { CardBlockedEnum, SingleOrManyEnum } from '../AddCard.enum';
import { useMutation } from 'hooks/useMutation/useMutation';

import { AddCardForm } from './AddCardForm';
import { useAddCardFormValidation } from './AddCardForm.validation';
import { AddCardFormBody, AddCardFormContainerProps } from './AddCardForm.types';

export const AddCardFormContainer = ({
  onModalClose,
  isFileLoaded,
  onSubmitAddCardFromFile,
  isSingleCardAdd,
  onAddSingleCardSuccess,
  isFileValidationError,
}: AddCardFormContainerProps) => {
  const { formatMessage } = useLocale();
  const { schema } = useAddCardFormValidation();
  const { handleError } = useErrorHandler();
  const queryClient = useQueryClient();

  const { load: loadAddCard, update: updateAddCard } = useLoaderToast('addCard');
  const { load: loadAddCards, update: updateAddCards } = useLoaderToast('addCards');

  const { mutate: onAddCard, isLoading: isLoadingAddCard } = useMutation(addCard, {
    onMutate: () => loadAddCard(formatMessage({ id: AppMessages['cards.addCard.single.loader'] })),
    onSuccess: (card) => {
      queryClient.invalidateQueries([QueryKeyEnum.CARDS]);
      onModalClose();
      updateAddCard(formatMessage({ id: AppMessages['cards.addCard.single.successMessage'] }));
      onAddSingleCardSuccess && onAddSingleCardSuccess(card);
    },
    onError: (error: ClientError<APIError>) => {
      const errorFields = error.response?.data.fields;

      const isCardNumberExistsError = errorFields?.find(
        ({ type, field }) => type === 'exists' && field === 'numerKarty',
      );

      if (isCardNumberExistsError) {
        updateAddCard(formatMessage({ id: AppMessages['cards.addCard.validation.cardNumber.exist'] }));
        return methods.setError('numerKarty', {
          message: formatMessage({ id: AppMessages['cards.addCard.validation.cardNumber.exist'] }),
        });
      }

      handleError({ error, updateToastCallback: updateAddCard });
    },
  });

  const { mutate: onAddCards, isLoading: isLoadingAddManyCards } = useMutation(addManyCards, {
    onMutate: () => loadAddCards(formatMessage({ id: AppMessages['cards.addCard.many.loader'] })),
    onSuccess: ({ liczbaDodanych, liczbaTworzonych }) => {
      queryClient.invalidateQueries([QueryKeyEnum.CARDS]);
      const everyCardsCreated = liczbaDodanych === liczbaTworzonych;

      if (!everyCardsCreated) {
        methods.setError('numerOd', {
          message: formatMessage(
            { id: AppMessages['cards.addCard.validation.many.numbers.excludedCards'] },
            {
              excludedCardsNumber: liczbaTworzonych - liczbaDodanych,
            },
          ),
        });
      }

      updateAddCards(
        formatMessage(
          { id: AppMessages['cards.addCard.many.successMessage'] },
          {
            addedNumber: liczbaDodanych,
            fromNumber: liczbaTworzonych,
          },
        ),
      );
      everyCardsCreated && onModalClose();
    },
    onError: (error: ClientError<APIError>) => {
      handleError({ error, updateToastCallback: updateAddCards });
    },
  });

  const onSubmit = ({ singleOrMany, numerKarty, numerOd, numerDo, zablokowana, ...rest }: AddCardFormBody) => {
    singleOrMany === SingleOrManyEnum.SINGLE
      ? onAddCard({
          body: {
            numerKarty: String(numerKarty),
            zablokowana: zablokowana === CardBlockedEnum.TAK ? true : false,
            ...rest,
          },
        })
      : onAddCards({
          body: {
            numerOd: String(numerOd),
            numerDo: String(numerDo),
            zablokowana: zablokowana === CardBlockedEnum.TAK ? true : false,
            ...rest,
          },
        });
  };

  const methods = useForm<AddCardFormBody>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      typKarty: CardTypeEnum.ZWYKLA,
      zablokowana: CardBlockedEnum.NIE,
      singleOrMany: SingleOrManyEnum.SINGLE,
    },
  });

  return (
    <FormProvider {...methods}>
      <AddCardForm
        onSubmit={onSubmit}
        onModalClose={onModalClose}
        isFileLoaded={isFileLoaded}
        onSubmitAddCardFromFile={onSubmitAddCardFromFile}
        isLoadingAddCard={isLoadingAddCard}
        isLoadingAddManyCards={isLoadingAddManyCards}
        isSingleCardAdd={isSingleCardAdd}
        isFileValidationError={isFileValidationError}
      />
    </FormProvider>
  );
};
