import { Tooltip } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ReactComponent as HistoryIcon } from 'assets/icons/icon-history.svg';
import { ReactComponent as ReturnIcon } from 'assets/icons/icon-return.svg';
import { ReactComponent as UnblockIcon } from 'assets/icons/icon-unblock.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/icon-trash.svg';
import { ReactComponent as BlockIcon } from 'assets/icons/icon-block.svg';
import { ReactComponent as ArchiveIcon } from 'assets/icons/icon-archive.svg';
import { ReactComponent as UnarchiveIcon } from 'assets/icons/icon-bring-back.svg';
import { ReactComponent as AcceptIcon } from 'assets/icons/accept-white.svg';
import { ReactComponent as RealoadIcon } from 'assets/icons/reload-white.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { ReactComponent as OwnerIcon } from 'assets/icons/owner.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as TerminalsIcon } from 'assets/icons/terminals.svg';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';

import { ActionButtonProps, ActionButtonType } from './ActionButton.types';
import * as Styled from './ActionButton.styles';

export const ActionButton = ({ type, onClick, customTooltipText, disabled }: ActionButtonProps) => {
  const { formatMessage } = useLocale();

  const actionOptions: Record<ActionButtonType, { text: string; icon: JSX.Element }> = {
    history: {
      text: formatMessage({ id: AppMessages[`common.action.history`] }),
      icon: <HistoryIcon />,
    },
    return: {
      text: formatMessage({ id: AppMessages[`common.action.return`] }),
      icon: <ReturnIcon />,
    },
    unblock: {
      text: formatMessage({ id: AppMessages[`common.action.unblock`] }),
      icon: <UnblockIcon />,
    },
    block: {
      text: formatMessage({ id: AppMessages[`common.action.block`] }),
      icon: <BlockIcon />,
    },
    delete: {
      text: formatMessage({ id: AppMessages[`common.action.delete`] }),
      icon: <TrashIcon />,
    },
    archive: {
      text: formatMessage({ id: AppMessages[`common.action.archive`] }),
      icon: <ArchiveIcon />,
    },
    unarchive: {
      text: formatMessage({ id: AppMessages[`common.action.unarchive`] }),
      icon: <UnarchiveIcon />,
    },
    accept: {
      text: formatMessage({ id: AppMessages[`common.action.accept`] }),
      icon: <AcceptIcon />,
    },
    reload: {
      text: formatMessage({ id: AppMessages[`common.action.reload`] }),
      icon: <RealoadIcon />,
    },
    permissions: {
      text: formatMessage({ id: AppMessages[`common.action.permissions`] }),
      icon: <KeyIcon />,
    },
    owners: {
      text: formatMessage({ id: AppMessages[`common.action.owners`] }),
      icon: <OwnerIcon />,
    },
    copy: {
      text: formatMessage({ id: AppMessages[`common.action.copy`] }),
      icon: <CopyIcon />,
    },
    terminals: {
      text: formatMessage({ id: AppMessages[`common.action.terminals`] }),
      icon: <TerminalsIcon />,
    },
    add: {
      text: '',
      icon: <AddIcon />,
    },
  };

  return (
    <Tooltip title={customTooltipText ? customTooltipText : actionOptions[type].text}>
      <Styled.ActionButton
        data-testid={`action-${type}`}
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={disabled}
      >
        {actionOptions[type].icon}
      </Styled.ActionButton>
    </Tooltip>
  );
};
