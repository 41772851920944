import { useLocale } from 'hooks/useLocale/useLocale';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { AppRouteWithParams } from 'routing/AppRoute.enum';

import { StatusTableItemProps, StatusText } from './StatusTableItem.types';
import * as Styled from './StatusTableItem.styles';

export const StatusTableItem = ({ status, disabled, textBold, smallFontSize, ...props }: StatusTableItemProps) => {
  const { formatMessage } = useLocale();

  const isOwnerHistoryView = useIsExactPath(AppRouteWithParams.ownerHistory.path);

  const statusText: StatusText = {
    OK: 'devices.readers.status.ok',
    NOWY: 'devices.readers.status.new',
    PODMIENIONY: 'devices.readers.status.replaced',
    AWARIA: 'devices.readers.status.failure',
    USUNIETY: 'devices.readers.status.deleted',
    DOSTEPNA: 'card.status.available',
    WYDANA: 'card.status.released',
    WYDANA_Z: 'card.status.releasedWithReturnDate',
    ZABLOKOWANO: isOwnerHistoryView ? 'owner.history.action.locked' : 'cards.history.action.locked',
    ODBLOKOWANO: isOwnerHistoryView ? 'owner.history.action.unlocked' : 'cards.history.action.unlocked',
    ZWROCONO: isOwnerHistoryView ? 'owner.history.action.returned' : 'cards.history.action.returned',
    WYDANO: isOwnerHistoryView ? 'owner.history.action.issued' : 'cards.history.action.issued',
    ZARCHIWIZOWANO: 'cards.history.action.archived',
    PRZYWROCONO: 'cards.history.action.restored',
    DODANO: 'cards.history.action.added',
    ZEDYTOWANO: 'cards.history.action.edited',
    AKTYWNY: 'user.status.active',
    NIEAKTYWNY: 'user.status.inactive',
    USUNIETO: 'group.history.action.deleted',
  };

  return (
    <Styled.Container {...props}>
      <Styled.Dot $status={status} $disabled={disabled}></Styled.Dot>
      <Styled.Text $textBold={textBold} $smallFontSize={smallFontSize}>
        {formatMessage({ id: statusText[status] })}
      </Styled.Text>
    </Styled.Container>
  );
};
