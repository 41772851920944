import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const CellWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Cell = styled(Typography)(
  ({ theme: { palette } }) => css`
    background-color: ${palette.grey[300]};
    padding: 6px 11px;
    font-weight: 600 !important;
    border-radius: 16px;
    display: inline-block;
    max-width: 170px;
    line-height: 1;
  `,
);
