import { AutocompleteRenderOptionState } from '@mui/material';

import { MultiAutocompleteOption } from '../multiAutocompleteOption/MultiAutocompleteOption';
import { Option } from '../MultiAutocomplete.types';

export const useRenderMultiAutocompleteOption = (args?: { idPrefix?: string }, withoutIdPrefix?: boolean) => {
  const renderMultiAutocompleteOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Option,
    state: AutocompleteRenderOptionState,
  ) => {
    return (
      <MultiAutocompleteOption
        props={props}
        option={option}
        state={state}
        idPrefix={args?.idPrefix}
        withoutIdPrefix={withoutIdPrefix}
      />
    );
  };

  return { renderMultiAutocompleteOption };
};
