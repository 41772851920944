import { Controller, useFormContext } from 'react-hook-form';
import { PinCodeInput } from 'shared-ui';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

import { PinCodesFieldsProps, PinCodesFormFields } from './PinCodesFields.types';

export const PinCodesFields = ({ required, isDisabled }: PinCodesFieldsProps) => {
  const { formatMessage } = useLocale();

  const { control, watch } = useFormContext<PinCodesFormFields>();

  return (
    <div>
      <Controller
        name="pin"
        control={control}
        render={({ field, formState: { errors } }) => (
          <PinCodeInput
            {...field}
            disabled={isDisabled}
            required={required}
            value={watch('pin')}
            label={formatMessage({ id: AppMessages['card.assign.pin.input.label'] })}
            errorMessage={errors?.pin?.message}
            placeholder={formatMessage({ id: AppMessages['card.assign.pin.input.placeholder'] })}
          />
        )}
      />

      <Controller
        name="pinConfirm"
        control={control}
        render={({ field, formState: { errors } }) => (
          <PinCodeInput
            {...field}
            disabled={isDisabled}
            required={required}
            value={watch('pinConfirm')}
            label={formatMessage({ id: AppMessages['card.assign.pin.confirm.label'] })}
            errorMessage={errors?.pinConfirm?.message}
            placeholder={formatMessage({ id: AppMessages['card.assign.pin.input.confirm.placeholder'] })}
          />
        )}
      />
    </div>
  );
};
