import { useLocale } from 'hooks/useLocale/useLocale';
import { Table } from 'ui/table/Table';
import { AppMessages } from 'i18n/messages';
import { useSelectedGroupGlcPermission } from 'hooks/useSelectedGroupGlcPermission/useSelectedGroupGlcPermission';

import { GroupGlcProps } from './GroupGlc.types';
import { GroupGlcTableHeader } from './groupTerminalsTableHeader/GroupGlcTableHeader';
import { GroupGlcTableRow } from './groupGlcTableRow/GroupGlcTableRow';

export const GroupGlc = ({ permissionsResponse, checkedPermissions, setCheckedPermissions }: GroupGlcProps) => {
  const { formatMessage } = useLocale();
  const { selectedGroupGlcPermission, setSelectedGroupGlcPermission } = useSelectedGroupGlcPermission();

  return (
    <Table
      onClickRow={(permission) =>
        setSelectedGroupGlcPermission(selectedGroupGlcPermission?.id === permission.id ? undefined : permission)
      }
      activeRowId={selectedGroupGlcPermission?.id}
      gridTemplateColumns="0.7fr 1fr 1fr 1fr 1fr"
      data={permissionsResponse?.data || []}
      nothingToShowText={formatMessage({ id: AppMessages['permissions.server.nothingToShow'] })}
      checkboxSelection={{
        checked: checkedPermissions,
        toggleChecked: setCheckedPermissions,
        withoutCheckedElementsBar: true,
      }}
      customTableHeight={() => undefined}
      renderHeader={() => <GroupGlcTableHeader />}
      renderRow={(permission) => <GroupGlcTableRow permission={permission} />}
    />
  );
};
