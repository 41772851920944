import { useMemo } from 'react';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { updateExipryDateMultiCards } from 'api/cards/cards';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { UpdateExpiryDateMultiCardsBody } from 'api/cards/cards.types';
import { useMutation } from 'hooks/useMutation/useMutation';
import { updateExipryDateMultiOwnersCards } from 'api/owners/owners';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { useModalState } from 'hooks/useModalState/useModalState';

import { UpdateExpiryDateMultiCards } from './UpdateExpiryDateMultiCards';
import { UpdateExpiryDateMultiCardsContainerProps } from './UpdateExpiryDateMultiCards.types';
import { useUpdateExpiryDateMultiCardsOrOwnersValidation } from './UpdateExpiryDateMultiCards.validation';

export const UpdateExpiryDateMultiCardsContainer = (props: UpdateExpiryDateMultiCardsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedCard } = useSelectedCard();
  const { selectedOwner } = useSelectedOwner();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onUpdateExpiryDateMultiCards, isLoading: isLoadingUpdateExpiryDateMultiCards } = useMutation(
    updateExipryDateMultiCards,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeyEnum.CARDS]);
        selectedCard && queryClient.invalidateQueries([QueryKeyEnum.CARD_DETAILS, selectedCard.id]);

        toast.dark(formatMessage({ id: AppMessages['cards.multiAction.expiryDate.success'] }));
        onClose();
      },
    },
  );

  const { mutate: onUpdateExpiryDateMultiOwnersCards, isLoading: isLoadingUpdateExpiryDateMultiOwners } = useMutation(
    updateExipryDateMultiOwnersCards,
    {
      onSuccess: ({
        numberOfChangedKads,
        numberOfRequestedKads,
        doNotHaveCard,
        numberOfChangedPkds,
        numberOfRequestedPkds,
      }) => {
        queryClient.invalidateQueries([QueryKeyEnum.OWNERS]);
        selectedOwner && queryClient.invalidateQueries([QueryKeyEnum.OWNER_DETAILS, selectedOwner.id]);

        if (numberOfChangedKads === numberOfRequestedKads) {
          toast.dark(formatMessage({ id: AppMessages['owners.multiAction.changeExpiry.success.message'] }), {
            autoClose: 7000,
          });
        } else {
          toast.dark(
            formatMessage(
              { id: AppMessages['owners.multiAction.changeExpiry.success.notAllChanged'] },
              {
                numberOfChangedKads: numberOfChangedKads || 0,
                numberOfRequestedKads,
              },
            ),
            { autoClose: 7000 },
          );
        }

        doNotHaveCard &&
          toast.dark(formatMessage({ id: AppMessages['owners.multiAction.changeExpiry.success.blockedCards'] }), {
            autoClose: 7000,
          });

        numberOfChangedPkds !== 0 &&
          toast.dark(
            formatMessage(
              { id: AppMessages['owners.multiAction.changeExpiry.success.ownersWithoutCards'] },
              {
                numberOfChangedPkds,
                numberOfRequestedPkds,
              },
            ),
            { autoClose: 7000 },
          );

        onModalClose();
      },
    },
  );

  const minDate = useMemo(() => new Date(), []);
  const { schema } = useUpdateExpiryDateMultiCardsOrOwnersValidation(minDate);
  const formMethods = useForm<UpdateExpiryDateMultiCardsBody>({
    defaultValues: {
      waznoscOd: new Date(),
      waznoscDo: null,
    },
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const onSubmit = (formBody: Omit<UpdateExpiryDateMultiCardsBody, 'cards'>) => {
    props.actionIn === 'cards'
      ? onUpdateExpiryDateMultiCards({ body: { ...formBody, cards: props.checkedCards.map(({ id }) => id) } })
      : onUpdateExpiryDateMultiOwnersCards({
          dataOd: formBody.waznoscOd,
          dataDo: formBody.waznoscDo,
          pkds: props.checkedOwners.map(({ id }) => id),
        });
  };

  const onClose = () => {
    formMethods.reset({ waznoscOd: new Date(), waznoscDo: null });
    onModalClose();
  };

  return (
    <FormProvider {...formMethods}>
      <UpdateExpiryDateMultiCards
        checkedElementsLength={props.actionIn === 'cards' ? props.checkedCards.length : props.checkedOwnersCardsNumber}
        actionIn={props.actionIn}
        isModalOpen={isModalOpen}
        onModalClose={onClose}
        onModalOpen={onModalOpen}
        isLoading={isLoadingUpdateExpiryDateMultiCards || isLoadingUpdateExpiryDateMultiOwners}
        onSubmit={onSubmit}
        minDate={minDate}
      />
    </FormProvider>
  );
};
