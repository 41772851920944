import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'shared-ui';

import { ReactComponent as InformationIcon } from 'assets/icons/information.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { ActionModal } from 'ui/actionModal/ActionModal';
import { ModifyWantedInformationBody } from 'api/wanted/wanted.types';

import { ModifyWantedInformationProps } from './ModifyWantedInformationMulti.types';

export const ModifyWantedInformation = ({
  checkedOwners,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
}: ModifyWantedInformationProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useFormContext<ModifyWantedInformationBody>();

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<InformationIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['wanted.multiAction.modify.button'] })}
      </Button>

      <ActionModal
        header={formatMessage({ id: AppMessages['wanted.multiAction.modify.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={isLoading || !isValid}
        submitButtonText={formatMessage({ id: AppMessages['common.save'] })}
      >
        <>
          <ActionModal.Subtitle>
            {formatMessage({ id: AppMessages['wanted.multiAction.modify.subtitle'] })} {checkedOwners.length}
          </ActionModal.Subtitle>

          <Controller
            name="informacja"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                label={formatMessage({ id: AppMessages['wanted.multiAction.modify.label'] })}
                placeholder={formatMessage({ id: AppMessages['wanted.multiAction.modify.placeholder'] })}
                required
                errorMessage={error?.message}
              />
            )}
          />
        </>
      </ActionModal>
    </>
  );
};
