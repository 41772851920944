import { useEffect } from 'react';
import { usePagination } from 'shared-ui';

import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { QueryKeyLocalStorageEnum, WantedQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchWanted } from 'api/wanted/wanted';

import { WantedTable } from './WantedTable';
import { WantedTableContainerProps } from './WantedTable.types';

export const WantedTableContainer = ({ wantedTableSearchQuery }: WantedTableContainerProps) => {
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.WANTED]: wantedQuery },
  } = useLocaleStoreQuery();
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();

  const { data: ownersResponse, isLoading } = useQuery(
    [QueryKeyEnum.WANTED, wantedQuery, page, rowsPerPage, wantedTableSearchQuery],
    fetchWanted,
    {
      args: {
        ...wantedQuery,
        [WantedQueryKeysEnum.LIMIT]: rowsPerPage,
        [WantedQueryKeysEnum.OFFSET]: rowsPerPage * page,
        [WantedQueryKeysEnum.QUERY]: wantedTableSearchQuery,
      },
    },
  );

  useEffect(() => {
    setPage(0);
  }, [wantedTableSearchQuery, wantedQuery]);

  return (
    <WantedTable
      ownersResponse={ownersResponse}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      wantedQuery={wantedQuery}
    />
  );
};
