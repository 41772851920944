import styled, { css } from 'styled-components';

import { CardStatusEnum } from 'api/cards/cards.enum';

import { StatusTableItemProps, DotStylesProps, StatusTextStylesProps } from './StatusTableItem.types';

const statusColor = (status: StatusTableItemProps['status'], disabled?: boolean) => {
  if (disabled) return '#383c49';

  return {
    AWARIA: '#9f7aed',
    OK: '#49b051',
    NOWY: '#2d6ce6',
    USUNIETY: '#e3557d',
    PODMIENIONY: '#ff952a',
    DOSTEPNA: '#77c6c1',
    WYDANA: '#efe380',
    ZABLOKOWANO: '#ffc276',
    ODBLOKOWANO: '#a0634a',
    ZARCHIWIZOWANO: '#e865ff',
    PRZYWROCONO: '#783a83',
    ZWROCONO: '#138268',
    WYDANO: '#77c6c1',
    DODANO: '#efe380',
    ZEDYTOWANO: '#ff952a',
    AKTYWNY: '#49B051',
    NIEAKTYWNY: '#E3557D',
    WYDANA_Z: undefined,
    USUNIETO: '#C20707',
  }[status];
};

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Dot = styled.div<DotStylesProps>(
  ({ $status, $disabled }) => css`
    width: 14px;
    height: 14px;
    background-color: ${statusColor($status, $disabled)};
    margin-right: 7px;
    margin-top: -2px;
    border-radius: 50%;
    background: ${$status === CardStatusEnum.WYDANA_Z &&
    `linear-gradient(-45deg, 
      ${statusColor(CardStatusEnum.DOSTEPNA, false)},
      50%, 
      ${statusColor(CardStatusEnum.WYDANA, false)} 
      50%);
     `};
  `,
);

export const Text = styled.span<StatusTextStylesProps>(
  ({ $textBold, $smallFontSize }) => css`
    font-weight: ${$textBold ? 700 : 'inherit'};
    font-size: ${$smallFontSize ? '12px' : 'inherit'};
  `,
);
