import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { acceptTerminal, reloadTerminal } from 'api/terminals/terminals';
import { TerminalStatusEnum } from 'api/terminals/terminals.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';

import { TerminalDetails } from './TerminalDetails';
import { TerminalDetailsContainerProps } from './TerminalDetails.types';

export const TerminalDetailsContainer = ({
  selectedTerminal,
  setSelectedTerminal,
  terminalActionDisabled,
}: TerminalDetailsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();

  const { mutate: onReloadTerminal } = useMutation(reloadTerminal, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.TERMINALS]);
      toast.dark(
        formatMessage({
          id: AppMessages['devices.details.tooltip.reload.successMessage'],
        }),
      );
      setSelectedTerminal({ ...selectedTerminal, status: TerminalStatusEnum.OK });
    },
  });

  const { mutate: onAcceptTerminal } = useMutation(acceptTerminal, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.TERMINALS]);
      toast.dark(
        formatMessage({
          id: AppMessages['devices.details.tooltip.accept.successMessage'],
        }),
      );
      setSelectedTerminal({ ...selectedTerminal, status: TerminalStatusEnum.OK });
    },
  });

  return (
    <TerminalDetails
      selectedTerminal={selectedTerminal}
      onAcceptTerminal={onAcceptTerminal}
      onReloadTerminal={onReloadTerminal}
      terminalActionDisabled={terminalActionDisabled}
    />
  );
};
