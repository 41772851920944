import { Button } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import * as Styled from '../DevicesMultiActions.styles';

import { AcceptMultiTerminalsProps } from './AcceptMultiTerminals.types';

export const AcceptMultiTerminals = ({ onSubmit, disabled }: AcceptMultiTerminalsProps) => {
  const { formatMessage } = useLocale();

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      startIcon={<Styled.AcceptIcon disabled={disabled} />}
      onClick={onSubmit}
    >
      {formatMessage({ id: AppMessages['devices.multiAction.accept'] })}
    </Button>
  );
};
