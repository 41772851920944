export enum OwnerDocumentEnum {
  DOWOD = 'DOWOD',
  PASZPORT = 'PASZPORT',
  PRAWO_JAZDY = 'PRAWO_JAZDY',
}

export enum OwnerHistoryActionDescriptionEnum {
  ZABLOKOWANO = 'owner.history.action.locked',
  ODBLOKOWANO = 'owner.history.action.unlocked',
  ZWROCONO = 'owner.history.action.returned',
  WYDANO = 'owner.history.action.issued',
  ZARCHIWIZOWANO = 'cards.history.action.archived',
  PRZYWROCONO = 'cards.history.action.restored',
  DODANO = 'cards.history.action.added',
  ZEDYTOWANO = 'cards.history.action.edited',
}
