import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { unarchiveGlc } from 'api/glc/glc';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useSelectedGlc } from 'hooks/useSelectedGlc/useSelectedGlc';
import { useModalState } from 'hooks/useModalState/useModalState';

import { UnarchiveGlc } from './UnarchiveGlc';
import { UnarchiveGlcContainerProps } from './UnarchiveGlc.types';

export const UnarchiveGlcContainer = ({ selectedGlc }: UnarchiveGlcContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { setSelectedGlc } = useSelectedGlc();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onUnarchiveGlc, isLoading } = useMutation(unarchiveGlc, {
    onSuccess: () => {
      setSelectedGlc(undefined);
      queryClient.invalidateQueries([QueryKeyEnum.GLC]);
      toast.dark(formatMessage({ id: AppMessages['glc.unarchive.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onUnarchiveGlc(selectedGlc.id);
  };

  return (
    <UnarchiveGlc
      selectedGlc={selectedGlc}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
