import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useAddGlcValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    idGlc: yup
      .string()
      .max(6, formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.validation.id.max'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    nazwa: yup
      .string()
      .max(60, formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.validation.name.max'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    opis: yup
      .string()
      .max(60, formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.validation.description.max'] }))
      .optional(),
    tkds: yup.array(),
    serwerId: yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] })),
  });

  return { schema };
};
