import styled from 'styled-components';
import { Typography } from '@mui/material';
import { PaginationSelectIcon, CloseGrayIcon } from 'shared-ui';

export const RemoveIcon = styled(CloseGrayIcon)`
  position: absolute;
  cursor: pointer;
  right: 12px;
  top: 12px;
`;

export const SelectIcon = styled(PaginationSelectIcon)`
  position: absolute;
  cursor: pointer;
  right: 12px;
  top: 12px;
`;

export const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 100px;
  gap: 10px;
`;

export const Filters = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 1fr) repeat(4, minmax(100px, 0.6fr));
  gap: 16px;
`;

export const ClearFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;
`;

export const ClearFiltersButton = styled(Typography)`
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
  justify-self: flex-end;
`;

export const ClearFiltersIcon = styled(CloseGrayIcon)`
  margin-left: 10px;
  margin-bottom: 4px;
`;
