import { useState } from 'react';

import { Terminal } from 'api/terminals/terminals.types';
import { SelectedTerminalContext } from '../selectedTerminalContext/SelectedTerminalContext';

import { SelectedTerminalContextControllerProps } from './SelectedServerContextController.types';

export const SelectedTerminalContextController = ({ children }: SelectedTerminalContextControllerProps) => {
  const [selectedTerminal, setSelectedTerminal] = useState<Terminal | undefined>(undefined);

  return (
    <SelectedTerminalContext.Provider value={{ selectedTerminal, setSelectedTerminal }}>
      {children}
    </SelectedTerminalContext.Provider>
  );
};
