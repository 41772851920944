import { useState } from 'react';

import { ExportEventsBody } from 'api/events/events.types';
import { ExportEventFormContext } from '../exportEventFormContext/ExportEventFormContext';

import { ExportEventFormContextControllerProps } from './ExportEventFormContextController.types';

export const ExportEventFormContextController = ({ children }: ExportEventFormContextControllerProps) => {
  const [exportEventFormState, setExportEventFormState] = useState<Partial<ExportEventsBody>>();

  return (
    <ExportEventFormContext.Provider value={{ exportEventFormState, setExportEventFormState }}>
      {children}
    </ExportEventFormContext.Provider>
  );
};
