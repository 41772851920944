import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Button } from 'shared-ui';

export const OwnerCardsDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  overflow: auto;
  margin-bottom: 16px;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CardButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;

export const NoCards = styled(Typography)(
  ({ theme: { palette } }) => css`
    font-size: 14px;
    padding: 6px 0px;
    color: ${palette.info.light};
  `,
);

export const Link = styled(ReactRouterLink)`
  text-decoration: none;
`;

export const CardButton = styled(Button)`
  min-width: 80px;
  width: 100%;
`;
