import { Controller, useFormContext } from 'react-hook-form';
import { formatISO, isValid as isValidDate } from 'date-fns';
import { Button, Autocomplete, Option, Datepicker } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import * as Styled from '../OwnerDetails.styles';
import { OwnerDocumentEnum } from 'api/owners/owners.enum';
import { OwnerDocumentDescriptionEnum } from 'app/owners/Owners.enum';
import { DateFormat } from 'core/global.enum';
import { PHONE_REGEX } from 'core/global.variables';

import { OwnerPersonalDetailsProps, OwnerPersonalDetailsForm } from './OwnerPersonalDetails.types';

export const OwnerPersonalDetails = ({
  isLoadingUpdateOwner,
  selectedOwner,
  onSubmit,
  resetToSelected,
  isEditDisabled,
}: OwnerPersonalDetailsProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty, isValid, errors },
    setValue,
    clearErrors,
  } = useFormContext<OwnerPersonalDetailsForm>();
  const document = watch('dokumentTozsamosci');

  const ownerDocumentOptions = Object.values(OwnerDocumentEnum).map((document) => ({
    label: formatMessage({ id: OwnerDocumentDescriptionEnum[document] }),
    value: document,
  }));

  const ownerDocumentValue = (value: string) => ({
    label: formatMessage({ id: OwnerDocumentDescriptionEnum[value as OwnerDocumentEnum] }),
    value,
  });

  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)} $withoutGap>
      <Controller
        control={control}
        name="dokumentTozsamosci"
        render={({ field }) => (
          <Styled.FieldWrapper>
            <Autocomplete
              {...field}
              value={field.value ? (ownerDocumentValue(field.value) as Option) : null}
              onChange={(_e, option) => {
                clearErrors('numerDokumentu');
                setValue('numerDokumentu', '');
                option ? field.onChange(option.value) : field.onChange(null);
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={ownerDocumentOptions}
              renderOption={(props, option) =>
                option.value ? (
                  <li {...props} key={option.value}>
                    <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
                  </li>
                ) : null
              }
              placeholder={formatMessage({ id: AppMessages['owner.details.personal.document.placeholder'] })}
              label={formatMessage({ id: AppMessages['owner.details.personal.document'] })}
              disabled={isEditDisabled}
            />
          </Styled.FieldWrapper>
        )}
      />

      {!!document && (
        <Controller
          name="numerDokumentu"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              value={field.value || ''}
              placeholder={formatMessage({ id: AppMessages['owner.details.personal.documentNumber.placeholder'] })}
              label={formatMessage({ id: AppMessages['owner.details.personal.documentNumber'] })}
              errorMessage={errors?.numerDokumentu?.message}
              disabled={isEditDisabled}
            />
          )}
        />
      )}
      <Controller
        name="dataUrodzenia"
        control={control}
        render={({ field }) => (
          <Datepicker
            {...field}
            onChange={(date) => {
              const formattedDate = !date ? date : isValidDate(date) ? formatISO(date) : date;

              field.onChange(formattedDate);
            }}
            label={formatMessage({ id: AppMessages['owner.details.personal.birthDate'] })}
            mask="____/__/__"
            inputFormat={DateFormat.DATE}
            placeholderText={formatMessage({
              id: AppMessages['owner.details.personal.birthDate.placeholder'],
            })}
            isClickable={!isEditDisabled}
            errorMessage={errors?.dataUrodzenia?.message}
            disabled={isEditDisabled}
          />
        )}
      />
      <Controller
        name="pesel"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            value={field.value || ''}
            type="number"
            placeholder={formatMessage({ id: AppMessages['owner.details.personal.pesel.placeholder'] })}
            label={formatMessage({ id: AppMessages['owner.details.personal.pesel'] })}
            errorMessage={errors?.pesel?.message}
            disabled={isEditDisabled}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            value={field.value || ''}
            placeholder={formatMessage({ id: AppMessages['owner.details.personal.email.placeholder'] })}
            label={formatMessage({ id: AppMessages['owner.details.personal.email'] })}
            errorMessage={errors?.email?.message}
            disabled={isEditDisabled}
          />
        )}
      />
      <Controller
        name="telefon"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            onChange={(e) => {
              if (e.target.value && !PHONE_REGEX.test(e.target.value)) return;

              field.onChange(e);
            }}
            value={field.value || ''}
            placeholder={formatMessage({ id: AppMessages['owner.details.personal.phone.placeholder'] })}
            label={formatMessage({ id: AppMessages['owner.details.personal.phone'] })}
            errorMessage={errors?.telefon?.message}
            disabled={isEditDisabled}
          />
        )}
      />
      <Controller
        name="opis"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            value={field.value || ''}
            placeholder={formatMessage({ id: AppMessages['owner.details.personal.description.placeholder'] })}
            label={formatMessage({ id: AppMessages['owner.details.personal.description'] })}
            errorMessage={errors?.opis?.message}
            maxRows={4}
            multiline
            disabled={isEditDisabled}
          />
        )}
      />

      {isDirty && (
        <Styled.ButtonsWrapper>
          <Button
            size="small"
            onClick={() => {
              reset(resetToSelected(selectedOwner));
            }}
            color="primary"
          >
            {formatMessage({ id: AppMessages['owner.details.button.cancel'] })}
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={isLoadingUpdateOwner}
            size="small"
            variant="contained"
            color="primary"
          >
            {formatMessage({ id: AppMessages['owner.details.button.save'] })}
          </Button>
        </Styled.ButtonsWrapper>
      )}
    </Styled.Form>
  );
};
