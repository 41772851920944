import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { UpdatePositionBody } from 'api/positions/positions.types';

import { PositionDetailsProps } from './PositionDetails.types';
import * as Styled from './PositionDetails.styles';
import { PositionActions } from './positionActions/PositionActions';

export const OrganizationDetails = ({ onSubmit, isLoading, selectedPosition }: PositionDetailsProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isValid },
  } = useFormContext<UpdatePositionBody>();

  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <PositionActions selectedPosition={selectedPosition} />

        <Controller
          name="nazwa"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              label={formatMessage({ id: AppMessages['definitions.position.details.name'] })}
              placeholder={formatMessage({ id: AppMessages['definitions.position.details.name.placeholder'] })}
              errorMessage={error?.message}
            />
          )}
        />
      </div>

      {isDirty && (
        <Styled.ButtonsWrapper>
          <Button
            size="small"
            onClick={() => {
              reset(selectedPosition);
            }}
            color="primary"
          >
            {formatMessage({ id: AppMessages['event.details.form.button.cancel'] })}
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={isLoading}
            size="small"
            variant="contained"
            color="primary"
          >
            {formatMessage({ id: AppMessages['event.details.form.button.save'] })}
          </Button>
        </Styled.ButtonsWrapper>
      )}
    </Styled.Form>
  );
};
