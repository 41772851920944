import { useEffect } from 'react';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { modifyWantedInformation } from 'api/wanted/wanted';
import { ModifyWantedInformationBody } from 'api/wanted/wanted.types';

import { ModifyWantedInformation } from './ModifyWantedInformation';
import { ModifyWantedInformationContainerProps } from './ModifyWantedInformation.types';
import { useModifyWantedInformationValidation } from './ModifyWantedInformation.validation';

export const ModifyWantedInformationContainer = ({ owner }: ModifyWantedInformationContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onModifyWantedInformation, isLoading } = useMutation(modifyWantedInformation, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.WANTED]);

      toast.dark(formatMessage({ id: AppMessages['wanted.modify.information.success'] }));

      onModalClose();
    },
  });

  const { schema } = useModifyWantedInformationValidation();
  const formMethods = useForm<ModifyWantedInformationBody>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const onSubmit = (body: ModifyWantedInformationBody) => {
    onModifyWantedInformation({ id: owner.id, body });
  };

  useEffect(() => {
    formMethods.reset({ informacja: '' });
  }, [isModalOpen]);

  return (
    <FormProvider {...formMethods}>
      <ModifyWantedInformation
        owner={owner}
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
};
