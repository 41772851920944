import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete } from 'shared-ui';
import { debounce } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import * as Styled from './FormAutocomplete.styles';
import { FormAutocompleteProps } from './FormAutocomplete.types';

export const FormAutocomplete = <T extends { id: string }>({
  formFieldName,
  data,
  setSearchQuery,
  isLoading,
  createOption,
  handleOptionSelect,
  label,
  placeholder,
  renderCustomOption,
  required,
  disabled,
  withPopupSearchIcon,
}: FormAutocompleteProps<T>) => {
  const { formatMessage } = useLocale();

  const { watch, control } = useFormContext();

  const handleSearch = debounce((search: string) => {
    setSearchQuery(search);
  }, 300);

  const getValidOptionCreator = (option: T) =>
    createOption
      ? createOption(option)
      : {
          label: option.id,
          value: option.id,
        };

  const getOptions = () => (data ? data.map(getValidOptionCreator) : []);

  const getCurrentOption = (option?: T) => {
    if (!option) return null;

    if (typeof option === 'object') return getValidOptionCreator(option);

    return getOptions().find(({ value }) => option === value) || null;
  };

  const currentOption = getCurrentOption(watch(formFieldName));

  return (
    <Controller
      name={formFieldName}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          inputSize="big"
          onChange={(_, option) =>
            option ? field.onChange(handleOptionSelect ? handleOptionSelect(option) : option) : field.onChange('')
          }
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={currentOption}
          options={getOptions()}
          renderOption={(props, option) =>
            renderCustomOption ? (
              renderCustomOption(props, option, data)
            ) : (
              <li {...props}>
                <Styled.OptionLabel> {option.label}</Styled.OptionLabel>
              </li>
            )
          }
          loading={isLoading}
          loadingText={formatMessage({ id: AppMessages['common.loading'] })}
          noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
          onInputChange={(_, value) => handleSearch(value)}
          label={label || formatMessage({ id: AppMessages['common.search'] })}
          placeholder={placeholder || formatMessage({ id: AppMessages['common.list.placeholder'] })}
          required={required}
          disableInputChange={!!currentOption}
          disabled={disabled}
          withPopupSearchIcon={withPopupSearchIcon}
        />
      )}
    />
  );
};
