import { Controller, useFormContext } from 'react-hook-form';
import { Datepicker, Modal } from 'shared-ui';

import { ReactComponent as UpdateExpiryDate } from 'assets/icons/expiry-date.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { UpdateExpiryDateMultiCardsBody } from 'api/cards/cards.types';
import { getEndOfToday } from 'helpers/getEndOfToday';

import { UpdateExpiryDateMultiCardsProps } from './UpdateExpiryDateMultiCards.types';
import * as Styled from './UpdateExpiryDateMultiCards.styles';

export const UpdateExpiryDateMultiCards = ({
  checkedElementsLength,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
  actionIn,
  minDate,
}: UpdateExpiryDateMultiCardsProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useFormContext<Omit<UpdateExpiryDateMultiCardsBody, 'cards'>>();

  return (
    <>
      <Styled.UpdateExpiryDateButton
        variant="outlined"
        color="primary"
        size="medium"
        startIcon={<UpdateExpiryDate />}
        onClick={onModalOpen}
      >
        {formatMessage({
          id: AppMessages[
            actionIn === 'cards' ? 'cards.multiAction.expiryDate.button' : 'owners.multiAction.changeExpiry.button'
          ],
        })}
      </Styled.UpdateExpiryDateButton>

      <Modal
        header={formatMessage({
          id: AppMessages['cards.multiAction.expiryDate.title'],
        })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={isLoading || !isValid}
        submitButtonText={formatMessage({ id: AppMessages['cards.multiAction.expiryDate.actionButton'] })}
      >
        <>
          <Styled.ModalSubtitle variant="h5">
            {formatMessage({ id: AppMessages['cards.multiAction.expiryDate.subtitle'] })} {checkedElementsLength}
          </Styled.ModalSubtitle>

          <Styled.DatepickersWrapper>
            <Styled.ExpiryDatepickerWrapper>
              <Controller
                name="waznoscOd"
                control={control}
                render={({ field }) => (
                  <Datepicker
                    {...field}
                    required
                    label={formatMessage({ id: AppMessages['cards.multiAction.expiryDate.startDate.label'] })}
                    minDate={minDate}
                    withCalendarIcon
                    withDatepickerPopover
                    isClickable
                    errorMessage={errors?.waznoscOd?.message}
                    popoverOrigin={{
                      vertical: -80,
                      horizontal: 60,
                    }}
                  />
                )}
              />
            </Styled.ExpiryDatepickerWrapper>

            <Styled.ExpiryDatepickerWrapper>
              <Controller
                name="waznoscDo"
                control={control}
                render={({ field }) => (
                  <Datepicker
                    {...field}
                    label={formatMessage({ id: AppMessages['cards.multiAction.expiryDate.endDate.label'] })}
                    minDate={watch('waznoscOd') || undefined}
                    withCalendarIcon
                    withDatepickerPopover
                    isClickable
                    errorMessage={errors?.waznoscDo?.message}
                    popoverOrigin={{
                      vertical: -80,
                      horizontal: 60,
                    }}
                    customDateFormatOnOpen={getEndOfToday}
                  />
                )}
              />
            </Styled.ExpiryDatepickerWrapper>
          </Styled.DatepickersWrapper>
        </>
      </Modal>
    </>
  );
};
