import { MutationFn, UseQueryFn } from 'shared-ui';

import { PaginatedResponse } from 'core/global.types';

import { AddEventBody, Event, ExportEventsBody } from './events.types';

export const fetchEvents: UseQueryFn<unknown, PaginatedResponse<Event>> = () => {
  return {
    endpoint: `/zdarzenie`,
  };
};

export const updateEventDetails: MutationFn<{ id: string; body: Event }, void> = ({ id, body }) => {
  return {
    endpoint: `/zdarzenie/${id}`,
    method: 'PUT',
    body,
  };
};

export const addEvent: MutationFn<{ body: AddEventBody }, void> = ({ body }) => {
  return {
    endpoint: `/zdarzenie`,
    method: 'POST',
    body,
  };
};

export const exportEvents: MutationFn<{ body: ExportEventsBody }, string> = ({ body }) => {
  return {
    endpoint: `/zdarzenia/export`,
    method: 'POST',
    body,
  };
};
