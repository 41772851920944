import { ListItem as MaterialListItem, IconButton, Typography, Popper as MaterialPopper } from '@mui/material';
import { PaginationSelectIcon } from 'shared-ui';
import styled, { css } from 'styled-components';

import { Input as InputBase } from 'ui/input/Input';
import { ListItemStylesProps } from '../OrganizationsTreeSelect.types';
import { HeaderOptionStylesProps } from 'reusable/tableSearch/TableSearch.types';

const commonInputStyles = css`
  font-weight: 700;
  text-align: center;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-right: 15px !important;
`;

export const Input = styled(InputBase)(
  ({ theme: { palette }, disabled }) => css`
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 100;
      cursor: ${disabled ? 'not-allowed' : 'text'};
    }

    & .MuiFilledInput-root {
      background-color: ${palette.grey[100]};
      color: ${palette.text.primary};
      ${commonInputStyles};
    }

    & .MuiOutlinedInput-root {
      ${commonInputStyles};
    }

    & .MuiChip-root {
      background-color: ${palette.grey[300]};
      height: 28px;
      max-width: 150px;
    }

    & span.MuiAutocomplete-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      border-radius: 16px;
      background-color: ${palette.grey[300]};
      height: 28px;
    }

    & .MuiChip-deleteIcon {
      display: none;
    }

    & .MuiFormLabel-root {
      top: -2px;
    }
  `,
);

export const SelectArrow = styled(PaginationSelectIcon)`
  margin-right: -5px;
`;

export const ListItemWrapper = styled.div(
  ({ theme: { palette } }) => css`
    border-bottom: 1px solid ${palette.grey[500]};

    &:last-child {
      border-bottom: none;
    }
  `,
);

export const ListItem = styled(MaterialListItem)<ListItemStylesProps>(
  ({ theme: { palette }, $isFirstLevel }) => css`
    padding: ${$isFirstLevel ? '7px 2px' : '7px 25px'};
    border-bottom: 1px solid ${palette.grey[500]};
    cursor: pointer;
    position: relative;
    color: ${palette.grey[900]};
    display: flex;
    justify-content: space-between;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      color: ${palette.primary.main};
    }
  `,
);

export const ExtraGroupOption = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 5px;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 5px;
`;

export const SubOption = styled.div`
  display: flex;
  align-items: center;
  padding-left: 35px;
  gap: 5px;
`;

const iconButtonCommonStyles = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const RightIconButton = styled(IconButton)`
  ${iconButtonCommonStyles};
  right: 3px;
`;

export const LeftIconButton = styled(IconButton)`
  ${iconButtonCommonStyles};
  left: 0;
`;

export const SelectedOptionsNumberCircle = styled.div<ListItemStylesProps>(
  ({ theme: { palette }, $isFirstLevel }) => css`
    width: 27px;
    height: 24px;
    background-color: ${palette.primary.main};
    border-radius: 50%;
    margin-right: ${$isFirstLevel ? 45 : 22}px;
    position: relative;
  `,
);

export const SelectedOptionsNumber = styled(Typography)(
  ({ theme: { palette } }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    color: ${palette.common.white};
  `,
);

const treeOptionTextCommonStyles = css`
  font-size: 12px;
  max-width: 160px;
`;

export const TreeOptionText = styled(Typography)`
  ${treeOptionTextCommonStyles}
`;

export const TreeOptionTextBold = styled(Typography)`
  ${treeOptionTextCommonStyles}
  font-weight: 700;
`;

export const CellHeaderItem = styled.div<{ $isActive: boolean }>(
  ({ $isActive, theme: { palette, shape } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    padding: 0 10px;
    margin-left: -10px;
    border: 1px solid ${$isActive ? palette.grey[500] : 'transparent'};
    border-radius: ${shape.borderRadius}px;
    cursor: pointer;
  `,
);

export const Tag = styled.span(
  ({ theme: { palette } }) => css`
    display: flex;
    align-items: center;
    align-items: center;
    padding: 6px 16px;
    border-radius: 16px;
    background-color: ${palette.grey[300]};
    height: 28px;
    margin-left: 5px;
  `,
);

export const Popper = styled(MaterialPopper)(
  ({ theme: { palette } }) => css`
    background-color: ${palette.grey.A200};
    border: 1px solid ${palette.grey[500]};
    max-height: 325px;
    border-radius: 8px;
    overflow: auto;
  `,
);

export const PopupHeaderWrapper = styled.div(
  ({ theme: { palette } }) => css`
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 6px;
    border-bottom: 1px solid ${palette.grey[500]};
    border-bottom: 1px solid ${palette.grey[500]};
    background-color: ${palette.grey.A200};
    z-index: 10;
  `,
);

export const HeaderOption = styled.span<HeaderOptionStylesProps>(
  ({ theme: { palette }, $isActive, $isDisabled }) => css`
    color: ${$isActive ? palette.primary.main : palette.grey[900]};
    background-color: ${$isActive && palette.common.white};
    cursor: ${$isDisabled ? 'not-allowed' : 'pointer'};
    pointer-events: ${$isDisabled ? 'none' : 'auto'};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 9px 14px;
    font-weight: 700;
  `,
);
