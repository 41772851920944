import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import {
  YesNoFilterItemsDescriptionEnum,
  useYesNoFilterItems,
} from 'hooks/useYesNoHeaderFilterItems/useYesNoFilterItems';
import { GlcNameSortEnum, GlcNumberSortEnum, GlcQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { Table } from 'ui/table/Table';

import { GlcTableHeaderProps } from './GlcTableHeader.types';

export const GlcTableHeader = ({ glcQueryLocalStorageName }: GlcTableHeaderProps) => {
  const { formatMessage } = useLocale();
  const { yesNoFilterItems } = useYesNoFilterItems();

  return (
    <>
      <Table.HeaderSortItem
        queryHeader={formatMessage({ id: AppMessages['glc.table.header.id'] })}
        queryKey={glcQueryLocalStorageName}
        sortBy={GlcNumberSortEnum}
      />

      <Table.HeaderSortItem
        queryHeader={formatMessage({ id: AppMessages['glc.table.header.name'] })}
        queryKey={glcQueryLocalStorageName}
        sortBy={GlcNameSortEnum}
      />

      <Table.HeaderFilterItem
        queryKey={glcQueryLocalStorageName}
        queryName={GlcQueryKeysEnum.GROUP_PERMISSIONS}
        filterItems={yesNoFilterItems}
        queryHeader={formatMessage({ id: AppMessages['glc.table.header.groupPermissions'] })}
        queryHeaderDescriptions={YesNoFilterItemsDescriptionEnum}
      />

      <Table.HeaderFilterItem
        queryKey={glcQueryLocalStorageName}
        queryName={GlcQueryKeysEnum.READER}
        filterItems={yesNoFilterItems}
        queryHeader={formatMessage({ id: AppMessages['glc.table.header.reader'] })}
        queryHeaderDescriptions={YesNoFilterItemsDescriptionEnum}
      />
    </>
  );
};
