import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useErrorHandler, APIError, ClientError, useQueryClient, useLoaderToast } from 'shared-ui';

import { addGroup } from 'api/groups/groups';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { GroupTypeEnum } from 'api/groups/groups.enum';
import { DEFAULT_PERMISSIONS } from 'core/global.variables';
import { useResetPermissionsFormValues } from 'hooks/useResetPermissionsFormValues/useResetPermissionsFormValues';
import { useModalState } from 'hooks/useModalState/useModalState';

import { useAddGroupValidation } from './AddGroup.validation';
import { AddGroupFormBody } from './AddGroup.types';
import { GlcValueEnum } from './AddGroup.enum';
import { AddGroup } from './AddGroup';

export const AddGroupContainer = () => {
  const { formatMessage } = useLocale();
  const { schema } = useAddGroupValidation();
  const queryClient = useQueryClient();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { handleError } = useErrorHandler();

  const { load: loadAddGroup, update: updateLoadAddGroup, close: closeLoadAddGroup } = useLoaderToast('addGroup');

  const { mutate: onAddGroup, isLoading: isLoadingAddGroup } = useMutation(addGroup, {
    onMutate: () => loadAddGroup(formatMessage({ id: AppMessages['groups.addGroup.loader'] })),
    onSuccess: (_, { body }) => {
      queryClient.invalidateQueries([QueryKeyEnum.GROUPS]);
      onModalClose();

      updateLoadAddGroup(
        formatMessage({
          id: AppMessages[
            body.typ === GroupTypeEnum.DYNAMICZNA
              ? 'groups.addGroup.dynamic.successMessage'
              : 'groups.addGroup.static.successMessage'
          ],
        }),
      );
    },
    onError: (error: ClientError<APIError>) => {
      const errorFields = error.response?.data.fields;

      const isGroupIdExistsError = errorFields?.find(({ type, field }) => type === 'exists' && field === 'idGrupy');

      if (isGroupIdExistsError) {
        closeLoadAddGroup();
        return methods.setError('idGrupy', {
          message: formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.validation.id'] }),
        });
      }

      handleError({ error, omitAPIErrorMessage: true, updateToastCallback: updateLoadAddGroup });
    },
  });
  const methods = useForm<AddGroupFormBody>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      ...DEFAULT_PERMISSIONS,
      tkds: [],
      idGlcs: [],
      typ: GroupTypeEnum.DYNAMICZNA,
      glc: GlcValueEnum.NO,
    },
  });

  const onSubmit = (body: AddGroupFormBody) => {
    onAddGroup({
      body: {
        ...body,
        pkds: body.pkds?.map(({ value }) => ({ id: `${value}` })),
        tkds: body.tkds?.map(({ value }) => ({ id: `${value}` })),
        idGlcs: body.idGlcs?.map(({ value }) => ({ id: `${value}` })),
        glc: body.glc === GlcValueEnum.YES ? true : false,
      },
    });
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen]);

  useEffect(() => {
    methods.setValue('pkds', undefined);
    methods.setValue('komorki', undefined);
  }, [methods.watch('typ')]);

  useResetPermissionsFormValues({
    selectedValues: methods.watch('tkds') || methods.watch('idGlcs'),
    setFormValue: methods.setValue,
  });

  return (
    <FormProvider {...methods}>
      <AddGroup
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        onSubmit={onSubmit}
        isLoadingAddGroup={isLoadingAddGroup}
      />
    </FormProvider>
  );
};
