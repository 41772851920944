import { useFormContext } from 'react-hook-form';

import { addOwnerPhoto } from 'api/owners/owners';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { OwnerPhoto } from './OwnerPhoto';
import { OwnerPhotoContainerProps } from './OwnerPhoto.types';

export const OwnerPhotoContainer = ({ isEditDisabled, isFetchingOwnerPhoto }: OwnerPhotoContainerProps) => {
  const { setValue } = useFormContext();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onAddOwnerPhoto } = useMutation(addOwnerPhoto, {
    onSuccess: (data, body) => {
      if (!data.zdjecieMime && !data.zdjecieUrl) return;

      setValue('photoFile', body.file, { shouldDirty: true });
      setValue('zdjecieMime', data.zdjecieMime);
      setValue('zdjecieUrl', data.zdjecieUrl);
      onModalClose();
    },
  });

  const onRemovePhoto = () => {
    setValue('photoFile', undefined, { shouldDirty: true });
    setValue('zdjecieMime', '');
    setValue('zdjecieUrl', '');
  };

  const onSubmitAddOwnerPhoto = async (file: Blob | undefined) => {
    if (!file) return;

    onAddOwnerPhoto({ file });
  };

  return (
    <OwnerPhoto
      onSubmitAddOwnerPhoto={onSubmitAddOwnerPhoto}
      isModalOpen={isModalOpen}
      onModalOpen={onModalOpen}
      onModalClose={onModalClose}
      onRemovePhoto={onRemovePhoto}
      isEditDisabled={isEditDisabled}
      isFetchingOwnerPhoto={isFetchingOwnerPhoto}
    />
  );
};
