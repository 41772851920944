import { DeleteRandomControlTerminalsContainer } from './deleteRandomControlTerminals/DeleteRandomControlTerminalsContainer';
import { RandomControlTerminalsMultiActionsProps } from './RandomControlTerminalsMultiActions.types';
import * as Styled from './RandomControlTerminalsMultiActions.styles';

export const RandomControlTerminalsMultiActions = ({
  controlId,
  checkedTerminals,
  setCheckedTerminals,
  terminalsCount,
}: RandomControlTerminalsMultiActionsProps) => {
  return (
    <Styled.Container>
      <DeleteRandomControlTerminalsContainer
        controlId={controlId}
        terminalsCount={terminalsCount}
        checkedTerminals={checkedTerminals}
        setCheckedTerminals={setCheckedTerminals}
      />
    </Styled.Container>
  );
};
