import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { archiveGroup } from 'api/groups/groups';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useSelectedGroup } from 'hooks/useSelectedGroup/useSelectedGroup';
import { useModalState } from 'hooks/useModalState/useModalState';

import { ArchiveGroup } from './ArchiveGroup';
import { ArchiveGroupContainerProps } from './ArchiveGroup.types';

export const ArchiveGroupContainer = ({ selectedGroup }: ArchiveGroupContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { setSelectedGroup } = useSelectedGroup();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onArchiveGroup, isLoading } = useMutation(archiveGroup, {
    onSuccess: () => {
      setSelectedGroup(undefined);
      queryClient.invalidateQueries([QueryKeyEnum.GROUPS]);
      toast.dark(formatMessage({ id: AppMessages['group.archive.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onArchiveGroup(selectedGroup.id);
  };

  return (
    <ArchiveGroup
      selectedGroup={selectedGroup}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
