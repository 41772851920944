import styled from 'styled-components';

export const Form = styled.form`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 45px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 8px;
`;
