import { useEffect } from 'react';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { updateCardDetails } from 'api/cards/cards';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { QueryKeyEnum } from 'core/global.enum';
import { useMutation } from 'hooks/useMutation/useMutation';
import { AppRouteWithParams } from 'routing/AppRoute.enum';
import { useMinValidationDate } from 'hooks/useMinValidationDate/useMinValidationDate';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';

import { CardDetailsEdit } from './CardDetailsEdit';
import { CardDetailsEditContainerProps, CardDetailsEditForm } from './CardDetailsEdit.types';
import { useCardDetailsEditValidation } from './CardDetailsEdit.validation';

export const CardDetailsEditContainer = ({ selectedCard }: CardDetailsEditContainerProps) => {
  const { formatMessage } = useLocale();
  const { setSelectedCard } = useSelectedCard();
  const isHistoryView = useIsExactPath(AppRouteWithParams.cardHistory.path);

  const queryClient = useQueryClient();

  const { mutate: onUpdateCard, isLoading } = useMutation(updateCardDetails, {
    onSuccess: (_, { body }) => {
      setSelectedCard({ ...selectedCard, ...body });
      queryClient.invalidateQueries([isHistoryView ? QueryKeyEnum.CARD_HISTORY : QueryKeyEnum.CARDS]);

      selectedCard.pin && body.pin === null
        ? toast.dark(formatMessage({ id: AppMessages['card.details.form.pin.success'] }))
        : toast.dark(formatMessage({ id: AppMessages['card.details.form.success'] }));

      formMethods.reset({
        ...selectedCard,
        waznoscOd: (selectedCard.waznoscOd as Date) || '',
        waznoscDo: (selectedCard.waznoscDo as Date) || '',
        dataZwrotu: (selectedCard.dataZwrotu as Date) || '',
      });
    },
  });

  const { minValidationDate } = useMinValidationDate();
  const { schema } = useCardDetailsEditValidation(selectedCard.waznoscOd, minValidationDate);
  const formMethods = useForm<CardDetailsEditForm>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      ...selectedCard,
      waznoscOd: (selectedCard.waznoscOd as Date) || '',
      waznoscDo: (selectedCard.waznoscDo as Date) || '',
      dataZwrotu: (selectedCard.dataZwrotu as Date) || '',
    },
  });

  useEffect(() => {
    !formMethods.formState.isDirty &&
      formMethods.reset({
        ...selectedCard,
        waznoscOd: (selectedCard.waznoscOd as Date) || '',
        waznoscDo: (selectedCard.waznoscDo as Date) || '',
        dataZwrotu: (selectedCard.dataZwrotu as Date) || '',
      });
  }, [selectedCard]);

  const onSubmit = (body: CardDetailsEditForm) => {
    onUpdateCard({
      id: selectedCard.id,
      body: {
        ...selectedCard,
        ...body,
        waznoscOd: (body.waznoscOd as Date) || null,
        waznoscDo: body.waznoscDo as Date,
        dataZwrotu: (body.dataZwrotu as Date) || null,
      },
    });
  };

  const deleteCardPin = () => {
    onUpdateCard({ id: selectedCard.id, body: { ...selectedCard, pin: null } });
  };

  return (
    <FormProvider {...formMethods}>
      <CardDetailsEdit
        selectedCard={selectedCard}
        onSubmit={onSubmit}
        isLoading={isLoading}
        setSelectedCard={setSelectedCard}
        deleteCardPin={deleteCardPin}
      />
    </FormProvider>
  );
};
