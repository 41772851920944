import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import {
  CardNumberSortEnum,
  EventDateSortEnum,
  IdTkdSortEnum,
  OwnerSortEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { Messages } from 'core/global.types';
import { Table } from 'ui/table/Table';
import { SortBy } from 'ui/table/headerSortItem/HeaderSortItem.types';

import { EventsTableHeaderProps } from './EventsTableHeader.types';

export const EventsTableHeader = ({ isHistoryView, displayFullTable }: EventsTableHeaderProps) => {
  const { formatMessage } = useLocale();

  const createSortableElement = (sortBy: SortBy, headerTitle: Messages) => {
    if (isHistoryView) {
      return (
        <Table.HeaderSortItem
          queryKey={QueryKeyLocalStorageEnum.EVENTS_HISTORY}
          sortBy={sortBy}
          queryHeader={formatMessage({ id: AppMessages[headerTitle] })}
        />
      );
    } else {
      return <Table.HeaderItem>{formatMessage({ id: AppMessages[headerTitle] })}</Table.HeaderItem>;
    }
  };

  return (
    <>
      {createSortableElement(IdTkdSortEnum, 'events.current.header.id')}

      {createSortableElement(CardNumberSortEnum, 'events.current.header.card')}

      {displayFullTable && createSortableElement(OwnerSortEnum, 'events.current.header.owner')}

      <Table.HeaderItem>{formatMessage({ id: AppMessages['events.current.header.mod'] })} </Table.HeaderItem>

      <Table.HeaderItem>{formatMessage({ id: AppMessages['events.current.header.mode'] })} </Table.HeaderItem>

      <Table.HeaderItem>{formatMessage({ id: AppMessages['events.current.header.rcp'] })} </Table.HeaderItem>

      <Table.HeaderItem>{formatMessage({ id: AppMessages['events.current.header.control'] })} </Table.HeaderItem>

      <Table.HeaderItem>{formatMessage({ id: AppMessages['events.current.header.wanted'] })} </Table.HeaderItem>

      {createSortableElement(EventDateSortEnum, 'events.current.header.date')}
    </>
  );
};
