import { Typography, IconButton } from '@mui/material';
import styled, { css } from 'styled-components';

import { HeaderOptionStylesProps, SearchWrapperStylesProps } from './TableSearch.types';

export const TableSearchWrapper = styled.div<SearchWrapperStylesProps>(
  ({ $withoutToggle }) => css`
    min-width: ${!$withoutToggle && '320px'};
  `,
);

export const OptionNumber = styled(Typography)`
  font-size: 12px;
`;

export const OptionLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
`;

export const PopupHeaderWrapper = styled.div(
  ({ theme: { palette } }) => css`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 6px;
    border-bottom: 1px solid ${palette.grey[500]};
  `,
);

export const HeaderOption = styled.span<HeaderOptionStylesProps>(
  ({ theme: { palette }, $isActive, $isDisabled }) => css`
    color: ${$isActive ? palette.primary.main : palette.grey[900]};
    background-color: ${$isActive && palette.common.white};
    cursor: ${$isDisabled ? 'not-allowed' : 'pointer'};
    pointer-events: ${$isDisabled ? 'none' : 'auto'};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 9px 14px;
    font-weight: 700;
  `,
);

export const IconWrapper = styled.div`
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 9px;
`;

export const PositionLabel = styled(Typography)`
  padding: 8px 6px;
  font-weight: 700;
`;

export const NotFoundLabel = styled.div`
  padding: 10px 8px;
`;

export const SearchButton = styled(IconButton)`
  margin-right: -5px;
`;
