import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { JUST_LETTERS_REGEX } from 'core/global.variables';

export const useAddUserValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    username: yup
      .string()
      .min(3, formatMessage({ id: AppMessages['users.addUser.validation.field.name'] }))
      .max(30, formatMessage({ id: AppMessages['users.addUser.validation.field.name.max'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    status: yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] })),
    typ: yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] })),
    password: yup
      .string()
      .min(8, formatMessage({ id: AppMessages['users.addUser.validation.field.password'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    passwordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        formatMessage({ id: AppMessages['users.addUser.validation.field.passwordConfirmation'] }),
      ),
    imie: yup
      .string()
      .min(3, formatMessage({ id: AppMessages['users.addUser.validation.field.firstName'] }))
      .max(32, formatMessage({ id: AppMessages['users.addUser.validation.field.firstName.max'] }))
      .matches(JUST_LETTERS_REGEX, formatMessage({ id: AppMessages['common.validation.letters'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    nazwisko: yup
      .string()
      .min(3, formatMessage({ id: AppMessages['users.addUser.validation.field.lastName'] }))
      .max(32, formatMessage({ id: AppMessages['users.addUser.validation.field.lastName.max'] }))
      .matches(JUST_LETTERS_REGEX, formatMessage({ id: AppMessages['common.validation.letters'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    email: yup
      .string()
      .email(formatMessage({ id: AppMessages['users.addUser.validation.field.email'] }))
      .optional(),
    opis: yup
      .string()
      .max(60, formatMessage({ id: AppMessages['users.addUser.validation.field.description.max'] }))
      .optional(),
  });

  return { schema };
};
