import { splitTextByWidth } from './splitTextByWidth';

export const splitTextByNewLines = (value: string, maxWidth: number): string[] => {
  if (!value) return [];

  return value.split('\n').flatMap((line) => {
    if (line.trim() === '') {
      return [''];
    }

    const linesByWidth = splitTextByWidth(line, maxWidth);

    return linesByWidth;
  });
};
