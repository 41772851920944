import { FormControl } from '@mui/material';
import { Select, TopBar } from 'shared-ui';
import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TopBarWrapper = styled(TopBar)`
  padding-bottom: 12px;
`;

export const RefreshSelectFormControl = styled(FormControl)`
  min-width: 200px;
`;

export const RefreshSelect = styled(Select)`
  width: 200px;
  font-weight: 700;
  padding-top: 22px;
  padding-bottom: 5px;
`;
