import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { deleteWanted } from 'api/wanted/wanted';

import { DeleteWanted } from './DeleteWanted';
import { DeleteWantedContainerProps } from './DeleteWanted.types';

export const DeleteWantedContainer = ({ owner }: DeleteWantedContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();

  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteWanted, isLoading } = useMutation(deleteWanted, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.WANTED]);
      queryClient.invalidateQueries([QueryKeyEnum.OWNERS_SEARCH]);
      toast.dark(formatMessage({ id: AppMessages['wanted.delete.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteWanted(owner.id);
  };

  return (
    <DeleteWanted
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
      owner={owner}
    />
  );
};
