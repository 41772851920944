import { GroupTypeDescriptionEnum } from 'app/groups/Groups.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { GroupsQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { Table } from 'ui/table/Table';

import { OwnerGroupsTableHeader } from './OwnerGroupsTableHeader/OwnerGroupsTableHeader';
import { OwnerGroupsProps } from './OwnerGroups.types';
import * as Styled from './OwnerGroups.styles';

export const OwnerGroups = ({ groupsResponse, page, setPage, rowsPerPage, setRowsPerPage }: OwnerGroupsProps) => {
  const { formatMessage } = useLocale();

  return (
    <Table
      pagination={{
        page,
        setPage,
        count: groupsResponse?.count || 0,
        rowsPerPage,
        setRowsPerPage,
      }}
      gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
      data={groupsResponse?.data || []}
      nothingToShowText={formatMessage({ id: AppMessages['groups.owner.table.nothingToShow'] })}
      renderHeader={() => <OwnerGroupsTableHeader />}
      renderRow={(group) => (
        <>
          <Table.RowItem>{group.idGrupy}</Table.RowItem>
          <Table.RowItem>{group.nazwa}</Table.RowItem>
          <Table.RowItem>{formatMessage({ id: GroupTypeDescriptionEnum[group.typ] })}</Table.RowItem>

          <Table.RowItem>
            {group.glc
              ? formatMessage({ id: AppMessages['common.yes'] }).toUpperCase()
              : formatMessage({ id: AppMessages['common.no'] }).toUpperCase()}
          </Table.RowItem>

          <Table.RowItem>
            <Styled.Link
              to={{
                pathname: AppRoute.groups,
                search: `?${GroupsQueryKeysEnum.QUERY}=${group.id}`,
              }}
            >
              <Styled.OwnerGroupDefinitionLink>
                {formatMessage({ id: AppMessages['groups.owner.table.link.groupDefinition'] })}
              </Styled.OwnerGroupDefinitionLink>
            </Styled.Link>
          </Table.RowItem>
        </>
      )}
    />
  );
};
