import { ForwardedRef, forwardRef, useRef, useMemo, useCallback } from 'react';
import { useMeasure } from 'react-use';

import { HighlightedTextInputProps, Keyword } from './KeywordsInput.types';
import { calculateTextWidth } from './utils/calculateTextWidth';
import { splitTextByNewLines } from './utils/splitTextByNewLines';
import { findKeywordBoundaries } from './utils/findKeywordBoundaries';
import { getIsCursorInsideKeyword } from './utils/getIsCursorInsideKeyword';
import { checkWordSpecialCharsWidth } from './utils/checkWordSpecialCharsWidth';
import * as Styled from './KeywordsInput.styles';

const KEYWORD_HEIGHT = 22;
const INPUT_X_PADDING = 24;
const FIRST_TEXT_LINE_HEIGHT = 12;
const TEXT_LINE_HEIGHT = 25.5;
const INPUT_LABEL_HEIGHT = 28;
const INPUT_PLACEHOLDER_HEIGHT = 10;
const SPECIAL_CHARS_REGEX = /(?!s)[.,?!""''+=()]/g;

export const KeywordsInput = forwardRef(
  ({ value, onChange, keywords, ...inputProps }: HighlightedTextInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const keywordsRef = useRef<HTMLDivElement>(null);

    const [inputWrapperRef, { width }] = useMeasure<HTMLDivElement>();

    const keywordNames = keywords.map((word) => word.name);

    const splittedTextByNewLines = splitTextByNewLines(value, width - INPUT_X_PADDING);
    const allWordsFromTextArr = splittedTextByNewLines.join(' ').split(' ');

    const replaceSpecialChars = (word: string): string => word.replace(SPECIAL_CHARS_REGEX, '');

    const usedKeywords = useMemo(
      () => allWordsFromTextArr.filter((word) => keywordNames.includes(replaceSpecialChars(word))),
      [value],
    );

    const getIsKeywordUsed = useCallback(
      (keywordValue: string) => usedKeywords.map((word) => replaceSpecialChars(word.trim())).includes(keywordValue),
      [usedKeywords],
    );

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      setTimeout(() => {
        const target = e.target as HTMLInputElement;
        const cursorPosition = target.selectionStart || 0;
        const currentInputValue = target.value;

        const currentWordData = findKeywordBoundaries(cursorPosition, currentInputValue, keywordNames);

        if (currentWordData) {
          const { word, end } = currentWordData;

          const isKeyword = keywordNames.includes(word);

          const isCoursorInsideKeyword = getIsCursorInsideKeyword(cursorPosition, currentInputValue, keywordNames);

          if (isKeyword && isCoursorInsideKeyword) {
            target.setSelectionRange(end, end);
          }
        }
      }, 0);

      const target = e.target as HTMLInputElement;
      const cursorPosition = target.selectionStart || 0;
      const cursorPositionEnd = target.selectionEnd || 0;
      const currentInputValue = target.value;

      const currentWordData = findKeywordBoundaries(cursorPosition, currentInputValue, keywordNames);

      if (currentWordData) {
        const { word, start, end } = currentWordData;

        const isKeyword = keywordNames.includes(word);

        if (isKeyword) {
          switch (e.key) {
            case 'ArrowRight':
              if (cursorPosition === start) {
                e.preventDefault();
                target.setSelectionRange(end, end);
              }
              break;

            case 'ArrowLeft':
              if (cursorPosition === end) {
                e.preventDefault();
                target.setSelectionRange(start, start);
              }
              break;

            case 'Backspace':
              if (cursorPosition !== cursorPositionEnd) {
                return;
              }

              e.preventDefault();
              const newValue = value.substring(0, start) + value.substring(end);
              onChange(newValue);

              target.setSelectionRange(start, start);
              inputRef.current?.focus();
              break;
          }
        }
      }
    };

    const removeKeyword = (text: string, keyword: string) => {
      const regex = new RegExp(keyword, 'g');
      return text.replace(regex, '');
    };

    const handleClickKeyword = (keyword: Keyword) => {
      const input = inputRef.current;
      if (!input) return;

      const cursorPosition = input.selectionStart || 0;

      const isEmptySpaceBefore =
        value[cursorPosition - 1] === ' ' ||
        value[cursorPosition - 1] === '\n' ||
        value[cursorPosition - 1] === undefined;
      const isEmptySpaceAfter = value[cursorPosition] === ' ' || value[cursorPosition] === undefined;
      const isInputFocused = input === document.activeElement;

      const setNewInnerValue = (addedKeywordName: string) => {
        const currentCursorPosition = isInputFocused ? cursorPosition : value.length;

        const isKeywordUsed = getIsKeywordUsed(name);

        if (isKeywordUsed) {
          const newValue = removeKeyword(value, !value.includes(addedKeywordName) ? name : addedKeywordName);

          onChange(newValue);
        } else {
          onChange(value.slice(0, currentCursorPosition) + addedKeywordName + value.slice(currentCursorPosition));

          const newCursorPosition = currentCursorPosition + addedKeywordName.length;

          setTimeout(() => {
            input.setSelectionRange(newCursorPosition, newCursorPosition);
          }, 0);
        }
      };

      const { name } = keyword;

      if (!isInputFocused && value) {
        setNewInnerValue(` ${name}`);
      } else if (!isEmptySpaceBefore && !isEmptySpaceAfter) {
        setNewInnerValue(` ${name} `);
      } else if (isEmptySpaceBefore) {
        setNewInnerValue(`${name} `);
      } else if (!isEmptySpaceBefore) {
        setNewInnerValue(` ${name}`);
      } else {
        setNewInnerValue(`${name}`);
      }

      input.focus();
    };

    const inputLabelHeight = !!inputProps.label ? INPUT_LABEL_HEIGHT : 0;
    const inputPlaceholderHeight = !!inputProps.errorMessage ? INPUT_PLACEHOLDER_HEIGHT : 0;

    const getTextLineTopPosition = (index: number) => {
      return index === 0
        ? FIRST_TEXT_LINE_HEIGHT + inputLabelHeight + inputPlaceholderHeight
        : index * TEXT_LINE_HEIGHT + FIRST_TEXT_LINE_HEIGHT + inputLabelHeight + inputPlaceholderHeight;
    };

    return (
      <>
        <Styled.InputWrapper ref={inputWrapperRef}>
          {splittedTextByNewLines.map((newLineText, index) => {
            return (
              <Styled.KeywordsWrapper
                ref={keywordsRef}
                style={{
                  height: TEXT_LINE_HEIGHT,
                  top: getTextLineTopPosition(index),
                }}
              >
                {newLineText.split(' ').map((word, i) => {
                  const wordWithoutSpecialChars = replaceSpecialChars(word);

                  const wordWidth = calculateTextWidth(word);
                  const wordWithoutSpecialChartWidth = calculateTextWidth(wordWithoutSpecialChars);
                  const emptySpaceWidth = calculateTextWidth(' ');
                  const marginLeft = i !== 0 ? emptySpaceWidth : 0;

                  const { beginSpecialCharWidth, endSpecialCharWidth } = checkWordSpecialCharsWidth(word);

                  const backgroundColor =
                    keywords.find(({ name }) => wordWithoutSpecialChars === name)?.backgroundColor || '';

                  return keywordNames.includes(wordWithoutSpecialChars) ? (
                    <span
                      key={wordWithoutSpecialChars + i}
                      style={{
                        marginLeft,
                      }}
                    >
                      <Styled.Keyword
                        $word={wordWithoutSpecialChars}
                        $width={wordWithoutSpecialChartWidth}
                        $height={KEYWORD_HEIGHT}
                        $backgroundColor={backgroundColor}
                        style={{
                          marginRight: endSpecialCharWidth,
                          marginLeft: beginSpecialCharWidth,
                        }}
                      >
                        {wordWithoutSpecialChars}
                      </Styled.Keyword>
                    </span>
                  ) : (
                    <span
                      key={word + i}
                      style={{
                        width: wordWidth,
                        marginLeft,
                      }}
                    >
                      {word}
                    </span>
                  );
                })}
              </Styled.KeywordsWrapper>
            );
          })}

          <Styled.InputField
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={handleKeyDown}
            {...inputProps}
            multiline
            ref={ref}
            inputProps={{
              ref: inputRef,
            }}
          />
        </Styled.InputWrapper>

        <Styled.BottomKeywordsWrapper>
          {keywords.map((keyword) => {
            const isKeywordUsed = getIsKeywordUsed(keyword.name);
            const width = calculateTextWidth(keyword.name);

            return (
              <Styled.Keyword
                key={keyword.name}
                $width={width}
                $height={KEYWORD_HEIGHT}
                $backgroundColor={keyword.backgroundColor}
                $disabled={isKeywordUsed}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  handleClickKeyword(keyword);
                }}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onPointerDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onFocus={(e) => {
                  e.preventDefault();
                }}
              >
                {keyword.name}
              </Styled.Keyword>
            );
          })}
        </Styled.BottomKeywordsWrapper>
      </>
    );
  },
);
