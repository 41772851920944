import { useState } from 'react';
import { useQueryClient } from 'shared-ui';

import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import { SelectedAnnouncementContext } from '../selectedAnnouncementContext/SelectedAnnouncementContext';
import { Announcement } from 'api/announcements/announcements.types';
import { fetchAnnouncementDetails } from 'api/announcements/announcements';

import { SelectedAnnouncementContextControllerProps } from './SelectedAnnouncementContextController.types';

export const SelectedAnnouncementContextController = ({ children }: SelectedAnnouncementContextControllerProps) => {
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState<string>();
  const queryClient = useQueryClient();

  const { data: selectedAnnouncement } = useQuery(
    [QueryKeyEnum.ANNOUNCEMENT_DETAILS, selectedAnnouncementId],
    () => fetchAnnouncementDetails(selectedAnnouncementId),
    {
      enabled: !!selectedAnnouncementId,
    },
  );

  const setSelectedAnnouncement = (announcement: Announcement | undefined, likeClickAction?: boolean) => {
    if (!announcement || (announcement.id === selectedAnnouncement?.id && selectedAnnouncementId && likeClickAction)) {
      selectedAnnouncement &&
        queryClient.setQueryData([QueryKeyEnum.ANNOUNCEMENT_DETAILS, selectedAnnouncement.id], null);

      return setSelectedAnnouncementId(undefined);
    }

    setSelectedAnnouncementId(announcement.id);

    queryClient.setQueryData([QueryKeyEnum.ANNOUNCEMENT_DETAILS, announcement.id], announcement);
    queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENT_DETAILS, announcement.id]);
  };

  const resetSelectedAnnouncement = () => {
    setSelectedAnnouncementId(undefined);
    setSelectedAnnouncement(undefined);
  };

  return (
    <SelectedAnnouncementContext.Provider
      value={{
        selectedAnnouncementId,
        setSelectedAnnouncementId,
        selectedAnnouncement,
        setSelectedAnnouncement,
        resetSelectedAnnouncement,
      }}
    >
      {children}
    </SelectedAnnouncementContext.Provider>
  );
};
