import { useContext } from 'react';

import { SelectedOwnerContext } from 'context/selectedOwner/selectedOwnerContext/SelectedOwnerContext';

export const useSelectedOwner = () => {
  const context = useContext(SelectedOwnerContext);

  if (context === undefined) {
    throw new Error('SelectedOwnerContext is unavailable, make sure you are using SelectedOwnerContextController');
  }

  return context;
};
