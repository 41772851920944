import styled from 'styled-components';
import MaterialTreeView from '@mui/lab/TreeView';

export const TreeView = styled(MaterialTreeView)`
  height: auto;
  flex-grow: 1;
  max-width: auto;
  overflow-y: auto;

  && > li > div {
    padding-left: 0;
    display: grid;
    grid-template-columns: 70px 1fr;

    & .MuiTreeItem-iconContainer {
      width: 100%;
      margin: 0;
    }
  }
`;
