import { useNavigate } from 'react-router-dom';

import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { AppRouteWithParams } from 'routing/AppRoute.enum';
import { ActionButton } from 'ui/actionButton/ActionButton';

import { DeleteOwnerContainer } from './deleteOwner/DeleteOwnerContainer';
import { ArchiveOwnerContainer } from './archiveOwner/ArchiveOwnerContainer';
import { UnarchiveOwnerContainer } from './unarchiveOwner/UnarchiveOwnerContainer';
import * as Styled from './OwnerActions.styles';

export const OwnerActions = () => {
  const { selectedOwner, setSelectedOwner } = useSelectedOwner();
  const navigate = useNavigate();
  const isArchivedOwner = !!selectedOwner?.archiwalny;
  const isArchiveAvailable = !isArchivedOwner && !selectedOwner?.kads?.length;

  if (!selectedOwner) return null;

  return (
    <Styled.ActionButtonWrapper>
      {!isArchivedOwner && (
        <>
          <ActionButton
            type="permissions"
            onClick={() =>
              navigate(
                AppRouteWithParams.ownerPermissionsEffective.get({
                  id: selectedOwner.id,
                }),
              )
            }
          />

          {isArchiveAvailable && (
            <ArchiveOwnerContainer selectedOwner={selectedOwner} setSelectedOwner={setSelectedOwner} />
          )}
        </>
      )}

      {isArchivedOwner && <UnarchiveOwnerContainer selectedOwner={selectedOwner} setSelectedOwner={setSelectedOwner} />}

      {selectedOwner?.canBeDeleted && <DeleteOwnerContainer selectedOwner={selectedOwner} />}

      <ActionButton
        type="history"
        onClick={() => navigate(AppRouteWithParams.ownerHistory.get({ id: selectedOwner.id }))}
      />
    </Styled.ActionButtonWrapper>
  );
};
