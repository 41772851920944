import * as yup from 'yup';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

export const usePinCodeAssignValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    pin: yup
      .string()
      .required(formatMessage({ id: AppMessages['card.assign.pin.validation.required'] }))
      .min(4, formatMessage({ id: AppMessages['card.assign.pin.validation.format'] })),
    pinConfirm: yup
      .string()
      .required(formatMessage({ id: AppMessages['card.assign.pin.validation.required'] }))
      .oneOf([yup.ref('pin'), null], formatMessage({ id: AppMessages['card.assign.pin.validation.comparision'] })),
  });

  return { schema };
};
