import { useLayoutEffect } from 'react';
import { usePagination } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { fetchServers } from 'api/servers/servers';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';

import { Display } from './Display';

export const DisplayContainer = () => {
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();

  const {
    storedQueries: { [QueryKeyLocalStorageEnum.TERMINALS_WITH_DISPLAY]: terminalsQuery },
    setQuery: setTerminalsQuery,
  } = useLocaleStoreQuery();

  const { data: serversResponse, isLoading } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  useLayoutEffect(() => {
    setTerminalsQuery(QueryKeyLocalStorageEnum.TERMINALS_WITH_DISPLAY, {
      ...terminalsQuery,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    });
  }, [page, rowsPerPage]);

  return (
    <Display
      servers={serversResponse?.data || []}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    />
  );
};
