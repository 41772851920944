import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import {
  CardsQueryKeysEnum,
  QueryKeyLocalStorageEnum,
  InstantControlIdSortEnum,
  InstantControlOwnerNameSortEnum,
  InstantControlOwnerRegistrationNumberSortEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { Table } from 'ui/table/Table';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { ProblemTkdDescriptionEnum } from 'api/cards/cards.enum';

import * as Styled from './InstantControlTableHeader.styles';

export const InstantControlTableHeader = () => {
  const { formatMessage } = useLocale();
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.INSTANT_CONTROL]: instantControlQuery },
    setQuery,
  } = useLocaleStoreQuery();
  const setRandomControlQuery = (updatedInstantControlQuery: Partial<typeof instantControlQuery>) => {
    setQuery(QueryKeyLocalStorageEnum.INSTANT_CONTROL, {
      ...instantControlQuery,
      ...updatedInstantControlQuery,
    });
  };
  const problemTkdFilterOptions = [
    {
      name: formatMessage({ id: AppMessages[ProblemTkdDescriptionEnum.true] }),
      filterBy: 'true',
    },
    {
      name: formatMessage({ id: AppMessages[ProblemTkdDescriptionEnum.false] }),
      filterBy: 'false',
    },
  ];
  return (
    <>
      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.INSTANT_CONTROL}
        sortBy={InstantControlIdSortEnum}
        queryHeader={formatMessage({ id: AppMessages['control.instant.table.id'] })}
      />
      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.INSTANT_CONTROL}
        sortBy={InstantControlOwnerNameSortEnum}
        queryHeader={formatMessage({ id: AppMessages['control.instant.table.name'] })}
      />
      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.INSTANT_CONTROL}
        queryName={CardsQueryKeysEnum.PROBLEM_TKD}
        filterItems={problemTkdFilterOptions}
        queryHeader={formatMessage({ id: AppMessages['control.instant.table.reader'] })}
        queryHeaderDescriptions={ProblemTkdDescriptionEnum}
        headerWrapper={(header) => <Styled.ProblemTkdHeaderText noWrap>{header}</Styled.ProblemTkdHeaderText>}
      />
      <OrganizationsTreeSelect
        multiple
        isFilterType
        onChange={(options) =>
          options &&
          setRandomControlQuery({
            komorka: options.map((cell) => cell.id),
          })
        }
        value={instantControlQuery.komorka?.map((id) => ({ id, nazwa: 'komorka' }))}
        placeholder={formatMessage({ id: AppMessages['control.instant.table.cell'] })}
      />
      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.INSTANT_CONTROL}
        sortBy={InstantControlOwnerRegistrationNumberSortEnum}
        queryHeader={formatMessage({ id: AppMessages['control.instant.table.registrationNumber'] })}
      />
    </>
  );
};
