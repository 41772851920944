import { GroupTypeEnum } from 'api/groups/groups.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionButton } from 'ui/actionButton/ActionButton';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { ArchiveGroupProps } from './ArchiveGroup.types';

export const ArchiveGroup = ({
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
  selectedGroup,
}: ArchiveGroupProps) => {
  const { formatMessage } = useLocale();

  const displayAdditionalInfo = selectedGroup.typ === GroupTypeEnum.DYNAMICZNA && !!selectedGroup.komorki.length;

  return (
    <>
      <ActionButton type="archive" onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['group.archive.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['group.archive.submitButton'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['group.archive.subtitle'] })} {selectedGroup.nazwa}
        </ActionModal.Subtitle>

        <ActionModal.Text>{formatMessage({ id: AppMessages['group.archive.text'] })}</ActionModal.Text>

        {displayAdditionalInfo && (
          <>
            <br />
            <ActionModal.Text>{formatMessage({ id: AppMessages['group.archive.additionalInfo'] })}</ActionModal.Text>
          </>
        )}
      </ActionModal>
    </>
  );
};
