import { useState, useEffect } from 'react';
import { ErrorBoundary, usePagination } from 'shared-ui';

import { Group } from 'api/groups/groups.types';
import { fetchGroups } from 'api/groups/groups';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useSearchQuery } from 'hooks/useSearchQuery/useSearchQuery';
import { GroupsQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useSelectedGroup } from 'hooks/useSelectedGroup/useSelectedGroup';
import { useGroupsArchivedView } from 'hooks/useGroupsArchivedView/useGroupsArchivedView';
import { useLocaleStoreGroupsQuery } from 'hooks/useLocaleStoreGroupsQuery/useLocaleStoreGroupsQuery';

import { Groups } from './Groups';

export const GroupsContainer = () => {
  const [checkedGroups, setCheckedGroups] = useState<Group[]>([]);
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();
  const [groupsTableSearchQuery, setGroupsTableSearchQuery] = useState('');
  const { setSelectedGroupId, setSelectedGroup } = useSelectedGroup();
  const searchQuery = useSearchQuery();
  const redirectedGroupId = searchQuery.get(GroupsQueryKeysEnum.QUERY);

  const { groupsQuery } = useLocaleStoreGroupsQuery();
  const { isGroupsArchivedView } = useGroupsArchivedView();

  const { data: groupsResponse, isLoading: isLoadingGroups } = useQuery(
    [
      QueryKeyEnum.GROUPS,
      groupsQuery,
      page,
      rowsPerPage,
      groupsTableSearchQuery,
      redirectedGroupId,
      isGroupsArchivedView,
    ],
    fetchGroups,
    {
      args: {
        ...groupsQuery,
        [GroupsQueryKeysEnum.LIMIT]: rowsPerPage,
        [GroupsQueryKeysEnum.OFFSET]: rowsPerPage * page,
        [GroupsQueryKeysEnum.ARCHIWALNA]: isGroupsArchivedView,
        id: redirectedGroupId ? redirectedGroupId : groupsTableSearchQuery,
      },
    },
  );

  useEffect(() => {
    if (redirectedGroupId) {
      setSelectedGroupId(redirectedGroupId);

      const redirectedGroup = groupsResponse?.data.find((group) => group.id === redirectedGroupId);

      if (redirectedGroup) setSelectedGroup(redirectedGroup);
    }
  }, [redirectedGroupId, groupsResponse]);

  useEffect(() => {
    if (!groupsResponse?.data) return;

    setCheckedGroups(
      groupsResponse?.data.filter((group) => checkedGroups.some((checkedGroup) => checkedGroup.id === group.id)),
    );
  }, [groupsResponse]);

  useEffect(() => {
    setPage(0);
  }, [groupsTableSearchQuery, groupsQuery, isGroupsArchivedView]);

  return (
    <ErrorBoundary>
      <Groups
        groupsResponse={groupsResponse}
        isLoadingGroups={isLoadingGroups}
        checkedGroups={checkedGroups}
        setCheckedGroups={setCheckedGroups}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setGroupsTableSearchQuery={setGroupsTableSearchQuery}
        withBackButton={!!redirectedGroupId}
      />
    </ErrorBoundary>
  );
};
