import { useNavigate } from 'react-router-dom';

import { ActionButton } from 'ui/actionButton/ActionButton';
import { AppRouteWithParams } from 'routing/AppRoute.enum';

import { GlcActionsProps } from './GlcActions.types';
import * as Styled from './GlcActions.styles';
import { ArchiveGlcContainer } from './archiveGlc/ArchiveGlcContainer';
import { UnarchiveGlcContainer } from './unarchiveGlc/UnarchiveGlcContainer';
import { DeleteGlcContainer } from './deleteGlc/DeleteGlcContainer';

export const GlcActions = ({ selectedGlc }: GlcActionsProps) => {
  const navigate = useNavigate();

  const isArchived = selectedGlc.archiwalny;
  const isArchiveAvailable = !isArchived && !selectedGlc.tkds?.length && !selectedGlc.uprGrupowe;
  const canBeDeleted = selectedGlc.canBeDeleted && !selectedGlc.tkds?.length;

  return (
    <Styled.ButtonsWrapper>
      {canBeDeleted && <DeleteGlcContainer selectedGlc={selectedGlc} />}
      {!isArchived && (
        <ActionButton
          type="terminals"
          onClick={() => navigate(AppRouteWithParams.glcTerminals.get({ id: selectedGlc.id }))}
        />
      )}
      {isArchiveAvailable && <ArchiveGlcContainer selectedGlc={selectedGlc} />}
      {isArchived && <UnarchiveGlcContainer selectedGlc={selectedGlc} />}
      <ActionButton
        type="history"
        onClick={() => navigate(AppRouteWithParams.glcHistory.get({ id: selectedGlc.id }))}
      />
    </Styled.ButtonsWrapper>
  );
};
