import { DeleteWantedMultiContainer } from './deleteWantedMulti/DeleteWantedMultiContainer';
import { ModifyWantedInformationContainer } from './modifyWantedInformationMulti/ModifyWantedInformationMultiContainer';
import { WantedMultiActionsProps } from './WantedMultiActions.types';
import { UpdateMultiWantedContainer } from './updateMultiWanted/UpdateMultiWantedContainer';
import * as Styled from './WantedMultiActions.styles';

export const WantedMultiActions = ({ checkedOwners, setCheckedOwners }: WantedMultiActionsProps) => {
  return (
    <Styled.Container>
      <ModifyWantedInformationContainer checkedOwners={checkedOwners} />
      <DeleteWantedMultiContainer checkedOwners={checkedOwners} setCheckedOwners={setCheckedOwners} />
      <UpdateMultiWantedContainer checkedOwners={checkedOwners} />
    </Styled.Container>
  );
};
