import { useState, useMemo } from 'react';
import { format } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, PinCodeInput, Datepicker } from 'shared-ui';

import { ReactComponent as ChangeIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg';
import { Input } from 'ui/input/Input';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { CardBlockTypeDescriptionEnum } from '../../Cards.enum';
import { CardBlockTypeEnum, CardStatusEnum } from 'api/cards/cards.enum';
import { DateFormat } from 'core/global.enum';
import { useAuth } from 'hooks/useAuth/useAuth';
import { ActionModal } from 'ui/actionModal/ActionModal';
import { handleValidFromDate } from 'helpers/handleValidFromDate';
import { getNow } from 'helpers/getNow';
import { getEndOfToday } from 'helpers/getEndOfToday';
import { handleValidToDate } from 'helpers/handleValidToDate';
import { Form } from 'ui/form/Form';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';

import { CardDetailsEditForm, CardDetailsEditProps } from './CardDetailsEdit.types';
import { OwnerAssignContainer } from './ownerAssign/OwnerAssignContainer';
import { PinCodeAssignContainer } from './pinCodeAssign/PinCodeAssignContainer';
import * as Styled from './CardDetailsEdit.styles';

export const CardDetailsEdit = ({
  selectedCard,
  onSubmit,
  isLoading,
  setSelectedCard,
  deleteCardPin,
}: CardDetailsEditProps) => {
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);
  const { formatMessage } = useLocale();
  const isCardBlocked = selectedCard.blokada !== CardBlockTypeEnum.ODBLOKOWANA;
  const isCardBlockedManually = selectedCard.blokada === CardBlockTypeEnum.RECZNA;
  const isExpiryDateEditable =
    [CardStatusEnum.WYDANA, CardStatusEnum.WYDANA_Z].includes(selectedCard.status) && !isCardBlockedManually;
  const isCardArchived = selectedCard.archiwalna;
  const { isAdmin } = useAuth();

  const minDate = useMemo(() => new Date(), []);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, errors, isValid },
    watch,
    trigger,
  } = useFormContext<CardDetailsEditForm>();

  const pinCode = watch('pin');
  const validFrom = new Date(watch('waznoscOd'));

  const { cardTypeOptions } = useAppSelectOptions();

  return (
    <>
      <Styled.CardDetailsWrapper onSubmit={handleSubmit(onSubmit)}>
        <Styled.DetailsItemWrapper>
          <Styled.DetailText htmlFor="nazwa">
            {formatMessage({ id: AppMessages['card.details.form.name'] })}
          </Styled.DetailText>
          <Controller
            name="nazwa"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                id={field.name}
                disabled={isCardArchived}
                placeholder={formatMessage({ id: AppMessages['card.details.form.name.placeholder'] })}
                withoutHelperText
                inputProps={{ spellCheck: false }}
                errorMessage={errors?.nazwa?.message}
              />
            )}
          />
        </Styled.DetailsItemWrapper>
        <Styled.DetailsItemWrapper>
          <Styled.DetailText htmlFor="card-type">
            {formatMessage({ id: AppMessages['card.details.form.type'] })}
          </Styled.DetailText>

          <Form.ControlledSelect
            name="typKarty"
            control={control}
            disabled={isCardArchived}
            data-testid="card-type"
            inputProps={{ id: 'card-type' }}
            options={cardTypeOptions}
          />
        </Styled.DetailsItemWrapper>

        <Styled.DetailsItemWrapper>
          <Styled.DetailText htmlFor="datepicker-validFrom">
            {formatMessage({ id: AppMessages['card.details.form.validFrom'] })}
          </Styled.DetailText>

          <Controller
            name="waznoscOd"
            control={control}
            render={({ field }) => (
              <Datepicker
                {...field}
                id="datepicker-validFrom"
                value={watch('waznoscOd')}
                disabled={!isExpiryDateEditable}
                minDate={minDate}
                smallPlaceholderFontSize
                withCalendarIcon
                withoutHelperText
                isClickable={isExpiryDateEditable}
                withDatepickerPopover={isExpiryDateEditable}
                errorMessage={errors?.waznoscOd?.message}
                popoverOrigin={{
                  vertical: 0,
                  horizontal: -520,
                }}
                customSetDateFormat={handleValidFromDate}
                customDateFormatOnOpen={getNow}
              />
            )}
          />
        </Styled.DetailsItemWrapper>

        <Styled.DetailsItemWrapper>
          <Styled.DetailText htmlFor="datepicker-validTo">
            {formatMessage({ id: AppMessages['card.details.form.validTo'] })}
          </Styled.DetailText>

          <Controller
            name="waznoscDo"
            control={control}
            render={({ field }) => (
              <Datepicker
                {...field}
                id="datepicker-validTo"
                value={watch('waznoscDo')}
                onChange={(date) => {
                  field.onChange(date);
                  trigger('waznoscOd');
                }}
                minDate={validFrom < minDate ? minDate : validFrom}
                disabled={!isExpiryDateEditable || isCardArchived}
                smallPlaceholderFontSize
                withCalendarIcon
                withoutHelperText
                isClickable={isExpiryDateEditable}
                withDatepickerPopover={isExpiryDateEditable}
                errorMessage={errors?.waznoscDo?.message}
                popoverOrigin={{
                  vertical: -20,
                  horizontal: -520,
                }}
                customSetDateFormat={handleValidToDate}
                customDateFormatOnOpen={getEndOfToday}
              />
            )}
          />
        </Styled.DetailsItemWrapper>

        <Styled.DetailsItemWrapper>
          <Styled.DetailText htmlFor="datepicker-returnDate">
            {formatMessage({ id: AppMessages['card.details.form.returnDate'] })}
          </Styled.DetailText>

          <Controller
            name="dataZwrotu"
            control={control}
            render={({ field }) => (
              <Datepicker
                {...field}
                id="datepicker-returnDate"
                value={watch('dataZwrotu')}
                disabled={!isExpiryDateEditable || isCardArchived}
                minDate={minDate}
                smallPlaceholderFontSize
                withCalendarIcon
                withoutHelperText
                isClickable={isExpiryDateEditable}
                withDatepickerPopover={isExpiryDateEditable}
                errorMessage={errors?.dataZwrotu?.message}
                popoverOrigin={{
                  vertical: 0,
                  horizontal: -520,
                }}
                customSetDateFormat={handleValidToDate}
                customDateFormatOnOpen={getEndOfToday}
              />
            )}
          />
        </Styled.DetailsItemWrapper>

        {selectedCard.pin ? (
          <Styled.DetailsItemVerticalWrapper>
            <Styled.DetailVerticalText>
              {formatMessage({ id: AppMessages['card.details.form.pin'] })}
            </Styled.DetailVerticalText>
            <PinCodeAssignContainer
              title={formatMessage({ id: AppMessages['card.assign.pin.title.edit'] })}
              actionText={formatMessage({ id: AppMessages['common.save'] })}
              successText={formatMessage({ id: AppMessages['card.assign.pin.action.success.edit'] })}
              isDisabled={isCardBlocked || isCardArchived}
              cardId={selectedCard.id}
              cardNumber={selectedCard.numerKarty}
              setSelectedCard={setSelectedCard}
              renderActionComponent={(onOpen, isDisabled) => (
                <Styled.ChangePinWrapper $isDisabled={!!isDisabled}>
                  <Controller
                    name="pin"
                    control={control}
                    render={({ field }) => (
                      <PinCodeInput
                        {...field}
                        id="pin-details"
                        withoutLabel
                        disabled
                        errorMessage={errors?.pin?.message}
                        withoutHelperText
                        placeholder={formatMessage({ id: AppMessages['card.details.form.pin.placeholder'] })}
                        fullWidth
                        withoutToggleShow={isAdmin ? false : true}
                      />
                    )}
                  />
                  <Styled.PinButton data-testid="change-pin-button" disabled={isDisabled} onClick={onOpen}>
                    <ChangeIcon />
                  </Styled.PinButton>
                  <Styled.PinButton
                    data-testid="delete-pin-button"
                    disabled={!pinCode || isDisabled}
                    onClick={() => {
                      setIsPinModalOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </Styled.PinButton>
                </Styled.ChangePinWrapper>
              )}
            />

            <ActionModal
              header={formatMessage({ id: AppMessages['card.pin.delete.title'] })}
              open={isPinModalOpen}
              onClose={() => {
                setIsPinModalOpen(false);
              }}
              onSubmit={() => {
                setIsPinModalOpen(false);
                deleteCardPin();
              }}
              submitButtonText={formatMessage({ id: AppMessages['card.pin.delete.actionButton'] })}
            >
              <ActionModal.Subtitle>
                {formatMessage({ id: AppMessages['card.pin.delete.subtitle'] })} {selectedCard.numerKarty}
              </ActionModal.Subtitle>

              <ActionModal.Text>{formatMessage({ id: AppMessages['card.pin.delete.text'] })}</ActionModal.Text>
            </ActionModal>
          </Styled.DetailsItemVerticalWrapper>
        ) : (
          <Styled.DetailsItemWrapper>
            <Styled.DetailText>{formatMessage({ id: AppMessages['card.details.form.pin'] })}</Styled.DetailText>
            <PinCodeAssignContainer
              title={formatMessage({ id: AppMessages['card.assign.pin.title'] })}
              actionText={formatMessage({ id: AppMessages['common.save'] })}
              successText={formatMessage({ id: AppMessages['card.assign.pin.action.success'] })}
              isDisabled={isCardBlocked || isCardArchived}
              cardId={selectedCard.id}
              cardNumber={selectedCard.numerKarty}
              setSelectedCard={setSelectedCard}
              renderActionComponent={(onOpen, isDisabled) => (
                <Button disabled={isDisabled} onClick={onOpen} size="small" variant="outlined" color="primary">
                  {formatMessage({ id: AppMessages['card.details.form.button.pin'] })}
                </Button>
              )}
            />
          </Styled.DetailsItemWrapper>
        )}

        <Styled.DetailsItemVerticalWrapper>
          <Styled.DetailVerticalText htmlFor="opis">
            {formatMessage({ id: AppMessages['card.details.form.description'] })}
          </Styled.DetailVerticalText>

          <Controller
            name="opis"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                id={field.name}
                disabled={isCardArchived}
                placeholder={formatMessage({ id: AppMessages['card.details.form.description.placeholder'] })}
                withoutHelperText
                errorMessage={errors?.opis?.message}
                multiline
                inputProps={{ spellCheck: false }}
                maxRows={4}
              />
            )}
          />
        </Styled.DetailsItemVerticalWrapper>

        <Styled.DetailsItemWrapper>
          <Styled.DetailText>{formatMessage({ id: AppMessages['card.details.form.owner'] })}</Styled.DetailText>

          {selectedCard?.posiadacz?.imie || selectedCard?.posiadacz?.nazwisko ? (
            <Styled.SecondaryText>
              {selectedCard?.posiadacz?.imie || ''} {selectedCard?.posiadacz?.nazwisko || ''}
            </Styled.SecondaryText>
          ) : (
            <OwnerAssignContainer selectedCard={selectedCard} isCardBlockedManually={isCardBlockedManually} />
          )}
        </Styled.DetailsItemWrapper>

        <Styled.DetailsItemWrapper>
          <Styled.DetailVerticalText>
            {formatMessage({ id: AppMessages['card.details.form.state'] })}
          </Styled.DetailVerticalText>

          <div>
            {isCardBlockedManually ? (
              <>
                <Styled.CardStatus data-testid="card-status">
                  {formatMessage({ id: CardBlockTypeDescriptionEnum[selectedCard.blokada] })}
                </Styled.CardStatus>

                <Styled.SecondaryText>
                  {selectedCard.czasBlokady ? format(new Date(selectedCard.czasBlokady), DateFormat.FULL) : null}
                </Styled.SecondaryText>

                <Styled.SecondaryText>{selectedCard.powodBlokady}</Styled.SecondaryText>
              </>
            ) : (
              <Styled.CardStatus data-testid="card-status">
                {formatMessage({ id: CardBlockTypeDescriptionEnum[selectedCard.blokada] })}
              </Styled.CardStatus>
            )}
          </div>
        </Styled.DetailsItemWrapper>

        {isDirty && (
          <Styled.ButtonsWrapper>
            <Button
              size="small"
              onClick={() => {
                reset({
                  ...selectedCard,
                  waznoscDo: (selectedCard.waznoscDo as Date) || '',
                  dataZwrotu: (selectedCard.dataZwrotu as Date) || '',
                });
              }}
              color="primary"
            >
              {formatMessage({ id: AppMessages['card.details.form.button.cancel'] })}
            </Button>

            <Button
              type="submit"
              disabled={!isValid}
              isLoading={isLoading}
              size="small"
              variant="contained"
              color="primary"
            >
              {formatMessage({ id: AppMessages['card.details.form.button.save'] })}
            </Button>
          </Styled.ButtonsWrapper>
        )}
      </Styled.CardDetailsWrapper>
    </>
  );
};
