import { useState, forwardRef, MouseEvent } from 'react';
import { Tooltip, Autocomplete, CloseGrayIcon, SearchIcon } from 'shared-ui';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-select-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-select-right.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { transformTreeToFlatArray } from 'helpers/transformTreeToFlatArray';
import { SingleSelectProps, TreeOption } from '../OrganizationsTreeSelect.types';

import * as Styled from './TreeSingleSelect.styles';

export const TreeSingleSelect = forwardRef<HTMLInputElement, SingleSelectProps>(
  ({ options, onChange, value, withLabel, isSearchType, ...props }, ref) => {
    const { formatMessage } = useLocale();
    const [selectedNavigationEl, setSelectedNavigationEl] = useState<TreeOption[] | undefined>(undefined);
    const [backHistory, setBackHistory] = useState<TreeOption[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const flatOrganizationsArray = transformTreeToFlatArray(options);

    const filterOrganizations = () => {
      return flatOrganizationsArray.filter(
        (option) =>
          option.nazwa.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
          option.kod?.toLowerCase().includes(searchValue.toLocaleLowerCase()),
      );
    };

    const arrayToRender = searchValue
      ? filterOrganizations()
      : selectedNavigationEl && !!backHistory.length
      ? selectedNavigationEl
      : options;

    const onSelectItem = ({ children, ...option }: TreeOption) => onChange(option);

    const onClickCloseButton = (e: MouseEvent) => {
      e.stopPropagation();
      setBackHistory([]);
    };

    const onClickBackButton = (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      const lastBackHistoryElement = backHistory[backHistory.length - 1];
      setSelectedNavigationEl(Array(lastBackHistoryElement));
      setBackHistory(backHistory.filter((historyElement) => historyElement.id !== lastBackHistoryElement.id));
    };

    const onClickGoDipperButton = (e: MouseEvent, option: TreeOption, subOption: TreeOption) => {
      e.stopPropagation();
      e.preventDefault();
      setSelectedNavigationEl(Array(subOption));
      setBackHistory([...backHistory, option]);
    };

    const onSearch = (searchValue: string) => {
      setSearchValue(searchValue);
      setBackHistory([]);
    };

    const getOption = (option: TreeOption | undefined) => {
      if (!option) return null;

      return { value: option.id, label: option.nazwa };
    };

    const getCurrentOrganizationCode = () => {
      return flatOrganizationsArray.find(({ id }) => value && id === value?.id)?.kod;
    };

    const autocomplete = (
      <Autocomplete
        data-testid={props.label}
        options={flatOrganizationsArray?.map((option) => ({ value: option.id, label: option.nazwa })) || []}
        getOptionLabel={(option) => option.label || ''}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        onInputChange={(_e, searchInputValue, reason) => {
          if (reason === 'clear') {
            onChange(undefined);
            onSearch('');
          } else {
            if (isSearchType && searchValue.length > searchInputValue.length) {
              onChange(undefined);
              onSearch('');
              return;
            }

            onSearch(searchInputValue);
          }
        }}
        ref={ref}
        customLabel={withLabel ? getCurrentOrganizationCode() : undefined}
        value={getOption(value)}
        {...props}
        PopperComponent={(params) => (
          <Styled.Popper {...params}>
            {!arrayToRender || !arrayToRender?.length ? (
              <Styled.ListItemWrapper>
                <Styled.NoOptionsTextWrapper>
                  <Styled.Option>{formatMessage({ id: AppMessages['common.notFound'] })}</Styled.Option>
                </Styled.NoOptionsTextWrapper>
              </Styled.ListItemWrapper>
            ) : (
              arrayToRender.map((option, index) => (
                <Styled.ListItemWrapper key={option.id}>
                  <Styled.ListItem $isFirstLevel={!backHistory.length} onMouseDown={() => onSelectItem(option)}>
                    {!!backHistory.length && (
                      <Styled.LeftIconButton onMouseDown={onClickBackButton}>
                        <ArrowLeftIcon />
                      </Styled.LeftIconButton>
                    )}

                    <Styled.Option>
                      <div>
                        <Styled.TreeOptionTextBold noWrap>{option.kod}</Styled.TreeOptionTextBold>
                        <Styled.TreeOptionText noWrap>{option.nazwa}</Styled.TreeOptionText>
                      </div>
                    </Styled.Option>

                    {index === 0 && (
                      <Styled.RightIconButton onMouseDown={onClickCloseButton}>
                        <CloseGrayIcon />
                      </Styled.RightIconButton>
                    )}
                  </Styled.ListItem>
                  {option.children &&
                    option.children?.map((subOption) => (
                      <Styled.ListItem
                        $isFirstLevel={!backHistory.length}
                        key={subOption.id}
                        onMouseDown={() => onSelectItem(subOption)}
                      >
                        <Tooltip
                          placement="bottom"
                          title={subOption.children ? subOption.children.map((option) => option.nazwa).join(', ') : ''}
                        >
                          <Styled.SubOption>
                            <div>
                              <Styled.TreeOptionTextBold noWrap>{subOption.kod}</Styled.TreeOptionTextBold>
                              <Styled.TreeOptionText noWrap>{subOption.nazwa}</Styled.TreeOptionText>
                            </div>
                          </Styled.SubOption>
                        </Tooltip>

                        {subOption.children && (
                          <Styled.RightIconButton onMouseDown={(e) => onClickGoDipperButton(e, option, subOption)}>
                            <ArrowRightIcon />
                          </Styled.RightIconButton>
                        )}
                      </Styled.ListItem>
                    ))}
                </Styled.ListItemWrapper>
              ))
            )}
          </Styled.Popper>
        )}
        {...(isSearchType && {
          inputVariant: 'outlined',
          inputSize: 'small',
          popupIcon: <SearchIcon onClick={() => setIsOpen(false)} />,
          stopRotatePopupIndicator: true,
        })}
      />
    );

    return isSearchType ? (
      <Styled.SearchWrapper>
        {isOpen ? (
          autocomplete
        ) : (
          <Styled.IconWrapper>
            <Styled.SearchButton size="small" onClick={() => setIsOpen(true)}>
              <SearchIcon />
            </Styled.SearchButton>
          </Styled.IconWrapper>
        )}
      </Styled.SearchWrapper>
    ) : (
      autocomplete
    );
  },
);
