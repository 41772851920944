import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'shared-ui';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Input } from 'ui/input/Input';
import { Group } from 'api/groups/groups.types';
import { GroupTypeDescriptionEnum } from '../Groups.enum';
import { GroupTypeEnum } from 'api/groups/groups.enum';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';

import { GroupDetailsProps } from './GroupDetails.types';
import * as Styled from './GroupDetails.styles';
import { GroupActions } from './groupActions/GroupActions';

export const GroupDetails = ({ isLoading, selectedGroup, onSubmit }: GroupDetailsProps) => {
  const { formatMessage } = useLocale();
  const isDynamicGroup = selectedGroup.typ === GroupTypeEnum.DYNAMICZNA;
  const isArchived = selectedGroup.archiwalna;

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isValid },
  } = useFormContext<Group>();

  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <GroupActions selectedGroup={selectedGroup} />

        <Styled.DetailsItemWrapper>
          <Styled.DetailName>{formatMessage({ id: AppMessages['group.details.id'] })}</Styled.DetailName>
          <Styled.DetailText>{selectedGroup.idGrupy}</Styled.DetailText>
        </Styled.DetailsItemWrapper>

        <Controller
          name="nazwa"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              label={formatMessage({ id: AppMessages['group.details.groupName.label'] })}
              placeholder={formatMessage({ id: AppMessages['group.details.groupName.placeholder'] })}
              errorMessage={error?.message}
              disabled={isArchived}
            />
          )}
        />

        <Input
          disabled
          value={formatMessage({ id: AppMessages[GroupTypeDescriptionEnum[selectedGroup.typ]] })}
          label={formatMessage({ id: AppMessages['group.details.groupType.label'] })}
        />

        <Input
          disabled
          value={
            selectedGroup.glc
              ? formatMessage({ id: AppMessages['common.yes'] }).toUpperCase()
              : formatMessage({ id: AppMessages['common.no'] }).toUpperCase()
          }
          label={formatMessage({ id: AppMessages['group.details.glc.label'] })}
        />

        {isDynamicGroup && (
          <Styled.OrganisationsWrapper>
            <Controller
              control={control}
              name="komorki"
              render={({ field }) => (
                <OrganizationsTreeSelect
                  {...field}
                  multiple
                  placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
                  label={formatMessage({ id: AppMessages['group.details.cell.label'] })}
                  disabled={isArchived}
                />
              )}
            />
          </Styled.OrganisationsWrapper>
        )}

        <Controller
          name="opis"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              label={formatMessage({ id: AppMessages['group.details.description.label'] })}
              placeholder={formatMessage({ id: AppMessages['group.details.description.placeholder'] })}
              errorMessage={error?.message}
              disabled={isArchived}
              maxRows={4}
              multiline
            />
          )}
        />
      </div>

      {isDirty && (
        <Styled.ButtonsWrapper>
          <Button
            size="small"
            onClick={() => {
              reset(selectedGroup);
            }}
            color="primary"
          >
            {formatMessage({ id: AppMessages['common.cancel'] })}
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={isLoading}
            size="small"
            variant="contained"
            color="primary"
          >
            {formatMessage({ id: AppMessages['common.save'] })}
          </Button>
        </Styled.ButtonsWrapper>
      )}
    </Styled.Form>
  );
};
