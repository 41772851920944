import { useParams } from 'react-router-dom';

import { fetchOwnerDetails } from 'api/owners/owners';
import { QueryKeyEnum } from 'core/global.enum';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { useQuery } from 'hooks/useQuery/useQuery';
import { OwnerQueryKeysContextController } from 'context/ownerQueryKeys/ownerQueryKeysContextController/OwnerQueryKeysContextController';

import { OwnerPermissions } from './OwnerPermissions';

export const OwnerPermissionsContainer = () => {
  const { id } = useParams<{ id: string }>();
  const ownerId = id || '';
  const { selectedOwner } = useSelectedOwner();

  const { data: owner } = useQuery([QueryKeyEnum.OWNER_DETAILS, ownerId], () => fetchOwnerDetails(ownerId));

  return (
    <OwnerQueryKeysContextController ownerId={ownerId}>
      <OwnerPermissions owner={selectedOwner || owner} />
    </OwnerQueryKeysContextController>
  );
};
