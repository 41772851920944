import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

import { AppMessages } from 'i18n/messages';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { updatePosition } from 'api/positions/positions';
import { UpdatePositionBody } from 'api/positions/positions.types';
import { useSelectedPosition } from 'hooks/useSelectedPosition/useSelectedPosition';
import { useMutation } from 'hooks/useMutation/useMutation';

import { OrganizationDetails } from './PositionDetails';
import { PositionDetailsContainerProps } from './PositionDetails.types';
import { usePositionDetailsValidation } from './PositionDetails.validation';

export const PositionDetailsContainer = ({ positions, selectedPosition }: PositionDetailsContainerProps) => {
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();
  const { schema } = usePositionDetailsValidation(positions, selectedPosition);
  const { setSelectedPosition } = useSelectedPosition();

  const { mutate: onUpdatePosition, isLoading } = useMutation(updatePosition, {
    onSuccess: (position) => {
      setSelectedPosition(position);
      toast.dark(formatMessage({ id: AppMessages['definitions.position.details.edit.success'] }));
      queryClient.invalidateQueries([QueryKeyEnum.POSITIONS]);
      queryClient.invalidateQueries([QueryKeyEnum.OWNERS]);
    },
  });

  const formMethods = useForm<UpdatePositionBody>({
    mode: 'all',
    defaultValues: selectedPosition,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    formMethods.reset(selectedPosition);
  }, [selectedPosition]);

  const onSubmit = (body: UpdatePositionBody) => {
    onUpdatePosition({ id: selectedPosition.id, body: { nazwa: body.nazwa } });
  };

  return (
    <FormProvider {...formMethods}>
      <OrganizationDetails isLoading={isLoading} selectedPosition={selectedPosition} onSubmit={onSubmit} />
    </FormProvider>
  );
};
