import { Controller, useFormContext } from 'react-hook-form';
import { Button, ChangePasswordWithoutConfirmContainer } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { Form } from 'ui/form/Form';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { changeUserPassword } from 'api/users/users';
import * as Styled from '../UserDetails.styles';

import { UserBasicDetailsProps, UserBasicDetailsForm } from './UserBasicDetails.types';

export const UserBasicDetails = ({ isLoadingUpdateUser, selectedUser, onSubmit }: UserBasicDetailsProps) => {
  const { formatMessage } = useLocale();
  const isSupervisorUser = !selectedUser.ktoWprowadzil;

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isValid, errors },
  } = useFormContext<UserBasicDetailsForm>();

  const { userStatusOptions, userTypeOptions } = useAppSelectOptions();

  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)}>
      <Styled.FieldsWrapper>
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              value={field.value || ''}
              placeholder={formatMessage({ id: AppMessages['user.details.basic.username.placeholder'] })}
              label={formatMessage({ id: AppMessages['user.details.basic.username'] })}
              errorMessage={errors?.username?.message}
            />
          )}
        />

        <Styled.FieldWrapper>
          <Form.ControlledSelect
            name="typ"
            control={control}
            disabled={isSupervisorUser}
            label={formatMessage({ id: AppMessages['user.details.basic.type'] })}
            options={userTypeOptions}
          />
        </Styled.FieldWrapper>

        <Styled.FieldWrapper>
          <Form.ControlledSelect
            name="status"
            control={control}
            disabled={isSupervisorUser}
            label={formatMessage({ id: AppMessages['user.details.basic.status'] })}
            options={userStatusOptions}
          />
        </Styled.FieldWrapper>

        <ChangePasswordWithoutConfirmContainer
          changePasswordMutation={changeUserPassword}
          selectedUser={selectedUser}
        />
      </Styled.FieldsWrapper>

      {isDirty && (
        <Styled.ButtonsWrapper>
          <Button
            size="small"
            onClick={() => {
              reset(selectedUser);
            }}
            color="primary"
          >
            {formatMessage({ id: AppMessages['owner.details.button.cancel'] })}
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={isLoadingUpdateUser}
            size="small"
            variant="contained"
            color="primary"
          >
            {formatMessage({ id: AppMessages['owner.details.button.save'] })}
          </Button>
        </Styled.ButtonsWrapper>
      )}
    </Styled.Form>
  );
};
