export enum CardBlockedEnum {
  TAK = 'TAK',
  NIE = 'NIE',
}

export enum CardBlockedDescriptionEnum {
  TAK = 'common.yes',
  NIE = 'common.no',
}

export enum SingleOrManyEnum {
  SINGLE = 'SINGLE',
  MANY = 'MANY',
}

export enum SingleOrManyDescriptionEnum {
  SINGLE = 'cards.addCard.single',
  MANY = 'cards.addCard.many',
}
