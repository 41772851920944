import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { HistoryList } from 'ui/historyList/HistoryList';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { ControlTypeDescriptionEnum } from 'api/control/control.enum';

import { ControlHistoryFilters } from './controlHistoryFilters/ControlHistoryFilters';
import { ControlHistoryProps } from './ControlHistory.types';

export const ControlHistory = ({
  controlHistoryResponse,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  servers,
  executorSearchQuery,
  setExecutorSearchQuery,
  tkdSearchQuery,
  setTkdSearchQuery,
  ownerSearchQuery,
  setOwnerSearchQuery,
  onClearControlHistoryQuery,
}: ControlHistoryProps) => {
  const { formatMessage } = useLocale();

  const {
    storedQueries: { controlHistory: controlHistoryQuery },
  } = useLocaleStoreQuery();

  const isClearFiltersButtonVisible =
    Object.values(controlHistoryQuery).some((query) => query) ||
    !!executorSearchQuery ||
    !!tkdSearchQuery ||
    !!ownerSearchQuery;

  return (
    <HistoryList
      data={controlHistoryResponse?.data || []}
      isLoading={isLoading}
      filters={{
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        isClearButtonVisible: isClearFiltersButtonVisible,
        onClearFilters: onClearControlHistoryQuery,
        render: () => (
          <ControlHistoryFilters
            servers={servers}
            tkdSearchQuery={tkdSearchQuery}
            executorSearchQuery={executorSearchQuery}
            ownerSearchQuery={ownerSearchQuery}
            setExecutorSearchQuery={setExecutorSearchQuery}
            setOwnerSearchQuery={setOwnerSearchQuery}
            setTkdSearchQuery={setTkdSearchQuery}
          />
        ),
      }}
      pagination={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        count: controlHistoryResponse?.count || 0,
      }}
      renderHistoryItemBody={(history) => (
        <>
          {history.typ && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['control.history.label.type'] })}
              value={formatMessage({ id: ControlTypeDescriptionEnum[history.typ] })}
            />
          )}

          {history.wykonawca && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['control.history.label.executor'] })}
              value={`${history.wykonawca.imie} ${history.wykonawca.nazwisko}`}
            />
          )}

          {history.dodaniPosiadacze && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage(
                { id: AppMessages['control.history.label.owners'] },
                { count: history.dodaniPosiadacze.length },
              )}
              items={history.dodaniPosiadacze}
              shouldScrollToTop={!!controlHistoryQuery.posiadacz && !isLoading}
              renderItemBody={(owner) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.registrationNumber'] })}
                  number={owner.nrEwidencyjny}
                  value={`${owner.imie} ${owner.nazwisko}`}
                />
              )}
            />
          )}

          {(history.tkds || history.tkdsAdded || history.tkdsDeleted) && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['owner.history.effectivePermissions.serwer'] })}
              value={
                history.tkds
                  ? history.tkds[0].server.nazwa
                  : history.tkdsAdded
                  ? history.tkdsAdded[0].server.nazwa
                  : history.tkdsDeleted
                  ? history.tkdsDeleted[0].server.nazwa
                  : ''
              }
            />
          )}

          {history.tkds && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage(
                { id: AppMessages['control.history.label.readers'] },
                { count: history.tkds.length },
              )}
              items={history.tkds}
              shouldScrollToTop={!!controlHistoryQuery.posiadacz && !isLoading}
              renderItemBody={(tkd) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.id'] })}
                  number={tkd.numer}
                  value={tkd.nazwa}
                />
              )}
            />
          )}

          {history.tkdsAdded && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage(
                { id: AppMessages['control.history.label.addedReaders'] },
                { count: history.tkdsAdded.length },
              )}
              items={history.tkdsAdded}
              shouldScrollToTop={!!controlHistoryQuery.posiadacz && !isLoading}
              renderItemBody={(tkd) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.id'] })}
                  number={tkd.numer}
                  value={tkd.nazwa}
                />
              )}
            />
          )}

          {history.tkdsDeleted && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage(
                { id: AppMessages['control.history.label.removedReaders'] },
                { count: history.tkdsDeleted.length },
              )}
              items={history.tkdsDeleted}
              shouldScrollToTop={!!controlHistoryQuery.posiadacz && !isLoading}
              renderItemBody={(tkd) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.id'] })}
                  number={tkd.numer}
                  value={tkd.nazwa}
                />
              )}
            />
          )}

          {history.powod && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['control.history.label.reason'] })}
              value={history.powod}
            />
          )}
        </>
      )}
    />
  );
};
