import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { JUST_LETTERS_REGEX } from 'core/global.variables';

export const useOwnerBasicDetailsValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    zdjecieUrl: yup.string().optional(),
    imie: yup
      .string()
      .min(3, formatMessage({ id: AppMessages['owners.addOwner.field.name.validation'] }))
      .max(32, formatMessage({ id: AppMessages['owners.addOwner.field.name.validation.max'] }))
      .matches(JUST_LETTERS_REGEX, formatMessage({ id: AppMessages['common.validation.letters'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    nazwisko: yup
      .string()
      .min(3, formatMessage({ id: AppMessages['owners.addOwner.field.name.validation'] }))
      .max(32, formatMessage({ id: AppMessages['owners.addOwner.field.name.validation.max'] }))
      .matches(JUST_LETTERS_REGEX, formatMessage({ id: AppMessages['common.validation.letters'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    drugieImie: yup
      .string()
      .when({
        is: (name: string) => !!name,
        then: (schema) =>
          schema
            .min(3, formatMessage({ id: AppMessages['owners.addOwner.field.secondName.validation'] }))
            .max(32, formatMessage({ id: AppMessages['owners.addOwner.field.secondName.validation.max'] })),
      })
      .matches(JUST_LETTERS_REGEX, formatMessage({ id: AppMessages['common.validation.letters'] }))
      .optional(),
    stanowisko: yup.object().nullable(),
  });

  return { schema };
};
