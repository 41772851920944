import { useAutocomplete } from '@mui/material';
import { Checkbox, PopupSearchIcon } from 'shared-ui';

import {
  GroupAssignedOwnersQueryKeysEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { Position } from 'api/positions/positions.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { PositionPopupAutocompleteProps } from './PositionPopupAutocomplete.types';
import * as Styled from './PositionPopupAutocomplete.styles';

export const PositionPopupAutocomplete = ({ setSearchQuery, positions, isLoading }: PositionPopupAutocompleteProps) => {
  const { formatMessage } = useLocale();

  const {
    storedQueries: { [QueryKeyLocalStorageEnum.GROUP_ASSIGNED_OWNERS]: groupAssignedOwners },
    setQuery: setOwnersQuery,
  } = useLocaleStoreQuery();
  const selectedPositions = groupAssignedOwners.stanowisko;
  const positionIds = positions.map(({ id }) => id);
  const positionsFromStorage = selectedPositions?.filter(({ id }) => !positionIds.includes(id)) || [];

  const { getRootProps, getInputProps, getOptionProps, groupedOptions, setAnchorEl } = useAutocomplete({
    id: 'customized-positions-autocomplete',
    multiple: true,
    open: true,
    options: [...positionsFromStorage, ...positions],
    getOptionLabel: (option) => option.nazwa,
    onInputChange: (_, value) => setSearchQuery(value),
    onChange: (_, option) => (option ? setSelectedPositions(option) : setSelectedPositions([])),
    value: selectedPositions,
  });

  const setSelectedPositions = (positions: Position[]) => {
    setOwnersQuery(QueryKeyLocalStorageEnum.GROUP_ASSIGNED_OWNERS, {
      ...groupAssignedOwners,
      [GroupAssignedOwnersQueryKeysEnum.STANOWISKO]: positions,
    });
  };

  return (
    <Styled.AutocompleteWrapper>
      <div {...getRootProps()}>
        <Styled.InputWrapper ref={setAnchorEl}>
          <PopupSearchIcon />
          <Styled.Input {...getInputProps()} placeholder={formatMessage({ id: AppMessages['common.search'] })} />
        </Styled.InputWrapper>
      </div>
      {isLoading ? (
        <Styled.Option>{formatMessage({ id: AppMessages['common.loading'] })}</Styled.Option>
      ) : (
        <Styled.OptionsWrapper>
          {(groupedOptions as typeof positions).map((option, index) => {
            const optionProps = getOptionProps({ option, index });
            const isSelected = !!selectedPositions?.find(({ id }) => id === option.id);

            return (
              <Styled.Option {...optionProps}>
                <Checkbox checked={isSelected} />

                <span>{option.nazwa}</span>
              </Styled.Option>
            );
          })}
        </Styled.OptionsWrapper>
      )}
    </Styled.AutocompleteWrapper>
  );
};
