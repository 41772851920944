import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useAddControlValidation = (isRandomControl: boolean) => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    ids: isRandomControl
      ? yup
          .array()
          .min(1)
          .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      : yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] })),
    tkds: yup
      .array()
      .min(1)
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    serwerId: yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] })),
  });

  return { schema };
};
