import { Controller, useFormContext } from 'react-hook-form';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Checkbox } from 'shared-ui';

import { AddEventExportFormatBody } from 'api/eventsExportFormat/eventsExportFormats.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { ReactComponent as DragAndDropIcon } from 'assets/icons/drag-and-drop.svg';
import { EventExportFormatParametersValuesEnum } from '../AddEventExportFormat.enum';

import { ParameterSelect } from './parameterSelect/ParameterSelect';
import { ParametersObjectEntriesValues, ParametersFormProps } from './ParametersForm.types';
import { ParametersNamesDescriptionEnum } from './ParametersForm.enum';
import * as Styled from './ParametersForm.styles';

export const ParametersForm = ({ eventsExportFormatConfig, isPreviewPageMode }: ParametersFormProps) => {
  const { formatMessage } = useLocale();

  const { control, watch, setValue } = useFormContext<AddEventExportFormatBody>();

  const { nazwa, separator, endline, ...parametersValues } = watch();

  const parameters = Object.entries(parametersValues)
    .filter(([_parameter, parameterValues]) => (isPreviewPageMode ? parameterValues.enabled : true))
    .sort(([_a, a], [_b, b]) => (a.position > b.position ? 1 : -1)) as ParametersObjectEntriesValues<
    typeof parametersValues
  >;

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const [removedParameter] = parameters.splice(result.source.index, 1);
    parameters.splice(result.destination.index, 0, removedParameter);

    parameters.forEach(([parameter, parameterValues], index) => {
      setValue(parameter, { ...parameterValues, position: index }, { shouldDirty: true });
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {parameters.map(([parameter], index) => (
              <Draggable key={parameter} draggableId={parameter} index={index} isDragDisabled={isPreviewPageMode}>
                {(provided) => (
                  <Styled.ParameterListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Styled.SelectWrapper>
                      {!isPreviewPageMode && (
                        <>
                          <DragAndDropIcon />

                          <Controller
                            control={control}
                            name={`${parameter}.enabled`}
                            render={({ field }) => <Checkbox {...field} checked={field.value} />}
                          />
                        </>
                      )}

                      <Styled.CheckboxLabel $isParameterEnabled={watch(`${parameter}.enabled`)}>
                        {formatMessage({
                          id: ParametersNamesDescriptionEnum[parameter],
                        })}
                      </Styled.CheckboxLabel>
                    </Styled.SelectWrapper>

                    <Styled.SelectWrapper>
                      <ParameterSelect
                        parameter={parameter as EventExportFormatParametersValuesEnum}
                        eventsExportFormatConfig={eventsExportFormatConfig}
                        isPreviewPageMode={isPreviewPageMode}
                      />
                    </Styled.SelectWrapper>
                  </Styled.ParameterListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
