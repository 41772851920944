import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionButton } from 'ui/actionButton/ActionButton';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { UnarchiveGroupProps } from './UnarchiveGroup.types';

export const UnarchiveGroup = ({
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
  selectedGroup,
}: UnarchiveGroupProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <ActionButton type="unarchive" onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['group.unarchive.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['group.unarchive.submitButton'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['group.unarchive.subtitle'] })} {selectedGroup.nazwa}
        </ActionModal.Subtitle>

        <ActionModal.Text>{formatMessage({ id: AppMessages['group.unarchive.text'] })}</ActionModal.Text>
      </ActionModal>
    </>
  );
};
