import { Typography } from '@mui/material';
import styled from 'styled-components';

export const OptionLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  padding: 6px;
`;

export const DescriptionInputWrapper = styled.div`
  grid-column: 1/3;
`;
