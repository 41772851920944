import { useContext } from 'react';

import { SelectedTerminalContext } from 'context/selectedTerminal/selectedTerminalContext/SelectedTerminalContext';

export const useSelectedTerminal = () => {
  const context = useContext(SelectedTerminalContext);

  if (context === undefined) {
    throw new Error(
      'SelectedTerminalContext is unavailable, make sure you are using SelectedTerminalContextController',
    );
  }

  return context;
};
