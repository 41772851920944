import { useNavigate, useLocation } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { PageLayout, TopBar } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AddEventExportFormatBody } from 'api/eventsExportFormat/eventsExportFormats.types';
import { ReactComponent as BackIcon } from 'assets/icons/back-x.svg';
import { AppRoute } from 'routing/AppRoute.enum';

import { AddEventExportFormatProps } from './AddEventExportFormat.types';
import { ParametersForm } from './parametersForm/ParametersForm';
import { SettingsForm } from './settingsForm/SettingsForm';
import { PreviewForm } from './previewForm/PreviewForm';
import * as Styled from './AddEventExportFormat.styles';

export const AddEventExportFormat = ({
  onSubmit,
  isLoading,
  isLoadingAddExportFormat,
  eventsExportFormatConfig,
  eventExportFormatPreview,
  isLoadingPreviewEventExportFormat,
  onPreviewEventExportFormat,
  isPreviewPageMode,
  isEditPageMode,
}: AddEventExportFormatProps) => {
  const { formatMessage } = useLocale();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    handleSubmit,
    formState: { isDirty, isValid },
    watch,
  } = useFormContext<AddEventExportFormatBody>();

  const { nazwa, separator, endline, ...parameters } = watch();
  const isAnyParameterEnabled = Object.values(parameters).some((parameter) => parameter.enabled);

  const pageHeader = isPreviewPageMode
    ? `${formatMessage({ id: AppMessages['definitions.events.previewExportFormat.title'] })} ${nazwa}`
    : isEditPageMode
    ? formatMessage({ id: AppMessages['definitions.events.editExportFormat.title'] })
    : formatMessage({ id: AppMessages['definitions.events.addExportFormat.title'] });

  return (
    <PageLayout
      withBackButton={{
        icon: isPreviewPageMode && <BackIcon />,
        onClick: () => {
          if (!location.state || !location.state.isRedirectFromExportEvents) {
            navigate(-1);
            return;
          }

          const redirectLink = location.state.isHistoryView ? AppRoute.eventsHistory : AppRoute.events;

          navigate(redirectLink, {
            state: {
              displayExportEventsModal: true,
            },
          });
        },
      }}
      header={pageHeader}
      isLoading={isLoading}
      renderContent={() => (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Styled.TopBar gridTemplateColumns="1fr auto auto">
            {!isPreviewPageMode && (
              <>
                <TopBar.Button onClick={() => navigate(-1)} variant="outlined" color="primary">
                  {formatMessage({ id: AppMessages['common.cancel'] })}
                </TopBar.Button>

                <Styled.TopBarButton
                  type="submit"
                  disabled={!isDirty || !isValid || !isAnyParameterEnabled}
                  variant="contained"
                  color="primary"
                  isLoading={isLoadingAddExportFormat}
                >
                  {formatMessage({ id: AppMessages['common.save'] })}
                </Styled.TopBarButton>
              </>
            )}
          </Styled.TopBar>

          <Styled.PageGrid>
            <Styled.GridLeftColumn>
              <Styled.SectionTitle variant="h4">
                {formatMessage({ id: AppMessages['definitions.events.addExportFormat.parameters'] })}
              </Styled.SectionTitle>

              <ParametersForm
                eventsExportFormatConfig={eventsExportFormatConfig}
                isPreviewPageMode={isPreviewPageMode}
              />
            </Styled.GridLeftColumn>

            <Styled.GridRightColumn>
              <Styled.SectionTitle variant="h4">
                {formatMessage({ id: AppMessages['definitions.events.addExportFormat.settings'] })}
              </Styled.SectionTitle>

              <Styled.SectionBox>
                <SettingsForm
                  eventsExportFormatConfig={eventsExportFormatConfig}
                  isPreviewPageMode={isPreviewPageMode}
                />
              </Styled.SectionBox>

              <Styled.Break />

              <Styled.SectionTitle variant="h4">
                {formatMessage({ id: AppMessages['definitions.events.addExportFormat.preview'] })}
              </Styled.SectionTitle>

              <Styled.SectionBox>
                <PreviewForm
                  eventExportFormatPreview={eventExportFormatPreview}
                  isLoadingPreviewEventExportFormat={isLoadingPreviewEventExportFormat}
                  onPreviewEventExportFormat={onPreviewEventExportFormat}
                  isPreviewPageMode={isPreviewPageMode}
                />
              </Styled.SectionBox>
            </Styled.GridRightColumn>
          </Styled.PageGrid>
        </form>
      )}
    />
  );
};
