import { ListItem, ListItemText, Typography } from '@mui/material';
import styled, { css } from 'styled-components';

import { TableHeaderItemStylesProps } from './Table.types';

export const TableRowItem = styled(ListItemText)`
  padding: 0 17px;
  word-break: break-word;
`;

export const TableHeaderItem = styled(ListItem)(
  ({ theme: { palette } }) => css`
    text-transform: uppercase;
    color: ${palette.grey[900]};
    border: 1px solid transparent;
    padding: 0 16px;
    font-size: 12px;
  `,
);

export const TableHeaderItemSortable = styled.div<TableHeaderItemStylesProps>(
  ({ $isActive, $disabled, theme: { palette, shape } }) => css`
    display: grid;
    grid-template-columns: 1fr 20px;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    padding: 0 10px;
    margin-left: -10px;
    border: 1px solid ${$isActive ? palette.grey[500] : 'transparent'};
    border-radius: ${shape.borderRadius}px;
    cursor: ${$disabled ? 'not-allowed' : 'pointer'};
    line-height: 120%;
  `,
);

export const TableHeaderItemTextMaxWidth = styled(Typography)`
  margin-top: 2px;
  max-width: 90px;
`;

export const TableHeaderItemText = styled.span`
  margin-top: 2px;
`;
