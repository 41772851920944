import {
  OwnerIdSortEnum,
  OwnerNameSortEnum,
  OwnerRegistrationNumberSortEnum,
  OwnersQueryKeysEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useLocaleStoreOwnersQuery } from 'hooks/useLocaleStoreOwnersQuery/useLocaleStoreOwnersQuery';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';
import {
  YesNoFilterItemsDescriptionEnum,
  useYesNoFilterItems,
} from 'hooks/useYesNoHeaderFilterItems/useYesNoFilterItems';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';

import { OwnersTableHeaderProps } from './OwnersTableHeader.types';

export const OwnersTableHeader = ({ displayFullTable }: OwnersTableHeaderProps) => {
  const { formatMessage } = useLocale();
  const { ownersQuery, setOwnersQuery, ownersQueryLocalStorageName } = useLocaleStoreOwnersQuery();

  const { yesNoFilterItems } = useYesNoFilterItems();

  return (
    <>
      <Table.HeaderSortItem
        queryKey={ownersQueryLocalStorageName}
        sortBy={OwnerIdSortEnum}
        queryHeader={formatMessage({ id: AppMessages['owners.header.id'] })}
      />

      <Table.HeaderSortItem
        queryKey={ownersQueryLocalStorageName}
        sortBy={OwnerNameSortEnum}
        queryHeader={formatMessage({ id: AppMessages['owners.header.fullName'] })}
      />

      <OrganizationsTreeSelect
        multiple
        isFilterType
        onChange={(options) =>
          options &&
          setOwnersQuery({
            komorka: options.map((cell) => cell.id),
          })
        }
        value={ownersQuery.komorka?.map((id) => ({ id, nazwa: 'komorka' }))}
        placeholder={formatMessage({ id: AppMessages['owners.header.cell'] })}
      />

      {displayFullTable && (
        <Table.HeaderSearchFilterItem filterBy="positions-pkd">
          {formatMessage({ id: AppMessages['owners.header.position'] })}
        </Table.HeaderSearchFilterItem>
      )}

      <Table.HeaderFilterItem
        queryKey={ownersQueryLocalStorageName}
        queryName={OwnersQueryKeysEnum.KARTA}
        filterItems={yesNoFilterItems}
        queryHeader="KARTA"
        queryHeaderDescriptions={YesNoFilterItemsDescriptionEnum}
      />

      <Table.HeaderSortItem
        queryKey={ownersQueryLocalStorageName}
        sortBy={OwnerRegistrationNumberSortEnum}
        queryHeader={formatMessage({ id: AppMessages['owners.header.registrationNumber'] })}
      />
    </>
  );
};
