import { QueryKeyLocalStorageEnum, TerminalWithDisplayIdSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';

export const TerminalsTableHeader = () => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.TERMINALS_WITH_DISPLAY}
        sortBy={TerminalWithDisplayIdSortEnum}
        queryHeader={formatMessage({ id: AppMessages['common.id'] })}
      />

      <Table.HeaderItem>{formatMessage({ id: AppMessages['definitions.display.readerName'] })}</Table.HeaderItem>
    </>
  );
};
