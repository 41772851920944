import { FormHelperText } from '@mui/material';
import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
  grid-column: 1/-1;
`;

export const FieldWrapper = styled.div`
  margin-top: 22px;
`;

export const ErrorMessage = styled(FormHelperText)(
  ({ theme: { palette } }) => css`
    display: block;
    font-weight: 700;
    color: ${palette.error.main};
    height: 24px;
  `,
);
