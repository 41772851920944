import { Typography } from '@mui/material';
import { Table, Tooltip } from 'shared-ui';

import { RowCellsItemProps } from './RowCellsItem.types';
import * as Styled from './RowCellsItem.styles';

const RowCellsItem = ({ cells, withMoreCellsNumber, oneLineTooltipItems }: RowCellsItemProps) => {
  if (!cells || !cells.length) {
    return <Table.RowItem></Table.RowItem>;
  }

  const sortedCells = cells.sort((a, b) =>
    typeof a.nazwa === 'string' && typeof b.nazwa === 'string' ? a.nazwa.localeCompare(b.nazwa) : a > b ? 1 : -1,
  );

  const tooltipBody = (shouldSliceFirstItem: boolean) => (
    <div style={{ display: oneLineTooltipItems ? 'flex' : undefined }}>
      {sortedCells
        .map((cell, index) => (
          <Typography key={cell.id}>
            {oneLineTooltipItems && sortedCells.length - 1 !== index ? `${cell.nazwa}, ` : cell.nazwa}
          </Typography>
        ))
        .splice(shouldSliceFirstItem ? 1 : 0, cells.length)}
    </div>
  );

  if (withMoreCellsNumber && sortedCells.length > 1) {
    return (
      <Table.RowItem>
        <Styled.CellWrapper>
          <Styled.Cell noWrap>{sortedCells[0].nazwa}</Styled.Cell>

          <Tooltip placement="bottom-start" title={tooltipBody(true)}>
            <Styled.Cell>+{sortedCells.length - 1}</Styled.Cell>
          </Tooltip>
        </Styled.CellWrapper>
      </Table.RowItem>
    );
  }

  return (
    <Table.RowItem>
      <Styled.CellWrapper>
        {sortedCells.length === 1 ? (
          <Styled.Cell noWrap>{sortedCells[0].nazwa}</Styled.Cell>
        ) : (
          <Tooltip placement="bottom-start" title={tooltipBody(false)}>
            <Styled.Cell>{sortedCells.length}</Styled.Cell>
          </Tooltip>
        )}
      </Styled.CellWrapper>
    </Table.RowItem>
  );
};

// eslint-disable-next-line import/no-default-export
export default RowCellsItem;
