import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { JUST_LETTERS_REGEX } from 'core/global.variables';

export const useUserPersonalDetailsValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    imie: yup
      .string()
      .max(32, formatMessage({ id: AppMessages['users.addUser.validation.field.firstName'] }))
      .matches(JUST_LETTERS_REGEX, formatMessage({ id: AppMessages['common.validation.letters'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    nazwisko: yup
      .string()
      .max(32, formatMessage({ id: AppMessages['users.addUser.validation.field.lastName'] }))
      .matches(JUST_LETTERS_REGEX, formatMessage({ id: AppMessages['common.validation.letters'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    email: yup
      .string()
      .email(formatMessage({ id: AppMessages['users.addUser.validation.field.email'] }))
      .optional(),
    opis: yup
      .string()
      .max(60, formatMessage({ id: AppMessages['users.addUser.validation.field.description.max'] }))
      .optional(),
  });

  return { schema };
};
