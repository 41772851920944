import { InputLabel, Typography } from '@mui/material';
import { Button } from 'shared-ui';
import styled, { css } from 'styled-components';

import { ReactComponent as PhotoIconBase } from 'assets/icons/picture.svg';

export const DropzoneDescription = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.info.light};
    font-weight: 700;
    margin: -20px 0;
    font-size: 12px;
  `,
);

export const LogotypePlaceholder = styled.div<{ $width: number; $height: number }>(
  ({ theme: { palette, shape }, $height, $width }) => css`
    background-color: ${palette.grey['A400']};
    height: ${$height}px;
    width: ${$width}px;
    border-radius: ${shape.borderRadius}px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `,
);

export const AddLogotypeButton = styled(Button)<{ $width: number }>(
  ({ $width }) => css`
    width: ${$width}px;
    margin-top: 16px;
  `,
);

export const PhotoLabel = styled(InputLabel)`
  margin-bottom: 11px;
  font-weight: 700;
`;

export const UploadedPhoto = styled.img(
  ({ theme: { shape } }) => css`
    width: 100%;
    height: 100%;
    border-radius: ${shape.borderRadius}px;
  `,
);

export const PhotoIcon = styled(PhotoIconBase)`
  margin-bottom: 12px;
`;
