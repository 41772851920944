import { EditGroupGlcPermissionsContainer } from './editGroupGlcPermissions/EditGroupGlcPermissionsContainer';
import { GroupGlcPermissionMultiActionsProps } from './GroupGlcPermissionsMultiActions.types';
import { DeleteGroupGlcPermissionsContainer } from './deleteGroupGlcPermissions/DeleteGroupGlcPermissionsContainer';
import * as Styled from './GroupGlcPermissionsMultiActions.styles';

export const GroupGlcPermissionMultiActions = ({
  checkedPermissions,
  groupId,
  setCheckedPermissions,
}: GroupGlcPermissionMultiActionsProps) => {
  return (
    <Styled.Container>
      <EditGroupGlcPermissionsContainer checkedPermissions={checkedPermissions} groupId={groupId} />
      <DeleteGroupGlcPermissionsContainer
        checkedPermissions={checkedPermissions}
        groupId={groupId}
        setCheckedPermissions={setCheckedPermissions}
      />
    </Styled.Container>
  );
};
