import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { announcementKeywords } from 'app/announcements/announcementsTable/AnnouncementsList.types';
import { countOccurrences } from 'helpers/countOccurrences';
import { removeOccurrences } from 'helpers/removeOccurrences';
import { MAX_ANNOUNCEMENT_LENGTH } from 'core/global.variables';

export const useModifyMultiAnnouncementsValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    czasKomunikatu: yup
      .number()
      .transform((curr, orig) => (!orig ? null : curr))
      .optional()
      .nullable()
      .typeError(formatMessage({ id: AppMessages['common.validation.required'] }))
      .min(1, formatMessage({ id: AppMessages['announcements.field.validation.duration'] }))
      .max(60, formatMessage({ id: AppMessages['announcements.field.validation.duration'] })),
    potwierdzenie: yup
      .string()
      .transform((curr, orig) => (!orig ? null : curr))
      .optional()
      .nullable()
      .typeError(formatMessage({ id: AppMessages['common.validation.required'] })),
    komunikat: yup
      .string()
      .optional()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(formatMessage({ id: AppMessages['common.validation.required'] }))
      .test(
        'check if tags are used once',
        formatMessage({ id: AppMessages['announcement.add.announcement.validation.multiple'] }),
        (val) => {
          if (!val) return true;

          const isKeywordUsedMoreThanOnce = announcementKeywords.some(({ name }) => {
            return countOccurrences(val, name) > 1;
          });

          return !isKeywordUsedMoreThanOnce;
        },
      )
      .test(
        'check length of announcement',
        formatMessage({ id: AppMessages['announcement.add.announcement.validation.length'] }),
        (val) => {
          if (!val) return true;

          let updatedValue = val;

          announcementKeywords.forEach(({ name }) => (updatedValue = removeOccurrences(updatedValue, name)));

          return updatedValue.length < MAX_ANNOUNCEMENT_LENGTH;
        },
      ),
  });
  return { schema };
};
