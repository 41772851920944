import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { deleteMultiOwners } from 'api/owners/owners';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteMultiOwners } from './DeleteMultiOwners';
import { DeleteMultiOwnersContainerProps } from './DeleteMultiOwners.types';

export const DeleteMultiOwnersContainer = ({ checkedOwners, setCheckedOwners }: DeleteMultiOwnersContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedOwner, setSelectedOwner } = useSelectedOwner();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteMultiOwners, isLoading } = useMutation(deleteMultiOwners, {
    onSuccess: () => {
      if (selectedOwner && checkedOwners.some(({ id }) => id === selectedOwner?.id)) {
        setSelectedOwner(undefined);
      }

      queryClient.invalidateQueries([QueryKeyEnum.OWNERS]);
      toast.dark(formatMessage({ id: AppMessages['owners.multiAction.delete.success'] }));
      setCheckedOwners([]);
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteMultiOwners({ body: checkedOwners.map(({ id }) => id) });
  };

  return (
    <DeleteMultiOwners
      checkedOwners={checkedOwners}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
