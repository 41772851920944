import { useQueryClient, useErrorHandler } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { deleteAnnouncement } from 'api/announcements/announcements';
import { useSelectedAnnouncement } from 'hooks/useSelectedAnnouncement/useSelectedAnnouncement';

import { DeleteAnnouncement } from './DeleteAnnouncement';
import { DeleteAnnouncementContainerProps } from './DeleteAnnouncement.types';

export const DeleteAnnouncementContainer = ({ selectedAnnouncement }: DeleteAnnouncementContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { setSelectedAnnouncement } = useSelectedAnnouncement();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { handleError } = useErrorHandler();

  const { mutate: onDeleteAnnouncement, isLoading } = useMutation(deleteAnnouncement, {
    onSuccess: () => {
      setSelectedAnnouncement(undefined);

      queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENTS]);
      queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENTS_SEARCH]);

      toast.dark(formatMessage({ id: AppMessages['announcement.delete.success'] }));
      onModalClose();
    },
    onError: (error) => {
      handleError({ error });
    },
  });

  const onSubmit = () => {
    onDeleteAnnouncement(selectedAnnouncement.id);
  };

  return (
    <DeleteAnnouncement
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
