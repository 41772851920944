export const getObjectNestLevelForSpecificKey = <Type, Key extends keyof Type>(
  obj: Type,
  keyToFind: Key,
  level = 1,
): number => {
  const levels = obj
    ? Object.entries(obj).reduce((acc, [key, value]) => {
        if (key === keyToFind) {
          if (Array.isArray(value)) {
            const mapped = value.map((val) => getObjectNestLevelForSpecificKey(val, keyToFind, 1 + level));
            return [...acc, ...mapped];
          }

          if (value && typeof value === 'object') {
            return [...acc, getObjectNestLevelForSpecificKey(value, keyToFind as never, 1 + level)];
          }

          return [...acc, level];
        }
        return acc;
      }, [] as number[])
    : [];

  return Math.max(...levels);
};
