import { useState, useEffect, useMemo } from 'react';
import { useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import { assignCardToOwner } from 'api/cards/cards';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { useMutation } from 'hooks/useMutation/useMutation';
import { AppRouteWithParams } from 'routing/AppRoute.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { useModalState } from 'hooks/useModalState/useModalState';

import { OwnerAssign } from './OwnerAssign';
import { AssignCardOwnerFormBody, OwnerAssignContainerProps } from './OwnerAssign.types';
import { useOwnerAssignValidation } from './OwnerAssign.validation';

export const OwnerAssignContainer = ({ selectedCard, isCardBlockedManually }: OwnerAssignContainerProps) => {
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const [isFormOwnerArchived, setIsFormOwnerArchived] = useState(false);
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();
  const isHistoryView = useIsExactPath(AppRouteWithParams.cardHistory.path);

  const defaultValidFrom = useMemo(() => new Date(), [isModalOpen]);

  const { schema } = useOwnerAssignValidation(defaultValidFrom, isCardBlockedManually, isFormOwnerArchived);
  const formMethods = useForm<AssignCardOwnerFormBody>({
    defaultValues: {
      waznoscOd: isCardBlockedManually ? null : defaultValidFrom,
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });
  const { setSelectedCard } = useSelectedCard();
  const { selectedOwner } = useSelectedOwner();

  const selectedAutocompleteOwner = formMethods.watch('pkd');

  useEffect(() => {
    const isFormOwnerArchived = !!selectedAutocompleteOwner?.archiwalny;

    setIsFormOwnerArchived(isFormOwnerArchived);
    formMethods.setValue('waznoscOd', isFormOwnerArchived || isCardBlockedManually ? null : defaultValidFrom);
  }, [selectedAutocompleteOwner?.value]);

  useEffect(() => {
    formMethods.trigger();
  }, [isFormOwnerArchived]);

  const { mutate: onAssignCard, isLoading: isLoadingAssignOwner } = useMutation(assignCardToOwner, {
    onSuccess: (updatedCard) => {
      if (selectedOwner && updatedCard.posiadacz?.id === selectedOwner.id) {
        queryClient.invalidateQueries([QueryKeyEnum.OWNER_DETAILS, updatedCard.posiadacz?.id]);
      }
      setSelectedCard(updatedCard);
      queryClient.invalidateQueries([isHistoryView ? QueryKeyEnum.CARD_HISTORY : QueryKeyEnum.CARDS]);
      onModalClose();
      toast.dark(formatMessage({ id: AppMessages['card.assign.success'] }));
    },
  });

  useEffect(() => {
    formMethods.reset({
      waznoscOd: isCardBlockedManually ? null : defaultValidFrom,
    });
  }, [isModalOpen]);

  const handleFormSubmit = (body: AssignCardOwnerFormBody) => {
    onAssignCard({
      id: selectedCard.id,
      body: {
        ...body,
        pkdId: `${body.pkd.value}`,
        waznoscOd: isCardBlockedManually ? null : body.waznoscOd || defaultValidFrom,
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <OwnerAssign
        selectedCard={selectedCard}
        handleFormSubmit={handleFormSubmit}
        isLoadingAssignOwner={isLoadingAssignOwner}
        isAssignModalOpen={isModalOpen}
        onCloseAssignModal={onModalClose}
        onOpenAssignModal={onModalOpen}
        isCardBlockedManually={isCardBlockedManually}
        isFormOwnerArchived={isFormOwnerArchived}
      />
    </FormProvider>
  );
};
