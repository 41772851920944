import { StrictMode } from 'react';
import { SharedUiProvider } from 'shared-ui';

import { QueryContextController } from 'context/query/queryContextController/QueryContextController';
import { SelectedCardContextController } from 'context/selectedCard/selectedCardContextController/SelectedCardContextController';
import { SelectedEventContextController } from 'context/selectedEvent/selectedEventContextController/SelectedEventContextController';
import { SelectedGroupContextController } from 'context/selectedGroup/selectedGroupContextController/SelectedGroupContextController';
import { SelectedGroupTerminalPermissionContextController } from 'context/selectedGroupTerminalPermission/selectedGroupTerminalPermissionContextController/SelectedGroupTerminalPermissionContextController';
import { SelectedOrganizationContextController } from 'context/selectedOrganization/selectedOrganizationContextController/SelectedOrganizationContextController';
import { SelectedOwnerContextController } from 'context/selectedOwner/selectedOwnerContextController/SelectedOwnerContextController';
import { SelectedOwnerIndividualPermissionContextController } from 'context/selectedOwnerIndividualPermission/selectedOwnerIndividualPermissionContextController/SelectedOwnerIndividualPermissionContextController';
import { SelectedPositionContextController } from 'context/selectedPosition/selectedPositionContextController/SelectedPositionContextController';
import { SelectedServerContextController } from 'context/selectedServer/selectedServerContextController/SelectedServerContextController';
import { SelectedTerminalContextController } from 'context/selectedTerminal/selectedTerminalContextController/SelectedServerContextController';
import { SelectedUserContextController } from 'context/selectedUser/selectedUserContextController/SelectedUserContextController';
import { LogoutModalContextController } from 'context/logoutModal/logoutModalContextController/LogoutModalContextController';
import { ExportEventFormContextController } from 'context/exportEventForm/exportEventFormContextController/ExportEventFormContextController';
import { SelectedGlcContextController } from 'context/selectedGlc/selectedGlcContextController/SelectedGlcContextController';
import { SelectedGroupGlcPermissionContextController } from 'context/selectedGroupGlcPermission/selectedGroupGlcPermissionContextController/SelectedGroupGlcPermissionContextController';
import { SelectedAnnouncementContextController } from 'context/selectedAnnouncement/selectedAnnouncementContextController/SelectedAnnouncementContextController';
import { translations } from 'i18n/messages';

import { AppProvidersProps } from './AppProviders.types';
import { AuthProvider } from './auth/AuthProvider';

export const AppProviders = ({ children }: AppProvidersProps) => (
  <StrictMode>
    <SharedUiProvider translations={translations}>
      <QueryContextController>
        <AuthProvider>
          <LogoutModalContextController>
            <SelectedOwnerContextController>
              <SelectedCardContextController>
                <SelectedEventContextController>
                  <SelectedServerContextController>
                    <SelectedTerminalContextController>
                      <SelectedOrganizationContextController>
                        <SelectedPositionContextController>
                          <SelectedGroupContextController>
                            <SelectedGroupTerminalPermissionContextController>
                              <SelectedGroupGlcPermissionContextController>
                                <SelectedOwnerIndividualPermissionContextController>
                                  <SelectedUserContextController>
                                    <SelectedGlcContextController>
                                      <SelectedAnnouncementContextController>
                                        <ExportEventFormContextController>{children}</ExportEventFormContextController>
                                      </SelectedAnnouncementContextController>
                                    </SelectedGlcContextController>
                                  </SelectedUserContextController>
                                </SelectedOwnerIndividualPermissionContextController>
                              </SelectedGroupGlcPermissionContextController>
                            </SelectedGroupTerminalPermissionContextController>
                          </SelectedGroupContextController>
                        </SelectedPositionContextController>
                      </SelectedOrganizationContextController>
                    </SelectedTerminalContextController>
                  </SelectedServerContextController>
                </SelectedEventContextController>
              </SelectedCardContextController>
            </SelectedOwnerContextController>
          </LogoutModalContextController>
        </AuthProvider>
      </QueryContextController>
    </SharedUiProvider>
  </StrictMode>
);
