import styled from 'styled-components';
import { Button } from 'shared-ui';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 16px;
`;

export const DeleteButton = styled(Button)`
  min-width: 110px;
  width: 110px;
`;

export const ReturnButton = styled(Button)`
  min-width: 120px;
  width: 120px;
`;

export const Wrapper = styled.div`
  width: 50%;
`;
