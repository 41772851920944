export enum GroupTypeEnum {
  DYNAMICZNA = 'DYNAMICZNA',
  STATYCZNA = 'STATYCZNA',
}

export enum GroupPermissionsHistoryActionDescriptionEnum {
  DODANO = 'group.history.action.added',
  ZEDYTOWANO = 'group.history.action.edited',
  USUNIETO = 'group.history.action.deleted',
}
