import { useContext } from 'react';

import { SelectedCardContext } from 'context/selectedCard/selectedCardContext/SelectedCardContext';

export const useSelectedCard = () => {
  const context = useContext(SelectedCardContext);

  if (context === undefined) {
    throw new Error('SelectedCardContext is unavailable, make sure you are using SelectedCardContextController');
  }

  return context;
};
