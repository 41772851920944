import { FilterOptionsState } from '@mui/material';
import { Option } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { fetchGroups } from 'api/groups/groups';
import { useQuery } from 'hooks/useQuery/useQuery';
import { GroupsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';
import * as Styled from '../TableSearch.styles';

export const useSearchByGroups = (optionsQuery: string, isEnabled: boolean) => {
  const { formatMessage } = useLocale();

  const { data: groupsResponse, isInitialLoading } = useQuery([QueryKeyEnum.GROUPS_SEARCH, optionsQuery], fetchGroups, {
    args: { query: optionsQuery, sort: [GroupsIdSortEnum.ASC] },
    enabled: isEnabled,
  });

  const groups = groupsResponse?.data || [];

  const createOptions = () => {
    return groups.map((group) => ({ value: group.id, label: group.nazwa, id: group.idGrupy }));
  };

  const filterOptions = (options: Option[], { inputValue }: FilterOptionsState<Option>) => {
    const standarizedInputValue = inputValue.toLowerCase();

    return options.filter(
      ({ label, value, ...rest }) =>
        label.toLowerCase().includes(standarizedInputValue) ||
        `${value}`.toLowerCase().includes(standarizedInputValue) ||
        `${rest?.number}`.toLowerCase().includes(standarizedInputValue) ||
        `${rest?.id}`.toLowerCase().includes(standarizedInputValue),
    );
  };

  const createOptionLabel = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <li {...optionProps}>
        <Styled.OptionNumber>
          {formatMessage({ id: AppMessages['tableSearch.group.id'] })}{' '}
          {groups.find((group) => group.id === option.value)?.idGrupy}
        </Styled.OptionNumber>

        <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
      </li>
    );
  };

  return { isLoading: isInitialLoading, createOptions, createOptionLabel, filterOptions };
};
