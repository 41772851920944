import { useEffect } from 'react';
import { usePagination } from 'shared-ui';

import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum, WantedHistoryQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { fetchServers } from 'api/servers/servers';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { fetchWantedHistory } from 'api/wanted/wanted';

import { useWantedHistorySearchQuery } from './hooks/useWantedHistorySearchQuery';
import { WantedHistory } from './WantedHistory';

export const WantedHistoryContainer = () => {
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();
  const {
    storedQueries: { wantedHistory: wantedHistoryQuery },
    setQuery,
  } = useLocaleStoreQuery();
  const {
    tkdSearchQuery,
    executorSearchQuery,
    ownerSearchQuery,
    setExecutorSearchQuery,
    setOwnerSearchQuery,
    setTkdSearchQuery,
    clearSearchQueries,
  } = useWantedHistorySearchQuery();

  const { data: serversResponse } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  const { data: wantedHistoryResponse, isLoading } = useQuery(
    [
      QueryKeyEnum.WANTED_HISTORY,
      wantedHistoryQuery,
      rowsPerPage,
      page,
      tkdSearchQuery,
      executorSearchQuery,
      ownerSearchQuery,
    ],
    () => fetchWantedHistory(),
    {
      args: {
        ...wantedHistoryQuery,
        [WantedHistoryQueryKeysEnum.SERWER]: wantedHistoryQuery.serwer?.value,
        [WantedHistoryQueryKeysEnum.CZYTNIK]: wantedHistoryQuery.tkd?.value,
        [WantedHistoryQueryKeysEnum.QUERY]: wantedHistoryQuery.query?.value,
        [WantedHistoryQueryKeysEnum.POSIADACZ]: wantedHistoryQuery.posiadacz?.value,
        [WantedHistoryQueryKeysEnum.INFORMACJA]: wantedHistoryQuery.informacja,
        [WantedHistoryQueryKeysEnum.LIMIT]: rowsPerPage,
        [WantedHistoryQueryKeysEnum.OFFSET]: rowsPerPage * page,
      },
    },
  );

  useEffect(() => {
    setPage(0);
  }, [tkdSearchQuery, executorSearchQuery, wantedHistoryQuery]);

  const onClearWantedHistoryQuery = () => {
    setQuery(QueryKeyLocalStorageEnum.WANTED_HISTORY, {});
    clearSearchQueries();
  };

  return (
    <WantedHistory
      wantedHistoryResponse={wantedHistoryResponse}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      servers={serversResponse?.data || []}
      tkdSearchQuery={tkdSearchQuery}
      executorSearchQuery={executorSearchQuery}
      ownerSearchQuery={ownerSearchQuery}
      setExecutorSearchQuery={setExecutorSearchQuery}
      setOwnerSearchQuery={setOwnerSearchQuery}
      setTkdSearchQuery={setTkdSearchQuery}
      onClearWantedHistoryQuery={onClearWantedHistoryQuery}
    />
  );
};
