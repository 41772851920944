import { useNavigate } from 'react-router-dom';

import { AppRouteWithParams } from 'routing/AppRoute.enum';
import { AddEventExportFormatPageModeEnum } from '../../addEventExportFormat/AddEventExportFormat.enum';

import { DeleteEventExportFormatContainer } from './deleteEventExportFormat/DeleteEventExportFormatContainer';
import { EventExportFormatsActionsProps } from './EventsExportFormatsActions.types';
import * as Styled from './EventsExportFormatsActions.styles';

export const EventsExportFormatsActions = ({ eventExportFormat }: EventExportFormatsActionsProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Styled.PenIcon
        onClick={() =>
          navigate(
            AppRouteWithParams.eventsExportFormats.get({
              id: eventExportFormat.id,
              mode: AddEventExportFormatPageModeEnum.EDIT,
            }),
          )
        }
      />

      <DeleteEventExportFormatContainer eventExportFormat={eventExportFormat} />
    </>
  );
};
