import { DateRange } from '@mui/lab';
import { formatISO } from 'date-fns';
import { Select } from 'shared-ui';
import isEqual from 'lodash.isequal';

import { EventsQueryKeysEnum, QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { EventEnum, EventModeEnum, EventModEnum } from 'api/events/events.enum';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { HistoryDatepickerWithModal } from 'reusable/historyDatepickerWithModal/HistoryDatepickerWithModal';
import { DEFAULT_QUERY_STATE } from 'context/query/queryContextController/QueryContextController.variables';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';

import { EventsFiltersProps } from './EventsFilters.types';
import * as Styled from './EventsFilters.styles';

export const EventsFilters = ({
  servers,
  setTableSearchQuery,
  tableSearchQuery,
  setArchivedSearchSelectedValue,
  archivedSearchSelectedValue,
}: EventsFiltersProps) => {
  const { formatMessage } = useLocale();
  const {
    setQuery: setEventsHistoryQuery,
    storedQueries: { [QueryKeyLocalStorageEnum.EVENTS_HISTORY]: eventsHistoryQuery },
  } = useLocaleStoreQuery();
  const { eventOptions, modOptions, modeOptions, yesNoOptions, serverOptions } = useAppSelectOptions({ servers });

  const handleEventsHistoryQueryChange = (eventHistoryQuery: typeof eventsHistoryQuery) => {
    setEventsHistoryQuery(QueryKeyLocalStorageEnum.EVENTS_HISTORY, eventHistoryQuery);
  };

  const isDefaultFilters = isEqual(eventsHistoryQuery, DEFAULT_QUERY_STATE.eventsHistory);
  const isClearFiltersButtonVisible =
    (!isDefaultFilters && Object.values(eventsHistoryQuery).some((query) => query !== undefined)) || tableSearchQuery;

  const handleSetDates = (dates: DateRange<Date>) => {
    setEventsHistoryQuery(QueryKeyLocalStorageEnum.EVENTS_HISTORY, {
      ...eventsHistoryQuery,
      dataOd: dates[0] ? formatISO(dates[0]) : undefined,
      dataDo: dates[1] ? formatISO(dates[1]) : undefined,
    });
  };

  const onClearCardHistoryQuery = () => {
    handleEventsHistoryQueryChange({});
    setTableSearchQuery('');
  };

  const createSelectIcons = (props: Record<string, unknown>, queryKey: EventsQueryKeysEnum) =>
    eventsHistoryQuery[queryKey] !== undefined ? (
      <Styled.RemoveIcon
        onClick={() =>
          handleEventsHistoryQueryChange({
            ...eventsHistoryQuery,
            [queryKey]: undefined,
          })
        }
      />
    ) : (
      <Styled.SelectIcon {...props} />
    );

  return (
    <Styled.FiltersWrapper>
      <Styled.Filters>
        <HistoryDatepickerWithModal
          dates={[
            eventsHistoryQuery.dataOd ? new Date(eventsHistoryQuery.dataOd) : null,
            eventsHistoryQuery.dataDo ? new Date(eventsHistoryQuery.dataDo) : null,
          ]}
          setDates={handleSetDates}
        />

        <Select
          fullWidth
          variant="outlined"
          onChange={(e) =>
            handleEventsHistoryQueryChange({
              ...eventsHistoryQuery,
              [EventsQueryKeysEnum.ZDARZENIE]: e.target.value as EventEnum,
            })
          }
          value={eventsHistoryQuery[EventsQueryKeysEnum.ZDARZENIE] || ''}
          IconComponent={(props) => createSelectIcons(props, EventsQueryKeysEnum.ZDARZENIE)}
          placeholder={formatMessage({ id: AppMessages['events.filters.event.placeholder'] })}
          options={eventOptions}
        />

        <Select
          fullWidth
          variant="outlined"
          onChange={(e) =>
            handleEventsHistoryQueryChange({
              ...eventsHistoryQuery,
              [EventsQueryKeysEnum.MOD]: e.target.value as EventModEnum,
            })
          }
          value={eventsHistoryQuery[EventsQueryKeysEnum.MOD] || ''}
          IconComponent={(props) => createSelectIcons(props, EventsQueryKeysEnum.MOD)}
          placeholder={formatMessage({ id: AppMessages['events.filters.mod.placeholder'] })}
          options={modOptions}
        />

        <Select
          fullWidth
          variant="outlined"
          onChange={(e) =>
            handleEventsHistoryQueryChange({
              ...eventsHistoryQuery,
              [EventsQueryKeysEnum.TRYB]: e.target.value as EventModeEnum,
            })
          }
          value={eventsHistoryQuery[EventsQueryKeysEnum.TRYB] || ''}
          IconComponent={(props) => createSelectIcons(props, EventsQueryKeysEnum.TRYB)}
          placeholder={formatMessage({ id: AppMessages['events.filters.mode.placeholder'] })}
          options={modeOptions}
        />

        <Select
          fullWidth
          variant="outlined"
          onChange={(e) =>
            handleEventsHistoryQueryChange({
              ...eventsHistoryQuery,
              [EventsQueryKeysEnum.RCP]: JSON.parse(e.target.value as string),
            })
          }
          value={JSON.stringify(eventsHistoryQuery[EventsQueryKeysEnum.RCP])}
          IconComponent={(props) => createSelectIcons(props, EventsQueryKeysEnum.RCP)}
          placeholder={formatMessage({ id: AppMessages['events.filters.rcp.placeholder'] })}
          options={yesNoOptions}
        />

        <TableSearchContainer
          withoutToggle
          inputSize="big"
          searchBy="cardsAndOwners"
          tableSearchQuery={tableSearchQuery}
          setTableSearchQuery={setTableSearchQuery}
          inputPlaceholder={formatMessage({ id: AppMessages['events.filters.search.placeholder'] })}
          selectedValue={archivedSearchSelectedValue}
          setSelectedValue={setArchivedSearchSelectedValue}
        />

        {!!servers.length && (
          <Select
            fullWidth
            variant="outlined"
            onChange={(e) =>
              handleEventsHistoryQueryChange({
                ...eventsHistoryQuery,
                [EventsQueryKeysEnum.SERVER]: e.target.value as string,
              })
            }
            value={eventsHistoryQuery[EventsQueryKeysEnum.SERVER] || ''}
            IconComponent={(props) => createSelectIcons(props, EventsQueryKeysEnum.SERVER)}
            placeholder={formatMessage({ id: AppMessages['events.filters.server.placeholder'] })}
            options={serverOptions}
          />
        )}

        <Select
          fullWidth
          variant="outlined"
          onChange={(e) =>
            handleEventsHistoryQueryChange({
              ...eventsHistoryQuery,
              [EventsQueryKeysEnum.POSZUKIWANY]: JSON.parse(e.target.value as string),
            })
          }
          value={JSON.stringify(eventsHistoryQuery[EventsQueryKeysEnum.POSZUKIWANY])}
          IconComponent={(props) => createSelectIcons(props, EventsQueryKeysEnum.POSZUKIWANY)}
          placeholder={formatMessage({ id: AppMessages['events.filters.wanted.placeholder'] })}
          options={yesNoOptions}
        />

        <Select
          fullWidth
          variant="outlined"
          onChange={(e) =>
            handleEventsHistoryQueryChange({
              ...eventsHistoryQuery,
              [EventsQueryKeysEnum.REWIZJA]: JSON.parse(e.target.value as string),
            })
          }
          value={JSON.stringify(eventsHistoryQuery[EventsQueryKeysEnum.REWIZJA])}
          IconComponent={(props) => createSelectIcons(props, EventsQueryKeysEnum.REWIZJA)}
          placeholder={formatMessage({ id: AppMessages['events.filters.control.placeholder'] })}
          options={yesNoOptions}
        />
      </Styled.Filters>

      {isClearFiltersButtonVisible && (
        <Styled.ClearFiltersWrapper>
          <Styled.ClearFiltersButton onClick={onClearCardHistoryQuery}>
            {formatMessage({ id: AppMessages['common.clear'] })}
            <Styled.ClearFiltersIcon />
          </Styled.ClearFiltersButton>
        </Styled.ClearFiltersWrapper>
      )}
    </Styled.FiltersWrapper>
  );
};
