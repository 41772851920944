import { UseQueryFn, MutationFn } from 'shared-ui';

import { Position, UpdatePositionBody, AddPositionBody } from './positions.types';

export const fetchPositions: UseQueryFn<unknown, Position[]> = () => {
  return {
    endpoint: `/stanowisko`,
  };
};

export const updatePosition: MutationFn<{ id: string; body: UpdatePositionBody }, Position> = ({ id, body }) => {
  return {
    endpoint: `/stanowisko/${id}`,
    method: 'PUT',
    body,
  };
};

export const addPosition: MutationFn<AddPositionBody, Position> = (body) => {
  return {
    endpoint: `/stanowisko`,
    method: 'POST',
    body,
  };
};

export const deletePosition: MutationFn<string, void> = (id) => {
  return {
    endpoint: `/stanowisko/${id}`,
    method: 'DELETE',
  };
};
