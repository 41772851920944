import { useContext } from 'react';

import { SelectedUserContext } from 'context/selectedUser/selectedUserContext/SelectedUserContext';

export const useSelectedUser = () => {
  const context = useContext(SelectedUserContext);

  if (context === undefined) {
    throw new Error('SelectedUserContext is unavailable, make sure you are using SelectedUserContextController');
  }

  return context;
};
