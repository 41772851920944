import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useAddOrganizationItemValidation = (allOrganizationCodes: string[]) => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    kod: yup
      .string()
      .max(6, formatMessage({ id: AppMessages['definitions.organization.validation.code'] }))
      .test(
        'check organization code duplicate',
        formatMessage({ id: AppMessages['definitions.organization.add.name.validation'] }),
        (code) => !(code && allOrganizationCodes.includes(code.toLowerCase())),
      )
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    nazwa: yup
      .string()
      .max(60, formatMessage({ id: AppMessages['definitions.organization.validation.name'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
  });

  return { schema };
};
