import { AddPhotoModal, useLocale } from 'shared-ui';

import { AppMessages } from 'i18n/messages';
import { ADD_LOGO_DROPZONE_HEIGHT, ADD_LOGO_DROPZONE_WIDTH } from '../Logotype';
import * as Styled from '../Logotype.styles';

import { AddLogotypeProps } from './AddLogotype.types';

const MAX_PHOTO_WIDTH = 1600;
const MAX_PHOTO_HEIGHT = 1200;

export const AddLogotype = ({ isModalOpen, onModalClose, onModalOpen, onSubmit }: AddLogotypeProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Styled.AddLogotypeButton variant="contained" onClick={() => onModalOpen()} $width={ADD_LOGO_DROPZONE_WIDTH}>
        {formatMessage({ id: AppMessages['common.add'] })}
      </Styled.AddLogotypeButton>

      <AddPhotoModal
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        clearOnClose
        modalTitle={formatMessage({ id: AppMessages['definitions.logotype.addFile'] })}
        submitButtonText={formatMessage({ id: AppMessages['common.add'] })}
        maxPhotoDimensions={{ width: MAX_PHOTO_WIDTH, height: MAX_PHOTO_HEIGHT }}
        dropzoneDimensions={{ width: ADD_LOGO_DROPZONE_WIDTH, height: ADD_LOGO_DROPZONE_HEIGHT }}
        errorAlignedLeft
        dropzoneDescription={() => (
          <Styled.DropzoneDescription>
            {formatMessage({ id: AppMessages['definitions.logotype.addFile.description'] })}
          </Styled.DropzoneDescription>
        )}
      />
    </>
  );
};
