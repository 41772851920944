import { useState } from 'react';
import { ClientError, APIError, useQueryClient, useErrorHandler } from 'shared-ui';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { deleteGroup } from 'api/groups/groups';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useSelectedGroup } from 'hooks/useSelectedGroup/useSelectedGroup';
import { DeleteGroupErrorResponse } from 'api/groups/groups.types';
import { AppRoute, AppRouteWithParams } from 'routing/AppRoute.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteGroup } from './DeleteGroup';
import { DeleteGroupContainerProps } from './DeleteGroup.types';

export const DeleteGroupContainer = ({ selectedGroup }: DeleteGroupContainerProps) => {
  const [deleteErrorType, setDeleteErrorType] = useState<DeleteGroupErrorResponse['type']>();
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { setSelectedGroup } = useSelectedGroup();
  const navigate = useNavigate();
  const isHistoryView = useIsExactPath(AppRouteWithParams.groupsHistory.path);
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { handleError } = useErrorHandler();

  const { mutate: onDeleteGroup, isLoading } = useMutation(deleteGroup, {
    onSuccess: () => {
      setSelectedGroup(undefined);
      queryClient.invalidateQueries([QueryKeyEnum.GROUPS]);
      isHistoryView && navigate(AppRoute.groups);
      toast.dark(formatMessage({ id: AppMessages['group.delete.success'] }));
      onModalClose();
    },
    onError: (error: ClientError<APIError<DeleteGroupErrorResponse>>) => {
      const errorType = error.response?.data.type;

      if (errorType) {
        onModalClose();
        return setDeleteErrorType(errorType);
      }

      handleError({ error, omitAPIErrorMessage: true });
    },
  });

  const onSubmit = () => {
    onDeleteGroup(selectedGroup.id);
  };

  return (
    <DeleteGroup
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onCloseDeleteModal={() => {
        setDeleteErrorType(undefined);
      }}
      isDeleteError={!!deleteErrorType}
      errorMessage={formatMessage({
        id: AppMessages[
          deleteErrorType === 'komorki_exists'
            ? 'group.delete.success.error.cells'
            : 'group.delete.success.error.owners'
        ],
      })}
    />
  );
};
