import { Button } from 'shared-ui';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { DeleteMultiOwnersProps } from './DeleteMultiOwners.types';

export const DeleteMultiOwners = ({
  checkedOwners,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
}: DeleteMultiOwnersProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<TrashIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['cards.multiAction.delete.button'] })}
      </Button>

      <ActionModal
        header={formatMessage({ id: AppMessages['owners.multiAction.delete.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['owners.multiAction.delete.submitButton'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['owners.multiAction.delete.subtitle'] })} {checkedOwners.length}
        </ActionModal.Subtitle>

        <ActionModal.Text>{formatMessage({ id: AppMessages['owners.multiAction.delete.text'] })}</ActionModal.Text>

        <ActionModal.Text>{formatMessage({ id: AppMessages['common.irreversibleAction'] })}</ActionModal.Text>
      </ActionModal>
    </>
  );
};
