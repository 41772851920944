import { ReactComponent as CollapseIcon } from 'assets/icons/collapse.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/expand.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { OrganizationHeaderProps } from './OrganizationHeader.types';
import * as Styled from './OrganizationHeader.styles';

export const OrganizationHeader = ({ isAllNodesExpanded, handleToggleAll }: OrganizationHeaderProps) => {
  const { formatMessage } = useLocale();

  return (
    <Styled.OrganizationHeader>
      <Styled.ExpandAllWrapper>
        <Styled.ToggleExpand
          data-testid="toggle-expand-button"
          checked={isAllNodesExpanded}
          onClick={handleToggleAll}
          icon={<CollapseIcon />}
          checkedIcon={<ExpandIcon />}
        />
      </Styled.ExpandAllWrapper>
      <Styled.ColumnsWrapper>
        <Styled.ColumnHeader> {formatMessage({ id: AppMessages['definitions.header.code'] })}</Styled.ColumnHeader>
        <Styled.ColumnHeader> {formatMessage({ id: AppMessages['definitions.header.name'] })}</Styled.ColumnHeader>
      </Styled.ColumnsWrapper>
    </Styled.OrganizationHeader>
  );
};
