import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { reloadManyTerminals } from 'api/terminals/terminals';
import { TerminalStatusEnum } from 'api/terminals/terminals.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useSelectedTerminal } from 'hooks/useSelectedTerminal/useSelectedTerminal';
import { QueryKeyEnum } from 'core/global.enum';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';

import { ReloadMultiTerminals } from './ReloadMultiTerminals';
import { ReloadMultiTerminalsContainerProps } from './ReloadMultiTerminals.types';

export const ReloadMultiTerminalsContainer = ({
  checkedTerminals,
  setCheckedTerminals,
  disabled,
}: ReloadMultiTerminalsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedTerminal, setSelectedTerminal } = useSelectedTerminal();

  const { mutate: onReloadManyTerminals } = useMutation(reloadManyTerminals, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.TERMINALS]);
      toast.dark(
        formatMessage({
          id: AppMessages['devices.multiAction.reload.successMessage'],
        }),
      );
      setCheckedTerminals([]);
      selectedTerminal && setSelectedTerminal({ ...selectedTerminal, status: TerminalStatusEnum.OK });
    },
  });

  const onSubmit = () => {
    onReloadManyTerminals(checkedTerminals.map((terminal) => terminal.id));
  };

  return <ReloadMultiTerminals onSubmit={onSubmit} disabled={disabled} />;
};
