import { useState } from 'react';

import { ExpandedServerIdContext } from '../expandedServerIdContext/ExpandedServerIdContext';

import { ExpandedServerIdContextControllerProps } from './ExpandedServerIdContextController.types';

export const ExpandedServerIdContextController = ({ children }: ExpandedServerIdContextControllerProps) => {
  const [expandedServerId, setExpandedServerId] = useState<string | undefined>(undefined);

  return (
    <ExpandedServerIdContext.Provider value={{ expandedServerId, setExpandedServerId }}>
      {children}
    </ExpandedServerIdContext.Provider>
  );
};
