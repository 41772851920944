import { MutationFn, UseQueryFn } from 'shared-ui';

import { Terminal } from 'api/terminals/terminals.types';
import { GlcQuery } from 'context/query/queryContext/QueryContext.types';
import { PaginatedResponse } from 'core/global.types';

import { AddGlcBody, AddGlcTerminalsBody, Glc, GlcHistory, UpdateGlcBody } from './glc.types';

export const fetchGlc: UseQueryFn<GlcQuery, PaginatedResponse<Glc>> = () => {
  return {
    endpoint: `/glc`,
  };
};

export const fetchGlcDetails: UseQueryFn<string, Glc> = (id) => {
  return {
    endpoint: `/glc/${id}`,
  };
};

export const addGlc: MutationFn<AddGlcBody, Glc> = (body) => {
  return {
    endpoint: `/glc`,
    method: 'POST',
    body,
  };
};

export const updateGlc: MutationFn<{ id: string; body: UpdateGlcBody }, Pick<Glc, 'id'>> = ({ id, body }) => {
  return {
    endpoint: `/glc/${id}`,
    method: 'PUT',
    body,
  };
};

export const fetchGlcHistory: UseQueryFn<string, PaginatedResponse<GlcHistory>> = (id) => {
  return {
    endpoint: `/glc/${id}/historia`,
    method: 'GET',
  };
};

export const fetchGlcTerminals: UseQueryFn<string, PaginatedResponse<Terminal>> = (glcId) => {
  return {
    endpoint: `/glc/${glcId}/tkd`,
  };
};

export const addGlcTerminals: MutationFn<AddGlcTerminalsBody, void> = ({ id, body }) => {
  return {
    endpoint: `glc/${id}/tkd`,
    method: 'POST',
    body,
  };
};

export const deleteGlcTerminals: MutationFn<{ id: string; body: string[] }, null> = ({ id, body }) => {
  return {
    endpoint: `/batch/glc/${id}/tkd`,
    method: 'DELETE',
    body,
  };
};

export const archiveGlc: MutationFn<string, Glc> = (id) => {
  return {
    endpoint: `/glc/${id}/archiwizuj`,
    method: 'POST',
  };
};

export const unarchiveGlc: MutationFn<string, Glc> = (id) => {
  return {
    endpoint: `/glc/${id}/przywroc`,
    method: 'POST',
  };
};

export const archiveMultiGlc: MutationFn<{ body: string[] }, void> = ({ body }) => {
  return {
    endpoint: '/batch/glc/archiwizuj',
    method: 'POST',
    body,
  };
};

export const unarchiveMultiGlc: MutationFn<{ body: string[] }, void> = ({ body }) => {
  return {
    endpoint: '/batch/glc/przywroc',
    method: 'POST',
    body,
  };
};

export const deleteGlc: MutationFn<string, null> = (id) => {
  return {
    endpoint: `/glc/${id}`,
    method: 'DELETE',
  };
};
