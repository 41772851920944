import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useErrorHandler, APIError, ClientError, useQueryClient, useLoaderToast } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { addGroupTerminalPermissions } from 'api/permissions/permissions';
import { fetchServers } from 'api/servers/servers';
import { fetchTerminals } from 'api/terminals/terminals';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useMutation } from 'hooks/useMutation/useMutation';
import { GroupTerminalsPermissionsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { DEFAULT_PERMISSIONS } from 'core/global.variables';
import { useResetPermissionsFormValues } from 'hooks/useResetPermissionsFormValues/useResetPermissionsFormValues';
import { useModalState } from 'hooks/useModalState/useModalState';

import { useAddGroupTerminalPermissionValidation } from './AddGroupTerminalPermission.validation';
import {
  AddGroupTerminalPermissionFormBody,
  AddGroupTerminalPermissionContainerProps,
} from './AddGroupTerminalPermission.types';
import { AddGroupTerminalPermission } from './AddGroupTerminalPermission';

export const AddGroupTerminalPermissionContainer = ({ groupId }: AddGroupTerminalPermissionContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { load: loadAddGroupTerminalPermissions, update: updateAddGroupTerminalPermissions } =
    useLoaderToast('addGroupTerminalPermissions');
  const [selectedServerId, setSelectedServerId] = useState('');
  const [terminalsQuery] = useState('');
  const { schema } = useAddGroupTerminalPermissionValidation();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { handleError } = useErrorHandler();

  const methods = useForm<AddGroupTerminalPermissionFormBody>({
    defaultValues: {
      ...DEFAULT_PERMISSIONS,
      idTkds: [],
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { data: serversResponse, isLoading: isLoadingServers } = useQuery(
    [QueryKeyEnum.SERVERS, groupId],
    () => fetchServers(groupId),
    {
      onSuccess: (serversResponse) => {
        if (serversResponse.data.length) {
          setSelectedServerId(serversResponse.data[0].id);
        }
      },
    },
  );

  const { data: terminalsResponse, isInitialLoading: isLoadingTerminals } = useQuery(
    [QueryKeyEnum.TERMINALS, selectedServerId, terminalsQuery],
    () => fetchTerminals(selectedServerId),
    {
      args: {
        zaakceptowane: true,
        sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
        withoutGlc: true,
      },
      enabled: !!selectedServerId,
    },
  );

  const { mutate: onAddGroupTerminalPermissions, isLoading } = useMutation(addGroupTerminalPermissions, {
    onMutate: ({ body }) => {
      const message =
        body.idTkds.length > 1
          ? 'group.reader.permissions.addPermissionMulti.loader'
          : 'group.reader.permissions.addPermission.loader';

      loadAddGroupTerminalPermissions(formatMessage({ id: AppMessages[message] }));
    },

    onSuccess: (_, { body }) => {
      queryClient.invalidateQueries([QueryKeyEnum.GROUP_TERMINALS_PERMISSIONS_SERVERS]);
      queryClient.invalidateQueries([QueryKeyEnum.GROUP_TERMINALS_PERMISSIONS]);

      const message =
        body.idTkds.length > 1
          ? 'group.reader.permissions.addPermissionMulti.success'
          : 'group.reader.permissions.addPermission.success';

      updateAddGroupTerminalPermissions(formatMessage({ id: AppMessages[message] }));
      onModalClose();
    },
    onError: (error: ClientError<APIError>) => {
      handleError({ error, updateToastCallback: updateAddGroupTerminalPermissions });
    },
  });

  const onSubmit = (body: AddGroupTerminalPermissionFormBody) => {
    onAddGroupTerminalPermissions({
      id: groupId,
      body: {
        ...body,
        idTkds: body.idTkds.map(({ value }) => `${value}`),
      },
    });
  };

  useResetPermissionsFormValues({ selectedValues: methods.watch('idTkds'), setFormValue: methods.setValue });

  useEffect(() => {
    methods.reset();
  }, [isModalOpen, selectedServerId]);

  return (
    <FormProvider {...methods}>
      <AddGroupTerminalPermission
        onSubmit={onSubmit}
        isModalOpen={isModalOpen}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
        isLoading={isLoading}
        servers={serversResponse?.data || []}
        selectedServerId={selectedServerId}
        setSelectedServerId={setSelectedServerId}
        isLoadingTerminals={isLoadingTerminals}
        terminals={terminalsResponse?.data || []}
        isLoadingServers={isLoadingServers}
      />
    </FormProvider>
  );
};
