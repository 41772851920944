import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useErrorHandler, useLoaderToast, ClientError, useQueryClient } from 'shared-ui';

import { updateEventDetails } from 'api/events/events';
import { AppMessages } from 'i18n/messages';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useSelectedEvent } from 'hooks/useSelectedEvent/useSelectedEvent';
import { useMutation } from 'hooks/useMutation/useMutation';
import { RcpEnum } from '../eventsFilters/EventsFilters.enum';

import { EventDetails } from './EventDetails';
import { EventDetailsContainerProps, EventDetailsEditForm } from './EventDetails.types';

export const EventDetailsContainer = ({ selectedEvent }: EventDetailsContainerProps) => {
  const { formatMessage } = useLocale();
  const { setSelectedEvent } = useSelectedEvent();
  const queryClient = useQueryClient();
  const { load: loadUpdateEvent, update: updateUpdateEvent } = useLoaderToast(`event-${selectedEvent.id}`);
  const { handleError } = useErrorHandler();

  const { mutate: onUpdateEvent, isLoading } = useMutation(updateEventDetails, {
    onMutate: () => loadUpdateEvent(formatMessage({ id: AppMessages['event.details.form.loader'] })),
    onSuccess: (_, { body }) => {
      setSelectedEvent({ ...selectedEvent, ...body });
      queryClient.invalidateQueries([QueryKeyEnum.EVENTS]);
      updateUpdateEvent(formatMessage({ id: AppMessages['event.details.form.success'] }));
    },
    onError: (error: ClientError<Error>) => {
      handleError({ error, updateToastCallback: updateUpdateEvent });
    },
  });

  const formMethods = useForm<EventDetailsEditForm>({
    mode: 'all',
    defaultValues: { ...selectedEvent, rcp: selectedEvent.rcp ? RcpEnum.TRUE : RcpEnum.FALSE },
  });

  useEffect(() => {
    formMethods.reset({ ...selectedEvent, rcp: selectedEvent.rcp ? RcpEnum.TRUE : RcpEnum.FALSE });
  }, [selectedEvent]);

  const onSubmit = (body: EventDetailsEditForm) => {
    onUpdateEvent({ id: selectedEvent.id, body: { ...selectedEvent, ...body, rcp: !!Number(body.rcp) } });
  };

  return (
    <FormProvider {...formMethods}>
      <EventDetails isLoading={isLoading} selectedEvent={selectedEvent} onSubmit={onSubmit} />
    </FormProvider>
  );
};
