import { useParams, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { PageLayout, TopBar, DetailsDrawer } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useReturnOwnerPermissionValue } from 'hooks/useReturnOwnerPermissionValue/useReturnOwnerPermissionValue';
import { AppMessages } from 'i18n/messages';
import { AppRoute, AppRouteWithParams } from 'routing/AppRoute.enum';
import { useSelectedOwnerIndividualPermission } from 'hooks/useSelectedOwnerIndividualPermission/useSelectedOwnerIndividualPermission';
import { useSelectedSearchOptions } from 'hooks/useSelectedSearchOptions/useSelectedSearchOptions';
import { SearchQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';

import { OwnerEffectivePermissionsContainer } from './ownerEffectivePermissions/OwnerEffectivePermissionsContainer';
import { OwnerIndividualPermissionsContainer } from './ownerIndividualPermissions/OwnerIndividualPermissionsContainer';
import { OwnerGroupsContainer } from './ownerGroups/OwnerGroupsContainer';
import { AddOwnerIndividualPermissionContainer } from './ownerIndividualPermissions/addOwnerIndividualPermission/AddOwnerIndividualPermissionContainer';
import { OwnerPermissionsProps } from './OwnerPermissions.types';
import { OwnerIndividualPermissionDetailsContainer } from './ownerIndividualPermissions/ownerIndividualPermissionDetails/OwnerIndividualPermissionDetailsContainer';

export const OwnerPermissions = ({ owner }: OwnerPermissionsProps) => {
  const [ownerGroupsTableSearchQuery, setOwnerGroupsTableSearchQuery] = useState('');
  const { formatMessage } = useLocale();
  const { id } = useParams<{ id: string }>();
  const ownerId = id || '';
  const { returnValueBasedOnOwnerPermissionLocation } = useReturnOwnerPermissionValue();
  const { selectedOwnerIndividualPermission } = useSelectedOwnerIndividualPermission();
  const location = useLocation();

  const { searchOption, setSelectedSearchOption } = useSelectedSearchOptions(
    owner?.archiwalny
      ? SearchQueryKeysEnum.ARCHIVED_OWNER_PERMISSIONS_GROUP
      : SearchQueryKeysEnum.ACTIVE_OWNER_PERMISSIONS_GROUP,
    setOwnerGroupsTableSearchQuery,
    location.pathname,
  );

  return (
    <PageLayout
      header={`${formatMessage({ id: AppMessages['permissions.title'] })} ${owner?.imie} ${owner?.nazwisko}`}
      withBackButton={{
        onClick: (navigate) => navigate(AppRoute.owners),
      }}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="auto auto auto 1fr">
            <TopBar.Link
              linkTo={AppRouteWithParams.ownerPermissionsEffective.get({ id: ownerId })}
              isActive={!!returnValueBasedOnOwnerPermissionLocation({ effective: () => true })}
            >
              {formatMessage({ id: AppMessages['permissions.effective'] })}
            </TopBar.Link>

            <TopBar.Link
              linkTo={AppRouteWithParams.ownerPermissionsIndividual.get({ id: ownerId })}
              isActive={!!returnValueBasedOnOwnerPermissionLocation({ individual: () => true })}
            >
              {formatMessage({ id: AppMessages['permissions.individual'] })}
            </TopBar.Link>

            <TopBar.Link
              linkTo={AppRouteWithParams.ownerPermissionsGroup.get({ id: ownerId })}
              isActive={!!returnValueBasedOnOwnerPermissionLocation({ group: () => true })}
            >
              {formatMessage({ id: AppMessages['permissions.group'] })}
            </TopBar.Link>

            {returnValueBasedOnOwnerPermissionLocation({
              effective: () => <TopBar.Header />,
              individual: () => (
                <TopBar.ButtonsWrapper>
                  <AddOwnerIndividualPermissionContainer ownerId={ownerId} />{' '}
                </TopBar.ButtonsWrapper>
              ),
              group: () => (
                <TopBar.Search>
                  <TableSearchContainer
                    searchIconTestId="page-search"
                    inputPlaceholder={formatMessage({ id: AppMessages['groups.owner.search'] })}
                    searchBy="ownerGroups"
                    setTableSearchQuery={setOwnerGroupsTableSearchQuery}
                    ownerId={ownerId}
                    selectedValue={searchOption}
                    setSelectedValue={setSelectedSearchOption}
                  />
                </TopBar.Search>
              ),
            })}
          </TopBar>

          {returnValueBasedOnOwnerPermissionLocation({
            group: () => <OwnerGroupsContainer ownerGroupsTableSearchQuery={ownerGroupsTableSearchQuery} />,
            individual: () => <OwnerIndividualPermissionsContainer />,
            effective: () => <OwnerEffectivePermissionsContainer />,
          })}
        </>
      )}
      renderDetails={() =>
        returnValueBasedOnOwnerPermissionLocation({
          individual: () => (
            <DetailsDrawer
              isOpen={!!selectedOwnerIndividualPermission}
              header={formatMessage({ id: AppMessages['permission.individual.details.title'] })}
            >
              {selectedOwnerIndividualPermission && (
                <OwnerIndividualPermissionDetailsContainer
                  ownerId={ownerId}
                  selectedPermission={selectedOwnerIndividualPermission}
                />
              )}
            </DetailsDrawer>
          ),
        })
      }
    />
  );
};
