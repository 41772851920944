import { DoubleTextElipsisWithTooltip, useObserver } from 'shared-ui';

import { Table } from 'ui/table/Table';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { OwnersTableRowProps } from './OwnersTableRow.types';

export const OwnersTableRow = ({ owner, displayFullTable }: OwnersTableRowProps) => {
  const { formatMessage } = useLocale();
  const { tableWidth } = useObserver();

  return (
    <>
      <Table.RowItem>{owner.skdId}</Table.RowItem>

      <Table.RowItem>
        <DoubleTextElipsisWithTooltip
          fullText={`${owner.nazwisko} ${owner.imie}`}
          firstLineText={owner?.imie}
          secondLineText={owner?.nazwisko}
          width={tableWidth}
        />
      </Table.RowItem>

      <Table.RowCellsItem cells={owner.komorka} />

      {displayFullTable && <Table.RowItem text={owner?.stanowisko?.nazwa} />}

      <Table.RowItem>
        {!!owner.kads?.length
          ? formatMessage({ id: AppMessages['common.yes'] }).toUpperCase()
          : formatMessage({ id: AppMessages['common.no'] }).toUpperCase()}
      </Table.RowItem>

      <Table.RowItem>{owner.nrEwidencyjny}</Table.RowItem>
    </>
  );
};
