import { useParams } from 'react-router-dom';

import { fetchGroupTerminalsPermissions } from 'api/permissions/permissions';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';

import { GroupTerminals } from './GroupTerminals';
import { GroupTerminalsContainerProps } from './GroupTerminals.types';

export const GroupTerminalsContainer = ({
  server,
  expandedServer,
  checkedPermissions,
  setCheckedPermissions,
  setPermissionsCount,
}: GroupTerminalsContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const groupId = id || '';
  const {
    storedQueries: { groupTerminalsPermissions: groupTerminalsPermissionsQuery },
  } = useLocaleStoreQuery();

  const { data: permissionsResponse } = useQuery(
    [QueryKeyEnum.GROUP_TERMINALS_PERMISSIONS, groupId, expandedServer?.id, groupTerminalsPermissionsQuery],
    () => fetchGroupTerminalsPermissions({ groupId, serverId: expandedServer?.id || server.id }),
    {
      args: groupTerminalsPermissionsQuery,
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setPermissionsCount(response.count);
      },
    },
  );

  return (
    <GroupTerminals
      permissionsResponse={permissionsResponse}
      checkedPermissions={checkedPermissions}
      setCheckedPermissions={setCheckedPermissions}
    />
  );
};
