import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionButton } from 'ui/actionButton/ActionButton';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { UnarchiveCardProps } from './UnarchiveCard.types';

export const UnarchiveCard = ({
  selectedCard,
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
}: UnarchiveCardProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <ActionButton type="unarchive" onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['card.unarchive.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        submitButtonDisabled={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['card.unarchive.actionButton'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['card.unarchive.subtitle'] })} {selectedCard.numerKarty}
        </ActionModal.Subtitle>

        <ActionModal.Text>{formatMessage({ id: AppMessages['card.unarchive.text'] })}</ActionModal.Text>

        <ActionModal.Text>{formatMessage({ id: AppMessages['common.reversibleAction'] })}</ActionModal.Text>
      </ActionModal>
    </>
  );
};
