import { useLocale } from 'hooks/useLocale/useLocale';
import { Table } from 'ui/table/Table';
import { AppMessages } from 'i18n/messages';
import { useSelectedOwnerIndividualPermission } from 'hooks/useSelectedOwnerIndividualPermission/useSelectedOwnerIndividualPermission';
import { Permission } from 'api/permissions/permissions.types';

import { OwnerIndividualTerminalsPermissionsProps } from './OwnerIndividualTerminalsPermissions.types';
import { OwnerIndividualPermissionsTableHeader } from './ownerIndividualPermissionsTableHeader/OwnerIndividualPermissionsTableHeader';
import { OwnerIndividualPermissionsTableRow } from './ownerIndividualPermissionsTableRow/OwnerIndividualPermissionsTableRow';

export const OwnerIndividualTerminalsPermissions = ({
  permissionsResponse,
  setCheckedPermissions,
  checkedPermissions,
}: OwnerIndividualTerminalsPermissionsProps) => {
  const { formatMessage } = useLocale();
  const { selectedOwnerIndividualPermission, setSelectedOwnerIndividualPermission } =
    useSelectedOwnerIndividualPermission();

  return (
    <Table
      gridTemplateColumns="0.7fr 1fr 1fr 1fr 1fr"
      onClickRow={(permission) =>
        setSelectedOwnerIndividualPermission(
          selectedOwnerIndividualPermission?.id === permission.id ? undefined : (permission as Permission<true>),
        )
      }
      activeRowId={selectedOwnerIndividualPermission?.id}
      data={permissionsResponse?.data || []}
      nothingToShowText={formatMessage({ id: AppMessages['permissions.server.nothingToShow'] })}
      customTableHeight={() => undefined}
      checkboxSelection={{
        checked: checkedPermissions,
        toggleChecked: setCheckedPermissions,
        withoutCheckedElementsBar: true,
      }}
      renderHeader={() => <OwnerIndividualPermissionsTableHeader />}
      renderRow={(permission) => <OwnerIndividualPermissionsTableRow permission={permission} />}
    />
  );
};
