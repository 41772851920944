import { useLocale } from 'hooks/useLocale/useLocale';
import { Table } from 'ui/table/Table';
import { AppMessages } from 'i18n/messages';
import { useSelectedGroupTerminalPermission } from 'hooks/useSelectedGroupTerminalPermission/useSelectedGroupTerminalPermission';

import { GroupTerminalsProps } from './GroupTerminals.types';
import { GroupTerminalsTableHeader } from './groupTerminalsTableHeader/GroupTerminalsTableHeader';
import { GroupTerminalsTableRow } from './groupTerminalsTableRow/GroupTerminalsTableRow';

export const GroupTerminals = ({
  permissionsResponse,
  checkedPermissions,
  setCheckedPermissions,
}: GroupTerminalsProps) => {
  const { formatMessage } = useLocale();
  const { selectedGroupTerminalPermission, setSelectedGroupTerminalPermission } = useSelectedGroupTerminalPermission();

  return (
    <Table
      onClickRow={(permission) =>
        setSelectedGroupTerminalPermission(
          selectedGroupTerminalPermission?.id === permission.id ? undefined : permission,
        )
      }
      activeRowId={selectedGroupTerminalPermission?.id}
      gridTemplateColumns="0.7fr 1fr 1fr 1fr 1fr"
      data={permissionsResponse?.data || []}
      nothingToShowText={formatMessage({ id: AppMessages['permissions.server.nothingToShow'] })}
      customTableHeight={() => undefined}
      checkboxSelection={{
        checked: checkedPermissions,
        toggleChecked: setCheckedPermissions,
        withoutCheckedElementsBar: true,
      }}
      renderHeader={() => <GroupTerminalsTableHeader />}
      renderRow={(permission) => <GroupTerminalsTableRow permission={permission} />}
    />
  );
};
