import { useState } from 'react';
import { ClientError, APIError } from 'shared-ui';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { useErrorHandler } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedOrganization } from 'hooks/useSelectedOrganization/useSelectedOrganization';
import { deleteOrganization, deleteOrganizationForce } from 'api/organizations/organizations';
import { DeleteOrganizationErrorResponse } from 'api/organizations/organizations.types';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteOrganization } from './DeleteOrganization';
import { DeleteOrganizationContainerProps } from './DeleteOrganization.types';

export const DeleteOrganizationContainer = ({ selectedOrganization }: DeleteOrganizationContainerProps) => {
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { handleError } = useErrorHandler();

  const [isConfirmationNeeded, setIsConfirmationNeeded] = useState(false);
  const [isOwnerErrorModalOpen, setIsOwnerErrorModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { setSelectedOrganization } = useSelectedOrganization();

  const { mutate: onDeleteOrganization, isLoading } = useMutation(deleteOrganization, {
    onSuccess: () => {
      setSelectedOrganization(undefined);
      queryClient.invalidateQueries([QueryKeyEnum.ORGANIZATIONS]);
      toast.dark(formatMessage({ id: AppMessages['definitions.organization.delete.success'] }));
      closeModals();
    },
    onError: (error: ClientError<APIError<DeleteOrganizationErrorResponse>>) => {
      if (error.response?.data.type === 'group_exists') {
        return setIsConfirmationNeeded(true);
      }

      if (error.response?.data.type === 'pkd_exists') {
        onModalClose();
        return setIsOwnerErrorModalOpen(true);
      }

      handleError({ error, omitAPIErrorMessage: true });
    },
  });

  const { mutate: onDeleteOrganizationForce, isLoading: isLoadingForce } = useMutation(deleteOrganizationForce, {
    onSuccess: () => {
      setSelectedOrganization(undefined);
      queryClient.invalidateQueries([QueryKeyEnum.ORGANIZATIONS]);
      toast.dark(formatMessage({ id: AppMessages['definitions.organization.delete.success'] }));
      closeModals();
    },
  });

  const onSubmit = (withForce?: boolean) => {
    withForce ? onDeleteOrganizationForce(selectedOrganization.id) : onDeleteOrganization(selectedOrganization.id);
  };

  const closeModals = () => {
    onModalClose();
    setIsConfirmationNeeded(false);
    setIsOwnerErrorModalOpen(false);
  };

  return (
    <DeleteOrganization
      selectedOrganization={selectedOrganization}
      isModalOpen={isModalOpen}
      onModalClose={closeModals}
      onModalOpen={onModalOpen}
      isLoading={isLoading || isLoadingForce}
      onSubmit={onSubmit}
      isConfirmationNeeded={isConfirmationNeeded}
      isOwnerErrorModalOpen={isOwnerErrorModalOpen}
    />
  );
};
