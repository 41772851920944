import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary, PageLayout } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { SearchQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';

import { WantedBar } from './wantedBar/WantedBar';
import { WantedTableContainer } from './wantedTable/WantedTableContainer';
import { WantedHistoryContainer } from './wantedHistory/WantedHistoryContainer';

export const Wanted = () => {
  const { formatMessage } = useLocale();
  const [wantedTableSearchQuery, setWantedTableSearchQuery] = useState('');
  const location = useLocation();
  const isHistoryView = location.pathname === AppRoute.wantedHistory;

  const currentPage = {
    [AppRoute.wanted]: <WantedTableContainer wantedTableSearchQuery={wantedTableSearchQuery} />,
    [AppRoute.wantedHistory]: <WantedHistoryContainer />,
  }[location.pathname];

  return (
    <ErrorBoundary>
      <PageLayout
        header={formatMessage({ id: AppMessages['wanted.title'] })}
        renderContent={() => (
          <>
            <WantedBar
              searchQueryKey={SearchQueryKeysEnum.WANTED}
              setTableSearchQuery={isHistoryView ? undefined : setWantedTableSearchQuery}
            />

            {currentPage}
          </>
        )}
      />
    </ErrorBoundary>
  );
};
