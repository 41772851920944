import { forwardRef } from 'react';

import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchOrganizations } from 'api/organizations/organizations';

import { TreeMultiSelect } from './treeMultiSelect/TreeMultiSelect';
import { TreeSingleSelect } from './treeSingleSelect/TreeSingleSelect';
import { OrganizationsTreeSelectProps } from './OrganizationsTreeSelect.types';

export const OrganizationsTreeSelect = forwardRef<HTMLInputElement, OrganizationsTreeSelectProps>((props, ref) => {
  const { data: organizationsResponse } = useQuery([QueryKeyEnum.ORGANIZATIONS], fetchOrganizations);

  const organizations = organizationsResponse || [];

  return props.multiple ? (
    <TreeMultiSelect ref={ref} {...props} options={organizations} />
  ) : (
    <TreeSingleSelect ref={ref} {...props} options={organizations} />
  );
});
