import { useState } from 'react';
import { PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers';
import { TextField, TextFieldProps } from '@mui/material';
import { sub, format, endOfDay, startOfDay } from 'date-fns';
import { Modal, CloseGrayIcon, CustomDay, CustomTimeInput } from 'shared-ui';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-grey.svg';
import { ReactComponent as LeftArrow } from 'assets/icons/left-arrow-datepicker.svg';
import { ReactComponent as RightArrow } from 'assets/icons/right-arrow-datepicker.svg';
import { DateFormat } from 'core/global.enum';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Input } from 'ui/input/Input';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DateRange, HistoryDatepickerWithModalProps } from './HistoryDatepickerWithModal.types';
import * as Styled from './HistoryDatepickerWithModal.styles';

export const HistoryDatepickerWithModal = ({ dates, setDates }: HistoryDatepickerWithModalProps) => {
  const [tempDates, setTempDates] = useState<DateRange<Date>>([null, null]);

  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { formatMessage } = useLocale();
  const currentDate = new Date();

  const [startDate, endDate] = tempDates;

  const isMinDateError = !!startDate && !!endDate && startDate > endDate;

  const handleDateConfirm = () => {
    setDates([startDate, endDate]);
    onModalClose();
  };

  const setDatesScope = (duration: Duration) => {
    const startDate = sub(currentDate, duration);

    setTempDates([startOfDay(startDate), endOfDay(currentDate)]);
  };

  const dateInputValue = () => {
    if (dates[0] === null && dates[1] === null) {
      return '';
    } else if (dates[0] && dates[1] === null) {
      return `${format(dates[0], DateFormat.FULL)} - ${format(dates[0], DateFormat.FULL)}`;
    } else if (dates[0] && dates[1]) {
      return `${format(dates[0], DateFormat.FULL)} - ${format(dates[1], DateFormat.FULL)}`;
    }
  };

  const isDatepickerInputFilled = dates.every((date) => date);

  const onClickDatepickerInputIcon = () => {
    if (isDatepickerInputFilled) {
      setDates([null, null]);
    } else {
      onModalOpen();
      setDatesScope({ days: 0 });
    }
  };

  return (
    <>
      <Input
        placeholder={formatMessage({ id: AppMessages['history.datepicker.modal.date'] })}
        variant="outlined"
        withoutHelperText
        InputProps={{
          readOnly: true,
        }}
        rightIcon={
          <Styled.IconButton onClick={onClickDatepickerInputIcon}>
            {isDatepickerInputFilled ? <CloseGrayIcon /> : <CalendarIcon />}
          </Styled.IconButton>
        }
        value={dateInputValue()}
      />

      <Modal
        header={formatMessage({ id: AppMessages['history.datepicker.modal.title'] })}
        open={isModalOpen}
        onClose={() => {
          setDates([null, null]);
          onModalClose();
        }}
        type="form"
        onSubmit={handleDateConfirm}
        submitButtonDisabled={startDate === null || endDate === null || isMinDateError}
        submitButtonText={formatMessage({ id: AppMessages['history.datepicker.modal.select'] })}
      >
        <Styled.Wrapper>
          <Styled.ActionButtonsWrapper>
            <Styled.ActionButton onClick={() => setDatesScope({ months: 1 })} variant="outlined" color="primary">
              {formatMessage({ id: AppMessages['cards.history.filters.date.lastMonth'] })}
            </Styled.ActionButton>

            <Styled.ActionButton onClick={() => setDatesScope({ months: 3 })} variant="outlined" color="primary">
              {formatMessage({ id: AppMessages['cards.history.filters.date.threeMonths'] })}
            </Styled.ActionButton>

            <Styled.ActionButton onClick={() => setDatesScope({ months: 6 })} variant="outlined" color="primary">
              {formatMessage({ id: AppMessages['cards.history.filters.date.sixMonths'] })}
            </Styled.ActionButton>

            <Styled.ActionButton onClick={() => setDatesScope({ years: 1 })} variant="outlined" color="primary">
              {formatMessage({ id: AppMessages['cards.history.filters.date.lastYear'] })}
            </Styled.ActionButton>
          </Styled.ActionButtonsWrapper>

          <Styled.DatePickersWrapper>
            <Styled.DatePickerWrapper>
              <StaticDatePicker
                value={startDate}
                components={{ RightArrowIcon: RightArrow, LeftArrowIcon: LeftArrow }}
                disableHighlightToday
                maxDate={endDate || new Date()}
                onChange={(date) => date && setTempDates([startOfDay(date), endDate])}
                displayStaticWrapperAs="desktop"
                openTo="day"
                renderInput={(params: TextFieldProps) => <TextField {...params} />}
                views={['day']}
                renderDay={(dayDate: Date, _: unknown, props: PickersDayProps<Date>) => (
                  <CustomDay
                    selected={Boolean(
                      startDate && format(startDate, DateFormat.DATE) === format(dayDate, DateFormat.DATE),
                    )}
                    {...props}
                  />
                )}
              />
              <CustomTimeInput
                date={startDate}
                setDate={(date) => setTempDates([date, endDate])}
                isMinDateError={isMinDateError}
              />
            </Styled.DatePickerWrapper>

            <Styled.DatePickerWrapper>
              <StaticDatePicker
                value={endDate}
                components={{ RightArrowIcon: RightArrow, LeftArrowIcon: LeftArrow }}
                disableHighlightToday
                maxDate={new Date()}
                minDate={startDate || undefined}
                onChange={(date) => date && setTempDates([startDate, endOfDay(date)])}
                displayStaticWrapperAs="desktop"
                openTo="day"
                renderInput={(params: TextFieldProps) => <TextField {...params} />}
                views={['day']}
                renderDay={(dayDate: Date, _: unknown, props: PickersDayProps<Date>) => (
                  <CustomDay
                    selected={Boolean(endDate && format(endDate, DateFormat.DATE) === format(dayDate, DateFormat.DATE))}
                    {...props}
                  />
                )}
              />
              <CustomTimeInput
                date={endDate}
                setDate={(date) => setTempDates([startDate, date])}
                isMinDateError={isMinDateError}
              />
            </Styled.DatePickerWrapper>
          </Styled.DatePickersWrapper>
        </Styled.Wrapper>
      </Modal>
    </>
  );
};
