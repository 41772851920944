import { EditGroupTerminalsPermissionsContainer } from './editGroupTerminalsPermissions/EditGroupTerminalsPermissionsContainer';
import { GroupTerminalsPermissionMultiActionsProps } from './GroupTerminalsPermissionsMultiActions.types';
import { DeleteGroupTerminalsPermissionsContainer } from './deleteGroupTerminalsPermissions/DeleteGroupTerminalsPermissionsContainer';
import * as Styled from './GroupTerminalsPermissionsMultiActions.styles';

export const GroupTerminalsPermissionMultiActions = ({
  checkedPermissions,
  groupId,
  setCheckedPermissions,
}: GroupTerminalsPermissionMultiActionsProps) => {
  return (
    <Styled.Container>
      <EditGroupTerminalsPermissionsContainer checkedPermissions={checkedPermissions} groupId={groupId} />
      <DeleteGroupTerminalsPermissionsContainer
        checkedPermissions={checkedPermissions}
        groupId={groupId}
        setCheckedPermissions={setCheckedPermissions}
      />
    </Styled.Container>
  );
};
