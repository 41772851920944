import { Button } from 'shared-ui';

import { ReactComponent as ArchiveIcon } from 'assets/icons/archive-primary.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { UnarchiveMultiGroupsProps } from './UnarchiveMultiGroups.types';

export const UnarchiveMultiGroups = ({
  checkedGroups,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
}: UnarchiveMultiGroupsProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<ArchiveIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['groups.multiAction.unarchive.button'] })}
      </Button>
      <ActionModal
        header={formatMessage({ id: AppMessages['groups.multiAction.unarchive.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['groups.multiAction.unarchive.button'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['groups.multiAction.unarchive.subtitle'] })} {checkedGroups.length}
        </ActionModal.Subtitle>

        <ActionModal.Text>{formatMessage({ id: AppMessages['groups.multiAction.unarchive.text'] })}</ActionModal.Text>
      </ActionModal>
    </>
  );
};
