import { useState } from 'react';
import { PageLayout, ShowUp, TopBar, Pagination } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ExpandableList } from 'ui/expandableList/ExpandableList';
import { findServerToExpand } from 'helpers/findServerToExpand';
import { AppRoute } from 'routing/AppRoute.enum';
import { Terminal } from 'api/terminals/terminals.types';

import { WantedTerminalsRowsContainer } from './wantedTerminalsRows/WantedTerminalsRowsContainer';
import { WantedTerminalsProps } from './WantedTerminals.types';
import { WantedTerminalsMultiActions } from './wantedTerminalsMultiActions/WantedTerminalsMultiActions';
import { AddWantedTerminalsContainer } from './addWantedTerminals/AddWantedTerminalsContainer';

export const WantedTerminals = ({
  servers,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  terminalsCount,
  setTerminalsCount,
  wantedId,
}: WantedTerminalsProps) => {
  const { formatMessage } = useLocale();
  const [checkedTerminals, setCheckedTerminals] = useState<Terminal[]>([]);

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['wanted.terminals.title'] })}
      withBackButton={{
        onClick: (navigate) => navigate(AppRoute.wanted),
      }}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="1fr">
            <TopBar.ButtonsWrapper>
              <AddWantedTerminalsContainer wantedId={wantedId || ''} />
            </TopBar.ButtonsWrapper>
          </TopBar>

          <ShowUp show={!!checkedTerminals.length}>
            <WantedTerminalsMultiActions
              wantedId={wantedId}
              checkedTerminals={checkedTerminals}
              setCheckedTerminals={setCheckedTerminals}
              terminalsCount={terminalsCount}
            />
          </ShowUp>

          <Pagination
            page={page}
            setPage={setPage}
            count={terminalsCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />

          <ExpandableList
            data={servers || []}
            isLoading={isLoading}
            nothingToShowText={formatMessage({ id: AppMessages['wanted.terminals.nothingToShow'] })}
            title={(server) => server.nazwa}
            initiallyExpandedItem={findServerToExpand(servers || [])}
            toggleItemExpansion={(expandedServer) => {
              setPage(0);
              if (!expandedServer) setTerminalsCount(0);
            }}
            renderExpandableItemBody={(server, expandedServer) => (
              <WantedTerminalsRowsContainer
                key={server.id}
                expandedServer={expandedServer}
                setTerminalsCount={setTerminalsCount}
                checkedTerminals={checkedTerminals}
                setCheckedTerminals={setCheckedTerminals}
              />
            )}
          />
        </>
      )}
    />
  );
};
