import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { LogoutModal } from 'shared-ui';

import { getSettings } from 'api/general/general';
import { QueryKeyEnum } from 'core/global.enum';
import { useAuth } from 'hooks/useAuth/useAuth';
import { LogoutModalContext } from '../logoutModalContext/LogoutModalContext';
import { useQuery } from 'hooks/useQuery/useQuery';
import { AppName } from 'ui/appName/AppName';

import { LogoutModalContextControllerProps } from './LogoutModalContextController.types';

export const LogoutModalContextController = ({ children }: LogoutModalContextControllerProps) => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const { isAuthenticated, logout } = useAuth();

  const { data: settings } = useQuery([QueryKeyEnum.SETTINGS], getSettings, {
    enabled: isAuthenticated,
    onSuccess: () => {
      start();
    },
  });

  const { start, pause } = useIdleTimer({
    onIdle: () => {
      setIsLogoutModalOpen(true);

      pause();
    },
    timeout: settings?.logoutTime ? settings.logoutTime * 1000 : undefined,
    startManually: true,
    crossTab: true,
  });

  const handleModalActionClick = () => {
    pause();
    logout();
    setIsLogoutModalOpen(false);
  };

  return (
    <LogoutModalContext.Provider value={{ setIsLogoutModalOpen, isLogoutModalOpen }}>
      {children}
      <LogoutModal appName={<AppName />} isOpen={isLogoutModalOpen} handleModalActionClick={handleModalActionClick} />
    </LogoutModalContext.Provider>
  );
};
