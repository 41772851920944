import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { archiveMultiGroups } from 'api/groups/groups';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useSelectedGroup } from 'hooks/useSelectedGroup/useSelectedGroup';
import { useModalState } from 'hooks/useModalState/useModalState';

import { ArchiveMultiGroups } from './ArchiveMultiGroups';
import { ArchiveMultiGroupsContainerProps } from './ArchiveMultiGroups.types';

export const ArchiveMultiGroupsContainer = ({ checkedGroups, setCheckedGroups }: ArchiveMultiGroupsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedGroup, setSelectedGroup } = useSelectedGroup();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onArchiveMultiGroups, isLoading } = useMutation(archiveMultiGroups, {
    onSuccess: () => {
      if (selectedGroup && checkedGroups.some(({ id }) => id === selectedGroup?.id)) {
        setSelectedGroup(undefined);
      }

      queryClient.invalidateQueries([QueryKeyEnum.GROUPS]);
      toast.dark(formatMessage({ id: AppMessages['groups.multiAction.archive.successMessage'] }));
      setCheckedGroups([]);
      onModalClose();
    },
  });

  const onSubmit = () => {
    onArchiveMultiGroups({ body: checkedGroups.map(({ id }) => id) });
  };

  return (
    <ArchiveMultiGroups
      checkedGroups={checkedGroups}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
