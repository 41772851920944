import { OrganizationActionsProps } from './OrganizationActions.types';
import { DeleteOrganizationContainer } from './deleteOrganization/DeleteOrganizationContainer';
import * as Styled from './OrganizationActions.styles';

export const OrganizationActions = ({ selectedOrganization, mainOrganization }: OrganizationActionsProps) => {
  const isMainOrganization = mainOrganization.id === selectedOrganization.id;
  const isDeleteAvailable = !selectedOrganization.children && !isMainOrganization;

  return (
    <Styled.ActionButtonsWrapper>
      {isDeleteAvailable && <DeleteOrganizationContainer selectedOrganization={selectedOrganization} />}
    </Styled.ActionButtonsWrapper>
  );
};
