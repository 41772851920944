import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { unarchiveMultiOwners } from 'api/owners/owners';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { UnarchiveMultiOwners } from './UnarchiveMultiOwners';
import { UnarchiveMultiOwnersContainerProps } from './UnarchiveMultiOwners.types';

export const UnarchiveMultiOwnersContainer = ({
  checkedOwners,
  setCheckedOwners,
}: UnarchiveMultiOwnersContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedOwner, setSelectedOwner, setLastArchivedSelectedOwnerId } = useSelectedOwner();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onUnarchiveMultiOwners, isLoading } = useMutation(unarchiveMultiOwners, {
    onSuccess: () => {
      if (selectedOwner && checkedOwners.some(({ id }) => id === selectedOwner?.id)) {
        setSelectedOwner(undefined);
        setLastArchivedSelectedOwnerId(undefined);
      }

      queryClient.invalidateQueries([QueryKeyEnum.OWNERS]);
      toast.dark(formatMessage({ id: AppMessages['owners.multiAction.unarchive.success'] }));
      setCheckedOwners([]);
      onModalClose();
    },
  });

  const onSubmit = () => {
    onUnarchiveMultiOwners({ body: checkedOwners.map(({ id }) => id) });
  };

  return (
    <UnarchiveMultiOwners
      checkedOwners={checkedOwners}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
