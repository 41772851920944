import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedOwnerIndividualPermission } from 'hooks/useSelectedOwnerIndividualPermission/useSelectedOwnerIndividualPermission';
import { deleteOwnerIndividualPermissions } from 'api/permissions/permissions';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteOwnerIndividualPermissions } from './DeleteOwnerIndividualPermissions';
import { DeleteOwnerIndividualPermissionsContainerProps } from './DeleteOwnerIndividualPermissions.types';

export const DeleteOwnerIndividualPermissionsContainer = ({
  checkedPermissions,
  setCheckedPermissions,
  ownerId,
}: DeleteOwnerIndividualPermissionsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedOwnerIndividualPermission, setSelectedOwnerIndividualPermission } =
    useSelectedOwnerIndividualPermission();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteOwnerIndividualPermissions, isLoading } = useMutation(deleteOwnerIndividualPermissions, {
    onSuccess: () => {
      if (
        selectedOwnerIndividualPermission &&
        checkedPermissions.some(({ id }) => id === selectedOwnerIndividualPermission?.id)
      ) {
        setSelectedOwnerIndividualPermission(undefined);
      }

      queryClient.invalidateQueries([QueryKeyEnum.OWNER_PERMISSIONS_INDIVIDUAL_SERVERS]);
      queryClient.invalidateQueries([QueryKeyEnum.OWNER_PERMISSIONS_INDIVIDUAL]);
      toast.dark(formatMessage({ id: AppMessages['permissions.multiAction.delete.success'] }));
      setCheckedPermissions([]);
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteOwnerIndividualPermissions({
      pkdId: ownerId,
      body: { idPermissions: checkedPermissions.map(({ id }) => id) },
    });
  };

  return (
    <DeleteOwnerIndividualPermissions
      checkedPermissions={checkedPermissions}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
