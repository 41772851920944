import { useEffect, useState } from 'react';
import { ErrorBoundary, usePagination } from 'shared-ui';

import { fetchOwners } from 'api/owners/owners';
import { Owner } from 'api/owners/owners.types';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useLocaleStoreOwnersQuery } from 'hooks/useLocaleStoreOwnersQuery/useLocaleStoreOwnersQuery';
import { OwnersQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useOwnerArchivedView } from 'hooks/useIsOwnerArchivedView/useOwnersArchivedView';

import { Owners } from './Owners';

export const OwnersContainer = () => {
  const [checkedOwners, setCheckedOwners] = useState<Owner[]>([]);
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();
  const [tableSearchQuery, setTableSearchQuery] = useState('');

  const { ownersQuery } = useLocaleStoreOwnersQuery();
  const { isOwnersArchivedView } = useOwnerArchivedView();

  const { data: ownersResponse, isLoading } = useQuery(
    [QueryKeyEnum.OWNERS, ownersQuery, page, rowsPerPage, tableSearchQuery, isOwnersArchivedView],
    fetchOwners,
    {
      args: {
        ...ownersQuery,
        [OwnersQueryKeysEnum.LIMIT]: rowsPerPage,
        [OwnersQueryKeysEnum.OFFSET]: rowsPerPage * page,
        [OwnersQueryKeysEnum.QUERY]: tableSearchQuery,
        [OwnersQueryKeysEnum.STANOWISKO]: ownersQuery.stanowisko?.map(({ id }) => id),
        [OwnersQueryKeysEnum.ARCHIWALNY]: isOwnersArchivedView,
        exactQuery: true,
      },
    },
  );

  useEffect(() => {
    setPage(0);
  }, [isOwnersArchivedView, ownersQuery, tableSearchQuery]);

  useEffect(() => {
    if (!ownersResponse) return;

    const updatedCheckedOwners = ownersResponse.data.filter((owner) =>
      checkedOwners.some((checkedOwner) => checkedOwner.id === owner.id),
    );

    setCheckedOwners(updatedCheckedOwners);
  }, [ownersResponse]);

  return (
    <ErrorBoundary>
      <Owners
        ownersResponse={ownersResponse}
        isLoading={isLoading}
        checkedOwners={checkedOwners}
        setCheckedOwners={setCheckedOwners}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setTableSearchQuery={setTableSearchQuery}
      />
    </ErrorBoundary>
  );
};
