import * as yup from 'yup';
import { getYear, isValid, subYears } from 'date-fns';

import { OwnerDocumentEnum } from 'api/owners/owners.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { validatePesel } from 'helpers/validatePesel';
import { AppMessages } from 'i18n/messages';
import { JUST_LETTERS_REGEX } from 'core/global.variables';

export const useAddOwnerManuallyValidation = (minDate: Date) => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape(
    {
      imie: yup
        .string()
        .min(3, formatMessage({ id: AppMessages['owners.addOwner.field.name.validation'] }))
        .max(32, formatMessage({ id: AppMessages['owners.addOwner.field.name.validation.max'] }))
        .matches(JUST_LETTERS_REGEX, formatMessage({ id: AppMessages['common.validation.letters'] }))
        .required(formatMessage({ id: AppMessages['common.validation.required'] })),
      nazwisko: yup
        .string()
        .min(3, formatMessage({ id: AppMessages['owners.addOwner.field.lastName.validation'] }))
        .max(32, formatMessage({ id: AppMessages['owners.addOwner.field.lastName.validation.max'] }))
        .matches(JUST_LETTERS_REGEX, formatMessage({ id: AppMessages['common.validation.letters'] }))
        .required(formatMessage({ id: AppMessages['common.validation.required'] })),
      drugieImie: yup
        .string()
        .when({
          is: (name: string) => name,
          then: () =>
            yup
              .string()
              .min(3, formatMessage({ id: AppMessages['owners.addOwner.field.secondName.validation'] }))
              .max(32, formatMessage({ id: AppMessages['owners.addOwner.field.secondName.validation.max'] }))
              .optional(),
        })
        .optional(),
      opis: yup
        .string()
        .max(60, formatMessage({ id: AppMessages['owners.addOwner.field.description.validation.max'] }))
        .optional(),
      nrEwidencyjny: yup
        .string()
        .max(
          6,
          formatMessage(
            { id: AppMessages['owners.addOwner.field.registrationNumber.validation.max'] },
            { maxLength: 6 },
          ),
        )
        .required(formatMessage({ id: AppMessages['common.validation.required'] })),
      nrEwidencyjnyZewnetrzny: yup
        .string()
        .max(
          6,
          formatMessage(
            { id: AppMessages['owners.addOwner.field.externalRegistrationNumber.validation.max'] },
            { maxLength: 6 },
          ),
        ),
      email: yup
        .string()
        .email(formatMessage({ id: AppMessages['owners.addOwner.field.email.validation'] }))
        .optional(),
      telefon: yup
        .string()
        .optional()
        .nullable()
        .test('length', formatMessage({ id: AppMessages['owners.addOwner.field.phoneNumber.validation'] }), (val) => {
          if (!val?.length) return true;

          return val.length >= 9 && val.length <= 20;
        }),
      pesel: yup
        .string()
        .test('pesel', formatMessage({ id: AppMessages['owners.addOwner.field.pesel.validation'] }), validatePesel)
        .optional(),
      numerDokumentu: yup
        .string()
        .optional()
        .when('dokumentTozsamosci', {
          is: (dokumentTozsamosci: OwnerDocumentEnum) => dokumentTozsamosci,
          then: yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] })),
        }),
      dataOd: yup
        .date()
        .min(minDate, formatMessage({ id: AppMessages['common.validation.date.past'] }))
        .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }))
        .when('dataDo', (validTo) => {
          if (validTo && isValid(validTo)) {
            return yup
              .date()
              .min(minDate, formatMessage({ id: AppMessages['common.validation.date.past'] }))
              .max(validTo, formatMessage({ id: AppMessages['common.validation.date.invalidRange'] }))
              .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }));
          }
          return yup
            .date()
            .min(minDate, formatMessage({ id: AppMessages['common.validation.date.past'] }))
            .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }));
        })
        .required(formatMessage({ id: AppMessages['common.validation.required'] })),
      dataDo: yup
        .date()
        .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }))
        .min(yup.ref('dataOd'), formatMessage({ id: AppMessages['common.validation.date.invalidRange'] }))
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .optional(),
      dataUrodzenia: yup
        .date()
        .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }))
        .min(getYear(subYears(new Date(), 100)), formatMessage({ id: AppMessages['common.validation.date.invalid'] }))
        .max(new Date(), formatMessage({ id: AppMessages['common.validation.date.invalid'] }))
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .optional(),
    },
    [['dataOd', 'dataDo']],
  );

  return { schema };
};
