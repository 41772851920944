import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { deleteEventExportFormat } from 'api/eventsExportFormat/eventsExportFormats';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteEventExportFormat } from './DeleteEventExportFormat';
import { DeleteEventExportFormatContainerProps } from './DeleteEventExportFormat.types';

export const DeleteEventExportFormatContainer = ({ eventExportFormat }: DeleteEventExportFormatContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();

  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteEventExportFormat, isLoading } = useMutation(deleteEventExportFormat, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.EVENTS_EXPORT_FORMAT]);
      toast.dark(formatMessage({ id: AppMessages['definitions.events.removeExportFormat.successMessage'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteEventExportFormat(eventExportFormat.id);
  };

  return (
    <DeleteEventExportFormat
      eventExportFormat={eventExportFormat}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
