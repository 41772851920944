import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { PermissionEnum } from 'api/permissions/permissions.enum';
import { AppMessages } from 'i18n/messages';

export const useEditOwnerIndividualPermissionsValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    rejestrujOdbicie: yup.string().required(),
    otwierajZamek: yup.string().required(),
    rejestrujRcp: yup
      .string()
      .required()
      .when(['rejestrujOdbicie', 'otwierajZamek'], {
        is: (rejestrujOdbicie: PermissionEnum, otwierajZamek: PermissionEnum) =>
          rejestrujOdbicie === PermissionEnum.NIEZDEFINIOWANO && otwierajZamek === PermissionEnum.NIEZDEFINIOWANO,
        then: yup
          .string()
          .required()
          .test(
            'rejestrujRcp',
            formatMessage({ id: AppMessages['permissions.field.permissions.validation'] }),
            (val) => {
              const isNotSet = val === PermissionEnum.NIEZDEFINIOWANO;

              return !isNotSet;
            },
          ),
      }),
  });

  return { schema };
};
