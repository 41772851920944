import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePagination } from 'shared-ui';

import { fetchGroupsForSpecificOwner } from 'api/groups/groups';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useOwnerQueryKeys } from 'hooks/useOwnerQueryKeys/useOwnerQueryKeys';

import { OwnerGroups } from './OwnerGroups';
import { OwnerGroupsContainerProps } from './OwnerGroups.types';

export const OwnerGroupsContainer = ({ ownerGroupsTableSearchQuery }: OwnerGroupsContainerProps) => {
  const { id: ownerId } = useParams<{ id: string }>();
  const { ownerGroupsQueryKey } = useOwnerQueryKeys();
  const {
    storedQueries: { [ownerGroupsQueryKey]: ownerGroupsQuery },
  } = useLocaleStoreQuery();
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();

  const { data: groupsResponse } = useQuery(
    [QueryKeyEnum.OWNER_GROUPS, ownerId, ownerGroupsQuery, ownerGroupsTableSearchQuery],
    () => fetchGroupsForSpecificOwner(ownerId),
    {
      args: { ...ownerGroupsQuery, query: ownerGroupsTableSearchQuery, queryById: true },
    },
  );

  useEffect(() => {
    setPage(0);
  }, [ownerGroupsQuery, ownerGroupsTableSearchQuery]);

  return (
    <OwnerGroups
      groupsResponse={groupsResponse}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    />
  );
};
