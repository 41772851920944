import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';

export const DetailsItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  margin-bottom: 24px;
  gap: 3px;
`;

export const DetailName = styled(Typography)(
  ({ theme: { palette } }) => css`
    font-weight: 700;
    color: ${palette.text.secondary};
    align-self: center;
  `,
);

export const DetailText = styled(Typography)(
  ({ theme: { palette } }) => css`
    color: ${palette.grey[700]};
  `,
);

export const DetailTextBold = styled(Typography)(
  ({ theme: { palette } }) => css`
    text-transform: uppercase;
    color: ${palette.grey[900]};
    font-weight: 700;
  `,
);

export const ButtonsWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-around;
`;
