import { ListItem as MaterialListItem, IconButton, Typography, Popper as MaterialPopper } from '@mui/material';
import styled, { css } from 'styled-components';

import { ListItemStylesProps } from '../OrganizationsTreeSelect.types';

export const Popper = styled(MaterialPopper)(
  ({ theme: { palette } }) => css`
    background-color: ${palette.grey.A200};
    border: 1px solid ${palette.grey[500]};
    max-height: 325px;
    border-radius: 8px;
    overflow: auto;
  `,
);

export const ListItemWrapper = styled.div(
  ({ theme: { palette } }) => css`
    border-bottom: 1px solid ${palette.grey[500]};

    &:last-child {
      border-bottom: none;
    }
  `,
);

export const ListItem = styled(MaterialListItem)<ListItemStylesProps>(
  ({ theme: { palette }, $isFirstLevel }) => css`
    padding: ${$isFirstLevel ? '7px 5px' : '7px 25px'};
    border-bottom: 1px solid ${palette.grey[500]};
    cursor: pointer;
    position: relative;
    color: ${palette.grey[900]};

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      color: ${palette.primary.main};
    }
  `,
);

export const Option = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

export const SubOption = styled.div`
  display: flex;
  align-items: center;
  padding-left: 35px;
`;

export const RightIconButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
`;

export const LeftIconButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
`;

const treeOptionTextCommonStyles = css`
  font-size: 12px;
  max-width: 200px;
`;

export const TreeOptionText = styled(Typography)`
  ${treeOptionTextCommonStyles}
`;

export const TreeOptionTextBold = styled(Typography)`
  ${treeOptionTextCommonStyles}
  font-weight: 700;
`;

export const NoOptionsTextWrapper = styled.div`
  padding: 14px 0;
`;

export const SearchWrapper = styled.div`
  min-width: 320px;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 9px;
`;

export const SearchButton = styled(IconButton)`
  margin-right: -5px;
`;
