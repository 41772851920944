import { Controller, useFormContext } from 'react-hook-form';
import { Modal, Button, Select } from 'shared-ui';

import { ReactComponent as AddIcon } from 'assets/icons/add-active-medium.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { PermissionEnum } from 'api/permissions/permissions.enum';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { Form } from 'ui/form/Form';
import * as Styled from '../GroupsMultiActions.styles';

import { AddMultiGroupPermissionsFormBody, AddMultiGroupPermissionsProps } from './AddMultiGroupPermissions.types';

export const AddMultiGroupPermissions = ({
  onModalOpen,
  onModalClose,
  isLoading,
  onSubmit,
  isModalOpen,
  servers,
  setSelectedServerId,
  selectedServerId,
  isLoadingTerminals,
  terminals,
  isLoadingServers,
  areGlcCheckedGroups,
  glcGroups,
  isLoadingGlcGroups,
}: AddMultiGroupPermissionsProps) => {
  const { formatMessage } = useLocale();
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption();

  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useFormContext<AddMultiGroupPermissionsFormBody>();

  const getReadersOptions = () => {
    return terminals.map((terminal) => ({
      label: terminal.nazwa,
      value: terminal.id,
      number: terminal.numerLogicznyKontrolera,
    }));
  };

  const filteredGlcGroupsBySelectedServer = glcGroups.filter((glc) => glc.rcp.id === selectedServerId);
  const getGlcOptions = () => {
    return filteredGlcGroupsBySelectedServer.map((glc) => ({
      label: glc.nazwa,
      value: glc.id,
      number: glc.idGlc,
    }));
  };
  const { serverOptions, permissionOptions } = useAppSelectOptions({ servers });

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<AddIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['groups.multiAction.addPermissions.button'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['groups.multiAction.addPermissions.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={isLoading || !!Object.keys(errors).length}
      >
        <Styled.FormWrapper>
          <Select
            value={selectedServerId}
            onChange={(e) => setSelectedServerId(e.target.value as string)}
            label={formatMessage({ id: AppMessages['groups.multiAction.addPermissions.field.server.label'] })}
            options={serverOptions}
          />

          <Controller
            name={areGlcCheckedGroups ? 'idGlcs' : 'idTkds'}
            control={control}
            render={({ field }) => (
              <MultiAutocomplete
                {...field}
                withLimitedTags
                stopRotatePopupIndicator
                inputSize="big"
                limitTags={1}
                onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                options={areGlcCheckedGroups ? getGlcOptions() : getReadersOptions()}
                loading={(areGlcCheckedGroups ? isLoadingGlcGroups : isLoadingTerminals) || isLoadingServers}
                loadingText={formatMessage({ id: AppMessages['common.loading'] })}
                label={formatMessage({
                  id: AppMessages[
                    areGlcCheckedGroups
                      ? 'groups.multiAction.addPermissions.field.glc.label'
                      : 'groups.multiAction.addPermissions.field.reader.label'
                  ],
                })}
                noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
                placeholder={formatMessage({
                  id: AppMessages['groups.multiAction.addPermissions.field.reader.placeholder'],
                })}
                renderOption={renderMultiAutocompleteOption}
              />
            )}
          />

          <Form.ControlledSelect
            name="rejestrujOdbicie"
            control={control}
            isError={!!errors.rejestrujRcp?.message}
            onChange={(e) => {
              setValue('rejestrujOdbicie', e.target.value as PermissionEnum, { shouldDirty: true });
              errors.rejestrujRcp?.message && trigger('rejestrujRcp');
            }}
            label={formatMessage({
              id: AppMessages['groups.multiAction.addPermissions.field.registerRecord.label'],
            })}
            data-testId="odbicie-select"
            options={permissionOptions}
          />

          <Form.ControlledSelect
            name="otwierajZamek"
            control={control}
            isError={!!errors.rejestrujRcp?.message}
            onChange={(e) => {
              setValue('otwierajZamek', e.target.value as PermissionEnum, { shouldDirty: true });
              errors.rejestrujRcp?.message && trigger('rejestrujRcp');
            }}
            label={formatMessage({ id: AppMessages['groups.multiAction.addPermissions.field.openLock.label'] })}
            data-testId="zamek-select"
            options={permissionOptions}
          />

          <Form.ControlledSelect
            name="rejestrujRcp"
            control={control}
            onChange={(e) => setValue('rejestrujRcp', e.target.value as PermissionEnum, { shouldDirty: true })}
            withHelperText
            errorMessage={errors.rejestrujRcp?.message}
            isError={!!errors.rejestrujRcp?.message}
            label={formatMessage({ id: AppMessages['groups.multiAction.addPermissions.field.registerRcp.label'] })}
            data-testId="rcp-select"
            options={permissionOptions}
          />
        </Styled.FormWrapper>
      </Modal>
    </>
  );
};
