import { Typography } from '@mui/material';
import { PageLayout, SettingItem } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { DefinitionsBar } from '../definitionsBar/DefinitionsBar';

import { AddLogotypeContainer } from './addLogotype/AddLogotypeContainer';
import { DeleteLogotypeContainer } from './deleteLogotype/DeleteLogotypeContainer';
import { LogotypeProps } from './Logotype.types';
import * as Styled from './Logotype.styles';

export const ADD_LOGO_DROPZONE_WIDTH = 560;
export const ADD_LOGO_DROPZONE_HEIGHT = 140;

export const Logotype = ({ isLoading, logo }: LogotypeProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <PageLayout
        header={formatMessage({ id: AppMessages['definitions.title'] })}
        renderContent={() => (
          <>
            <DefinitionsBar />

            <SettingItem
              header={
                <>
                  <Typography variant="h4">
                    {formatMessage({ id: AppMessages['definitions.logotype.title'] })}
                  </Typography>
                </>
              }
            >
              <>
                <Styled.PhotoLabel>
                  {formatMessage({ id: AppMessages['definitions.logotype.addFile'] })}
                </Styled.PhotoLabel>

                <Styled.LogotypePlaceholder $width={ADD_LOGO_DROPZONE_WIDTH} $height={ADD_LOGO_DROPZONE_HEIGHT}>
                  {logo && !isLoading ? (
                    <Styled.UploadedPhoto src={URL.createObjectURL(logo)} />
                  ) : (
                    <>
                      <Styled.PhotoIcon />

                      <Typography variant="subtitle2">
                        {formatMessage({ id: AppMessages['owners.addOwner.photo.correctFiles'] })}
                      </Typography>
                      <Typography variant="subtitle2">
                        {formatMessage({ id: AppMessages['owners.addOwner.photo.size'] })}
                      </Typography>
                      <Typography variant="subtitle2">
                        {formatMessage({ id: AppMessages['owners.addOwner.photo.maxFileSize'] })}
                      </Typography>
                    </>
                  )}
                </Styled.LogotypePlaceholder>

                {logo ? <DeleteLogotypeContainer /> : <AddLogotypeContainer />}
              </>
            </SettingItem>
          </>
        )}
      />
    </>
  );
};
