import { useOwnerArchivedView } from 'hooks/useIsOwnerArchivedView/useOwnersArchivedView';
import { OwnerCardDetails } from 'api/owners/owners.types';
import { ReturnMultiCardsContainer } from 'reusable/returnMultiCards/ReturnMultiCardsContainer';
import { UpdateExpiryDateMultiCardsContainer } from 'reusable/updateExpiryDateMultiCards/UpdateExpiryDateMultiCardsContainer';

import { DeleteMultiOwnersContainer } from './deleteMultiOwners/DeleteMultiOwnersContainer';
import { EditMultiOwnersContainer } from './editMultiOwners/EditMultiOwnersContainer';
import { OwnersMultiActionsProps } from './OwnersMultiActions.types';
import { UnarchiveMultiOwnersContainer } from './unarchiveMultiOwners/UnarchiveMultiOwnersContainer';
import { ArchiveMultiOwnersContainer } from './archiveMultiOwners/ArchiveMultiOwnersContainer';
import * as Styled from './OwnersMultiActions.styles';

export const OwnersMultiActions = ({ checkedOwners, setCheckedOwners }: OwnersMultiActionsProps) => {
  const { isOwnersArchivedView } = useOwnerArchivedView();
  const isReturnCardsActionEnable = !isOwnersArchivedView && checkedOwners.every(({ kads }) => !!kads);
  const isUpdateExpiryDateActionEnable = !isOwnersArchivedView && checkedOwners.every(({ kads }) => !!kads);
  const isDeleteMultiOwnersActionEnable = checkedOwners.every(({ canBeDeleted }) => canBeDeleted);
  const isArchiveCardsActionEnable = checkedOwners.every(({ kads }) => !kads);

  const checkedOwnersCards = checkedOwners
    .filter(({ kads }) => !!kads)
    .reduce((acc: OwnerCardDetails[], owner) => (owner.kads ? [...acc, ...owner.kads] : acc), []);

  return (
    <Styled.Container>
      {isDeleteMultiOwnersActionEnable && (
        <DeleteMultiOwnersContainer checkedOwners={checkedOwners} setCheckedOwners={setCheckedOwners} />
      )}

      {isOwnersArchivedView ? (
        <UnarchiveMultiOwnersContainer checkedOwners={checkedOwners} setCheckedOwners={setCheckedOwners} />
      ) : (
        <>
          {isArchiveCardsActionEnable && (
            <ArchiveMultiOwnersContainer checkedOwners={checkedOwners} setCheckedOwners={setCheckedOwners} />
          )}
          <EditMultiOwnersContainer checkedOwners={checkedOwners} />
        </>
      )}

      {isUpdateExpiryDateActionEnable && (
        <UpdateExpiryDateMultiCardsContainer
          checkedOwners={checkedOwners}
          checkedOwnersCardsNumber={checkedOwnersCards.length}
          actionIn="owners"
        />
      )}

      {isReturnCardsActionEnable && <ReturnMultiCardsContainer checkedCards={checkedOwnersCards} actionIn="owners" />}
    </Styled.Container>
  );
};
