import { useLayoutEffect, useState } from 'react';
import { ErrorBoundary, usePagination } from 'shared-ui';

import { fetchServers } from 'api/servers/servers';
import { Terminal } from 'api/terminals/terminals.types';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useQuery } from 'hooks/useQuery/useQuery';

import { Devices } from './Devices';

export const DevicesContainer = () => {
  const [checkedTerminals, setCheckedTerminals] = useState<Terminal[]>([]);
  const [terminalsCount, setTerminalsCount] = useState(0);
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();

  const {
    storedQueries: { [QueryKeyLocalStorageEnum.TERMINALS]: terminalsQuery },
    setQuery: setTerminalsQuery,
  } = useLocaleStoreQuery();

  const { data: serversResponse, isLoading } = useQuery([QueryKeyEnum.SERVERS], fetchServers, {
    refetchInterval: 5000,
  });

  useLayoutEffect(() => {
    setTerminalsQuery(QueryKeyLocalStorageEnum.TERMINALS, {
      ...terminalsQuery,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    });
  }, [page, rowsPerPage]);

  return (
    <ErrorBoundary>
      <Devices
        servers={serversResponse?.data}
        isLoading={isLoading}
        checkedTerminals={checkedTerminals}
        setCheckedTerminals={setCheckedTerminals}
        terminalsCount={terminalsCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setTerminalsCount={setTerminalsCount}
      />
    </ErrorBoundary>
  );
};
