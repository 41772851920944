import { fetchOwnerDetails } from 'api/owners/owners';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { OwnerQueryKeysContext } from '../ownerQueryKeysContext/OwnerQueryKeysContext';

import { OwnerQueryKeysContextControllerProps } from './OwnerQueryKeysContextController.types';

export const OwnerQueryKeysContextController = ({ ownerId, children }: OwnerQueryKeysContextControllerProps) => {
  const { data: owner } = useQuery([QueryKeyEnum.OWNER_DETAILS, ownerId], () => fetchOwnerDetails(ownerId));

  const isArchived = !!owner?.archiwalny;

  const ownerPermissionsEffectiveQueryKey = isArchived
    ? QueryKeyLocalStorageEnum.ARCHIVED_OWNER_PERMISSIONS_EFFECTIVE
    : QueryKeyLocalStorageEnum.ACTIVE_OWNER_PERMISSIONS_EFFECTIVE;
  const ownerPermissionsIndividualQueryKey = isArchived
    ? QueryKeyLocalStorageEnum.ARCHIVED_OWNER_PERMISSIONS_INDIVIDUAL
    : QueryKeyLocalStorageEnum.ACTIVE_OWNER_PERMISSIONS_INDIVIDUAL;
  const ownerGroupsQueryKey = isArchived
    ? QueryKeyLocalStorageEnum.ARCHIVED_OWNER_GROUPS
    : QueryKeyLocalStorageEnum.ACTIVE_OWNER_GROUPS;

  return (
    <OwnerQueryKeysContext.Provider
      value={{
        ownerPermissionsEffectiveQueryKey,
        ownerPermissionsIndividualQueryKey,
        ownerGroupsQueryKey,
      }}
    >
      {children}
    </OwnerQueryKeysContext.Provider>
  );
};
