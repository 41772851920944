import * as yup from 'yup';
import { isValid, isEqual } from 'date-fns';

import { CardTypeEnum } from 'api/cards/cards.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useCardDetailsEditValidation = (defaultExpiryStartDate: Date, minDate: Date) => {
  const { formatMessage } = useLocale();

  const minDateRequired = yup
    .date()
    .min(minDate, formatMessage({ id: AppMessages['common.validation.date.past'] }))
    .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }));

  const dateOptional = yup.date().typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }));

  const schema = yup.object().shape({
    nazwa: yup
      .string()
      .max(30, formatMessage({ id: AppMessages['card.details.form.name.validation.tooLong'] }))
      .optional(),
    opis: yup
      .string()
      .max(60, formatMessage({ id: AppMessages['card.details.form.description.validation.tooLong'] }))
      .optional(),
    typKarty: yup.mixed<CardTypeEnum>().oneOf(Object.values(CardTypeEnum)),
    pin: yup
      .number()
      .test('length check', '', (pin) => `${pin}`.length === 4)
      .nullable(),
    waznoscOd: !!defaultExpiryStartDate
      ? yup
          .date()
          .min(minDate, formatMessage({ id: AppMessages['common.validation.date.past'] }))
          .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }))
          .when('waznoscDo', (validTo) => {
            if (validTo && isValid(validTo)) {
              return yup
                .date()
                .when('waznoscOd', (validFrom) => {
                  const isDateUnchanged = isEqual(new Date(validFrom), new Date(defaultExpiryStartDate));

                  if (isDateUnchanged) return dateOptional;

                  return minDateRequired;
                })
                .max(validTo, formatMessage({ id: AppMessages['common.validation.date.invalidRange'] }))
                .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }));
            }

            return yup.date().when('waznoscOd', (validFrom) => {
              const isDateUnchanged = isEqual(new Date(validFrom), new Date(defaultExpiryStartDate));

              if (isDateUnchanged) return dateOptional;

              return minDateRequired;
            });
          })

          .required(formatMessage({ id: AppMessages['common.validation.date.format'] }))
      : yup.string().nullable(),
    waznoscDo: yup
      .date()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .optional()
      .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }))
      .min(
        yup.ref('waznoscOd'),
        formatMessage({ id: AppMessages['cards.multiAction.expiryDate.endDate.validation.value'] }),
      )
      .nullable(),
    dataZwrotu: yup
      .date()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .optional()
      .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }))
      .nullable(),
  });

  return { schema };
};
