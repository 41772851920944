import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { Company } from 'api/owners/owners.types';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';

import { OrganizationDetailsProps, UpdateOrganizationFormBody } from './OrganizationDetails.types';
import { OrganizationActions } from './organizationActions/OrganizationActions';
import * as Styled from './OrganizationDetails.styles';

export const OrganizationDetails = ({
  onSubmit,
  isLoading,
  selectedOrganization,
  mainOrganization,
  parentElement,
  organizations,
}: OrganizationDetailsProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isValid, errors },
    watch,
  } = useFormContext<UpdateOrganizationFormBody>();
  const parentFieldValue = watch('parent');
  const isCoreElementOfOrganization = !parentElement;
  const isNameAndCodeFieldsDisabled = !!(parentFieldValue && parentElement?.id !== parentFieldValue?.id);

  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <OrganizationActions mainOrganization={mainOrganization} selectedOrganization={selectedOrganization} />

        <Controller
          name="kod"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              label={formatMessage({ id: AppMessages['definitions.organization.add.code.label'] })}
              placeholder={formatMessage({ id: AppMessages['definitions.organization.add.code.placeholder'] })}
              errorMessage={error?.message}
              disabled={isNameAndCodeFieldsDisabled}
            />
          )}
        />

        <Controller
          name="nazwa"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              label={formatMessage({ id: AppMessages['definitions.organization.add.name.label'] })}
              placeholder={formatMessage({ id: AppMessages['definitions.organization.add.name.placeholder'] })}
              errorMessage={error?.message}
              disabled={isNameAndCodeFieldsDisabled}
            />
          )}
        />

        {!isCoreElementOfOrganization && !!organizations && (
          <>
            <Styled.Label>
              {formatMessage({ id: AppMessages['definitions.organization.details.parent.label'] })}
            </Styled.Label>
            <Styled.DescriptionWrapper>
              <Styled.Description>
                {formatMessage({ id: AppMessages['definitions.organization.details.parent.description'] })}
              </Styled.Description>
              <Styled.Description>
                {formatMessage({ id: AppMessages['definitions.organization.details.parent.description.validation'] })}
              </Styled.Description>
            </Styled.DescriptionWrapper>

            <Controller
              control={control}
              name="parent"
              render={({ field }) => (
                <OrganizationsTreeSelect
                  {...field}
                  withLabel
                  withSearchIcon={!!!field.value}
                  onChange={(val) => (val ? field.onChange(val) : field.onChange(''))}
                  value={typeof field.value === 'object' ? (field.value as Company) : undefined}
                  placeholder={formatMessage({ id: AppMessages['common.search'] })}
                />
              )}
            />
            <Styled.Error>{errors?.parent && 'id' in errors?.parent && errors?.parent?.id?.message}</Styled.Error>
          </>
        )}
      </div>
      {isDirty && (
        <Styled.ButtonsWrapper>
          <Button
            size="small"
            onClick={() => {
              reset({ ...selectedOrganization, parent: parentElement });
            }}
            color="primary"
          >
            {formatMessage({ id: AppMessages['event.details.form.button.cancel'] })}
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={isLoading}
            size="small"
            variant="contained"
            color="primary"
          >
            {formatMessage({ id: AppMessages['event.details.form.button.save'] })}
          </Button>
        </Styled.ButtonsWrapper>
      )}
    </Styled.Form>
  );
};
