import { Modal } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionButton } from 'ui/actionButton/ActionButton';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { DeleteGroupProps } from './DeleteGroup.types';

export const DeleteGroup = ({
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
  isDeleteError,
  errorMessage,
  onCloseDeleteModal,
}: DeleteGroupProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <ActionButton type="delete" onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['group.delete.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['group.delete.submitButton'] })}
      >
        <ActionModal.Text variant="h5" bold>
          {formatMessage({ id: AppMessages['group.delete.text'] })}
        </ActionModal.Text>
      </ActionModal>

      <Modal
        type="info"
        onSubmit={onCloseDeleteModal}
        header={formatMessage({ id: AppMessages['group.delete.title'] })}
        open={isDeleteError}
        onClose={onCloseDeleteModal}
        size="medium"
      >
        <ActionModal.Text variant="h5" bold>
          {errorMessage}
        </ActionModal.Text>
      </Modal>
    </>
  );
};
