import { useState } from 'react';
import { Button } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useModalState } from 'hooks/useModalState/useModalState';
import { AppMessages } from 'i18n/messages';

import { AddOwnerManuallyContainer } from './addOwnerManually/AddOwnerManuallyContainer';
import { AddOwnerPreview } from './addOwnerPreview/AddOwnerPreview';
import { AddOwnerImportContainer } from './addOwnerImport/AddOwnerImportContainer';
import { AddOwnerImportWithPhotoContainer } from './addOwnerInportWithPhoto/AddOwnerImportWithPhotoContainer';
import { AddOwnerSteps } from './AddOwner.enum';

export const AddOwner = () => {
  const { formatMessage } = useLocale();

  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const [choosenStep, setChoosenStep] = useState<AddOwnerSteps>(AddOwnerSteps.MANUALLY);
  const [step, setStep] = useState<AddOwnerSteps>(AddOwnerSteps.PREVIEW);

  const onModalCloseWithStateReset = () => {
    onModalClose();
    setStep(AddOwnerSteps.PREVIEW);
    setChoosenStep(AddOwnerSteps.MANUALLY);
  };

  const onClickBackButton = () => {
    setStep(AddOwnerSteps.PREVIEW);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['owners.addOwner'] })}
      </Button>

      {
        {
          [AddOwnerSteps.PREVIEW]: (
            <AddOwnerPreview
              isModalOpen={isModalOpen}
              onModalClose={onModalCloseWithStateReset}
              setStep={setStep}
              choosenStep={choosenStep}
              setChoosenStep={setChoosenStep}
            />
          ),

          [AddOwnerSteps.MANUALLY]: (
            <AddOwnerManuallyContainer
              isModalOpen={isModalOpen}
              onModalClose={onModalCloseWithStateReset}
              onClickBackButton={onClickBackButton}
            />
          ),

          [AddOwnerSteps.IMPORT]: (
            <AddOwnerImportContainer
              isModalOpen={isModalOpen}
              onModalClose={onModalCloseWithStateReset}
              onClickBackButton={onClickBackButton}
            />
          ),

          [AddOwnerSteps.IMPORT_WITH_PHOTO]: (
            <AddOwnerImportWithPhotoContainer
              isModalOpen={isModalOpen}
              onModalClose={onModalCloseWithStateReset}
              onClickBackButton={onClickBackButton}
            />
          ),
        }[step]
      }
    </>
  );
};
