import { UseQueryFn, MutationFn } from 'shared-ui';

import { PaginatedResponse } from 'core/global.types';

import { Server, AddServerFormBody } from './servers.types';

export const fetchServers: UseQueryFn<unknown, PaginatedResponse<Server>> = () => {
  return {
    endpoint: `/rcp`,
  };
};

export const connectServer: MutationFn<string, Server> = (id) => {
  return {
    endpoint: `/rcp/${id}/polacz`,
    method: 'POST',
  };
};

export const disconnectServer: MutationFn<string, Server> = (id) => {
  return {
    endpoint: `/rcp/${id}/odlacz`,
    method: 'POST',
  };
};

export const updateServer: MutationFn<{ id: string; body: Server }, Server> = ({ id, body }) => {
  return {
    endpoint: `/rcp/${id}`,
    method: 'PUT',
    body,
  };
};

export const addServer: MutationFn<{ body: AddServerFormBody }, Server> = ({ body }) => {
  return {
    endpoint: '/rcp',
    method: 'POST',
    body,
  };
};

export const deleteServer: MutationFn<{ id: string }, undefined> = ({ id }) => {
  return {
    endpoint: `/rcp/${id}`,
    method: 'DELETE',
  };
};

export const fetchServersForOwnerIndividualPermissions: UseQueryFn<string, PaginatedResponse<Server>> = (id) => {
  return {
    endpoint: `/pkd/${id}/uprawnienie/indywidualne/rcp`,
  };
};

export const fetchServersForOwnerEffectivePermissions: UseQueryFn<string, PaginatedResponse<Server>> = (id) => {
  return {
    endpoint: `/pkd/${id}/uprawnienie/efektywne/rcp`,
  };
};

export const fetchServersForGroupTerminalsPermissions: UseQueryFn<string, PaginatedResponse<Server>> = (id) => {
  return {
    endpoint: `/grupa/${id}/uprawnienie/rcp`,
  };
};

export const fetchServersForGroupGlcPermissions: UseQueryFn<string, PaginatedResponse<Server>> = (id) => {
  return {
    endpoint: `/grupa/${id}/uprawnienie/glc/rcp`,
  };
};

export const fetchServersForControl: UseQueryFn<string, PaginatedResponse<Server>> = (id) => {
  return {
    endpoint: `/kontrola/${id}/rcp`,
  };
};
