import { PermissionEnum, PermissionDesctiptionEnum } from 'api/permissions/permissions.enum';
import {
  GroupGlcPermissionsQueryKeysEnum,
  QueryKeyLocalStorageEnum,
  GroupGlcPermissionsGlcNameSortEnum,
  GroupGlcPermissionsIdSortEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';

export const GroupGlcTableHeader = () => {
  const { formatMessage } = useLocale();

  const premissionsFilterItems = Object.values(PermissionEnum).map((permission) => ({
    name: formatMessage({ id: PermissionDesctiptionEnum[permission] }),
    filterBy: permission,
  }));

  return (
    <>
      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.GROUP_GLC_PERMISSIONS}
        sortBy={GroupGlcPermissionsIdSortEnum}
        queryHeader={formatMessage({ id: AppMessages['group.reader.permissions.table.header.id'] })}
      />

      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.GROUP_GLC_PERMISSIONS}
        sortBy={GroupGlcPermissionsGlcNameSortEnum}
        queryHeader={formatMessage({ id: AppMessages['group.reader.permissions.table.header.glcName'] })}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.GROUP_GLC_PERMISSIONS}
        queryName={GroupGlcPermissionsQueryKeysEnum.REJESTRUJ_ODBICIE}
        filterItems={premissionsFilterItems}
        queryHeader={formatMessage({ id: AppMessages['group.reader.permissions.table.header.registerRecord'] })}
        queryHeaderDescriptions={PermissionDesctiptionEnum}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.GROUP_GLC_PERMISSIONS}
        queryName={GroupGlcPermissionsQueryKeysEnum.OTWIERAJ_ZAMEK}
        filterItems={premissionsFilterItems}
        queryHeader={formatMessage({ id: AppMessages['group.reader.permissions.table.header.openLock'] })}
        queryHeaderDescriptions={PermissionDesctiptionEnum}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.GROUP_GLC_PERMISSIONS}
        queryName={GroupGlcPermissionsQueryKeysEnum.REJESTRUJ_RCP}
        filterItems={premissionsFilterItems}
        queryHeader={formatMessage({ id: AppMessages['group.reader.permissions.table.header.registerRcp'] })}
        queryHeaderDescriptions={PermissionDesctiptionEnum}
      />
    </>
  );
};
