import { useFormContext } from 'react-hook-form';
import { Modal } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AddOwnerBody } from 'api/owners/owners.types';

import { BasicDataFormContainer } from './basicDataForm/BasicDataFormContainer';
import { SystemDataForm } from './systemDataForm/SystemDataForm';
import { PersonalDataForm } from './personalDataForm/PersonalDataForm';
import { AddOwnerManuallyProps } from './AddOwnerManually.types';
import * as Styled from './AddOwnerManually.styles';

export const AddOwnerManually = ({
  onSubmit,
  isModalOpen,
  onModalClose,
  isLoadingAddOwner,
  onClickBackButton,
}: AddOwnerManuallyProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    formState: { isValid },
  } = useFormContext<AddOwnerBody>();

  return (
    <Modal
      header={formatMessage({ id: AppMessages['owners.addOwner.title'] })}
      onClose={onModalClose}
      open={isModalOpen}
      size="big"
      type="form"
      onSubmit={handleSubmit(onSubmit)}
      submitButtonDisabled={!isValid || isLoadingAddOwner}
      withBackButton
      onClickBackButton={onClickBackButton}
    >
      <Styled.Grid data-testid="add-owner-manually">
        <Styled.SubHeader>{formatMessage({ id: AppMessages['owners.addOwner.subtitle.basicData'] })}</Styled.SubHeader>

        <BasicDataFormContainer />

        <Styled.SubHeader>{formatMessage({ id: AppMessages['owners.addOwner.subtitle.systemData'] })}</Styled.SubHeader>

        <SystemDataForm />

        <Styled.SubHeader>
          {formatMessage({ id: AppMessages['owners.addOwner.subtitle.personalData'] })}
        </Styled.SubHeader>

        <PersonalDataForm />
      </Styled.Grid>
    </Modal>
  );
};
