import { Button } from 'shared-ui';

import { GroupTypeEnum } from 'api/groups/groups.enum';
import { ReactComponent as ArchiveIcon } from 'assets/icons/archive-primary.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { ArchiveMultiGroupsProps } from './ArchiveMultiGroups.types';

export const ArchiveMultiGroups = ({
  checkedGroups,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
}: ArchiveMultiGroupsProps) => {
  const { formatMessage } = useLocale();

  const displayAdditionalInfo = checkedGroups.some(
    (group) => group.typ === GroupTypeEnum.DYNAMICZNA && !!group.komorki.length,
  );

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<ArchiveIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['groups.multiAction.archive.button'] })}
      </Button>

      <ActionModal
        header={formatMessage({ id: AppMessages['groups.multiAction.archive.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['groups.multiAction.archive.button'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['groups.multiAction.archive.subtitle'] })} {checkedGroups.length}
        </ActionModal.Subtitle>

        <ActionModal.Text>{formatMessage({ id: AppMessages['groups.multiAction.archive.text'] })}</ActionModal.Text>

        {displayAdditionalInfo && (
          <>
            <br />
            <ActionModal.Text>
              {formatMessage({ id: AppMessages['groups.multiAction.archive.warning'] })}
            </ActionModal.Text>
          </>
        )}
      </ActionModal>
    </>
  );
};
