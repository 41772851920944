import { ClientError, APIError, useQueryClient, useErrorHandler, useLoaderToast } from 'shared-ui';

import { connectServer } from 'api/servers/servers';
import { disconnectServer } from 'api/servers/servers';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useMutation } from 'hooks/useMutation/useMutation';
import { AppMessages } from 'i18n/messages';

import { ConnectServerActions } from './ConnectServerActions';
import { ConnectServerActionsContainerProps } from './ConnectServerActions.types';

export const ConnectServerActionsContainer = ({ server }: ConnectServerActionsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { handleError } = useErrorHandler();
  const { load: loadConnect, update: updateConnect } = useLoaderToast('connectServer');
  const { load: loadDisconnect, update: updateDisconnect } = useLoaderToast('disconnectServer');

  const { mutate: onConnect } = useMutation(connectServer, {
    onMutate: () => loadConnect(formatMessage({ id: AppMessages['devices.servers.loader.connect'] })),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.SERVERS]);
      updateConnect(
        formatMessage({ id: AppMessages['devices.servers.connect.success'] }, { serverName: server.nazwa }),
      );
    },
    onError: (error: ClientError<APIError>) => {
      handleError({ error, updateToastCallback: updateConnect });
    },
  });

  const { mutate: onDisconnect } = useMutation(disconnectServer, {
    onMutate: () => loadDisconnect(formatMessage({ id: AppMessages['devices.servers.loader.disconnect'] })),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.SERVERS]);

      updateDisconnect(
        formatMessage({ id: AppMessages['devices.servers.disconnect.success'] }, { serverName: server.nazwa }),
      );
    },
    onError: (error: ClientError<APIError>) => {
      handleError({ error, updateToastCallback: updateDisconnect });
    },
  });

  return <ConnectServerActions server={server} onConnect={onConnect} onDisconnect={onDisconnect} />;
};
