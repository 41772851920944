import { DeleteMultiAnnouncementAssignedOwnersContainer } from './deleteMultiAnnouncementAssignedOwners/DeleteMultiAnnouncementAssignedOwnersContainer';
import { AnnouncementAssignedOwnersMultiActionsProps } from './AnnouncementAssignedOwnersMultiActions.types';
import * as Styled from './AnnouncementAssignedOwnersMultiActions.styles';

export const AnnouncementAssignedOwnersMultiActions = ({
  checkedOwners,
  setCheckedOwners,
}: AnnouncementAssignedOwnersMultiActionsProps) => {
  return (
    <Styled.Container>
      <DeleteMultiAnnouncementAssignedOwnersContainer
        checkedOwners={checkedOwners}
        setCheckedOwners={setCheckedOwners}
      />
    </Styled.Container>
  );
};
