import { useLocale } from 'hooks/useLocale/useLocale';
import { Table } from 'ui/table/Table';
import { AppMessages } from 'i18n/messages';
import {
  QueryKeyLocalStorageEnum,
  WantedTerminalsIdSortEnum,
  WantedTerminalsNameSortEnum,
} from 'context/query/queryContext/QueryContext.enum';

import { WantedTerminalsRowsProps } from './WantedTerminalsRows.types';

export const WantedTerminalsRows = ({
  terminalsResponse,
  setCheckedTerminals,
  checkedTerminals,
}: WantedTerminalsRowsProps) => {
  const { formatMessage } = useLocale();

  return (
    <Table
      gridTemplateColumns="200px 1fr"
      data={terminalsResponse?.data || []}
      nothingToShowText={formatMessage({ id: AppMessages['wanted.terminals.nothingToShow'] })}
      customTableHeight={() => undefined}
      checkboxSelection={{
        checked: checkedTerminals,
        toggleChecked: setCheckedTerminals,
        withoutCheckedElementsBar: true,
      }}
      renderHeader={() => (
        <>
          <Table.HeaderSortItem
            queryKey={QueryKeyLocalStorageEnum.WANTED_TERMINALS}
            sortBy={WantedTerminalsIdSortEnum}
            queryHeader={formatMessage({ id: AppMessages['wanted.terminals.table.header.id'] })}
          />

          <Table.HeaderSortItem
            queryKey={QueryKeyLocalStorageEnum.WANTED_TERMINALS}
            sortBy={WantedTerminalsNameSortEnum}
            queryHeader={formatMessage({ id: AppMessages['wanted.terminals.table.header.readerName'] })}
          />
        </>
      )}
      renderRow={(terminal) => (
        <>
          <Table.RowItem>{terminal.numerLogicznyKontrolera}</Table.RowItem>

          <Table.RowItem>{terminal.nazwa}</Table.RowItem>
        </>
      )}
    />
  );
};
