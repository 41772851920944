import { Dropzone, DropzoneErrorCodes, Modal } from 'shared-ui';

import { ReactComponent as AddIcon } from 'assets/icons/plus.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import {
  ACCEPTED_IMPORT_FILE_TYPES,
  ACCEPTED_IMPORT_IMAGE_TYPES,
  MAX_IMPORT_FILES,
  MAX_IMPORT_FILE_SIZE_BYTES,
} from 'core/global.variables';
import { useOnDropRejected } from 'hooks/useOnDropRejected/useOnDropRejected';
import { DownloadExampleFile } from 'ui/downloadExampleFile/DownloadExampleFile';

import { AddOwnerImportWithPhotoProps } from './AddOwnerImportWithPhoto.types';
import * as Styled from './AddOwnerImportWithPhoto.styles';

const MAX_PHOTO_SIZE_BYTES = 10000000;
const MAX_PHOTOS = 100;

export const AddOwnerImportWithPhoto = ({
  isModalOpen,
  onModalClose,
  onClickBackButton,
  onAddFile,
  onAddPhotos,
  isLoadingAddFile,
  isLoadingAddPhotos,
  file,
  setFile,
  photos,
  setPhotos,
  fileError,
  setFileError,
  photosError,
  setPhotosError,
  downloadLink,
  setDownloadLink,
  filesNotMatch,
  setFilesNotMatch,
  onSubmit,
  isLoadingAddOwnersWithPhotos,
}: AddOwnerImportWithPhotoProps) => {
  const { formatMessage } = useLocale();
  const { onDropRejected } = useOnDropRejected(setFileError);

  return (
    <Modal
      header={formatMessage({ id: AppMessages['owners.addOwner.title'] })}
      submitButtonText={formatMessage({ id: AppMessages['common.add'] })}
      onClose={onModalClose}
      open={isModalOpen}
      size="medium"
      type="form"
      onSubmit={onSubmit}
      withBackButton
      onClickBackButton={onClickBackButton}
      isLoading={isLoadingAddOwnersWithPhotos}
      submitButtonDisabled={
        !file ||
        !photos ||
        !photos.length ||
        !!downloadLink ||
        !!fileError ||
        !!photosError ||
        filesNotMatch ||
        isLoadingAddFile ||
        isLoadingAddPhotos
      }
      cancelButtonDisabled={isLoadingAddOwnersWithPhotos}
    >
      <div data-testid="add-owner-importWithPhoto">
        <Dropzone
          file={file}
          onClickRemoveButton={() => {
            setFile(undefined);
            setPhotosError(undefined);
          }}
          dropzoneDescription={formatMessage({
            id: AppMessages['owners.addOwner.importWithPhoto.importFile.description'],
          })}
          isLoading={isLoadingAddFile}
          error={fileError}
          addedFileSubtext={file && `${(file.size / 1000000).toFixed(2)} MB / 15 MB`}
          rootDropzoneProps={{
            disabled: isLoadingAddOwnersWithPhotos,
            multiple: false,
            maxFiles: MAX_IMPORT_FILES,
            maxSize: MAX_IMPORT_FILE_SIZE_BYTES,
            accept: ACCEPTED_IMPORT_FILE_TYPES,
            onDrop: (acceptedFiles, rejectedFiles) => {
              if (rejectedFiles.length) return;

              setFileError(undefined);
              onAddFile(acceptedFiles[0]);
            },
            onDropRejected: onDropRejected,
          }}
          title={
            <>
              {formatMessage({ id: AppMessages['owners.addOwner.importWithPhoto.stepOne'] })}
              <Styled.SubtitleText>
                {formatMessage({ id: AppMessages['owners.addOwner.import.importFile'] })}
              </Styled.SubtitleText>
            </>
          }
          titleAddonEnd={<DownloadExampleFile exampleType="ownersWithPhoto" />}
          removeFileButtonOptions={{
            disabled: !!filesNotMatch || (!!photos && !!photos.length),
            disabledTooltipText: formatMessage({
              id: AppMessages['owners.addOwner.importWithPhoto.importFile.buttonDisabled'],
            }),
          }}
        />

        <Dropzone
          type="images"
          file={photos}
          onClickRemoveButton={() => {
            setPhotos(undefined);
            setFilesNotMatch(false);
            setDownloadLink('');
          }}
          dropzoneDescription={formatMessage({
            id: AppMessages['owners.addOwner.importWithPhoto.importPhotos.description'],
          })}
          uploadIcon={<AddIcon />}
          isLoading={isLoadingAddPhotos}
          error={photosError}
          rootDropzoneProps={{
            disabled: !file || isLoadingAddOwnersWithPhotos,
            accept: ACCEPTED_IMPORT_IMAGE_TYPES,
            maxSize: MAX_PHOTO_SIZE_BYTES,
            maxFiles: MAX_PHOTOS,
            onDrop: (acceptedFiles, rejectedFiles) => {
              if (rejectedFiles.length) return;

              onAddPhotos(acceptedFiles);
              setPhotosError(undefined);
            },
            onDropRejected: (rejectedFiles) => {
              const isFileTooLarge = rejectedFiles.some(
                ({ errors }) => errors[0].code === DropzoneErrorCodes.FILE_TOO_LARGE,
              );
              const isInvalidResolution = rejectedFiles.some(
                ({ errors }) => errors[0].code === DropzoneErrorCodes.INVALID_RESOLUTION,
              );
              const isInvalidFileType = rejectedFiles.some(
                ({ errors }) => errors[0].code === DropzoneErrorCodes.FILE_INVALID_TYPE,
              );
              const isTooManyFiles = rejectedFiles.some(
                ({ errors }) => errors[0].code === DropzoneErrorCodes.TOO_MANY_FILES,
              );

              if (isFileTooLarge) {
                setPhotosError(
                  formatMessage({ id: AppMessages['owners.addOwner.importWithPhoto.error.invalidPhotoSize'] }),
                );
              }
              if (isInvalidResolution) {
                setPhotosError(
                  formatMessage({ id: AppMessages['owners.addOwner.importWithPhoto.error.invalidResolution'] }),
                );
              }
              if (isInvalidFileType) {
                setPhotosError(
                  formatMessage({ id: AppMessages['owners.addOwner.importWithPhoto.error.invalidPhotoType'] }),
                );
              }
              if (isTooManyFiles) {
                setPhotosError(
                  formatMessage({ id: AppMessages['owners.addOwner.importWithPhoto.error.tooManyPhotos'] }),
                );
              }

              setPhotos(undefined);
            },
          }}
          title={
            <>
              {formatMessage({ id: AppMessages['owners.addOwner.importWithPhoto.stepTwo'] })}
              <Styled.SubtitleText>
                {formatMessage({ id: AppMessages['owners.addOwner.importWithPhoto.importPhotos'] })}
              </Styled.SubtitleText>
            </>
          }
        />

        {downloadLink && (
          <Styled.ErrorBox>
            <Styled.ErrorText>
              {filesNotMatch
                ? formatMessage({ id: AppMessages['owners.addOwner.importWithPhoto.error.filesNotMatchRaport'] })
                : formatMessage({ id: AppMessages['owners.addOwner.importWithPhoto.error.excludedOwnersRaport'] })}
            </Styled.ErrorText>

            <Styled.DownloadLink download="report.txt" href={downloadLink}>
              {formatMessage({ id: AppMessages['common.download.errorReport'] })}
            </Styled.DownloadLink>
          </Styled.ErrorBox>
        )}
      </div>
    </Modal>
  );
};
