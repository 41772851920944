import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';
import { ActionButton } from 'ui/actionButton/ActionButton';

import { DeleteServerProps } from './DeleteServer.types';

export const DeleteServer = ({
  selectedServer,
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
}: DeleteServerProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <ActionButton type="delete" onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['common.delete'] })}
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        submitButtonDisabled={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['devices.details.deleteServer.actionButton'] })}
      >
        <>
          <ActionModal.Subtitle>
            {formatMessage({ id: AppMessages['devices.details.deleteServer.subtitle'] })} {selectedServer.nazwa}
          </ActionModal.Subtitle>

          <ActionModal.Text>{formatMessage({ id: AppMessages['devices.details.deleteServer.text'] })}</ActionModal.Text>

          <ActionModal.Text>{formatMessage({ id: AppMessages['common.irreversibleAction'] })}</ActionModal.Text>
        </>
      </ActionModal>
    </>
  );
};
