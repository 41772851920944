import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { unarchiveMultiCards } from 'api/cards/cards';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { useModalState } from 'hooks/useModalState/useModalState';

import { UnarchiveMultiCards } from './UnarchiveMultiCards';
import { UnarchiveMultiCardsContainerProps } from './UnarchiveMultiCards.types';

export const UnarchiveMultiCardsContainer = ({ checkedCards }: UnarchiveMultiCardsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedCard, setSelectedCard, setLastArchivedSelectedCardId } = useSelectedCard();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onUnarchiveMultiCards, isLoading } = useMutation(unarchiveMultiCards, {
    onSuccess: () => {
      if (selectedCard && checkedCards.some(({ id }) => id === selectedCard?.id)) {
        setSelectedCard(undefined);
        setLastArchivedSelectedCardId(undefined);
      }

      queryClient.invalidateQueries([QueryKeyEnum.CARDS]);
      toast.dark(formatMessage({ id: AppMessages['cards.multiAction.unarchive.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onUnarchiveMultiCards({ body: checkedCards.map(({ id }) => id) });
  };

  return (
    <UnarchiveMultiCards
      checkedCards={checkedCards}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
