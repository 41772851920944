import { Table } from 'ui/table/Table';

import { GroupAssignedOwnersTableRowProps } from './GroupAssignedOwnersTableRow.types';

export const GroupAssignedOwnersTableRow = ({ owner }: GroupAssignedOwnersTableRowProps) => {
  return (
    <>
      <Table.RowItem>{owner.skdId}</Table.RowItem>

      <Table.RowItem>{`${owner.nazwisko} ${owner.imie}`}</Table.RowItem>

      <Table.RowCellsItem cells={owner.komorka} />

      <Table.RowItem text={owner?.stanowisko?.nazwa} />

      <Table.RowItem>{owner.nrEwidencyjny}</Table.RowItem>
    </>
  );
};
