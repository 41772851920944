import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

import { AddEventExportFormatBody } from 'api/eventsExportFormat/eventsExportFormats.types';
import {
  addEventExportFormat,
  fetchEventsExportFormatConfig,
  fetchEventExportFormatDetails,
  previewEventExportFormat,
  editEventExportFormat,
} from 'api/eventsExportFormat/eventsExportFormats';
import { useMutation } from 'hooks/useMutation/useMutation';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppRoute } from 'routing/AppRoute.enum';
import { AppMessages } from 'i18n/messages';

import { AddEventExportFormat } from './AddEventExportFormat';
import { useAddEventExportFormatValidation } from './AddEventExportFormat.validation';
import { AddEventExportFormatPageModeEnum } from './AddEventExportFormat.enum';
import { addEventExportFormatDefaultFormValues, AddEventExportFormatUrlParams } from './AddEventExportFormat.types';

export const AddEventExportFormatContainer = () => {
  const { formatMessage } = useLocale();
  const { id: exportFormatId, mode } = useParams<AddEventExportFormatUrlParams>();
  const navigate = useNavigate();

  const isAddPageMode = mode === AddEventExportFormatPageModeEnum.ADD;
  const isEditPageMode = !!exportFormatId && mode === AddEventExportFormatPageModeEnum.EDIT;
  const isPreviewPageMode = !!exportFormatId && mode === AddEventExportFormatPageModeEnum.PREVIEW;

  const { schema } = useAddEventExportFormatValidation();
  const formMethods = useForm<AddEventExportFormatBody>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: addEventExportFormatDefaultFormValues,
  });

  const {
    data: eventExportFormat,
    isInitialLoading: isLoadingEventExportFormat,
    isRefetching: isRefetchingEventExportFormat,
  } = useQuery([QueryKeyEnum.EVENT_EXPORT_FORMAT_DETAILS], () => fetchEventExportFormatDetails(exportFormatId), {
    enabled: isEditPageMode || isPreviewPageMode,
    refetchOnMount: true,
  });

  const { data: eventsExportFormatConfig, isLoading: isLoadingEventExportFormatConfig } = useQuery(
    [QueryKeyEnum.EVENTS_EXPORT_FORMAT_CONFIG],
    fetchEventsExportFormatConfig,
  );

  const { mutate: onAddEventExportFormat, isLoading: isLoadingAddExportFormat } = useMutation(addEventExportFormat, {
    onSuccess: () => {
      navigate(AppRoute.eventsExportFormats);
      toast.dark(formatMessage({ id: AppMessages['definitions.events.addExportFormat.addSuccessMessage'] }));
    },
  });

  const { mutate: onEditEventExportFormat, isLoading: isLoadingEditExportFormat } = useMutation(editEventExportFormat, {
    onSuccess: () => {
      navigate(AppRoute.eventsExportFormats);
      toast.dark(formatMessage({ id: AppMessages['definitions.events.addExportFormat.editSuccessMessage'] }));
    },
  });

  const {
    mutate: onPreviewEventExportFormat,
    data: eventExportFormatPreview,
    isLoading: isLoadingPreviewEventExportFormat,
  } = useMutation(previewEventExportFormat);

  const onSubmit = (body: AddEventExportFormatBody) => {
    if (isEditPageMode) {
      onEditEventExportFormat({
        id: exportFormatId,
        body,
      });
    } else {
      onAddEventExportFormat(body);
    }
  };

  const onResetForm = () => {
    if (eventExportFormat && (isEditPageMode || isPreviewPageMode)) {
      const { id, createdAt, updatedAt, ...eventExportFormatFormatValues } = eventExportFormat;

      formMethods.reset(eventExportFormatFormatValues);
      onPreviewEventExportFormat(eventExportFormatFormatValues);
    } else if (eventsExportFormatConfig && isAddPageMode) {
      const { date, endline, separator } = eventsExportFormatConfig;

      const defaultFormValues = {
        ...addEventExportFormatDefaultFormValues,
        endline: endline[0],
        separator: separator[0],
        data: { ...addEventExportFormatDefaultFormValues.data, format: date[0] },
      };

      formMethods.reset(defaultFormValues);
      onPreviewEventExportFormat(defaultFormValues);
    }
  };

  useEffect(() => {
    onResetForm();
  }, [eventExportFormat, eventsExportFormatConfig]);

  return (
    <FormProvider {...formMethods}>
      <AddEventExportFormat
        onSubmit={onSubmit}
        isLoading={isLoadingEventExportFormat || isLoadingEventExportFormatConfig || isRefetchingEventExportFormat}
        isLoadingAddExportFormat={isLoadingAddExportFormat || isLoadingEditExportFormat}
        eventsExportFormatConfig={eventsExportFormatConfig}
        eventExportFormatPreview={eventExportFormatPreview?.preview}
        isLoadingPreviewEventExportFormat={isLoadingPreviewEventExportFormat}
        onPreviewEventExportFormat={onPreviewEventExportFormat}
        isPreviewPageMode={isPreviewPageMode}
        isEditPageMode={isEditPageMode}
      />
    </FormProvider>
  );
};
