import { useContext } from 'react';

import { SelectedGroupContext } from 'context/selectedGroup/selectedGroupContext/SelectedGroupContext';

export const useSelectedGroup = () => {
  const context = useContext(SelectedGroupContext);

  if (context === undefined) {
    throw new Error('SelectedGroupContext is unavailable, make sure you are using SelectedGroupContextController');
  }

  return context;
};
