import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'shared-ui';

import { ReactComponent as BlockIcon } from 'assets/icons/block.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { BlockCardBody } from 'api/cards/cards.types';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { BlockMultiCardsProps } from './BlockMultiCards.types';

export const BlockMultiCards = ({
  checkedCards,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
}: BlockMultiCardsProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useFormContext<BlockCardBody>();

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<BlockIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['cards.multiAction.block.button'] })}
      </Button>

      <ActionModal
        header={formatMessage({ id: AppMessages['cards.multiAction.block.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={isLoading || !isValid}
        submitButtonText={formatMessage({ id: AppMessages['cards.multiAction.block.actionButton'] })}
      >
        <>
          <ActionModal.Subtitle>
            {formatMessage({ id: AppMessages['cards.multiAction.block.subtitle'] })} {checkedCards.length}
          </ActionModal.Subtitle>

          <Controller
            name="powodBlokady"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={formatMessage({ id: AppMessages['cards.multiAction.block.reason.label'] })}
                placeholder={formatMessage({ id: AppMessages['cards.multiAction.block.reason.placeholder'] })}
                required
              />
            )}
          />
        </>
      </ActionModal>
    </>
  );
};
