import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { archiveCard } from 'api/cards/cards';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRouteWithParams } from 'routing/AppRoute.enum';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { useModalState } from 'hooks/useModalState/useModalState';

import { ArchiveCard } from './ArchiveCard';
import { ArchiveCardContainerProps } from './ArchiveCard.types';

export const ArchiveCardContainer = ({ selectedCard, setSelectedCard }: ArchiveCardContainerProps) => {
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();
  const isHistoryView = useIsExactPath(AppRouteWithParams.cardHistory.path);

  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onArchiveCard, isLoading } = useMutation(archiveCard, {
    onSuccess: (updatedCard) => {
      if (isHistoryView) {
        setSelectedCard(updatedCard);
        queryClient.invalidateQueries([QueryKeyEnum.CARD_HISTORY]);
      } else {
        queryClient.invalidateQueries([QueryKeyEnum.CARDS]);
        setSelectedCard(undefined);
      }

      toast.dark(formatMessage({ id: AppMessages['card.archive.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onArchiveCard(selectedCard.id);
  };

  return (
    <ArchiveCard
      selectedCard={selectedCard}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
