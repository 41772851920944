import { useNavigate } from 'react-router-dom';

import { ActionButton } from 'ui/actionButton/ActionButton';
import { AppRouteWithParams } from 'routing/AppRoute.enum';

import { DeleteAnnouncementContainer } from './deleteAnnouncement/DeleteAnnouncementContainer';
import { AnnouncementActionsProps } from './AnnouncementActions.types';
import * as Styled from './AnnouncementActions.styles';

export const AnnouncementActions = ({ selectedAnnouncement }: AnnouncementActionsProps) => {
  const navigate = useNavigate();
  const canBeDeleted = !selectedAnnouncement.pkd;

  return (
    <Styled.ButtonsWrapper>
      <ActionButton
        type="owners"
        onClick={() => navigate(AppRouteWithParams.announcementOwners.get({ id: selectedAnnouncement.id }))}
      />

      {canBeDeleted && <DeleteAnnouncementContainer selectedAnnouncement={selectedAnnouncement} />}
    </Styled.ButtonsWrapper>
  );
};
