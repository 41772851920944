import { IconButton, InputLabel, Typography } from '@mui/material';
import styled, { css } from 'styled-components';

import { ChangePinWrapperStylesProps } from './CardDetailsEdit.types';

const textStyle = css(
  ({ theme: { palette } }) => css`
    color: ${palette.grey[700]};
    font-size: 14px;
    padding: 4px 0px;
    align-self: center;
  `,
);

export const CardDetailsWrapper = styled.form`
  padding-bottom: 40px;
`;

export const DetailsItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  margin-bottom: 8px;
`;

export const DetailsItemVerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`;

export const DetailText = styled(InputLabel)`
  ${textStyle};
  font-weight: 700;
`;

export const DetailVerticalText = styled(InputLabel)`
  ${textStyle};
  font-weight: 700;
  align-self: flex-start;
`;

export const SecondaryText = styled(Typography)`
  ${textStyle};
  font-weight: 500;
  word-break: break-word;
`;

export const CardStatus = styled(Typography)(
  ({ theme: { palette } }) => css`
    ${textStyle};
    color: ${palette.grey[900]};
    font-weight: 700;
    text-transform: uppercase;
  `,
);

export const ButtonsWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-around;
`;

export const ExpiryDateSubtitle = styled(Typography)`
  font-size: 18px;
  width: 600px;
  font-weight: 700;
  margin-bottom: 16px;
`;

export const PinButton = styled(IconButton)(
  ({ theme: { palette } }) => css`
    width: 45px;
    height: 45px;
    border-radius: 16px;
    background-color: ${palette.grey[100]};
    margin-left: 8px;

    &.Mui-disabled {
      background-color: #fbfbfb;

      svg {
        opacity: 0.35;
      }
    }
  `,
);

export const ChangePinWrapper = styled.div<ChangePinWrapperStylesProps>(
  ({ $isDisabled }) => css`
    display: flex;
    cursor: ${$isDisabled ? 'not-allowed' : 'auto'};
  `,
);
