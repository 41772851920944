import { StatusTableItem } from 'ui/statusTableItem/StatusTableItem';
import { Table } from 'ui/table/Table';

import { TerminalsTableRowProps } from './TerminalsTableRow.types';
import * as Styled from './TerminalsTableRow.styles';

export const TerminalsTableRow = ({ terminal, terminalsRowsDisabled }: TerminalsTableRowProps) => {
  return (
    <>
      <Table.RowItem>{terminal.numerLogicznyKontrolera}</Table.RowItem>
      <Table.RowItem> {terminal.nazwa}</Table.RowItem>
      <Table.RowItem>{terminal.podlaczony ? <Styled.YesIcon /> : <Styled.NoIcon />}</Table.RowItem>
      <Table.RowItem>{terminal.dostepny ? <Styled.YesIcon /> : <Styled.NoIcon />} </Table.RowItem>
      <Table.RowItem>
        <StatusTableItem status={terminal.status} disabled={terminalsRowsDisabled} />
      </Table.RowItem>
      <Table.RowItem>{terminal.glc && terminal.glc.idGlc}</Table.RowItem>
    </>
  );
};
