import { useNavigate } from 'react-router-dom';

import { GroupTypeEnum } from 'api/groups/groups.enum';
import { AppRouteWithParams } from 'routing/AppRoute.enum';
import { ActionButton } from 'ui/actionButton/ActionButton';

import { CopyGroupContainer } from './copyGroup/CopyGroupContainer';
import { DeleteGroupContainer } from './deleteGroup/DeleteGroupContainer';
import { GroupActionsProps } from './GroupActions.types';
import { ArchiveGroupContainer } from './archiveGroup/ArchiveGroupContainer';
import { UnarchiveGroupContainer } from './unarchiveGroup/UnarchiveGroupContainer';
import * as Styled from './GroupActions.styles';

export const GroupActions = ({ selectedGroup }: GroupActionsProps) => {
  const navigate = useNavigate();
  const isStaticGroup = selectedGroup.typ === GroupTypeEnum.STATYCZNA;
  const isArchived = selectedGroup.archiwalna;
  const isArchiveAvailable = !isArchived && !selectedGroup.posiadacz;

  return (
    <Styled.ButtonsWrapper>
      {selectedGroup.canBeDeleted && <DeleteGroupContainer selectedGroup={selectedGroup} />}

      {isArchiveAvailable && <ArchiveGroupContainer selectedGroup={selectedGroup} />}

      {isArchived && <UnarchiveGroupContainer selectedGroup={selectedGroup} />}

      {!isArchived && (
        <>
          <ActionButton
            type="permissions"
            onClick={() => {
              if (selectedGroup.glc) navigate(AppRouteWithParams.groupGlcPermissions.get({ id: selectedGroup.id }));
              else navigate(AppRouteWithParams.groupReadersPermissions.get({ id: selectedGroup.id }));
            }}
          />

          {isStaticGroup && (
            <ActionButton
              type="owners"
              onClick={() => navigate(AppRouteWithParams.groupOwners.get({ id: selectedGroup.id }))}
            />
          )}

          <CopyGroupContainer selectedGroup={selectedGroup} />
        </>
      )}

      <ActionButton
        type="history"
        onClick={() => navigate(AppRouteWithParams.groupsHistory.get({ id: selectedGroup.id }))}
      />
    </Styled.ButtonsWrapper>
  );
};
