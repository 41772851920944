import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';

import { InstantControlTableHeader } from './instantControlTableHeader/InstantControlTableHeader';
import { InstantControlTableRow } from './instantControlTableRow/InstantControlTableRow';
import { InstantControlProps } from './InstantControl.types';

export const InstantControl = ({
  ownersResponse,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}: InstantControlProps) => {
  const { formatMessage } = useLocale();

  return (
    <Table
      isLoading={isLoading}
      pagination={{
        page,
        setPage,
        count: ownersResponse?.count || 0,
        rowsPerPage,
        setRowsPerPage,
      }}
      gridTemplateColumns="1fr minmax(190px, 1fr) 1fr 1fr 1fr 100px"
      data={ownersResponse?.data || []}
      nothingToShowText={formatMessage({ id: AppMessages['control.instant.nothingToShow'] })}
      renderHeader={() => <InstantControlTableHeader />}
      renderRow={(owner) => <InstantControlTableRow owner={owner} />}
    />
  );
};
