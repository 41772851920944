import { ForwardedRef, forwardRef, useState } from 'react';
import { SearchIcon } from 'shared-ui';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

import { Option } from './MultiAutocomplete.types';
import { MultiAutocompleteProps } from './MultiAutocomplete.types';
import * as Styled from './MultiAutocomplete.styles';

export const MultiAutocomplete = forwardRef(
  (
    {
      label,
      required,
      placeholder,
      inputVariant = 'filled',
      inputSize,
      stopRotatePopupIndicator,
      withoutSearchIcon,
      withLimitedTags,
      withDefaultIcon,
      ...props
    }: MultiAutocompleteProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { formatMessage } = useLocale();

    const [inputValue, setInputValue] = useState<string | undefined>(props.inputValue || '');

    return (
      <div>
        {label && (
          <Styled.Label htmlFor={label}>
            {label} {required && <Styled.Required>*</Styled.Required>}
          </Styled.Label>
        )}
        <Styled.MultiAutocomplete
          id={props.id || label}
          {...props}
          value={props.value || []}
          multiple
          openOnFocus
          disableClearable
          disableCloseOnSelect
          popupIcon={withDefaultIcon ? undefined : withoutSearchIcon ? null : <SearchIcon />}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onInputChange={(e, value, inputChangeReason) => {
            props.onInputChange && props.onInputChange(e, value, inputChangeReason);
            e && setInputValue(value);
          }}
          inputValue={inputValue}
          renderInput={(renderProps) => (
            <Styled.Input
              {...renderProps}
              inputRef={ref}
              fullWidth
              variant={inputVariant}
              inputProps={{
                ...renderProps.inputProps,
                placeholder: props.value?.length ? '' : placeholder,
              }}
              InputProps={{ ...renderProps.InputProps }}
              $inputSize={inputSize}
              $stopRotatePopupIndicator={stopRotatePopupIndicator}
            />
          )}
          loadingText={props.loadingText || formatMessage({ id: AppMessages['common.loading'] })}
          noOptionsText={props.noOptionsText || formatMessage({ id: AppMessages['common.notFound'] })}
          ChipProps={{ deleteIcon: <></> }}
          {...(withLimitedTags
            ? {
                renderTags: (value: Option[]) => (
                  <Styled.TagsWrapper>
                    <Styled.Tag>{value[0].label}</Styled.Tag>
                    {value.length > 1 && <Styled.Chip>+{value.length - 1}</Styled.Chip>}
                  </Styled.TagsWrapper>
                ),
              }
            : {})}
        />
      </div>
    );
  },
);
