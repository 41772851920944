import { Typography } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as TrashIconBase } from 'assets/icons/primary-trash.svg';

export const CannotDeleteErrorText = styled(Typography)`
  font-weight: 700;
  margin-bottom: 0;
`;

export const TrashIcon = styled(TrashIconBase)`
  cursor: pointer;
`;
