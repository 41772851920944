import { useFormContext } from 'react-hook-form';
import { Modal, Button } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Form } from 'ui/form/Form';

import { AddGroupDefinitionFormContainer } from './addGroupDefinitionForm/AddGroupDefinitionFormContainer';
import { AddGroupPermissionsFormContainer } from './addGroupPermissionsForm/AddGroupPermissionsFormContainer';
import { AddGroupFormBody, AddGroupProps } from './AddGroup.types';
import * as Styled from './AddGroup.styles';

export const AddGroup = ({ onSubmit, onModalOpen, onModalClose, isModalOpen, isLoadingAddGroup }: AddGroupProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = useFormContext<AddGroupFormBody>();

  return (
    <>
      <Button variant="contained" color="primary" onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['groups.addGroup'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['groups.addGroup.title'] })}
        onClose={onModalClose}
        open={isModalOpen}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid || isLoadingAddGroup || !!Object.keys(errors).length}
      >
        <Form.Grid>
          <Styled.SubHeader>
            {formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.title'] })}
          </Styled.SubHeader>

          <AddGroupDefinitionFormContainer />

          <Styled.SubHeader>{formatMessage({ id: AppMessages['groups.addGroup.permissions.title'] })}</Styled.SubHeader>

          <AddGroupPermissionsFormContainer />
        </Form.Grid>
      </Modal>
    </>
  );
};
