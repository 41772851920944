import { useState } from 'react';

import { User } from 'api/users/users.types';
import { SelectedUserContext } from '../selectedUserContext/SelectedUserContext';
import { UserDetailsTabEnum } from '../selectedUserContext/SelectedUserContext.enum';

import { SelectedUserContextControllerProps } from './SelectedUserContextController.types';

export const SelectedUserContextController = ({ children }: SelectedUserContextControllerProps) => {
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [selectedUserDetailsTab, setSelectedUserDetailsTab] = useState(UserDetailsTabEnum.BASIC);

  const handleSetSelectedUser = (user?: User) => {
    if (selectedUser?.id !== user?.id) setSelectedUserDetailsTab(UserDetailsTabEnum.BASIC);

    setSelectedUser(user);
  };

  return (
    <SelectedUserContext.Provider
      value={{
        setSelectedUser: handleSetSelectedUser,
        selectedUserDetailsTab,
        setSelectedUserDetailsTab,
        selectedUser,
      }}
    >
      {children}
    </SelectedUserContext.Provider>
  );
};
