import { useState } from 'react';
import { Popover } from 'shared-ui';

import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';

import { Search } from './search/Search';
import { HeaderSearchFilterItemProps } from './HeaderSearchFilterItem.types';
import * as Styled from './HeaderSearchFilterItem.styles';
import { usePositions } from './hooks/usePositions';
import { useGlcs } from './hooks/useGlcs';

const HeaderSearchFilterItem = (props: HeaderSearchFilterItemProps) => {
  const [headerFilterEl, setHeaderFilterEl] = useState<HTMLDivElement | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState('');

  const positionsProps = usePositions(searchQuery, props.filterBy === 'positions-pkd');
  const glcsProps = useGlcs(searchQuery, props.filterBy === 'glcs-tkd');

  const getProps = () => {
    switch (props.filterBy) {
      case 'positions-pkd':
        return positionsProps;
      case 'glcs-tkd':
        return glcsProps;
    }
  };

  const filterProps = getProps();

  return (
    <Styled.HeaderItem>
      <Styled.HeaderItemSortable $isActive={!!headerFilterEl} onClick={(e) => setHeaderFilterEl(e.currentTarget)}>
        {props.children}
        {filterProps.isOptionSelected ? (
          <RemoveIcon
            onClick={(e) => {
              e.stopPropagation();
              filterProps.clearSearch();
            }}
          />
        ) : (
          <DropdownIcon />
        )}
      </Styled.HeaderItemSortable>

      {headerFilterEl && (
        <Popover anchorEl={headerFilterEl} onClose={() => setHeaderFilterEl(undefined)}>
          <Search {...filterProps} setSearchQuery={setSearchQuery} />
        </Popover>
      )}
    </Styled.HeaderItem>
  );
};

// eslint-disable-next-line import/no-default-export
export default HeaderSearchFilterItem;
