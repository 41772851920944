import { MutationFn, UseQueryFn } from 'shared-ui';

import { PaginatedResponse } from 'core/global.types';

import {
  Permission,
  FetchOwnerPermissionsBody,
  AddOwnerIndividualPermissionsBody,
  EditOwnerIndividualPermissionsBody,
  EditOwnerIndividualPermissionBody,
  DeleteOwnerIndividualPermissionsBody,
  AddGroupTerminalPermissionsBody,
  DeleteGroupTerminalPermissionsBody,
  EditGroupTerminalPermissionBody,
  FetchGroupTerminalsPermissionsBody,
  EditMultiGroupTerminalsPermissionsBody,
  DeleteMultiGroupTerminalsPermissionsBody,
  GroupPermission,
  FetchGroupGlcPermissionsBody,
  DeleteGroupGlcPermissionsBody,
  EditGroupGlcPermissionBody,
  EditMultiGroupGlcPermissionsBody,
  DeleteMultiGroupGlcPermissionsBody,
  AddGroupGlcPermissionsBody,
} from './permissions.types';

export const fetchOwnerEffectivePermissions: UseQueryFn<FetchOwnerPermissionsBody, PaginatedResponse<Permission>> = (
  body,
) => {
  return {
    endpoint: `/pkd/${body?.ownerId}/uprawnienie/efektywne/rcp/${body?.serverId}`,
  };
};

export const fetchOwnerIndividualPermissions: UseQueryFn<FetchOwnerPermissionsBody, PaginatedResponse<Permission>> = (
  body,
) => {
  return {
    endpoint: `/pkd/${body?.ownerId}/uprawnienie/indywidualne/rcp/${body?.serverId}`,
  };
};

export const addOwnerIndividualPermissions: MutationFn<AddOwnerIndividualPermissionsBody, Permission[]> = ({
  id,
  body,
}) => {
  return {
    endpoint: `/pkd/${id}/uprawnienie/indywidualne`,
    method: 'POST',
    body,
  };
};

export const editOwnerIndividualPermissions: MutationFn<EditOwnerIndividualPermissionsBody, void> = ({ id, body }) => {
  return {
    endpoint: `/batch/pkd/${id}/uprawnienie/indywidualne`,
    method: 'PUT',
    body,
  };
};

export const editOwnerIndividualPermission: MutationFn<EditOwnerIndividualPermissionBody, void> = ({
  pkdId,
  permissionId,
  body,
}) => {
  return {
    endpoint: `/pkd/${pkdId}/uprawnienie/indywidualne/${permissionId}`,
    method: 'PUT',
    body,
  };
};

export const deleteOwnerIndividualPermission: MutationFn<
  {
    pkdId: string;
    permissionId: string;
  },
  void
> = ({ pkdId, permissionId }) => {
  return {
    endpoint: `/pkd/${pkdId}/uprawnienie/indywidualne/${permissionId}`,
    method: 'DELETE',
  };
};

export const deleteOwnerIndividualPermissions: MutationFn<DeleteOwnerIndividualPermissionsBody, void> = ({
  pkdId,
  body,
}) => {
  return {
    endpoint: `/batch/pkd/${pkdId}/uprawnienie/indywidualne`,
    method: 'DELETE',
    body,
  };
};

export const fetchGroupTerminalsPermissions: UseQueryFn<
  FetchGroupTerminalsPermissionsBody,
  PaginatedResponse<GroupPermission>
> = (body) => {
  return {
    endpoint: `/grupa/${body?.groupId}/uprawnienie/rcp/${body?.serverId}`,
  };
};

export const addGroupTerminalPermissions: MutationFn<AddGroupTerminalPermissionsBody, void> = ({ id, body }) => {
  return {
    endpoint: `grupa/${id}/uprawnienie`,
    method: 'POST',
    body,
  };
};

export const deleteGroupTerminalPermissions: MutationFn<DeleteGroupTerminalPermissionsBody, void> = ({
  groupId,
  permissionId,
}) => {
  return {
    endpoint: `/grupa/${groupId}/uprawnienie/${permissionId}`,
    method: 'DELETE',
  };
};

export const editGroupTerminalPermission: MutationFn<EditGroupTerminalPermissionBody, void> = ({
  groupId,
  permissionId,
  body,
}) => {
  return {
    endpoint: `/grupa/${groupId}/uprawnienie/${permissionId}`,
    method: 'PUT',
    body,
  };
};

export const editMultiGroupTerminalsPermissions: MutationFn<EditMultiGroupTerminalsPermissionsBody, void> = ({
  id,
  body,
}) => {
  return {
    endpoint: `/batch/grupa/${id}/uprawnienie/grupowe`,
    method: 'PUT',
    body,
  };
};

export const deleteMultiGroupTerminalsPermissions: MutationFn<DeleteMultiGroupTerminalsPermissionsBody, void> = ({
  id,
  body,
}) => {
  return {
    endpoint: `/batch/grupa/${id}/uprawnienie/grupowe`,
    method: 'DELETE',
    body,
  };
};

export const fetchGroupGlcPermissions: UseQueryFn<FetchGroupGlcPermissionsBody, PaginatedResponse<GroupPermission>> = (
  body,
) => {
  return {
    endpoint: `/grupa/${body?.groupId}/uprawnienie/glc/rcp/${body?.serverId}`,
  };
};

export const deleteGroupGlcPermission: MutationFn<DeleteGroupGlcPermissionsBody, void> = ({
  groupId,
  permissionId,
}) => {
  return {
    endpoint: `/grupa/${groupId}/uprawnienie/glc/${permissionId}`,
    method: 'DELETE',
  };
};

export const editGroupGlcPermission: MutationFn<EditGroupGlcPermissionBody, void> = ({
  groupId,
  permissionId,
  body,
}) => {
  return {
    endpoint: `/grupa/${groupId}/uprawnienie/glc/${permissionId}`,
    method: 'PUT',
    body,
  };
};

export const editMultiGroupGlcPermissions: MutationFn<EditMultiGroupGlcPermissionsBody, void> = ({ id, body }) => {
  return {
    endpoint: `/batch/grupa/${id}/uprawnienie/grupowe/glc`,
    method: 'PUT',
    body,
  };
};

export const deleteMultiGroupGlcPermissions: MutationFn<DeleteMultiGroupGlcPermissionsBody, void> = ({ id, body }) => {
  return {
    endpoint: `/batch/grupa/${id}/uprawnienie/grupowe/glc`,
    method: 'DELETE',
    body,
  };
};
export const addGroupGlcPermissions: MutationFn<AddGroupGlcPermissionsBody, void> = ({ id, body }) => {
  return {
    endpoint: `grupa/${id}/uprawnienie/glc`,
    method: 'POST',
    body,
  };
};
