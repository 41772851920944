import { formatISO } from 'date-fns';
import { Autocomplete } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { HistoryActionAutocomplete } from 'reusable/historyActionAutocomplete/HistoryActionAutocomplete';
import { HistoryDatepickerWithModal } from 'reusable/historyDatepickerWithModal/HistoryDatepickerWithModal';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { HistoryList } from 'ui/historyList/HistoryList';
import { HistoryActionEnum } from 'core/global.enum';
import { PermissionHistory } from 'api/groups/groups.types';
import { useGroupHistoryQuery } from '../hooks/useGroupHistoryQuery';

import { PermissionHistoryProps } from './PermissionHistory.types';

export const PermissionsHistory = ({
  isLoading,
  onClearHistoryQuery,
  groupPermissionsHistoryResponse,
  page,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  servers,
  tkdSearchQuery,
  setTkdSearchQuery,
  executorSearchQuery,
  setExecutorSearchQuery,
  glcSearchQuery,
  setGlcSearchQuery,
  group,
}: PermissionHistoryProps) => {
  const { formatMessage } = useLocale();
  const { setGroupHistoryQuery, query: groupHistoryQuery } = useGroupHistoryQuery(false);

  const serversOptions =
    servers?.map((server) => ({
      label: `${server.nazwa}`,
      value: server.id,
    })) || [];

  const mapHistoryPermissions = (action: HistoryActionEnum, permissionHistory?: PermissionHistory[]) =>
    permissionHistory?.map((permission) => ({
      ...permission,
      uprawnienia: action === HistoryActionEnum.USUNIETO ? undefined : permission.uprawnienia,
    })) || [];

  return (
    <HistoryList
      data={groupPermissionsHistoryResponse?.data || []}
      isLoading={isLoading}
      filters={{
        gridTemplateColumns: '1fr 1fr 1fr',
        isClearButtonVisible: Object.values(groupHistoryQuery).some((query) => query),
        onClearFilters: () => onClearHistoryQuery(),
        render: () => (
          <>
            <HistoryDatepickerWithModal
              dates={[
                groupHistoryQuery.dataOd ? new Date(groupHistoryQuery.dataOd) : null,
                groupHistoryQuery.dataDo ? new Date(groupHistoryQuery.dataDo) : null,
              ]}
              setDates={(dates) =>
                setGroupHistoryQuery({
                  dataOd: dates[0] ? formatISO(dates[0]) : undefined,
                  dataDo: dates[1] ? formatISO(dates[1]) : undefined,
                })
              }
            />

            <HistoryActionAutocomplete
              selectedAction={groupHistoryQuery.akcja}
              onChangeAction={(action) => setGroupHistoryQuery({ akcja: action })}
              historyType="groupPermissions"
            />

            <Autocomplete
              onChange={(_, option) =>
                setGroupHistoryQuery({ serwer: option || undefined, tkd: undefined, glc: undefined })
              }
              value={serversOptions.find((server) => groupHistoryQuery.serwer?.value === server.value) || null}
              options={serversOptions}
              loadingText={formatMessage({ id: AppMessages['common.loading'] })}
              noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
              placeholder={formatMessage({ id: AppMessages['group.history.search.server'] })}
              inputVariant="outlined"
              inputSize="big"
              isOptionEqualToValue={(option, value) => option.value === value.value}
              stopRotatePopupIndicator
            />

            {group?.glc ? (
              <TableSearchContainer
                withoutToggle
                inputPlaceholder={formatMessage({ id: AppMessages['group.history.search.glc'] })}
                searchBy="glc"
                tableSearchQuery={glcSearchQuery}
                setTableSearchQuery={setGlcSearchQuery}
                selectedValue={groupHistoryQuery.glc}
                setSelectedValue={(option) => setGroupHistoryQuery({ glc: option || undefined })}
                inputSize="big"
                isDisabled={!groupHistoryQuery.serwer}
                serverId={groupHistoryQuery.serwer?.value as string}
              />
            ) : (
              <TableSearchContainer
                withoutToggle
                inputPlaceholder={formatMessage({ id: AppMessages['group.history.search.reader'] })}
                searchBy="terminals"
                tableSearchQuery={tkdSearchQuery}
                setTableSearchQuery={setTkdSearchQuery}
                selectedValue={groupHistoryQuery.tkd}
                setSelectedValue={(option) => setGroupHistoryQuery({ tkd: option || undefined })}
                inputSize="big"
                isDisabled={!groupHistoryQuery.serwer}
                serverId={groupHistoryQuery.serwer?.value as string}
              />
            )}

            <TableSearchContainer
              withoutToggle
              inputPlaceholder={formatMessage({ id: AppMessages['group.history.search.executor'] })}
              searchBy="users"
              tableSearchQuery={executorSearchQuery}
              setTableSearchQuery={setExecutorSearchQuery}
              selectedValue={groupHistoryQuery.wykonawca}
              setSelectedValue={(option) => setGroupHistoryQuery({ wykonawca: option || undefined })}
              inputSize="big"
            />
          </>
        ),
      }}
      pagination={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        count: groupPermissionsHistoryResponse?.count || 0,
      }}
      renderHistoryItemBody={(history) => (
        <>
          <HistoryList.ActionBodyItem
            title={formatMessage({ id: AppMessages['group.history.permissions.descriptionTitle.server'] })}
            value={`${
              history.tkds?.length ? history.tkds[0]?.server.nazwa : history.glcs && history.glcs[0]?.server.nazwa
            }`}
          />
          {history.tkds && (
            <HistoryList.ActionExpandablePermissionsBodyItem
              title={formatMessage({ id: AppMessages['group.history.permissions.descriptionTitle.reader'] })}
              data={mapHistoryPermissions(history.akcja, history.tkds)}
              shouldScrollToTop={!!groupHistoryQuery.tkd && !isLoading}
            />
          )}
          {history.glcs && (
            <HistoryList.ActionExpandablePermissionsBodyItem
              title={formatMessage({ id: AppMessages['group.history.permissions.descriptionTitle.glc'] })}
              data={mapHistoryPermissions(history.akcja, history.glcs)}
              shouldScrollToTop={!!groupHistoryQuery.glc && !isLoading}
            />
          )}
          {history.powod && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['group.history.permissions.descriptionTitle.reason'] })}
              value={history.powod}
            />
          )}
          {history.wykonawca && !!Object.entries(history.wykonawca).length && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['group.history.descriptionTitle.executor'] })}
              value={`${history.wykonawca.imie} ${history.wykonawca.nazwisko}`}
            />
          )}
        </>
      )}
    />
  );
};
