import { useState } from 'react';
import { Checkbox } from 'shared-ui';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import * as Styled from '../TreeMultiSelect.styles';

import { GroupedTreeMultiSelectBodyProps } from './GroupedTreeMultiSelectBody.types';
import { MultiTreeSelectGroupTypeEnum } from './GroupedTreeMultiSelectBody.enum';

export const GroupedTreeMultiSelectBody = ({
  renderOrganizationsGroupBody,
  extraGroupProps,
  onSelectItem,
  isOptionSelected,
  clearSelectedItems,
}: GroupedTreeMultiSelectBodyProps) => {
  const { formatMessage } = useLocale();

  const [selectedGroup, setSelectedGroup] = useState(MultiTreeSelectGroupTypeEnum.EXTRA);

  const handleGroupSelect = (groupType: MultiTreeSelectGroupTypeEnum) => {
    setSelectedGroup(groupType);
    clearSelectedItems();
    extraGroupProps.onGroupSelect && extraGroupProps.onGroupSelect(groupType);
  };

  return (
    <>
      <Styled.PopupHeaderWrapper>
        <Styled.HeaderOption
          onClick={() => handleGroupSelect(MultiTreeSelectGroupTypeEnum.EXTRA)}
          $isActive={selectedGroup === MultiTreeSelectGroupTypeEnum.EXTRA}
        >
          {extraGroupProps.label}
        </Styled.HeaderOption>
        <Styled.HeaderOption
          onClick={() => handleGroupSelect(MultiTreeSelectGroupTypeEnum.ORGANIZATIONS)}
          $isActive={selectedGroup === MultiTreeSelectGroupTypeEnum.ORGANIZATIONS}
        >
          {formatMessage({ id: AppMessages['groupedTreeMultiSelect.cell'] })}
        </Styled.HeaderOption>
      </Styled.PopupHeaderWrapper>

      {selectedGroup === MultiTreeSelectGroupTypeEnum.ORGANIZATIONS
        ? renderOrganizationsGroupBody()
        : extraGroupProps.options.map((option) => (
            <Styled.ListItemWrapper onClick={() => onSelectItem(option)} key={option.id}>
              <Styled.ListItem $isFirstLevel={true}>
                <Styled.ExtraGroupOption>
                  <Checkbox checked={isOptionSelected(option.id)} color="primary" />

                  {extraGroupProps.renderOption(option)}
                </Styled.ExtraGroupOption>
              </Styled.ListItem>
            </Styled.ListItemWrapper>
          ))}
    </>
  );
};
