import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Modal, Button } from 'shared-ui';

import { AddServerFormBody } from 'api/servers/servers.types';
import { MAX_AMOUNT_OF_SERVERS } from 'core/global.variables';
import { Input } from 'ui/input/Input';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AddressIpMaskedInput } from 'ui/addressIpMaskedInput/AddressIpMaskedInput';

import { AddServerProps } from './AddServer.types';
import * as Styled from './AddServer.styles';

export const AddServer = ({ onSubmit, isLoading, onModalClose, onModalOpen, isModalOpen, servers }: AddServerProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    trigger,
    watch,
    control,
  } = useFormContext<AddServerFormBody>();

  const port = watch('port');
  const host = watch('host');

  useEffect(() => {
    port && trigger('port');
  }, [host]);

  return (
    <>
      <Styled.ButtonWrapper>
        <Button
          variant="contained"
          color="primary"
          onClick={onModalOpen}
          disabled={servers && servers?.length >= MAX_AMOUNT_OF_SERVERS}
        >
          {formatMessage({ id: AppMessages['devices.addServerButton'] })}
        </Button>
      </Styled.ButtonWrapper>

      <Modal
        header={formatMessage({ id: AppMessages['devices.addServer.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={isLoading || !isValid}
        submitButtonText={formatMessage({ id: AppMessages['common.add'] })}
      >
        <>
          <Styled.FormGrid>
            <Styled.InputWrapper>
              <Input
                {...register('nazwa')}
                label={formatMessage({ id: AppMessages['devices.details.name'] })}
                placeholder={formatMessage({ id: AppMessages['devices.addServer.placeholder.name'] })}
                errorMessage={errors?.nazwa?.message}
                required
              />
            </Styled.InputWrapper>

            <Styled.InputWrapper>
              <Controller
                name="host"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <AddressIpMaskedInput
                    {...field}
                    label={formatMessage({ id: AppMessages['devices.details.addressIP'] })}
                    placeholder={formatMessage({ id: AppMessages['devices.addServer.placeholder.addressIP'] })}
                    errorMessage={errors?.host?.message}
                    required
                  />
                )}
              />
            </Styled.InputWrapper>

            <Input
              {...register('port')}
              label={formatMessage({ id: AppMessages['devices.details.port'] })}
              placeholder={formatMessage({ id: AppMessages['devices.addServer.placeholder.port'] })}
              type="number"
              errorMessage={errors?.port?.message}
              required
            />

            <Input
              {...register('timeout')}
              label={formatMessage({ id: AppMessages['devices.details.timeout'] })}
              placeholder={formatMessage({ id: AppMessages['devices.addServer.placeholder.timeout'] })}
              type="number"
              errorMessage={errors?.timeout?.message}
              required
            />
          </Styled.FormGrid>
        </>
      </Modal>
    </>
  );
};
