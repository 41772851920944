import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';
import * as Styled from '../EventsExportFormatsActions.styles';

import { DeleteEventExportFormatProps } from './DeleteEventExportFormat.types';

export const DeleteEventExportFormat = ({
  eventExportFormat,
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
}: DeleteEventExportFormatProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Styled.TrashIcon onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['definitions.events.removeExportFormat.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['common.delete'] })}
      >
        <ActionModal.Subtitle>{eventExportFormat.nazwa}</ActionModal.Subtitle>

        <ActionModal.Text>
          {formatMessage({ id: AppMessages['definitions.events.removeExportFormat.text'] })}
        </ActionModal.Text>
      </ActionModal>
    </>
  );
};
