import { ListItem as MaterialListItem } from '@mui/material';
import styled, { css } from 'styled-components';

export const HeaderItem = styled(MaterialListItem)(
  ({ theme: { palette } }) => css`
    text-transform: uppercase;
    color: ${palette.grey[900]};
    border: 1px solid transparent;
    padding: 0 16px;
    font-size: 12px;
  `,
);

export const HeaderItemSortable = styled.div<{ $isActive?: boolean }>(
  ({ $isActive, theme: { palette, shape } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    padding: 0 10px;
    margin-left: -10px;
    border: 1px solid ${$isActive ? palette.grey[500] : 'transparent'};
    border-radius: ${shape.borderRadius}px;
    cursor: pointer;
  `,
);
