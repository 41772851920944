import { CardThumbnail } from 'ui/cardThumbnail/CardThumbnail';
import { CardBlockTypeEnum } from 'api/cards/cards.enum';

import { CardDetailsProps } from './CardDetails.types';
import { CardDetailsEditContainer } from './cardDetailsEdit/CardDetailsEditContainer';
import { CardActions } from './cardActions/CardActions';

export const CardDetails = ({ selectedCard, setSelectedCard }: CardDetailsProps) => (
  <>
    <CardThumbnail
      status={selectedCard.status}
      cardType={selectedCard.typKarty}
      blockTime={selectedCard.czasBlokady}
      blocked={selectedCard.blokada !== CardBlockTypeEnum.ODBLOKOWANA}
      cardNumber={selectedCard.numerKarty}
      createdAt={new Date(selectedCard.createdAt)}
      isTkdProblem={selectedCard.problemTkd}
      archived={selectedCard.archiwalna}
      issuedTime={selectedCard.czasWydania}
      archiveTime={selectedCard.czasArchiwizacji}
    />

    <CardActions selectedCard={selectedCard} setSelectedCard={setSelectedCard} />

    <CardDetailsEditContainer
      selectedCard={{ ...selectedCard, nazwa: selectedCard.nazwa || '', opis: selectedCard.opis || '' }}
    />
  </>
);
