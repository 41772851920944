import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { debounce } from 'debounce';
import { Modal, Autocomplete } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { CardStatusEnum } from 'api/cards/cards.enum';
import { AddCardContainer } from 'reusable/addCard/AddCardContainer';
import { CardThumbnail } from 'ui/cardThumbnail/CardThumbnail';
import { ExpiryDateFields } from 'reusable/form/expiryDateFields/ExpiryDateFields';
import { PinCodesFields } from 'reusable/form/pinFields/PinCodesFields';

import { CardAssignProps, CardAssignForm } from './CardAssign.types';
import * as Styled from './CardAssign.styles';

export const CardAssign = ({
  onOpenAssignModal,
  onCloseAssignModal,
  isAssignModalOpen,
  handleFormSubmit,
  setQuery,
  isLoadingCards,
  cards,
  isLoadingAssignCard,
  selectedCard,
  isCardBlockedManually,
  isEditDisabled,
  isCardBlocked,
  isSelectedCardAvailable,
}: CardAssignProps) => {
  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);
  const { formatMessage } = useLocale();
  const isSelectedCardOut =
    selectedCard && [CardStatusEnum.WYDANA, CardStatusEnum.WYDANA_Z].includes(selectedCard.status);
  const defaultCardOption = { label: '', value: '' };

  const {
    handleSubmit,
    control,
    formState: { isValid },
    setValue,
  } = useFormContext<CardAssignForm>();

  const handleCardsSearch = debounce((search: string) => {
    setQuery(search);
  }, 300);

  const cardsOptions = useMemo(
    () =>
      cards
        ? [
            defaultCardOption,
            ...cards.map((card) => ({
              label: `${card.numerKarty}`,
              value: card.id,
            })),
          ]
        : [defaultCardOption],
    [cards, selectedCard?.id],
  );

  const getCurrentCardOption = (cardId?: string) => {
    return cardsOptions.find(({ value }) => cardId === value);
  };

  return (
    <>
      <Styled.AssignButton
        disabled={isEditDisabled}
        onClick={onOpenAssignModal}
        variant="outlined"
        color="primary"
        size="small"
      >
        {formatMessage({ id: AppMessages['owner.details.cards.button.assign'] })}
      </Styled.AssignButton>

      <Modal
        header={formatMessage({ id: AppMessages['owner.details.cards.button.assign'] })}
        onClose={onCloseAssignModal}
        open={isAssignModalOpen}
        size="medium"
        type="form"
        onSubmit={handleSubmit(handleFormSubmit)}
        submitButtonDisabled={!isValid || isLoadingAssignCard || !isSelectedCardAvailable}
        submitButtonText={formatMessage({ id: AppMessages['owner.details.assign.card.assign'] })}
        cancelButtonText={formatMessage({ id: AppMessages['owner.details.assign.card.cancel'] })}
      >
        <Styled.Grid>
          <div>
            <Controller
              name="cardId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disableClearable={!!!selectedCard?.id}
                  onChange={(_, option) => (option ? field.onChange(option.value) : field.onChange(null))}
                  value={selectedCard ? getCurrentCardOption(selectedCard?.id) : null}
                  options={cardsOptions}
                  renderOption={(props, option) =>
                    option.value ? (
                      <li {...props} key={option.value}>
                        <Styled.OptionLabel> {option.label}</Styled.OptionLabel>
                      </li>
                    ) : null
                  }
                  loading={isLoadingCards}
                  loadingText={formatMessage({ id: AppMessages['common.loading'] })}
                  label={formatMessage({ id: AppMessages['owner.details.assign.card.search'] })}
                  noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
                  onInputChange={(e, value) => {
                    e && e.type !== 'blur' && handleCardsSearch(value);
                  }}
                  placeholder={formatMessage({ id: AppMessages['owner.details.assign.card.search.placeholder'] })}
                />
              )}
            />

            <Styled.CardStatus $isVisible={!!selectedCard?.id}>
              {formatMessage({
                id: AppMessages[
                  isSelectedCardAvailable
                    ? 'owner.details.assign.card.status.available'
                    : isSelectedCardOut
                    ? 'owner.details.assign.card.status.out'
                    : 'owner.details.assign.card.status.archived'
                ],
              })}
            </Styled.CardStatus>
            <Styled.Text>{formatMessage({ id: AppMessages['owner.details.assign.card.or'] })}</Styled.Text>
            <Styled.AddCardButton
              onClick={() => setIsAddCardModalOpen(true)}
              variant="contained"
              color="primary"
              size="small"
            >
              {formatMessage({ id: AppMessages['owner.details.assign.card.add'] })}
            </Styled.AddCardButton>
          </div>
          {selectedCard && (
            <>
              <div>
                <CardThumbnail
                  cardType={selectedCard.typKarty}
                  blockTime={selectedCard.czasBlokady}
                  createdAt={selectedCard.createdAt}
                  cardNumber={selectedCard.numerKarty}
                  blocked={isCardBlockedManually}
                  isTkdProblem={selectedCard.problemTkd}
                  archived={selectedCard.archiwalna}
                  status={selectedCard.status}
                  issuedTime={selectedCard.czasWydania}
                  archiveTime={selectedCard.czasArchiwizacji}
                />
              </div>
              <div>
                <ExpiryDateFields isBlocked={!isSelectedCardAvailable || isCardBlocked} />
                {isCardBlockedManually && (
                  <Styled.BlockInfo>
                    {formatMessage({
                      id: AppMessages['card.assign.block.info'],
                    })}
                  </Styled.BlockInfo>
                )}
              </div>
              <PinCodesFields isDisabled={!isSelectedCardAvailable || isCardBlocked} />
            </>
          )}
        </Styled.Grid>
      </Modal>
      <AddCardContainer
        isSingleCardAdd
        isModalOpen={isAddCardModalOpen}
        setIsModalOpen={setIsAddCardModalOpen}
        onAddSingleCardSuccess={(card) => {
          setQuery(card.numerKarty);
          setValue('cardId', card.id, { shouldValidate: true });
        }}
      />
    </>
  );
};
