export const getObjectInNestedObjectByKey = <Type, Key extends keyof Type>(
  obj: Type,
  keyToFind: Key,
  valueToFind: Type[Key],
  level = 1,
  parentIds = [] as string[],
): { object: Type; level: number; parentIds: string[] } | null => {
  const foundedData = obj
    ? Object.entries(obj).reduce((acc, [key, value], _, arr) => {
        if (acc) return acc;

        const idRow = arr.find(([key]) => key === 'id') as [string, string];
        const [, elementId] = idRow;

        if (key === keyToFind && value === valueToFind) {
          return { object: obj, level, parentIds };
        }

        if (Array.isArray(value)) {
          const mapped = value.map((val) =>
            getObjectInNestedObjectByKey(val, keyToFind, valueToFind, level + 1, [...parentIds, elementId]),
          );

          return mapped.filter(Boolean)[0] || null;
        }

        if (typeof value === 'object' && value !== null) {
          return (
            getObjectInNestedObjectByKey(value, keyToFind as never, valueToFind as never, level + 1, [
              ...parentIds,
              elementId,
            ]) || null
          );
        }

        return null;
      }, null as { object: Type; level: number; parentIds: string[] } | null)
    : null;

  return foundedData || null;
};
