import { Typography } from '@mui/material';
import styled from 'styled-components';
import { Button } from 'shared-ui';

export const ModalSubtitle = styled(Typography)`
  margin-bottom: 24px;
`;

export const DatepickerWrapper = styled.div`
  width: 50%;
`;

export const ReturnButton = styled(Button)`
  min-width: 120px;
  width: 120px;
`;
