import { GroupTypeDescriptionEnum } from 'app/groups/Groups.enum';
import { GroupTypeEnum } from 'api/groups/groups.enum';
import {
  OwnerGroupIdSortEnum,
  OwnerGroupNameSortEnum,
  OwnerGroupsQueryKeysEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useOwnerQueryKeys } from 'hooks/useOwnerQueryKeys/useOwnerQueryKeys';
import { Table } from 'ui/table/Table';
import {
  YesNoFilterItemsDescriptionEnum,
  useYesNoFilterItems,
} from 'hooks/useYesNoHeaderFilterItems/useYesNoFilterItems';

export const OwnerGroupsTableHeader = () => {
  const { formatMessage } = useLocale();
  const { ownerGroupsQueryKey } = useOwnerQueryKeys();
  const { yesNoFilterItems } = useYesNoFilterItems();

  return (
    <>
      <Table.HeaderSortItem
        queryKey={ownerGroupsQueryKey}
        sortBy={OwnerGroupIdSortEnum}
        queryHeader={formatMessage({ id: AppMessages['groups.owner.table.header.id'] })}
      />

      <Table.HeaderSortItem
        queryKey={ownerGroupsQueryKey}
        sortBy={OwnerGroupNameSortEnum}
        queryHeader={formatMessage({ id: AppMessages['groups.owner.table.header.groupName'] })}
      />

      <Table.HeaderFilterItem
        queryKey={ownerGroupsQueryKey}
        queryName={OwnerGroupsQueryKeysEnum.RODZAJ_GRUPY}
        filterItems={Object.values(GroupTypeEnum).map((groupType) => ({
          name: formatMessage({ id: GroupTypeDescriptionEnum[groupType] }),
          filterBy: groupType,
        }))}
        queryHeader={formatMessage({ id: AppMessages['groups.owner.table.header.groupType'] })}
        queryHeaderDescriptions={GroupTypeDescriptionEnum}
      />

      <Table.HeaderFilterItem
        queryKey={ownerGroupsQueryKey}
        queryName={OwnerGroupsQueryKeysEnum.GLC}
        filterItems={yesNoFilterItems}
        queryHeader={formatMessage({ id: AppMessages['groups.owner.table.header.glc'] })}
        queryHeaderDescriptions={YesNoFilterItemsDescriptionEnum}
      />
    </>
  );
};
