import { Table } from 'ui/table/Table';

import { GlcTermianalsTableRowProps } from './GlcTerminalsTableRow.types';

export const GlcTerminalsTableRow = ({ terminal }: GlcTermianalsTableRowProps) => {
  return (
    <>
      <Table.RowItem>{terminal.numerLogicznyKontrolera}</Table.RowItem>

      <Table.RowItem>{terminal.nazwa}</Table.RowItem>
    </>
  );
};
