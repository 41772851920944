import { PageLayout, TopBar, DetailsDrawer } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { HistoryList } from 'ui/historyList/HistoryList';
import { GroupDetailsContainer } from '../groupDetails/GroupDetailsContainer';
import { AppRouteWithParams } from 'routing/AppRoute.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';

import { BaseHistoryContainer } from './baseHistory/BaseHistoryContainer';
import { PermissionsHistoryContainer } from './permissionsHistory/PermissionsHistoryContainer';
import { GroupHistoryProps } from './GroupHistory.types';

export const GroupHistory = ({ group, groupId, isLoadingGroupDetails }: GroupHistoryProps) => {
  const { formatMessage } = useLocale();
  const isBaseView = useIsExactPath(AppRouteWithParams.groupsHistory.path);

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['group.history.title'] })}
      withBackButton={{
        onClick: (navigate) => {
          navigate(-1);
        },
      }}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="auto auto 1fr ">
            <TopBar.Link replace linkTo={AppRouteWithParams.groupsHistory.get({ id: groupId })} isActive={isBaseView}>
              {formatMessage({ id: AppMessages['group.history.base'] })}
            </TopBar.Link>

            <TopBar.Link
              replace
              linkTo={AppRouteWithParams.groupsPermissionsHistory.get({ id: groupId })}
              isActive={!isBaseView}
            >
              {formatMessage({ id: AppMessages['group.history.permissions'] })}
            </TopBar.Link>
            <TopBar.Header />
          </TopBar>
          {isBaseView ? <BaseHistoryContainer group={group} /> : <PermissionsHistoryContainer group={group} />}
        </>
      )}
      renderDetails={() =>
        !isLoadingGroupDetails && group ? (
          <DetailsDrawer isOpen header={formatMessage({ id: AppMessages['common.details'] })}>
            <GroupDetailsContainer selectedGroup={group} />
          </DetailsDrawer>
        ) : (
          <HistoryList.DetailsLoader />
        )
      }
    />
  );
};
