import styled, { css } from 'styled-components';

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 50px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 8px;
`;

export const ButtonWrapper = styled.div(
  ({ theme: { palette } }) => css`
    display: flex;
    justify-content: flex-end;
    border-bottom: 3px solid ${palette.grey[300]};
    padding-bottom: 12px;
  `,
);
