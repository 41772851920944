export enum ParametersNamesDescriptionEnum {
  numerKarty = 'definitions.events.addExportFormat.parameters.cardNumber',
  data = 'definitions.events.addExportFormat.parameters.date',
  mod = 'definitions.events.addExportFormat.parameters.mod',
  tryb = 'definitions.events.addExportFormat.parameters.mode',
  stanWejscia = 'definitions.events.addExportFormat.parameters.entryState',
  strefa = 'definitions.events.addExportFormat.parameters.zone',
  rcp = 'definitions.events.addExportFormat.parameters.rcp',
  poszukiwany = 'definitions.events.addExportFormat.parameters.wanted',
  rewizja = 'definitions.events.addExportFormat.parameters.revision',
  czytnik = 'definitions.events.addExportFormat.parameters.reader',
}
