import { useState } from 'react';

import { AppRoute } from 'routing/AppRoute.enum';
import { Event } from 'api/events/events.types';
import { SelectedEventContext } from '../selectedEventContext/SelectedEventContext';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';

import { SelectedEventContextControllerProps } from './SelectedEventContextController.types';

export const SelectedEventContextController = ({ children }: SelectedEventContextControllerProps) => {
  const [historySelectedEvent, setHistorySelectedEvent] = useState<Event | undefined>(undefined);
  const [currentSelectedEvent, setCurrentSelectedEvent] = useState<Event | undefined>(undefined);
  const isHistoryView = useIsExactPath(AppRoute.eventsHistory);

  const setSelectedEvent = (event: Event | undefined) => {
    (isHistoryView ? setHistorySelectedEvent : setCurrentSelectedEvent)(event);
  };

  const selectedEvent = isHistoryView ? historySelectedEvent : currentSelectedEvent;

  return (
    <SelectedEventContext.Provider value={{ selectedEvent, setSelectedEvent }}>
      {children}
    </SelectedEventContext.Provider>
  );
};
