import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import {
  OwnerEffectivePermissionsHistoryQuery,
  OwnerHistoryQuery,
} from 'context/query/queryContext/QueryContext.types';

type OwnerHistoryQueryType<T extends OwnerHistoryQuery | OwnerEffectivePermissionsHistoryQuery> = {
  setOwnerHistoryQuery: (query: OwnerHistoryQuery | OwnerEffectivePermissionsHistoryQuery) => void;
  clearOwnerHistoryQuery: () => void;
  query: T;
};
export function useOwnerHistoryQuery(
  queryKey: QueryKeyLocalStorageEnum.ACTIVE_OWNER_HISTORY | QueryKeyLocalStorageEnum.ARCHIVED_OWNER_HISTORY,
): OwnerHistoryQueryType<OwnerHistoryQuery>;
export function useOwnerHistoryQuery(
  queryKey: QueryKeyLocalStorageEnum.OWNER_EFFECTIVE_PERMISSIONS_HISTORY,
): OwnerHistoryQueryType<OwnerEffectivePermissionsHistoryQuery>;
export function useOwnerHistoryQuery(queryKey = QueryKeyLocalStorageEnum.ACTIVE_OWNER_HISTORY) {
  const { storedQueries, setQuery } = useLocaleStoreQuery();

  const setOwnerHistoryQuery = (query: OwnerHistoryQuery | OwnerEffectivePermissionsHistoryQuery) => {
    setQuery(queryKey, {
      ...storedQueries[queryKey],
      ...query,
    });
  };

  const clearOwnerHistoryQuery = () => {
    setQuery(queryKey, {});
  };

  return {
    setOwnerHistoryQuery: setOwnerHistoryQuery,
    clearOwnerHistoryQuery: clearOwnerHistoryQuery,
    query: storedQueries[queryKey],
  };
}
