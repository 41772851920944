import { useState } from 'react';

import { Position } from 'api/positions/positions.types';
import { SelectedPositionContext } from '../selectedPositionContext/SelectedPositionContext';

import { SelectedPositionContextControllerProps } from './SelectedPositionContextController.types';

export const SelectedPositionContextController = ({ children }: SelectedPositionContextControllerProps) => {
  const [selectedPosition, setSelectedPosition] = useState<Position | undefined>(undefined);

  return (
    <SelectedPositionContext.Provider value={{ selectedPosition, setSelectedPosition }}>
      {children}
    </SelectedPositionContext.Provider>
  );
};
