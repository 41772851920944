import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { deleteMultiCards } from 'api/cards/cards';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteMultiCards } from './DeleteMultiCards';
import { DeleteMultiCardsContainerProps } from './DeleteMultiCards.types';

export const DeleteMultiCardsContainer = ({ checkedCards, setCheckedCards }: DeleteMultiCardsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedCard, setSelectedCard } = useSelectedCard();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteMultiCards, isLoading } = useMutation(deleteMultiCards, {
    onSuccess: () => {
      if (selectedCard && checkedCards.some(({ id }) => id === selectedCard?.id)) {
        setSelectedCard(undefined);
      }

      queryClient.invalidateQueries([QueryKeyEnum.CARDS]);
      toast.dark(formatMessage({ id: AppMessages['cards.multiAction.delete.success'] }));
      setCheckedCards([]);
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteMultiCards({ body: checkedCards.map(({ id }) => id) });
  };

  return (
    <DeleteMultiCards
      checkedCards={checkedCards}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
