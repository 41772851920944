import { PageLayout, DetailsDrawer } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';
import { useSelectedPosition } from 'hooks/useSelectedPosition/useSelectedPosition';
import { DefinitionsBar } from '../definitionsBar/DefinitionsBar';

import { PositionsProps } from './Positions.types';
import { PositionsTableHeader } from './positionsTableHeader/PositionsTableHeader';
import { PositionsTableRow } from './positionsTableRow/PositionsTableRow';
import { PositionDetailsContainer } from './positionDetails/PositionDetailsContainer';

export const Positions = ({ positionsResponse, isLoading, setPositionsTableSearchQuery }: PositionsProps) => {
  const { formatMessage } = useLocale();
  const { selectedPosition, setSelectedPosition } = useSelectedPosition();

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['definitions.title'] })}
      renderContent={() => (
        <>
          <DefinitionsBar setPositionsTableSearchQuery={setPositionsTableSearchQuery} />

          <Table
            isLoading={isLoading}
            gridTemplateColumns="1fr"
            data={positionsResponse || []}
            nothingToShowText={formatMessage({ id: AppMessages['definitions.positions.nothingToShow'] })}
            renderHeader={() => <PositionsTableHeader />}
            renderRow={(position) => <PositionsTableRow position={position} />}
            activeRowId={selectedPosition?.id}
            onClickRow={(position) => setSelectedPosition(selectedPosition?.id === position.id ? undefined : position)}
          />
        </>
      )}
      renderDetails={() => (
        <DetailsDrawer isOpen={!!selectedPosition} header={formatMessage({ id: AppMessages['event.details.title'] })}>
          {selectedPosition && (
            <PositionDetailsContainer positions={positionsResponse || []} selectedPosition={selectedPosition} />
          )}
        </DetailsDrawer>
      )}
    />
  );
};
