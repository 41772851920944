import { Controller, useFormContext } from 'react-hook-form';
import { Modal, Button, Select } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { Form } from 'ui/form/Form';

import { AddWantedTerminalsFormBody, AddWantedTerminalsProps } from './AddWantedTerminals.types';

export const AddWantedTerminals = ({
  onModalOpen,
  onModalClose,
  isLoading,
  onSubmit,
  isModalOpen,
  isLoadingTerminals,
  terminals,
  selectedServerId,
  setSelectedServerId,
  servers,
  isLoadingServers,
}: AddWantedTerminalsProps) => {
  const { formatMessage } = useLocale();
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useFormContext<AddWantedTerminalsFormBody>();

  const isTkdNotSelected = !watch('idTkds')?.length;

  const getOptions = () => {
    return terminals.map((terminal) => ({
      label: terminal.nazwa,
      value: terminal.id,
      number: terminal.numerLogicznyKontrolera,
    }));
  };

  const { serverOptions } = useAppSelectOptions({ servers });

  return (
    <>
      <Button onClick={onModalOpen} variant="contained">
        {formatMessage({ id: AppMessages['wanted.terminals.add.button'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['wanted.terminals.add.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={isTkdNotSelected || isLoading || !!Object.keys(errors).length}
      >
        <Form.Grid>
          <Select
            required
            value={selectedServerId}
            onChange={(e) => setSelectedServerId(e.target.value as string)}
            label={formatMessage({ id: AppMessages['wanted.terminals.add.server.label'] })}
            options={serverOptions}
          />

          <Controller
            name="idTkds"
            control={control}
            render={({ field }) => (
              <MultiAutocomplete
                {...field}
                required
                withLimitedTags
                stopRotatePopupIndicator
                inputSize="big"
                limitTags={1}
                onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                options={getOptions()}
                loading={isLoadingTerminals || isLoadingServers}
                loadingText={formatMessage({ id: AppMessages['common.loading'] })}
                label={formatMessage({ id: AppMessages['wanted.terminals.add.terminal.label'] })}
                noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
                placeholder={formatMessage({ id: AppMessages['wanted.terminals.add.terminal.placeholder'] })}
                renderOption={renderMultiAutocompleteOption}
              />
            )}
          />
        </Form.Grid>
      </Modal>
    </>
  );
};
