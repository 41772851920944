import { useContext } from 'react';

import { SelectedServerContext } from 'context/selectedServer/selectedServerContext/SelectedServerContext';

export const useSelectedServer = () => {
  const context = useContext(SelectedServerContext);

  if (context === undefined) {
    throw new Error('SelectedServerContext is unavailable, make sure you are using SelectedServerContextController');
  }

  return context;
};
