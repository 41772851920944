import styled from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  left: 30px;
  top: 53px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
