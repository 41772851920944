import { Input } from 'shared-ui';
import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputField = styled(Input)`
  width: 100%;
  height: 100%;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  resize: none;
  position: relative;
  vertical-align: middle;
  line-height: 2px;

  .MuiFilledInput-input.MuiInputBase-inputSizeSmall {
    line-height: 1.8 !important;
  }
`;

export const KeywordsWrapper = styled.div(
  ({ theme: { typography } }) => css`
    width: 100%;
    height: 100%;
    white-space: nowrap;
    font-family: ${typography.fontFamily};
    font-size: 14px;
    padding-left: 12px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    position: absolute;
    left: 0px;
    line-height: 1.8;
    cursor: default;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
);

export const Keyword = styled.span<{
  $width: number;
  $backgroundColor: string;
  $height?: number;
  $word?: string;
  $disabled?: boolean;
}>(({ $width, $backgroundColor, $height, $word, $disabled, theme: { palette } }) => {
  return css`
    color: black;
    display: inline-block;
    position: relative;
    z-index: 100;
    font-weight: 600;
    font-size: 12px;
    width: ${$width}px;
    height: ${$height}px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: inherit;
    cursor: pointer;

    &::before {
      content: '';
      width: calc(100% - 2px);
      height: calc(100% + 1px);
      position: absolute;
      left: 0.5px;
      top: -1px;
      background-color: ${$disabled ? '#E8E8E8' : $backgroundColor};
      z-index: -1;
      border-radius: 4px;
    }

    &::after {
      content: '${$word}';
      width: calc(100%);
      height: 100%;
      position: absolute;
      left: 1px;
      top: 50%;
      font-weight: 700;
      font-size: 14px;
      transform: translateY(-50%);
      color: ${palette.grey[100]};
      z-index: -2;
      border-radius: 10px;
    }
  `;
});

export const BottomKeywordsWrapper = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 5px;
  flex-wrap: wrap;
`;
