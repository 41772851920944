import styled, { css } from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

export const OwnerGroupDefinitionLink = styled.span(
  ({ theme: { palette } }) => css`
    display: block;
    color: ${palette.primary.main};
    font-weight: 700;
    text-align: right;
  `,
);

export const Link = styled(ReactRouterLink)`
  text-decoration: none;
`;
