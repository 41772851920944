import { Controller, useFormContext } from 'react-hook-form';
import { debounce } from 'debounce';
import { Modal, Button } from 'shared-ui';

import { ReactComponent as AddIcon } from 'assets/icons/add-active-medium.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';
import * as Styled from '../GroupsMultiActions.styles';

import { AddMultiOwnersFormBody, AddMultiOwnersProps } from './AddMultiOwners.types';

export const AddMultiCells = ({
  onSubmit,
  isLoading,
  isModalOpen,
  onModalClose,
  onModalOpen,
  owners,
  isLoadingOwners,
  setOwnersQuery,
  ownersQuery,
}: AddMultiOwnersProps) => {
  const { formatMessage } = useLocale();
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption({
    idPrefix: formatMessage({
      id: AppMessages['groups.multiAction.addOwner.field.cell.optionLabel'],
    }),
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<AddMultiOwnersFormBody>();

  const getOptions = () => {
    return owners.map((owner) => ({
      label: `${owner.imie} ${owner.nazwisko}`,
      value: owner.id,
      number: owner.nrEwidencyjny,
    }));
  };

  const handleOwnerSearch = debounce((search: string) => {
    setOwnersQuery(search);
  }, 300);

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<AddIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['groups.multiAction.addOwner.button'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['groups.multiAction.addOwner.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid || isLoading}
      >
        <Styled.Wrapper>
          <Controller
            name={'pkds'}
            control={control}
            render={({ field }) => (
              <MultiAutocomplete
                {...field}
                stopRotatePopupIndicator
                inputSize="big"
                limitTags={1}
                withLimitedTags
                onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                inputValue={ownersQuery}
                onInputChange={(e, value) => e && handleOwnerSearch(value)}
                options={getOptions()}
                loading={isLoadingOwners}
                loadingText={formatMessage({ id: AppMessages['common.loading'] })}
                label={formatMessage({ id: AppMessages['groups.multiAction.addOwner.field.cell.label'] })}
                placeholder={formatMessage({
                  id: AppMessages['common.list.placeholder'],
                })}
                noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
                renderOption={renderMultiAutocompleteOption}
              />
            )}
          />
        </Styled.Wrapper>
      </Modal>
    </>
  );
};
