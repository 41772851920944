import { useState, useLayoutEffect } from 'react';
import { Option, Autocomplete, useLocale, SearchIcon } from 'shared-ui';
import debounce from 'debounce';

import { AppMessages } from 'i18n/messages';

import { TableSearchProps } from './TableSearch.types';
import * as Styled from './TableSearch.styles';

export const TableSearch = (props: TableSearchProps) => {
  const { formatMessage } = useLocale();
  const [isOpen, setIsOpen] = useState(!!props.selectedValue?.value);

  useLayoutEffect(() => {
    !props.tableSearchQuery && props.setSelectedValue(null);
  }, [props.tableSearchQuery]);

  const handleOwnerSearch = debounce((search: string) => {
    props.setOptionsQuery(search);
  }, 300);

  const handleSelectOption = (option: Option | null) => {
    props.setTableSearchQuery(`${option?.value || ''}`);
    props.setSelectedValue(option);
  };

  return (
    <Styled.TableSearchWrapper $withoutToggle={!!props.withoutToggle}>
      {isOpen || !!props.withoutToggle ? (
        <Autocomplete
          resetNoOptionsWrapper
          filterOptions={
            props.filterOptions ||
            ((options, { inputValue }) => {
              const standarizedInputValue = inputValue.toLowerCase();

              return options.filter(
                ({ label, value, ...rest }) =>
                  label.toLowerCase().includes(standarizedInputValue) ||
                  `${value}`.toLowerCase().includes(standarizedInputValue) ||
                  `${rest?.number}`.toLowerCase().includes(standarizedInputValue),
              );
            })
          }
          inputVariant="outlined"
          inputSize={props.inputSize || 'small'}
          stopRotatePopupIndicator
          onChange={(_, option) => handleSelectOption(option)}
          isOptionEqualToValue={
            props.isOptionEqualToValue ? props.isOptionEqualToValue : (option, value) => option.value === value.value
          }
          value={props.selectedValue}
          options={props.createOptions()}
          renderOption={(optionProps, option) => props.createOptionLabel(optionProps, option)}
          loading={props.isLoading}
          loadingText={formatMessage({ id: AppMessages['common.loading'] })}
          noOptionsText={
            props.createPopupHeader ? (
              props.createPopupHeader(true)
            ) : (
              <Styled.NotFoundLabel>{formatMessage({ id: AppMessages['common.notFound'] })}</Styled.NotFoundLabel>
            )
          }
          onInputChange={(_, value) => handleOwnerSearch(value)}
          required
          placeholder={props.inputPlaceholder || formatMessage({ id: AppMessages['tableSearch.placeholder'] })}
          popupIcon={props.withoutToggle ? <SearchIcon /> : <SearchIcon onClick={() => setIsOpen(false)} />}
          renderGroup={'createGroup' in props ? props.createGroup : undefined}
          groupBy={(option) => (option.groupBy ? option.groupBy : '')}
          disabled={props.isDisabled}
        />
      ) : (
        <Styled.IconWrapper>
          <Styled.SearchButton data-testid={props.searchIconTestId} size="small" onClick={() => setIsOpen(true)}>
            <SearchIcon />
          </Styled.SearchButton>
        </Styled.IconWrapper>
      )}
    </Styled.TableSearchWrapper>
  );
};
