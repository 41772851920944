import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useAddEventExportFormatValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    nazwa: yup
      .string()
      .max(60, formatMessage({ id: AppMessages['definitions.events.addExportFormat.settings.formatName.max'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
  });

  return { schema };
};
