import { formatISO } from 'date-fns';
import { Autocomplete } from 'shared-ui';

import { HistoryDatepickerWithModal } from 'reusable/historyDatepickerWithModal/HistoryDatepickerWithModal';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { HistoryActionAutocomplete } from 'reusable/historyActionAutocomplete/HistoryActionAutocomplete';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { WantedHistoryQuery } from 'context/query/queryContext/QueryContext.types';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';

import { WantedHistoryFiltersProps } from './WantedHistoryFilters.types';
import * as Styled from './WantedHistoryFilters.styles';

export const WantedHistoryFilters = ({
  servers,
  executorSearchQuery,
  setExecutorSearchQuery,
  tkdSearchQuery,
  setTkdSearchQuery,
  ownerSearchQuery,
  setOwnerSearchQuery,
}: WantedHistoryFiltersProps) => {
  const { formatMessage } = useLocale();

  const {
    storedQueries: { wantedHistory: wantedHistoryQuery },
    setQuery,
  } = useLocaleStoreQuery();

  const setWantedHistoryQuery = (query: WantedHistoryQuery) => {
    setQuery(QueryKeyLocalStorageEnum.WANTED_HISTORY, { ...wantedHistoryQuery, ...query });
  };

  const serverOptions =
    servers?.map((server) => ({
      label: `${server.nazwa}`,
      value: server.id,
    })) || [];

  const yesNoOptions = [
    {
      label: formatMessage({ id: AppMessages['common.yes'] }),
      value: 'true',
    },
    {
      label: formatMessage({ id: AppMessages['common.no'] }),
      value: 'false',
    },
  ];

  return (
    <>
      <HistoryDatepickerWithModal
        dates={[
          wantedHistoryQuery.dataOd ? new Date(wantedHistoryQuery.dataOd) : null,
          wantedHistoryQuery.dataDo ? new Date(wantedHistoryQuery.dataDo) : null,
        ]}
        setDates={(dates) =>
          setWantedHistoryQuery({
            dataOd: dates[0] ? formatISO(dates[0]) : undefined,
            dataDo: dates[1] ? formatISO(dates[1]) : undefined,
          })
        }
      />

      <HistoryActionAutocomplete
        selectedAction={wantedHistoryQuery.akcja}
        onChangeAction={(action) => setWantedHistoryQuery({ akcja: action })}
        historyType="wanted"
      />

      <Autocomplete
        onChange={(_, option) => setWantedHistoryQuery({ serwer: option || undefined, tkd: undefined })}
        value={serverOptions.find((server) => wantedHistoryQuery.serwer?.value === server.value) || null}
        options={serverOptions}
        loadingText={formatMessage({ id: AppMessages['common.loading'] })}
        noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
        placeholder={formatMessage({ id: AppMessages['wanted.history.filters.server'] })}
        inputVariant="outlined"
        inputSize="big"
        isOptionEqualToValue={(option, value) => option.value === value.value}
        stopRotatePopupIndicator
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            <Styled.AutocompleteOption>{option.label}</Styled.AutocompleteOption>
          </li>
        )}
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['wanted.history.filters.reader'] })}
        searchBy="terminals"
        tableSearchQuery={tkdSearchQuery}
        setTableSearchQuery={setTkdSearchQuery}
        selectedValue={wantedHistoryQuery.tkd}
        setSelectedValue={(option) => setWantedHistoryQuery({ tkd: option || undefined })}
        inputSize="big"
        isDisabled={!wantedHistoryQuery.serwer}
        serverId={wantedHistoryQuery.serwer?.value as string}
      />

      <Autocomplete
        value={yesNoOptions.find((option) => wantedHistoryQuery.informacja === option.value) || null}
        onChange={(_, option) => setWantedHistoryQuery({ informacja: (option?.value as string) || undefined })}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        options={yesNoOptions}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            <Styled.AutocompleteOption>{option.label}</Styled.AutocompleteOption>
          </li>
        )}
        placeholder={formatMessage({ id: AppMessages['wanted.history.filters.information'] })}
        inputVariant="outlined"
        inputSize="big"
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['wanted.history.filters.executor'] })}
        searchBy="users"
        tableSearchQuery={executorSearchQuery}
        setTableSearchQuery={setExecutorSearchQuery}
        selectedValue={wantedHistoryQuery.query}
        setSelectedValue={(option) => setWantedHistoryQuery({ query: option || undefined })}
        inputSize="big"
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['wanted.history.filters.owner'] })}
        searchBy="owners"
        tableSearchQuery={ownerSearchQuery}
        setTableSearchQuery={setOwnerSearchQuery}
        selectedValue={wantedHistoryQuery.posiadacz}
        setSelectedValue={(option) =>
          setWantedHistoryQuery({
            posiadacz: option ? { ...option, value: option.id as string } : undefined,
          })
        }
        inputSize="big"
      />
    </>
  );
};
