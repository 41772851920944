import { Button } from 'shared-ui';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useSelectedGlc } from 'hooks/useSelectedGlc/useSelectedGlc';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { DeleteMultiGlcTerminalsProps } from './DeleteMultiGlcTerminals.types';

export const DeleteMultiGlcTerminals = ({
  checkedGlcTerminals,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
}: DeleteMultiGlcTerminalsProps) => {
  const { formatMessage } = useLocale();
  const { selectedGlc } = useSelectedGlc();

  const translations = {
    single: (subtitleText?: string | number) => ({
      title: formatMessage({ id: AppMessages['glc.terminals.delete.title'] }),
      subtitle: `${formatMessage({ id: AppMessages['glc.terminals.delete.subtitle'] })} ${subtitleText}`,
      text: formatMessage({ id: AppMessages['glc.terminals.delete.subtitle.text'] }),
    }),
    multi: (subtitleText?: string | number) => ({
      title: formatMessage({ id: AppMessages['glc.terminals.delete.title.multi'] }),
      subtitle: `${formatMessage({ id: AppMessages['glc.terminals.delete.subtitle.multi'] })} ${subtitleText}`,
      text: formatMessage({ id: AppMessages['glc.terminals.delete.subtitle.text.multi'] }),
    }),
  };

  const content =
    checkedGlcTerminals.length > 1
      ? translations.multi(checkedGlcTerminals.length)
      : translations.single(selectedGlc?.nazwa);

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<TrashIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['cards.multiAction.delete.button'] })}
      </Button>

      <ActionModal
        header={content.title}
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['common.delete'] })}
      >
        <ActionModal.Subtitle>{content.subtitle}</ActionModal.Subtitle>

        <ActionModal.Text>{content.text}</ActionModal.Text>

        <ActionModal.Text>{formatMessage({ id: AppMessages['common.irreversibleAction'] })}</ActionModal.Text>
      </ActionModal>
    </>
  );
};
