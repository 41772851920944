import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useUserBasicDetailsValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    username: yup
      .string()
      .min(3, formatMessage({ id: AppMessages['users.addUser.validation.field.name'] }))
      .max(30, formatMessage({ id: AppMessages['user.details.basic.username.maxLenghtValidation'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
  });

  return { schema };
};
