import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { deleteRandomControlTerminals } from 'api/control/control';

import { DeleteRandomControlTerminals } from './DeleteRandomControlTerminals';
import { DeleteRandomControlTerminalsContainerProps } from './DeleteRandomControlTerminals.types';

export const DeleteRandomControlTerminalsContainer = ({
  controlId,
  checkedTerminals,
  setCheckedTerminals,
  terminalsCount,
}: DeleteRandomControlTerminalsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const isMultiDelete = checkedTerminals.length > 1;

  const { mutate: onDeleteRandomControlTerminals, isLoading } = useMutation(deleteRandomControlTerminals, {
    onSuccess: (_, { body }) => {
      body.length > 1
        ? toast.dark(formatMessage({ id: AppMessages['control.random.multiAction.terminals.delete.multi.success'] }))
        : toast.dark(formatMessage({ id: AppMessages['control.random.multiAction.terminals.delete.single.success'] }));

      queryClient.invalidateQueries([QueryKeyEnum.RANDOM_CONTROL_TERMINALS, controlId]);
      queryClient.invalidateQueries([QueryKeyEnum.TERMINALS]);

      setCheckedTerminals([]);
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteRandomControlTerminals({
      id: controlId,
      body: checkedTerminals.map(({ id }) => id),
    });
  };

  return (
    <DeleteRandomControlTerminals
      checkedTerminals={checkedTerminals}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
      isMultiDelete={isMultiDelete}
      terminalsCount={terminalsCount}
    />
  );
};
