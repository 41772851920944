import { Table } from 'ui/table/Table';

import { AnnouncementAssignedOwnersTableRowProps } from './AnnouncementAssignedOwnersTableRow.types';

export const AnnouncementAssignedOwnersTableRow = ({ owner }: AnnouncementAssignedOwnersTableRowProps) => {
  return (
    <>
      <Table.RowItem>{owner.skdId}</Table.RowItem>

      <Table.RowItem>{`${owner.nazwisko} ${owner.imie}`}</Table.RowItem>

      <Table.RowCellsItem cells={owner.komorka} />

      <Table.RowItem text={owner?.stanowisko?.nazwa} />

      <Table.RowItem>{owner.nrEwidencyjny}</Table.RowItem>
    </>
  );
};
