export enum CardTypeDescriptionEnum {
  ZASTEPCZA = 'cards.type.substitute',
  ZWYKLA = 'cards.type.standard',
  ZWYKLA_WYROZNIONA = 'cards.type.standardFeatured',
  ZASTEPCZA_WYROZNIONA = 'cards.type.substituteFeatured',
}

export enum CardStatusDescriptionEnum {
  DOSTEPNA = 'card.status.available',
  WYDANA = 'card.status.released',
  WYDANA_Z = 'card.status.releasedWithReturnDate',
}

export enum CardBlockTypeDescriptionEnum {
  ODBLOKOWANA = 'card.header.unlocked.option',
  RECZNA = 'card.header.lockedManually.option',
  ZABLOKOWANA_KAD = 'card.header.blockedCardExpiry.option',
  ZABLOKOWANA_PKD = 'card.header.blockedOwnerExpiry.option',
}

export enum CardBlockTypeDescriptionTableEnum {
  ODBLOKOWANA = 'card.blockType.unlocked',
  RECZNA = 'card.blockType.lockedManually',
  ZABLOKOWANA_KAD = 'card.blockType.blockedCardExpiry',
  ZABLOKOWANA_PKD = 'card.blockType.blockedOwnerExpiry',
}
