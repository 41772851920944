import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { ReturnCardProps } from './ReturnCard.types';

export const ReturnCard = ({
  cardNumber,
  renderActionComponent,
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
  isReturnDisabled,
}: ReturnCardProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      {renderActionComponent(onModalOpen, isReturnDisabled)}

      <ActionModal
        header={formatMessage({ id: AppMessages['card.return.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={onSubmit}
        submitButtonDisabled={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['card.return.actionButton'] })}
      >
        <>
          <ActionModal.Subtitle>
            {formatMessage({ id: AppMessages['card.return.subtitle'] })} {cardNumber}
          </ActionModal.Subtitle>

          <ActionModal.Text>{formatMessage({ id: AppMessages['card.return.text'] })}</ActionModal.Text>

          <ActionModal.Text>{formatMessage({ id: AppMessages['common.reversibleAction'] })}</ActionModal.Text>
        </>
      </ActionModal>
    </>
  );
};
