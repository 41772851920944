import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePagination } from 'shared-ui';

import { fetchOwnerEffectivePermissionsHistory } from 'api/owners/owners';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import {
  OwnerEffectivePermissionsHistoryQueryKeysEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useOwnerHistoryQuery } from '../hooks/useOwnerHistoryQuery';
import { fetchServers } from 'api/servers/servers';

import { OwnerEffectivePermissionsHistory } from './OwnerEffectivePermissionsHistory';

export const OwnerEffectivePermissionsHistoryContainer = () => {
  const { id } = useParams<{ id: string }>();

  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();
  const { query: ownerHistoryQuery, clearOwnerHistoryQuery } = useOwnerHistoryQuery(
    QueryKeyLocalStorageEnum.OWNER_EFFECTIVE_PERMISSIONS_HISTORY,
  );
  const [tkdSearchQuery, setTkdSearchQuery] = useState(ownerHistoryQuery?.tkd?.label || '');
  const [executorSearchQuery, setExecutorSearchQuery] = useState(ownerHistoryQuery?.wykonawca?.label || '');

  const { data: serversResponse } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  const { data: ownerHistoryResponse, isLoading } = useQuery(
    [QueryKeyEnum.OWNER_HISTORY, id, ownerHistoryQuery, rowsPerPage, page, tkdSearchQuery, executorSearchQuery],
    () => fetchOwnerEffectivePermissionsHistory(id),
    {
      args: {
        ...ownerHistoryQuery,
        [OwnerEffectivePermissionsHistoryQueryKeysEnum.SERWER]: ownerHistoryQuery.serwer?.value,
        [OwnerEffectivePermissionsHistoryQueryKeysEnum.CZYTNIK]: ownerHistoryQuery.tkd?.value,
        [OwnerEffectivePermissionsHistoryQueryKeysEnum.WYKONAWCA]: ownerHistoryQuery.wykonawca?.value,
        [OwnerEffectivePermissionsHistoryQueryKeysEnum.LIMIT]: rowsPerPage,
        [OwnerEffectivePermissionsHistoryQueryKeysEnum.OFFSET]: rowsPerPage * page,
      },
    },
  );

  useEffect(() => {
    setPage(0);
  }, [tkdSearchQuery, executorSearchQuery, ownerHistoryQuery]);

  const onClearOwnerHistoryQuery = () => {
    clearOwnerHistoryQuery();
    setTkdSearchQuery('');
    setExecutorSearchQuery('');
  };

  return (
    <OwnerEffectivePermissionsHistory
      ownerHistoryResponse={ownerHistoryResponse}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      servers={serversResponse?.data || []}
      onClearOwnerHistoryQuery={onClearOwnerHistoryQuery}
      executorSearchQuery={executorSearchQuery}
      setExecutorSearchQuery={setExecutorSearchQuery}
      tkdSearchQuery={tkdSearchQuery}
      setTkdSearchQuery={setTkdSearchQuery}
    />
  );
};
