import { useState } from 'react';
import { PageLayout, ShowUp, TopBar, Pagination } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ExpandableList } from 'ui/expandableList/ExpandableList';
import { findServerToExpand } from 'helpers/findServerToExpand';
import { AppRoute } from 'routing/AppRoute.enum';
import { Terminal } from 'api/terminals/terminals.types';

import { RandomControlTerminalsRowsContainer } from './randomControlTerminalsRows/RandomControlTerminalsRowsContainer';
import { RandomControlTerminalsProps } from './RandomControlTerminals.types';
import { AddRandomControlTerminalsContainer } from './addRandomControlTerminals/AddRandomControlTerminalsContainer';
import { RandomControlTerminalsMultiActions } from './randomControlTerminalsMultiActions/RandomControlTerminalsMultiActions';

export const RandomControlTerminals = ({
  servers,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  terminalsCount,
  setTerminalsCount,
  controlId,
}: RandomControlTerminalsProps) => {
  const { formatMessage } = useLocale();
  const [checkedTerminals, setCheckedTerminals] = useState<Terminal[]>([]);

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['control.random.terminals.title'] })}
      withBackButton={{
        onClick: (navigate) => navigate(AppRoute.controlRandom),
      }}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="1fr">
            <TopBar.ButtonsWrapper>
              <AddRandomControlTerminalsContainer controlId={controlId || ''} />
            </TopBar.ButtonsWrapper>
          </TopBar>

          <ShowUp show={!!checkedTerminals.length}>
            <RandomControlTerminalsMultiActions
              controlId={controlId}
              checkedTerminals={checkedTerminals}
              setCheckedTerminals={setCheckedTerminals}
              terminalsCount={terminalsCount}
            />
          </ShowUp>

          <Pagination
            page={page}
            setPage={setPage}
            count={terminalsCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />

          <ExpandableList
            data={servers || []}
            isLoading={isLoading}
            nothingToShowText={formatMessage({ id: AppMessages['control.random.terminals.nothingToShow'] })}
            title={(server) => server.nazwa}
            initiallyExpandedItem={findServerToExpand(servers || [])}
            toggleItemExpansion={(expandedServer) => {
              setPage(0);
              if (!expandedServer) setTerminalsCount(0);
            }}
            renderExpandableItemBody={(server, expandedServer) => (
              <RandomControlTerminalsRowsContainer
                key={server.id}
                expandedServer={expandedServer}
                setTerminalsCount={setTerminalsCount}
                checkedTerminals={checkedTerminals}
                setCheckedTerminals={setCheckedTerminals}
              />
            )}
          />
        </>
      )}
    />
  );
};
