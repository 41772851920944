import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

import { ExpiryDateFieldsWrapperStylesProps, OwnerStatusStylesProps } from './OwnerAssign.types';

export const AssignForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :first-child {
    margin-bottom: 22px;
  }
`;

export const OptionNumber = styled(Typography)`
  font-size: 12px;
`;

export const OptionLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
`;

export const Thumbnail = styled.div`
  max-height: 200px;
`;

export const ExpiryDateFieldsWrapper = styled.div<ExpiryDateFieldsWrapperStylesProps>(
  ({ $isBlocked }) => css`
    margin-bottom: ${$isBlocked ? '0' : '26px'};
  `,
);

export const ButtonsWrapper = styled.div`
  grid-column: 1/3;
  display: flex;
  justify-content: flex-end;
  gap: 26px;
`;

export const BlockInfo = styled(Typography)(
  ({ theme: { palette } }) => css`
    font-size: 12px;
    font-weight: 700;
    color: ${palette.grey[800]};
    margin-top: 8px;
  `,
);

export const OwnerStatus = styled(Typography)<OwnerStatusStylesProps>(
  ({ theme: { palette }, $isVisible }) => css`
    font-size: 12px;
    font-weight: 700;
    padding: 8px 0px;
    color: ${palette.grey[900]};
    visibility: ${$isVisible ? 'visible' : 'hidden'};
    margin-top: -22px;
  `,
);
