import { useState, useEffect } from 'react';
import { PageLayout, TopBar, DetailsDrawer, ShowUp } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { Card as CardType } from 'api/cards/cards.types';
import { AddCardContainer } from 'reusable/addCard/AddCardContainer';
import { QueryKeyLocalStorageEnum, SearchQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useSelectedSearchOptions } from 'hooks/useSelectedSearchOptions/useSelectedSearchOptions';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { DEFAULT_QUERY_STATE } from 'context/query/queryContextController/QueryContextController.variables';
import { Table } from 'ui/table/Table';
import { OWNERS_QUERY_PREFIX } from 'reusable/tableSearch/hooks/useSearchByOwners';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';

import { CardsTableHeader } from './cardsTableHeader/CardsTableHeader';
import { CardsTableRow } from './cardsTableRow/CardsTableRow';
import { CardsProps } from './Cards.types';
import { CardDetails } from './cardDetails/CardDetails';
import { CardsMultiActions } from './cardsMultiActions/CardsMultiActions';

export const Cards = ({
  cardsResponse,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  isArchivedView,
  cardsQueryLocalStorageName,
  cardsQuery,
  redirectedCardId,
  setTableSearchQuery,
}: CardsProps) => {
  const { selectedCard, setSelectedCard, selectedCardId, lastActiveSelectedCardId, lastArchivedSelectedCardId } =
    useSelectedCard();
  const [checkedCards, setCheckedCards] = useState<CardType[]>([]);
  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);
  const { formatMessage } = useLocale();
  const { setPartialStoredQueries, storedQueries } = useLocaleStoreQuery();

  const { searchOption, setSelectedSearchOption } = useSelectedSearchOptions(
    isArchivedView ? SearchQueryKeysEnum.CARDS_ARCHIVED : SearchQueryKeysEnum.CARDS_ACTIVE,
    setTableSearchQuery,
    isArchivedView,
    (option) => (option?.number ? `${OWNERS_QUERY_PREFIX}${option?.number}` : `${option?.value || ''}`),
  );

  useEffect(() => {
    if (selectedCard && cardsResponse?.data) {
      const updatedCard = cardsResponse.data.find((card) => selectedCard.id === card.id);

      updatedCard && setSelectedCard(updatedCard);
    }
  }, [cardsResponse?.data]);

  useEffect(() => {
    resetCheckedCards();
  }, [cardsQuery]);

  const resetCheckedCards = () => {
    setCheckedCards([]);
  };

  const clearSelectedCardQueries = () => {
    const cardHistoryQueryKey = isArchivedView
      ? QueryKeyLocalStorageEnum.ARCHIVED_CARD_HISTORY
      : QueryKeyLocalStorageEnum.ACTIVE_CARD_HISTORY;
    const cardSearchQueryKey = isArchivedView
      ? SearchQueryKeysEnum.ARCHIVED_CARD_HISTORY
      : SearchQueryKeysEnum.ACTIVE_CARD_HISTORY;

    setPartialStoredQueries({
      [cardHistoryQueryKey]: DEFAULT_QUERY_STATE[cardHistoryQueryKey],
      [QueryKeyLocalStorageEnum.SELECTED_SEARCH_OPTIONS]: {
        ...storedQueries?.selectedSearchOptions,
        [cardSearchQueryKey]: undefined,
      },
    });
  };

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['cards.title'] })}
      withBackButton={{
        visible: !!redirectedCardId,
        onClick: (navigate) => navigate(AppRoute.owners),
      }}
      renderContent={() => (
        <>
          <TopBar>
            <TopBar.Link linkTo={AppRoute.cards} onClick={resetCheckedCards} isActive={!isArchivedView}>
              {formatMessage({ id: AppMessages['cards.active'] })}
            </TopBar.Link>

            <TopBar.Link linkTo={AppRoute.cardsArchived} onClick={resetCheckedCards} isActive={isArchivedView}>
              {formatMessage({ id: AppMessages['cards.archived'] })}
            </TopBar.Link>

            <TopBar.Search>
              <TableSearchContainer
                searchIconTestId="page-search"
                inputPlaceholder={formatMessage({ id: AppMessages['cards.search'] })}
                searchBy="cardsAndOwners"
                setTableSearchQuery={setTableSearchQuery}
                selectedValue={searchOption}
                setSelectedValue={setSelectedSearchOption}
              />
            </TopBar.Search>

            <AddCardContainer
              isModalOpen={isAddCardModalOpen}
              setIsModalOpen={setIsAddCardModalOpen}
              renderActionComponent={(onModalOpen) => (
                <TopBar.Button onClick={onModalOpen}>
                  {formatMessage({ id: AppMessages['cards.addCardButton'] })}
                </TopBar.Button>
              )}
            />
          </TopBar>

          <Table
            isLoading={isLoading}
            testIdKey="numerKarty"
            gridTemplateColumns="minmax(122px, 0.6fr) minmax(115px, 0.5fr) minmax(75px, 1fr) minmax(130px, 1fr) minmax(190px, 1fr) minmax(100px, 1fr)"
            data={cardsResponse?.data || []}
            nothingToShowText={formatMessage({ id: AppMessages['cards.nothingToShow'] })}
            activeRowId={selectedCardId}
            onClickRow={(card) => {
              clearSelectedCardQueries();
              setSelectedCard(card, true);
            }}
            renderMultiActions={() => (
              <ShowUp show={checkedCards.length > 1}>
                <CardsMultiActions checkedCards={checkedCards} setCheckedCards={setCheckedCards} />
              </ShowUp>
            )}
            pagination={{
              page,
              setPage,
              count: cardsResponse?.count || 0,
              rowsPerPage,
              setRowsPerPage,
            }}
            checkboxSelection={{
              checked: checkedCards,
              toggleChecked: setCheckedCards,
              customGridTemplateColumnValue: 'minmax(60px, 0.3fr)',
            }}
            renderHeader={() => <CardsTableHeader cardsQueryLocalStorageName={cardsQueryLocalStorageName} />}
            renderRow={(card) => <CardsTableRow card={card} />}
          />
        </>
      )}
      renderDetails={() => (
        <DetailsDrawer
          isOpen={
            !!selectedCardId &&
            !!selectedCard &&
            (isArchivedView ? !!lastArchivedSelectedCardId : !!lastActiveSelectedCardId)
          }
          header={formatMessage({ id: AppMessages['card.details.title'] })}
        >
          {selectedCardId && selectedCard && (
            <CardDetails key={selectedCard.id} selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
          )}
        </DetailsDrawer>
      )}
    />
  );
};
