import { formatISO } from 'date-fns';
import { Autocomplete, SearchIcon } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { HistoryDatepickerWithModal } from 'reusable/historyDatepickerWithModal/HistoryDatepickerWithModal';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { HistoryActionAutocomplete } from 'reusable/historyActionAutocomplete/HistoryActionAutocomplete';
import { HistoryList } from 'ui/historyList/HistoryList';
import { GroupTypeEnum } from 'api/groups/groups.enum';
import { transformTreeToFlatArray } from 'helpers/transformTreeToFlatArray';
import { useGroupHistoryQuery } from '../hooks/useGroupHistoryQuery';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';
import * as Styled from '../GroupHistory.styles';

import { BaseHistoryProps } from './BaseHistory.types';

export const BaseHistory = ({
  group,
  groupHistoryResponse,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  isLoading,
  isFetching,
  onClearHistoryQuery,
  searchQuery,
  setSearchQuery,
  organizations,
  owners,
}: BaseHistoryProps) => {
  const { formatMessage } = useLocale();
  const { setGroupHistoryQuery, query: groupHistoryQuery } = useGroupHistoryQuery();

  const ownersOptions =
    owners?.map((owner) => ({
      label: `${owner.imie || ''} ${owner.nazwisko || ''}`,
      value: owner.id,
      number: owner.nrEwidencyjny,
    })) || [];

  return (
    <HistoryList
      data={groupHistoryResponse?.data || []}
      isLoading={isLoading}
      filters={{
        gridTemplateColumns: '1fr 1fr 1fr',
        isClearButtonVisible: Object.values(groupHistoryQuery).some((query) => query) || !!searchQuery,
        onClearFilters: () => onClearHistoryQuery(),
        render: () => (
          <>
            <HistoryDatepickerWithModal
              dates={[
                groupHistoryQuery.dataOd ? new Date(groupHistoryQuery.dataOd) : null,
                groupHistoryQuery.dataDo ? new Date(groupHistoryQuery.dataDo) : null,
              ]}
              setDates={(dates) =>
                setGroupHistoryQuery({
                  dataOd: dates[0] ? formatISO(dates[0]) : undefined,
                  dataDo: dates[1] ? formatISO(dates[1]) : undefined,
                })
              }
            />

            <HistoryActionAutocomplete
              selectedAction={groupHistoryQuery.akcja}
              onChangeAction={(action) => setGroupHistoryQuery({ akcja: action })}
              historyType="groups"
            />

            {group?.typ === GroupTypeEnum.STATYCZNA && (
              <Autocomplete
                onChange={(_, option) => setGroupHistoryQuery({ posiadacz: option?.value as string | undefined })}
                value={ownersOptions.find(({ value }) => groupHistoryQuery.posiadacz === value) || null}
                options={ownersOptions}
                renderOption={(props, option) =>
                  option.value ? (
                    <li {...props} key={`${option.label}-${option.value}`}>
                      <Styled.OptionNumber>
                        {formatMessage({ id: AppMessages['tableSearch.owner.number'] })}{' '}
                        {owners ? owners.find((owner) => owner.id === option.value)?.nrEwidencyjny || 0 : ''}
                      </Styled.OptionNumber>

                      <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
                    </li>
                  ) : null
                }
                loadingText={formatMessage({ id: AppMessages['common.loading'] })}
                noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
                placeholder={formatMessage({ id: AppMessages['group.history.search.owner'] })}
                inputVariant="outlined"
                inputSize="big"
                isOptionEqualToValue={(option, value) => option.value === value.value}
                popupIcon={<SearchIcon />}
                stopRotatePopupIndicator
              />
            )}

            {group?.typ === GroupTypeEnum.DYNAMICZNA && (
              <OrganizationsTreeSelect
                inputVariant="outlined"
                inputSize="big"
                onChange={(val) => setGroupHistoryQuery({ komorka: val?.id })}
                value={
                  organizations
                    ? transformTreeToFlatArray(organizations).find(
                        (organization) => organization.id === groupHistoryQuery.komorka,
                      )
                    : undefined
                }
                placeholder={formatMessage({ id: AppMessages['group.history.search.cell'] })}
              />
            )}

            <TableSearchContainer
              withoutToggle
              inputPlaceholder={formatMessage({ id: AppMessages['group.history.search.executor'] })}
              searchBy="users"
              tableSearchQuery={searchQuery}
              setTableSearchQuery={setSearchQuery}
              selectedValue={groupHistoryQuery.query}
              setSelectedValue={(option) => setGroupHistoryQuery({ query: option || undefined })}
              inputSize="big"
            />
          </>
        ),
      }}
      pagination={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        count: groupHistoryResponse?.count || 0,
      }}
      renderHistoryItemBody={(history) => (
        <>
          <HistoryList.ActionBodyItem
            title={formatMessage({ id: AppMessages['group.history.descriptionTitle.executor'] })}
            value={`${history.wykonawca?.imie} ${history.wykonawca?.nazwisko}`}
          />

          {history.nazwa && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['group.history.descriptionTitle.name'] })}
              oldValue={history.nazwa.old}
              newValue={history.nazwa.new}
            />
          )}

          {history.opis && (
            <HistoryList.ActionModifiedLongFieldBodyItem
              title={formatMessage({ id: AppMessages['group.history.descriptionTitle.description'] })}
              oldValue={history.opis.old}
              newValue={history.opis.new}
            />
          )}

          {history.komorka && (
            <HistoryList.ActionModifiedFieldBodyItem
              title={formatMessage({ id: AppMessages['group.history.descriptionTitle.cell'] })}
              oldValue={history.komorka.old}
              newValue={history.komorka.new}
            />
          )}

          {history.dodaniPosiadacze && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage({ id: AppMessages['group.history.descriptionTitle.addedOwners'] })}
              items={history.dodaniPosiadacze}
              shouldScrollToTop={!!groupHistoryQuery.posiadacz && !isFetching}
              renderItemBody={(owner) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.registrationNumber'] })}
                  number={owner.nrEwidencyjny}
                  value={`${owner.imie} ${owner.nazwisko}`}
                />
              )}
            />
          )}

          {history.usunieciPosiadacze && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage({ id: AppMessages['group.history.descriptionTitle.removedOwners'] })}
              items={history.usunieciPosiadacze}
              shouldScrollToTop={!!groupHistoryQuery.posiadacz && !isFetching}
              renderItemBody={(owner) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.registrationNumber'] })}
                  number={owner.nrEwidencyjny}
                  value={`${owner.imie} ${owner.nazwisko}`}
                />
              )}
            />
          )}
        </>
      )}
    />
  );
};
