import { Tooltip, Button } from 'shared-ui';

import { ReactComponent as TrashDisabledIcon } from 'assets/icons/trash-disabled.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { DeleteWantedTerminalsProps } from './DeleteWantedTerminals.types';

const SINGLE_CONTENT = {
  title: 'wanted.terminals.delete.single.title',
  subtitle: 'wanted.terminals.delete.single.subtitle',
  text: 'wanted.terminals.delete.single.text',
} as const;

const MULTI_CONTENT = {
  title: 'wanted.terminals.delete.multi.title',
  subtitle: 'wanted.terminals.delete.multi.subtitle',
  text: 'wanted.terminals.delete.multi.text',
} as const;

export const DeleteWantedTerminals = ({
  checkedTerminals,
  isModalOpen,
  isMultiDelete,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
  terminalsCount,
}: DeleteWantedTerminalsProps) => {
  const { formatMessage } = useLocale();
  const { title, subtitle, text } = isMultiDelete ? MULTI_CONTENT : SINGLE_CONTENT;
  const isDeleteDisabled = terminalsCount === checkedTerminals.length;

  return (
    <>
      <Tooltip
        placement="bottom-start"
        disableHoverListener={!isDeleteDisabled}
        title={formatMessage({ id: AppMessages['wanted.terminals.delete.tooltip'] })}
      >
        <Button
          disabled={isDeleteDisabled}
          variant="outlined"
          color="primary"
          size="medium"
          startIcon={isDeleteDisabled ? <TrashDisabledIcon /> : <TrashIcon />}
          onClick={onModalOpen}
        >
          {formatMessage({ id: AppMessages['wanted.terminals.delete.button'] })}
        </Button>
      </Tooltip>
      <ActionModal
        size="medium"
        header={formatMessage({ id: AppMessages[title] })}
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['wanted.terminals.delete.button'] })}
      >
        <>
          <ActionModal.Subtitle>
            {formatMessage({ id: AppMessages[subtitle] })}{' '}
            {isMultiDelete ? checkedTerminals.length : checkedTerminals[0]?.nazwa}
          </ActionModal.Subtitle>

          <ActionModal.Text>{formatMessage({ id: AppMessages[text] })}</ActionModal.Text>
        </>
      </ActionModal>
    </>
  );
};
