import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth/useAuth';
import { AppRoute } from '../AppRoute.enum';
import { Loader } from 'ui/loader/Loader';

import { AuthorizedRouteProps } from './AuthorizedRoute.types';

export const AuthorizedRoute = ({ children, adminRoute }: AuthorizedRouteProps) => {
  const { isAuthenticated, isAdmin, isUserLoading } = useAuth();
  const location = useLocation();

  if (isUserLoading) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return children;
  }

  if (adminRoute) {
    return isAdmin ? children : <Navigate to={AppRoute.cards} />;
  }

  return (
    <Navigate
      to={{
        pathname: AppRoute.login,
      }}
      state={{
        destinationLink: location.pathname,
        prevPath: location?.state?.prevPath,
      }}
    />
  );
};
