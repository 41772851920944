import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'shared-ui';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { ActionModal } from 'ui/actionModal/ActionModal';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';
import { Form } from 'ui/form/Form';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';

import { UpdateMultiWantedForm, UpdateMultiWantedProps } from './UpdateMultiWanted.types';

export const UpdateMultiWanted = ({
  servers,
  terminals,
  isLoadingTerminals,
  isModalOpen,
  onModalClose,
  onModalOpen,
  onSubmit,
  isLoadingUpdateWanted,
  checkedOwners,
}: UpdateMultiWantedProps) => {
  const { formatMessage } = useLocale();
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<UpdateMultiWantedForm>();

  const getTerminalsOptions = () => {
    return terminals.map((terminal) => ({
      label: terminal.nazwa,
      value: terminal.id,
      number: terminal.numerLogicznyKontrolera,
    }));
  };

  const { serverOptions } = useAppSelectOptions({ servers });

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<EditIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['wanted.multiAction.update.button'] })}
      </Button>

      <ActionModal
        size="medium"
        header={formatMessage({ id: AppMessages['wanted.multiAction.update.title'] })}
        onClose={onModalClose}
        open={isModalOpen}
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid || isLoadingUpdateWanted}
        submitButtonText={formatMessage({ id: AppMessages['wanted.multiAction.update.submit'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['wanted.multiAction.update.subtitle'] })} {checkedOwners.length}
        </ActionModal.Subtitle>

        <Form.Grid>
          <Form.ControlledSelect
            name="serwerId"
            control={control}
            options={serverOptions}
            onChange={(e) => {
              setValue('serwerId', e.target.value as string, { shouldDirty: true });
              setValue('tkds', []);
            }}
            label={formatMessage({ id: AppMessages['wanted.multiAction.update.server.label'] })}
            withHelperText
            required
          />

          <Controller
            name="tkds"
            control={control}
            render={({ field }) => (
              <MultiAutocomplete
                {...field}
                required
                withLimitedTags
                stopRotatePopupIndicator
                inputSize="big"
                limitTags={1}
                onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                options={getTerminalsOptions()}
                loading={isLoadingTerminals}
                label={formatMessage({ id: AppMessages['wanted.multiAction.update.readers.label'] })}
                placeholder={formatMessage({
                  id: AppMessages['wanted.multiAction.update.readers.placeholder'],
                })}
                renderOption={renderMultiAutocompleteOption}
              />
            )}
          />
        </Form.Grid>
      </ActionModal>
    </>
  );
};
