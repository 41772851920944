import {
  AnnouncementModDescriptionEnum,
  AnnouncementModEnum,
  AnnouncementModShortDescriptionEnum,
} from 'api/announcements/announcements.enum';
import { ProblemTkdDescriptionEnum } from 'api/cards/cards.enum';
import { EVENT_MODE_NUMBERS } from 'app/events/Events.variables';
import {
  AnnouncementTerminalNameSortEnum,
  AnnouncementsIdSortEnum,
  AnnouncementsNameSortEnum,
  AnnouncementsQueryKeysEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import {
  YesNoFilterItemsDescriptionEnum,
  useYesNoFilterItems,
} from 'hooks/useYesNoHeaderFilterItems/useYesNoFilterItems';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';

export const AnnouncementsTableHeader = () => {
  const { formatMessage } = useLocale();

  const { yesNoFilterItems } = useYesNoFilterItems();

  const problemTkdFilterOptions = [
    {
      name: formatMessage({ id: AppMessages[ProblemTkdDescriptionEnum.true] }),
      filterBy: 'true',
    },
    {
      name: formatMessage({ id: AppMessages[ProblemTkdDescriptionEnum.false] }),
      filterBy: 'false',
    },
  ];

  const modFilterOptions = Object.values(AnnouncementModEnum).map((option) => ({
    name: formatMessage({ id: AnnouncementModDescriptionEnum[option] }),
    filterBy: option,
  }));

  const modeOptions = EVENT_MODE_NUMBERS.map((mode) => ({
    name: String(mode),
    filterBy: String(mode),
  }));

  return (
    <>
      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.ANNOUNCEMENTS}
        sortBy={AnnouncementsNameSortEnum}
        queryHeader={formatMessage({ id: AppMessages['announcements.filters.name'] })}
      />

      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.ANNOUNCEMENTS}
        sortBy={AnnouncementsIdSortEnum}
        queryHeader={formatMessage({ id: AppMessages['announcements.filters.id'] })}
      />

      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.ANNOUNCEMENTS}
        sortBy={AnnouncementTerminalNameSortEnum}
        queryHeader={formatMessage({ id: AppMessages['announcements.filters.readerName'] })}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.ANNOUNCEMENTS}
        queryName={AnnouncementsQueryKeysEnum.PROBLEM_TKD}
        filterItems={problemTkdFilterOptions}
        queryHeader={formatMessage({ id: AppMessages['announcements.filters.reader'] })}
        queryHeaderDescriptions={ProblemTkdDescriptionEnum}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.ANNOUNCEMENTS}
        queryName={AnnouncementsQueryKeysEnum.MOD}
        filterItems={modFilterOptions}
        queryHeader={formatMessage({ id: AppMessages['announcements.filters.mod'] })}
        queryHeaderDescriptions={AnnouncementModShortDescriptionEnum}
      />

      <Table.HeaderMultiFilterItem
        queryKey={QueryKeyLocalStorageEnum.ANNOUNCEMENTS}
        queryName={AnnouncementsQueryKeysEnum.TRYB}
        filterItems={modeOptions}
        queryHeader={formatMessage({ id: AppMessages['announcements.filters.mode'] })}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.ANNOUNCEMENTS}
        queryName={AnnouncementsQueryKeysEnum.POSIADACZ}
        filterItems={yesNoFilterItems}
        queryHeader={formatMessage({ id: AppMessages['announcements.filters.owner'] })}
        queryHeaderDescriptions={YesNoFilterItemsDescriptionEnum}
      />
    </>
  );
};
