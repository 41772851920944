import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePagination } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { Terminal } from 'api/terminals/terminals.types';
import { fetchGlcTerminals } from 'api/glc/glc';

import { GlcTerminals } from './GlcTerminals';

export const GlcTerminalsContainer = () => {
  const {
    storedQueries: { glcTerminals: glcTerminalsQuery },
  } = useLocaleStoreQuery();
  const [checkedGlcTerminals, setCheckedGlcTerminals] = useState<Terminal[]>([]);
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();
  const { id } = useParams<{ id: string }>();

  const { data: glcTerminalsResponse, isLoading } = useQuery(
    [QueryKeyEnum.GLC_TERMINALS, glcTerminalsQuery, page, rowsPerPage, id],
    () => fetchGlcTerminals(id),
    {
      args: {
        ...glcTerminalsQuery,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      },
    },
  );

  useEffect(() => {
    setPage(0);
  }, [glcTerminalsQuery]);

  return (
    <GlcTerminals
      glcTerminalsResponse={glcTerminalsResponse}
      isLoading={isLoading}
      checkedGlcTerminals={checkedGlcTerminals}
      setCheckedGlcTerminals={setCheckedGlcTerminals}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      glcId={id || ''}
    />
  );
};
