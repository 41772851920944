import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionButton } from 'ui/actionButton/ActionButton';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { DeleteOwnerPermissionProps } from './DeleteOwnerIndividualPermission.types';

export const DeleteOwnerPermission = ({
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
}: DeleteOwnerPermissionProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <ActionButton type="delete" onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['permission.individual.delete.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['permission.individual.delete.button'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['permission.individual.delete.subtitle'] })}
        </ActionModal.Subtitle>

        <ActionModal.Text>{formatMessage({ id: AppMessages['permission.individual.delete.text'] })}</ActionModal.Text>

        <ActionModal.Text>{formatMessage({ id: AppMessages['common.irreversibleAction'] })}</ActionModal.Text>
      </ActionModal>
    </>
  );
};
