import { Table } from 'ui/table/Table';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { GroupsTableRowProps } from './GroupsTableRow.types';

export const GroupsTableRow = ({ group }: GroupsTableRowProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Table.RowItem>{group.idGrupy}</Table.RowItem>

      <Table.RowItem>{group.nazwa}</Table.RowItem>

      <Table.RowTagItem tag={group.typ} />

      <Table.RowCellsItem cells={group.komorki} />

      <Table.RowItem>
        {group.posiadacz
          ? formatMessage({ id: AppMessages['common.yes'] }).toUpperCase()
          : formatMessage({ id: AppMessages['common.no'] }).toUpperCase()}
      </Table.RowItem>

      <Table.RowItem>
        {group.glc
          ? formatMessage({ id: AppMessages['common.yes'] }).toUpperCase()
          : formatMessage({ id: AppMessages['common.no'] }).toUpperCase()}
      </Table.RowItem>
    </>
  );
};
