import styled, { css } from 'styled-components';

import { ReactComponent as ConnectButtonIconBase } from 'assets/icons/connect-button.svg';
import { ReactComponent as DisconnectButtonIconBase } from 'assets/icons/disconnect-button.svg';

const connectButtonStyles = css`
  cursor: pointer;
  margin-left: 8px;
`;

export const ConnectButtonIcon = styled(ConnectButtonIconBase)`
  ${connectButtonStyles}
`;

export const DisconnectButtonIcon = styled(DisconnectButtonIconBase)`
  ${connectButtonStyles}
`;
