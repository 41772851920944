export enum CardTypeEnum {
  ZWYKLA = 'ZWYKLA',
  ZASTEPCZA = 'ZASTEPCZA',
  ZWYKLA_WYROZNIONA = 'ZWYKLA_WYROZNIONA',
  ZASTEPCZA_WYROZNIONA = 'ZASTEPCZA_WYROZNIONA',
}

export enum CardStatusEnum {
  DOSTEPNA = 'DOSTEPNA',
  WYDANA = 'WYDANA',
  WYDANA_Z = 'WYDANA_Z',
}

export enum CardBlockadeEnum {
  AUTOMATYCZNA = 'AUTOMATYCZNA',
  MANUALNA = 'MANUALNA',
}

export enum CardBlockTypeEnum {
  ODBLOKOWANA = 'ODBLOKOWANA',
  RECZNA = 'RECZNA',
  ZABLOKOWANA_KAD = 'ZABLOKOWANA_KAD',
  ZABLOKOWANA_PKD = 'ZABLOKOWANA_PKD',
}

export enum CardBlockadeDescriptionEnum {
  AUTOMATYCZNA = 'cards.history.blockType.automatic',
  MANUALNA = 'cards.history.blockType.manual',
}

export enum CardUnblockDescriptionEnum {
  AUTOMATYCZNA = 'cards.history.unblockType.automatic',
  MANUALNA = 'cards.history.unblockType.manual',
}

export enum CardHistoryActionDescriptionEnum {
  ZABLOKOWANO = 'cards.history.action.locked',
  ODBLOKOWANO = 'cards.history.action.unlocked',
  ZWROCONO = 'cards.history.action.returned',
  WYDANO = 'cards.history.action.issued',
  ZARCHIWIZOWANO = 'cards.history.action.archived',
  PRZYWROCONO = 'cards.history.action.restored',
  DODANO = 'cards.history.action.added',
  ZEDYTOWANO = 'cards.history.action.edited',
}

export enum ProblemTkdDescriptionEnum {
  true = 'card.header.notUpdated.option',
  false = 'card.header.updated.option',
}
