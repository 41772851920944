import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ShowUp, Pagination } from 'shared-ui';

import { Permission } from 'api/permissions/permissions.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ExpandableList } from 'ui/expandableList/ExpandableList';
import { findServerToExpand } from 'helpers/findServerToExpand';
import { useSelectedOwnerIndividualPermission } from 'hooks/useSelectedOwnerIndividualPermission/useSelectedOwnerIndividualPermission';

import { OwnerIndividualPermissionMultiActions } from './ownerIndividualPermissionsMultiActions/OwnerIndividualPermissionsMultiActions';
import { OwnerIndividualTerminalsPermissionsContainer } from './ownerIndividualTerminalsPermissions/OwnerIndividualTerminalsPermissionsContainer';
import { OwnerIndividualPermissionsProps } from './OwnerIndividualPermissions.types';

export const OwnerIndividualPermissions = ({
  servers,
  isLoading,
  permissionsCount,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setPermissionsCount,
}: OwnerIndividualPermissionsProps) => {
  const { formatMessage } = useLocale();
  const { id } = useParams<{ id: string }>();
  const ownerId = id || '';

  const { selectedOwnerIndividualPermission, setSelectedOwnerIndividualPermission } =
    useSelectedOwnerIndividualPermission();
  const [checkedPermissions, setCheckedPermissions] = useState<Permission[]>([]);

  const serverWithSelectedPermission = servers.find(
    (server) => server.id === selectedOwnerIndividualPermission?.rcp.id,
  );
  const initiallyExpandedServer = serverWithSelectedPermission || findServerToExpand(servers || []);

  return (
    <>
      <ShowUp show={checkedPermissions.length > 1}>
        <OwnerIndividualPermissionMultiActions
          ownerId={ownerId}
          checkedPermissions={checkedPermissions}
          setCheckedPermissions={setCheckedPermissions}
        />
      </ShowUp>

      <Pagination
        page={page}
        setPage={setPage}
        count={permissionsCount}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      <ExpandableList
        data={servers}
        isLoading={isLoading}
        nothingToShowText={formatMessage({ id: AppMessages['permissions.individual.nothingToShow'] })}
        title={(server) => server.nazwa}
        initiallyExpandedItem={initiallyExpandedServer}
        toggleItemExpansion={(expandedServer) => {
          setPage(0);
          setCheckedPermissions([]);
          setSelectedOwnerIndividualPermission(undefined);
          if (!expandedServer) setPermissionsCount(0);
        }}
        renderExpandableItemBody={(server, expandedServer) => (
          <OwnerIndividualTerminalsPermissionsContainer
            key={server.id}
            server={server}
            expandedServer={expandedServer}
            checkedPermissions={checkedPermissions}
            setCheckedPermissions={setCheckedPermissions}
            setPermissionsCount={setPermissionsCount}
          />
        )}
      />
    </>
  );
};
