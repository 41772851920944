import { PageLayout, Pagination } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ExpandableList } from 'ui/expandableList/ExpandableList';
import { findServerToExpand } from 'helpers/findServerToExpand';
import { AppRoute } from 'routing/AppRoute.enum';

import { InstantControlTerminalsRowsContainer } from './instantControlTerminalsRows/InstantControlTerminalsRowsContainer';
import { InstantControlTerminalsProps } from './InstantControlTerminals.types';

export const InstantControlTerminals = ({
  servers,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  terminalsCount,
  setTerminalsCount,
}: InstantControlTerminalsProps) => {
  const { formatMessage } = useLocale();

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['control.instant.terminals.title'] })}
      withBackButton={{
        onClick: (navigate) => navigate(AppRoute.controlInstant),
      }}
      renderContent={() => (
        <>
          <Pagination
            page={page}
            setPage={setPage}
            count={terminalsCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />

          <ExpandableList
            data={servers || []}
            isLoading={isLoading}
            nothingToShowText={formatMessage({ id: AppMessages['control.instant.terminals.nothingToShow'] })}
            title={(server) => server.nazwa}
            initiallyExpandedItem={findServerToExpand(servers || [])}
            toggleItemExpansion={(expandedServer) => {
              setPage(0);
              if (!expandedServer) setTerminalsCount(0);
            }}
            renderExpandableItemBody={(server, expandedServer) => (
              <InstantControlTerminalsRowsContainer
                key={server.id}
                expandedServer={expandedServer}
                setTerminalsCount={setTerminalsCount}
              />
            )}
          />
        </>
      )}
    />
  );
};
