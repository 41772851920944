import { useEffect } from 'react';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { DEFAULT_PERMISSIONS } from 'core/global.variables';
import { editMultiGroupTerminalsPermissions } from 'api/permissions/permissions';
import { useSelectedGroupTerminalPermission } from 'hooks/useSelectedGroupTerminalPermission/useSelectedGroupTerminalPermission';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { EditGroupTerminalsPermissions } from './EditGroupTerminalsPermissions';
import {
  EditGroupTerminalsPermissionsContainerProps,
  EditGroupTerminalsPermissionsFormBody,
} from './EditGroupTerminalsPermissions.types';
import { useEditGroupTerminalsPermissionsValidation } from './EditGroupTerminalsPermissions.validation';

export const EditGroupTerminalsPermissionsContainer = ({
  checkedPermissions,
  groupId,
}: EditGroupTerminalsPermissionsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedGroupTerminalPermission, setSelectedGroupTerminalPermission } = useSelectedGroupTerminalPermission();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onEditMultiGroupTerminalsPermissions, isLoading } = useMutation(editMultiGroupTerminalsPermissions, {
    onSuccess: (_, { body }) => {
      if (
        selectedGroupTerminalPermission &&
        checkedPermissions.some(({ id }) => id === selectedGroupTerminalPermission?.id)
      ) {
        setSelectedGroupTerminalPermission({ ...selectedGroupTerminalPermission, ...body });
      }

      queryClient.invalidateQueries([QueryKeyEnum.GROUP_TERMINALS_PERMISSIONS_SERVERS]);
      queryClient.invalidateQueries([QueryKeyEnum.GROUP_TERMINALS_PERMISSIONS]);
      toast.dark(formatMessage({ id: AppMessages['permissions.multiAction.edit.success'] }));
      onModalClose();
    },
  });

  const { schema } = useEditGroupTerminalsPermissionsValidation();
  const formMethods = useForm<EditGroupTerminalsPermissionsFormBody>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      ...DEFAULT_PERMISSIONS,
    },
  });

  useEffect(() => {
    formMethods.reset();
  }, [isModalOpen]);

  const onSubmit = (formBody: EditGroupTerminalsPermissionsFormBody) => {
    onEditMultiGroupTerminalsPermissions({
      id: groupId,
      body: {
        ...formBody,
        idPermissions: checkedPermissions.map(({ id }) => `${id}`),
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <EditGroupTerminalsPermissions
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        isLoading={isLoading}
        onSubmit={onSubmit}
        checkedPermissions={checkedPermissions}
      />
    </FormProvider>
  );
};
