import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
  padding: 24px 0;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
`;

export const Label = styled(Typography)`
  margin-bottom: 8px;
  font-weight: 700;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Description = styled(Typography)`
  font-size: 12px;
  padding-bottom: 4px;
`;

export const Error = styled(Typography)(
  ({ theme: { palette } }) => css`
    padding-top: 4px;
    color: ${palette.error.main};
    font-size: 12px;
    font-weight: 700;
  `,
);

export const OptionValue = styled(Typography)`
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 2px;
`;

export const OptionLabel = styled(Typography)`
  font-size: 14px;
`;
