import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { unblockCard } from 'api/cards/cards';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { CardBlockTypeEnum } from 'api/cards/cards.enum';
import { AppRouteWithParams } from 'routing/AppRoute.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { useModalState } from 'hooks/useModalState/useModalState';

import { UnblockCard } from './UnblockCard';
import { UnblockCardContainerProps } from './UnblockCard.types';

export const UnblockCardContainer = ({ selectedCard }: UnblockCardContainerProps) => {
  const queryClient = useQueryClient();
  const isHistoryView = useIsExactPath(AppRouteWithParams.cardHistory.path);

  const { formatMessage } = useLocale();
  const { setSelectedCard } = useSelectedCard();

  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onUnblockCard, isLoading } = useMutation(unblockCard, {
    onSuccess: () => {
      queryClient.invalidateQueries([isHistoryView ? QueryKeyEnum.CARD_HISTORY : QueryKeyEnum.CARDS]);
      setSelectedCard({ ...selectedCard, blokada: CardBlockTypeEnum.ODBLOKOWANA });
      toast.dark(formatMessage({ id: AppMessages['card.unblock.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onUnblockCard(selectedCard.id);
  };

  return (
    <UnblockCard
      selectedCard={selectedCard}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
