import { Button, Input, Select } from 'shared-ui';
import { Controller, useFormContext } from 'react-hook-form';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';
import { Form } from 'ui/form/Form';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { announcementKeywords } from 'app/announcements/announcementsTable/AnnouncementsList.types';
import { KeywordsInput } from 'ui/keywordsInput/KeywordsInput';

import { ModifyMultiAnnouncementsFormBody, ModifyMultiAnnouncementsProps } from './ModifyMultiAnnouncements.types';
import * as Styled from './ModifyMultiAnnouncements.styles';

export const ModifyMultiAnnouncements = ({
  checkedAnnouncements,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
}: ModifyMultiAnnouncementsProps) => {
  const { formatMessage } = useLocale();
  const { yesNoOptions } = useAppSelectOptions();

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useFormContext<ModifyMultiAnnouncementsFormBody>();

  return (
    <>
      <Button variant="outlined" color="primary" size="small" startIcon={<EditIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['announcements.multiAction.modify.button'] })}
      </Button>

      <ActionModal
        header={formatMessage({ id: AppMessages['announcements.multiAction.modify.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={handleSubmit(onSubmit)}
        isLoading={isLoading}
        submitButtonDisabled={!isDirty || !isValid}
        submitButtonText={formatMessage({ id: AppMessages['common.save'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['announcements.multiAction.modify.subtitle'] })}{' '}
          {checkedAnnouncements.length}
        </ActionModal.Subtitle>

        <Form.Grid>
          <Controller
            name="potwierdzenie"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label={formatMessage({ id: AppMessages['announcement.add.confirmation.label'] })}
                placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
                options={yesNoOptions}
              />
            )}
          />
          <Controller
            name="czasKomunikatu"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                type="number"
                label={formatMessage({ id: AppMessages['announcement.add.duration.label'] })}
                placeholder={formatMessage({ id: AppMessages['announcement.add.duration.placeholder'] })}
                errorMessage={error?.message}
              />
            )}
          />

          <Styled.InputWrapper>
            <Controller
              name="komunikat"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <KeywordsInput
                  {...field}
                  label={formatMessage({ id: AppMessages['announcement.add.announcement.label'] })}
                  errorMessage={error?.message}
                  keywords={announcementKeywords}
                  placeholder={formatMessage({ id: AppMessages['announcement.add.announcement.placeholder'] })}
                />
              )}
            />
          </Styled.InputWrapper>
        </Form.Grid>
      </ActionModal>
    </>
  );
};
