import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'shared-ui';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Input } from 'ui/input/Input';
import { Glc } from 'api/glc/glc.types';

import { GlcDetailsProps } from './GlcDetails.types';
import * as Styled from './GlcDetails.styles';
import { GlcActions } from './glcActions/GlcActions';

export const GlcDetails = ({ isLoading, selectedGlc, onSubmit }: GlcDetailsProps) => {
  const { formatMessage } = useLocale();
  const isArchived = selectedGlc.archiwalny;

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isValid },
  } = useFormContext<Glc>();

  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <GlcActions selectedGlc={selectedGlc} />

        <Styled.DetailsItemWrapper>
          <Styled.DetailName>{formatMessage({ id: AppMessages['glc.details.id'] })}</Styled.DetailName>
          <Styled.DetailText>{selectedGlc.idGlc}</Styled.DetailText>
        </Styled.DetailsItemWrapper>

        <Controller
          name="nazwa"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              label={formatMessage({ id: AppMessages['glc.details.name.label'] })}
              placeholder={formatMessage({ id: AppMessages['glc.details.name.placeholder'] })}
              errorMessage={error?.message}
              disabled={isArchived}
              testId="glc-details-name"
            />
          )}
        />

        <Input
          disabled
          value={selectedGlc.rcp.nazwa}
          label={formatMessage({ id: AppMessages['glc.details.server.label'] })}
        />

        <Controller
          name="opis"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              label={formatMessage({ id: AppMessages['glc.details.description.label'] })}
              placeholder={formatMessage({ id: AppMessages['glc.details.description.placeholder'] })}
              errorMessage={error?.message}
              disabled={isArchived}
              maxRows={4}
              multiline
              testId="glc-details-description"
            />
          )}
        />
      </div>

      {isDirty && (
        <Styled.ButtonsWrapper>
          <Button
            size="small"
            onClick={() => {
              reset(selectedGlc);
            }}
            color="primary"
          >
            {formatMessage({ id: AppMessages['common.cancel'] })}
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={isLoading}
            size="small"
            variant="contained"
            color="primary"
          >
            {formatMessage({ id: AppMessages['common.save'] })}
          </Button>
        </Styled.ButtonsWrapper>
      )}
    </Styled.Form>
  );
};
