import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Server } from 'api/servers/servers.types';

export const useAddServerValidation = (servers?: Server[]) => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    nazwa: yup
      .string()
      .max(60, formatMessage({ id: AppMessages['devices.addServer.validation.name.max'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    port: yup
      .string()
      .when('host', (host) => {
        const serverWithSameHost = servers && servers.find((server) => server.host === host);

        if (!!serverWithSameHost) {
          return yup
            .string()
            .notOneOf(
              [`${serverWithSameHost.port}`],
              formatMessage({ id: AppMessages['devices.addServer.validation.port'] }),
            )
            .required(formatMessage({ id: AppMessages['common.validation.required'] }));
        }

        return yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] }));
      })
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    host: yup
      .string()
      .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      .matches(
        /^(([1-9]?\d|1\d\d|2[0-5][0-5]|2[0-4]\d)\.){3}([1-9]?\d|1\d\d|2[0-5][0-5]|2[0-4]\d)$/,
        formatMessage({ id: AppMessages['devices.addServer.validation.addressIP'] }),
      ),
    timeout: yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] })),
  });

  return { schema };
};
