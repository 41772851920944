import { useContext } from 'react';

import { OwnerQueryKeysContext } from 'context/ownerQueryKeys/ownerQueryKeysContext/OwnerQueryKeysContext';

export const useOwnerQueryKeys = () => {
  const context = useContext(OwnerQueryKeysContext);

  if (context === undefined) {
    throw new Error('OwnerQueryKeysContext is unavailable, make sure you are using OwnerQueryKeysContextController');
  }

  return context;
};
