import { DoubleTextElipsisWithTooltip, ProblemIconWithTooltip, Tooltip, useObserver } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { StatusTableItem } from 'ui/statusTableItem/StatusTableItem';
import { truncate } from 'helpers/truncate';
import { AppMessages } from 'i18n/messages';
import { CardBlockTypeDescriptionTableEnum, CardTypeDescriptionEnum } from '../Cards.enum';
import { CardBlockTypeEnum } from 'api/cards/cards.enum';
import { Table } from 'ui/table/Table';

import { CardsTableRowProps } from './CardsTableRow.types';
import * as Styled from './CardsTableRow.styles';

export const CardsTableRow = ({ card }: CardsTableRowProps) => {
  const { formatMessage } = useLocale();
  const { tableWidth } = useObserver();
  const ownerFullName = `${card?.posiadacz?.imie || ''} ${card?.posiadacz?.nazwisko || ''}`;

  return (
    <>
      <Tooltip
        placement="bottom-start"
        disableHoverListener={truncate(card.numerKarty) === `${card.numerKarty}`}
        title={card.numerKarty}
      >
        <Table.RowItem>{truncate(card.numerKarty)}</Table.RowItem>
      </Tooltip>

      <Table.RowItem>
        <ProblemIconWithTooltip
          isError={card.problemTkd}
          tooltip={formatMessage({ id: AppMessages['card.table.notUpdated.info'] })}
        />
      </Table.RowItem>

      <Table.RowItem>{formatMessage({ id: CardTypeDescriptionEnum[card.typKarty] })}</Table.RowItem>

      <Table.RowItem>
        <StatusTableItem status={card.status} />
      </Table.RowItem>

      <Table.RowItem>
        <Styled.BlockadeWrapper>
          {card.blokada !== CardBlockTypeEnum.ODBLOKOWANA ? <Styled.YesIcon /> : <Styled.NoIcon />}
          {formatMessage({ id: CardBlockTypeDescriptionTableEnum[card.blokada] })}
        </Styled.BlockadeWrapper>
      </Table.RowItem>

      <Table.RowItem>
        <DoubleTextElipsisWithTooltip
          fullText={ownerFullName}
          firstLineText={card?.posiadacz?.imie}
          secondLineText={card?.posiadacz?.nazwisko}
          width={tableWidth}
        />
      </Table.RowItem>
    </>
  );
};
