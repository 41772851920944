import { Controller, useFormContext } from 'react-hook-form';
import { Select } from 'shared-ui';

import { PermissionEnum } from 'api/permissions/permissions.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';
import { AppMessages } from 'i18n/messages';
import { AddGroupFormBody } from '../AddGroup.types';
import { GlcValueEnum } from '../AddGroup.enum';
import { Form } from 'ui/form/Form';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';

import { AddGroupPermissionsFormProps } from './AddGroupPermissionsForm.types';

export const AddGroupPermissionsForm = ({
  servers,
  selectedServerId,
  setSelectedServerId,
  terminals,
  isLoadingTerminals,
  glcGroups,
  isLoadingGlcGroups,
}: AddGroupPermissionsFormProps) => {
  const { formatMessage } = useLocale();
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption();

  const {
    control,
    trigger,
    formState: { errors },
    clearErrors,
    watch,
    setValue,
  } = useFormContext<AddGroupFormBody>();

  const isGlcGroup = watch('glc') === GlcValueEnum.YES;

  const isTkdNotSelected = !watch('tkds')?.length;
  const isGlcNotSelected = !watch('idGlcs')?.length;
  const isPermissionSelectDisabled = isTkdNotSelected && isGlcNotSelected;

  const getReadersOptions = () => {
    return terminals.map((terminal) => ({
      label: terminal.nazwa,
      value: terminal.id,
      number: terminal.numerLogicznyKontrolera,
    }));
  };

  const { serverOptions, permissionOptions } = useAppSelectOptions({ servers });

  const filteredGlcGroupsBySelectedServer = glcGroups.filter((glc) => glc.rcp.id === selectedServerId);
  const getGlcOptions = () => {
    return filteredGlcGroupsBySelectedServer.map((glc) => ({
      value: glc.id,
      label: glc.nazwa,
      number: glc.idGlc,
    }));
  };

  const resetSelectedValues = () => {
    setValue('tkds', []);
    setValue('idGlcs', []);
  };

  return (
    <>
      <Select
        value={selectedServerId}
        onChange={(e) => {
          resetSelectedValues();
          setSelectedServerId(e.target.value as string);
        }}
        label={formatMessage({ id: AppMessages['groups.addGroup.permissions.field.serverName.label'] })}
        withHelperText
        options={serverOptions}
      />

      <Controller
        name={isGlcGroup ? 'idGlcs' : 'tkds'}
        control={control}
        render={({ field }) => (
          <MultiAutocomplete
            {...field}
            withLimitedTags
            stopRotatePopupIndicator
            inputSize="big"
            limitTags={1}
            onChange={(_, option, reason) => {
              option ? field.onChange(option) : field.onChange(null);
              if (reason === 'removeOption' || reason === 'clear') {
                clearErrors('rejestrujRcp');
              }
            }}
            options={isGlcGroup ? getGlcOptions() : getReadersOptions()}
            loading={isGlcGroup ? isLoadingGlcGroups : isLoadingTerminals}
            loadingText={formatMessage({ id: AppMessages['common.loading'] })}
            label={formatMessage({
              id: AppMessages[
                isGlcGroup
                  ? 'groups.addGroup.permissions.field.glcs.label'
                  : 'groups.addGroup.permissions.field.reader.label'
              ],
            })}
            noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
            placeholder={formatMessage({ id: AppMessages['groups.addGroup.permissions.field.reader.placeholder'] })}
            renderOption={renderMultiAutocompleteOption}
          />
        )}
      />

      <Form.ControlledSelect
        name="rejestrujOdbicie"
        control={control}
        disabled={isPermissionSelectDisabled}
        withHelperText
        isError={!!errors.rejestrujRcp?.message}
        onChange={(e) => {
          setValue('rejestrujOdbicie', e.target.value as PermissionEnum, { shouldDirty: true });
          errors.rejestrujRcp?.message && trigger('rejestrujRcp');
        }}
        label={formatMessage({ id: AppMessages['groups.addGroup.permissions.field.registerRecord.label'] })}
        testId="registerRecordSelect"
        data-testId="odbicie-select"
        options={permissionOptions}
      />

      <Form.ControlledSelect
        name="otwierajZamek"
        control={control}
        withHelperText
        disabled={isPermissionSelectDisabled}
        isError={!!errors.rejestrujRcp?.message}
        onChange={(e) => {
          setValue('otwierajZamek', e.target.value as PermissionEnum, { shouldDirty: true });
          errors.rejestrujRcp?.message && trigger('rejestrujRcp');
        }}
        label={formatMessage({ id: AppMessages['groups.addGroup.permissions.field.openLock.label'] })}
        data-testId="zamek-select"
        options={permissionOptions}
      />

      <Form.ControlledSelect
        name="rejestrujRcp"
        control={control}
        withHelperText
        disabled={isPermissionSelectDisabled}
        onChange={(e) => setValue('rejestrujRcp', e.target.value as PermissionEnum, { shouldDirty: true })}
        errorMessage={errors.rejestrujRcp?.message}
        isError={!!errors.rejestrujRcp?.message}
        label={formatMessage({ id: AppMessages['groups.addGroup.permissions.field.registerRcp.label'] })}
        data-testId="rcp-select"
        options={permissionOptions}
      />
    </>
  );
};
