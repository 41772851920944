import { Modal } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionButton } from 'ui/actionButton/ActionButton';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { DeleteOrganizationProps } from './DeleteOrganization.types';

export const DeleteOrganization = ({
  selectedOrganization,
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
  isConfirmationNeeded,
  isOwnerErrorModalOpen,
}: DeleteOrganizationProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <ActionButton type="delete" onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['definitions.organization.delete.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={() => onSubmit(isConfirmationNeeded)}
        submitButtonDisabled={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['definitions.organization.delete.button'] })}
      >
        <>
          {isConfirmationNeeded ? (
            <>
              <ActionModal.Text bold>
                {formatMessage({ id: AppMessages['definitions.organization.delete.error.permission.title'] })}
              </ActionModal.Text>
              <ActionModal.Text bold>
                {formatMessage({ id: AppMessages['definitions.organization.delete.error.permission.description'] })}
              </ActionModal.Text>
            </>
          ) : (
            <>
              <ActionModal.Subtitle>
                {formatMessage({ id: AppMessages['definitions.organization.delete.subtitle'] })}{' '}
                {selectedOrganization.nazwa}
              </ActionModal.Subtitle>
              <ActionModal.Text>
                {formatMessage({ id: AppMessages['definitions.organization.delete.text'] })}
              </ActionModal.Text>
            </>
          )}
        </>
      </ActionModal>

      <Modal
        type="info"
        onSubmit={onModalClose}
        header={formatMessage({ id: AppMessages['definitions.organization.delete.title'] })}
        open={isOwnerErrorModalOpen}
        submitButtonText={formatMessage({ id: AppMessages['definitions.organization.delete.error.owner.confirm'] })}
        onClose={onModalClose}
        size="medium"
      >
        <>
          <ActionModal.Text bold>
            {formatMessage({ id: AppMessages['definitions.organization.delete.error.owner.title'] })}
          </ActionModal.Text>
          <ActionModal.Text bold>
            {formatMessage({ id: AppMessages['definitions.organization.delete.error.owner.description'] })}
          </ActionModal.Text>
        </>
      </Modal>
    </>
  );
};
