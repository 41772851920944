import { useState } from 'react';
import { Popover } from 'shared-ui';

import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import {
  GroupAssignedOwnersQueryKeysEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';
import * as Styled from '../GroupAssignedOwnersTableHeader.styles';

import { PositionGroupAssignedOwnersHeaderItemProps } from './PositionGroupAssignedOwnersMultiActionsHeaderItem.types';
import { PositionPopupAutocompleteContainer } from './positionPopupAutocomplete/PositionPopupAutocompleteContainer';

export const PositionGroupAssignedOwnersHeaderItem = ({ children }: PositionGroupAssignedOwnersHeaderItemProps) => {
  const [headerFilterEl, setHeaderFilterEl] = useState<HTMLDivElement | undefined>(undefined);

  const {
    storedQueries: { [QueryKeyLocalStorageEnum.GROUP_ASSIGNED_OWNERS]: groupAssignedOwnersQuery },
    setQuery: setOwnersQuery,
  } = useLocaleStoreQuery();

  return (
    <Styled.HeaderItem>
      <Styled.HeaderItemSortable $isActive={!!headerFilterEl} onClick={(e) => setHeaderFilterEl(e.currentTarget)}>
        {children}
        {!!groupAssignedOwnersQuery?.stanowisko?.length ? (
          <RemoveIcon
            onClick={(e) => {
              e.stopPropagation();
              setOwnersQuery(QueryKeyLocalStorageEnum.GROUP_ASSIGNED_OWNERS, {
                ...groupAssignedOwnersQuery,
                [GroupAssignedOwnersQueryKeysEnum.STANOWISKO]: [],
              });
            }}
          />
        ) : (
          <DropdownIcon />
        )}
      </Styled.HeaderItemSortable>

      {headerFilterEl && (
        <Popover anchorEl={headerFilterEl} onClose={() => setHeaderFilterEl(undefined)}>
          <PositionPopupAutocompleteContainer />
        </Popover>
      )}
    </Styled.HeaderItem>
  );
};
