import { ChangeEvent, forwardRef, Ref } from 'react';

import { Input } from 'ui/input/Input';
import { InputProps } from 'ui/input/Input.types';

export const AddressIpMaskedInput = forwardRef((props: InputProps, ref: Ref<HTMLInputElement> | undefined) => {
  const onlyNumbersAndDotsRegExp = /^[.0-9]*$/;

  const onChangeMasked = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!props.onChange) return;
    if (!onlyNumbersAndDotsRegExp.test(e.target.value)) return;

    if (e.target.value === '.' || e.target.value.endsWith('..')) return;

    const parts = e.target.value.split('.');

    if (
      parts.length > 4 ||
      parts.some((part: string | number) => part === '00' || (part as number) < 0 || (part as number) > 255)
    ) {
      return;
    }

    props.onChange(e);
  };

  return <Input {...props} ref={ref} onChange={onChangeMasked} />;
});
