import { useEffect } from 'react';
import { usePagination } from 'shared-ui';

import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { QueryKeyLocalStorageEnum, RandomControlQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchControl } from 'api/control/control';
import { ControlTypeEnum } from 'api/control/control.enum';

import { RandomControl } from './RandomControl';
import { RandomControlContainerProps } from './RandomControl.types';

export const RandomControlContainer = ({ randomControlTableSearchQuery }: RandomControlContainerProps) => {
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.RANDOM_CONTROL]: randomControlQuery },
  } = useLocaleStoreQuery();
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();

  const { data: ownersResponse, isLoading } = useQuery(
    [QueryKeyEnum.RANDOM_CONTROL_OWNERS, randomControlQuery, page, rowsPerPage, randomControlTableSearchQuery],
    fetchControl,
    {
      args: {
        ...randomControlQuery,
        [RandomControlQueryKeysEnum.LIMIT]: rowsPerPage,
        [RandomControlQueryKeysEnum.OFFSET]: rowsPerPage * page,
        [RandomControlQueryKeysEnum.QUERY]: randomControlTableSearchQuery,
        [RandomControlQueryKeysEnum.TYP]: ControlTypeEnum.LOSOWA,
      },
    },
  );

  useEffect(() => {
    setPage(0);
  }, [randomControlTableSearchQuery, randomControlQuery]);

  return (
    <RandomControl
      ownersResponse={ownersResponse}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      randomControlQuery={randomControlQuery}
    />
  );
};
