import { DeleteMultiAnnouncementsContainer } from './deleteMultiAnnouncements/DeleteMultiAnnouncementsContainer';
import { AddMultiOwnersContainer } from './addMultiOwners/AddMultiOwnersContainer';
import { ModifyMultiAnnouncementsContainer } from './modifyMultiAnnouncements/ModifyMultiAnnouncementsContainer';
import { AnnouncementsMultiActionsProps } from './AnnouncementsMultiActions.types';
import * as Styled from './AnnouncementsMultiActions.styles';

export const AnnouncementsMultiActions = ({
  checkedAnnouncements,
  setCheckedAnnouncements,
}: AnnouncementsMultiActionsProps) => {
  const isDeleteMultiAnnouncementsActionEnabled = checkedAnnouncements.every((announcement) => !announcement.pkd);

  return (
    <Styled.Container>
      <ModifyMultiAnnouncementsContainer
        checkedAnnouncements={checkedAnnouncements}
        setCheckedAnnouncements={setCheckedAnnouncements}
      />

      <AddMultiOwnersContainer
        checkedAnnouncements={checkedAnnouncements}
        setCheckedAnnouncements={setCheckedAnnouncements}
      />

      {isDeleteMultiAnnouncementsActionEnabled && (
        <DeleteMultiAnnouncementsContainer
          checkedAnnouncements={checkedAnnouncements}
          setCheckedAnnouncements={setCheckedAnnouncements}
        />
      )}
    </Styled.Container>
  );
};
