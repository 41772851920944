import { useEffect } from 'react';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';
import { QueryKeyEnum } from 'core/global.enum';
import { AppMessages } from 'i18n/messages';
import { UpdateGroupBody } from 'api/groups/groups.types';
import { updateGlc } from 'api/glc/glc';
import { useMutation } from 'hooks/useMutation/useMutation';
import { AppRouteWithParams } from 'routing/AppRoute.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { useSelectedGlc } from 'hooks/useSelectedGlc/useSelectedGlc';
import { Glc } from 'api/glc/glc.types';

import { GlcDetails } from './GlcDetails';
import { GlcDetailsContainerProps } from './GlcDetails.types';
import { useGlcDetailsValidation } from './GlcDetails.validation';

export const GlcDetailsContainer = ({ selectedGlc }: GlcDetailsContainerProps) => {
  const { formatMessage } = useLocale();
  const { setSelectedGlc } = useSelectedGlc();
  const { schema } = useGlcDetailsValidation();
  const queryClient = useQueryClient();
  const isHistoryView = useIsExactPath(AppRouteWithParams.glcHistory.path);

  const { mutate: onUpdateOwner, isLoading } = useMutation(updateGlc, {
    onSuccess: (_, { body }) => {
      setSelectedGlc({ ...selectedGlc, ...body });

      if (isHistoryView) {
        queryClient.invalidateQueries([QueryKeyEnum.GLC_HISTORY]);
      } else {
        queryClient.invalidateQueries([QueryKeyEnum.GLC]);
      }

      toast.dark(formatMessage({ id: AppMessages['glc.details.success'] }));
    },
  });

  const formMethods = useForm<UpdateGroupBody>({
    mode: 'all',
    defaultValues: selectedGlc,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    formMethods.reset(selectedGlc);
  }, [selectedGlc]);

  useEffect(() => {
    setSelectedGlc(selectedGlc);
  }, []);

  const onSubmit = (body: Glc) => {
    onUpdateOwner({ id: selectedGlc.id, body: { ...selectedGlc, ...body } });
  };

  return (
    <FormProvider {...formMethods}>
      <GlcDetails selectedGlc={selectedGlc} onSubmit={onSubmit} isLoading={isLoading} />
    </FormProvider>
  );
};
