import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useQueryClient } from 'shared-ui';

import { useModalState } from 'hooks/useModalState/useModalState';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import { fetchServers } from 'api/servers/servers';
import { fetchTerminals } from 'api/terminals/terminals';
import { updateMultiRandomControl } from 'api/control/control';
import { GroupTerminalsPermissionsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { UpdateMultiRandomControl } from './UpdateMultiRandomControl';
import { UpdateMultiRandomControlContainerProps, UpdateMultiRandomControlForm } from './UpdateMultiRandomControl.types';
import { useUpdateMultiRandomControlValidation } from './UpdateMultiRandomControl.validation';

export const UpdateMultiRandomControlContainer = ({ checkedOwners }: UpdateMultiRandomControlContainerProps) => {
  const { isModalOpen, onModalClose, onModalOpen } = useModalState();
  const { schema } = useUpdateMultiRandomControlValidation();
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();

  const formMethods = useForm({
    resolver: yupResolver(schema),
  });
  const { serwerId } = formMethods.watch();

  const { data: serversResponse } = useQuery([QueryKeyEnum.SERVERS], fetchServers);
  const servers = serversResponse?.data || [];

  const { data: terminalsResponse, isInitialLoading: isLoadingTerminals } = useQuery(
    [QueryKeyEnum.TERMINALS, serwerId],
    () => fetchTerminals(serwerId),
    {
      args: {
        zaakceptowane: true,
        sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
      },
      enabled: !!serwerId,
    },
  );
  const terminals = terminalsResponse?.data || [];

  const { mutate: updateRandomControl, isLoading: isLoadingUpdateControl } = useMutation(updateMultiRandomControl, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.RANDOM_CONTROL_OWNERS]);
      toast.dark(formatMessage({ id: AppMessages['control.random.multiAction.update.success'] }));
      onModalClose();
    },
  });

  const onSubmit = (body: UpdateMultiRandomControlForm) => {
    updateRandomControl({
      pkds: checkedOwners.map(({ id }) => id) || [],
      tkds: body.tkds?.map(({ value }) => value as string) || [],
    });
  };

  useEffect(() => {
    servers.length && formMethods.reset({ serwerId: servers[0].id });
  }, [servers]);

  useEffect(() => {
    formMethods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...formMethods}>
      <UpdateMultiRandomControl
        servers={servers}
        terminals={terminals}
        isLoadingTerminals={isLoadingTerminals}
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        onSubmit={onSubmit}
        isLoadingUpdateControl={isLoadingUpdateControl}
        checkedOwners={checkedOwners}
      />
    </FormProvider>
  );
};
