import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DetailsDrawer, ErrorBoundary, PageLayout } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { SearchQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useSelectedAnnouncement } from 'hooks/useSelectedAnnouncement/useSelectedAnnouncement';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';

import { AnnouncementsBar } from './announcementsBar/AnnouncementsBar';
import { AnnouncementsListContainer } from './announcementsTable/AnnouncementsListContainer';
import { AnnouncementDetailsContainer } from './announcementDetails/AnnouncementDetailsContainer';
import { AnnouncementsHistoryContainer } from './announcementsHistory/AnnouncementsHistoryContainer';
import { ExpandedServerIdContextController } from './context/selectedServer/expandedServerIdContextController/ExpandedServerIdContextController';

export const Announcements = () => {
  const { formatMessage } = useLocale();
  const [announcementsTableSearchQuery, setAnnouncementsTableSearchQuery] = useState('');
  const location = useLocation();
  const { selectedAnnouncement, selectedAnnouncementId } = useSelectedAnnouncement();
  const isHistoryView = useIsExactPath(AppRoute.announcementsHistory);

  const currentPage = {
    [AppRoute.announcements]: (
      <AnnouncementsListContainer announcementsTableSearchQuery={announcementsTableSearchQuery} />
    ),
    [AppRoute.announcementsHistory]: <AnnouncementsHistoryContainer />,
  }[location.pathname];

  return (
    <ErrorBoundary>
      <ExpandedServerIdContextController>
        <PageLayout
          header={formatMessage({ id: AppMessages['announcements.title'] })}
          renderContent={() => (
            <>
              <AnnouncementsBar
                searchQueryKey={SearchQueryKeysEnum.WANTED}
                setTableSearchQuery={isHistoryView ? undefined : setAnnouncementsTableSearchQuery}
              />

              {currentPage}
            </>
          )}
          renderDetails={() => (
            <DetailsDrawer
              isOpen={!!selectedAnnouncement && !!selectedAnnouncementId && !isHistoryView}
              header={formatMessage({ id: AppMessages['announcements.details.title'] })}
            >
              {selectedAnnouncement && selectedAnnouncementId && (
                <AnnouncementDetailsContainer selectedAnnouncement={selectedAnnouncement} />
              )}
            </DetailsDrawer>
          )}
        />
      </ExpandedServerIdContextController>
    </ErrorBoundary>
  );
};
