import { PageLayout, useDrawer, TopBar, DetailsDrawer, ShowUp } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { Table } from 'ui/table/Table';
import { AppRoute } from 'routing/AppRoute.enum';
import { useOwnerArchivedView } from 'hooks/useIsOwnerArchivedView/useOwnersArchivedView';
import { useSelectedSearchOptions } from 'hooks/useSelectedSearchOptions/useSelectedSearchOptions';
import { QueryKeyLocalStorageEnum, SearchQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { DEFAULT_QUERY_STATE } from 'context/query/queryContextController/QueryContextController.variables';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';

import { OwnerDetails } from './ownerDetails/OwnerDetails';
import { OwnersProps } from './Owners.types';
import { OwnersTableHeader } from './ownersTableHeader/OwnersTableHeader';
import { OwnersTableRow } from './ownersTableRow/OwnersTableRow';
import { OwnersMultiActions } from './ownersMultiActions/OwnersMultiActions';
import { AddOwner } from './addOwner/AddOwner';

export const Owners = ({
  ownersResponse,
  isLoading,
  checkedOwners,
  setCheckedOwners,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setTableSearchQuery,
}: OwnersProps) => {
  const { selectedOwnerId, selectedOwner, setSelectedOwner, lastActiveSelectedOwnerId, lastArchivedSelectedOwnerId } =
    useSelectedOwner();
  const { formatMessage } = useLocale();
  const { isDrawerOpen } = useDrawer();
  const { isOwnersArchivedView } = useOwnerArchivedView();
  const { setPartialStoredQueries, storedQueries } = useLocaleStoreQuery();
  const { searchOption, setSelectedSearchOption } = useSelectedSearchOptions(
    isOwnersArchivedView ? SearchQueryKeysEnum.OWNERS_ARCHIVED : SearchQueryKeysEnum.OWNERS_ACTIVE,
    setTableSearchQuery,
    isOwnersArchivedView,
  );

  const isSelectedOwnerDetailsOpen =
    !!(selectedOwner && selectedOwnerId) &&
    (isOwnersArchivedView ? !!lastArchivedSelectedOwnerId : !!lastActiveSelectedOwnerId);
  const displayFullTable = !(isDrawerOpen && isSelectedOwnerDetailsOpen);

  const resetCheckedOwners = () => {
    setCheckedOwners([]);
  };

  const clearSelectedOwnerQueries = () => {
    const defaultQueryState = isOwnersArchivedView
      ? {
          [QueryKeyLocalStorageEnum.ARCHIVED_OWNER_HISTORY]: DEFAULT_QUERY_STATE.archivedOwnerHistory,
          [QueryKeyLocalStorageEnum.ARCHIVED_OWNER_GROUPS]: DEFAULT_QUERY_STATE.archivedOwnerGroups,
          [QueryKeyLocalStorageEnum.ARCHIVED_OWNER_PERMISSIONS_EFFECTIVE]:
            DEFAULT_QUERY_STATE.archivedOwnerPermissionsEffective,
          [QueryKeyLocalStorageEnum.ARCHIVED_OWNER_PERMISSIONS_INDIVIDUAL]:
            DEFAULT_QUERY_STATE.archivedOwnerPermissionsIndividual,
          [QueryKeyLocalStorageEnum.SELECTED_SEARCH_OPTIONS]: {
            ...storedQueries?.selectedSearchOptions,
            [SearchQueryKeysEnum.ARCHIVED_OWNER_HISTORY]: undefined,
            [SearchQueryKeysEnum.ARCHIVED_OWNER_PERMISSIONS_GROUP]: undefined,
          },
        }
      : {
          [QueryKeyLocalStorageEnum.ACTIVE_OWNER_HISTORY]: DEFAULT_QUERY_STATE.activeOwnerHistory,
          [QueryKeyLocalStorageEnum.ACTIVE_OWNER_GROUPS]: DEFAULT_QUERY_STATE.activeOwnerGroups,
          [QueryKeyLocalStorageEnum.ACTIVE_OWNER_PERMISSIONS_EFFECTIVE]:
            DEFAULT_QUERY_STATE.activeOwnerPermissionsEffective,
          [QueryKeyLocalStorageEnum.ACTIVE_OWNER_PERMISSIONS_INDIVIDUAL]:
            DEFAULT_QUERY_STATE.activeOwnerPermissionsIndividual,
          [QueryKeyLocalStorageEnum.SELECTED_SEARCH_OPTIONS]: {
            ...storedQueries?.selectedSearchOptions,
            [SearchQueryKeysEnum.ACTIVE_OWNER_HISTORY]: undefined,
            [SearchQueryKeysEnum.ACTIVE_OWNER_PERMISSIONS_GROUP]: undefined,
          },
        };

    setPartialStoredQueries(defaultQueryState);
  };

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['owners.title'] })}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="auto auto 1fr auto">
            <TopBar.Link linkTo={AppRoute.owners} onClick={resetCheckedOwners} isActive={!isOwnersArchivedView}>
              {formatMessage({ id: AppMessages['owners.active'] })}
            </TopBar.Link>

            <TopBar.Link linkTo={AppRoute.ownersArchived} onClick={resetCheckedOwners} isActive={isOwnersArchivedView}>
              {formatMessage({ id: AppMessages['owners.archived'] })}
            </TopBar.Link>

            <TopBar.Search>
              <TableSearchContainer
                searchIconTestId="page-search"
                inputPlaceholder={formatMessage({ id: AppMessages['owners.search'] })}
                searchBy="owners"
                setTableSearchQuery={setTableSearchQuery}
                selectedValue={searchOption}
                setSelectedValue={setSelectedSearchOption}
              />
            </TopBar.Search>

            <TopBar.ButtonsWrapper>
              <AddOwner />
            </TopBar.ButtonsWrapper>
          </TopBar>

          <Table
            isLoading={isLoading}
            gridTemplateColumns={
              displayFullTable
                ? 'minmax(140px, 1fr) minmax(190px, 1fr) 1fr 1fr minmax(100px, 0.7fr) minmax(140px, 0.7fr)'
                : '0.5fr 1fr 1fr 0.7fr 0.7fr'
            }
            data={ownersResponse?.data || []}
            nothingToShowText={formatMessage({ id: AppMessages['owners.nothingToShow'] })}
            onClickRow={(owner) => {
              clearSelectedOwnerQueries();
              setSelectedOwner(owner, true, true);
            }}
            activeRowId={selectedOwnerId}
            checkboxSelection={{
              checked: checkedOwners,
              toggleChecked: setCheckedOwners,
            }}
            renderMultiActions={() => (
              <ShowUp show={checkedOwners.length > 1}>
                <OwnersMultiActions checkedOwners={checkedOwners} setCheckedOwners={setCheckedOwners} />
              </ShowUp>
            )}
            pagination={{
              page,
              setPage,
              count: ownersResponse?.count || 0,
              rowsPerPage,
              setRowsPerPage,
            }}
            renderHeader={() => <OwnersTableHeader displayFullTable={displayFullTable} />}
            renderRow={(owner) => <OwnersTableRow owner={owner} displayFullTable={displayFullTable} />}
          />
        </>
      )}
      renderDetails={() => (
        <DetailsDrawer
          isOpen={isSelectedOwnerDetailsOpen}
          header={formatMessage({ id: AppMessages['owner.details.title'] })}
        >
          {selectedOwnerId && selectedOwner && <OwnerDetails selectedOwner={selectedOwner} />}
        </DetailsDrawer>
      )}
    />
  );
};
