import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const DetailsItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  margin-bottom: 24px;
`;

export const DetailName = styled(Typography)(
  ({ theme: { palette } }) => css`
    font-weight: 700;
    color: ${palette.text.secondary};
  `,
);

export const DetailText = styled(Typography)(
  ({ theme: { palette } }) => css`
    color: ${palette.grey[700]};
  `,
);
