import { useEffect } from 'react';
import { Path, PathValue, UseFormSetValue } from 'react-hook-form';

import { DEFAULT_PERMISSIONS } from 'core/global.variables';

export const useResetPermissionsFormValues = <T extends Record<keyof typeof DEFAULT_PERMISSIONS, string>>({
  selectedValues,
  setFormValue,
}: {
  selectedValues?: unknown[];
  setFormValue: UseFormSetValue<T>;
}) => {
  useEffect(() => {
    if (!selectedValues?.length) {
      Object.entries(DEFAULT_PERMISSIONS).forEach(([key, val]) => {
        setFormValue(key as Path<T>, val as PathValue<T, Path<T>>);
      });
    }
  }, [selectedValues]);
};
