import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useQueryClient } from 'shared-ui';

import { useModalState } from 'hooks/useModalState/useModalState';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import { fetchServers } from 'api/servers/servers';
import { fetchTerminals } from 'api/terminals/terminals';
import { fetchOwners } from 'api/owners/owners';
import { addControl, fetchOwnersControl } from 'api/control/control';
import {
  GroupTerminalsPermissionsIdSortEnum,
  OwnerRegistrationNumberSortEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { MultiTreeSelectGroupTypeEnum } from 'ui/organizationsTreeSelect/treeMultiSelect/groupedTreeMultiSelectBody/GroupedTreeMultiSelectBody.enum';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ControlTypeEnum } from 'api/control/control.enum';

import { AddControl } from './AddControl';
import { AddControlContainerProps, AddControlForm } from './AddControl.types';
import { useAddControlValidation } from './AddControl.validation';

export const AddControlContainer = ({ type }: AddControlContainerProps) => {
  const { isModalOpen, onModalClose, onModalOpen } = useModalState();
  const isRandomControl = type === ControlTypeEnum.LOSOWA;
  const { schema } = useAddControlValidation(isRandomControl);
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();

  const [selectedOwnersGroup, setSelectedOwnersGroup] = useState<MultiTreeSelectGroupTypeEnum>(
    MultiTreeSelectGroupTypeEnum.EXTRA,
  );

  const { data: ownersControlResponse, isLoading: isLoadingOwnersControl } = useQuery(
    [QueryKeyEnum.OWNERS_CONTROL],
    fetchOwnersControl,
  );

  const { notLessOftenThan, notMoreOftenThan } = ownersControlResponse || {};
  const isAddControlDisabled = isRandomControl && (!notLessOftenThan || !notMoreOftenThan);

  const formMethods = useForm({
    resolver: yupResolver(schema),
  });

  const { serwerId } = formMethods.watch();

  const { data: serversResponse } = useQuery([QueryKeyEnum.SERVERS], fetchServers);
  const servers = serversResponse?.data || [];

  const { data: terminalsResponse, isInitialLoading: isLoadingTerminals } = useQuery(
    [QueryKeyEnum.TERMINALS, serwerId, type],
    () => fetchTerminals(serwerId),
    {
      args: {
        zaakceptowane: true,
        sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
      },
      enabled: !!serwerId,
    },
  );
  const terminals = terminalsResponse?.data || [];

  const { data: ownersResponse } = useQuery([QueryKeyEnum.OWNERS_SEARCH, type], fetchOwners, {
    args: {
      offset: 0,
      limit: 100,
      sort: [OwnerRegistrationNumberSortEnum.ASC],
      ...(!isRandomControl && { karta: true, kontrolaNatychmiastowa: false }),
      ...(isRandomControl && { archiwalny: false }),
    },
  });
  const owners = ownersResponse?.data || [];

  const { mutate: onAddControl, isLoading: isLoadingAddControl } = useMutation(addControl, {
    onSuccess: (_, body) => {
      const messageType = isRandomControl ? 'random' : 'instant';
      const successMessage =
        body.idPkds?.length === 1
          ? (`control.${messageType}.addOwner.success` as const)
          : (`control.${messageType}.addOwner.success.multi` as const);

      if (isRandomControl) {
        queryClient.invalidateQueries([QueryKeyEnum.RANDOM_CONTROL_OWNERS]);
      } else {
        queryClient.invalidateQueries([QueryKeyEnum.INSTANT_CONTROL_OWNERS]);
      }

      toast.dark(formatMessage({ id: AppMessages[successMessage] }));
      queryClient.invalidateQueries([QueryKeyEnum.OWNERS_SEARCH, type]);

      onModalClose();
    },
  });

  const onSubmit = (body: AddControlForm) => {
    const selectedGroupKey = selectedOwnersGroup === MultiTreeSelectGroupTypeEnum.EXTRA ? 'idPkds' : 'idKomorek';

    onAddControl({
      [selectedGroupKey]: typeof body.ids === 'string' ? [body.ids] : body.ids?.map(({ id }) => id) || [],
      tkds: body.tkds?.map(({ value }) => value as string) || [],
      typ: type,
    });
  };

  useEffect(() => {
    servers.length && formMethods.reset({ serwerId: servers[0].id });
  }, [servers]);

  useEffect(() => {
    formMethods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...formMethods}>
      <AddControl
        servers={servers}
        terminals={terminals}
        owners={owners}
        isLoadingTerminals={isLoadingTerminals}
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        onSubmit={onSubmit}
        isLoadingAddControl={isLoadingAddControl}
        setSelectedOwnersGroup={setSelectedOwnersGroup}
        isLoadingOwnersControl={isRandomControl && isLoadingOwnersControl}
        isAddControlDisabled={isAddControlDisabled}
        isRandomControl={isRandomControl}
      />
    </FormProvider>
  );
};
