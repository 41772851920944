import { useParams } from 'react-router-dom';
import { PageLayout, TopBar, DetailsDrawer } from 'shared-ui';

import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute, AppRouteWithParams } from 'routing/AppRoute.enum';
import { OwnerDetails } from '../ownerDetails/OwnerDetails';
import { HistoryList } from 'ui/historyList/HistoryList';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';

import { BaseOwnerHistoryContainer } from './baseOwnerHistory/BaseOwnerHistoryContainer';
import { OwnerEffectivePermissionsHistoryContainer } from './ownerEffectivePermissionsHistory/OwnerEffectivePermissionsHistoryContainer';

export const OwnerHistory = () => {
  const { formatMessage } = useLocale();
  const { selectedOwner } = useSelectedOwner();
  const { id } = useParams() as { id: string };

  const isBaseOwnerHistoryView = useIsExactPath(AppRouteWithParams.ownerHistory.path);

  return (
    <PageLayout
      key={id}
      header={formatMessage({ id: AppMessages['common.history'] })}
      withBackButton={{
        onClick: (navigate) => navigate(AppRoute.owners),
      }}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="auto auto 1fr ">
            <TopBar.Link linkTo={AppRouteWithParams.ownerHistory.get({ id })} isActive={isBaseOwnerHistoryView}>
              {formatMessage({ id: AppMessages['owner.history.title'] })}
            </TopBar.Link>
            <TopBar.Link
              linkTo={AppRouteWithParams.ownerPermissionsEffectiveHistory.get({ id })}
              isActive={!isBaseOwnerHistoryView}
            >
              {formatMessage({ id: AppMessages['owner.history.effectivePermissions.title'] })}
            </TopBar.Link>
            <TopBar.Header />
          </TopBar>

          {isBaseOwnerHistoryView ? (
            <BaseOwnerHistoryContainer selectedOwner={selectedOwner} />
          ) : (
            <OwnerEffectivePermissionsHistoryContainer />
          )}
        </>
      )}
      renderDetails={() =>
        selectedOwner ? (
          <DetailsDrawer isOpen={!!selectedOwner} header={formatMessage({ id: AppMessages['owner.details.title'] })}>
            <OwnerDetails selectedOwner={selectedOwner} />
          </DetailsDrawer>
        ) : (
          <HistoryList.DetailsLoader />
        )
      }
    />
  );
};
