import { FileRejection } from 'react-dropzone';
import { DropzoneErrorCodes } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useOnDropRejected = (setFileError: (error: string) => void) => {
  const { formatMessage } = useLocale();

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    const errorCode = rejectedFiles[0].errors[0].code;

    if (errorCode === DropzoneErrorCodes.FILE_INVALID_TYPE) {
      setFileError(formatMessage({ id: AppMessages['owners.addOwner.import.error.invalidFileType'] }));
    }
    if (errorCode === DropzoneErrorCodes.TOO_MANY_FILES) {
      setFileError(formatMessage({ id: AppMessages['owners.addOwner.import.error.tooManyFiles'] }));
    }

    if (errorCode === DropzoneErrorCodes.FILE_TOO_LARGE) {
      setFileError(formatMessage({ id: AppMessages['owners.addOwner.import.error.invalidFileSize'] }));
    }
  };

  return { onDropRejected };
};
