import { useState } from 'react';

import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';

export const useAnnouncementsHistorySearchQuery = () => {
  const {
    storedQueries: { announcementsHistory: announcementsHistoryQuery },
  } = useLocaleStoreQuery();

  const [tkdSearchQuery, setTkdSearchQuery] = useState(announcementsHistoryQuery?.tkd?.label || '');
  const [executorSearchQuery, setExecutorSearchQuery] = useState(announcementsHistoryQuery?.query?.label || '');
  const [ownerSearchQuery, setOwnerSearchQuery] = useState(announcementsHistoryQuery?.posiadacz?.label || '');
  const [nameSearchQuery, setNameSearchQuery] = useState(announcementsHistoryQuery?.nazwa?.label || '');

  const clearSearchQueries = () => {
    setTkdSearchQuery('');
    setExecutorSearchQuery('');
    setOwnerSearchQuery('');
    setNameSearchQuery('');
  };

  return {
    tkdSearchQuery,
    setTkdSearchQuery,
    executorSearchQuery,
    setExecutorSearchQuery,
    ownerSearchQuery,
    setOwnerSearchQuery,
    nameSearchQuery,
    setNameSearchQuery,
    clearSearchQueries,
  };
};
