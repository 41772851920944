import { UserStatusDescriptionEnum, UserStatusEnum, UserTypeDescriptionEnum, UserTypeEnum } from 'api/users/users.enum';
import {
  UsersUsernameSortEnum,
  UsersFullNameSortEnum,
  UsersQueryKeysEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { StatusTableItem } from 'ui/statusTableItem/StatusTableItem';
import { Table } from 'ui/table/Table';

export const UsersTableHeader = () => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.USERS}
        sortBy={UsersUsernameSortEnum}
        queryHeader={formatMessage({ id: AppMessages['users.table.header.name'] })}
      />

      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.USERS}
        sortBy={UsersFullNameSortEnum}
        queryHeader={formatMessage({ id: AppMessages['users.table.header.fullName'] })}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.USERS}
        queryName={UsersQueryKeysEnum.TYP}
        filterItems={Object.values(UserTypeEnum).map((type) => ({
          name: formatMessage({ id: UserTypeDescriptionEnum[type] }),
          filterBy: type,
        }))}
        queryHeader={formatMessage({ id: AppMessages['users.table.header.type'] })}
        queryHeaderDescriptions={UserTypeDescriptionEnum}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.USERS}
        queryName={UsersQueryKeysEnum.STATUS}
        filterItems={Object.values(UserStatusEnum).map((status) => ({
          name: <StatusTableItem status={status} />,
          filterBy: status,
        }))}
        queryHeader={formatMessage({ id: AppMessages['users.table.header.status'] })}
        queryHeaderDescriptions={UserStatusDescriptionEnum}
      />
    </>
  );
};
