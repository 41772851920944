import { useContext } from 'react';

import { SelectedGroupGlcPermissionContext } from 'context/selectedGroupGlcPermission/selectedGroupGlcPermissionContext/SelectedGroupGlcPermissionContext';

export const useSelectedGroupGlcPermission = () => {
  const context = useContext(SelectedGroupGlcPermissionContext);

  if (context === undefined) {
    throw new Error(
      'SelectedGroupGlcPermissionContext is unavailable, make sure you are using SelectedGroupGlcPermissionContextController',
    );
  }

  return context;
};
