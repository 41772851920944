import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateRange } from '@mui/lab';
import { formatISO } from 'date-fns';
import { usePagination } from 'shared-ui';

import { fetchCardHistory } from 'api/cards/cards';
import {
  CardHistoryQueryKeysEnum,
  CardsHistorySearchByEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { HistoryActionEnum, QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { useQuery } from 'hooks/useQuery/useQuery';

import { CardHistory } from './CardHistory';

export const CardHistoryContainer = () => {
  const { id } = useParams<{ id: string }>();
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();
  const [tableSearchQuery, setTableSearchQuery] = useState('');
  const [tableSearchByQuery, setTableSearchByQuery] = useState<CardsHistorySearchByEnum>(CardsHistorySearchByEnum.PKD);
  const { selectedCard } = useSelectedCard();

  const cardHistoryQueryKey = selectedCard?.archiwalna
    ? QueryKeyLocalStorageEnum.ARCHIVED_CARD_HISTORY
    : QueryKeyLocalStorageEnum.ACTIVE_CARD_HISTORY;

  const {
    storedQueries: { [cardHistoryQueryKey]: cardHistoryQuery },
    setQuery: setCardHistoryQuery,
  } = useLocaleStoreQuery();

  const { data: cardHistoryResponse, isLoading: isLoadingCardHistory } = useQuery(
    [QueryKeyEnum.CARD_HISTORY, id, cardHistoryQuery, tableSearchQuery, tableSearchByQuery, page, rowsPerPage],
    () => fetchCardHistory(id),
    {
      args: {
        ...cardHistoryQuery,
        [CardHistoryQueryKeysEnum.LIMIT]: rowsPerPage,
        [CardHistoryQueryKeysEnum.OFFSET]: rowsPerPage * page,
        ...(tableSearchQuery
          ? { [CardHistoryQueryKeysEnum.QUERY]: tableSearchQuery, [CardHistoryQueryKeysEnum.BY]: tableSearchByQuery }
          : {}),
      },
    },
  );

  useEffect(() => {
    setPage(0);
  }, [tableSearchQuery, cardHistoryQuery]);

  const onClearCardHistoryQuery = () => {
    setCardHistoryQuery(cardHistoryQueryKey, {
      ...cardHistoryQuery,
      dataOd: undefined,
      dataDo: undefined,
      akcja: undefined,
      uzytkownik: undefined,
    });
    setTableSearchQuery('');
  };

  const handleSetDates = (dates: DateRange<Date>) => {
    setCardHistoryQuery(cardHistoryQueryKey, {
      ...cardHistoryQuery,
      dataOd: dates[0] ? formatISO(dates[0]) : undefined,
      dataDo: dates[1] ? formatISO(dates[1]) : undefined,
    });
  };

  const onSelectAction = (action: HistoryActionEnum | undefined) => {
    setCardHistoryQuery(cardHistoryQueryKey, {
      ...cardHistoryQuery,
      akcja: action,
    });
  };

  return (
    <CardHistory
      card={selectedCard}
      cardHistoryResponse={cardHistoryResponse}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      cardHistoryQuery={cardHistoryQuery}
      onSelectAction={onSelectAction}
      onClearCardHistoryQuery={onClearCardHistoryQuery}
      isLoadingCardHistory={isLoadingCardHistory}
      handleSetDates={handleSetDates}
      tableSearchQuery={tableSearchQuery}
      setTableSearchQuery={setTableSearchQuery}
      tableSearchByQuery={tableSearchByQuery}
      setTableSearchByQuery={setTableSearchByQuery}
    />
  );
};
