import { KeywordPosition } from '../KeywordsInput.types';

export const findKeywordBoundaries = (position: number, value: string, keywordNames: string[]) => {
  return keywordNames.reduce<KeywordPosition | null>((acc, keyword) => {
    if (acc) return acc;

    const findBoundary = (index: number): KeywordPosition | null => {
      if (index === -1) return null;

      const end = index + keyword.length;

      if (position >= index && position <= end) {
        return { word: keyword, start: index, end };
      }

      return findBoundary(value.indexOf(keyword, index + 1));
    };

    return findBoundary(value.indexOf(keyword));
  }, null);
};
