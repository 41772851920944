import { EditOwnerIndividualPermissionsContainer } from './editOwnerIndividualPermissions/EditOwnerIndividualPermissionsContainer';
import { OwnerIndividualPermissionMultiActionsProps } from './OwnerIndividualPermissionsMultiActions.types';
import { DeleteOwnerIndividualPermissionsContainer } from './deleteOwnerIndividualPermissions/DeleteOwnerIndividualPermissionsContainer';
import * as Styled from './OwnerIndividualPermissionsMultiActions.styles';

export const OwnerIndividualPermissionMultiActions = ({
  checkedPermissions,
  ownerId,
  setCheckedPermissions,
}: OwnerIndividualPermissionMultiActionsProps) => {
  return (
    <Styled.Container>
      <EditOwnerIndividualPermissionsContainer checkedPermissions={checkedPermissions} ownerId={ownerId} />
      <DeleteOwnerIndividualPermissionsContainer
        checkedPermissions={checkedPermissions}
        ownerId={ownerId}
        setCheckedPermissions={setCheckedPermissions}
      />
    </Styled.Container>
  );
};
