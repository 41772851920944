import { useParams } from 'react-router-dom';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { deleteGroupGlcPermission } from 'api/permissions/permissions';
import { useSelectedGroupGlcPermission } from 'hooks/useSelectedGroupGlcPermission/useSelectedGroupGlcPermission';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteGroupGlcPermission } from './DeleteGroupGlcPermission';
import { DeleteGroupGlcPermissionContainerProps } from './DeleteGroupGlcPermission.types';

export const DeleteGroupGlcPermissionContainer = ({ selectedPermission }: DeleteGroupGlcPermissionContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const groupId = id || '';
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { setSelectedGroupGlcPermission } = useSelectedGroupGlcPermission();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteGroupGlcPermission, isLoading } = useMutation(deleteGroupGlcPermission, {
    onSuccess: () => {
      setSelectedGroupGlcPermission(undefined);
      queryClient.invalidateQueries([QueryKeyEnum.GROUP_GLC_PERMISSIONS_SERVERS]);
      queryClient.invalidateQueries([QueryKeyEnum.GROUP_GLC_PERMISSIONS]);
      toast.dark(formatMessage({ id: AppMessages['group.reader.permission.delete.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteGroupGlcPermission({
      permissionId: selectedPermission.id,
      groupId,
    });
  };

  return (
    <DeleteGroupGlcPermission
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
