import { useState, forwardRef, useRef, KeyboardEvent } from 'react';
import { Autocomplete } from '@mui/material';
import { Popover } from 'shared-ui';

import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { Table } from 'ui/table/Table';
import { MultipleSelectProps, TreeOption, TreeSelectOption } from '../OrganizationsTreeSelect.types';

import * as Styled from './TreeMultiSelect.styles';
import { TreeMultiSelectBody } from './treeMultiSelectBody/TreeMultiSelectBody';
import { GroupedTreeMultiSelectBody } from './groupedTreeMultiSelectBody/GroupedTreeMultiSelectBody';

const TAGS_LIMIT = 1;

export const TreeMultiSelect = forwardRef<HTMLInputElement, MultipleSelectProps>(
  ({ options, value, onChange, label, placeholder, isFilterType, inputVariant, extraGroupProps, ...props }, ref) => {
    const [popoverEl, setPopoverEl] = useState<HTMLDivElement | undefined>(undefined);

    const autocompleteRef = useRef<HTMLInputElement | undefined>(undefined);

    const onClosePopover = () => setPopoverEl(undefined);

    const onEnterKeyDown = (e: KeyboardEvent, callbackFn: () => void) => {
      if (e.key === 'Enter') {
        callbackFn();
      }
    };

    const isOptionSelected = (optionId: string) =>
      value ? value.some((selectedOption) => selectedOption.id === optionId) : false;

    const onSelectItem = ({ children, ...option }: TreeOption) => {
      const optionsValue = value?.map(({ id, nazwa, kod }) => ({ id, nazwa, kod })) || [];

      if (isOptionSelected(option.id)) {
        onChange(optionsValue.filter((selectedOption) => selectedOption.id !== option.id));
        return;
      }
      onChange([...optionsValue, option]);
    };

    const renderTags = (selectedValues: TreeSelectOption[]) => {
      const sortedSelectedValues = selectedValues.sort((a, b) => {
        if (!a.kod || !b.kod) return 0;
        else if (a.kod > b.kod) return 1;
        else return -1;
      });

      return selectedValues.length === TAGS_LIMIT ? (
        <Styled.Tag>{sortedSelectedValues[0].nazwa}</Styled.Tag>
      ) : (
        <>
          <Styled.Tag>{selectedValues[0].nazwa}</Styled.Tag>
          <Styled.Tag>+{selectedValues.length - TAGS_LIMIT}</Styled.Tag>
        </>
      );
    };

    return (
      <>
        {isFilterType ? (
          <Table.HeaderItem>
            <Styled.CellHeaderItem $isActive={!!popoverEl} onClick={(e) => setPopoverEl(e.currentTarget)}>
              {placeholder}
              {!!value && !!value.length ? (
                <RemoveIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange([]);
                  }}
                />
              ) : (
                <DropdownIcon />
              )}
            </Styled.CellHeaderItem>
          </Table.HeaderItem>
        ) : (
          <Autocomplete
            data-testid={label}
            limitTags={value && value.length > TAGS_LIMIT ? 0 : TAGS_LIMIT}
            disableClearable
            options={value || []}
            getOptionLabel={(option) => option.nazwa}
            value={value || []}
            isOptionEqualToValue={(option, value) => option.nazwa === value.nazwa}
            renderTags={renderTags}
            ref={autocompleteRef}
            getLimitTagsText={(tagsCount) => tagsCount}
            {...props}
            renderInput={(params) => (
              <div onClick={(e) => !props.disabled && setPopoverEl(e.currentTarget)}>
                <Styled.Input
                  {...params}
                  required={props.required}
                  label={label}
                  variant={inputVariant || 'filled'}
                  InputLabelProps={{ shrink: false }}
                  InputProps={{ ...params.InputProps }}
                  placeholder={value && !!value.length ? '' : placeholder}
                  rightIcon={<Styled.SelectArrow />}
                  withoutHelperText
                  inputRef={ref}
                  onKeyDown={(e) => {
                    onEnterKeyDown(e, () => setPopoverEl(e.currentTarget));
                    if (e.key === 'Tab') return;

                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </div>
            )}
          />
        )}

        <Popover
          disableScrollLock
          anchorEl={popoverEl}
          onClose={onClosePopover}
          PaperProps={{
            style: {
              marginTop: isFilterType ? 8 : 0,
              width: autocompleteRef.current?.clientWidth,
              minWidth: isFilterType ? 325 : autocompleteRef.current?.clientWidth,
            },
          }}
        >
          {extraGroupProps ? (
            <GroupedTreeMultiSelectBody
              extraGroupProps={extraGroupProps}
              onSelectItem={onSelectItem}
              isOptionSelected={isOptionSelected}
              clearSelectedItems={() => onChange([])}
              renderOrganizationsGroupBody={() => (
                <TreeMultiSelectBody
                  options={options}
                  onSelectItem={onSelectItem}
                  value={value}
                  onClosePopover={onClosePopover}
                  isOptionSelected={isOptionSelected}
                />
              )}
            />
          ) : (
            <TreeMultiSelectBody
              options={options}
              onSelectItem={onSelectItem}
              value={value}
              onClosePopover={onClosePopover}
              isOptionSelected={isOptionSelected}
            />
          )}
        </Popover>
      </>
    );
  },
);
