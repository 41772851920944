import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { unblockMultiCards } from 'api/cards/cards';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { CardBlockTypeEnum } from 'api/cards/cards.enum';
import { useModalState } from 'hooks/useModalState/useModalState';

import { UnblockMultiCards } from './UnblockMultiCards';
import { UnblockMultiCardsContainerProps } from './UnblockMultiCards.types';

export const UnblockMultiCardsContainer = ({ checkedCards }: UnblockMultiCardsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedCard, setSelectedCard } = useSelectedCard();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onUnblockMultiCards, isLoading } = useMutation(unblockMultiCards, {
    onSuccess: () => {
      if (selectedCard && checkedCards.some(({ id }) => id === selectedCard?.id)) {
        setSelectedCard({ ...selectedCard, blokada: CardBlockTypeEnum.ODBLOKOWANA });
      }

      queryClient.invalidateQueries([QueryKeyEnum.CARDS]);
      toast.dark(formatMessage({ id: AppMessages['cards.multiAction.unblock.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onUnblockMultiCards({ body: checkedCards.map(({ id }) => id) });
  };

  return (
    <UnblockMultiCards
      checkedCards={checkedCards}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
