import { useEffect } from 'react';
import { useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { addOrganization } from 'api/organizations/organizations';
import { findAllByKey } from 'helpers/findAllByKey';
import { useMutation } from 'hooks/useMutation/useMutation';

import { AddOrganizationItem } from './AddOrganizationItem';
import { AddOrganizationItemFormBody, AddOrganizationItemContainerProps } from './AddOrganizationItem.types';
import { useAddOrganizationItemValidation } from './AddOrganizationItem.validation';

export const AddOrganizationItemContainer = ({
  organization,
  isModalOpen,
  onModalClose,
  mainOrganization,
}: AddOrganizationItemContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const allOrganizationCodesStandarized = (mainOrganization ? findAllByKey(mainOrganization, 'kod') : []).map((code) =>
    code.toLowerCase(),
  );

  const { schema } = useAddOrganizationItemValidation(allOrganizationCodesStandarized);

  const methods = useForm<AddOrganizationItemFormBody>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      kod: '',
      nazwa: '',
    },
  });

  const { mutate: onAddOrganization, isLoading } = useMutation(addOrganization, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.ORGANIZATIONS]);
      toast.dark(formatMessage({ id: AppMessages['definitions.organization.add.success'] }));
      onModalClose();
    },
  });

  const onSubmit = (body: AddOrganizationItemFormBody) => {
    onAddOrganization({ ...body, parent: organization.id });
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...methods}>
      <AddOrganizationItem
        onSubmit={onSubmit}
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        isLoading={isLoading}
      />
    </FormProvider>
  );
};
