import { useParams } from 'react-router-dom';

import { fetchGroupDetails } from 'api/groups/groups';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useSelectedGroup } from 'hooks/useSelectedGroup/useSelectedGroup';

import { GroupHistory } from './GroupHistory';

export const GroupHistoryContainer = () => {
  const { selectedGroup } = useSelectedGroup();
  const { id } = useParams() as { id: string };

  const { data: groupDetails, isInitialLoading: isLoadingGroupDetails } = useQuery(
    [QueryKeyEnum.GROUP_DETAILS, id],
    () => fetchGroupDetails(id),
    {
      enabled: !selectedGroup,
    },
  );

  return (
    <GroupHistory groupId={id} isLoadingGroupDetails={isLoadingGroupDetails} group={selectedGroup || groupDetails} />
  );
};
