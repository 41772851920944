import { PageLayout } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';
import { DefinitionsBar } from '../definitionsBar/DefinitionsBar';

import { EventsExportFormatsTableHeader } from './eventsExportFormatsTableHeader/EventsExportFormatsTableHeader';
import { EventsExportFormatsTableRow } from './eventsExportFormatsTableRow/EventsExportFormatsTableRow';
import { EventsExportFormatProps } from './EventsExportFormats.types';

export const EventsExportFormats = ({ eventsExportFormatsResponse, isLoading }: EventsExportFormatProps) => {
  const { formatMessage } = useLocale();

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['definitions.title'] })}
      renderContent={() => (
        <>
          <DefinitionsBar />

          <Table
            isLoading={isLoading}
            gridTemplateColumns="1fr 50px 50px"
            data={eventsExportFormatsResponse ?? []}
            nothingToShowText={formatMessage({ id: AppMessages['definitions.events.nothingToShow'] })}
            renderHeader={() => <EventsExportFormatsTableHeader />}
            renderRow={(eventExportFormat) => <EventsExportFormatsTableRow eventExportFormat={eventExportFormat} />}
          />
        </>
      )}
    />
  );
};
