import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { addServer } from 'api/servers/servers';
import { AddServerFormBody } from 'api/servers/servers.types';
import { MAX_AMOUNT_OF_SERVERS } from 'core/global.variables';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useMutation } from 'hooks/useMutation/useMutation';
import { AppMessages } from 'i18n/messages';
import { useModalState } from 'hooks/useModalState/useModalState';

import { AddServer } from './AddServer';
import { useAddServerValidation } from './AddServer.validation';
import { AddServerContainerProps } from './AddServer.types';

export const AddServerContainer = ({ servers }: AddServerContainerProps) => {
  const { formatMessage } = useLocale();
  const { schema } = useAddServerValidation(servers);
  const queryClient = useQueryClient();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onAddServer, isLoading } = useMutation(addServer, {
    onSuccess: () => {
      if (!!servers?.length && servers?.length + 1 === MAX_AMOUNT_OF_SERVERS) {
        toast.dark(formatMessage({ id: AppMessages['devices.addServer.max'] }));
        return;
      }

      queryClient.invalidateQueries([QueryKeyEnum.SERVERS]);
      toast.dark(formatMessage({ id: AppMessages['devices.addServer.success'] }));
      onModalClose();
    },
  });

  const onSubmit = (body: AddServerFormBody) => {
    onAddServer({ body: { ...body, port: Number(body.port), timeout: Number(body.timeout) } });
  };

  const methods = useForm<AddServerFormBody>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  useEffect(() => {
    methods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...methods}>
      <AddServer
        isLoading={isLoading}
        onSubmit={onSubmit}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        isModalOpen={isModalOpen}
        servers={servers}
      />
    </FormProvider>
  );
};
