import { useFormContext, Controller } from 'react-hook-form';
import { Radio } from '@mui/material';
import { Button, Input } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { SingleOrManyDescriptionEnum, SingleOrManyEnum } from '../AddCard.enum';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { Form } from 'ui/form/Form';

import { AddCardFormBody, AddCardFormProps } from './AddCardForm.types';
import * as Styled from './AddCardForm.styles';

export const AddCardForm = ({
  onSubmit,
  onModalClose,
  isFileLoaded,
  onSubmitAddCardFromFile,
  isLoadingAddCard,
  isLoadingAddManyCards,
  isSingleCardAdd,
  isFileValidationError,
}: AddCardFormProps) => {
  const { formatMessage } = useLocale();

  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useFormContext<AddCardFormBody>();

  const watchSingleOrMany = watch('singleOrMany');

  const { cardTypeOptions, cardBlockedOptions } = useAppSelectOptions();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="singleOrMany"
        control={control}
        render={({ field: { value } }) => (
          <Styled.RadioGroup
            row
            value={value}
            onChange={(e) => {
              reset({
                ...getValues(),
                singleOrMany: e.target.value as SingleOrManyEnum,
                numerKarty: undefined,
                numerOd: undefined,
                numerDo: undefined,
              });
            }}
          >
            <Styled.ControlLabel
              value={SingleOrManyEnum.SINGLE}
              control={<Radio color="primary" />}
              label={formatMessage({ id: AppMessages[SingleOrManyDescriptionEnum.SINGLE] })}
              disabled={isFileLoaded}
            />
            <Styled.ControlLabel
              value={SingleOrManyEnum.MANY}
              control={<Radio color="primary" />}
              label={formatMessage({ id: AppMessages[SingleOrManyDescriptionEnum.MANY] })}
              disabled={isFileLoaded || !!isSingleCardAdd}
            />
          </Styled.RadioGroup>
        )}
      />

      <Styled.FormGrid isFileLoaded={isFileLoaded}>
        {watchSingleOrMany === SingleOrManyEnum.SINGLE ? (
          <Styled.CardNumberInputWrapper $isFullTextWidth>
            <Input
              {...register('numerKarty')}
              label={formatMessage({ id: AppMessages['cards.addCard.label.cardNumber'] })}
              placeholder={formatMessage({ id: AppMessages['cards.addCard.placeholder.cardNumber'] })}
              type="number"
              errorMessage={errors?.numerKarty?.message}
              required
              disabled={isFileLoaded}
            />
          </Styled.CardNumberInputWrapper>
        ) : (
          <>
            <Controller
              name="numerOd"
              render={({ field }) => (
                <Styled.CardNumberInputWrapper>
                  <Input
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      trigger('numerDo');
                    }}
                    label={formatMessage({ id: AppMessages['cards.addCard.label.numberFrom'] })}
                    placeholder={formatMessage({ id: AppMessages['cards.addCard.placeholder.numberFrom'] })}
                    type="number"
                    errorMessage={errors?.numerOd?.message}
                    required
                    disabled={isFileLoaded}
                    helperText={formatMessage({ id: AppMessages['cards.addCard.many.maxCards'] })}
                  />
                </Styled.CardNumberInputWrapper>
              )}
            />

            <Controller
              name="numerDo"
              render={({ field }) => (
                <Styled.CardNumberToInputWrapper>
                  <Input
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      trigger('numerOd');
                    }}
                    label={formatMessage({ id: AppMessages['cards.addCard.label.numberTo'] })}
                    placeholder={formatMessage({ id: AppMessages['cards.addCard.placeholder.numberTo'] })}
                    type="number"
                    errorMessage={errors?.numerDo?.message}
                    required
                    disabled={isFileLoaded}
                  />
                </Styled.CardNumberToInputWrapper>
              )}
            />
          </>
        )}

        <Styled.CardNameInputWrapper>
          <Input
            {...register('nazwa')}
            label={formatMessage({ id: AppMessages['cards.addCard.label.name'] })}
            placeholder={formatMessage({ id: AppMessages['cards.addCard.placeholder.name'] })}
            disabled={isFileLoaded}
            errorMessage={errors?.nazwa?.message}
          />
        </Styled.CardNameInputWrapper>

        <Styled.CardTypeInputWrapper>
          <Form.ControlledSelect
            name="typKarty"
            control={control}
            label={formatMessage({ id: AppMessages['cards.addCard.label.cardType'] })}
            required
            disabled={isFileLoaded}
            options={cardTypeOptions}
          />
        </Styled.CardTypeInputWrapper>

        <Styled.CardBlockedInputWrapper>
          <Form.ControlledSelect
            name="zablokowana"
            control={control}
            label={formatMessage({ id: AppMessages['cards.addCard.label.blocked'] })}
            required
            disabled={isFileLoaded}
            options={cardBlockedOptions}
          />
        </Styled.CardBlockedInputWrapper>

        <Styled.CardDescriptionInputWrapper>
          <Input
            {...register('opis')}
            label={formatMessage({ id: AppMessages['cards.addCard.label.description'] })}
            placeholder={formatMessage({ id: AppMessages['cards.addCard.placeholder.description'] })}
            disabled={isFileLoaded}
            errorMessage={errors?.opis?.message}
          />
        </Styled.CardDescriptionInputWrapper>
      </Styled.FormGrid>

      <Styled.ButtonsWrapper>
        <Button color="primary" onClick={onModalClose} size="small">
          {formatMessage({ id: AppMessages['common.cancel'] })}
        </Button>
        <Button
          isLoading={isLoadingAddCard || isLoadingAddManyCards}
          variant="contained"
          color="primary"
          type={isFileLoaded ? undefined : 'submit'}
          size="small"
          disabled={isFileLoaded ? isFileValidationError : !isValid}
          onClick={() => isFileLoaded && onSubmitAddCardFromFile()}
        >
          {formatMessage({ id: AppMessages['common.add'] })}
        </Button>
      </Styled.ButtonsWrapper>
    </form>
  );
};
