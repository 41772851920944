import { TerminalStatusEnum } from 'api/terminals/terminals.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionButton } from 'ui/actionButton/ActionButton';

import { TerminalDetailsProps } from './TerminalDetails.types';
import * as Styled from './TerminalDetails.styles';

export const TerminalDetails = ({
  selectedTerminal,
  onAcceptTerminal,
  onReloadTerminal,
  terminalActionDisabled,
}: TerminalDetailsProps) => {
  const { formatMessage } = useLocale();

  const showReloadTerminalButton =
    selectedTerminal.status === TerminalStatusEnum.PODMIENIONY || selectedTerminal.status === TerminalStatusEnum.OK;

  const showAcceptTerminalButton = selectedTerminal.status === TerminalStatusEnum.NOWY;

  return (
    <>
      {showReloadTerminalButton && (
        <Styled.ActionButtonWrapper>
          <ActionButton
            type="reload"
            onClick={() => onReloadTerminal(selectedTerminal.id)}
            disabled={terminalActionDisabled}
          />
        </Styled.ActionButtonWrapper>
      )}

      {showAcceptTerminalButton && (
        <Styled.ActionButtonWrapper>
          <ActionButton
            type="accept"
            onClick={() => onAcceptTerminal(selectedTerminal.id)}
            disabled={terminalActionDisabled}
          />
        </Styled.ActionButtonWrapper>
      )}

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['devices.readers.details.id'] })}</Styled.DetailName>
        <Styled.DetailText> {selectedTerminal?.numerLogicznyKontrolera}</Styled.DetailText>
      </Styled.DetailsItemWrapper>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['devices.readers.details.name'] })}</Styled.DetailName>
        <Styled.DetailText> {selectedTerminal?.nazwa} </Styled.DetailText>
      </Styled.DetailsItemWrapper>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>
          {formatMessage({ id: AppMessages['devices.readers.details.serialNumber'] })}
        </Styled.DetailName>
        <Styled.DetailText> {selectedTerminal.numerSeryjny} </Styled.DetailText>
      </Styled.DetailsItemWrapper>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['devices.readers.details.version'] })}</Styled.DetailName>
        <Styled.DetailText> {selectedTerminal.wersja} </Styled.DetailText>
      </Styled.DetailsItemWrapper>

      <div>
        <Styled.DetailName>
          {formatMessage({ id: AppMessages['devices.readers.details.description'] })}
        </Styled.DetailName>
        <Styled.DescriptionText> {selectedTerminal.opis}</Styled.DescriptionText>
      </div>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>
          {formatMessage({ id: AppMessages['devices.readers.details.deviceName'] })}
        </Styled.DetailName>
        <Styled.DetailText> {selectedTerminal.nazwaUrzadzenia} </Styled.DetailText>
      </Styled.DetailsItemWrapper>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['devices.readers.details.mod'] })}</Styled.DetailName>
        <Styled.DetailTextStrong>
          {selectedTerminal.mod
            ? formatMessage({ id: AppMessages['devices.readers.details.mod.exit'] })
            : formatMessage({ id: AppMessages['devices.readers.details.mod.entry'] })}
        </Styled.DetailTextStrong>
      </Styled.DetailsItemWrapper>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['devices.readers.details.mode'] })}</Styled.DetailName>
        <Styled.DetailTextStrong>{selectedTerminal.tryb}</Styled.DetailTextStrong>
      </Styled.DetailsItemWrapper>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['devices.readers.details.type'] })}</Styled.DetailName>
        <Styled.DetailTextStrong> {selectedTerminal.typ} </Styled.DetailTextStrong>
      </Styled.DetailsItemWrapper>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>
          {formatMessage({ id: AppMessages['devices.readers.details.numberOfChannels'] })}
        </Styled.DetailName>
        <div>
          <Styled.DetailTextWithBackground> {selectedTerminal.iloscKanalow} </Styled.DetailTextWithBackground>
        </div>
      </Styled.DetailsItemWrapper>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['devices.readers.details.zone'] })}</Styled.DetailName>
        <div>
          <Styled.DetailTextWithBackground> {selectedTerminal.strefa} </Styled.DetailTextWithBackground>
        </div>
      </Styled.DetailsItemWrapper>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['devices.readers.details.license'] })}</Styled.DetailName>
        <Styled.DetailText> {selectedTerminal.licencja} </Styled.DetailText>
      </Styled.DetailsItemWrapper>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>
          {formatMessage({ id: AppMessages['devices.readers.details.numberOfRegistrations'] })}
        </Styled.DetailName>
        <div>
          <Styled.DetailTextWithBackground> {selectedTerminal.iloscRejestracji} </Styled.DetailTextWithBackground>
        </div>
      </Styled.DetailsItemWrapper>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['devices.readers.details.display'] })}</Styled.DetailName>
        <Styled.DetailTextStrong>
          {selectedTerminal.wyswietlacz
            ? formatMessage({ id: AppMessages['common.yes'] })
            : formatMessage({ id: AppMessages['common.no'] })}
        </Styled.DetailTextStrong>
      </Styled.DetailsItemWrapper>
    </>
  );
};
