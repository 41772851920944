import { useState } from 'react';

export const useModalState = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onModalClose = () => setIsModalOpen(false);
  const onModalOpen = () => setIsModalOpen(true);

  return { isModalOpen, onModalClose, onModalOpen };
};
