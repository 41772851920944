import { useContext } from 'react';

import { SelectedPositionContext } from 'context/selectedPosition/selectedPositionContext/SelectedPositionContext';

export const useSelectedPosition = () => {
  const context = useContext(SelectedPositionContext);

  if (context === undefined) {
    throw new Error(
      'SelectedPositionContext is unavailable, make sure you are using SelectedPositionContextController',
    );
  }

  return context;
};
