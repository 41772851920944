import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useAddEventValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    posiadacz: yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] })),
    numerKarty: yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] })),
    data: yup
      .date()
      .typeError(formatMessage({ id: AppMessages['events.addEvent.validation.date.invalidDate'] }))
      .max(new Date(), formatMessage({ id: AppMessages['events.addEvent.validation.date.dateOutOfRange'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
  });

  return { schema };
};
