import {
  OwnerIdSortEnum,
  OwnerNameSortEnum,
  OwnerRegistrationNumberSortEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';

import { PositionAssignedOwnersHeaderItem } from './positionOwnersHeaderItem/PositionAssignedOwnersHeaderItem';

export const AnnouncementAssignedOwnersTableHeader = () => {
  const { formatMessage } = useLocale();

  const {
    storedQueries: { [QueryKeyLocalStorageEnum.ANNOUNCEMENT_ASSIGNED_OWNERS]: announcementAssignedOwnersQuery },
    setQuery,
  } = useLocaleStoreQuery();

  return (
    <>
      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.ANNOUNCEMENT_ASSIGNED_OWNERS}
        sortBy={OwnerIdSortEnum}
        queryHeader={formatMessage({ id: AppMessages['owners.header.id'] })}
      />

      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.ANNOUNCEMENT_ASSIGNED_OWNERS}
        sortBy={OwnerNameSortEnum}
        queryHeader={formatMessage({ id: AppMessages['owners.header.fullName'] })}
      />

      <OrganizationsTreeSelect
        multiple
        isFilterType
        onChange={(options) =>
          options &&
          setQuery(QueryKeyLocalStorageEnum.ANNOUNCEMENT_ASSIGNED_OWNERS, {
            ...announcementAssignedOwnersQuery,
            komorka: options.map((cell) => cell.id),
          })
        }
        value={announcementAssignedOwnersQuery.komorka?.map((id) => ({ id, nazwa: 'komorka' }))}
        placeholder={formatMessage({ id: AppMessages['owners.header.cell'] })}
      />

      <PositionAssignedOwnersHeaderItem>
        {formatMessage({ id: AppMessages['owners.header.position'] })}
      </PositionAssignedOwnersHeaderItem>

      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.ANNOUNCEMENT_ASSIGNED_OWNERS}
        sortBy={OwnerRegistrationNumberSortEnum}
        queryHeader={formatMessage({ id: AppMessages['owners.header.registrationNumber'] })}
      />
    </>
  );
};
