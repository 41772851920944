import { GroupTypeEnum } from 'api/groups/groups.enum';
import { useGroupsArchivedView } from 'hooks/useGroupsArchivedView/useGroupsArchivedView';

import { AddMultiCellsContainer } from './addMultiCells/AddMultiCellsContainer';
import { AddMultiOwnersContainer } from './addMultiOwners/AddMultiOwnersContainer';
import { AddMultiGroupPermissionsContainer } from './addMultiGroupPermissions/AddMultiGroupPermissionsContainer';
import { ArchiveMultiGroupsContainer } from './archiveMultiGroups/ArchiveMultiGroupsContainer';
import { UnarchiveMultiGroupsContainer } from './unarchiveMultiGroups/UnarchiveMultiGroupsContainer';
import { GroupsMultiActionsProps } from './GroupsMultiActions.types';
import * as Styled from './GroupsMultiActions.styles';

export const GroupsMultiActions = ({ checkedGroups, setCheckedGroups }: GroupsMultiActionsProps) => {
  const { isGroupsArchivedView } = useGroupsArchivedView();
  const isAddMultiCellsActionEnable = checkedGroups.every(({ typ }) => typ === GroupTypeEnum.DYNAMICZNA);
  const isAddMultiGroupPermissionsActionEnable =
    checkedGroups.every(({ glc }) => glc) || checkedGroups.every(({ glc }) => !glc);
  const isAddMultiOwnersActionEnable = checkedGroups.every(({ typ }) => typ === GroupTypeEnum.STATYCZNA);
  const isArchiveMultiGroupsActionEnable = checkedGroups.every((group) => !group.archiwalna && !group.posiadacz);
  const isUnarchiveMultiGroupsActionEnable = checkedGroups.every((group) => group.archiwalna);

  return (
    <Styled.Container>
      {!isGroupsArchivedView && (
        <>
          {isAddMultiCellsActionEnable && <AddMultiCellsContainer checkedGroups={checkedGroups} />}
          {isAddMultiOwnersActionEnable && <AddMultiOwnersContainer checkedGroups={checkedGroups} />}
          {isArchiveMultiGroupsActionEnable && (
            <ArchiveMultiGroupsContainer checkedGroups={checkedGroups} setCheckedGroups={setCheckedGroups} />
          )}

          {isAddMultiGroupPermissionsActionEnable && (
            <AddMultiGroupPermissionsContainer checkedGroups={checkedGroups} />
          )}
        </>
      )}
      {isUnarchiveMultiGroupsActionEnable && (
        <UnarchiveMultiGroupsContainer checkedGroups={checkedGroups} setCheckedGroups={setCheckedGroups} />
      )}
    </Styled.Container>
  );
};
