import { DeleteMultiGroupAssignedOwnersContainer } from './deleteMultiGroupAssignedOwners/DeleteMultiGroupAssignedOwnersContainer';
import { GroupAssignedOwnersMultiActionsProps } from './GroupAssignedOwnersMultiActions.types';
import * as Styled from './GroupAssignedOwnersMultiActions.styles';

export const GroupAssignedOwnersMultiActions = ({
  checkedOwners,
  setCheckedOwners,
}: GroupAssignedOwnersMultiActionsProps) => {
  return (
    <Styled.Container>
      <DeleteMultiGroupAssignedOwnersContainer checkedOwners={checkedOwners} setCheckedOwners={setCheckedOwners} />
    </Styled.Container>
  );
};
