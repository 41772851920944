import * as yup from 'yup';
import { isValid } from 'date-fns';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useOwnerAssignValidation = (
  minDate: Date,
  isCardBlockedManually: boolean,
  isFormOwnerArchived: boolean,
) => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape(
    {
      pkd: yup.object().required(),
      waznoscOd:
        isCardBlockedManually || isFormOwnerArchived
          ? yup.date().nullable()
          : yup
              .date()
              .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }))
              .min(minDate, formatMessage({ id: AppMessages['common.validation.date.past'] }))
              .when('waznoscDo', (validTo) => {
                if (validTo && isValid(validTo)) {
                  return yup
                    .date()
                    .min(minDate, formatMessage({ id: AppMessages['common.validation.date.past'] }))
                    .max(validTo, formatMessage({ id: AppMessages['common.validation.date.invalidRange'] }))
                    .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }));
                }
                return yup
                  .date()
                  .min(minDate, formatMessage({ id: AppMessages['common.validation.date.past'] }))
                  .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }));
              })
              .required(formatMessage({ id: AppMessages['common.validation.required'] })),
      waznoscDo: yup.date().when('waznoscOd', (validFrom) => {
        if (validFrom && isValid(validFrom)) {
          return yup
            .date()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .min(validFrom, formatMessage({ id: AppMessages['common.validation.date.invalidRange'] }))
            .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }));
        }
        return yup.date().optional();
      }),
      pin: yup
        .string()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .nullable()
        .optional()
        .test('length', formatMessage({ id: AppMessages['card.assign.pin.validation.format'] }), (val) => {
          if (!val?.length) return true;

          return val.length === 4;
        }),
      pinConfirm: yup.string().when('pin', (pin) => {
        return pin
          ? yup
              .string()
              .required(formatMessage({ id: AppMessages['card.assign.pin.validation.required'] }))
              .oneOf(
                [yup.ref('pin'), null],
                formatMessage({ id: AppMessages['card.assign.pin.validation.comparision'] }),
              )
          : yup.string().optional();
      }),
    },
    [['waznoscOd', 'waznoscDo']],
  );

  return { schema };
};
