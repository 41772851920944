import { useEffect } from 'react';
import { PageLayout, TopBar, DetailsDrawer, ShowUp } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';
import { useSelectedGroup } from 'hooks/useSelectedGroup/useSelectedGroup';
import { QueryKeyLocalStorageEnum, SearchQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useSelectedSearchOptions } from 'hooks/useSelectedSearchOptions/useSelectedSearchOptions';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { DEFAULT_QUERY_STATE } from 'context/query/queryContextController/QueryContextController.variables';
import { AppRoute } from 'routing/AppRoute.enum';
import { useGroupsArchivedView } from 'hooks/useGroupsArchivedView/useGroupsArchivedView';
import { useSelectedGroupGlcPermission } from 'hooks/useSelectedGroupGlcPermission/useSelectedGroupGlcPermission';
import { useSelectedGroupTerminalPermission } from 'hooks/useSelectedGroupTerminalPermission/useSelectedGroupTerminalPermission';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';

import { GroupsMultiActions } from './groupsMultiActions/GroupsMultiActions';
import { AddGroupContainer } from './addGroup/AddGroupContainer';
import { GroupsTableHeader } from './groupsTableHeader/GroupsTableHeader';
import { GroupsTableRow } from './groupsTableRow/GroupsTableRow';
import { GroupsProps } from './Groups.types';
import { GroupDetailsContainer } from './groupDetails/GroupDetailsContainer';

export const Groups = ({
  groupsResponse,
  isLoadingGroups,
  checkedGroups,
  setCheckedGroups,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setGroupsTableSearchQuery,
  withBackButton,
}: GroupsProps) => {
  const { formatMessage } = useLocale();
  const { selectedGroup, setSelectedGroup, selectedGroupId, lastActiveSelectedGroupId, lastArchivedSelectedGroupId } =
    useSelectedGroup();
  const { setPartialStoredQueries } = useLocaleStoreQuery();
  const { isGroupsArchivedView } = useGroupsArchivedView();
  const { searchOption, setSelectedSearchOption } = useSelectedSearchOptions(
    isGroupsArchivedView ? SearchQueryKeysEnum.GROUPS_ARCHIVED : SearchQueryKeysEnum.GROUPS_ACTIVE,
    setGroupsTableSearchQuery,
    isGroupsArchivedView,
  );
  const { setSelectedGroupTerminalPermission } = useSelectedGroupTerminalPermission();
  const { setSelectedGroupGlcPermission } = useSelectedGroupGlcPermission();

  useEffect(() => {
    if (selectedGroup && groupsResponse?.data) {
      const updatedCard = groupsResponse.data.find((card) => selectedGroup.id === card.id);

      updatedCard && setSelectedGroup(updatedCard);
    }
  }, [groupsResponse?.data]);

  const clearSelectedGroupQueries = () => {
    setSelectedGroupGlcPermission(undefined);
    setSelectedGroupTerminalPermission(undefined);
    setPartialStoredQueries({
      [QueryKeyLocalStorageEnum.GROUP_ASSIGNED_OWNERS]: DEFAULT_QUERY_STATE.groupAssignedOwners,
      [QueryKeyLocalStorageEnum.GROUP_TERMINALS_PERMISSIONS]: DEFAULT_QUERY_STATE.groupTerminalsPermissions,
      [QueryKeyLocalStorageEnum.GROUP_GLC_PERMISSIONS]: DEFAULT_QUERY_STATE.groupGlcPermissions,
      [QueryKeyLocalStorageEnum.GROUP_HISTORY]: DEFAULT_QUERY_STATE.groupHistory,
      [QueryKeyLocalStorageEnum.GROUP_PERMISSIONS_HISTORY]: DEFAULT_QUERY_STATE.groupPermissionsHistory,
    });
  };

  const resetCheckedGroups = () => {
    setCheckedGroups([]);
  };

  return (
    <PageLayout
      withBackButton={withBackButton}
      header={formatMessage({ id: AppMessages['groups.title'] })}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="auto auto 1fr auto">
            <TopBar.Link linkTo={AppRoute.groups} onClick={resetCheckedGroups} isActive={!isGroupsArchivedView}>
              {formatMessage({ id: AppMessages['groups.active'] })}
            </TopBar.Link>

            <TopBar.Link linkTo={AppRoute.groupsArchived} onClick={resetCheckedGroups} isActive={isGroupsArchivedView}>
              {formatMessage({ id: AppMessages['groups.archived'] })}
            </TopBar.Link>

            <TopBar.Search>
              <TableSearchContainer
                searchIconTestId="page-search"
                inputPlaceholder={formatMessage({ id: AppMessages['groups.search'] })}
                searchBy="groups"
                setTableSearchQuery={setGroupsTableSearchQuery}
                selectedValue={searchOption}
                setSelectedValue={setSelectedSearchOption}
              />
            </TopBar.Search>

            <TopBar.ButtonsWrapper>
              <AddGroupContainer />
            </TopBar.ButtonsWrapper>
          </TopBar>

          <Table
            isLoading={isLoadingGroups}
            renderMultiActions={() => (
              <ShowUp show={checkedGroups.length > 1}>
                <GroupsMultiActions setCheckedGroups={setCheckedGroups} checkedGroups={checkedGroups} />
              </ShowUp>
            )}
            pagination={{
              page,
              setPage,
              count: groupsResponse?.count || 0,
              rowsPerPage,
              setRowsPerPage,
            }}
            testIdKey="idGrupy"
            gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr"
            data={groupsResponse?.data || []}
            nothingToShowText={formatMessage({ id: AppMessages['groups.nothingToShow'] })}
            activeRowId={selectedGroupId}
            onClickRow={(group) => {
              clearSelectedGroupQueries();
              setSelectedGroup(selectedGroup?.id === group.id ? undefined : group);
            }}
            checkboxSelection={{
              checked: checkedGroups,
              toggleChecked: setCheckedGroups,
            }}
            renderHeader={() => <GroupsTableHeader />}
            renderRow={(group) => <GroupsTableRow group={group} />}
          />
        </>
      )}
      renderDetails={() => (
        <DetailsDrawer
          isOpen={
            !!selectedGroupId &&
            !!selectedGroup &&
            (isGroupsArchivedView ? !!lastArchivedSelectedGroupId : !!lastActiveSelectedGroupId)
          }
          header={formatMessage({ id: AppMessages['event.details.title'] })}
        >
          {selectedGroupId && selectedGroup && <GroupDetailsContainer selectedGroup={selectedGroup} />}
        </DetailsDrawer>
      )}
    />
  );
};
