import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout, ShowUp, TopBar, Pagination, DetailsDrawer } from 'shared-ui';

import { GroupPermission } from 'api/permissions/permissions.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ExpandableList } from 'ui/expandableList/ExpandableList';
import { findServerToExpand } from 'helpers/findServerToExpand';
import { AppRoute, AppRouteParams } from 'routing/AppRoute.enum';
import { useSelectedGroupGlcPermission } from 'hooks/useSelectedGroupGlcPermission/useSelectedGroupGlcPermission';

import { AddGroupGlcPermissionContainer } from './addGroupGlcPermission/AddGroupGlcPermissionContainer';
import { GroupGlcPermissionMultiActions } from './groupGlcPermissionsMultiActions/GroupGlcPermissionsMultiActions';
import { GroupGlcPermissionDetailsContainer } from './groupGlcPermissionDetails/GroupGlcPermissionDetailsContainer';
import { GroupGlcContainer } from './groupGlc/GroupGlcContainer';
import { GroupGlcPermissionsProps } from './GroupGlcPermissions.types';

export const GroupGlcPermissions = ({
  servers,
  isLoading,
  permissionsCount,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  groupName,
  setPermissionsCount,
}: GroupGlcPermissionsProps) => {
  const { formatMessage } = useLocale();
  const { selectedGroupGlcPermission } = useSelectedGroupGlcPermission();
  const { id } = useParams<AppRouteParams['groupGlcPermissions']>();
  const groupId = id || '';

  const [checkedPermissions, setCheckedPermissions] = useState<GroupPermission[]>([]);

  return (
    <PageLayout
      header={`${formatMessage({ id: AppMessages['group.readers.permissions.title'] })} ${groupName}`}
      withBackButton={{
        onClick: (navigate) => navigate(AppRoute.groups),
      }}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="1fr">
            <TopBar.ButtonsWrapper>
              <AddGroupGlcPermissionContainer groupId={groupId} />
            </TopBar.ButtonsWrapper>
          </TopBar>

          <ShowUp show={checkedPermissions.length > 1}>
            <GroupGlcPermissionMultiActions
              groupId={groupId}
              checkedPermissions={checkedPermissions}
              setCheckedPermissions={setCheckedPermissions}
            />
          </ShowUp>

          <Pagination
            page={page}
            setPage={setPage}
            count={permissionsCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />

          <ExpandableList
            data={servers || []}
            isLoading={isLoading}
            nothingToShowText={formatMessage({ id: AppMessages['group.readers.permissions.nothingToShow'] })}
            title={(server) => server.nazwa}
            initiallyExpandedItem={findServerToExpand(servers || [])}
            toggleItemExpansion={(expandedServer) => {
              setPage(0);
              setCheckedPermissions([]);
              if (!expandedServer) setPermissionsCount(0);
            }}
            renderExpandableItemBody={(server, expandedServer) => (
              <GroupGlcContainer
                key={server.id}
                server={server}
                expandedServer={expandedServer}
                checkedPermissions={checkedPermissions}
                setCheckedPermissions={setCheckedPermissions}
                setPermissionsCount={setPermissionsCount}
              />
            )}
          />
        </>
      )}
      renderDetails={() => (
        <DetailsDrawer
          isOpen={!!selectedGroupGlcPermission}
          header={formatMessage({ id: AppMessages['group.reader.permission.details.title'] })}
        >
          {selectedGroupGlcPermission && (
            <GroupGlcPermissionDetailsContainer groupId={groupId} selectedPermission={selectedGroupGlcPermission} />
          )}
        </DetailsDrawer>
      )}
    />
  );
};
