import { Controller, useFormContext } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';
import { Input } from 'ui/input/Input';
import { AppMessages } from 'i18n/messages';
import { Form } from 'ui/form/Form';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';

import { SettingsFormProps } from './SettingsForm.types';

export const SettingsForm = ({ eventsExportFormatConfig, isPreviewPageMode }: SettingsFormProps) => {
  const { formatMessage } = useLocale();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { separatorOptions, endlineOptions } = useAppSelectOptions({ eventsExportFormatConfig });

  return (
    <>
      <Controller
        name="nazwa"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            errorMessage={errors?.nazwa?.message as string}
            label={formatMessage({
              id: AppMessages['definitions.events.addExportFormat.settings.formatName'],
            })}
            placeholder="Nadaj nazwę formatu"
            required={!isPreviewPageMode}
            disabled={isPreviewPageMode}
          />
        )}
      />

      <Form.ControlledSelect
        name="separator"
        control={control}
        options={separatorOptions}
        label={formatMessage({
          id: AppMessages['definitions.events.addExportFormat.settings.separator'],
        })}
        disabled={isPreviewPageMode}
        withHelperText
        required
      />

      <Form.ControlledSelect
        name="endline"
        control={control}
        options={endlineOptions}
        label={formatMessage({
          id: AppMessages['definitions.events.addExportFormat.settings.lineBreakCharacter'],
        })}
        disabled={isPreviewPageMode}
        withHelperText
        required
      />
    </>
  );
};
