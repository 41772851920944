import { Controller, useFormContext } from 'react-hook-form';
import { Modal, Button } from 'shared-ui';

import { ReactComponent as AddIcon } from 'assets/icons/add-active-medium.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';
import * as Styled from '../GroupsMultiActions.styles';

import { AddMultiCellsFormBody, AddMultiCellsProps } from './AddMultiCells.types';

export const AddMultiCells = ({ onSubmit, isLoading, isModalOpen, onModalClose, onModalOpen }: AddMultiCellsProps) => {
  const { formatMessage } = useLocale();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<AddMultiCellsFormBody>();

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<AddIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['groups.multiAction.addCell.button'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['groups.multiAction.addCell.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid || isLoading}
      >
        <Styled.Wrapper>
          <Controller
            name="komorki"
            control={control}
            render={({ field }) => (
              <OrganizationsTreeSelect
                {...field}
                multiple
                label={formatMessage({ id: AppMessages['groups.multiAction.addCell.field.cell.label'] })}
                placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
              />
            )}
          />
        </Styled.Wrapper>
      </Modal>
    </>
  );
};
