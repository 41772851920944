import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { deleteAnnouncementAssignedOwners } from 'api/announcements/announcements';

import { DeleteMultiAnnouncementAssignedOwners } from './DeleteMultiAnnouncementAssignedOwners';
import { DeleteMultiAnnouncementAssignedOwnersContainerProps } from './DeleteMultiAnnouncementAssignedOwners.types';

export const DeleteMultiAnnouncementAssignedOwnersContainer = ({
  checkedOwners,
  setCheckedOwners,
}: DeleteMultiAnnouncementAssignedOwnersContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { id } = useParams<{ id: string }>();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteAnnouncementAssignedOwners, isLoading } = useMutation(deleteAnnouncementAssignedOwners, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENT_ASSIGNED_OWNERS]);
      toast.dark(formatMessage({ id: AppMessages['announcement.owners.multiAction.delete.success'] }));
      setCheckedOwners([]);
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteAnnouncementAssignedOwners({ id: id || '', body: checkedOwners.map(({ id }) => id) });
  };

  return (
    <DeleteMultiAnnouncementAssignedOwners
      checkedOwners={checkedOwners}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
