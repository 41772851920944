import { TerminalStatusEnum } from 'api/terminals/terminals.enum';

import { AcceptMultiTerminalsContainer } from './acceptMultiTerminals/AcceptMultiTerminalsContainer';
import * as Styled from './DevicesMultiActions.styles';
import { DevicesMultiActionsProps } from './DevicesMultiActions.types';
import { ReloadMultiTerminalsContainer } from './reloadMultiTerminals/ReloadMultiTerminalsContainer';

export const DevicesMultiActions = ({
  checkedTerminals,
  setCheckedTerminals,
  terminalActionDisabled,
}: DevicesMultiActionsProps) => {
  const shouldShowAcceptButton = !checkedTerminals.filter((terminal) => terminal.status !== TerminalStatusEnum.NOWY)
    .length;

  const shouldShowReloadButton = !checkedTerminals.filter(
    (terminal) => terminal.status !== TerminalStatusEnum.PODMIENIONY && terminal.status !== TerminalStatusEnum.OK,
  ).length;

  return (
    <Styled.Container>
      {shouldShowAcceptButton && (
        <AcceptMultiTerminalsContainer
          checkedTerminals={checkedTerminals}
          setCheckedTerminals={setCheckedTerminals}
          disabled={terminalActionDisabled}
        />
      )}

      {shouldShowReloadButton && (
        <ReloadMultiTerminalsContainer
          checkedTerminals={checkedTerminals}
          setCheckedTerminals={setCheckedTerminals}
          disabled={terminalActionDisabled}
        />
      )}
    </Styled.Container>
  );
};
