import { useFormContext } from 'react-hook-form';
import { Modal, Button } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { PinCodesFields } from 'reusable/form/pinFields/PinCodesFields';

import { AssignCardPinForm, PinCodeAssignProps } from './PinCodeAssign.types';
import * as Styled from './PinCodeAssign.styles';

export const PinCodeAssign = ({
  title,
  cardNumber,
  isPinCodeAssignModalOpen,
  actionText,
  handleFormSubmit,
  onCloseAssignModal,
  onOpenAssignModal,
  isLoadingAssignPin,
  isDisabled,
  renderActionComponent,
}: PinCodeAssignProps) => {
  const { formatMessage } = useLocale();

  const { handleSubmit, formState } = useFormContext<AssignCardPinForm>();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      if (typeof event.preventDefault === 'function') {
        event.preventDefault();
      }
      if (typeof event.stopPropagation === 'function') {
        event.stopPropagation();
      }
    }

    return handleSubmit(async (values) => handleFormSubmit(values))(event);
  };

  return (
    <>
      {renderActionComponent(onOpenAssignModal, isDisabled)}

      <Modal header={title} open={isPinCodeAssignModalOpen} onClose={onCloseAssignModal}>
        <Styled.PinCodeAssignForm onSubmit={onSubmit}>
          <Styled.CardNumber>
            {formatMessage({ id: AppMessages['card.assign.pin.card'] })} {cardNumber}
          </Styled.CardNumber>

          <Styled.PinCodesInputWrapper>
            <PinCodesFields required />
          </Styled.PinCodesInputWrapper>

          <Styled.ButtonsWrapper>
            <Button onClick={onCloseAssignModal} variant="text" size="small">
              {formatMessage({ id: AppMessages['card.assign.pin.action.cancel'] })}
            </Button>
            <Button
              isLoading={isLoadingAssignPin}
              disabled={!formState.isValid}
              type="submit"
              variant="contained"
              size="small"
            >
              {actionText}
            </Button>
          </Styled.ButtonsWrapper>
        </Styled.PinCodeAssignForm>
      </Modal>
    </>
  );
};
