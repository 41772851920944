import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { UserBasicDetails } from './UserBasicDetails';
import { UserBasicDetailsContainerProps, UserBasicDetailsForm } from './UserBasicDetails.types';
import { useUserBasicDetailsValidation } from './UserBasicDetails.validation';

export const UserBasicDetailsContainer = ({
  isLoadingUpdateUser,
  selectedUser,
  onUpdateUserSubmit,
  errorFields,
}: UserBasicDetailsContainerProps) => {
  const { formatMessage } = useLocale();
  const { schema } = useUserBasicDetailsValidation();
  const formMethods = useForm<UserBasicDetailsForm>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: selectedUser,
  });

  useEffect(() => {
    formMethods.reset(selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    const isUsernameExistsError = errorFields?.find(({ type, field }) => type === 'exists' && field === 'username');

    if (isUsernameExistsError) {
      return formMethods.setError('username', {
        message: formatMessage({ id: AppMessages['user.details.basic.username.existsValidation'] }),
      });
    }
  }, [errorFields]);

  const onSubmit = (body: UserBasicDetailsForm) => {
    onUpdateUserSubmit({
      ...selectedUser,
      ...body,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <UserBasicDetails isLoadingUpdateUser={isLoadingUpdateUser} selectedUser={selectedUser} onSubmit={onSubmit} />
    </FormProvider>
  );
};
