import { format } from 'date-fns';
import { TabMenu, useLocale } from 'shared-ui';

import { OwnerDetailsTabEnum } from 'context/selectedOwner/selectedOwnerContext/SelectedOwnerContext.enum';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { DateFormat } from 'core/global.enum';

import { OwnerDetailsProps } from './OwnerDetails.types';
import { OwnerDetailsTabDescriptionEnum } from './OwnerDetails.enum';
import { OwnerBasicDetailsContainer } from './ownerBasicDetails/OwnerBasicDetailsContainer';
import { OwnerSystemDetailsContainer } from './ownerSystemDetails/OwnerSystemDetailsContainer';
import { OwnerPersonalDetailsContainer } from './ownerPersonalDetails/OwnerPersonalDetailsContainer';
import { OwnerActions } from './ownerActions/OwnerActions';
import { OwnerCardsDetails } from './ownerCardsDetails/OwnerCardsDetails';

export const OwnerDetails = ({ selectedOwner }: OwnerDetailsProps) => {
  const { formatMessage } = useLocale();
  const { selectedOwnerDetailsTab, setSelectedOwnerDetailsTab } = useSelectedOwner();
  const isEditDisabled = selectedOwner.archiwalny;

  return (
    <>
      <OwnerActions />

      <TabMenu.Wrapper
        selectedValue={selectedOwnerDetailsTab}
        setSelectedValue={setSelectedOwnerDetailsTab}
        tabsValues={OwnerDetailsTabEnum}
        tabsDescriptions={{
          [OwnerDetailsTabEnum.BASIC]: formatMessage({ id: OwnerDetailsTabDescriptionEnum.BASIC }),
          [OwnerDetailsTabEnum.CARDS]: formatMessage({ id: OwnerDetailsTabDescriptionEnum.CARDS }),
          [OwnerDetailsTabEnum.PERSONAL]: formatMessage({ id: OwnerDetailsTabDescriptionEnum.PERSONAL }),
          [OwnerDetailsTabEnum.SYSTEM]: formatMessage({ id: OwnerDetailsTabDescriptionEnum.SYSTEM }),
        }}
      >
        <TabMenu.Panel value={OwnerDetailsTabEnum.BASIC}>
          <OwnerBasicDetailsContainer
            selectedOwner={{
              ...selectedOwner,
              drugieImie: selectedOwner.drugieImie || '',
            }}
            isEditDisabled={isEditDisabled}
          />
        </TabMenu.Panel>
        <TabMenu.Panel value={OwnerDetailsTabEnum.CARDS}>
          <OwnerCardsDetails selectedOwner={selectedOwner} isEditDisabled={isEditDisabled} />
        </TabMenu.Panel>
        <TabMenu.Panel value={OwnerDetailsTabEnum.PERSONAL}>
          <OwnerPersonalDetailsContainer
            selectedOwner={{
              ...selectedOwner,
              dataUrodzenia: selectedOwner.dataUrodzenia
                ? format(new Date(selectedOwner.dataUrodzenia), DateFormat.DATE)
                : undefined,
            }}
            isEditDisabled={isEditDisabled}
          />
        </TabMenu.Panel>
        <TabMenu.Panel value={OwnerDetailsTabEnum.SYSTEM}>
          <OwnerSystemDetailsContainer selectedOwner={selectedOwner} isEditDisabled={isEditDisabled} />
        </TabMenu.Panel>
      </TabMenu.Wrapper>
    </>
  );
};
