import * as yup from 'yup';

import { PermissionEnum } from 'api/permissions/permissions.enum';
import { Option } from 'ui/multiAutocomplete/MultiAutocomplete.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useAddGroupValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    idGrupy: yup
      .string()
      .max(6, formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.validation.id.max'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    nazwa: yup
      .string()
      .max(60, formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.validation.name.max'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    typ: yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] })),
    opis: yup
      .string()
      .max(60, formatMessage({ id: AppMessages['groups.addGroup.groupDefinition.field.validation.description.max'] }))
      .optional(),
    tkds: yup.array(),
    idGlcs: yup.array(),
    rejestrujOdbicie: yup.string().required(),
    otwierajZamek: yup.string().required(),
    rejestrujRcp: yup
      .string()
      .required()
      .when(['rejestrujOdbicie', 'otwierajZamek', 'tkds', 'idGlcs'], {
        is: (rejestrujOdbicie: PermissionEnum, otwierajZamek: PermissionEnum, tkds: Option[], idGlcs: Option[]) =>
          rejestrujOdbicie === PermissionEnum.NIEZDEFINIOWANO &&
          otwierajZamek === PermissionEnum.NIEZDEFINIOWANO &&
          ((tkds && !!tkds.length) || (idGlcs && !!idGlcs.length)),
        then: yup
          .string()
          .required()
          .test(
            'rejestrujRcp',
            formatMessage({ id: AppMessages['groups.multiAction.addPermissions.field.permissions.validation'] }),
            (val) => {
              const isNotSet = val === PermissionEnum.NIEZDEFINIOWANO;

              return !isNotSet;
            },
          ),
      }),
  });

  return { schema };
};
