import { PermissionEnum, PermissionDesctiptionEnum } from 'api/permissions/permissions.enum';
import {
  OwnerPermissionsIdSortEnum,
  OwnerPermissionsReaderNameSortEnum,
  OwnerPermissionsQueryKeysEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useOwnerQueryKeys } from 'hooks/useOwnerQueryKeys/useOwnerQueryKeys';
import { Table } from 'ui/table/Table';

export const OwnerIndividualPermissionsTableHeader = () => {
  const { formatMessage } = useLocale();
  const { ownerPermissionsIndividualQueryKey } = useOwnerQueryKeys();

  const premissionsFilterItems = Object.values(PermissionEnum).map((permission) => ({
    name: formatMessage({ id: PermissionDesctiptionEnum[permission] }),
    filterBy: permission,
  }));

  return (
    <>
      <Table.HeaderSortItem
        queryKey={ownerPermissionsIndividualQueryKey}
        sortBy={OwnerPermissionsIdSortEnum}
        queryHeader={formatMessage({ id: AppMessages['permissions.table.header.id'] })}
      />

      <Table.HeaderSortItem
        queryKey={ownerPermissionsIndividualQueryKey}
        sortBy={OwnerPermissionsReaderNameSortEnum}
        queryHeader={formatMessage({ id: AppMessages['permissions.table.header.readerName'] })}
      />

      <Table.HeaderFilterItem
        queryKey={ownerPermissionsIndividualQueryKey}
        queryName={OwnerPermissionsQueryKeysEnum.REJESTRUJ_ODBICIE}
        filterItems={premissionsFilterItems}
        queryHeader={formatMessage({ id: AppMessages['permissions.table.header.registerRecord'] })}
        queryHeaderDescriptions={PermissionDesctiptionEnum}
      />

      <Table.HeaderFilterItem
        queryKey={ownerPermissionsIndividualQueryKey}
        queryName={OwnerPermissionsQueryKeysEnum.OTWIERAJ_ZAMEK}
        filterItems={premissionsFilterItems}
        queryHeader={formatMessage({ id: AppMessages['permissions.table.header.openLock'] })}
        queryHeaderDescriptions={PermissionDesctiptionEnum}
      />

      <Table.HeaderFilterItem
        queryKey={ownerPermissionsIndividualQueryKey}
        queryName={OwnerPermissionsQueryKeysEnum.REJESTRUJ_RCP}
        filterItems={premissionsFilterItems}
        queryHeader={formatMessage({ id: AppMessages['permissions.table.header.registerRcp'] })}
        queryHeaderDescriptions={PermissionDesctiptionEnum}
      />
    </>
  );
};
