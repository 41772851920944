import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTheme } from 'styled-components';

import { ReactComponent as CardsIconLight } from 'assets/icons/cards-active.svg';
import { ReactComponent as CardsIconDark } from 'assets/icons/icon-cards-dark.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { DateFormat } from 'core/global.enum';
import { Messages } from 'core/global.types';
import { CardTypeDescriptionEnum } from 'app/cards/Cards.enum';
import { CardStatusEnum, CardTypeEnum } from 'api/cards/cards.enum';

import * as Styled from './CardThumbnail.styles';
import { CardThumbnailProps, ThumbnailDetailValue } from './CardThumbnail.types';

export const CardThumbnail = (props: CardThumbnailProps) => {
  const { formatMessage } = useLocale();
  const theme = useTheme();

  const translateValue = (value: ThumbnailDetailValue) =>
    value in AppMessages ? formatMessage({ id: AppMessages[value as Messages] }) : value;

  const createTitle = (label: ThumbnailDetailValue, details: ThumbnailDetailValue) => (
    <div>
      <Styled.CardThumbnailTitle>{translateValue(label)}</Styled.CardThumbnailTitle>
      <Typography>{translateValue(details)}</Typography>
    </div>
  );

  const createDetail = (label: ThumbnailDetailValue, details: ThumbnailDetailValue) => (
    <div>
      <Styled.CardThumbnailDetailsTitle>{translateValue(label)}</Styled.CardThumbnailDetailsTitle>
      <Styled.CardThumbnailDetails>{translateValue(details)}</Styled.CardThumbnailDetails>
    </div>
  );

  const createNumberAndTypeDetails = () => {
    return (
      <>
        {createTitle('cardThumbnail.number', props.cardNumber)}
        {createDetail('cardThumbnail.type', CardTypeDescriptionEnum[props.cardType])}
      </>
    );
  };

  const createArchivedData = () =>
    props.archiveTime && createDetail('cardThumbnail.archived', format(new Date(props.archiveTime), DateFormat.FULL));

  const createBlockedData = () =>
    props.blockTime && createDetail('cardThumbnail.blocked', format(new Date(props.blockTime), DateFormat.FULL));

  const createIssuedData = () =>
    props.issuedTime && createDetail('cardThumbnail.issued', format(new Date(props.issuedTime), DateFormat.FULL));

  const createDefaultData = () =>
    props.createdAt && createDetail('cardThumbnail.activated', format(new Date(props.createdAt), DateFormat.FULL));

  const getActionDateDetails = () => {
    if (props.archived) return createArchivedData();

    if (props.blocked) return createBlockedData();

    if (props.isIssued || (props.status && [CardStatusEnum.WYDANA, CardStatusEnum.WYDANA_Z].includes(props.status)))
      return createIssuedData();

    return createDefaultData();
  };

  const getThumbnailTheme = () => {
    if (props.isTkdProblem) {
      return {
        iconBackground: '#FDDDD7',
        background: '#FDCCC4',
        fontColor: theme.palette.background.paper,
        cardsIcon: <CardsIconDark />,
      };
    }

    if (props.cardType === CardTypeEnum.ZASTEPCZA) {
      return {
        iconBackground: theme.palette.grey[600],
        background: theme.palette.grey[900],
        fontColor: theme.palette.common.white,
        cardsIcon: <CardsIconLight />,
      };
    }

    if (props.cardType === CardTypeEnum.ZASTEPCZA_WYROZNIONA) {
      return {
        iconBackground: theme.palette.primary.main,
        background: theme.palette.grey[900],
        fontColor: theme.palette.common.white,
        cardsIcon: <CardsIconLight />,
      };
    }

    if (props.cardType === CardTypeEnum.ZWYKLA_WYROZNIONA) {
      return {
        iconBackground: theme.palette.primary.main,
        background: theme.palette.background.paper,
        fontColor: theme.palette.common.white,
        cardsIcon: <CardsIconLight />,
      };
    }

    return {
      iconBackground: theme.palette.grey[900],
      background: theme.palette.background.paper,
      fontColor: theme.palette.common.white,
      cardsIcon: <CardsIconLight />,
    };
  };

  const thumbnailTheme = getThumbnailTheme();

  return (
    <Styled.CardThumbnail $color={thumbnailTheme.background} $fontColor={thumbnailTheme.fontColor}>
      <Styled.CardThumbnailIconWrapper $color={thumbnailTheme.iconBackground}>
        <Styled.IconWrapper>{thumbnailTheme.cardsIcon}</Styled.IconWrapper>
      </Styled.CardThumbnailIconWrapper>
      {createNumberAndTypeDetails()}
      {getActionDateDetails()}
    </Styled.CardThumbnail>
  );
};
