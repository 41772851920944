import { useEffect, useState } from 'react';
import { useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { fetchServers } from 'api/servers/servers';
import { fetchTerminals } from 'api/terminals/terminals';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useMutation } from 'hooks/useMutation/useMutation';
import { addMultiPermissions } from 'api/groups/groups';
import { DEFAULT_PERMISSIONS } from 'core/global.variables';
import { GlcNumberSortEnum, GroupTerminalsPermissionsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { fetchGlc } from 'api/glc/glc';
import { useModalState } from 'hooks/useModalState/useModalState';

import { useAddMulitGroupPermissionsValidation } from './AddMultiGroupPermissions.validation';
import {
  AddMultiGroupPermissionsFormBody,
  AddMultiGroupPermissionsContainerProps,
} from './AddMultiGroupPermissions.types';
import { AddMultiGroupPermissions } from './AddMultiGroupPermissions';

export const AddMultiGroupPermissionsContainer = ({ checkedGroups }: AddMultiGroupPermissionsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const [selectedServerId, setSelectedServerId] = useState('');
  const [terminalsQuery] = useState('');
  const areGlcCheckedGroups = checkedGroups.every(({ glc }) => glc);
  const { schema } = useAddMulitGroupPermissionsValidation(areGlcCheckedGroups);
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const methods = useForm<AddMultiGroupPermissionsFormBody>({
    defaultValues: {
      ...DEFAULT_PERMISSIONS,
      idTkds: [],
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { data: serversResponse, isLoading: isLoadingServers } = useQuery([QueryKeyEnum.SERVERS], fetchServers, {
    onSuccess: (serversResponse) => {
      if (serversResponse.data.length) {
        setSelectedServerId(serversResponse.data[0].id);
      }
    },
  });

  const { data: terminalsResponse, isInitialLoading: isLoadingTerminals } = useQuery(
    [QueryKeyEnum.TERMINALS, selectedServerId, terminalsQuery],
    () => fetchTerminals(selectedServerId),
    {
      args: {
        zaakceptowane: true,
        sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
        withoutGlc: true,
      },
      enabled: !!selectedServerId && !areGlcCheckedGroups,
    },
  );

  const { data: glcResponse, isInitialLoading: isLoadingGlcGroups } = useQuery([QueryKeyEnum.GLC], fetchGlc, {
    args: { sort: [GlcNumberSortEnum.ASC] },
    enabled: !!selectedServerId && areGlcCheckedGroups,
  });

  const { mutate: onAddMultiPermissions, isLoading } = useMutation(addMultiPermissions, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.GROUPS]);
      toast.dark(formatMessage({ id: AppMessages['groups.multiAction.addPermissions.successMessage'] }));
      onModalClose();
    },
  });

  const onSubmit = (body: AddMultiGroupPermissionsFormBody) => {
    onAddMultiPermissions({
      ...body,
      idTkds: body.idTkds?.map(({ value }) => `${value}`) || [],
      idGlcs: body.idGlcs?.map(({ value }) => `${value}`) || [],
      idGrup: checkedGroups.map(({ id }) => id),
    });
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen, selectedServerId]);

  return (
    <FormProvider {...methods}>
      <AddMultiGroupPermissions
        onSubmit={onSubmit}
        isModalOpen={isModalOpen}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
        isLoading={isLoading}
        servers={serversResponse?.data || []}
        selectedServerId={selectedServerId}
        setSelectedServerId={setSelectedServerId}
        isLoadingTerminals={isLoadingTerminals}
        terminals={terminalsResponse?.data || []}
        glcGroups={glcResponse?.data || []}
        isLoadingGlcGroups={isLoadingGlcGroups}
        isLoadingServers={isLoadingServers}
        areGlcCheckedGroups={areGlcCheckedGroups}
      />
    </FormProvider>
  );
};
