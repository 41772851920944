import { useContext } from 'react';

import { ExpandedServerIdContext } from 'app/announcements/context/selectedServer/expandedServerIdContext/ExpandedServerIdContext';

export const useExpandedServerId = () => {
  const context = useContext(ExpandedServerIdContext);

  if (context === undefined) {
    throw new Error(
      'ExpandedServerIdContext is unavailable, make sure you are using ExpandedServerIdContextController',
    );
  }

  return context;
};
