import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'shared-ui';

import { AppProviders } from 'providers/AppProviders';

export const App = () => (
  <AppProviders>
    <ErrorBoundary>
      <main>
        <Outlet />
      </main>
    </ErrorBoundary>
  </AppProviders>
);
