import * as yup from 'yup';
import { isValid, isEqual } from 'date-fns';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

export const useOwnerSystemDetailsValidation = (minDate: Date, defaultExpiryStartDate?: Date | null) => {
  const { formatMessage } = useLocale();

  const minDateRequired = yup
    .date()
    .min(minDate, formatMessage({ id: AppMessages['common.validation.date.past'] }))
    .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }));

  const dateOptional = yup.date().typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }));

  const schema = yup.object().shape({
    nrEwidencyjny: yup
      .string()
      .max(
        6,
        formatMessage({ id: AppMessages['owners.addOwner.field.registrationNumber.validation.max'] }, { maxLength: 6 }),
      )
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    nrEwidencyjnyZewnetrzny: yup
      .string()
      .max(
        6,
        formatMessage({ id: AppMessages['owners.addOwner.field.registrationNumber.validation.max'] }, { maxLength: 6 }),
      ),
    rcp: yup.boolean().required(),

    dataOd: yup
      .date()
      .min(minDate, formatMessage({ id: AppMessages['common.validation.date.past'] }))
      .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }))
      .when('dataDo', (validTo) => {
        if (validTo && isValid(validTo)) {
          return yup
            .date()
            .when('dataOd', (validFrom) => {
              const isDateUnchanged =
                defaultExpiryStartDate && isEqual(new Date(validFrom), new Date(defaultExpiryStartDate));

              if (isDateUnchanged) return dateOptional;

              return minDateRequired;
            })
            .max(validTo, formatMessage({ id: AppMessages['common.validation.date.invalidRange'] }))
            .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }));
        }
        return yup.date().when('dataOd', (validFrom) => {
          const isDateUnchanged =
            defaultExpiryStartDate && isEqual(new Date(validFrom), new Date(defaultExpiryStartDate));

          if (isDateUnchanged) return dateOptional;

          return minDateRequired;
        });
      })
      .required(formatMessage({ id: AppMessages['common.validation.date.format'] })),
    dataDo: yup
      .date()
      .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }))
      .min(
        yup.ref('dataOd'),
        formatMessage({ id: AppMessages['cards.multiAction.expiryDate.endDate.validation.value'] }),
      )
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .optional(),
  });

  return schema;
};
