import { useState } from 'react';
import { useQueryClient } from 'shared-ui';
import { useLocation } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { Card } from 'api/cards/cards.types';
import { SelectedCardContext } from '../selectedCardContext/SelectedCardContext';
import { fetchCardDetails } from 'api/cards/cards';
import { useQuery } from 'hooks/useQuery/useQuery';
import { AppRoute } from 'routing/AppRoute.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';

import { SelectedCardContextControllerProps } from './SelectedCardContextController.types';

export const SelectedCardContextController = ({ children }: SelectedCardContextControllerProps) => {
  const [selectedCardId, setSelectedCardId] = useState<string>();
  const isArchivedView = useIsExactPath(AppRoute.cardsArchived);
  const isCardHistoryView = useIsExactPath(AppRoute.cardHistory);
  const queryClient = useQueryClient();
  const [lastActiveSelectedCardId, setLastActiveSelectedCardId] = useState<string>();
  const [lastArchivedSelectedCardId, setLastArchivedSelectedCardId] = useState<string>();

  const location = useLocation();
  const cardIdFromHistory = isCardHistoryView ? location.pathname.split(`${AppRoute.cardHistory}/`)[1] : '';

  const cardIdToDisplayInDetails =
    cardIdFromHistory || (isArchivedView ? lastArchivedSelectedCardId : lastActiveSelectedCardId);

  const { data: selectedCard } = useQuery(
    [QueryKeyEnum.CARD_DETAILS, cardIdToDisplayInDetails],
    () => fetchCardDetails(cardIdToDisplayInDetails),
    {
      enabled: !!cardIdToDisplayInDetails,
      refetchInterval: !!cardIdToDisplayInDetails && 5000,
    },
  );

  const setSelectedCard = (card: Card | undefined, likeClickAction?: boolean) => {
    if (!card || (card.id === selectedCard?.id && selectedCardId && likeClickAction)) {
      selectedCard && queryClient.setQueryData([QueryKeyEnum.CARD_DETAILS, selectedCard.id], null);

      isArchivedView ? setLastArchivedSelectedCardId(undefined) : setLastActiveSelectedCardId(undefined);

      return setSelectedCardId(undefined);
    }

    setSelectedCardId(card.id);

    isArchivedView ? setLastArchivedSelectedCardId(card.id) : setLastActiveSelectedCardId(card.id);

    queryClient.setQueryData([QueryKeyEnum.CARD_DETAILS, card.id], card);
    queryClient.invalidateQueries([QueryKeyEnum.CARD_DETAILS, card.id]);
  };

  const resetSelectedCard = () => {
    setLastActiveSelectedCardId(undefined);
    setLastArchivedSelectedCardId(undefined);
    setSelectedCardId(undefined);
    setSelectedCard(undefined);
  };

  return (
    <SelectedCardContext.Provider
      value={{
        selectedCardId,
        setSelectedCardId,
        selectedCard,
        setSelectedCard,
        lastActiveSelectedCardId,
        lastArchivedSelectedCardId,
        setLastActiveSelectedCardId,
        setLastArchivedSelectedCardId,
        resetSelectedCard,
      }}
    >
      {children}
    </SelectedCardContext.Provider>
  );
};
