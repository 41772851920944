import { FormHelperText } from '@mui/material';
import styled, { css } from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7px 24px;
`;

export const InformationInputWrapper = styled.div`
  grid-column: 1/-1;
`;

export const ErrorMessage = styled(FormHelperText)(
  ({ theme: { palette } }) => css`
    display: block;
    font-weight: 700;
    color: ${palette.error.main};
    height: 24px;
  `,
);
