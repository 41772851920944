import { useContext } from 'react';

import { SelectedEventContext } from 'context/selectedEvent/selectedEventContext/SelectedEventContext';

export const useSelectedEvent = () => {
  const context = useContext(SelectedEventContext);

  if (context === undefined) {
    throw new Error('SelectedEventContext is unavailable, make sure you are using SelectedEventContextController');
  }

  return context;
};
