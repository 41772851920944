import { useMemo } from 'react';
import { Option } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { fetchGroupsForSpecificOwner } from 'api/groups/groups';
import { useQuery } from 'hooks/useQuery/useQuery';
import { OwnerGroupNameSortEnum } from 'context/query/queryContext/QueryContext.enum';
import * as Styled from '../TableSearch.styles';

export const useSearchByOwnerGroups = (
  optionsQuery: string,
  isEnabled: boolean,
  ownerId: string,
  option: Option | null,
) => {
  const { formatMessage } = useLocale();
  const memoizedInputValue = useMemo(() => option?.label || optionsQuery, [option?.value]);

  const queryById = memoizedInputValue === optionsQuery;

  const { data: groupsResponse, isInitialLoading } = useQuery(
    [QueryKeyEnum.OWNER_GROUPS_SEARCH, ownerId, optionsQuery, memoizedInputValue],
    () => fetchGroupsForSpecificOwner(ownerId),
    {
      args: {
        query: queryById ? option?.value : optionsQuery,
        sort: [OwnerGroupNameSortEnum.ASC],
        queryById: queryById ? true : null,
      },
      enabled: isEnabled,
    },
  );

  const groups = groupsResponse?.data || [];

  const createOptions = () => {
    return groups.map((group) => ({ value: group.idGrupy, label: group.nazwa, id: group.id }));
  };

  const createOptionLabel = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <li {...optionProps} key={option.value}>
        <Styled.OptionNumber>
          {formatMessage({ id: AppMessages['tableSearch.group.id'] })}{' '}
          {groups.find((group) => group.id === option.id)?.idGrupy}
        </Styled.OptionNumber>

        <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
      </li>
    );
  };

  return { isLoading: isInitialLoading, createOptions, createOptionLabel };
};
