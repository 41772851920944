import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Datepicker } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { handleValidFromDate } from 'helpers/handleValidFromDate';
import { getNow } from 'helpers/getNow';
import { handleValidToDate } from 'helpers/handleValidToDate';
import { getEndOfToday } from 'helpers/getEndOfToday';
import { Form } from 'ui/form/Form';
import { RcpEnum } from 'app/events/eventsFilters/EventsFilters.enum';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';

export const SystemDataForm = () => {
  const { formatMessage } = useLocale();

  const minDate = useMemo(() => new Date(), []);

  const { control, setValue, watch, trigger } = useFormContext();

  useEffect(() => {
    setValue('dataOd', new Date());
  }, []);

  const { rcpOptions } = useAppSelectOptions();

  return (
    <>
      <Controller
        name="nrEwidencyjny"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            required
            label={formatMessage({ id: AppMessages['owners.addOwner.field.registrationNumber.label'] })}
            placeholder={formatMessage({ id: AppMessages['owners.addOwner.field.registrationNumber.placeholder'] })}
            errorMessage={error?.message}
          />
        )}
      />

      <Controller
        name="nrEwidencyjnyZewnetrzny"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            label={formatMessage({ id: AppMessages['owners.addOwner.field.externalRegistrationNumber.label'] })}
            placeholder={formatMessage({
              id: AppMessages['owners.addOwner.field.externalRegistrationNumber.placeholder'],
            })}
            errorMessage={error?.message}
          />
        )}
      />

      <Form.ControlledSelect
        name="rcp"
        control={control}
        defaultValue={RcpEnum.TRUE}
        label={formatMessage({ id: AppMessages['owners.addOwner.field.rcpWorker.label'] })}
        required
        options={rcpOptions}
      />

      <Controller
        name="dataOd"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Datepicker
            {...field}
            required
            label={formatMessage({ id: AppMessages['owners.addOwner.field.dateFrom.label'] })}
            minDate={minDate}
            withCalendarIcon
            withDatepickerPopover
            isClickable
            errorMessage={error?.message}
            popoverOrigin={{
              vertical: -80,
              horizontal: 60,
            }}
            customSetDateFormat={handleValidFromDate}
            customDateFormatOnOpen={getNow}
          />
        )}
      />

      <Controller
        name="dataDo"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Datepicker
            {...field}
            label={formatMessage({ id: AppMessages['owners.addOwner.field.dateTo.label'] })}
            minDate={watch('dataOd') || undefined}
            withCalendarIcon
            withDatepickerPopover
            isClickable
            errorMessage={error?.message}
            popoverOrigin={{
              vertical: -80,
              horizontal: 60,
            }}
            customSetDateFormat={handleValidToDate}
            customDateFormatOnOpen={getEndOfToday}
            onChange={(date) => {
              field.onChange(date);
              trigger('dataOd');
            }}
          />
        )}
      />
    </>
  );
};
