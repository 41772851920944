import { IconButton } from '@mui/material';
import styled, { css } from 'styled-components';

export const FormatWrapper = styled.div`
  display: grid;
  align-items: center;
  gap: 16px;
  grid-template-columns: 1fr 60px;
`;

export const FormatDetailsButton = styled(IconButton)(
  ({ disabled }) => css`
    width: 40px;
    height: 40px;
    margin-right: 6px;
    justify-self: end;

    ${disabled &&
    css`
      filter: grayscale(0.8);
    `}
  `,
);
