import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Position } from 'api/positions/positions.types';

export const usePositionDetailsValidation = (positions: Position[], selectedPosition: Position) => {
  const { formatMessage } = useLocale();

  const positionNamesStandarized = positions
    .filter(({ id }) => id !== selectedPosition.id)
    .map(({ nazwa }) => nazwa.toLowerCase());

  const schema = yup.object().shape({
    nazwa: yup
      .string()
      .min(3, formatMessage({ id: AppMessages['definitions.position.details.name.minLength.validation'] }))
      .max(60, formatMessage({ id: AppMessages['definitions.position.details.name.maxLength.validation'] }))
      .test(
        'check organization code duplicate',
        formatMessage({ id: AppMessages['definitions.position.details.name.validation'] }),
        (positionName) => !(positionName && positionNamesStandarized.includes(positionName.toLowerCase())),
      )
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
  });
  return { schema };
};
