import { useLayoutEffect } from 'react';
import { Option } from 'shared-ui';

import { QueryKeyLocalStorageEnum, SearchQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { SelectedSearchOptionsQuery } from 'context/query/queryContext/QueryContext.types';

export const useSelectedSearchOptions = <T extends SearchQueryKeysEnum>(
  searchQueryKey: T,
  setOptionCallback?: (optionValue: string) => void,
  setOptionCallbackCallbackListener?: unknown,
  getValueCallback?: (option?: SelectedSearchOptionsQuery[T]) => string,
) => {
  const {
    setQuery: setSearchQuery,
    storedQueries: { [QueryKeyLocalStorageEnum.SELECTED_SEARCH_OPTIONS]: searchOptions },
  } = useLocaleStoreQuery();

  const setSelectedSearchOption = (option?: Option | null) => {
    setSearchQuery(QueryKeyLocalStorageEnum.SELECTED_SEARCH_OPTIONS, {
      ...searchOptions,
      [searchQueryKey]: option,
    });
  };

  useLayoutEffect(() => {
    if (searchQueryKey) {
      setSelectedSearchOption(searchOptions[searchQueryKey]);

      const option = searchOptions[searchQueryKey];
      const value = getValueCallback ? getValueCallback(searchOptions[searchQueryKey]) : (option?.value as string);

      setOptionCallback && setOptionCallback(value || '');
    }
  }, [setOptionCallbackCallbackListener, searchQueryKey, setOptionCallback]);

  return { setSelectedSearchOption, searchOption: searchOptions[searchQueryKey] };
};
