import { Button } from 'shared-ui';

import { ReactComponent as ArchiveIcon } from 'assets/icons/archive-primary.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { UnarchiveMultiCardsProps } from './UnarchiveMultiCards.types';

export const UnarchiveMultiCards = ({
  checkedCards,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
}: UnarchiveMultiCardsProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<ArchiveIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['cards.multiAction.unarchive.button'] })}
      </Button>

      <ActionModal
        header={formatMessage({ id: AppMessages['cards.multiAction.unarchive.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        submitButtonDisabled={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['cards.multiAction.unarchive.button'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['cards.multiAction.unarchive.subtitle'] })} {checkedCards.length}
        </ActionModal.Subtitle>

        <ActionModal.Text>{formatMessage({ id: AppMessages['cards.multiAction.unarchive.text'] })}</ActionModal.Text>

        <ActionModal.Text>{formatMessage({ id: AppMessages['common.reversibleAction'] })}</ActionModal.Text>
      </ActionModal>
    </>
  );
};
