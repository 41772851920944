import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, Datepicker } from 'shared-ui';

import { OwnerDocumentEnum } from 'api/owners/owners.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { DateFormat } from 'core/global.enum';
import { OwnerDocumentDescriptionEnum } from 'app/owners/Owners.enum';
import { PHONE_REGEX } from 'core/global.variables';

import * as Styled from './PersonalDataForm.styles';

export const PersonalDataForm = () => {
  const { formatMessage } = useLocale();

  const { control, watch, setValue, clearErrors } = useFormContext();

  const ownerDocumentOptions = Object.values(OwnerDocumentEnum).map((document) => ({
    label: formatMessage({ id: OwnerDocumentDescriptionEnum[document] }),
    value: document,
  }));

  return (
    <>
      <Controller
        control={control}
        name="dokumentTozsamosci"
        render={({ field }) => (
          <Autocomplete
            {...field}
            onChange={(_e, option) => {
              clearErrors('numerDokumentu');
              setValue('numerDokumentu', '');
              option ? field.onChange(option.value) : field.onChange(null);
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            options={ownerDocumentOptions}
            renderOption={(props, option) =>
              option.value ? (
                <li {...props} key={option.value}>
                  <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
                </li>
              ) : null
            }
            label={formatMessage({ id: AppMessages['owners.addOwner.field.identification.label'] })}
            placeholder={formatMessage({ id: AppMessages['owners.addOwner.select.placeholder'] })}
          />
        )}
      />

      <Controller
        name="dataUrodzenia"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Datepicker
            {...field}
            label={formatMessage({ id: AppMessages['owners.addOwner.field.birthDate.label'] })}
            mask="____/__/__"
            inputFormat={DateFormat.DATE}
            placeholderText={formatMessage({
              id: AppMessages['owners.addOwner.field.birthDate.placeholder'],
            })}
            isClickable
            errorMessage={error?.message}
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            label={formatMessage({ id: AppMessages['owners.addOwner.field.email.label'] })}
            placeholder={formatMessage({ id: AppMessages['owners.addOwner.field.email.placeholder'] })}
            errorMessage={error?.message}
          />
        )}
      />

      <Controller
        name="numerDokumentu"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            value={field.value || ''}
            disabled={!watch('dokumentTozsamosci')}
            label={formatMessage({ id: AppMessages['owners.addOwner.field.documentNumber.label'] })}
            placeholder={formatMessage({ id: AppMessages['owners.addOwner.field.documentNumber.placeholder'] })}
            errorMessage={error?.message}
          />
        )}
      />

      <Controller
        name="pesel"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            type="number"
            label={formatMessage({ id: AppMessages['owners.addOwner.field.pesel.label'] })}
            placeholder={formatMessage({ id: AppMessages['owners.addOwner.field.pesel.placeholder'] })}
            errorMessage={error?.message}
          />
        )}
      />

      <Controller
        name="telefon"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            onChange={(e) => {
              if (e.target.value && !PHONE_REGEX.test(e.target.value)) return;

              field.onChange(e);
            }}
            value={field.value || ''}
            label={formatMessage({ id: AppMessages['owners.addOwner.field.phoneNumber.label'] })}
            placeholder={formatMessage({ id: AppMessages['owners.addOwner.field.phoneNumber.placeholder'] })}
            errorMessage={error?.message}
          />
        )}
      />

      <Styled.DescriptionInputWrapper>
        <Controller
          name="opis"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              label={formatMessage({ id: AppMessages['owners.addOwner.field.description.label'] })}
              placeholder={formatMessage({ id: AppMessages['owners.addOwner.field.description.placeholder'] })}
              errorMessage={error?.message}
            />
          )}
        />
      </Styled.DescriptionInputWrapper>
    </>
  );
};
