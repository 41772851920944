import { useState } from 'react';

import { GroupPermission } from 'api/permissions/permissions.types';
import { SelectedGroupTerminalPermissionContext } from '../selectedGroupTerminalPermissionContext/SelectedGroupTerminalPermissionContext';

import { SelectedGroupTerminalPermissionContextControllerProps } from './SelectedGroupTerminalPermissionContextController.types';

export const SelectedGroupTerminalPermissionContextController = ({
  children,
}: SelectedGroupTerminalPermissionContextControllerProps) => {
  const [selectedGroupTerminalPermission, setSelectedGroupTerminalPermission] = useState<GroupPermission | undefined>(
    undefined,
  );

  return (
    <SelectedGroupTerminalPermissionContext.Provider
      value={{ selectedGroupTerminalPermission, setSelectedGroupTerminalPermission }}
    >
      {children}
    </SelectedGroupTerminalPermissionContext.Provider>
  );
};
