import { useState, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import { useLoaderToast } from 'shared-ui';

import { fetchEventsExportFormats } from 'api/eventsExportFormat/eventsExportFormats';
import { fetchServers } from 'api/servers/servers';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { getEndOfToday } from 'helpers/getEndOfToday';
import { useExportEventForm } from 'hooks/useExportEventForm/useExportEventForm';
import { ExportEventsBody } from 'api/events/events.types';
import { useMutation } from 'hooks/useMutation/useMutation';
import { AppMessages } from 'i18n/messages';
import { exportEvents } from 'api/events/events';
import { useLocale } from 'hooks/useLocale/useLocale';
import { isTestEnv } from 'helpers/isTestEnv';
import { getStartOfToday } from 'helpers/getStartOfToday';
import { EventEnum } from 'api/events/events.enum';
import {
  EventExportFormatNameSortEnum,
  EventExportFormatQueryKeysEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useModalState } from 'hooks/useModalState/useModalState';

import { ExportEvents } from './ExportEvents';
import { useExportEventsValidation } from './ExportEvents.validation';
import { ServerOptions } from './ExportEvents.enum';

export const ExportEventsContainer = () => {
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const maxDate = useMemo(() => getEndOfToday(), []);
  const { exportEventFormState } = useExportEventForm();
  const { formatMessage } = useLocale();
  const {
    load: loadExportEventsToast,
    update: updateExportEventsToast,
    close: closeExportEventToast,
  } = useLoaderToast('disconnecting');

  const location = useLocation();
  const { schema } = useExportEventsValidation(maxDate);
  const formMethods = useForm<ExportEventsBody>({
    resolver: yupResolver(schema),
    defaultValues: {
      dataDo: getEndOfToday(),
      dataOd: getStartOfToday(),
    },
    mode: 'all',
  });
  const [displayModalAfterRedirect, setDisplayModalAfterRedirect] = useState(
    !!location.state?.displayExportEventsModal,
  );

  const { data: eventsExportFormats } = useQuery(
    [QueryKeyEnum.EVENTS_EXPORT_FORMAT],
    () => fetchEventsExportFormats(),
    { args: { limit: 100, [EventExportFormatQueryKeysEnum.SORT]: EventExportFormatNameSortEnum.ASC } },
  );

  const { data } = useQuery([QueryKeyEnum.SERVERS], fetchServers);

  const { mutate: onExportEvents, isLoading } = useMutation(exportEvents, {
    onMutate: () => {
      loadExportEventsToast(formatMessage({ id: AppMessages['events.exportEvents.dateTo.progress'] }));
    },
    onSuccess: (response) => {
      updateExportEventsToast(formatMessage({ id: AppMessages['events.exportEvents.dateTo.success'] }));
      onModalClose();

      const blob = new Blob([response], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'report.txt';
      link.href = url;
      !isTestEnv() && link.click();
    },
    onSettled: closeExportEventToast,
  });

  const onSubmit = (body: ExportEventsBody) => {
    onExportEvents({
      body: {
        ...body,
        ...(body.nazwaRcp === `${ServerOptions.EMPTY}`
          ? { zdarzenie: EventEnum.MANUALNY, nazwaRcp: undefined }
          : { nazwaRcp: body.nazwaRcp }),
      },
    });
  };

  const dateFrom = formMethods.watch('dataOd');
  const dateTo = formMethods.watch('dataDo');

  useEffect(() => {
    if (displayModalAfterRedirect) {
      formMethods.reset(exportEventFormState);
      onModalOpen();
      setDisplayModalAfterRedirect(false);
    }
  }, []);

  useEffect(() => {
    if (dateTo) {
      formMethods.trigger('dataDo');
    }

    if (dateFrom) {
      formMethods.trigger('dataOd');
    }
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (!displayModalAfterRedirect && !isModalOpen) {
      formMethods.reset({
        formatId: '',
        nazwaRcp: '',
        dataDo: getEndOfToday(),
        dataOd: getStartOfToday(),
      });
    }
  }, [isModalOpen]);

  return (
    <FormProvider {...formMethods}>
      <ExportEvents
        onSubmit={onSubmit}
        isLoading={isLoading}
        isModalOpen={isModalOpen}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
        servers={data?.data || []}
        eventsExportFormats={eventsExportFormats?.data || []}
        maxDate={maxDate}
      />
    </FormProvider>
  );
};
