import { AppRoute } from 'routing/AppRoute.enum';
import { CardBlockTypeEnum, CardStatusEnum } from 'api/cards/cards.enum';
import { ReturnMultiCardsContainer } from 'reusable/returnMultiCards/ReturnMultiCardsContainer';
import { UpdateExpiryDateMultiCardsContainer } from 'reusable/updateExpiryDateMultiCards/UpdateExpiryDateMultiCardsContainer';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';

import { CardsMultiActionsProps } from './CardsMultiActions.types';
import { ArchiveMultiCardsContainer } from './archiveMultiCards/ArchiveMultiCardsContainer';
import { UnarchiveMultiCardsContainer } from './unarchiveMultiCards/UnarchiveMultiCardsContainer';
import { DeleteMultiCardsContainer } from './deleteMultiCards/DeleteMultiCardsContainer';
import { BlockMultiCardsContainer } from './blockMultiCards/BlockMultiCardsContainer';
import { UnblockMultiCardsContainer } from './unblockMultiCards/UnblockMultiCardsContainer';
import { EditMultiCardsContainer } from './editMultiCards/EditMultiCardsContainer';
import { ReturnDateMultiCardsContainer } from './returnDateMultiCards/ReturnDateMultiCardsContainer';
import * as Styled from './CardsMultiActions.styles';

export const CardsMultiActions = ({ checkedCards, setCheckedCards }: CardsMultiActionsProps) => {
  const isArchivedView = useIsExactPath(AppRoute.cardsArchived);

  const isDeleteEnable = checkedCards.every(({ uzywana }) => !uzywana);
  const isBlockEnable = checkedCards.every(({ blokada }) => blokada !== CardBlockTypeEnum.RECZNA);
  const isUnblockEnable = checkedCards.every(({ blokada }) => blokada === CardBlockTypeEnum.RECZNA);
  const isArchiveEnable = checkedCards.every(({ posiadacz }) => !(posiadacz && Object.keys(posiadacz).length));
  const isUpdateExpiryDateEnable = checkedCards.every(
    ({ status, blokada }) =>
      [CardStatusEnum.WYDANA, CardStatusEnum.WYDANA_Z].includes(status) && blokada !== CardBlockTypeEnum.RECZNA,
  );
  const isReturnEnable = checkedCards.every(({ posiadacz }) => posiadacz && Object.keys(posiadacz).length);
  const isReturnDateEnable = checkedCards.every(({ status }) => CardStatusEnum.DOSTEPNA !== status);

  return (
    <Styled.Container>
      {isArchivedView ? (
        <UnarchiveMultiCardsContainer checkedCards={checkedCards} />
      ) : (
        <>
          {isArchiveEnable && <ArchiveMultiCardsContainer checkedCards={checkedCards} />}

          {isBlockEnable && <BlockMultiCardsContainer checkedCards={checkedCards} />}
          {isUpdateExpiryDateEnable && (
            <UpdateExpiryDateMultiCardsContainer checkedCards={checkedCards} actionIn="cards" />
          )}
          {isReturnDateEnable && <ReturnDateMultiCardsContainer checkedCards={checkedCards} />}
          {isUnblockEnable && <UnblockMultiCardsContainer checkedCards={checkedCards} />}
          {isReturnEnable && <ReturnMultiCardsContainer checkedCards={checkedCards} actionIn="cards" />}
        </>
      )}

      {isDeleteEnable && <DeleteMultiCardsContainer checkedCards={checkedCards} setCheckedCards={setCheckedCards} />}

      <EditMultiCardsContainer checkedCards={checkedCards} />
    </Styled.Container>
  );
};
