import { PageLayout, ShowUp, TopBar } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';
import { AppRoute } from 'routing/AppRoute.enum';

import { AnnouncementAssignedOwnersProps } from './AnnouncementAssignedOwners.types';
import { AnnouncementAssignedOwnersTableHeader } from './announcementAssignedOwnersTableHeader/AnnouncementAssignedOwnersTableHeader';
import { AnnouncementAssignedOwnersTableRow } from './announcementAssignedOwnersTableRow/AnnouncementAssignedOwnersTableRow';
import { AnnouncementAssignedOwnersMultiActions } from './announcementAssignedOwnersMultiActions/AnnouncementAssignedOwnersMultiActions';
import { AddAnnouncementAssignedOwnersContainer } from './addAnnouncementAssignedOwners/AddAnnouncementAssignedOwnersContainer';

export const AnnouncementAssignedOwners = ({
  ownersResponse,
  isLoading,
  checkedAssignedOwners,
  setCheckedAssignedOwners,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  announcementName,
}: AnnouncementAssignedOwnersProps) => {
  const { formatMessage } = useLocale();

  return (
    <PageLayout
      header={announcementName}
      withBackButton={{
        onClick: (navigate) => navigate(AppRoute.announcements),
      }}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="1fr">
            <TopBar.ButtonsWrapper>
              <AddAnnouncementAssignedOwnersContainer />
            </TopBar.ButtonsWrapper>
          </TopBar>

          <Table
            isLoading={isLoading}
            renderMultiActions={() => (
              <ShowUp show={!!checkedAssignedOwners.length}>
                <AnnouncementAssignedOwnersMultiActions
                  checkedOwners={checkedAssignedOwners}
                  setCheckedOwners={setCheckedAssignedOwners}
                />
              </ShowUp>
            )}
            pagination={{
              page,
              setPage,
              count: ownersResponse?.count || 0,
              rowsPerPage,
              setRowsPerPage,
            }}
            gridTemplateColumns="1fr 1fr 1.5fr 1fr 0.7fr"
            data={ownersResponse?.data || []}
            nothingToShowText={formatMessage({ id: AppMessages['owners.nothingToShow'] })}
            checkboxSelection={{
              checked: checkedAssignedOwners,
              toggleChecked: setCheckedAssignedOwners,
            }}
            renderHeader={() => <AnnouncementAssignedOwnersTableHeader />}
            renderRow={(owner) => <AnnouncementAssignedOwnersTableRow owner={owner} />}
          />
        </>
      )}
    />
  );
};
