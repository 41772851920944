import { PageLayout, ShowUp, TopBar } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';
import { AppRoute } from 'routing/AppRoute.enum';
import { useSelectedGlc } from 'hooks/useSelectedGlc/useSelectedGlc';

import { GlcTerminalsProps } from './GlcTerminals.types';
import { GlcTerminalsTableHeader } from './glcTerminalsTableHeader/GlcTerminalsTableHeader';
import { GlcTerminalsTableRow } from './glcTerminalsTableRow/GlcTerminalsTableRow';
import { GlcTerminalsMultiActions } from './glcTerminalsMultiActions/GlcTerminalsMultiActions';
import { AddGlcTerminalsContainer } from './addGlcTerminals/AddGlcTerminalsContainer';

export const GlcTerminals = ({
  glcTerminalsResponse,
  isLoading,
  checkedGlcTerminals,
  setCheckedGlcTerminals,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  glcId,
}: GlcTerminalsProps) => {
  const { formatMessage } = useLocale();
  const { selectedGlc } = useSelectedGlc();

  return (
    <PageLayout
      header={`${formatMessage({ id: AppMessages['glc.terminals.title'] })}`}
      withBackButton={{
        onClick: (navigate) => navigate(AppRoute.glc),
      }}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="1fr">
            <TopBar.ButtonsWrapper>
              {!selectedGlc?.uprGrupowe && <AddGlcTerminalsContainer glcId={glcId} />}
            </TopBar.ButtonsWrapper>
          </TopBar>

          <Table
            isLoading={isLoading}
            renderMultiActions={() => (
              <ShowUp show={!!checkedGlcTerminals.length}>
                <GlcTerminalsMultiActions
                  checkedGlcTerminals={checkedGlcTerminals}
                  setCheckedGlcTerminals={setCheckedGlcTerminals}
                />
              </ShowUp>
            )}
            pagination={{
              page,
              setPage,
              count: glcTerminalsResponse?.count || 0,
              rowsPerPage,
              setRowsPerPage,
            }}
            gridTemplateColumns="1fr 5fr"
            data={glcTerminalsResponse?.data || []}
            nothingToShowText={formatMessage({ id: AppMessages['glc.terminals.nothingToShow'] })}
            checkboxSelection={{
              checked: checkedGlcTerminals,
              toggleChecked: setCheckedGlcTerminals,
              disabled: !!selectedGlc?.uprGrupowe,
            }}
            renderHeader={() => <GlcTerminalsTableHeader />}
            renderRow={(terminal) => <GlcTerminalsTableRow terminal={terminal} />}
          />
        </>
      )}
    />
  );
};
