import { useLocation } from 'react-router-dom';
import { TopBar } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { useSelectedSearchOptions } from 'hooks/useSelectedSearchOptions/useSelectedSearchOptions';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { useExpandedServerId } from '../hooks/useSelectedServerId/useSelectedServerId';
import { AddAnnouncementContainer } from '../announcementsTable/addAnnouncements/AddAnnouncementContainer';

import { WantedBarProps } from './AnnouncementsBar.types';

export const AnnouncementsBar = ({ searchQueryKey, setTableSearchQuery }: WantedBarProps) => {
  const { formatMessage } = useLocale();
  const location = useLocation();

  const { searchOption, setSelectedSearchOption } = useSelectedSearchOptions(searchQueryKey, setTableSearchQuery);
  const { expandedServerId } = useExpandedServerId();

  return (
    <TopBar gridTemplateColumns="auto auto 1fr auto">
      <TopBar.Link linkTo={AppRoute.announcements} isActive={location.pathname === AppRoute.announcements}>
        {formatMessage({ id: AppMessages['announcements.list'] })}
      </TopBar.Link>

      <TopBar.Link
        linkTo={AppRoute.announcementsHistory}
        isActive={location.pathname === AppRoute.announcementsHistory}
      >
        {formatMessage({ id: AppMessages['announcements.history'] })}
      </TopBar.Link>

      {setTableSearchQuery ? (
        <>
          <TopBar.Search>
            <TableSearchContainer
              searchIconTestId="page-search"
              inputPlaceholder={formatMessage({ id: AppMessages['announcements.search'] })}
              searchBy="serverAnnouncements"
              setTableSearchQuery={setTableSearchQuery}
              selectedValue={searchOption}
              setSelectedValue={setSelectedSearchOption}
              serverId={expandedServerId}
            />
          </TopBar.Search>

          <TopBar.ButtonsWrapper>
            <AddAnnouncementContainer />
          </TopBar.ButtonsWrapper>
        </>
      ) : (
        <TopBar.EmptyBarWithLine />
      )}
    </TopBar>
  );
};
