import { useSelectedGroupTerminalPermission } from 'hooks/useSelectedGroupTerminalPermission/useSelectedGroupTerminalPermission';

import { DeleteGroupTerminalPermissionContainer } from './deleteGroupTerminalPermission/DeleteGroupTerminalPermissionContainer';
import * as Styled from './GroupTerminalPermissionActions.styles';

export const GroupTerminalPermissionActions = () => {
  const { selectedGroupTerminalPermission } = useSelectedGroupTerminalPermission();

  return (
    <Styled.ActionButtonWrapper>
      {selectedGroupTerminalPermission && (
        <DeleteGroupTerminalPermissionContainer selectedPermission={selectedGroupTerminalPermission} />
      )}
    </Styled.ActionButtonWrapper>
  );
};
