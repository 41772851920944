import { useNavigate } from 'react-router-dom';

import { CardBlockTypeEnum, CardStatusEnum } from 'api/cards/cards.enum';
import { AppRouteWithParams } from 'routing/AppRoute.enum';
import { ReturnCardContainer } from 'reusable/returnCard/ReturnCardContainer';
import { ActionButton } from 'ui/actionButton/ActionButton';

import { CardActionsProps } from './CardActions.types';
import { UnarchiveContainer } from './unarchiveCard/UnarchiveCardContainer';
import { ArchiveCardContainer } from './archiveCard/ArchiveCardContainer';
import { DeleteCardContainer } from './deleteCard/DeleteCardContainer';
import { BlockCardContainer } from './blockCard/BlockCardContainer';
import { UnblockCardContainer } from './unblockCard/UnblockCardContainer';
import * as Styled from './CardActions.styles';

export const CardActions = ({ selectedCard, setSelectedCard }: CardActionsProps) => {
  const navigate = useNavigate();
  const isCardOwner = !!(selectedCard.posiadacz && Object.keys(selectedCard.posiadacz).length);

  return (
    <Styled.CardActionButtonsWrapper>
      {selectedCard.archiwalna ? null : selectedCard.blokada === CardBlockTypeEnum.RECZNA ? (
        <UnblockCardContainer selectedCard={selectedCard} />
      ) : (
        <BlockCardContainer selectedCard={selectedCard} />
      )}

      {selectedCard.archiwalna ? (
        <UnarchiveContainer selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
      ) : (
        !isCardOwner && <ArchiveCardContainer selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
      )}

      <ActionButton
        type="history"
        onClick={() => navigate(AppRouteWithParams.cardHistory.get({ id: selectedCard.id }))}
      />

      {[CardStatusEnum.WYDANA, CardStatusEnum.WYDANA_Z].includes(selectedCard.status) && !selectedCard.archiwalna && (
        <ReturnCardContainer
          cardId={selectedCard.id}
          cardNumber={selectedCard.numerKarty}
          renderActionComponent={(openModal) => <ActionButton type="return" onClick={openModal} />}
        />
      )}

      {!selectedCard.uzywana && <DeleteCardContainer selectedCard={selectedCard} />}
    </Styled.CardActionButtonsWrapper>
  );
};
