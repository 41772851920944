import { Button } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import * as Styled from '../DevicesMultiActions.styles';

import { ReloadMultiTerminalsProps } from './ReloadMultiTerminals.types';

export const ReloadMultiTerminals = ({ onSubmit, disabled }: ReloadMultiTerminalsProps) => {
  const { formatMessage } = useLocale();

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      startIcon={<Styled.RealoadIcon disabled={disabled} />}
      onClick={onSubmit}
      disabled={disabled}
    >
      {formatMessage({ id: AppMessages['devices.multiAction.reload'] })}
    </Button>
  );
};
