import { Typography } from '@mui/material';
import styled from 'styled-components';

export const AutocompleteOption = styled(Typography)`
  padding: 6px;
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const SelectOption = styled(Typography)`
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
`;
