import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { deleteGlc } from 'api/glc/glc';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useSelectedGlc } from 'hooks/useSelectedGlc/useSelectedGlc';
import { AppRoute, AppRouteWithParams } from 'routing/AppRoute.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteGlc } from './DeleteGlc';
import { DeleteGlcContainerProps } from './DeleteGlc.types';

export const DeleteGlcContainer = ({ selectedGlc }: DeleteGlcContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { setSelectedGlc } = useSelectedGlc();
  const navigate = useNavigate();
  const isHistoryView = useIsExactPath(AppRouteWithParams.glcHistory.path);
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteGlc, isLoading } = useMutation(deleteGlc, {
    onSuccess: () => {
      setSelectedGlc(undefined);
      queryClient.invalidateQueries([QueryKeyEnum.GLC]);
      isHistoryView && navigate(AppRoute.glc);
      toast.dark(formatMessage({ id: AppMessages['glc.delete.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteGlc(selectedGlc.id);
  };

  return (
    <DeleteGlc
      selectedGlc={selectedGlc}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
