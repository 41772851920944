import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useErrorHandler, useLoaderToast, ClientError, useQueryClient } from 'shared-ui';

import { editGroupTerminalPermission } from 'api/permissions/permissions';
import { AppMessages } from 'i18n/messages';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useSelectedGroupTerminalPermission } from 'hooks/useSelectedGroupTerminalPermission/useSelectedGroupTerminalPermission';
import { useMutation } from 'hooks/useMutation/useMutation';

import { GroupTerminalPermissionDetails } from './GroupTerminalPermissionDetails';
import {
  GroupTerminalPermissionDetailsContainerProps,
  GroupTerminalPermissionDetailsEditForm,
} from './GroupTerminalPermissionDetails.types';
import { useGroupTerminalPermissionDetailsValidation } from './GroupTerminalPermissionDetails.validation';

export const GroupTerminalPermissionDetailsContainer = ({
  selectedPermission,
  groupId,
}: GroupTerminalPermissionDetailsContainerProps) => {
  const { formatMessage } = useLocale();
  const { setSelectedGroupTerminalPermission } = useSelectedGroupTerminalPermission();
  const queryClient = useQueryClient();
  const { load: loadEditPermission, update: updateEditPermission } = useLoaderToast(
    `permission-${selectedPermission.id}`,
  );
  const { schema } = useGroupTerminalPermissionDetailsValidation();
  const { handleError } = useErrorHandler();

  const { mutate: onEditPermission, isLoading } = useMutation(editGroupTerminalPermission, {
    onMutate: () => loadEditPermission(formatMessage({ id: AppMessages['group.reader.permission.details.loader'] })),
    onSuccess: (_, { body }) => {
      setSelectedGroupTerminalPermission({ ...selectedPermission, ...body });
      queryClient.invalidateQueries([QueryKeyEnum.GROUP_TERMINALS_PERMISSIONS_SERVERS]);
      queryClient.invalidateQueries([QueryKeyEnum.GROUP_TERMINALS_PERMISSIONS]);
      updateEditPermission(formatMessage({ id: AppMessages['group.reader.permission.details.edit.success'] }));
    },
    onError: (error: ClientError<Error>) => {
      handleError({ error, updateToastCallback: updateEditPermission });
    },
  });

  const formMethods = useForm<GroupTerminalPermissionDetailsEditForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      otwierajZamek: selectedPermission.otwierajZamek,
      rejestrujOdbicie: selectedPermission.rejestrujOdbicie,
      rejestrujRcp: selectedPermission.rejestrujRcp,
    },
  });

  useEffect(() => {
    formMethods.reset(selectedPermission);
  }, [selectedPermission]);

  const onSubmit = (body: GroupTerminalPermissionDetailsEditForm) => {
    onEditPermission({
      groupId: groupId,
      permissionId: selectedPermission.id,
      body: { idTkd: selectedPermission.tkd.id, ...body },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <GroupTerminalPermissionDetails
        isLoading={isLoading}
        selectedPermission={selectedPermission}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
};
