import { Modal } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionButton } from 'ui/actionButton/ActionButton';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { DeletePositionProps } from './DeletePosition.types';

export const DeletePosition = ({
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
  isErrorModalOpen,
  selectedPosition,
}: DeletePositionProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <ActionButton type="delete" onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['definitions.position.delete.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        submitButtonDisabled={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['definitions.organization.delete.button'] })}
      >
        <ActionModal.Subtitle>{selectedPosition.nazwa}</ActionModal.Subtitle>
        <ActionModal.Text>{formatMessage({ id: AppMessages['definitions.position.delete.text'] })}</ActionModal.Text>
        <ActionModal.Text>{formatMessage({ id: AppMessages['common.irreversibleAction'] })}</ActionModal.Text>
      </ActionModal>

      <Modal
        header={formatMessage({ id: AppMessages['definitions.position.delete.title'] })}
        open={isErrorModalOpen}
        onClose={onModalClose}
        onSubmit={onModalClose}
        size="medium"
        type="info"
      >
        <>
          <ActionModal.Text bold>
            {formatMessage({ id: AppMessages['definitions.position.delete.error.owner.title'] })}
          </ActionModal.Text>
          <ActionModal.Text bold>
            {formatMessage({ id: AppMessages['definitions.position.delete.error.owner.description'] })}
          </ActionModal.Text>
        </>
      </Modal>
    </>
  );
};
