export enum PermissionEnum {
  NIEZDEFINIOWANO = 'NIEZDEFINIOWANO',
  BRAK = 'BRAK',
  PRZYZNANO = 'PRZYZNANO',
}

export enum PermissionEffectiveEnum {
  NIEZDEFINIOWANO = 'NIEZDEFINIOWANO',
  BRAK = 'BRAK',
  PRZYZNANO = 'PRZYZNANO',
  BRAK_INDYWIDUALNE = 'BRAK_INDYWIDUALNE',
  PRZYZNANO_INDYWIDUALNE = 'PRZYZNANO_INDYWIDUALNE',
}

export enum PermissionDesctiptionEnum {
  NIEZDEFINIOWANO = 'permissions.notDefined',
  BRAK = 'permissions.noRights',
  PRZYZNANO = 'permissions.granted',
}
