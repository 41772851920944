import styled, { css } from 'styled-components';
import MaterialTreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { Typography, IconButton } from '@mui/material';

export const TreeItem = styled(MaterialTreeItem)(
  ({ theme: { palette, typography } }) => css`
    margin: 8px 0 0;

    & .${treeItemClasses.content} {
      min-height: 50px;
      border-radius: 8px;
      padding: 8px;
      background-color: ${palette.common.white};
      color: ${palette.text.secondary};
      border: 1px solid ${palette.common.white};
      font-weight: ${typography.fontWeightMedium};

      &.Mui-expanded {
        font-weight: ${typography.fontWeightRegular};
      }

      &.Mui-focused {
        border: 1px solid ${palette.common.white};
      }

      &.Mui-selected,
      &.Mui-selected.Mui-focused {
        background-color: ${palette.common.white};
        border: 1px solid ${palette.grey[500]};
      }
      & .${treeItemClasses.label} {
        font-weight: inherit;
        color: inherit;
        padding-left: 0;
      }
    }

    & .${treeItemClasses.group} {
      & .${treeItemClasses.content} {
      }
    }

    & .${treeItemClasses.iconContainer} {
      width: 24px;
      margin: 0 22px 0 15px;
    }
  `,
);

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 45px;
  grid-template-columns: 150px 1fr;
`;

export const Code = styled(Typography)`
  font-weight: 700;
  margin-left: 15px;
`;

export const Name = styled(Typography)``;

export const AddButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: #eff0f5;
`;
