import { useLayoutEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { QueryKeyEnum } from 'core/global.enum';
import { fetchOwnerPhoto } from 'api/owners/owners';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { useQuery } from 'hooks/useQuery/useQuery';
import { Owner } from 'api/owners/owners.types';
import { useUpdateOwnerDetails } from 'hooks/useUpdateOwnerDetails/useUpdateOwnerDetails';

import { OwnerBasicDetails } from './OwnerBasicDetails';
import { OwnerBasicDetailsContainerProps, OwnerBasicDetailsForm } from './OwnerBasicDetails.types';
import { useOwnerBasicDetailsValidation } from './OwnerBasicDetails.validation';

export const OwnerBasicDetailsContainer = ({ selectedOwner, isEditDisabled }: OwnerBasicDetailsContainerProps) => {
  const { selectedOwner: globalSelectedOwner } = useSelectedOwner();
  const { schema } = useOwnerBasicDetailsValidation();

  const { onUpdateOwnerSubmit, isLoadingUpdateOwner } = useUpdateOwnerDetails(selectedOwner);

  const resetToSelected = (selected: Owner, photoFile?: FileList) => {
    return {
      imie: selected.imie || '',
      nazwisko: selected.nazwisko || '',
      drugieImie: selected.drugieImie || '',
      zdjecieUrl: selected.zdjecieUrl || '',
      zdjecieMime: selected.zdjecieMime || '',
      stanowisko: selected.stanowisko || '',
      firma: selected.firma || '',
      komorka: selected.komorka || '',
      ...(photoFile && { photoFile }),
    };
  };

  const shouldFetchOwnerPhoto = !!globalSelectedOwner && !!globalSelectedOwner.zdjecieUrl;

  const formMethods = useForm<OwnerBasicDetailsForm>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: resetToSelected(selectedOwner),
  });

  const { refetch: refetchOwnerPhoto, isInitialLoading: isFetchingOwnerPhoto } = useQuery(
    [QueryKeyEnum.OWNER_PHOTO, selectedOwner?.id],
    () => fetchOwnerPhoto(selectedOwner?.id),
    {
      onSuccess: (photoFile) => formMethods.reset(resetToSelected(selectedOwner, photoFile as unknown as FileList)),
      enabled: shouldFetchOwnerPhoto,
      handleErrorManually: true,
    },
  );

  useLayoutEffect(() => {
    !shouldFetchOwnerPhoto && formMethods.reset(resetToSelected(selectedOwner));
  }, [globalSelectedOwner]);

  const onSubmit = (body: OwnerBasicDetailsForm) => {
    const { photoFile, ...rest } = body;
    onUpdateOwnerSubmit({
      ...selectedOwner,
      ...rest,
      drugieImie: body.drugieImie || null,
      stanowisko: typeof body.stanowisko === 'object' ? body.stanowisko : undefined,
      firma: typeof body.firma === 'object' ? body.firma : undefined,
      komorka: Array.isArray(body.komorka) ? body.komorka : undefined,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <OwnerBasicDetails
        isEditDisabled={isEditDisabled}
        isLoadingUpdateOwner={isLoadingUpdateOwner}
        selectedOwner={selectedOwner}
        onSubmit={onSubmit}
        refetchOwnerPhoto={refetchOwnerPhoto}
        resetToSelected={resetToSelected}
        isFetchingOwnerPhoto={isFetchingOwnerPhoto}
      />
    </FormProvider>
  );
};
