import { useSelectedGroupGlcPermission } from 'hooks/useSelectedGroupGlcPermission/useSelectedGroupGlcPermission';

import { DeleteGroupGlcPermissionContainer } from './deleteGroupGlcPermission/DeleteGroupGlcPermissionContainer';
import * as Styled from './GroupGlcPermissionActions.styles';

export const GroupGlcPermissionActions = () => {
  const { selectedGroupGlcPermission } = useSelectedGroupGlcPermission();

  return (
    <Styled.ActionButtonWrapper>
      {selectedGroupGlcPermission && (
        <DeleteGroupGlcPermissionContainer selectedPermission={selectedGroupGlcPermission} />
      )}
    </Styled.ActionButtonWrapper>
  );
};
