import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { downloadCardsImportExample } from 'api/cards/cards';
import { downloadOwnersImportExample, downloadOwnersWithPhotoImportExample } from 'api/owners/owners';
import { DownloadExampleFileResponse } from 'api/owners/owners.types';

import { DownloadExampleFileProps } from './DownloadExampleFile.types';
import * as Styled from './DownloadExampleFile.styles';

export const DownloadExampleFile = ({ exampleType }: DownloadExampleFileProps) => {
  const { formatMessage } = useLocale();

  const createDownloadLink = (exampleText: string) => {
    const element = document.createElement('a');
    const file = new Blob([exampleText], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = 'example.txt';
    document.body.appendChild(element);
    element.click();
  };

  const apiCallFn = {
    cards: downloadCardsImportExample,
    owners: downloadOwnersImportExample,
    ownersWithPhoto: downloadOwnersWithPhotoImportExample,
  }[exampleType];

  const { mutate: onDownloadImportExample } = useMutation(apiCallFn, {
    onSuccess: (response: DownloadExampleFileResponse) => createDownloadLink(response.example),
  });

  return (
    <Styled.Tooltip
      placement="right"
      arrow
      title={
        <Styled.TooltipTitleWrapper>
          <Styled.Text>{formatMessage({ id: AppMessages['downloadExampleFile.text'] })}</Styled.Text>

          <Styled.DownloadButton
            variant="contained"
            size="small"
            startIcon={<DownloadIcon />}
            onClick={() => onDownloadImportExample()}
          >
            {formatMessage({ id: AppMessages['downloadExampleFile.button'] })}
          </Styled.DownloadButton>
        </Styled.TooltipTitleWrapper>
      }
    >
      <InfoIcon />
    </Styled.Tooltip>
  );
};
