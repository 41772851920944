import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { ClientError, APIError } from 'shared-ui';
import { useState } from 'react';

import { useModalState } from 'hooks/useModalState/useModalState';
import { useMutation } from 'hooks/useMutation/useMutation';
import { updateTerminal } from 'api/terminals/terminals';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { DeleteTerminal } from './DeleteTerminal';
import { DeleteTerminalContainerProps } from './DeleteTerminal.types';

export const DeleteTerminalContainer = ({ selectedTerminal }: DeleteTerminalContainerProps) => {
  const { isModalOpen, onModalClose, onModalOpen } = useModalState();
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();

  const [cannotDelete, setCannotDelete] = useState(false);

  const { mutate: onUpdateTerminal, isLoading } = useMutation(updateTerminal, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.TERMINALS_WITH_DISPLAY]);
      toast.dark(formatMessage({ id: AppMessages['definitions.display.delete.success'] }));
      onModalClose();
    },
    onError: (error: ClientError<APIError>) => {
      if (error.response?.data.code === 'aktywniPoszukiwani') {
        setCannotDelete(true);
      }
    },
  });

  const onSubmit = () => {
    onUpdateTerminal({
      id: selectedTerminal.id,
      body: {
        wyswietlacz: false,
      },
    });
  };

  return (
    <DeleteTerminal
      selectedTerminal={selectedTerminal}
      isModalOpen={isModalOpen}
      onModalClose={() => {
        setCannotDelete(false);
        onModalClose();
      }}
      onModalOpen={onModalOpen}
      onSubmit={onSubmit}
      isLoading={isLoading}
      cannotDelete={cannotDelete}
    />
  );
};
