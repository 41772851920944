import styled from 'styled-components';

import { ReactComponent as TerminalsIconBase } from 'assets/icons/terminals-active.svg';
import { ReactComponent as InformationBase } from 'assets/icons/information.svg';

export const TerminalsIcon = styled(TerminalsIconBase)`
  cursor: pointer;
`;

export const InformationIcon = styled(InformationBase)`
  cursor: pointer;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
`;
