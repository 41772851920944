import { Modal } from 'shared-ui';

import * as Styled from './ActionModal.styles';
import { ActionModalProp, SubtitleProps, TextProps } from './ActionModal.types';

export const ActionModal = (props: ActionModalProp) => {
  return (
    <Modal {...props} size={props.size || 'small'} type="form">
      {props.children}
    </Modal>
  );
};

const Subtitle = ({ children }: SubtitleProps) => <Styled.Subtitle variant="h5">{children}</Styled.Subtitle>;

const Text = ({ children, bold, ...rest }: TextProps) => (
  <Styled.Text {...rest} variant={rest.variant || 'subtitle1'} bold={bold}>
    {children}
  </Styled.Text>
);

ActionModal.Subtitle = Subtitle;
ActionModal.Text = Text;
