import {
  Autocomplete as MultiAutocompleteMaterial,
  AutocompleteProps,
  TextField,
  InputLabel,
  TextFieldProps,
} from '@mui/material';
import styled, { css } from 'styled-components';

import { InputStylesProps, Option } from './MultiAutocomplete.types';

const autcompleteSizes = {
  padding: {
    small: '2.5px 8px',
    medium: '5px 8px',
    big: '6px 8px',
  },
};

const selectListElementStyles = css(
  ({ theme: { palette } }) => css`
    color: ${palette.primary.main};
    background-color: ${palette.grey.A200};
  `,
);

export const MultiAutocomplete = styled(({ className, ...props }: AutocompleteProps<Option, true, boolean, false>) => (
  <MultiAutocompleteMaterial
    classes={{
      root: 'root',
      popupIndicator: 'selectIcon',
      paper: className,
      option: 'option',
      listbox: 'listbox',
      clearIndicator: 'clearIcon',
    }}
    {...props}
  />
))(
  ({ theme: { palette, shape } }) => css`
    background-color: ${palette.grey.A200};
    box-shadow: none;
    border-radius: ${shape.borderRadius}px;
    border: 1px solid ${palette.grey[500]};

    & .selectIcon {
      right: 6px;
    }

    & .listbox {
      padding: 0px;
    }

    & .option {
      color: ${palette.grey[900]};
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start !important;
      border-bottom: 1px solid ${palette.grey[500]};
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      padding: 8px !important;
      background-color: ${palette.grey.A200};

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        color: ${palette.primary.main};
        background-color: ${palette.grey[300]};
      }

      &[aria-selected='true'] {
        color: ${palette.primary.main};
        background-color: ${palette.grey.A200};
      }

      &:focus {
        ${selectListElementStyles};
      }
      &:active {
        ${selectListElementStyles};
      }
    }

    & .clearIcon {
      visibility: visible;
      margin-right: 2px;
    }
  `,
);

export const Input = styled(({ className, ...props }: TextFieldProps & InputStylesProps) => (
  <TextField {...props} classes={{ root: className }} />
))(
  ({ theme: { palette, shape }, $inputSize, $stopRotatePopupIndicator, disabled }) => css`
    & .MuiFilledInput-root {
      background-color: ${palette.grey[100]};
      color: ${palette.text.primary};
      border-radius: ${shape.borderRadius}px;
      font-weight: 700;
      text-align: center;
      padding: ${autcompleteSizes.padding[$inputSize || 'medium']} !important;
      padding-right: 20px !important;

      input {
        min-width: 100px;
      }

      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }

    & .MuiOutlinedInput-root {
      padding: ${autcompleteSizes.padding[$inputSize || 'medium']} !important;
    }

    & ::placeholder {
      color: ${palette.text.primary};
      font-weight: 500;
      opacity: 1;
    }

    & .selectIcon {
      right: 6px;
    }

    & .MuiAutocomplete-popupIndicatorOpen {
      transform: ${$stopRotatePopupIndicator ? 'rotate(0)' : 'rotate(180)'};
    }

    & .MuiChip-root {
      background-color: ${palette.grey[300]};
      height: 28px;
    }

    & span.MuiAutocomplete-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
      border-radius: 50%;
      background-color: ${palette.grey[300]};
      height: 28px;

      ${disabled &&
      css`
        opacity: 0.5;
      `}
    }
  `,
);

export const Label = styled(InputLabel)`
  margin-bottom: 8px;
  font-weight: 700;
`;

export const Required = styled.span(
  ({ theme: { palette } }) => css`
    color: ${palette.error.main};
  `,
);

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

export const Chip = styled.span(
  ({ theme: { palette } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    border-radius: 50%;
    background-color: ${palette.grey[300]};
    height: 28px;
    min-width: 28px;
    font-size: 12px;
  `,
);

export const Tag = styled.div(
  ({ theme: { palette } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${palette.grey[300]};
    height: 28px;
    font-size: 12px;
    padding: 0 12px;
    border-radius: 16px;
    margin: 3px;
    max-width: calc(100% - 6px);
    white-space: nowrap;
  `,
);
