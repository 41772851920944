import { useEffect, useState } from 'react';
import { ErrorBoundary, usePagination } from 'shared-ui';

import { fetchUsers } from 'api/users/users';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';

import { Users } from './Users';

export const UsersContainer = () => {
  const {
    storedQueries: { users: usersQuery },
  } = useLocaleStoreQuery();
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();
  const [tableSearchQuery, setTableSearchQuery] = useState('');

  const { data: usersResponse, isLoading: isLoadingUsers } = useQuery(
    [QueryKeyEnum.USERS, usersQuery, page, rowsPerPage, tableSearchQuery],
    fetchUsers,
    {
      args: {
        ...usersQuery,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        query: tableSearchQuery,
      },
    },
  );

  useEffect(() => {
    setPage(0);
  }, [tableSearchQuery, usersQuery]);

  return (
    <ErrorBoundary>
      <Users
        users={usersResponse?.data || []}
        isLoadingUsers={isLoadingUsers}
        usersCount={usersResponse?.count || 0}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setTableSearchQuery={setTableSearchQuery}
      />
    </ErrorBoundary>
  );
};
