import { useFormContext } from 'react-hook-form';
import { Button } from 'shared-ui';

import { ReactComponent as EditIcon } from 'assets/icons/permission-key.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';
import { PermissionEnum } from 'api/permissions/permissions.enum';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { Form } from 'ui/form/Form';
import * as Styled from '../OwnerIndividualPermissionsMultiActions.styles';

import {
  EditOwnerIndividualPermissionsFormBody,
  EditOwnerIndividualPermissionsProps,
} from './EditOwnerIndividualPermissions.types';

export const EditOwnerIndividualPermissions = ({
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
  checkedPermissions,
}: EditOwnerIndividualPermissionsProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
  } = useFormContext<EditOwnerIndividualPermissionsFormBody>();

  const { permissionOptions } = useAppSelectOptions();

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<EditIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['permissions.multiAction.edit.button'] })}
      </Button>

      <ActionModal
        header={formatMessage({ id: AppMessages['permissions.multiAction.edit.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={handleSubmit(onSubmit)}
        isLoading={isLoading}
        submitButtonDisabled={isLoading || !!Object.keys(errors).length}
        submitButtonText={formatMessage({ id: AppMessages['permissions.multiAction.edit.submit'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['permissions.multiAction.edit.subtitle'] })} {checkedPermissions.length}
        </ActionModal.Subtitle>
        <Styled.FormWrapper>
          <Form.ControlledSelect
            name="rejestrujOdbicie"
            control={control}
            isError={!!errors.rejestrujRcp?.message}
            onChange={(e) => {
              setValue('rejestrujOdbicie', e.target.value as PermissionEnum, { shouldDirty: true });
              errors.rejestrujRcp?.message && trigger('rejestrujRcp');
            }}
            label={formatMessage({ id: AppMessages['group.reader.permissions.field.registerRecord.label'] })}
            data-testId="odbicie-select"
            options={permissionOptions}
          />

          <Form.ControlledSelect
            name="otwierajZamek"
            control={control}
            isError={!!errors.rejestrujRcp?.message}
            onChange={(e) => {
              setValue('otwierajZamek', e.target.value as PermissionEnum, { shouldDirty: true });
              errors.rejestrujRcp?.message && trigger('rejestrujRcp');
            }}
            label={formatMessage({ id: AppMessages['group.reader.permissions.field.openLock.label'] })}
            data-testId="zamek-select"
            options={permissionOptions}
          />

          <Form.ControlledSelect
            name="rejestrujRcp"
            control={control}
            onChange={(e) => setValue('rejestrujRcp', e.target.value as PermissionEnum, { shouldDirty: true })}
            withHelperText
            errorMessage={errors.rejestrujRcp?.message}
            isError={!!errors.rejestrujRcp?.message}
            label={formatMessage({ id: AppMessages['group.reader.permissions.field.registerRcp.label'] })}
            data-testId="rcp-select"
            options={permissionOptions}
          />
        </Styled.FormWrapper>
      </ActionModal>
    </>
  );
};
