export const transformTreeToFlatArray = <T extends Record<string, unknown>>(tree: T[] | undefined) => {
  if (!tree) return [];

  const arr: T[] = [];

  const collectTreeElements = (nodes: T) => {
    arr.push({ ...nodes, children: undefined });
    Array.isArray(nodes.children) && nodes.children.map((node) => collectTreeElements(node));
  };
  tree.map((organization) => collectTreeElements(organization));

  return arr;
};
