import { useEffect, useState } from 'react';
import { useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { addMultiOwners, fetchGroupDetails } from 'api/groups/groups';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchOwners } from 'api/owners/owners';
import { OwnerRegistrationNumberSortEnum, OwnersQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useModalState } from 'hooks/useModalState/useModalState';

import { AddPosition } from './AddGroupAssignedOwners';
import { useAddGroupAssignedOwnersValidation } from './AddGroupAssignedOwners.validation';
import { AddOwnersToGroupsFormBody } from './AddGroupAssignedOwners.types';

export const AddGroupAssignedOwnersContainer = () => {
  const [ownersQuery, setOwnersQuery] = useState('');

  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { schema } = useAddGroupAssignedOwnersValidation();
  const { id } = useParams<{ id: string }>();

  const { data: ownersResponse, isLoading: isLoadingOwners } = useQuery(
    [QueryKeyEnum.OWNERS_SEARCH, ownersQuery],
    fetchOwners,
    {
      args: {
        query: ownersQuery,
        offset: 0,
        limit: 100,
        sort: [OwnerRegistrationNumberSortEnum.ASC],
        [OwnersQueryKeysEnum.ARCHIWALNY]: false,
      },
    },
  );

  const methods = useForm<AddOwnersToGroupsFormBody>({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { mutate: onAddOwnersToGroups, isLoading } = useMutation(addMultiOwners, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.GROUP_ASSIGNED_OWNERS]);
      toast.dark(formatMessage({ id: AppMessages['group.addOwner.successMessage'] }));
      onModalClose();
    },
  });

  const { data: groupResponse } = useQuery([QueryKeyEnum.GROUP_ASSIGNED_OWNERS, id], () => fetchGroupDetails(id), {
    enabled: !!id,
  });

  const onSubmit = ({ idPkds }: AddOwnersToGroupsFormBody) => {
    onAddOwnersToGroups({ idGrup: id ? [id] : [], idPkds: idPkds.map(({ value }) => `${value}`) });
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...methods}>
      <AddPosition
        onSubmit={onSubmit}
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        isLoading={isLoading}
        owners={ownersResponse?.data || []}
        isLoadingOwners={isLoadingOwners}
        group={groupResponse}
        setOwnersQuery={setOwnersQuery}
        ownersQuery={ownersQuery}
      />
    </FormProvider>
  );
};
