import { Table } from 'ui/table/Table';

import { EventsExportFormatsActions } from './eventsExportFormatsActions/EventsExportFormatsActions';
import { EventsExportFormatsTableRowProps } from './EventsExportFormatsTableRow.types';

export const EventsExportFormatsTableRow = ({ eventExportFormat }: EventsExportFormatsTableRowProps) => {
  return (
    <>
      <Table.RowItem>{eventExportFormat.nazwa}</Table.RowItem>

      <EventsExportFormatsActions eventExportFormat={eventExportFormat} />
    </>
  );
};
