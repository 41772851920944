import { fetchTerminals } from 'api/terminals/terminals';
import { TerminalsQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';

import { Terminals } from './Terminals';
import { TerminalsContainerProps } from './Terminals.types';

export const TerminalsContainer = ({
  serverStatus,
  checkedTerminals,
  setCheckedTerminals,
  setTerminalsCount,
  expandedServer,
}: TerminalsContainerProps) => {
  const {
    storedQueries: { terminals: terminalsQuery },
  } = useLocaleStoreQuery();

  const { data: terminals, isInitialLoading } = useQuery(
    [QueryKeyEnum.TERMINALS, expandedServer?.id, terminalsQuery],
    () => fetchTerminals(expandedServer?.id),
    {
      args: { ...terminalsQuery, [TerminalsQueryKeysEnum.GLC_IDS]: terminalsQuery.glcIds?.map(({ id }) => id) },
      refetchInterval: 5000,
      onSuccess: (response) => {
        expandedServer && setTerminalsCount(response.count);
      },
      enabled: !!expandedServer,
    },
  );

  return (
    <Terminals
      terminals={terminals?.data}
      isLoading={isInitialLoading}
      serverStatus={serverStatus}
      checkedTerminals={checkedTerminals}
      setCheckedTerminals={setCheckedTerminals}
    />
  );
};
