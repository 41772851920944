import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { unarchiveOwner } from 'api/owners/owners';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { UnarchiveOwner } from './UnarchiveOwner';
import { UnarchiveOwnerContainerProps } from './UnarchiveOwner.types';

export const UnarchiveOwnerContainer = ({ selectedOwner, setSelectedOwner }: UnarchiveOwnerContainerProps) => {
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onUnarchiveOwner, isLoading } = useMutation(unarchiveOwner, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.OWNERS]);
      setSelectedOwner(undefined);

      toast.dark(formatMessage({ id: AppMessages['owner.unarchive.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onUnarchiveOwner(selectedOwner.id);
  };

  return (
    <UnarchiveOwner
      selectedOwner={selectedOwner}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
