import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { deleteControl } from 'api/control/control';

import { DeleteRandomControl } from './DeleteRandomControl';
import { DeleteRandomControlContainerProps } from './DeleteRandomControl.types';

export const DeleteRandomControlContainer = ({ owner }: DeleteRandomControlContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();

  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteRandomControl, isLoading } = useMutation(deleteControl, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.RANDOM_CONTROL_OWNERS]);
      queryClient.invalidateQueries([QueryKeyEnum.OWNERS_SEARCH]);
      toast.dark(formatMessage({ id: AppMessages['control.deleteOwner.successMessage'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteRandomControl(owner.id);
  };

  return (
    <DeleteRandomControl
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
