import { FilterOptionsState } from '@mui/material';
import { Option } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { fetchGlc } from 'api/glc/glc';
import { useQuery } from 'hooks/useQuery/useQuery';
import { GlcNumberSortEnum, GlcQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import * as Styled from '../TableSearch.styles';

export const useSearchByGlc = (optionsQuery: string, isEnabled: boolean, serverId?: string) => {
  const { formatMessage } = useLocale();

  const { data: glcResponse, isInitialLoading } = useQuery(
    [QueryKeyEnum.GLC_SEARCH, optionsQuery, serverId],
    fetchGlc,
    {
      args: { query: optionsQuery, sort: [GlcNumberSortEnum.ASC], [GlcQueryKeysEnum.SERVER_ID]: serverId },
      enabled: isEnabled,
    },
  );

  const glcGroups = glcResponse?.data || [];

  const createOptions = () => {
    return glcGroups.map((glc) => ({
      value: glc.id,
      label: glc.nazwa,
      id: glc.idGlc,
    }));
  };

  const filterOptions = (options: Option[], { inputValue }: FilterOptionsState<Option>) => {
    const standarizedInputValue = inputValue.toLowerCase();

    return options.filter(
      ({ label, value, ...rest }) =>
        label.toLowerCase().includes(standarizedInputValue) ||
        `${value}`.toLowerCase().includes(standarizedInputValue) ||
        `${rest?.id}`.toLowerCase().includes(standarizedInputValue),
    );
  };

  const createOptionLabel = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <li {...optionProps}>
        <Styled.OptionNumber>
          {formatMessage({ id: AppMessages['tableSearch.glc.id'] })}{' '}
          {glcGroups.find((glc) => glc.id === option.value)?.idGlc}
        </Styled.OptionNumber>

        <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
      </li>
    );
  };

  return { isLoading: isInitialLoading, createOptions, createOptionLabel, filterOptions };
};
