import { useState } from 'react';

import { Permission } from 'api/permissions/permissions.types';
import { SelectedOwnerIndividualPermissionContext } from '../selectedOwnerIndividualPermissionContext/SelectedOwnerIndividualPermissionContext';

import { SelectedOwnerIndividualPermissionContextControllerProps } from './SelectedOwnerIndividualPermissionContextController.types';

export const SelectedOwnerIndividualPermissionContextController = ({
  children,
}: SelectedOwnerIndividualPermissionContextControllerProps) => {
  const [selectedOwnerIndividualPermission, setSelectedOwnerIndividualPermission] = useState<
    Permission<true> | undefined
  >(undefined);

  return (
    <SelectedOwnerIndividualPermissionContext.Provider
      value={{ selectedOwnerIndividualPermission, setSelectedOwnerIndividualPermission }}
    >
      {children}
    </SelectedOwnerIndividualPermissionContext.Provider>
  );
};
