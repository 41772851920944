import { useEffect, useState } from 'react';
import { useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchOwners } from 'api/owners/owners';
import { OwnerRegistrationNumberSortEnum, OwnersQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useModalState } from 'hooks/useModalState/useModalState';
import { MultiTreeSelectGroupTypeEnum } from 'ui/organizationsTreeSelect/treeMultiSelect/groupedTreeMultiSelectBody/GroupedTreeMultiSelectBody.enum';
import { addAnnouncementOwners, fetchAnnouncementDetails } from 'api/announcements/announcements';

import { AddPosition } from './AddAnnouncementAssignedOwners';
import { useAddAnnouncementAssignedOwnersValidation } from './AddAnnouncementAssignedOwners.validation';
import { AddOwnersToAnnouncementFormBody } from './AddAnnouncementAssignedOwners.types';

export const AddAnnouncementAssignedOwnersContainer = () => {
  const [selectedOwnersGroup, setSelectedOwnersGroup] = useState<MultiTreeSelectGroupTypeEnum>(
    MultiTreeSelectGroupTypeEnum.EXTRA,
  );

  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { schema } = useAddAnnouncementAssignedOwnersValidation();
  const { id } = useParams<{ id: string }>();

  const { data: ownersResponse } = useQuery([QueryKeyEnum.OWNERS_SEARCH], fetchOwners, {
    args: {
      offset: 0,
      limit: 100,
      sort: [OwnerRegistrationNumberSortEnum.ASC],
      [OwnersQueryKeysEnum.ARCHIWALNY]: false,
    },
  });

  const methods = useForm<AddOwnersToAnnouncementFormBody>({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { mutate: onAddOwnersToAnnouncement, isLoading } = useMutation(addAnnouncementOwners, {
    onSuccess: (response) => {
      queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENT_ASSIGNED_OWNERS]);

      response.count > 1
        ? toast.dark(formatMessage({ id: AppMessages['announcement.addOwner.successMessageMulti'] }))
        : toast.dark(formatMessage({ id: AppMessages['announcement.addOwner.successMessage'] }));

      onModalClose();
    },
  });

  const { data: announcementResponse } = useQuery(
    [QueryKeyEnum.ANNOUNCEMENT_ASSIGNED_OWNERS, id],
    () => fetchAnnouncementDetails(id),
    {
      enabled: !!id,
    },
  );

  const onSubmit = ({ ids }: AddOwnersToAnnouncementFormBody) => {
    const selectedKey = selectedOwnersGroup === MultiTreeSelectGroupTypeEnum.EXTRA ? 'idPkds' : 'idKomorek';

    onAddOwnersToAnnouncement({
      id: id || '',
      idKomorek: [],
      idPkds: [],
      [selectedKey]: typeof ids === 'string' ? [ids] : ids?.map(({ id }) => id) || [],
    });
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...methods}>
      <AddPosition
        onSubmit={onSubmit}
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        isLoading={isLoading}
        owners={ownersResponse?.data || []}
        announcement={announcementResponse}
        setSelectedOwnersGroup={setSelectedOwnersGroup}
      />
    </FormProvider>
  );
};
