import { Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { PageLayout, Tooltip, Button, SettingItem } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { DefinitionsBar } from '../definitionsBar/DefinitionsBar';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { Input } from 'ui/input/Input';

import * as Styled from './OwnersControl.styles';
import { UpdateOwnersControlFormBody, OwnersControlProps } from './OwnersControl.types';

export const OwnersControl = ({ onSubmit, isLoading }: OwnersControlProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    trigger,
    formState: { errors, isValid },
  } = useFormContext<UpdateOwnersControlFormBody>();

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['definitions.title'] })}
      renderContent={() => (
        <>
          <DefinitionsBar />

          <SettingItem
            header={
              <>
                <Typography variant="h4">{formatMessage({ id: AppMessages['definitions.control.title'] })}</Typography>
                <Tooltip
                  placement="right-end"
                  title={formatMessage({ id: AppMessages['definitions.control.tooltip'] })}
                >
                  <Styled.IconWrapper>
                    <InfoIcon />
                  </Styled.IconWrapper>
                </Tooltip>
              </>
            }
          >
            <Styled.Form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="notMoreOftenThan"
                render={({ field }) => (
                  <Input
                    {...field}
                    value={field.value || ''}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      trigger('notLessOftenThan');
                    }}
                    label={formatMessage({ id: AppMessages['definitions.control.notMoreOftenThan.label'] })}
                    placeholder={formatMessage({ id: AppMessages['definitions.control.placeholder'] })}
                    type="number"
                    errorMessage={errors?.notMoreOftenThan?.message}
                  />
                )}
              />
              <Controller
                name="notLessOftenThan"
                render={({ field }) => (
                  <Input
                    {...field}
                    value={field.value || ''}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      trigger('notMoreOftenThan');
                    }}
                    label={formatMessage({ id: AppMessages['definitions.control.notLessOftenThan.label'] })}
                    placeholder={formatMessage({ id: AppMessages['definitions.control.placeholder'] })}
                    type="number"
                    errorMessage={errors?.notLessOftenThan?.message}
                  />
                )}
              />
              <Styled.SubmitWrapper>
                <Button
                  isLoading={isLoading}
                  variant="contained"
                  color="primary"
                  type={'submit'}
                  size="small"
                  disabled={!isValid}
                >
                  {formatMessage({ id: AppMessages['common.save'] })}
                </Button>
              </Styled.SubmitWrapper>
            </Styled.Form>
          </SettingItem>
        </>
      )}
    />
  );
};
