import { Controller, useFormContext } from 'react-hook-form';
import { Modal, Button } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { Form } from 'ui/form/Form';

import { AddGlcFormBody, AddGlcProps } from './AddGlc.types';
import * as Styled from './AddGlc.styles';

export const AddGlc = ({
  onSubmit,
  onModalOpen,
  onModalClose,
  isModalOpen,
  isLoadingAddGlc,
  terminals,
  servers,
  isLoadingTerminals,
}: AddGlcProps) => {
  const { formatMessage } = useLocale();
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useFormContext<AddGlcFormBody>();

  const getTerminalsOptions = () => {
    return terminals.map((terminal) => ({
      label: terminal.nazwa,
      value: terminal.id,
      number: terminal.numerLogicznyKontrolera,
    }));
  };

  const { serverOptions } = useAppSelectOptions({ servers });

  return (
    <>
      <Button variant="contained" color="primary" onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['glc.addGlc.title'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['glc.addGlc.title'] })}
        onClose={onModalClose}
        open={isModalOpen}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid || isLoadingAddGlc || !!Object.keys(errors).length}
      >
        <Form.Grid>
          <Controller
            name="idGlc"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required
                label={formatMessage({ id: AppMessages['glc.addGlc.field.id.label'] })}
                placeholder={formatMessage({ id: AppMessages['glc.addGlc.field.id.placeholder'] })}
                errorMessage={errors?.idGlc?.message}
              />
            )}
          />

          <Controller
            name="nazwa"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required
                label={formatMessage({ id: AppMessages['glc.addGlc.field.name.label'] })}
                placeholder={formatMessage({
                  id: AppMessages['glc.addGlc.field.name.placeholder'],
                })}
                errorMessage={errors?.nazwa?.message}
              />
            )}
          />

          <Form.ControlledSelect
            name="serwerId"
            control={control}
            value={watch('serwerId')}
            onChange={(e) => {
              setValue('serwerId', e.target.value as string, { shouldDirty: true });
              setValue('tkds', []);
            }}
            label={formatMessage({ id: AppMessages['glc.addGlc.field.server.label'] })}
            withHelperText
            required
            options={serverOptions}
          />

          <Controller
            name="tkds"
            control={control}
            render={({ field }) => (
              <MultiAutocomplete
                {...field}
                withLimitedTags
                stopRotatePopupIndicator
                inputSize="big"
                limitTags={1}
                onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                options={getTerminalsOptions()}
                loading={isLoadingTerminals}
                loadingText={formatMessage({ id: AppMessages['common.loading'] })}
                label={formatMessage({ id: AppMessages['glc.addGlc.field.reader.label'] })}
                noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
                placeholder={formatMessage({ id: AppMessages['glc.addGlc.field.reader.placeholder'] })}
                renderOption={renderMultiAutocompleteOption}
              />
            )}
          />

          <Styled.DescriptionInputWrapper>
            <Controller
              name="opis"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label={formatMessage({ id: AppMessages['glc.addGlc.field.description.label'] })}
                  placeholder={formatMessage({
                    id: AppMessages['glc.addGlc.field.description.placeholder'],
                  })}
                  errorMessage={errors?.opis?.message}
                />
              )}
            />
          </Styled.DescriptionInputWrapper>
        </Form.Grid>
      </Modal>
    </>
  );
};
