import { useContext } from 'react';

import { SelectedGroupTerminalPermissionContext } from 'context/selectedGroupTerminalPermission/selectedGroupTerminalPermissionContext/SelectedGroupTerminalPermissionContext';

export const useSelectedGroupTerminalPermission = () => {
  const context = useContext(SelectedGroupTerminalPermissionContext);

  if (context === undefined) {
    throw new Error(
      'SelectedGroupTerminalPermissionContext is unavailable, make sure you are using SelectedGroupTerminalPermissionContextController',
    );
  }

  return context;
};
