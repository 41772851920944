import { TextField, InputLabel, FormHelperText as MaterialFormHelperText } from '@mui/material';
import styled, { css } from 'styled-components';

import { HelperTextStylesProps, InputStylesProps } from './Input.types';

export const Input = styled(({ className, ...props }) => (
  <TextField classes={{ root: className }} {...props} />
))<InputStylesProps>(
  ({ $isError, $isValue, $isClickable, $smallPlaceholderFontSize, variant, theme: { palette, shape } }) => css`
    & .MuiFilledInput-root {
      background-color: ${palette.grey[100]};
      color: ${$isError ? palette.error.main : palette.text.primary};
      border-radius: ${shape.borderRadius}px;
      font-weight: 700;

      &::before {
        content: none;
      }

      input[type='password'] {
        font-family: Verdana;
        letter-spacing: 4px;
      }
    }

    & .MuiFilledInput-input.MuiInputBase-inputSizeSmall {
      padding-top: ${$isValue && !$isError && '12.5px'};
      padding-bottom: ${$isValue && !$isError && '12.5px'};
    }

    & .MuiInputBase-multiline {
      padding-top: ${$isValue && 0};
      padding-bottom: ${$isValue && 0};
      padding-top: ${$isError && '23px'};
      padding-bottom: ${$isError && '12px'};
    }

    & .MuiFilledInput-root.Mui-focused {
      background-color: ${palette.grey[200]};
    }

    & .MuiFilledInput-underline:before {
      border-bottom: none;
    }

    & .MuiFilledInput-underline:after {
      border-bottom: none;
    }

    & .MuiFormLabel-root {
      color: ${$isError ? palette.error.main : palette.text.primary};
      font-weight: 500;
      top: ${variant === 'outlined' && '5px'};
      font-size: ${$smallPlaceholderFontSize && '13px'};
    }

    & .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
      border-radius: ${shape.borderRadius}px;
    }

    & .MuiOutlinedInput-input {
      padding: 13.5px 16px;
      font-weight: 700;
    }

    & .css-1i7e0ou-MuiFormLabel-root-MuiInputLabel-root {
      transform: translate(14.5px, 16px) scale(1);
    }

    & .css-1ihe0i9-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border-color: ${palette.grey[400]};
    }

    & .Mui-disabled {
      cursor: ${$isClickable ? 'pointer' : 'not-allowed'};
      -webkit-text-fill-color: ${palette.text.primary};
      opacity: ${$isClickable ? 1 : 0.6};
    }

    & input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `,
);

export const Label = styled(InputLabel)`
  margin-bottom: 8px;
  font-weight: 700;
`;

export const Required = styled.span(
  ({ theme: { palette } }) => `
  color: ${palette.error.main};
`,
);

export const FormHelperText = styled(MaterialFormHelperText)<HelperTextStylesProps>(
  ({ $isHelperText, $isError, theme: { palette } }) => css`
    display: block;
    font-weight: 700;
    color: ${palette.error.main};
    color: ${$isHelperText && !$isError && palette.grey[800]};
  `,
);
