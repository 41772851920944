import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'shared-ui';

import { Server } from 'api/servers/servers.types';
import { Input } from 'ui/input/Input';
import { AddressIpMaskedInput } from 'ui/addressIpMaskedInput/AddressIpMaskedInput';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ServerActions } from '../serverDetails/serverActions/ServerActions';

import { useServerDetailsEditValidation } from './ServerDetailsEdit.validation';
import { ServerDetailsEditProps } from './ServerDetailsEdit.types';
import * as Styled from './ServerDetailsEdit.styles';

export const ServerDetailsEdit = ({ selectedServer, isLoading, onSubmit, servers }: ServerDetailsEditProps) => {
  const { formatMessage } = useLocale();
  const { schema } = useServerDetailsEditValidation(selectedServer, servers);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, errors, isValid },
    watch,
    trigger,
  } = useForm<Server>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: selectedServer,
  });

  const port = watch('port');
  const host = watch('host');

  useEffect(() => {
    port && trigger('port');
  }, [host]);

  useEffect(() => {
    reset(selectedServer);
  }, [selectedServer]);

  return (
    <>
      <ServerActions selectedServer={selectedServer} />

      <Styled.Form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Styled.InputWrapper>
            <Controller
              name="nazwa"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label={formatMessage({ id: AppMessages['devices.details.name'] })}
                  placeholder={formatMessage({ id: AppMessages['devices.details.placeholder.name'] })}
                  errorMessage={errors?.nazwa?.message}
                />
              )}
            />
          </Styled.InputWrapper>

          <Styled.InputWrapper>
            <Controller
              name="host"
              control={control}
              render={({ field }) => (
                <AddressIpMaskedInput
                  {...field}
                  label={formatMessage({ id: AppMessages['devices.details.addressIP'] })}
                  placeholder={formatMessage({ id: AppMessages['devices.details.placeholder.addressIP'] })}
                  errorMessage={errors?.host?.message}
                />
              )}
            />
          </Styled.InputWrapper>

          <Styled.InputWrapper>
            <Controller
              name="port"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label={formatMessage({ id: AppMessages['devices.details.port'] })}
                  placeholder={formatMessage({ id: AppMessages['devices.details.placeholder.port'] })}
                  type="number"
                  errorMessage={errors?.port?.message}
                />
              )}
            />
          </Styled.InputWrapper>

          <Controller
            name="timeout"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={formatMessage({ id: AppMessages['devices.details.timeout'] })}
                placeholder={formatMessage({ id: AppMessages['devices.details.placeholder.timeout'] })}
                type="number"
                errorMessage={errors?.timeout?.message}
              />
            )}
          />
        </div>

        {isDirty && (
          <Styled.ButtonsWrapper>
            <Button color="primary" onClick={() => reset()}>
              {formatMessage({ id: AppMessages['common.cancel'] })}
            </Button>
            <Button disabled={!isValid} isLoading={isLoading} variant="contained" color="primary" type="submit">
              {formatMessage({ id: AppMessages['common.save'] })}
            </Button>
          </Styled.ButtonsWrapper>
        )}
      </Styled.Form>
    </>
  );
};
