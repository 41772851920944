import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useQueryClient } from 'shared-ui';

import { useMutation } from 'hooks/useMutation/useMutation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { fetchOwnersControl, updateOwnersControl } from 'api/control/control';
import { OwnersControl as OwnersControlType } from 'api/control/control.types';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';

import { OwnersControl } from './OwnersControl';
import { UpdateOwnersControlFormBody } from './OwnersControl.types';
import { useUpdateOwnersControlFormValidation } from './OwnersControl.validation';

export const OwnersControlContainer = () => {
  const { formatMessage } = useLocale();
  const { schema } = useUpdateOwnersControlFormValidation();
  const queryClient = useQueryClient();
  const ownersControl = queryClient.getQueryData<OwnersControlType>([QueryKeyEnum.OWNERS_CONTROL]);

  const formMethods = useForm<UpdateOwnersControlFormBody>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: ownersControl,
  });

  const { isLoading: isLoadingOwersControl } = useQuery([QueryKeyEnum.OWNERS_CONTROL], fetchOwnersControl, {
    onSuccess: (data) => {
      formMethods.reset(data);
    },
  });

  const { mutate: onUpdateOwnersControl, isLoading } = useMutation(updateOwnersControl, {
    onSuccess: () => {
      toast.dark(formatMessage({ id: AppMessages['definitions.control.success'] }));
    },
  });

  const onSubmit = (body: UpdateOwnersControlFormBody) => {
    onUpdateOwnersControl({ body });
  };

  return (
    <FormProvider {...formMethods}>
      <OwnersControl onSubmit={onSubmit} isLoading={isLoading || isLoadingOwersControl} />
    </FormProvider>
  );
};
