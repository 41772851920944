export const findAllByKey = <Type, Key extends keyof Type>(obj: Type, keyToFind: Key): Type[Key][] => {
  return obj
    ? Object.entries(obj).reduce(
        (acc, [key, value]) =>
          key === keyToFind
            ? acc.concat(value as Type[Key])
            : typeof value === 'object'
            ? acc.concat(findAllByKey(value, keyToFind as never))
            : acc,
        [] as Type[Key][],
      )
    : [];
};
