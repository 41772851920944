import { ListItem, Collapse as MaterialCollapse } from '@mui/material';
import styled, { css } from 'styled-components';

export const ExpandableListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 0px;
`;

export const ExpandableListItemClickableArea = styled.div<{ isActive: boolean; isClickable: boolean }>(
  ({ isActive, isClickable, theme: { shape, palette } }) => css`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    cursor: ${isClickable ? 'pointer' : 'default'};
    padding: 8px;
    padding-left: 20px;
    border-radius: ${shape.borderRadius}px;
    border: 1px solid ${isActive ? palette.grey[500] : 'transparent'};
    background-color: ${isActive && palette.common.white};
  `,
);
export const Collapse = styled(MaterialCollapse)`
  margin-left: 55px;
`;
