import { useEffect, useState } from 'react';
import { useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { addGlcTerminals } from 'api/glc/glc';
import { useSelectedGlc } from 'hooks/useSelectedGlc/useSelectedGlc';
import { fetchTerminals } from 'api/terminals/terminals';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useMutation } from 'hooks/useMutation/useMutation';
import { GlcTerminalsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { useModalState } from 'hooks/useModalState/useModalState';

import { useAddGlcTerminalsValidation } from './AddGlcTerminals.validation';
import { AddGlcTerminalsFormBody, AddGlcTerminalsContainerProps } from './AddGlcTerminals.types';
import { AddGlcTerminals } from './AddGlcTerminals';

export const AddGlcTerminalsContainer = ({ glcId }: AddGlcTerminalsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedGlc } = useSelectedGlc();
  const [terminalsQuery] = useState('');
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { schema } = useAddGlcTerminalsValidation();

  const glcServerId = selectedGlc?.rcp.id;

  const methods = useForm<AddGlcTerminalsFormBody>({
    defaultValues: {
      idTkds: [],
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { data: terminalsResponse, isInitialLoading: isLoadingTerminals } = useQuery(
    [QueryKeyEnum.TERMINALS, glcServerId, terminalsQuery],
    () => fetchTerminals(glcServerId),
    {
      args: {
        zaakceptowane: true,
        sort: [GlcTerminalsIdSortEnum.ASC],
        withoutGlc: true,
        withoutDirectGroupPermission: true,
      },
      enabled: !!glcServerId,
    },
  );

  const { mutate: onAddGlcTerminalsPermissions, isLoading } = useMutation(addGlcTerminals, {
    onSuccess: (_, { body }) => {
      queryClient.invalidateQueries([QueryKeyEnum.GLC_TERMINALS]);
      queryClient.invalidateQueries([QueryKeyEnum.TERMINALS, glcServerId, terminalsQuery]);

      body.idTkds?.length > 1
        ? toast.dark(formatMessage({ id: AppMessages['glc.terminals.add.terminal.success.multi'] }))
        : toast.dark(formatMessage({ id: AppMessages['glc.terminals.add.terminal.success'] }));

      onModalClose();
    },
  });

  const onSubmit = (body: AddGlcTerminalsFormBody) => {
    onAddGlcTerminalsPermissions({
      id: glcId,
      body: {
        idTkds: body.idTkds.map(({ value }) => `${value}`),
      },
    });
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen, glcServerId]);

  return (
    <FormProvider {...methods}>
      <AddGlcTerminals
        onSubmit={onSubmit}
        isModalOpen={isModalOpen}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
        isLoading={isLoading}
        isLoadingTerminals={isLoadingTerminals}
        terminals={terminalsResponse?.data || []}
      />
    </FormProvider>
  );
};
