import { TabMenu } from 'shared-ui';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useSelectedUser } from 'hooks/useSelectedUser/useSelectedUser';
import { UserDetailsTabEnum } from 'context/selectedUser/selectedUserContext/SelectedUserContext.enum';

import { UserDetailsProps } from './UserDetails.types';
import * as Styled from './UserDetails.styles';
import { UserBasicDetailsContainer } from './userBasicDetails/UserBasicDetailsContainer';
import { UserDetailsTabDescriptionEnum } from './UserDetails.enum';
import { UserPersonalDetailsContainer } from './userPersonalDetails/UserPersonalDetailsContainer';

export const UserDetails = ({
  isLoadingUpdateUser,
  selectedUser,
  onUpdateUserSubmit,
  errorFields,
}: UserDetailsProps) => {
  const { formatMessage } = useLocale();
  const { selectedUserDetailsTab, setSelectedUserDetailsTab } = useSelectedUser();

  return (
    <>
      {selectedUser.ktoZedytowal ? (
        <Styled.DetailsItemWrapper>
          <Styled.DetailName>{formatMessage({ id: AppMessages['owner.details.editedBy'] })}</Styled.DetailName>
          <Styled.DetailText> {selectedUser.ktoZedytowal} </Styled.DetailText>
        </Styled.DetailsItemWrapper>
      ) : (
        <Styled.DetailsItemWrapper>
          <Styled.DetailName>{formatMessage({ id: AppMessages['owner.details.addedBy'] })}</Styled.DetailName>
          <Styled.DetailText>
            {selectedUser.ktoWprowadzil || formatMessage({ id: AppMessages['owner.details.addedByInstaller'] })}
          </Styled.DetailText>
        </Styled.DetailsItemWrapper>
      )}
      <TabMenu.Wrapper
        selectedValue={selectedUserDetailsTab}
        setSelectedValue={setSelectedUserDetailsTab}
        tabsValues={UserDetailsTabEnum}
        tabsDescriptions={{
          [UserDetailsTabEnum.BASIC]: formatMessage({ id: UserDetailsTabDescriptionEnum.BASIC }),
          [UserDetailsTabEnum.PERSONAL]: formatMessage({ id: UserDetailsTabDescriptionEnum.PERSONAL }),
        }}
      >
        <TabMenu.Panel value={UserDetailsTabEnum.BASIC}>
          <UserBasicDetailsContainer
            isLoadingUpdateUser={isLoadingUpdateUser}
            selectedUser={selectedUser}
            onUpdateUserSubmit={onUpdateUserSubmit}
            errorFields={errorFields}
          />
        </TabMenu.Panel>
        <TabMenu.Panel value={UserDetailsTabEnum.PERSONAL}>
          <UserPersonalDetailsContainer
            isLoadingUpdateUser={isLoadingUpdateUser}
            selectedUser={selectedUser}
            onUpdateUserSubmit={onUpdateUserSubmit}
            errorFields={errorFields}
          />
        </TabMenu.Panel>
      </TabMenu.Wrapper>
    </>
  );
};
