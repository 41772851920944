import { useState, MouseEvent, KeyboardEvent } from 'react';
import { Checkbox, Tooltip, CloseGrayIcon } from 'shared-ui';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-select-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-select-right.svg';
import { TreeOption } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect.types';
import { findAllByKey } from 'helpers/findAllByKey';
import * as Styled from '../TreeMultiSelect.styles';

import { TreeMultiSelectBodyProps } from './TreeMultiSelectBody.types';

export const TreeMultiSelectBody = ({
  options,
  value,
  onSelectItem,
  onClosePopover,
  isOptionSelected,
}: TreeMultiSelectBodyProps) => {
  const [selectedNavigationEl, setSelectedNavigationEl] = useState<TreeOption[]>([]);
  const [backHistory, setBackHistory] = useState<TreeOption[]>([]);

  const arrayToRender = selectedNavigationEl && !!backHistory.length ? selectedNavigationEl : options;

  const onClickBackButton = (e: MouseEvent) => {
    e.stopPropagation();
    const lastBackHistoryElement = backHistory[backHistory.length - 1];
    setSelectedNavigationEl(Array(lastBackHistoryElement));
    setBackHistory(backHistory.filter((historyElement) => historyElement.id !== lastBackHistoryElement.id));
  };

  const onClickGoDipperButton = (e: MouseEvent, option: TreeOption, subOption: TreeOption) => {
    e.stopPropagation();
    setSelectedNavigationEl(Array(subOption));
    setBackHistory([...backHistory, option]);
  };

  const onClickCloseButton = (e: MouseEvent) => {
    e.stopPropagation();
    setBackHistory([]);
    onClosePopover && onClosePopover();
  };

  const selectedOptionsNumber = (option: TreeOption) => {
    const ids = findAllByKey(option, 'id');

    return ids?.filter((id) => value?.some((val) => val.id === id)).length;
  };

  const onEnterKeyDown = (e: KeyboardEvent, callbackFn: () => void) => {
    if (e.key === 'Enter') {
      callbackFn();
    }
  };

  return (
    <>
      {arrayToRender.map((option, index) => (
        <Styled.ListItemWrapper key={option.id}>
          <Styled.ListItem $isFirstLevel={!backHistory.length} onClick={() => onSelectItem(option)}>
            {!!backHistory.length && (
              <Styled.LeftIconButton onClick={onClickBackButton}>
                <ArrowLeftIcon />
              </Styled.LeftIconButton>
            )}

            <Styled.Option>
              <Checkbox
                checked={!!isOptionSelected(option.id)}
                color="primary"
                onKeyDown={(e) => {
                  onEnterKeyDown(e, () => onSelectItem(option));
                }}
              />

              <div>
                <Styled.TreeOptionTextBold noWrap>{option.kod}</Styled.TreeOptionTextBold>
                <Styled.TreeOptionText noWrap>{option.nazwa}</Styled.TreeOptionText>
              </div>
            </Styled.Option>

            {index === 0 && (
              <Styled.RightIconButton onClick={onClickCloseButton}>
                <CloseGrayIcon />
              </Styled.RightIconButton>
            )}

            {option.children && !!selectedOptionsNumber(option) && (
              <Styled.SelectedOptionsNumberCircle $isFirstLevel={!backHistory.length}>
                <Styled.SelectedOptionsNumber> {selectedOptionsNumber(option)}</Styled.SelectedOptionsNumber>
              </Styled.SelectedOptionsNumberCircle>
            )}
          </Styled.ListItem>

          {option.children?.map((subOption) => (
            <Styled.ListItem
              $isFirstLevel={!backHistory.length}
              key={subOption.id}
              onClick={() => onSelectItem(subOption)}
            >
              <Styled.SubOption>
                <Checkbox
                  checked={!!isOptionSelected(subOption.id)}
                  color="primary"
                  onKeyDown={(e) => {
                    onEnterKeyDown(e, () => onSelectItem(subOption));
                  }}
                />
                <Tooltip
                  placement="bottom"
                  title={subOption.children ? subOption.children.map((option) => option.nazwa).join(', ') : ''}
                >
                  <div>
                    <Styled.TreeOptionTextBold noWrap>{subOption.kod}</Styled.TreeOptionTextBold>
                    <Styled.TreeOptionText noWrap>{subOption.nazwa}</Styled.TreeOptionText>
                  </div>
                </Tooltip>
              </Styled.SubOption>

              {subOption.children && (
                <>
                  <Styled.RightIconButton onClick={(e) => onClickGoDipperButton(e, option, subOption)}>
                    <ArrowRightIcon />
                  </Styled.RightIconButton>

                  {!!selectedOptionsNumber(subOption) && (
                    <Styled.SelectedOptionsNumberCircle $isFirstLevel={!backHistory.length}>
                      <Styled.SelectedOptionsNumber>{selectedOptionsNumber(subOption)}</Styled.SelectedOptionsNumber>
                    </Styled.SelectedOptionsNumberCircle>
                  )}
                </>
              )}
            </Styled.ListItem>
          ))}
        </Styled.ListItemWrapper>
      ))}
    </>
  );
};
