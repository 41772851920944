import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { unarchiveGroup } from 'api/groups/groups';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useSelectedGroup } from 'hooks/useSelectedGroup/useSelectedGroup';
import { useModalState } from 'hooks/useModalState/useModalState';

import { UnarchiveGroup } from './UnarchiveGroup';
import { UnarchiveGroupContainerProps } from './UnarchiveGroup.types';

export const UnarchiveGroupContainer = ({ selectedGroup }: UnarchiveGroupContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { setSelectedGroup } = useSelectedGroup();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onUnarchiveGroup, isLoading } = useMutation(unarchiveGroup, {
    onSuccess: () => {
      setSelectedGroup(undefined);
      queryClient.invalidateQueries([QueryKeyEnum.GROUPS]);
      toast.dark(formatMessage({ id: AppMessages['group.unarchive.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onUnarchiveGroup(selectedGroup.id);
  };

  return (
    <UnarchiveGroup
      selectedGroup={selectedGroup}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
