import { DeleteServerContainer } from './deleteServer/DeleteServerContainer';
import { ServerActionsProps } from './ServerActions.types';
import * as Styled from './ServerActions.styles';

export const ServerActions = ({ selectedServer }: ServerActionsProps) => {
  return (
    <Styled.ActionsButtonsWrapper>
      {selectedServer.canBeDeleted && <DeleteServerContainer selectedServer={selectedServer} />}
    </Styled.ActionsButtonsWrapper>
  );
};
