import { Option } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { fetchPositions } from 'api/positions/positions';
import { useQuery } from 'hooks/useQuery/useQuery';
import * as Styled from '../TableSearch.styles';

export const useSearchByPositions = (optionsQuery: string, isEnabled: boolean) => {
  const { data: positionsResponse, isInitialLoading } = useQuery(
    [QueryKeyEnum.POSITIONS_SEARCH, optionsQuery],
    fetchPositions,
    { args: { query: optionsQuery }, enabled: isEnabled },
  );

  const positions = positionsResponse || [];

  const createOptions = () => {
    return positions.map((position) => ({ label: position.nazwa, value: position.nazwa }));
  };

  const createOptionLabel = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <li {...optionProps}>
        <Styled.PositionLabel>{option.label}</Styled.PositionLabel>
      </li>
    );
  };

  return { isLoading: isInitialLoading, createOptions, createOptionLabel };
};
