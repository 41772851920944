import styled, { css } from 'styled-components';

export const TagsWrapper = styled.div`
  width: 85%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

export const Chip = styled.span(
  ({ theme: { palette } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    border-radius: 50%;
    background-color: ${palette.grey[300]};
    height: 28px;
    min-width: 28px;
    font-size: 12px;
  `,
);

export const Tag = styled.div(
  ({ theme: { palette } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${palette.grey[300]};
    height: 28px;
    font-size: 12px;
    padding: 0 12px;
    border-radius: 16px;
    margin: 3px;
    max-width: calc(100% - 6px);
    white-space: nowrap;
  `,
);

export const DescriptionInputWrapper = styled.div`
  grid-column: 1/3;
`;
