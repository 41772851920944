import { useParams } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { fetchWantedTerminals } from 'api/wanted/wanted';

import { WantedTerminalsRows } from './WantedTerminalsRows';
import { WantedTerminalsRowsContainerProps } from './WantedTerminalsRows.types';

export const WantedTerminalsRowsContainer = ({
  expandedServer,
  setTerminalsCount,
  checkedTerminals,
  setCheckedTerminals,
}: WantedTerminalsRowsContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const wantedId = id || '';
  const serverId = expandedServer?.id || '';
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.WANTED_TERMINALS]: wantedTerminalsQuery },
  } = useLocaleStoreQuery();

  const { data: terminalsResponse } = useQuery(
    [QueryKeyEnum.WANTED_TERMINALS, wantedId, expandedServer?.id, wantedTerminalsQuery],
    () => fetchWantedTerminals({ wantedId, serverId }),
    {
      args: wantedTerminalsQuery,
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setTerminalsCount(response.count);
      },
    },
  );

  return (
    <WantedTerminalsRows
      checkedTerminals={checkedTerminals}
      terminalsResponse={terminalsResponse}
      setCheckedTerminals={setCheckedTerminals}
    />
  );
};
