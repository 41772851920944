import { useState } from 'react';

import { Server } from 'api/servers/servers.types';
import { SelectedServerContext } from '../selectedServerContext/SelectedServerContext';

import { SelectedServerContextControllerProps } from './SelectedServerContextController.types';

export const SelectedServerContextController = ({ children }: SelectedServerContextControllerProps) => {
  const [selectedServer, setSelectedServer] = useState<Server | undefined>(undefined);

  return (
    <SelectedServerContext.Provider value={{ selectedServer, setSelectedServer }}>
      {children}
    </SelectedServerContext.Provider>
  );
};
