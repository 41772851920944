import { useLocale } from 'hooks/useLocale/useLocale';
import { Table } from 'ui/table/Table';
import { AppMessages } from 'i18n/messages';
import {
  InstantControlTerminalsIdSortEnum,
  InstantControlTerminalsNameSortEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';

import { InstantControlTerminalsRowsProps } from './InstantControlTerminalsRows.types';

export const InstantControlTerminalsRows = ({ terminalsResponse }: InstantControlTerminalsRowsProps) => {
  const { formatMessage } = useLocale();

  return (
    <Table
      gridTemplateColumns="1fr 1fr"
      data={terminalsResponse?.data || []}
      nothingToShowText={formatMessage({ id: AppMessages['control.instant.terminals.nothingToShow'] })}
      customTableHeight={() => undefined}
      renderHeader={() => (
        <>
          <Table.HeaderSortItem
            queryKey={QueryKeyLocalStorageEnum.INSTANT_CONTROL_TERMINALS}
            sortBy={InstantControlTerminalsIdSortEnum}
            queryHeader={formatMessage({ id: AppMessages['control.instant.terminals.table.header.id'] })}
          />

          <Table.HeaderSortItem
            queryKey={QueryKeyLocalStorageEnum.INSTANT_CONTROL_TERMINALS}
            sortBy={InstantControlTerminalsNameSortEnum}
            queryHeader={formatMessage({ id: AppMessages['control.instant.terminals.table.header.readerName'] })}
          />
        </>
      )}
      renderRow={(terminal) => (
        <>
          <Table.RowItem>{terminal.numerLogicznyKontrolera}</Table.RowItem>

          <Table.RowItem>{terminal.nazwa}</Table.RowItem>
        </>
      )}
    />
  );
};
