import { fetchOrganizations } from 'api/organizations/organizations';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';

import { Organization } from './Organization';

export const OrganizationContainer = () => {
  const { data: organizationResponse, isLoading: isLoadingOrganization } = useQuery([QueryKeyEnum.ORGANIZATIONS], () =>
    fetchOrganizations(),
  );
  return (
    <Organization
      organization={organizationResponse ? organizationResponse[organizationResponse.length - 1] : null}
      isLoadingOrganization={isLoadingOrganization}
    />
  );
};
