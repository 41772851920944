import { Organization } from 'api/organizations/organizations.types';
import { findAllByKey } from 'helpers/findAllByKey';
import { getObjectInNestedObjectByKey } from 'helpers/getObjectInNestedObjectByKey';

export const getParentElement = (mainOrganization: Organization, selectedOrganization: Organization) => {
  const allNodeIds = findAllByKey(mainOrganization, 'id');

  const allOrganizationItems = allNodeIds
    .map((nodeId) => getObjectInNestedObjectByKey(mainOrganization, 'id', nodeId)?.object as Organization | null)
    .filter(Boolean);

  const parentObject = allOrganizationItems.filter((organinzationItem) => {
    return (
      organinzationItem?.children &&
      organinzationItem?.children.some((organizationItem) => organizationItem.id === selectedOrganization.id)
    );
  })[0];

  return parentObject ? { nazwa: parentObject.nazwa, id: parentObject.id } : null;
};
