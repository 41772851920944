import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Datepicker } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { RcpEnum } from 'app/events/eventsFilters/EventsFilters.enum';
import { getNow } from 'helpers/getNow';
import { handleValidFromDate } from 'helpers/handleValidFromDate';
import { handleValidToDate } from 'helpers/handleValidToDate';
import { getEndOfToday } from 'helpers/getEndOfToday';
import { Form } from 'ui/form/Form';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import * as Styled from '../OwnerDetails.styles';

import { OwnerSystemDetailsProps, OwnerSystemDetailsForm } from './OwnerSystemDetails.types';

export const OwnerSystemDetails = ({
  isLoadingUpdateOwner,
  selectedOwner,
  onSubmit,
  resetToSelected,
  isEditDisabled,
}: OwnerSystemDetailsProps) => {
  const { formatMessage } = useLocale();

  const minDate = useMemo(() => new Date(), []);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty, isValid, errors },
    trigger,
  } = useFormContext<OwnerSystemDetailsForm>();

  const validFrom = new Date(watch('dataOd') as string);

  const { rcpOptions } = useAppSelectOptions();

  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)} $withoutGap>
      <Controller
        name="nrEwidencyjny"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder={formatMessage({ id: AppMessages['owner.details.system.number.placeholder'] })}
            label={formatMessage({ id: AppMessages['owner.details.system.number'] })}
            errorMessage={errors?.nrEwidencyjny?.message}
            disabled={isEditDisabled}
          />
        )}
      />
      <Controller
        name="nrEwidencyjnyZewnetrzny"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            value={field.value || ''}
            placeholder={formatMessage({ id: AppMessages['owner.details.system.internalNumber.placeholder'] })}
            label={formatMessage({ id: AppMessages['owner.details.system.internalNumber'] })}
            errorMessage={errors?.nrEwidencyjnyZewnetrzny?.message}
            disabled={isEditDisabled}
          />
        )}
      />

      <Form.ControlledSelect
        name="rcp"
        control={control}
        defaultValue={RcpEnum.TRUE}
        label={formatMessage({ id: AppMessages['owners.addOwner.field.rcpWorker.label'] })}
        required
        options={rcpOptions}
        withHelperText
      />

      <Controller
        name="dataOd"
        control={control}
        render={({ field }) => (
          <Datepicker
            {...field}
            label={formatMessage({ id: AppMessages['owner.details.system.validFrom'] })}
            value={watch('dataOd')}
            minDate={minDate}
            withCalendarIcon
            disabled={isEditDisabled}
            isClickable={!isEditDisabled}
            withDatepickerPopover
            errorMessage={errors?.dataOd?.message}
            popoverOrigin={{
              vertical: 0,
              horizontal: -630,
            }}
            customSetDateFormat={handleValidFromDate}
            customDateFormatOnOpen={getNow}
            withoutErrorMessage
          />
        )}
      />

      <Controller
        name="dataDo"
        control={control}
        render={({ field }) => (
          <Datepicker
            {...field}
            label={formatMessage({ id: AppMessages['owner.details.system.validTo'] })}
            minDate={validFrom < minDate ? minDate : validFrom}
            value={watch('dataDo')}
            withCalendarIcon
            disabled={isEditDisabled}
            isClickable={!isEditDisabled}
            withDatepickerPopover
            errorMessage={errors?.dataDo?.message}
            popoverOrigin={{
              vertical: 0,
              horizontal: -630,
            }}
            customSetDateFormat={handleValidToDate}
            customDateFormatOnOpen={getEndOfToday}
            onChange={(date) => {
              field.onChange(date);
              trigger('dataOd');
            }}
            withoutErrorMessage
          />
        )}
      />

      {isDirty && (
        <Styled.ButtonsWrapper>
          <Button
            size="small"
            onClick={() => {
              reset(resetToSelected(selectedOwner));
            }}
            color="primary"
          >
            {formatMessage({ id: AppMessages['owner.details.button.cancel'] })}
          </Button>

          <Button
            type="submit"
            disabled={!isValid}
            isLoading={isLoadingUpdateOwner}
            size="small"
            variant="contained"
            color="primary"
          >
            {formatMessage({ id: AppMessages['owner.details.button.save'] })}
          </Button>
        </Styled.ButtonsWrapper>
      )}
    </Styled.Form>
  );
};
