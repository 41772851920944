import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const Title = styled(Typography)`
  margin-bottom: 26px;
`;

export const RadioWrapper = styled.div(
  ({ theme: { palette, shape } }) => css`
    padding: 16px;
    border: 1px solid ${palette.grey[300]};
    margin-bottom: 18px;
    border-radius: ${shape.borderRadius}px;

    &:last-of-type {
      margin-bottom: 0;
    }
  `,
);

export const RadioLabelWrapper = styled.div`
  margin-left: 10px;
`;
