import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

import { PhotoDetailsStylesProps } from './OwnerPhoto.types';

export const OwnerPhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PhotoPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 96px;
  width: 96px;
  background-color: #f4f4f4;
  border-radius: 16px;
`;

export const DetailName = styled(Typography)(
  ({ theme: { palette } }) => css`
    font-weight: 700;
    color: ${palette.text.secondary};
  `,
);

export const PlaceholderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
`;

export const PhotoDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  align-items: flex-start;
`;

export const PhotoDetail = styled(Typography)(
  ({ theme: { palette } }) => css`
    font-size: 12px;
    font-weight: 700;
    color: ${palette.info.light};
  `,
);

export const PhotoDetails = styled.div<PhotoDetailsStylesProps>(
  ({ isVisible }) => css`
    visibility: ${isVisible ? 'visible' : 'hidden'};
  `,
);
