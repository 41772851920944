import { Controller, useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import { isJustLetters, AddPhotoModal } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { FormAutocompleteContainer } from 'reusable/formAutocomplete/FormAutocompleteContainer';
import { QueryKeyEnum } from 'core/global.enum';
import { fetchPositions } from 'api/positions/positions';
import { PositionNameSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';

import { BasicDataFormProps } from './BasicDataForm.types';
import * as Styled from './BasicDataForm.styles';

export const BasicDataForm = ({
  onSubmitAddOwnerPhoto,
  photoFile,
  isPhotoModalOpen,
  onOpenPhotoModal,
  onClosePhotoModal,
}: BasicDataFormProps) => {
  const { formatMessage } = useLocale();

  const { control } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name="imie"
        defaultValue=""
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            onChange={(e) => {
              if (!isJustLetters(e.target.value)) return;

              field.onChange(e);
            }}
            required
            label={formatMessage({ id: AppMessages['owners.addOwner.field.name.label'] })}
            placeholder={formatMessage({ id: AppMessages['owners.addOwner.field.name.placeholder'] })}
            errorMessage={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="nazwisko"
        defaultValue=""
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            onChange={(e) => {
              if (!isJustLetters(e.target.value)) return;

              field.onChange(e);
            }}
            required
            label={formatMessage({ id: AppMessages['owners.addOwner.field.lastName.label'] })}
            placeholder={formatMessage({ id: AppMessages['owners.addOwner.field.lastName.placeholder'] })}
            errorMessage={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="drugieImie"
        defaultValue=""
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            onChange={(e) => {
              if (!isJustLetters(e.target.value)) return;

              field.onChange(e);
            }}
            errorMessage={error?.message}
            label={formatMessage({ id: AppMessages['owners.addOwner.field.secondName.label'] })}
            placeholder={formatMessage({ id: AppMessages['owners.addOwner.field.secondName.placeholder'] })}
          />
        )}
      />

      <Controller
        control={control}
        name="firma"
        defaultValue={undefined}
        render={({ field }) => (
          <OrganizationsTreeSelect
            {...field}
            placeholder={formatMessage({ id: AppMessages['owners.addOwner.select.placeholder'] })}
            label={formatMessage({ id: AppMessages['owners.addOwner.field.company.label'] })}
          />
        )}
      />

      <FormAutocompleteContainer
        formFieldName="stanowisko"
        queryKey={QueryKeyEnum.POSITIONS}
        queryFunction={fetchPositions}
        createOption={(position) => ({
          label: position.nazwa,
          value: position.id,
        })}
        handleOptionSelect={(option) => ({
          nazwa: option.label,
          id: option.value,
        })}
        args={{
          sort: [PositionNameSortEnum.ASC],
        }}
        label={formatMessage({ id: AppMessages['owner.details.basic.position'] })}
      />

      <Controller
        control={control}
        name="komorka"
        render={({ field }) => (
          <OrganizationsTreeSelect
            {...field}
            multiple
            placeholder={formatMessage({ id: AppMessages['owners.addOwner.select.placeholder'] })}
            label={formatMessage({ id: AppMessages['owners.addOwner.field.cell.label'] })}
          />
        )}
      />

      <Styled.PhotoWrapper>
        <Styled.PhotoLabel> {formatMessage({ id: AppMessages['owners.addOwner.photo.title'] })}</Styled.PhotoLabel>
        <Styled.PhotoArea>
          {photoFile ? (
            <Styled.UploadedPhoto src={URL.createObjectURL(photoFile)} />
          ) : (
            <>
              <Styled.PhotoIcon />

              <Typography variant="subtitle2">
                {formatMessage({ id: AppMessages['owners.addOwner.photo.correctFiles'] })}
              </Typography>
              <Typography variant="subtitle2">
                {formatMessage({ id: AppMessages['owners.addOwner.photo.size'] })}
              </Typography>
              <Typography variant="subtitle2">
                {formatMessage({ id: AppMessages['owners.addOwner.photo.maxFileSize'] })}
              </Typography>
            </>
          )}
        </Styled.PhotoArea>

        <Styled.AddImageButton variant="contained" onClick={onOpenPhotoModal}>
          {photoFile
            ? formatMessage({ id: AppMessages['owners.addOwner.photo.editButton'] })
            : formatMessage({ id: AppMessages['owners.addOwner.photo.addButton'] })}
        </Styled.AddImageButton>
      </Styled.PhotoWrapper>

      <AddPhotoModal open={isPhotoModalOpen} onClose={onClosePhotoModal} onSubmit={onSubmitAddOwnerPhoto} />
    </>
  );
};
