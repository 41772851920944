import { Server } from 'api/servers/servers.types';
import { Keyword } from 'ui/keywordsInput/KeywordsInput.types';

export type AnnouncementsListProps = {
  servers: Server[] | undefined;
  isLoading: boolean;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  announcementsCount: number;
  setAnnouncementsCount: (announcementsCount: number) => void;
  announcementsTableSearchQuery: string;
};

export type AnnouncementsListContainerProps = {
  announcementsTableSearchQuery: string;
};

export const announcementKeywords: Keyword[] = [
  { name: '@Imię', backgroundColor: '#77C6C1' },
  { name: '@Nazwisko', backgroundColor: '#FFBCCF' },
  { name: '@Stanowisko', backgroundColor: '#83C3FF' },
  { name: '@Organizacja/Firma', backgroundColor: '#EFE380' },
];
