import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const ParameterListItem = styled.div(
  ({ theme: { palette, shape } }) => css`
    background-color: ${palette.common.white};
    border-radius: ${shape.borderRadius}px;
    padding: 16px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 76px;
  `,
);

export const CheckboxLabel = styled(Typography)<{ $isParameterEnabled: boolean }>(
  ({ $isParameterEnabled, theme: { palette } }) => css`
    font-weight: ${$isParameterEnabled ? 700 : 400};
    color: ${palette.grey[900]};
    line-height: 90%;
  `,
);

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
