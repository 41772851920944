import * as yup from 'yup';
import { getYear, subYears } from 'date-fns';

import { useLocale } from 'hooks/useLocale/useLocale';
import { validatePesel } from 'helpers/validatePesel';
import { AppMessages } from 'i18n/messages';
import { OwnerDocumentEnum } from 'api/owners/owners.enum';

export const useOwnerPersonalDetailsValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape(
    {
      email: yup
        .string()
        .email(formatMessage({ id: AppMessages['owners.addOwner.field.email.validation'] }))
        .optional()
        .nullable(),
      telefon: yup
        .string()
        .optional()
        .nullable()
        .test('length', formatMessage({ id: AppMessages['owners.addOwner.field.phoneNumber.validation'] }), (val) => {
          if (!val?.length) return true;

          return val.length >= 9 && val.length <= 20;
        }),
      pesel: yup
        .string()
        .test('pesel', formatMessage({ id: AppMessages['owners.addOwner.field.pesel.validation'] }), validatePesel)
        .optional()
        .nullable(),

      dokumentTozsamosci: yup.string().optional().nullable(),
      numerDokumentu: yup
        .string()
        .optional()
        .nullable()
        .when('dokumentTozsamosci', {
          is: (dokumentTozsamosci: OwnerDocumentEnum) => !!dokumentTozsamosci,
          then: yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] })),
        }),
      opis: yup
        .string()
        .max(60, formatMessage({ id: AppMessages['owners.addOwner.field.description.validation.max'] }))
        .optional(),
      dataUrodzenia: yup
        .date()
        .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }))
        .min(getYear(subYears(new Date(), 100)), formatMessage({ id: AppMessages['common.validation.date.invalid'] }))
        .max(new Date(), formatMessage({ id: AppMessages['common.validation.date.invalid'] }))
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .optional(),
    },
    [['dataUrodzenia', 'dataUrodzenia']],
  );

  return { schema };
};
