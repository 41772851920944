import { useState, useEffect } from 'react';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { returnMultiCards } from 'api/cards/cards';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { CardStatusEnum } from 'api/cards/cards.enum';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { ReturnMultiCards } from './ReturnMultiCards';
import { ReturnMultiCardsContainerProps } from './ReturnMultiCards.types';

export const ReturnMultiCardsContainer = ({ checkedCards, actionIn }: ReturnMultiCardsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedCard, setSelectedCard } = useSelectedCard();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const [minDate, setMinDate] = useState(new Date());

  const { mutate: onReturnMultiCards, isLoading } = useMutation(returnMultiCards, {
    onSuccess: (_, { body }) => {
      if (actionIn === 'cards') {
        if (selectedCard && checkedCards.some(({ id }) => id === selectedCard?.id)) {
          setSelectedCard({ ...selectedCard, ...body, status: CardStatusEnum.DOSTEPNA, posiadacz: undefined });
        }
        queryClient.invalidateQueries([QueryKeyEnum.CARDS]);
      }

      if (actionIn === 'owners') {
        queryClient.invalidateQueries([QueryKeyEnum.OWNERS]);
        queryClient.invalidateQueries([QueryKeyEnum.OWNER_DETAILS]);
      }

      toast.dark(formatMessage({ id: AppMessages['cards.multiAction.return.success'] }));
      onModalClose();
    },
  });

  useEffect(() => {
    setMinDate(new Date());
  }, [isModalOpen]);

  const onSubmit = () => {
    onReturnMultiCards({ body: { cards: checkedCards.map(({ id }) => id) } });
  };

  return (
    <ReturnMultiCards
      checkedCards={checkedCards}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
      minDate={minDate}
    />
  );
};
