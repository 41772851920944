import { useEffect, useState } from 'react';
import { useQueryClient, useLoaderToast, useErrorHandler, ClientError, APIError } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EventModeEnum, EventModEnum } from 'api/events/events.enum';
import { addEvent } from 'api/events/events';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { fetchCardsWithOwners } from 'api/cards/cards';
import { CardWithOwner } from 'api/cards/cards.types';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useModalState } from 'hooks/useModalState/useModalState';
import { RcpEnum } from '../eventsFilters/EventsFilters.enum';

import { AddEvent } from './AddEvent';
import { useAddEventValidation } from './AddEvent.validation';
import { AddEventFormBody } from './AddEvent.types';

export const AddEventContainer = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { schema } = useAddEventValidation();
  const { load: loadAddEvent, update: updateAddEvent } = useLoaderToast('addEvent');
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { handleError } = useErrorHandler();

  const [selectedCardWithOwner, setSelectedCardWithOwner] = useState<CardWithOwner | undefined>(undefined);

  const [query, setQuery] = useState('');

  const methods = useForm<AddEventFormBody>({
    resolver: yupResolver(schema),
    defaultValues: {
      mod: EventModEnum.WEJSCIE,
      tryb: EventModeEnum.ZWYKLE,
      rcp: RcpEnum.FALSE,
    },
    mode: 'all',
  });

  const { data: cardsWithOwnerResponse, isLoading } = useQuery([QueryKeyEnum.OWNERS, query], fetchCardsWithOwners, {
    args: { query },
  });

  const { mutate: onAddEvent, isLoading: isLoadingAddEvent } = useMutation(addEvent, {
    onMutate: () => loadAddEvent(formatMessage({ id: AppMessages['events.addEvent.loading'] })),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.EVENTS]);
      updateAddEvent(formatMessage({ id: AppMessages['events.addEvent.successMessage'] }));
      onModalClose();
    },
    onError: (error: ClientError<APIError>) => {
      handleError({ error, updateToastCallback: updateAddEvent });
    },
  });

  const onSubmit = (body: AddEventFormBody) => {
    onAddEvent({
      body: {
        ...body,
        posiadacz: selectedCardWithOwner?.posiadacz || body.posiadacz,
        rcp: !!Number(body.rcp),
      },
    });
  };

  useEffect(() => {
    if (!selectedCardWithOwner) return;

    methods.clearErrors();
    methods.setValue('numerKarty', selectedCardWithOwner.numerKarty);
    methods.setValue(
      'posiadacz',
      `${selectedCardWithOwner.posiadacz.imie} ${selectedCardWithOwner.posiadacz.nazwisko}`,
    );
    methods.trigger();
  }, [selectedCardWithOwner, methods.setValue]);

  useEffect(() => {
    setQuery('');
    methods.reset();
    setSelectedCardWithOwner(undefined);
  }, [isModalOpen]);

  return (
    <FormProvider {...methods}>
      <AddEvent
        onSubmit={onSubmit}
        isModalOpen={isModalOpen}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
        cardsWithOwner={cardsWithOwnerResponse?.data}
        query={query}
        setQuery={setQuery}
        isLoading={isLoading}
        setSelectedCardWithOwner={setSelectedCardWithOwner}
        isLoadingAddEvent={isLoadingAddEvent}
      />
    </FormProvider>
  );
};
