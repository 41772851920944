import { DeleteMultiRandomControlContainer } from './deleteMultiRandomControl/DeleteMultiRandomControlContainer';
import * as Styled from './RandomControlMultiActions.styles';
import { RandomControlMultiActionsProps } from './RandomControlMultiActions.types';
import { UpdateMultiRandomControlContainer } from './updateMultiRandomControl/UpdateMultiRandomControlContainer';

export const RandomControlMultiActions = ({ checkedOwners, setCheckedOwners }: RandomControlMultiActionsProps) => {
  return (
    <Styled.Container>
      <DeleteMultiRandomControlContainer checkedOwners={checkedOwners} setCheckedOwners={setCheckedOwners} />
      <UpdateMultiRandomControlContainer checkedOwners={checkedOwners} />
    </Styled.Container>
  );
};
