import {
  GroupsIdSortEnum,
  GroupsNameSortEnum,
  GroupsQueryKeysEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { GroupTypeEnum } from 'api/groups/groups.enum';
import { GroupTypeDescriptionEnum } from '../Groups.enum';
import { useLocaleStoreGroupsQuery } from 'hooks/useLocaleStoreGroupsQuery/useLocaleStoreGroupsQuery';
import { Table } from 'ui/table/Table';
import {
  YesNoFilterItemsDescriptionEnum,
  useYesNoFilterItems,
} from 'hooks/useYesNoHeaderFilterItems/useYesNoFilterItems';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';

export const GroupsTableHeader = () => {
  const { formatMessage } = useLocale();
  const { groupsQuery, setGroupsQuery, groupsQueryLocalStorageName } = useLocaleStoreGroupsQuery();
  const { yesNoFilterItems } = useYesNoFilterItems();

  return (
    <>
      <Table.HeaderSortItem
        queryKey={groupsQueryLocalStorageName}
        sortBy={GroupsIdSortEnum}
        queryHeader={formatMessage({ id: AppMessages['groups.table.header.id'] })}
      />

      <Table.HeaderSortItem
        queryKey={groupsQueryLocalStorageName}
        sortBy={GroupsNameSortEnum}
        queryHeader={formatMessage({ id: AppMessages['groups.table.header.name'] })}
      />

      <Table.HeaderFilterItem
        queryKey={groupsQueryLocalStorageName}
        queryName={GroupsQueryKeysEnum.RODZAJ_GRUPY}
        filterItems={Object.values(GroupTypeEnum).map((groupType) => ({
          name: formatMessage({ id: GroupTypeDescriptionEnum[groupType] }),
          filterBy: groupType,
        }))}
        queryHeader={formatMessage({ id: AppMessages['groups.table.header.groupType'] })}
        queryHeaderDescriptions={GroupTypeDescriptionEnum}
      />

      <OrganizationsTreeSelect
        multiple
        isFilterType
        onChange={(options) =>
          options && setGroupsQuery({ [GroupsQueryKeysEnum.KOMORKA]: options.map((cell) => cell.id) })
        }
        value={groupsQuery.komorka?.map((id) => ({ id, nazwa: 'komorka' }))}
        placeholder={formatMessage({ id: AppMessages['groups.table.header.cell'] })}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.GROUPS}
        queryName={GroupsQueryKeysEnum.POSIADACZ}
        filterItems={yesNoFilterItems}
        queryHeader={formatMessage({ id: AppMessages['groups.table.header.owner'] })}
        queryHeaderDescriptions={YesNoFilterItemsDescriptionEnum}
      />

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.GROUPS}
        queryName={GroupsQueryKeysEnum.GLC}
        filterItems={yesNoFilterItems}
        queryHeader={formatMessage({ id: AppMessages['groups.table.header.glc'] })}
        queryHeaderDescriptions={YesNoFilterItemsDescriptionEnum}
      />
    </>
  );
};
