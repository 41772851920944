import { ReactComponent as ArrowUp } from 'assets/icons/sort-arrow-up.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/sort-arrow-down.svg';
import { SortKeysWithValues } from 'context/query/queryContext/QueryContext.types';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import * as Styled from '../Table.styles';

import { HeaderSortItemProps } from './HeaderSortItem.types';

const HeaderSortItem = <T extends keyof SortKeysWithValues>({
  queryHeader,
  queryKey,
  sortBy,
}: HeaderSortItemProps<T>) => {
  const {
    storedQueries: { [queryKey]: storedQuery },
    setSortQuery,
  } = useLocaleStoreQuery();

  const isSorted =
    storedQuery['sort'] &&
    (storedQuery['sort'] as unknown as SortKeysWithValues[keyof SortKeysWithValues]).includes(sortBy.ASC);

  const onClickSort = () => {
    setSortQuery(queryKey, isSorted ? (sortBy.DESC as SortKeysWithValues[T]) : (sortBy.ASC as SortKeysWithValues[T]));
  };

  return (
    <Styled.TableHeaderItem>
      <Styled.TableHeaderItemSortable data-testid={`sort-${sortBy.ASC}`} onClick={onClickSort}>
        <Styled.TableHeaderItemText>{queryHeader}</Styled.TableHeaderItemText>
        {isSorted ? <ArrowUp /> : <ArrowDown />}
      </Styled.TableHeaderItemSortable>
    </Styled.TableHeaderItem>
  );
};

// eslint-disable-next-line import/no-default-export
export default HeaderSortItem;
