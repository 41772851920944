import { Button } from 'shared-ui';

import { ReactComponent as ArchiveIcon } from 'assets/icons/archive-primary.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { ArchiveMultiOwnersProps } from './ArchiveMultiOwners.types';

export const ArchiveMultiOwners = ({
  checkedOwners,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
}: ArchiveMultiOwnersProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<ArchiveIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['owners.multiAction.archive.title'] })}
      </Button>

      <ActionModal
        header={formatMessage({ id: AppMessages['owners.multiAction.archive.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['owners.multiAction.archive.submitButton'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['owners.multiAction.archive.subtitle'] })} {checkedOwners.length}
        </ActionModal.Subtitle>

        <ActionModal.Text>{formatMessage({ id: AppMessages['owners.multiAction.archive.confirm'] })}</ActionModal.Text>

        <br />

        <ActionModal.Text>
          {formatMessage({ id: AppMessages['owners.multiAction.archive.description'] })}
        </ActionModal.Text>
      </ActionModal>
    </>
  );
};
