import { PositionActionsProps } from './PositionActions.types';
import { DeletePositionContainer } from './deletePosition/DeletePositionContainer';
import * as Styled from './PositionActions.styles';

export const PositionActions = ({ selectedPosition }: PositionActionsProps) => {
  return (
    <Styled.ActionButtonsWrapper>
      <DeletePositionContainer selectedPosition={selectedPosition} />
    </Styled.ActionButtonsWrapper>
  );
};
