export enum AnnouncementModEnum {
  WEJSCIE = 'WEJSCIE',
  WEJSCIE_WYJSCIE = 'WEJSCIE_WYJSCIE',
  WYJSCIE = 'WYJSCIE',
}

export enum AnnouncementModDescriptionEnum {
  WEJSCIE = 'announcements.filters.mod.entry',
  WEJSCIE_WYJSCIE = 'announcements.filters.mod.entryExit',
  WYJSCIE = 'announcements.filters.mod.exit',
}

export enum AnnouncementModShortDescriptionEnum {
  WEJSCIE = 'announcements.filters.mod.entry',
  WEJSCIE_WYJSCIE = 'announcements.filters.mod.entryExitShort',
  WYJSCIE = 'announcements.filters.mod.exit',
}
