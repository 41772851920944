import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ButtonsWrapper = styled.div`
  padding: 24px 0;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
`;

export const DetailsItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  margin-bottom: 24px;
`;

export const DetailName = styled(Typography)(
  ({ theme: { palette } }) => css`
    font-weight: 700;
    color: ${palette.text.secondary};
  `,
);

export const DetailText = styled(Typography)(
  ({ theme: { palette } }) => css`
    color: ${palette.grey[700]};
  `,
);
