import { Typography } from '@mui/material';
import { Select } from 'shared-ui';
import styled, { css } from 'styled-components';

export const SelectLabel = styled(Typography)(
  ({ theme: { palette } }) => css`
    text-transform: uppercase;
    line-height: 90%;
    color: ${palette.grey[900]};
  `,
);

export const ShortSelect = styled(Select)`
  min-width: 106px;
`;

export const LongSelect = styled(Select)`
  min-width: 330px;
`;
