import { Button } from 'shared-ui';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { DeleteGroupGlcPermissionsProps } from './DeleteGroupGlcPermissions.types';

export const DeleteGroupGlcPermissions = ({
  checkedPermissions,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
}: DeleteGroupGlcPermissionsProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<TrashIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['group.reader.permissions.multiAction.delete.button'] })}
      </Button>

      <ActionModal
        header={formatMessage({ id: AppMessages['group.reader.permissions.multiAction.delete.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['group.reader.permissions.multiAction.delete.button'] })}
      >
        <>
          <ActionModal.Subtitle>
            {formatMessage({ id: AppMessages['group.glc.permissions.multiAction.delete.subtitle'] })}{' '}
            {checkedPermissions.length}
          </ActionModal.Subtitle>

          <ActionModal.Text>
            {formatMessage({ id: AppMessages['group.reader.permissions.multiAction.delete.text'] })}
          </ActionModal.Text>

          <ActionModal.Text>{formatMessage({ id: AppMessages['common.irreversibleAction'] })}</ActionModal.Text>
        </>
      </ActionModal>
    </>
  );
};
