import { MutationFn, UseQueryFn } from 'shared-ui';

import { Terminal } from 'api/terminals/terminals.types';
import { PaginatedResponse } from 'core/global.types';
import { Owner } from 'api/owners/owners.types';

import {
  AddControlBody,
  ControlHistory,
  UpdateRandomControlBody,
  OwnersControl,
  AddRandomControlTerminalsBody,
  DeleteRandomControlTerminalsBody,
} from './control.types';

export const fetchControl: UseQueryFn<unknown, PaginatedResponse<Owner>> = () => {
  return {
    endpoint: `/kontrola`,
  };
};

export const updateOwnersControl: MutationFn<{ body: OwnersControl }, void> = ({ body }) => {
  return {
    endpoint: '/setting',
    method: 'POST',
    body,
  };
};

export const fetchOwnersControl: UseQueryFn<void, OwnersControl> = () => {
  return {
    endpoint: `/setting`,
  };
};

export const fetchControlHistory: UseQueryFn<void, PaginatedResponse<ControlHistory>> = () => {
  return {
    endpoint: `/kontrola/historia`,
  };
};

export const addControl: MutationFn<AddControlBody, string> = (body) => {
  return {
    endpoint: '/kontrola',
    method: 'POST',
    body,
  };
};

export const deleteControl: MutationFn<string, void> = (id) => {
  return {
    endpoint: `/kontrola/${id}`,
    method: 'DELETE',
  };
};

export const fetchControlOwnerTerminals: UseQueryFn<
  {
    controlId: string;
    serverId: string;
  },
  PaginatedResponse<Terminal>
> = (body) => {
  return {
    endpoint: `/kontrola/${body?.controlId}/rcp/${body?.serverId}`,
  };
};

export const deleteMultiRandomControl: MutationFn<{ body: string[] }, null> = ({ body }) => {
  return {
    endpoint: '/batch/kontrola',
    method: 'DELETE',
    body,
  };
};

export const updateMultiRandomControl: MutationFn<UpdateRandomControlBody, string> = (body) => {
  return {
    endpoint: '/batch/kontrola',
    method: 'PUT',
    body,
  };
};

export const addRandomControlTerminals: MutationFn<AddRandomControlTerminalsBody, void> = ({ id, body }) => {
  return {
    endpoint: `batch/kontrola/${id}/tkd`,
    method: 'POST',
    body,
  };
};

export const deleteRandomControlTerminals: MutationFn<DeleteRandomControlTerminalsBody, void> = ({ id, body }) => {
  return {
    endpoint: `batch/kontrola/${id}/tkd`,
    method: 'DELETE',
    body,
  };
};
