export enum EventDescriptionEnum {
  TKD = 'events.filters.event.reader',
  MANUALNY = 'events.filters.event.manual',
}

export enum RcpEnum {
  FALSE = '0',
  TRUE = '1',
}

export enum YesNoFiltersEnum {
  YES = 'true',
  NO = 'false',
}

export enum RcpDescriptionEnum {
  'events.addEvent.rcp.option.notRejegister',
  'events.addEvent.rcp.option.rejegister',
}
