import { MutationFn, UseQueryFn } from 'shared-ui';

export const fetchLogotype: UseQueryFn<string, Blob> = () => {
  return {
    endpoint: `/logo`,
    responseType: 'blob',
  };
};

export const addLogotype: MutationFn<{ file: Blob }, void> = ({ file }) => {
  const formData = new FormData();
  formData.append('logo', file);

  return {
    endpoint: '/logo',
    method: 'POST',
    body: formData,
    file: true,
  };
};

export const deleteLogotype: MutationFn<void, void> = () => {
  return {
    endpoint: '/logo',
    method: 'DELETE',
  };
};
