import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

import { CardThumbnailStylesProps, CardThumbnailIconWrapperStylesProps } from './CardThumbnail.types';

export const CardThumbnail = styled.div<CardThumbnailStylesProps>(
  ({ $color, $fontColor }) => css`
    border-radius: 16px;
    background-color: ${$color};
    padding: 24px;
    height: 184px;
    gap: 24px;
    display: grid;
    grid-template-rows: 1fr 54px;
    grid-template-columns: 0.6fr 1fr;
    align-items: flex-start;
    color: ${$fontColor};
  `,
);

export const CardThumbnailIconWrapper = styled.div<CardThumbnailIconWrapperStylesProps>(
  ({ theme: { shape }, $color }) => css`
    background-color: ${$color};
    border-radius: ${shape.borderRadius}px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
);

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0px;
`;

export const CardThumbnailTitle = styled(Typography)`
  font-weight: 700;
  margin-bottom: 4px;
`;

export const CardThumbnailDetailsTitle = styled(Typography)`
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 4px;
`;

export const CardThumbnailDetails = styled(Typography)`
  font-size: 12px;
`;
