import { ListItem as MaterialListItem } from '@mui/material';
import styled, { css } from 'styled-components';

import { OwnersHeaderItemStylesProps } from './HeaderSearchFilterItem.types';

export const HeaderItem = styled(MaterialListItem)(
  ({ theme: { palette } }) => css`
    text-transform: uppercase;
    color: ${palette.grey[900]};
    border: 1px solid transparent;
    padding: 0 16px;
  `,
);

export const HeaderItemSortable = styled.div<OwnersHeaderItemStylesProps>(
  ({ $isActive, theme: { palette, shape } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    padding: 0 10px;
    margin-left: -10px;
    border: 1px solid ${$isActive ? palette.grey[500] : 'transparent'};
    border-radius: ${shape.borderRadius}px;
    cursor: pointer;
    font-size: 12px;
  `,
);

export const AutocompleteWrapper = styled.div`
  min-width: 300px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px 10px;
`;

export const Input = styled.input(
  ({ theme: { palette } }) => css`
    color: ${palette.text.primary};
    font-size: 14px;
    margin-top: 2px;
    background-color: transparent;
    border: none;
    outline: none;

    ::placeholder {
      color: ${palette.text.primary};
      font-size: 12px;
    }
  `,
);

export const OptionsWrapper = styled.div`
  max-height: 320px;
`;

export const Option = styled.li(
  ({ theme: { palette } }) => css`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    color: ${palette.grey[900]};
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid ${palette.grey[500]};
    cursor: pointer;

    &:first-child {
      border-top: 1px solid ${palette.grey[500]};
    }

    &:last-child {
      border-bottom: none;
    }
  `,
);
