import { useQuery } from 'shared-ui';

import { fetchLogotype } from 'api/logotype/logotype';
import { QueryKeyEnum } from 'core/global.enum';

import { Logotype } from './Logotype';

export const LogotypeContainer = () => {
  const {
    data: logo,
    isLoading,
    isError,
  } = useQuery([QueryKeyEnum.LOGOTYPE], fetchLogotype, {
    handleErrorManually: true,
  });

  return <Logotype logo={!isError ? logo : undefined} isLoading={isLoading} />;
};
