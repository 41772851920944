import * as yup from 'yup';

import { Organization } from 'api/organizations/organizations.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { findAllByKey } from 'helpers/findAllByKey';
import { getObjectInNestedObjectByKey } from 'helpers/getObjectInNestedObjectByKey';
import { getObjectNestLevelForSpecificKey } from 'helpers/getObjectNestLevelForSpecificKey';
import { MAX_TREE_LEVEL } from '../Organization.variables';

import { OrganizationOption } from './OrganizationDetails.types';

export const useOrganizationDetailsValidation = (
  mainOrganization: Organization,
  organizationFromDetails: Organization,
  organizationFromDetailsParentElement: OrganizationOption | null,
) => {
  const { formatMessage } = useLocale();
  const allOrganizationCodesWithoutSelectedStandarized = findAllByKey(mainOrganization, 'kod')
    .map((code) => code.toLowerCase())
    .filter((code) => code !== organizationFromDetails.kod.toLowerCase());

  const isMainOrganization = mainOrganization.kod === organizationFromDetails.kod;

  const schema = yup.object().shape({
    kod: yup
      .string()
      .max(6, formatMessage({ id: AppMessages['definitions.organization.validation.code'] }))
      .test(
        'check organization code duplicate',
        formatMessage({ id: AppMessages['definitions.organization.add.name.validation'] }),
        (code) => !(code && allOrganizationCodesWithoutSelectedStandarized.includes(code.toLowerCase())),
      )
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    nazwa: yup
      .string()
      .max(60, formatMessage({ id: AppMessages['definitions.organization.validation.name'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    parent: isMainOrganization
      ? yup.object().nullable()
      : yup
          .object()
          .shape({
            id: yup
              .string()
              .test(
                'check if organization item exists in organization',
                formatMessage({ id: AppMessages['definitions.organization.details.parent.validation.exist'] }),
                (id) => {
                  if (id === organizationFromDetailsParentElement?.id) return true;

                  const selectedOrganizationItem = id
                    ? getObjectInNestedObjectByKey(mainOrganization, 'id', id)?.object
                    : null;

                  return !!selectedOrganizationItem;
                },
              )
              .test(
                'check if organization item exists in organization',
                formatMessage({ id: AppMessages['definitions.organization.details.parent.validation.same'] }),
                (id) => {
                  if (id === organizationFromDetails?.id) return false;

                  return true;
                },
              )
              .test(
                'check if new structure does not exceed max nesting level',
                formatMessage({ id: AppMessages['definitions.organization.details.parent.validation.level'] }),
                (id) => {
                  if (id === organizationFromDetailsParentElement?.id) return true;

                  const selectedOrganizationItem = id ? getObjectInNestedObjectByKey(mainOrganization, 'id', id) : null;

                  if (!selectedOrganizationItem) return false;

                  const objectNestLevelOfSelectedOrganization = getObjectNestLevelForSpecificKey(
                    organizationFromDetails,
                    'children',
                  );

                  const newTreeNestingLevel = objectNestLevelOfSelectedOrganization + selectedOrganizationItem.level;

                  return newTreeNestingLevel <= MAX_TREE_LEVEL;
                },
              )
              .test(
                'check if parent is not assigned to own children',
                formatMessage({ id: AppMessages['definitions.organization.details.parent.validation.children'] }),
                (id) => {
                  if (id === organizationFromDetailsParentElement?.id) return true;

                  const organizationCodesWithoutSelectedStandarized = findAllByKey(organizationFromDetails, 'id')
                    .map((id) => id.toLowerCase())
                    .filter((itemId) => itemId !== organizationFromDetails.id);

                  return !(id && organizationCodesWithoutSelectedStandarized.includes(id));
                },
              )
              .required(),
            nazwa: yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] })),
          })
          .required(),
  });
  return { schema };
};
