import { useState } from 'react';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import { PageLayout, TopBar, Pagination, Option, DetailsDrawer } from 'shared-ui';

import { useSelectedEvent } from 'hooks/useSelectedEvent/useSelectedEvent';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { Table } from 'ui/table/Table';

import { AddEventContainer } from './addEvent/AddEventContainer';
import { EventsProps, RefetchInterval } from './Events.types';
import { EventDetailsContainer } from './eventDetails/EventDetailsContainer';
import { EventsTableHeader } from './eventsTableHeader/EventsTableHeader';
import { EventsTableRow } from './eventsTableRow/EventsTableRow';
import { EventsFiltersContainer } from './eventsFilters/EventsFiltersContainer';
import { ExportEventsContainer } from './exportEvents/ExportEventsContainer';
import * as Styled from './Events.styles';

export const Events = ({
  eventsResponse,
  isLoading,
  refetchInterval,
  setRefetchInterval,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  isHistoryView,
  setTableSearchQuery,
  tableSearchQuery,
  isExportAvailable,
}: EventsProps) => {
  const [archivedSearchSelectedValue, setArchivedSearchSelectedValue] = useState<Option | null>(null);
  const { selectedEvent, setSelectedEvent } = useSelectedEvent();
  const { formatMessage } = useLocale();
  const displayFullTable = !selectedEvent;

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['events.title'] })}
      renderContent={() => (
        <>
          <Styled.TopBarWrapper>
            <TopBar.Link linkTo={AppRoute.events} isActive={!isHistoryView}>
              {formatMessage({ id: AppMessages['events.current'] })}
            </TopBar.Link>

            <TopBar.Link linkTo={AppRoute.eventsHistory} isActive={isHistoryView}>
              {formatMessage({ id: AppMessages['events.history'] })}
            </TopBar.Link>

            <TopBar.ButtonsWrapper>
              {isExportAvailable && <ExportEventsContainer />}
              <AddEventContainer />
            </TopBar.ButtonsWrapper>
          </Styled.TopBarWrapper>

          <Table
            isLoading={isLoading}
            gridTemplateColumns={`repeat(${displayFullTable ? 9 : 8}, minmax(100px, 1fr))`}
            data={eventsResponse?.data || []}
            nothingToShowText={formatMessage({ id: AppMessages['events.nothingToShow'] })}
            activeRowId={selectedEvent?.id}
            onClickRow={(event) => setSelectedEvent(selectedEvent?.id === event.id ? undefined : event)}
            renderHeader={() => <EventsTableHeader isHistoryView={isHistoryView} displayFullTable={displayFullTable} />}
            renderRow={(event) => <EventsTableRow event={event} displayFullTable={displayFullTable} />}
            renderAdditionalTopElement={() => (
              <>
                {isHistoryView && (
                  <EventsFiltersContainer
                    archivedSearchSelectedValue={archivedSearchSelectedValue}
                    setArchivedSearchSelectedValue={setArchivedSearchSelectedValue}
                    setTableSearchQuery={setTableSearchQuery}
                    tableSearchQuery={tableSearchQuery}
                  />
                )}

                <Styled.PaginationWrapper>
                  <Pagination
                    page={page}
                    setPage={setPage}
                    count={eventsResponse?.count || 0}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    withoutNavigation={!isHistoryView}
                  />

                  {!isHistoryView && (
                    <FormControl variant="filled">
                      <InputLabel focused={false}>{formatMessage({ id: AppMessages['events.refresh'] })}</InputLabel>
                      <Styled.RefreshSelect
                        variant="outlined"
                        value={refetchInterval}
                        onChange={(e) => setRefetchInterval(e.target.value as RefetchInterval)}
                      >
                        <MenuItem value={15000}>
                          {formatMessage({ id: AppMessages['events.refresh.fifteenSeconds'] })}
                        </MenuItem>
                        <MenuItem value={30000}>
                          {formatMessage({ id: AppMessages['events.refresh.thirtySeconds'] })}
                        </MenuItem>
                        <MenuItem value={60000}>
                          {formatMessage({ id: AppMessages['events.refresh.sixtySeconds'] })}
                        </MenuItem>
                      </Styled.RefreshSelect>
                    </FormControl>
                  )}
                </Styled.PaginationWrapper>
              </>
            )}
          />
        </>
      )}
      renderDetails={() => (
        <DetailsDrawer isOpen={!!selectedEvent} header={formatMessage({ id: AppMessages['event.details.title'] })}>
          {selectedEvent && <EventDetailsContainer selectedEvent={selectedEvent} />}
        </DetailsDrawer>
      )}
    />
  );
};
