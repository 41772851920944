export enum UserTypeEnum {
  ADMINISTRATOR = 'ADMINISTRATOR',
  ZWYKLY = 'ZWYKLY',
}

export enum UserStatusEnum {
  AKTYWNY = 'AKTYWNY',
  NIEAKTYWNY = 'NIEAKTYWNY',
}

export enum UserTypeDescriptionEnum {
  ADMINISTRATOR = 'user.type.admin',
  ZWYKLY = 'user.type.ordinary',
}

export enum UserStatusDescriptionEnum {
  AKTYWNY = 'user.status.active',
  NIEAKTYWNY = 'user.status.inactive',
}
