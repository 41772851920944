import { Tooltip as MaterialTooltip, Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import { Button } from 'shared-ui';

export const Tooltip = styled(({ className, ...props }) => (
  <MaterialTooltip {...props} classes={{ popper: className, tooltip: 'tooltip' }} />
))(
  ({ theme }) => css`
    & .tooltip {
      padding: 20px;
      background-color: ${theme.palette.background.paper};
      max-width: 500px;
    }
  `,
);

export const DownloadButton = styled(Button)`
  height: 42px;
`;

export const TooltipTitleWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const Text = styled(Typography)`
  width: 165px;
`;
