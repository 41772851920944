import { useCallback, useState, useEffect } from 'react';
import { FileWithPath } from 'react-dropzone';
import { Typography } from '@mui/material';
import { Modal, Dropzone } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { DownloadExampleFile } from 'ui/downloadExampleFile/DownloadExampleFile';
import { ACCEPTED_IMPORT_FILE_TYPES, MAX_IMPORT_FILES, MAX_IMPORT_FILE_SIZE_BYTES } from 'core/global.variables';

import { AddCardProps } from './AddCard.types';
import { AddCardFormContainer } from './addCardForm/AddCardFormContainer';

export const AddCard = ({
  file,
  setFile,
  onSubmitAddCardFromFile,
  uploadErrors,
  downloadLink,
  setDownloadLink,
  isModalOpen,
  onModalOpen,
  onModalClose,
  renderActionComponent,
  isSingleCardAdd,
  onAddSingleCardSuccess,
  limitUploadError,
  isFileValidationError,
  isValidatingFile,
}: AddCardProps) => {
  const { formatMessage } = useLocale();
  const [isDropRejected, setIsDropRejected] = useState(false);

  const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
    setFile(acceptedFiles[0]);
  }, []);

  useEffect(() => {
    setIsDropRejected(false);
  }, [isModalOpen]);

  const onClickRemoveButton = () => {
    setDownloadLink('');
    setFile(undefined);
  };

  return (
    <>
      {renderActionComponent && renderActionComponent(onModalOpen)}

      <Modal
        header={formatMessage({ id: AppMessages['cards.addCard.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
      >
        <>
          <Dropzone
            rootDropzoneProps={{
              onDrop,
              accept: ACCEPTED_IMPORT_FILE_TYPES,
              disabled: isSingleCardAdd,
              onDropRejected: () => {
                setIsDropRejected(true);
              },
              onDropAccepted: () => {
                setIsDropRejected(false);
              },
              maxFiles: MAX_IMPORT_FILES,
              maxSize: MAX_IMPORT_FILE_SIZE_BYTES,
            }}
            title={formatMessage({ id: AppMessages['cards.addCard.subtitle.import'] })}
            titleAddonEnd={<DownloadExampleFile exampleType="cards" />}
            file={file}
            onClickRemoveButton={onClickRemoveButton}
            isLoading={isValidatingFile}
            error={
              isDropRejected
                ? formatMessage({ id: AppMessages['cards.addCard.filesValidation'] })
                : uploadErrors
                ? formatMessage({ id: AppMessages['cards.addCard.uploadValidation'] })
                : limitUploadError
                ? limitUploadError
                : undefined
            }
            downloadLink={downloadLink && downloadLink}
            dropzoneDescription={formatMessage({ id: AppMessages['cards.addCard.requiredFiles'] })}
            addedFileSubtext={file && `${(file.size / 1048576).toFixed(2)} MB / 15 MB`}
          />

          <Typography variant="h5">{formatMessage({ id: AppMessages['cards.addCard.subtitle.manually'] })}</Typography>

          <AddCardFormContainer
            onModalClose={onModalClose}
            isFileLoaded={!!file}
            onSubmitAddCardFromFile={onSubmitAddCardFromFile}
            isSingleCardAdd={isSingleCardAdd}
            onAddSingleCardSuccess={onAddSingleCardSuccess}
            isFileValidationError={isFileValidationError || !!downloadLink}
          />
        </>
      </Modal>
    </>
  );
};
