import { useAutocomplete } from '@mui/material';
import { Checkbox, PopupSearchIcon } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import * as Styled from '../HeaderSearchFilterItem.styles';

import { SearchProps } from './Search.types';

export const Search = ({ setSearchQuery, options, isLoading, setSearch, selectedOptions = [] }: SearchProps) => {
  const { formatMessage } = useLocale();

  const optionsIds = selectedOptions.map(({ id }) => id);
  const optionsFromStorage = selectedOptions?.filter(({ id }) => !optionsIds.includes(id)) || [];

  const { getRootProps, getInputProps, getOptionProps, groupedOptions, setAnchorEl } = useAutocomplete({
    id: 'customized-autocomplete',
    multiple: true,
    open: true,
    options: [...optionsFromStorage, ...options],
    getOptionLabel: (option) => option.nazwa,
    onInputChange: (_, value) => setSearchQuery(value),
    onChange: (_, option) => (option ? setSearch(option) : setSearch([])),
    value: selectedOptions,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    clearOnBlur: false,
    clearOnEscape: false,
  });

  return (
    <Styled.AutocompleteWrapper>
      <div {...getRootProps()}>
        <Styled.InputWrapper ref={setAnchorEl}>
          <PopupSearchIcon />
          <Styled.Input {...getInputProps()} placeholder={formatMessage({ id: AppMessages['common.search'] })} />
        </Styled.InputWrapper>
      </div>
      {isLoading ? (
        <Styled.Option>{formatMessage({ id: AppMessages['common.loading'] })}</Styled.Option>
      ) : (
        <Styled.OptionsWrapper>
          {(groupedOptions as typeof options).map((option, index) => {
            const optionProps = getOptionProps({ option, index });
            const isSelected = !!selectedOptions?.find(({ id }) => id === option.id);

            return (
              <Styled.Option {...optionProps}>
                <Checkbox checked={isSelected} color="primary" />

                <span>{option.nazwa}</span>
              </Styled.Option>
            );
          })}
        </Styled.OptionsWrapper>
      )}
    </Styled.AutocompleteWrapper>
  );
};
