import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionButton } from 'ui/actionButton/ActionButton';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { DeleteGlcProps } from './DeleteGlc.types';

export const DeleteGlc = ({
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
  selectedGlc,
}: DeleteGlcProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <ActionButton type="delete" onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['glc.delete.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['glc.delete.submitButton'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['glc.delete.subtitle'] })} {selectedGlc.nazwa}
        </ActionModal.Subtitle>
        <ActionModal.Text>{formatMessage({ id: AppMessages['glc.delete.text'] })}</ActionModal.Text>
        <ActionModal.Text>{formatMessage({ id: AppMessages['glc.delete.warning'] })}</ActionModal.Text>
      </ActionModal>
    </>
  );
};
