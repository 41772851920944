import { Typography } from '@mui/material';
import { Checkbox } from 'shared-ui';
import styled, { css } from 'styled-components';

export const OrganizationHeader = styled.div`
  display: flex;
  margin: 16px 0 10px;
  align-items: center;
  display: grid;
  grid-template-columns: 70px 1fr;
`;

export const ToggleExpand = styled(Checkbox)(
  ({ theme: { palette } }) => css`
    border-radius: 0;
    margin: 0;
    height: 32px;
    width: 100%;
    background-color: ${palette.grey[300]};

    :hover {
      background-color: ${palette.grey[300]};
    }
  `,
);

export const ExpandAllWrapper = styled.div`
  width: 100%;
  min-width: 70px;
`;

export const ColumnsWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 45px;
  grid-template-columns: 150px 1fr;
`;

export const ColumnHeader = styled(Typography)(
  ({ theme: { palette } }) => css`
    font-size: 12px;
    color: ${palette.grey[900]};

    :first-child {
      margin-left: 15px;
    }
  `,
);
