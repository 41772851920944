import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import { Button } from 'shared-ui';

import { CardStatusStylesProps } from './CardAssign.types';

export const AssignButton = styled(Button)`
  width: 100%;
  min-height: 40px;
  margin: 24px 0px;
`;

export const CardStatus = styled(Typography)<CardStatusStylesProps>(
  ({ theme: { palette }, $isVisible }) => css`
    font-size: 12px;
    font-weight: 700;
    padding: 8px 0px 16px;
    color: ${palette.grey[900]};
    visibility: ${$isVisible ? 'visible' : 'hidden'};
  `,
);

export const Text = styled(Typography)`
  font-weight: 700;
  padding: 0px 0px 16px;
`;

export const OptionLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  padding: 6px 0px;
`;

export const AddCardButton = styled(Button)`
  width: 200px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px 52px;
`;

export const BlockInfo = styled(Typography)(
  ({ theme: { palette } }) => css`
    font-size: 12px;
    font-weight: 700;
    color: ${palette.grey[800]};
    margin-top: 8px;
  `,
);
