import { fetchGlc } from 'api/glc/glc';
import {
  GlcNumberSortEnum,
  QueryKeyLocalStorageEnum,
  TerminalsQueryKeysEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { Option } from '../HeaderSearchFilterItem.types';

export const useGlcs = (searchQuery: string, isEnabled: boolean) => {
  const { data, isInitialLoading } = useQuery([QueryKeyEnum.GLC_SEARCH, searchQuery], fetchGlc, {
    args: { query: searchQuery, sort: [GlcNumberSortEnum.ASC], limit: 1000 },
    enabled: isEnabled,
  });

  const {
    storedQueries: { [QueryKeyLocalStorageEnum.TERMINALS]: terminalsQuery },
    setQuery,
  } = useLocaleStoreQuery();

  return {
    options: data?.data || [],
    isLoading: isInitialLoading,
    selectedOptions: terminalsQuery.glcIds,
    setSearch: (options: Option[]) => {
      setQuery(QueryKeyLocalStorageEnum.TERMINALS, { ...terminalsQuery, [TerminalsQueryKeysEnum.GLC_IDS]: options });
    },
    clearSearch: () => {
      setQuery(QueryKeyLocalStorageEnum.TERMINALS, { ...terminalsQuery, [TerminalsQueryKeysEnum.GLC_IDS]: [] });
    },

    isOptionSelected: !!terminalsQuery?.glcIds?.length,
  };
};
