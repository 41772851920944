import { format } from 'date-fns';
import { PageLayout, TopBar, DetailsDrawer } from 'shared-ui';

import { DateFormat, HistoryActionEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { CardDetails } from '../cardDetails/CardDetails';
import { HistoryDatepickerWithModal } from 'reusable/historyDatepickerWithModal/HistoryDatepickerWithModal';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { HistoryActionAutocomplete } from 'reusable/historyActionAutocomplete/HistoryActionAutocomplete';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { HistoryList } from 'ui/historyList/HistoryList';
import { CardBlockadeDescriptionEnum, CardUnblockDescriptionEnum } from 'api/cards/cards.enum';
import { CardTypeDescriptionEnum } from '../Cards.enum';
import { SearchQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useSelectedSearchOptions } from 'hooks/useSelectedSearchOptions/useSelectedSearchOptions';

import { CardHistoryProps } from './CardHistory.types';

export const CardHistory = ({
  card,
  cardHistoryResponse,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  cardHistoryQuery,
  onSelectAction,
  onClearCardHistoryQuery,
  isLoadingCardHistory,
  handleSetDates,
  tableSearchQuery,
  setTableSearchQuery,
  tableSearchByQuery,
  setTableSearchByQuery,
}: CardHistoryProps) => {
  const { formatMessage } = useLocale();
  const { setSelectedCard, selectedCard } = useSelectedCard();
  const { searchOption, setSelectedSearchOption } = useSelectedSearchOptions(
    selectedCard?.archiwalna ? SearchQueryKeysEnum.ARCHIVED_CARD_HISTORY : SearchQueryKeysEnum.ACTIVE_CARD_HISTORY,
    setTableSearchQuery,
    tableSearchByQuery,
  );

  const isClearFiltersButtonVisible = Object.values(cardHistoryQuery).some((query) => query) || !!tableSearchQuery;

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['common.history'] })}
      withBackButton
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="1fr">
            <TopBar.EmptyBarWithLine />
          </TopBar>

          <HistoryList
            data={cardHistoryResponse?.data || []}
            isLoading={isLoadingCardHistory}
            filters={{
              isClearButtonVisible: isClearFiltersButtonVisible,
              onClearFilters: () => onClearCardHistoryQuery(),
              render: () => (
                <>
                  <HistoryDatepickerWithModal
                    dates={[
                      cardHistoryQuery.dataOd ? new Date(cardHistoryQuery.dataOd) : null,
                      cardHistoryQuery.dataDo ? new Date(cardHistoryQuery.dataDo) : null,
                    ]}
                    setDates={handleSetDates}
                  />

                  <HistoryActionAutocomplete
                    selectedAction={cardHistoryQuery.akcja}
                    onChangeAction={onSelectAction}
                    historyType="cards"
                  />

                  <TableSearchContainer
                    withoutToggle
                    inputSize="big"
                    searchBy="ownersAndUsers"
                    tableSearchQuery={tableSearchQuery}
                    setTableSearchQuery={setTableSearchQuery}
                    inputPlaceholder={formatMessage({ id: AppMessages['cards.history.filters.ownerOrUser'] })}
                    setTableSearchByQuery={setTableSearchByQuery}
                    tableSearchByQuery={tableSearchByQuery}
                    selectedValue={searchOption}
                    setSelectedValue={setSelectedSearchOption}
                  />
                </>
              ),
            }}
            pagination={{
              page,
              setPage,
              rowsPerPage,
              setRowsPerPage,
              count: cardHistoryResponse?.count || 0,
            }}
            renderHistoryItemBody={(history) => (
              <>
                {history.blokada && (
                  <HistoryList.ActionBodyItem
                    title={
                      history.akcja === HistoryActionEnum.ODBLOKOWANO
                        ? formatMessage({ id: AppMessages['cards.history.descriptionTitle.unlocking'] })
                        : formatMessage({ id: AppMessages['cards.history.descriptionTitle.blockade'] })
                    }
                    value={
                      history.akcja === HistoryActionEnum.ODBLOKOWANO
                        ? formatMessage({ id: CardUnblockDescriptionEnum[history.blokada] })
                        : formatMessage({ id: CardBlockadeDescriptionEnum[history.blokada] })
                    }
                  />
                )}

                {history.powodBlokady && (
                  <HistoryList.ActionBodyItem
                    title={
                      history.akcja === HistoryActionEnum.ODBLOKOWANO
                        ? formatMessage({ id: AppMessages['cards.history.descriptionTitle.unblockReason'] })
                        : formatMessage({ id: AppMessages['cards.history.descriptionTitle.blockadeReason'] })
                    }
                    value={history.powodBlokady}
                  />
                )}

                {history.wykonawca && (
                  <HistoryList.ActionBodyItem
                    title={formatMessage({ id: AppMessages['cards.history.descriptionTitle.executor'] })}
                    value={`${history.wykonawca.imie} ${history.wykonawca.nazwisko}`}
                  />
                )}

                {history.posiadacz && (
                  <HistoryList.ActionBodyItem
                    title={formatMessage({ id: AppMessages['cards.history.descriptionTitle.owner'] })}
                    value={`${history.posiadacz.imie} ${history.posiadacz.nazwisko}, ${history.posiadacz.id}`}
                  />
                )}

                {history.nazwa && (
                  <HistoryList.ActionModifiedFieldBodyItem
                    title={formatMessage({ id: AppMessages['cards.history.descriptionTitle.name'] })}
                    oldValue={history.nazwa.old}
                    newValue={history.nazwa.new}
                  />
                )}

                {history.typ && (
                  <HistoryList.ActionModifiedFieldBodyItem
                    title={formatMessage({ id: AppMessages['cards.history.descriptionTitle.type'] })}
                    oldValue={history.typ.old && formatMessage({ id: CardTypeDescriptionEnum[history.typ.old] })}
                    newValue={history.typ.new && formatMessage({ id: CardTypeDescriptionEnum[history.typ.new] })}
                  />
                )}

                {history.waznoscOd && (
                  <HistoryList.ActionModifiedFieldBodyItem
                    title={formatMessage({ id: AppMessages['cards.history.descriptionTitle.validFrom'] })}
                    oldValue={history.waznoscOd.old && format(new Date(history.waznoscOd.old), DateFormat.FULL)}
                    newValue={history.waznoscOd.new && format(new Date(history.waznoscOd.new), DateFormat.FULL)}
                  />
                )}

                {history.waznoscDo && (
                  <HistoryList.ActionModifiedFieldBodyItem
                    title={formatMessage({ id: AppMessages['cards.history.descriptionTitle.validTo'] })}
                    oldValue={history.waznoscDo.old && format(new Date(history.waznoscDo.old), DateFormat.FULL)}
                    newValue={history.waznoscDo.new && format(new Date(history.waznoscDo.new), DateFormat.FULL)}
                  />
                )}

                {history.dataZwrotu && (
                  <HistoryList.ActionModifiedFieldBodyItem
                    title={formatMessage({ id: AppMessages['cards.history.descriptionTitle.returnDate'] })}
                    oldValue={history.dataZwrotu.old && format(new Date(history.dataZwrotu.old), DateFormat.FULL)}
                    newValue={history.dataZwrotu.new && format(new Date(history.dataZwrotu.new), DateFormat.FULL)}
                  />
                )}

                {history.opis && (
                  <HistoryList.ActionModifiedLongFieldBodyItem
                    title={formatMessage({ id: AppMessages['cards.history.descriptionTitle.description'] })}
                    oldValue={history.opis.old}
                    newValue={history.opis.new}
                  />
                )}

                {history.pin && (
                  <HistoryList.ActionModifiedPinBodyItem
                    title={formatMessage({ id: AppMessages['cards.history.descriptionTitle.pin'] })}
                    oldValue={history.pin.old}
                    newValue={history.pin.new}
                  />
                )}
              </>
            )}
          />
        </>
      )}
      renderDetails={() =>
        card ? (
          <DetailsDrawer isOpen header={formatMessage({ id: AppMessages['card.details.title'] })}>
            <CardDetails selectedCard={card} setSelectedCard={setSelectedCard} />
          </DetailsDrawer>
        ) : (
          <HistoryList.DetailsLoader />
        )
      }
    />
  );
};
