import { toast } from 'react-toastify';
import { useQueryClient, useIsFetching } from 'shared-ui';

import { returnCard } from 'api/cards/cards';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { CardStatusEnum } from 'api/cards/cards.enum';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { useMutation } from 'hooks/useMutation/useMutation';
import { AppRouteWithParams } from 'routing/AppRoute.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { useModalState } from 'hooks/useModalState/useModalState';

import { ReturnCard } from './ReturnCard';
import { ReturnCardContainerProps } from './ReturnCard.types';

export const ReturnCardContainer = ({
  cardId,
  cardNumber,
  renderActionComponent,
  isOwnerView,
}: ReturnCardContainerProps) => {
  const queryClient = useQueryClient();
  const isCardHistoryView = useIsExactPath(AppRouteWithParams.cardHistory.path);
  const isOwnerHistoryView = useIsExactPath(AppRouteWithParams.ownerHistory.path);
  const { formatMessage } = useLocale();
  const { setSelectedCard, selectedCard } = useSelectedCard();
  const { selectedOwner, setSelectedOwner } = useSelectedOwner();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const isFetchingOwnerDetails = !!useIsFetching([QueryKeyEnum.OWNER_DETAILS, selectedOwner?.id]);

  const { mutate: onReturnCard, isLoading } = useMutation(returnCard, {
    onSuccess: () => {
      if (selectedOwner && isOwnerView) {
        queryClient.invalidateQueries([QueryKeyEnum.OWNER_DETAILS, selectedOwner.id]);
        queryClient.invalidateQueries([isOwnerHistoryView ? QueryKeyEnum.OWNER_HISTORY : QueryKeyEnum.OWNERS]);
        setSelectedOwner(selectedOwner);
      }

      if (selectedCard && !isOwnerView) {
        setSelectedCard({ ...selectedCard, status: CardStatusEnum.DOSTEPNA, posiadacz: undefined });
        queryClient.invalidateQueries([isCardHistoryView ? QueryKeyEnum.CARD_HISTORY : QueryKeyEnum.CARDS]);
      }

      toast.dark(formatMessage({ id: AppMessages['card.return.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onReturnCard({ id: cardId });
  };

  return (
    <ReturnCard
      renderActionComponent={renderActionComponent}
      cardId={cardId}
      cardNumber={cardNumber}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
      isReturnDisabled={isFetchingOwnerDetails || isLoading}
    />
  );
};
