import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedGroupTerminalPermission } from 'hooks/useSelectedGroupTerminalPermission/useSelectedGroupTerminalPermission';
import { deleteMultiGroupTerminalsPermissions } from 'api/permissions/permissions';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteGroupTerminalsPermissions } from './DeleteGroupTerminalsPermissions';
import { DeleteGroupTerminalsPermissionsContainerProps } from './DeleteGroupTerminalsPermissions.types';

export const DeleteGroupTerminalsPermissionsContainer = ({
  checkedPermissions,
  setCheckedPermissions,
  groupId,
}: DeleteGroupTerminalsPermissionsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedGroupTerminalPermission, setSelectedGroupTerminalPermission } = useSelectedGroupTerminalPermission();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteMultiGroupTerminalsPermissions, isLoading } = useMutation(
    deleteMultiGroupTerminalsPermissions,
    {
      onSuccess: () => {
        if (
          selectedGroupTerminalPermission &&
          checkedPermissions.some(({ id }) => id === selectedGroupTerminalPermission?.id)
        ) {
          setSelectedGroupTerminalPermission(undefined);
        }

        queryClient.invalidateQueries([QueryKeyEnum.GROUP_TERMINALS_PERMISSIONS_SERVERS]);
        queryClient.invalidateQueries([QueryKeyEnum.GROUP_TERMINALS_PERMISSIONS]);
        toast.dark(formatMessage({ id: AppMessages['permissions.multiAction.delete.success'] }));
        setCheckedPermissions([]);
        onModalClose();
      },
    },
  );

  const onSubmit = () => {
    onDeleteMultiGroupTerminalsPermissions({
      id: groupId,
      body: { idPermissions: checkedPermissions.map(({ id }) => id) },
    });
  };

  return (
    <DeleteGroupTerminalsPermissions
      checkedPermissions={checkedPermissions}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
