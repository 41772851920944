import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useReturnDateMultiCardsValidation = (minDate: Date) => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    dataZwrotu: yup
      .date()
      .min(minDate, formatMessage({ id: AppMessages['cards.multiAction.return.datepicker.pastDate.validation'] }))
      .typeError(formatMessage({ id: AppMessages['cards.multiAction.return.datepicker.validation'] }))
      .required(formatMessage({ id: AppMessages['cards.multiAction.return.datepicker.validation'] })),
  });

  return { schema };
};
