import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useModifyWantedInformationValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    informacja: yup
      .string()
      .max(64, formatMessage({ id: AppMessages['wanted.modify.information.validation'] }))
      .required(),
  });

  return { schema };
};
