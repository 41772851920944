import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatISO } from 'date-fns';

import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { OwnerDocumentEnum } from 'api/owners/owners.enum';
import { Owner } from 'api/owners/owners.types';
import { useUpdateOwnerDetails } from 'hooks/useUpdateOwnerDetails/useUpdateOwnerDetails';

import { OwnerPersonalDetails } from './OwnerPersonalDetails';
import { OwnerPersonalDetailsContainerProps, OwnerPersonalDetailsForm } from './OwnerPersonalDetails.types';
import { useOwnerPersonalDetailsValidation } from './OwnerPersonalDetails.validation';

export const OwnerPersonalDetailsContainer = ({
  selectedOwner,
  isEditDisabled,
}: OwnerPersonalDetailsContainerProps) => {
  const { selectedOwner: globalSelectedOwner } = useSelectedOwner();
  const { onUpdateOwnerSubmit, isLoadingUpdateOwner } = useUpdateOwnerDetails(selectedOwner);

  const resetToSelected = (selected: Owner) => {
    return {
      ...selected,
      dokumentTozsamosci: selected.dokumentTozsamosci || '',
      numerDokumentu: selected.numerDokumentu || '',
      dataUrodzenia: selected.dataUrodzenia ? formatISO(new Date(selected.dataUrodzenia)) : '',
      pesel: selected.pesel || '',
      email: selected.email || '',
      telefon: selected.telefon || '',
      opis: selected.opis || '',
    };
  };

  const { schema } = useOwnerPersonalDetailsValidation();
  const formMethods = useForm<OwnerPersonalDetailsForm>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: resetToSelected(selectedOwner),
  });

  useEffect(() => {
    formMethods.reset(resetToSelected(selectedOwner));
  }, [globalSelectedOwner]);

  const onSubmit = (body: OwnerPersonalDetailsForm) => {
    onUpdateOwnerSubmit({
      ...selectedOwner,
      ...body,
      createdAt: selectedOwner.createdAt,
      dataUrodzenia: body.dataUrodzenia ? formatISO(new Date(body.dataUrodzenia)) : undefined,
      pesel: !!Number(body.pesel) ? Number(body.pesel) : undefined,
      dokumentTozsamosci:
        body.dokumentTozsamosci && body.dokumentTozsamosci in OwnerDocumentEnum
          ? (body.dokumentTozsamosci as OwnerDocumentEnum)
          : null,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <OwnerPersonalDetails
        isLoadingUpdateOwner={isLoadingUpdateOwner}
        selectedOwner={selectedOwner}
        onSubmit={onSubmit}
        resetToSelected={resetToSelected}
        isEditDisabled={isEditDisabled}
      />
    </FormProvider>
  );
};
