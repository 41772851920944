import { MutationFn, UseQueryFn } from 'shared-ui';

import { PaginatedResponse } from 'core/global.types';
import { Owner } from 'api/owners/owners.types';

import {
  AddAnnouncementBody,
  AddAnnouncementOwnersBody,
  AddMultiOwnersBody,
  Announcement,
  AnnouncementsHistory,
  ModifyAnnouncementsBody,
  UpdateAnnouncementBody,
} from './announcements.types';
import { AnnouncementModEnum } from './announcements.enum';

export const fetchAnnouncements: UseQueryFn<string, PaginatedResponse<Announcement>> = (id) => {
  return {
    endpoint: `/komunikat/rcp/${id}`,
  };
};

export const fetchAllAnnouncements: UseQueryFn<unknown, PaginatedResponse<Announcement>> = () => {
  return {
    endpoint: `/komunikat`,
  };
};

export const fetchAnnouncementDetails: UseQueryFn<string, Announcement> = (id) => {
  return {
    endpoint: `/komunikat/${id}`,
  };
};

export const updateAnnouncement: MutationFn<{ id: string; body: UpdateAnnouncementBody }, Pick<Announcement, 'id'>> = ({
  id,
  body,
}) => {
  return {
    endpoint: `/komunikat/${id}`,
    method: 'PUT',
    body,
  };
};

export const deleteAnnouncement: MutationFn<string, null> = (id) => {
  return {
    endpoint: `/komunikat/${id}`,
    method: 'DELETE',
  };
};

export const deleteMultiAnnouncements: MutationFn<{ body: string[] }, void> = ({ body }) => {
  return {
    endpoint: '/batch/komunikat',
    method: 'DELETE',
    body,
  };
};

export const fetchAnnouncementsHistory: UseQueryFn<void, PaginatedResponse<AnnouncementsHistory>> = () => {
  return {
    endpoint: `/komunikat/historia`,
  };
};

export const addAnnouncement: MutationFn<AddAnnouncementBody, Announcement> = (body) => {
  return {
    endpoint: '/komunikat',
    method: 'POST',
    body,
  };
};

export const fetchAnnouncementMods: UseQueryFn<string, { mods: AnnouncementModEnum[] }> = (id) => {
  return {
    endpoint: `/tkd/${id}/komunikat/mod`,
  };
};

export const fetchAnnouncementModes: UseQueryFn<string, number[]> = (id) => {
  return {
    endpoint: `/tkd/${id}/komunikat/tryb`,
  };
};

export const fetchAnnouncementAssignedOwners: UseQueryFn<string, PaginatedResponse<Owner>> = (id) => {
  return {
    endpoint: `/komunikat/${id}/pkd`,
  };
};

export const deleteAnnouncementAssignedOwners: MutationFn<{ id: string; body: string[] }, null> = ({ id, body }) => {
  return {
    endpoint: `/batch/komunikat/${id}/pkd`,
    method: 'DELETE',
    body,
  };
};

export const addAnnouncementOwners: MutationFn<AddAnnouncementOwnersBody, PaginatedResponse<Owner>> = ({
  id,
  ...body
}) => {
  return {
    endpoint: `/komunikat/${id}/pkd`,
    method: 'POST',
    body,
  };
};

export const addMultiOwnersToAnnouncements: MutationFn<AddMultiOwnersBody, void> = (body) => {
  return {
    endpoint: '/batch/komunikat/pkd',
    method: 'POST',
    body,
  };
};

export const modifyAnnouncements: MutationFn<ModifyAnnouncementsBody, void> = (body) => {
  return {
    endpoint: '/batch/komunikat',
    method: 'PUT',
    body,
  };
};
