import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7px 24px;
`;

export const SubHeader = styled((props) => <Typography {...props} variant="h4" />)(
  ({ theme: { palette } }) => css`
    padding-bottom: 16px;
    margin-bottom: 24px;
    margin-top: 5px;
    border-bottom: 2px solid ${palette.grey[300]};
    grid-column: 1/3;

    &:first-child {
      margin-top: 0;
    }
  `,
);
