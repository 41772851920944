import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

import { ServerStatusProps } from './ServerStatus.types';
import * as Styled from './ServerStatus.styles';

export const ServerStatus = ({ status }: ServerStatusProps) => {
  const { formatMessage } = useLocale();

  const statusText = {
    POLACZONY: formatMessage({ id: AppMessages['devices.servers.status.connected'] }),
    ODLACZONY: formatMessage({ id: AppMessages['devices.servers.status.disconnected'] }),
    AWARIA: formatMessage({ id: AppMessages['devices.servers.status.failure'] }),
  }[status];

  return (
    <Styled.Container status={status}>
      <Styled.Text status={status}>{statusText}</Styled.Text>
    </Styled.Container>
  );
};
