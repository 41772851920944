import { useLocation } from 'react-router-dom';
import { TopBar } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { useSelectedSearchOptions } from 'hooks/useSelectedSearchOptions/useSelectedSearchOptions';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';

import { ControlBarProps } from './ControlBar.types';

export const ControlBar = ({ searchQueryKey, setTableSearchQuery, children }: ControlBarProps) => {
  const { formatMessage } = useLocale();
  const location = useLocation();

  const { searchOption, setSelectedSearchOption } = useSelectedSearchOptions(searchQueryKey, setTableSearchQuery);

  return (
    <TopBar gridTemplateColumns="auto auto auto 1fr auto">
      <TopBar.Link linkTo={AppRoute.controlRandom} isActive={location.pathname === AppRoute.controlRandom}>
        {formatMessage({ id: AppMessages['control.random'] })}
      </TopBar.Link>

      <TopBar.Link linkTo={AppRoute.controlInstant} isActive={location.pathname === AppRoute.controlInstant}>
        {formatMessage({ id: AppMessages['control.instant'] })}
      </TopBar.Link>

      <TopBar.Link linkTo={AppRoute.controlHistory} isActive={location.pathname === AppRoute.controlHistory}>
        {formatMessage({ id: AppMessages['common.history'] })}
      </TopBar.Link>

      {setTableSearchQuery ? (
        <TopBar.Search>
          <TableSearchContainer
            searchIconTestId="page-search"
            inputPlaceholder={formatMessage({ id: AppMessages['control.instant.search'] })}
            searchBy="owners"
            setTableSearchQuery={setTableSearchQuery}
            selectedValue={searchOption}
            setSelectedValue={setSelectedSearchOption}
          />
        </TopBar.Search>
      ) : (
        <TopBar.EmptyBarWithLine />
      )}

      {children}
    </TopBar>
  );
};
