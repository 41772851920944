import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'shared-ui';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';
import { Input } from 'ui/input/Input';
import * as Styled from '../CardsMultiActions.styles';

import { EditMulitCardsFormBody, EditMultiCardsProps } from './EditMultiCards.types';

export const BlockMultiCards = ({
  checkedCards,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
}: EditMultiCardsProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useFormContext<EditMulitCardsFormBody>();

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<EditIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['cards.multiAction.edit.button'] })}
      </Button>

      <ActionModal
        header={formatMessage({ id: AppMessages['cards.multiAction.edit.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={isLoading || !isValid}
        submitButtonText={formatMessage({ id: AppMessages['cards.multiAction.edit.actionButton'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['cards.multiAction.edit.subtitle'] })} {checkedCards.length}
        </ActionModal.Subtitle>
        <Styled.Wrapper>
          <Controller
            name="nazwa"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                errorMessage={errors.nazwa?.message}
                label={formatMessage({ id: AppMessages['cards.multiAction.edit.name.label'] })}
                placeholder={formatMessage({ id: AppMessages['cards.multiAction.edit.name.placeholder'] })}
              />
            )}
          />
        </Styled.Wrapper>

        <Controller
          name="opis"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              errorMessage={errors.opis?.message}
              label={formatMessage({ id: AppMessages['cards.multiAction.edit.description.label'] })}
              placeholder={formatMessage({ id: AppMessages['cards.multiAction.edit.description.placeholder'] })}
              multiline
              maxRows={4}
            />
          )}
        />
      </ActionModal>
    </>
  );
};
