import { useFormContext } from 'react-hook-form';
import { Button } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { AddEventExportFormatBody } from 'api/eventsExportFormat/eventsExportFormats.types';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh-primary.svg';
import { Loader } from 'ui/loader/Loader';

import { PreviewFormProps } from './PreviewForm.types';
import * as Styled from './PreviewForm.styles';

export const PreviewForm = ({
  eventExportFormatPreview,
  isLoadingPreviewEventExportFormat,
  onPreviewEventExportFormat,
  isPreviewPageMode,
}: PreviewFormProps) => {
  const { formatMessage } = useLocale();

  const { watch } = useFormContext<AddEventExportFormatBody>();

  return (
    <>
      <Styled.InputWrapper>
        {isLoadingPreviewEventExportFormat && (
          <Styled.LoaderWrapper>
            <Loader size={25} />
          </Styled.LoaderWrapper>
        )}

        <Input
          label={formatMessage({
            id: AppMessages['definitions.events.addExportFormat.preview.formatPreview'],
          })}
          value={
            eventExportFormatPreview ? JSON.stringify(eventExportFormatPreview).replace('"', '').replace('"', '') : ''
          }
          multiline
          inputProps={{
            readOnly: true,
          }}
          disabled={isPreviewPageMode}
          withoutHelperText={isPreviewPageMode}
        />
      </Styled.InputWrapper>

      {!isPreviewPageMode && (
        <Styled.ButtonWrapper>
          <Button
            onClick={() => onPreviewEventExportFormat(watch())}
            variant="outlined"
            color="primary"
            startIcon={<RefreshIcon />}
          >
            {formatMessage({
              id: AppMessages['definitions.events.addExportFormat.preview.refresh'],
            })}
          </Button>
        </Styled.ButtonWrapper>
      )}
    </>
  );
};
