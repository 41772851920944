import { useParams } from 'react-router-dom';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { deleteOwnerIndividualPermission } from 'api/permissions/permissions';
import { useSelectedOwnerIndividualPermission } from 'hooks/useSelectedOwnerIndividualPermission/useSelectedOwnerIndividualPermission';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteOwnerPermission } from './DeleteOwnerIndividualPermission';
import { DeleteOwnerPermissionContainerProps } from './DeleteOwnerIndividualPermission.types';

export const DeleteOwnerPermissionContainer = ({ selectedPermission }: DeleteOwnerPermissionContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const ownerId = id || '';
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { setSelectedOwnerIndividualPermission } = useSelectedOwnerIndividualPermission();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteOwnerIndividualPermission, isLoading } = useMutation(deleteOwnerIndividualPermission, {
    onSuccess: () => {
      setSelectedOwnerIndividualPermission(undefined);
      queryClient.invalidateQueries([QueryKeyEnum.OWNER_PERMISSIONS_INDIVIDUAL_SERVERS]);
      queryClient.invalidateQueries([QueryKeyEnum.OWNER_PERMISSIONS_INDIVIDUAL]);
      toast.dark(formatMessage({ id: AppMessages['permission.individual.delete.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteOwnerIndividualPermission({
      permissionId: selectedPermission.id,
      pkdId: ownerId,
    });
  };

  return (
    <DeleteOwnerPermission
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
