import { FileWithPath } from 'react-dropzone';
import { UseQueryFn, MutationFn } from 'shared-ui';

import { PaginatedResponse } from 'core/global.types';

import {
  AddOwnerBody,
  EditMultiOwnersBody,
  Owner,
  PhotoData,
  UpdateExpiryDateMultiOwnersCardsBody,
  UpdateExpiryDateMultiOwnersCardsResponse,
  ArchiveMultiOwnersResponse,
  OwnerHistory,
  ImportOwnersWithPhotosFileValidationResponse,
  AddOwnersFromFileResponse,
  AddOwnersWithPhotosResponse,
  DownloadExampleFileResponse,
  ImportFileWithPhotosValidationResponse,
  OwnerEffectivePermissionsHistory,
} from './owners.types';

export const fetchOwners: UseQueryFn<unknown, PaginatedResponse<Owner>> = () => {
  return {
    endpoint: `/pkd`,
  };
};

export const addOwner: MutationFn<AddOwnerBody, Owner> = (body) => {
  return {
    endpoint: '/pkd',
    method: 'POST',
    body,
  };
};

export const fetchOwnerDetails: UseQueryFn<string, Owner> = (id) => {
  return {
    endpoint: `/pkd/${id}`,
  };
};

export const updateOwnerDetails: MutationFn<{ id: string; body: Owner }, void> = ({ id, body }) => {
  return {
    endpoint: `/pkd/${id}`,
    method: 'PUT',
    body,
  };
};

export const addOwnerPhoto: MutationFn<{ file: Blob }, PhotoData> = ({ file }) => {
  const formData = new FormData();
  formData.append('zdjecie', file);

  return {
    endpoint: '/pkd/zdjecie',
    method: 'POST',
    body: formData,
    file: true,
  };
};

export const fetchOwnerPhoto: UseQueryFn<string, Blob> = (id) => {
  return {
    endpoint: `/pkd/${id}/zdjecie`,
    responseType: 'blob',
  };
};

export const deleteOwner: MutationFn<string, null> = (id) => {
  return {
    endpoint: `/pkd/${id}`,
    method: 'DELETE',
  };
};

export const deleteMultiOwners: MutationFn<{ body: string[] }, null> = ({ body }) => {
  return {
    endpoint: '/batch/pkd',
    method: 'DELETE',
    body,
  };
};

export const editMultiOwners: MutationFn<EditMultiOwnersBody, null> = (body) => {
  return {
    endpoint: '/batch/pkd',
    method: 'POST',
    body,
  };
};

export const updateExipryDateMultiOwnersCards: MutationFn<
  UpdateExpiryDateMultiOwnersCardsBody,
  UpdateExpiryDateMultiOwnersCardsResponse
> = (body) => {
  return {
    endpoint: '/batch/pkd/waznosc',
    method: 'POST',
    body,
  };
};

export const archiveOwner: MutationFn<string, Owner> = (id) => {
  return {
    endpoint: `/pkd/${id}/archiwizuj`,
    method: 'POST',
  };
};

export const unarchiveOwner: MutationFn<string, Owner> = (id) => {
  return {
    endpoint: `/pkd/${id}/przywroc`,
    method: 'POST',
  };
};

export const unarchiveMultiOwners: MutationFn<{ body: string[] }, void> = ({ body }) => {
  return {
    endpoint: '/pkd/przywroc/wiele',
    method: 'POST',
    body,
  };
};

export const archiveMultiOwners: MutationFn<{ body: string[] }, ArchiveMultiOwnersResponse> = ({ body }) => {
  return {
    endpoint: '/pkd/archiwizuj/wiele',
    method: 'POST',
    body,
  };
};

export const fetchOwnerHistory: UseQueryFn<string, PaginatedResponse<OwnerHistory>> = (id) => {
  return {
    endpoint: `/pkd/${id}/historia`,
  };
};

export const fetchOwnerHistoryOldPhoto: UseQueryFn<string, Blob> = (id) => {
  return {
    endpoint: `/pkd/${id}/historia/zdjecie/old`,
    responseType: 'blob',
  };
};

export const fetchOwnerHistoryNewPhoto: UseQueryFn<string, Blob> = (id) => {
  return {
    endpoint: `/pkd/${id}/historia/zdjecie/new`,
    responseType: 'blob',
  };
};

export const addOwnersWithPhotosImportFile: MutationFn<FileWithPath, ImportOwnersWithPhotosFileValidationResponse> = (
  file,
) => {
  const formData = new FormData();
  formData.append('file', file);

  return {
    endpoint: '/pkd/import/photo/validate',
    method: 'POST',
    body: formData,
    file: true,
  };
};

export const addOwnersWithPhotosImportPhotos: MutationFn<FileWithPath[], void> = (files) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('file', file);
  });

  return {
    endpoint: '/pkd/import/photo/files',
    method: 'POST',
    body: formData,
    file: true,
  };
};

export const addOwnersWithPhotosValidateFileWithPhotos: MutationFn<string, ImportFileWithPhotosValidationResponse> = (
  importFileName,
) => {
  return {
    endpoint: '/pkd/import/photo/validatephotos',
    method: 'POST',
    body: {
      importFileName,
    },
  };
};

export const addOwnersWithPhotos: MutationFn<string, AddOwnersWithPhotosResponse> = (importFileName) => {
  return {
    endpoint: '/pkd/import/photo',
    method: 'POST',
    body: {
      importFileName,
    },
  };
};

export const validateOwnersImportFile: MutationFn<FileWithPath, void> = (file) => {
  const formData = new FormData();
  formData.append('file', file);

  return {
    endpoint: '/pkd/import/nophoto/validate',
    method: 'POST',
    body: formData,
    file: true,
  };
};

export const addOwnersFromFile: MutationFn<{ body: FileWithPath }, AddOwnersFromFileResponse> = ({ body }) => {
  const formData = new FormData();
  formData.append('file', body);

  return {
    endpoint: '/pkd/import/nophoto',
    method: 'POST',
    body: formData,
    file: true,
  };
};

export const downloadOwnersImportExample: MutationFn<void, DownloadExampleFileResponse> = () => {
  return {
    endpoint: '/pkd/import/nophoto/example',
    method: 'POST',
  };
};

export const downloadOwnersWithPhotoImportExample: MutationFn<void, DownloadExampleFileResponse> = () => {
  return {
    endpoint: '/pkd/import/photo/example',
    method: 'POST',
  };
};

export const fetchOwnerEffectivePermissionsHistory: UseQueryFn<
  string,
  PaginatedResponse<OwnerEffectivePermissionsHistory>
> = (id) => {
  return {
    endpoint: `/pkd/${id}/historia/uprawnienia/efektywne`,
    method: 'GET',
  };
};
