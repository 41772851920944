import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { useMutation } from 'hooks/useMutation/useMutation';
import { deleteGroupAssignedOwners } from 'api/groups/groups';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteMultiGroupAssignedOwners } from './DeleteMultiGroupAssignedOwners';
import { DeleteMultiGroupAssignedOwnersContainerProps } from './DeleteMultiGroupAssignedOwners.types';

export const DeleteMultiGroupAssignedOwnersContainer = ({
  checkedOwners,
  setCheckedOwners,
}: DeleteMultiGroupAssignedOwnersContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedOwner, setSelectedOwner } = useSelectedOwner();
  const { id } = useParams<{ id: string }>();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteGroupAssignedOwners, isLoading } = useMutation(deleteGroupAssignedOwners, {
    onSuccess: () => {
      if (selectedOwner && checkedOwners.some(({ id }) => id === selectedOwner?.id)) {
        setSelectedOwner(undefined);
      }

      queryClient.invalidateQueries([QueryKeyEnum.GROUP_ASSIGNED_OWNERS]);
      toast.dark(formatMessage({ id: AppMessages['owners.multiAction.delete.success'] }));
      setCheckedOwners([]);
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteGroupAssignedOwners({ id: id || '', body: checkedOwners.map(({ id }) => id) });
  };

  return (
    <DeleteMultiGroupAssignedOwners
      checkedOwners={checkedOwners}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
