import { useState } from 'react';
import { ClientError, APIError } from 'shared-ui';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { useErrorHandler } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { deletePosition } from 'api/positions/positions';
import { DeletePositionErrorResponse } from 'api/positions/positions.types';
import { useSelectedPosition } from 'hooks/useSelectedPosition/useSelectedPosition';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeletePosition } from './DeletePosition';
import { DeletePositionContainerProps } from './DeletePosition.types';

export const DeletePositionContainer = ({ selectedPosition }: DeletePositionContainerProps) => {
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);

  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { setSelectedPosition } = useSelectedPosition();
  const { handleError } = useErrorHandler();

  const { mutate: onDeletePosition, isLoading } = useMutation(deletePosition, {
    onSuccess: () => {
      setSelectedPosition(undefined);
      queryClient.invalidateQueries([QueryKeyEnum.POSITIONS]);
      toast.dark(formatMessage({ id: AppMessages['definitions.position.delete.success'] }));
      closeModals();
    },
    onError: (error: ClientError<APIError<DeletePositionErrorResponse>>) => {
      if (error.response?.data.type === 'pkd_exists') {
        onModalClose();
        return setErrorModalOpen(true);
      }

      handleError({ error, omitAPIErrorMessage: true });
    },
  });

  const onSubmit = () => {
    onDeletePosition(selectedPosition.id);
  };

  const closeModals = () => {
    onModalClose();
    setErrorModalOpen(false);
  };

  return (
    <DeletePosition
      selectedPosition={selectedPosition}
      isModalOpen={isModalOpen}
      onModalClose={closeModals}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
      isErrorModalOpen={isErrorModalOpen}
    />
  );
};
