import { formatISO } from 'date-fns';
import { Autocomplete } from 'shared-ui';

import { HistoryDatepickerWithModal } from 'reusable/historyDatepickerWithModal/HistoryDatepickerWithModal';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { HistoryActionAutocomplete } from 'reusable/historyActionAutocomplete/HistoryActionAutocomplete';
import { useLocale } from 'hooks/useLocale/useLocale';
import { ControlTypeDescriptionEnum, ControlTypeEnum } from 'api/control/control.enum';
import { AppMessages } from 'i18n/messages';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { ControlHistoryQuery } from 'context/query/queryContext/QueryContext.types';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';

import { ControlHistoryFiltersProps } from './ControlHistoryFilters.types';
import * as Styled from './ControlHistoryFilters.styles';

export const ControlHistoryFilters = ({
  servers,
  executorSearchQuery,
  setExecutorSearchQuery,
  tkdSearchQuery,
  setTkdSearchQuery,
  ownerSearchQuery,
  setOwnerSearchQuery,
}: ControlHistoryFiltersProps) => {
  const { formatMessage } = useLocale();

  const {
    storedQueries: { controlHistory: controlHistoryQuery },
    setQuery,
  } = useLocaleStoreQuery();

  const setControlHistoryQuery = (query: ControlHistoryQuery) => {
    setQuery(QueryKeyLocalStorageEnum.CONTROL_HISTORY, { ...controlHistoryQuery, ...query });
  };

  const serversOptions =
    servers?.map((server) => ({
      label: `${server.nazwa}`,
      value: server.id,
    })) || [];

  const controlTypeOptions = Object.values(ControlTypeEnum).map((controlType) => ({
    label: formatMessage({ id: ControlTypeDescriptionEnum[controlType] }).toUpperCase(),
    value: controlType,
  }));

  return (
    <>
      <HistoryDatepickerWithModal
        dates={[
          controlHistoryQuery.dataOd ? new Date(controlHistoryQuery.dataOd) : null,
          controlHistoryQuery.dataDo ? new Date(controlHistoryQuery.dataDo) : null,
        ]}
        setDates={(dates) =>
          setControlHistoryQuery({
            dataOd: dates[0] ? formatISO(dates[0]) : undefined,
            dataDo: dates[1] ? formatISO(dates[1]) : undefined,
          })
        }
      />

      <Autocomplete
        onChange={(_, option) => setControlHistoryQuery({ typ: option?.value as ControlTypeEnum })}
        value={controlTypeOptions.find((controlType) => controlHistoryQuery.typ === controlType.value) || null}
        options={controlTypeOptions}
        loadingText={formatMessage({ id: AppMessages['common.loading'] })}
        noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
        placeholder={formatMessage({ id: AppMessages['control.history.filters.type'] })}
        inputVariant="outlined"
        inputSize="big"
        isOptionEqualToValue={(option, value) => option.value === value.value}
        stopRotatePopupIndicator
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            <Styled.AutocompleteOption>{option.label.toLowerCase()}</Styled.AutocompleteOption>
          </li>
        )}
      />

      <HistoryActionAutocomplete
        selectedAction={controlHistoryQuery.akcja}
        onChangeAction={(action) => setControlHistoryQuery({ akcja: action })}
        historyType="control"
      />

      <Autocomplete
        onChange={(_, option) => setControlHistoryQuery({ serwer: option || undefined, tkd: undefined })}
        value={serversOptions.find((server) => controlHistoryQuery.serwer?.value === server.value) || null}
        options={serversOptions}
        loadingText={formatMessage({ id: AppMessages['common.loading'] })}
        noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
        placeholder={formatMessage({ id: AppMessages['control.history.filters.server'] })}
        inputVariant="outlined"
        inputSize="big"
        isOptionEqualToValue={(option, value) => option.value === value.value}
        stopRotatePopupIndicator
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            <Styled.AutocompleteOption>{option.label}</Styled.AutocompleteOption>
          </li>
        )}
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['control.history.filters.reader'] })}
        searchBy="terminals"
        tableSearchQuery={tkdSearchQuery}
        setTableSearchQuery={setTkdSearchQuery}
        selectedValue={controlHistoryQuery.tkd}
        setSelectedValue={(option) => setControlHistoryQuery({ tkd: option || undefined })}
        inputSize="big"
        isDisabled={!controlHistoryQuery.serwer}
        serverId={controlHistoryQuery.serwer?.value as string}
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['control.history.filters.executor'] })}
        searchBy="users"
        tableSearchQuery={executorSearchQuery}
        setTableSearchQuery={setExecutorSearchQuery}
        selectedValue={controlHistoryQuery.query}
        setSelectedValue={(option) => setControlHistoryQuery({ query: option || undefined })}
        inputSize="big"
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['control.history.filters.owner'] })}
        searchBy="owners"
        tableSearchQuery={ownerSearchQuery}
        setTableSearchQuery={setOwnerSearchQuery}
        selectedValue={controlHistoryQuery.posiadacz}
        setSelectedValue={(option) =>
          setControlHistoryQuery({
            posiadacz: option ? { ...option, value: option.id as string } : undefined,
          })
        }
        inputSize="big"
      />
    </>
  );
};
