import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionButton } from 'ui/actionButton/ActionButton';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { ArchiveCardProps } from './ArchiveCard.types';

export const ArchiveCard = ({
  selectedCard,
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
}: ArchiveCardProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <ActionButton type="archive" onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['card.archive.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        submitButtonDisabled={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['card.archive.actionButton'] })}
      >
        <>
          <ActionModal.Subtitle>
            {formatMessage({ id: AppMessages['card.archive.subtitle'] })} {selectedCard.numerKarty}
          </ActionModal.Subtitle>

          <ActionModal.Text>{formatMessage({ id: AppMessages['card.archive.text'] })}</ActionModal.Text>

          <ActionModal.Text>{formatMessage({ id: AppMessages['common.reversibleAction'] })}</ActionModal.Text>
        </>
      </ActionModal>
    </>
  );
};
