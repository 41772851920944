import { AutocompleteOption, Button } from 'shared-ui';
import { Controller, useFormContext } from 'react-hook-form';

import { ReactComponent as AddIcon } from 'assets/icons/add-active-medium.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';

import { AddMultiOwnersFormBody, AddMultiOwnersProps } from './AddMultiOwners.types';
import * as Styled from './AddMultiOwners.styles';

export const AddMultiOwners = ({
  checkedAnnouncements,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
  setSelectedOwnersGroup,
  owners,
}: AddMultiOwnersProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useFormContext<AddMultiOwnersFormBody>();

  const ownersOptions = owners.map((owner) => ({
    id: owner.id,
    nazwa: `${owner.imie} ${owner.nazwisko}`,
    kod: owner.nrEwidencyjny,
  }));

  return (
    <>
      <Button variant="outlined" color="primary" size="small" startIcon={<AddIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['announcements.multiAction.addOwners.button'] })}
      </Button>

      <ActionModal
        header={formatMessage({ id: AppMessages['announcements.multiAction.addOwners.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={handleSubmit(onSubmit)}
        isLoading={isLoading}
        submitButtonDisabled={!isValid}
        submitButtonText={formatMessage({ id: AppMessages['common.add'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['announcements.multiAction.addOwners.subtitle'] })}{' '}
          {checkedAnnouncements.length}
        </ActionModal.Subtitle>

        <Styled.Wrapper>
          <Controller
            control={control}
            name="ids"
            render={({ field }) => (
              <OrganizationsTreeSelect
                {...field}
                multiple
                placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
                label={formatMessage({ id: AppMessages['announcement.add.chooseOwners'] })}
                extraGroupProps={{
                  options: ownersOptions,
                  label: formatMessage({ id: AppMessages['announcement.add.owner'] }),
                  onGroupSelect: setSelectedOwnersGroup,
                  renderOption: (option) => (
                    <AutocompleteOption
                      key={option.id}
                      idPrefix={formatMessage({ id: AppMessages['tableSearch.owner.number'] })}
                      id={option.kod || ''}
                      label={option.nazwa}
                    />
                  ),
                }}
              />
            )}
          />
        </Styled.Wrapper>
      </ActionModal>
    </>
  );
};
