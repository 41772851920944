import { useFormContext } from 'react-hook-form';
import { Button } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { PermissionEnum } from 'api/permissions/permissions.enum';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { Form } from 'ui/form/Form';

import { GroupGlcPermissionActions } from './groupGlcPermissionActions/GroupGlcPermissionActions';
import { GroupGlcPermissionDetailsProps, GroupGlcPermissionDetailsEditForm } from './GroupGlcPermissionDetails.types';
import * as Styled from './GroupGlcPermissionDetails.styles';

export const GroupGlcPermissionDetails = ({
  selectedPermission,
  isLoading,
  onSubmit,
}: GroupGlcPermissionDetailsProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isValid, errors },
    trigger,
    setValue,
  } = useFormContext<GroupGlcPermissionDetailsEditForm>();

  const { permissionOptions } = useAppSelectOptions();

  return (
    <>
      <GroupGlcPermissionActions />

      {selectedPermission.ktoZedytowal ? (
        <Styled.DetailsItemWrapper>
          <Styled.DetailName>
            {formatMessage({ id: AppMessages['group.reader.permission.details.editedBy'] })}
          </Styled.DetailName>
          <Styled.DetailText>{selectedPermission.ktoZedytowal}</Styled.DetailText>
        </Styled.DetailsItemWrapper>
      ) : (
        <Styled.DetailsItemWrapper>
          <Styled.DetailName>
            {formatMessage({ id: AppMessages['group.reader.permission.details.addedBy'] })}
          </Styled.DetailName>
          <Styled.DetailText>{selectedPermission.ktoWprowadzil}</Styled.DetailText>
        </Styled.DetailsItemWrapper>
      )}

      <Styled.Form onSubmit={handleSubmit(onSubmit)}>
        <Styled.ContentWrapper>
          <Form.ControlledSelect
            name="rejestrujOdbicie"
            control={control}
            isError={!!errors.rejestrujRcp?.message}
            onChange={(e) => {
              setValue('rejestrujOdbicie', e.target.value as PermissionEnum, { shouldDirty: true });
              trigger('rejestrujRcp');
            }}
            label={formatMessage({ id: AppMessages['group.reader.permissions.field.registerRecord.label'] })}
            options={permissionOptions}
          />

          <Form.ControlledSelect
            name="otwierajZamek"
            control={control}
            isError={!!errors.rejestrujRcp?.message}
            onChange={(e) => {
              setValue('otwierajZamek', e.target.value as PermissionEnum, { shouldDirty: true });
              trigger('rejestrujRcp');
            }}
            label={formatMessage({ id: AppMessages['group.reader.permissions.field.openLock.label'] })}
            options={permissionOptions}
          />

          <Form.ControlledSelect
            name="rejestrujRcp"
            control={control}
            onChange={(e) => setValue('rejestrujRcp', e.target.value as PermissionEnum, { shouldDirty: true })}
            withHelperText
            errorMessage={errors.rejestrujRcp?.message}
            isError={!!errors.rejestrujRcp?.message}
            label={formatMessage({ id: AppMessages['group.reader.permissions.field.registerRcp.label'] })}
            options={permissionOptions}
          />
        </Styled.ContentWrapper>

        {isDirty && (
          <Styled.ButtonsWrapper>
            <Button
              size="small"
              onClick={() => {
                reset(selectedPermission);
              }}
              color="primary"
            >
              {formatMessage({ id: AppMessages['common.cancel'] })}
            </Button>
            <Button
              type="submit"
              disabled={!isValid}
              isLoading={isLoading}
              size="small"
              variant="contained"
              color="primary"
            >
              {formatMessage({ id: AppMessages['common.save'] })}
            </Button>
          </Styled.ButtonsWrapper>
        )}
      </Styled.Form>
    </>
  );
};
