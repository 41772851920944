import * as yup from 'yup';
import { isValid } from 'date-fns';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useExportEventsValidation = (maxDate: Date) => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape(
    {
      nazwaRcp: yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] })),
      formatId: yup.string().required(formatMessage({ id: AppMessages['common.validation.required'] })),
      dataOd: yup
        .date()
        .max(maxDate, formatMessage({ id: AppMessages['common.validation.date.future'] }))
        .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }))
        .when('dataDo', (validTo) => {
          if (validTo && isValid(validTo)) {
            return yup
              .date()
              .max(maxDate, formatMessage({ id: AppMessages['common.validation.date.future'] }))
              .max(validTo, formatMessage({ id: AppMessages['common.validation.date.invalidRange'] }))
              .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }));
          }
          return yup
            .date()
            .max(maxDate, formatMessage({ id: AppMessages['common.validation.date.future'] }))
            .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }));
        })

        .required(formatMessage({ id: AppMessages['common.validation.date.format'] })),
      dataDo: yup
        .date()
        .required()
        .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }))
        .min(yup.ref('dataOd'), formatMessage({ id: AppMessages['common.validation.date.invalidRange'] })),
    },
    [['dataOd', 'dataDo']],
  );

  return { schema };
};
