import { Typography } from '@mui/material';
import styled from 'styled-components';
import { Button } from 'shared-ui';

export const UpdateExpiryDateButton = styled(Button)`
  min-width: 210px;
`;

export const ModalSubtitle = styled(Typography)`
  margin-bottom: 24px;
`;

export const DatepickersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 60px;
`;

export const ExpiryDatepickerWrapper = styled.div`
  width: 100%;
`;
