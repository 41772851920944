import { Option } from 'shared-ui';

import { fetchOwners } from 'api/owners/owners';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useQuery } from 'hooks/useQuery/useQuery';
import { OwnerRegistrationNumberSortEnum } from 'context/query/queryContext/QueryContext.enum';
import * as Styled from '../TableSearch.styles';

export const OWNERS_QUERY_PREFIX = 'pkdNumber';

export const useSearchByOwners = (
  optionsQuery: string,
  isEnabled: boolean,
  withGroupBy?: boolean,
  withQueryPrefix?: boolean,
  setTableSearchQuery?: (search: string) => void,
  withNameAsAValue?: boolean,
) => {
  const { formatMessage } = useLocale();

  const { data: ownersResponse, isInitialLoading } = useQuery([QueryKeyEnum.OWNERS_SEARCH, optionsQuery], fetchOwners, {
    args: { query: optionsQuery, offset: 0, limit: 100, sort: [OwnerRegistrationNumberSortEnum.ASC] },
    enabled: isEnabled,
  });

  const owners = ownersResponse?.data || [];

  const createOptions = () => {
    return (
      ownersResponse?.data.map((owner) => ({
        label: `${owner.imie || ''} ${owner.nazwisko || ''}`,
        value: withNameAsAValue ? `${owner.imie || ''} ${owner.nazwisko || ''}` : owner.nrEwidencyjny,
        number: owner.nrEwidencyjny,
        groupBy: withGroupBy ? 'owner' : '',
        id: owner.id,
      })) || []
    );
  };

  const createOptionLabel = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <li {...optionProps} key={`${option.value}-${optionProps.id}`}>
        <Styled.OptionNumber>
          {formatMessage({ id: AppMessages['tableSearch.owner.number'] })}{' '}
          {owners.find((owner) => owner.nrEwidencyjny === option.number)?.nrEwidencyjny || 0}
        </Styled.OptionNumber>

        <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
      </li>
    );
  };

  return {
    isLoading: isInitialLoading,
    createOptions,
    createOptionLabel,
    isOptionEqualToValue: (option: Option, value: Option) => option.number === value.number,
    ...(setTableSearchQuery && {
      setTableSearchQuery: (search: string) => {
        if (withQueryPrefix) {
          return setTableSearchQuery(`${OWNERS_QUERY_PREFIX}${search || ''}`);
        }

        setTableSearchQuery(search);
      },
    }),
  };
};
