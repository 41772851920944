import { Typography } from '@mui/material';
import styled from 'styled-components';

export const OptionNumber = styled(Typography)`
  font-size: 12px;
`;

export const OptionLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
`;
