import { CircularProgress, CircularProgressProps } from '@mui/material';

import * as Styled from './Loader.styles';

export const Loader = (props: CircularProgressProps) => {
  return (
    <Styled.LoaderWrapper>
      <CircularProgress {...props} />
    </Styled.LoaderWrapper>
  );
};
