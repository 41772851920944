import { useContext } from 'react';

import { SelectedAnnouncementContext } from 'context/selectedAnnouncement/selectedAnnouncementContext/SelectedAnnouncementContext';

export const useSelectedAnnouncement = () => {
  const context = useContext(SelectedAnnouncementContext);

  if (context === undefined) {
    throw new Error(
      'SelectedAnnouncementContext is unavailable, make sure you are using SelectedAnnouncementContextController',
    );
  }

  return context;
};
