import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { deleteServer } from 'api/servers/servers';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedServer } from 'hooks/useSelectedServer/useSelectedServer';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteServer } from './DeleteServer';
import { DeleteServerContainerProps } from './DeleteServer.types';

export const DeleteServerContainer = ({ selectedServer }: DeleteServerContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { setSelectedServer } = useSelectedServer();

  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteServer, isLoading } = useMutation(deleteServer, {
    onSuccess: () => {
      setSelectedServer(undefined);
      queryClient.invalidateQueries([QueryKeyEnum.SERVERS]);
      toast.dark(formatMessage({ id: AppMessages['devices.details.deleteServer.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteServer({ id: selectedServer.id });
  };

  return (
    <DeleteServer
      selectedServer={selectedServer}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
