import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useQueryClient } from 'shared-ui';

import { useModalState } from 'hooks/useModalState/useModalState';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyEnum } from 'core/global.enum';
import { fetchServers } from 'api/servers/servers';
import { fetchTerminals } from 'api/terminals/terminals';
import { fetchOwners } from 'api/owners/owners';
import {
  GroupTerminalsPermissionsIdSortEnum,
  OwnerRegistrationNumberSortEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { addWanted } from 'api/wanted/wanted';
import { AppMessages } from 'i18n/messages';

import { AddWanted } from './AddWanted';
import { AddWantedForm } from './AddWanted.types';
import { useAddWantedValidation } from './AddWanted.validation';

export const AddWantedContainer = () => {
  const { isModalOpen, onModalClose, onModalOpen } = useModalState();
  const { schema } = useAddWantedValidation();
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();

  const formMethods = useForm<AddWantedForm>({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { serwerId } = formMethods.watch();

  const { data: serversResponse } = useQuery([QueryKeyEnum.SERVERS], fetchServers);
  const servers = serversResponse?.data || [];

  const { data: terminalsResponse, isInitialLoading: isLoadingTerminals } = useQuery(
    [QueryKeyEnum.TERMINALS, serwerId],
    () => fetchTerminals(serwerId),
    {
      args: {
        sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
        zaakceptowane: true,
        wyswietlacz: true,
      },
      enabled: !!serwerId,
    },
  );
  const terminals = terminalsResponse?.data || [];

  const { data: ownersResponse, isLoading: isLoadingOwners } = useQuery([QueryKeyEnum.OWNERS_SEARCH], fetchOwners, {
    args: {
      offset: 0,
      limit: 100,
      sort: [OwnerRegistrationNumberSortEnum.ASC],
      karta: true,
    },
  });
  const owners = ownersResponse?.data || [];

  const { mutate: onAddWanted, isLoading: isLoadingAddWanted } = useMutation(addWanted, {
    onSuccess: (_, body) => {
      queryClient.invalidateQueries([QueryKeyEnum.WANTED]);
      toast.dark(formatMessage({ id: AppMessages['wanted.addOwner.successMessage'] }, { count: body.idPkds?.length }));
      onModalClose();
    },
  });

  const onSubmit = (body: AddWantedForm) => {
    onAddWanted({
      idPkds: body.idPkds?.map(({ value }) => value as string) || [],
      tkds: body.tkds?.map(({ value }) => value as string) || [],
      informacja: body.informacja || '',
    });
  };

  useEffect(() => {
    servers.length && formMethods.reset({ serwerId: servers[0].id });
  }, [servers]);

  useEffect(() => {
    formMethods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...formMethods}>
      <AddWanted
        servers={servers}
        terminals={terminals}
        owners={owners}
        isLoadingTerminals={isLoadingTerminals}
        isLoadingOwners={isLoadingOwners}
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        onSubmit={onSubmit}
        isLoadingAddWanted={isLoadingAddWanted}
      />
    </FormProvider>
  );
};
