import { useState, useEffect, useMemo } from 'react';
import { useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import { assignCardToOwner, searchCard } from 'api/cards/cards';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useQuery } from 'hooks/useQuery/useQuery';
import { CardNumberSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { CardBlockTypeEnum, CardStatusEnum } from 'api/cards/cards.enum';
import { AppRouteWithParams } from 'routing/AppRoute.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';

import { CardAssign } from './CardAssign';
import { CardAssignContainerProps, CardAssignForm } from './CardAssign.types';
import { useCardAssignValidation } from './CardAssign.validation';

export const CardAssignContainer = ({ ownerId, isEditDisabled }: CardAssignContainerProps) => {
  const [isCardBlockedManually, setIsCardBlockedManually] = useState(false);
  const [isDateRequired, setIsDateRequired] = useState(true);
  const [query, setQuery] = useState<string | number>('');
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();
  const defaultValidFrom = useMemo(() => new Date(), [isCardBlockedManually]);
  const { schema } = useCardAssignValidation(defaultValidFrom, isCardBlockedManually, isDateRequired);
  const formMethods = useForm<CardAssignForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      waznoscOd: isCardBlockedManually ? null : defaultValidFrom,
    },
  });
  const { setSelectedCard } = useSelectedCard();
  const { selectedOwner, setSelectedOwner } = useSelectedOwner();
  const formValues = formMethods.watch();

  const isOwnerHistoryView = useIsExactPath(AppRouteWithParams.ownerHistory.path);

  const { data: cardsResponse, isLoading: isLoadingCards } = useQuery([QueryKeyEnum.CARD_SEARCH, query], searchCard, {
    args: { query, offset: 0, limit: 100, sort: [CardNumberSortEnum.ASC] },
  });

  const { mutate: onAssignCard, isLoading: isLoadingAssignCard } = useMutation(assignCardToOwner, {
    onSuccess: (updatedCard) => {
      if (selectedOwner && updatedCard.posiadacz?.id === selectedOwner.id) {
        queryClient.invalidateQueries([QueryKeyEnum.OWNER_DETAILS, updatedCard.posiadacz?.id]);
        setSelectedOwner(selectedOwner);
      }
      setSelectedCard(updatedCard);
      queryClient.invalidateQueries([QueryKeyEnum.CARDS]);
      queryClient.invalidateQueries([isOwnerHistoryView ? QueryKeyEnum.OWNER_HISTORY : QueryKeyEnum.OWNERS]);
      setIsAssignModalOpen(false);
      toast.dark(formatMessage({ id: AppMessages['card.assign.success'] }));
    },
  });

  const selectedCard = useMemo(
    () => cardsResponse?.data?.find(({ id }) => id === formValues.cardId),
    [formValues.cardId, cardsResponse?.data],
  );

  const isSelectedCardAvailable =
    !!selectedCard && selectedCard.status === CardStatusEnum.DOSTEPNA && !selectedCard.archiwalna;
  const isCardBlocked = selectedCard?.blokada !== CardBlockTypeEnum.ODBLOKOWANA;

  useEffect(() => {
    formMethods.setValue('waznoscOd', !isSelectedCardAvailable || isCardBlocked ? null : defaultValidFrom);

    if (!isSelectedCardAvailable || isCardBlocked) {
      return setIsDateRequired(false);
    }

    setIsDateRequired(true);
  }, [isSelectedCardAvailable, isCardBlocked]);

  useEffect(() => {
    setIsCardBlockedManually(selectedCard?.blokada === CardBlockTypeEnum.RECZNA);
  }, [selectedCard]);

  useEffect(() => {
    formMethods.setValue('waznoscOd', isCardBlockedManually ? null : defaultValidFrom);

    formMethods.setValue('waznoscDo', null);
  }, [isCardBlockedManually]);

  useEffect(() => {
    selectedCard && formMethods.trigger();
  }, [formValues.cardId]);

  useEffect(() => {
    formMethods.reset();
    setQuery('');
  }, [isAssignModalOpen]);

  const handleFormSubmit = ({ cardId, pin }: CardAssignForm) => {
    onAssignCard({
      id: cardId,
      body: {
        pkdId: ownerId,
        waznoscOd: isCardBlockedManually ? null : formValues.waznoscOd || selectedCard?.waznoscOd || defaultValidFrom,
        waznoscDo: isCardBlockedManually ? null : formValues.waznoscDo || selectedCard?.waznoscDo || null,
        pin,
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <CardAssign
        handleFormSubmit={handleFormSubmit}
        setQuery={setQuery}
        cards={cardsResponse?.data || []}
        isLoadingCards={isLoadingCards}
        isLoadingAssignCard={isLoadingAssignCard}
        isAssignModalOpen={isAssignModalOpen}
        onCloseAssignModal={() => setIsAssignModalOpen(false)}
        onOpenAssignModal={() => setIsAssignModalOpen(true)}
        selectedCard={selectedCard ? { ...selectedCard } : null}
        isCardBlockedManually={isCardBlockedManually}
        isEditDisabled={isEditDisabled}
        isSelectedCardAvailable={isSelectedCardAvailable}
        isCardBlocked={isCardBlocked}
      />
    </FormProvider>
  );
};
