import { useState, useEffect } from 'react';
import { ShowUp } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Owner } from 'api/owners/owners.types';
import { Table } from 'ui/table/Table';

import { WantedTableHeader } from './wantedTableHeader/WantedTableHeader';
import { WantedTableRow } from './wantedTableRow/WantedTableRow';
import { WantedTableProps } from './WantedTable.types';
import { WantedMultiActions } from './wantedMultiActions/WantedMultiActions';

export const WantedTable = ({
  ownersResponse,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  wantedQuery,
}: WantedTableProps) => {
  const [wantedOwners, setWantedOwners] = useState<Owner[]>([]);
  const { formatMessage } = useLocale();

  useEffect(() => {
    setWantedOwners([]);
  }, [wantedQuery]);

  return (
    <>
      <Table
        isLoading={isLoading}
        renderMultiActions={() => (
          <ShowUp show={wantedOwners.length > 1}>
            <WantedMultiActions checkedOwners={wantedOwners} setCheckedOwners={setWantedOwners} />
          </ShowUp>
        )}
        pagination={{
          page,
          setPage,
          count: ownersResponse?.count || 0,
          rowsPerPage,
          setRowsPerPage,
        }}
        gridTemplateColumns="minmax(140px, 1fr) minmax(190px, 1fr) 1fr 1fr minmax(100px, 1fr) 140px"
        data={ownersResponse?.data || []}
        nothingToShowText={formatMessage({ id: AppMessages['wanted.nothingToShow'] })}
        checkboxSelection={{
          checked: wantedOwners,
          toggleChecked: setWantedOwners,
        }}
        renderHeader={() => <WantedTableHeader />}
        renderRow={(owner) => <WantedTableRow owner={owner} />}
      />
    </>
  );
};
