import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { AppRouteWithParams } from 'routing/AppRoute.enum';

export const useReturnOwnerPermissionValue = () => {
  const isGroupPermissionsView = useIsExactPath(AppRouteWithParams.ownerPermissionsGroup.path);
  const isIndividualPermissionsView = useIsExactPath(AppRouteWithParams.ownerPermissionsIndividual.path);

  const returnValueBasedOnOwnerPermissionLocation = <T>({
    group,
    individual,
    effective,
  }: Partial<{
    group: () => T;
    individual: () => T;
    effective: () => T;
  }>) => {
    if (isGroupPermissionsView) {
      return group ? group() : null;
    } else if (isIndividualPermissionsView) {
      return individual ? individual() : null;
    } else {
      return effective ? effective() : null;
    }
  };

  return { returnValueBasedOnOwnerPermissionLocation };
};
