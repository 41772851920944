import { Typography } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 16px;
`;

export const EditMultiOwnersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 25px;
  grid-template-areas:
    'cell position'
    'rcp company'
    'description description';
`;

export const OwnersDescriptionWrapper = styled.div`
  grid-area: description;
`;

export const OptionLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  padding: 6px;
`;
