import { useState, useEffect } from 'react';
import { PageLayout, DetailsDrawer } from 'shared-ui';

import { ReactComponent as CollapseIcon } from 'assets/icons/collapse.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/expand.svg';
import { findAllByKey } from 'helpers/findAllByKey';
import { Loader } from 'ui/loader/Loader';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedOrganization } from 'hooks/useSelectedOrganization/useSelectedOrganization';
import { DefinitionsBar } from '../definitionsBar/DefinitionsBar';
import { getObjectInNestedObjectByKey } from 'helpers/getObjectInNestedObjectByKey';

import * as Styled from './Organization.styles';
import { OrganizationHeader } from './organizationHeader/OrganizationHeader';
import { OrganizationItem } from './organizationItem/OrganizationItem';
import { OrganizationProps } from './Organization.types';
import { OrganizationDetailsContainer } from './organizationDetails/OrganizationDetailsContainer';
import { EXPAND_ORGANIZATION_ITEM_TAGS } from './Organization.variables';

export const Organization = ({ isLoadingOrganization, organization }: OrganizationProps) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  const [searchOrganizationId, setSearchOrganizationId] = useState<string>();
  const { formatMessage } = useLocale();
  const { selectedOrganization, setSelectedOrganization } = useSelectedOrganization();

  const allNodeIds = organization ? findAllByKey(organization, 'id') : [];
  const isAllNodesExpanded = expanded.length === allNodeIds.length;

  useEffect(() => {
    if (organization && selectedOrganization) {
      const updatedSelectedOrganization = getObjectInNestedObjectByKey(organization, 'id', selectedOrganization.id);

      if (updatedSelectedOrganization) {
        setSelectedOrganization(updatedSelectedOrganization.object);
      }
    }
  }, [organization]);

  useEffect(() => {
    if (!organization || !searchOrganizationId) return;

    const updatedSelectedOrganization = getObjectInNestedObjectByKey(organization, 'id', searchOrganizationId);

    if (!updatedSelectedOrganization) return;

    setSelectedOrganization(updatedSelectedOrganization.object);
    setExpanded(updatedSelectedOrganization.parentIds);
  }, [searchOrganizationId]);

  const handleToggle = (_: React.SyntheticEvent, nodeIds: string[]) => {
    if (EXPAND_ORGANIZATION_ITEM_TAGS.includes((_.target as HTMLElement)?.tagName)) {
      setExpanded(nodeIds);
    }
  };

  const handleToggleAll = () => {
    setExpanded(isAllNodesExpanded ? [] : allNodeIds);
  };

  return (
    <PageLayout
      header={formatMessage({ id: AppMessages['definitions.title'] })}
      renderContent={() => (
        <>
          <DefinitionsBar
            searchOrganizationId={searchOrganizationId}
            setSearchOrganizationId={setSearchOrganizationId}
          />
          {isLoadingOrganization ? (
            <Loader />
          ) : (
            <>
              <OrganizationHeader isAllNodesExpanded={isAllNodesExpanded} handleToggleAll={handleToggleAll} />
              <Styled.TreeView
                selected={selectedOrganization?.id || ''}
                expanded={expanded}
                aria-label="organizations-label"
                defaultCollapseIcon={<ExpandIcon />}
                defaultExpandIcon={<CollapseIcon />}
                onNodeToggle={handleToggle}
              >
                {organization && <OrganizationItem mainOrganization={organization} organization={organization} />}
              </Styled.TreeView>
            </>
          )}
        </>
      )}
      renderDetails={() => (
        <DetailsDrawer
          isOpen={!!selectedOrganization}
          header={formatMessage({ id: AppMessages['event.details.title'] })}
        >
          {organization && selectedOrganization && (
            <OrganizationDetailsContainer mainOrganization={organization} selectedOrganization={selectedOrganization} />
          )}
        </DetailsDrawer>
      )}
    />
  );
};
