import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const SubtitleText = styled.span`
  font-weight: 500;
  margin-left: 5px;
`;

export const ErrorBox = styled.div(
  ({ theme: { palette, shape } }) => css`
    font-size: 12px;
    color: ${palette.warning.main};
    background-color: ${palette.error.light};
    font-weight: 700;
    padding: 15px 24px 15px 15px;
    border-radius: ${shape.borderRadius}px;
    display: flex;
    justify-content: space-between;
  `,
);

export const DownloadLink = styled.a(
  ({ theme: { palette } }) => css`
    color: ${palette.error.main};
  `,
);

export const ErrorText = styled(Typography)(
  ({ theme: { palette } }) => css`
    color: ${palette.error.main};
    font-weight: inherit;
    font-size: inherit;
  `,
);
