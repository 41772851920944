import { MutationFn, UseQueryFn } from 'shared-ui';

import { Owner } from 'api/owners/owners.types';
import { PaginatedResponse } from 'core/global.types';

import {
  AddGroupBody,
  AddMultiCellsBody,
  AddMultiOwnersBody,
  AddMultiPermissionsBody,
  Group,
  UpdateGroupBody,
  CopyGroupBody,
  GroupHistory,
  GroupPermissionHistory,
} from './groups.types';

export const fetchGroupsForSpecificOwner: UseQueryFn<string, PaginatedResponse<Group>> = (id) => {
  return {
    endpoint: `/pkd/${id}/grupa`,
  };
};

export const fetchGroups: UseQueryFn<unknown, PaginatedResponse<Group>> = () => {
  return {
    endpoint: `/grupa`,
  };
};

export const addGroup: MutationFn<{ body: AddGroupBody }, PaginatedResponse<string>> = ({ body }) => {
  return {
    endpoint: `/grupa`,
    method: 'POST',
    body,
  };
};

export const updateGroup: MutationFn<{ id: string; body: UpdateGroupBody }, Pick<Group, 'id'>> = ({ id, body }) => {
  return {
    endpoint: `/grupa/${id}`,
    method: 'PUT',
    body,
  };
};

export const deleteGroup: MutationFn<string, null> = (id) => {
  return {
    endpoint: `/grupa/${id}`,
    method: 'DELETE',
  };
};

export const copyGroup: MutationFn<{ body: CopyGroupBody }, null> = ({ body }) => {
  return {
    endpoint: `/grupa/kopiuj`,
    method: 'POST',
    body,
  };
};

export const fetchGroupAssignedOwners: UseQueryFn<string, PaginatedResponse<Owner>> = (id) => {
  return {
    endpoint: `/grupa/${id}/pkd`,
  };
};

export const deleteGroupAssignedOwners: MutationFn<{ id: string; body: string[] }, null> = ({ id, body }) => {
  return {
    endpoint: `/batch/grupa/${id}/pkd`,
    method: 'DELETE',
    body,
  };
};

export const addMultiCells: MutationFn<AddMultiCellsBody, { okUuids: string[] }> = (body) => {
  return {
    endpoint: `/batch/grupa/komorka`,
    method: 'POST',
    body,
  };
};

export const addMultiOwners: MutationFn<AddMultiOwnersBody, { okUuids: string[] }> = (body) => {
  return {
    endpoint: `/batch/grupa/pkd`,
    method: 'POST',
    body,
  };
};

export const addMultiPermissions: MutationFn<AddMultiPermissionsBody, { okUuids: string[] }> = (body) => {
  return {
    endpoint: `/batch/grupa/tkd`,
    method: 'POST',
    body,
  };
};

export const fetchGroupDetails: UseQueryFn<string, Group> = (id) => {
  return {
    endpoint: `/grupa/${id}`,
  };
};

export const fetchGroupHistory: UseQueryFn<string, PaginatedResponse<GroupHistory>> = (id) => {
  return {
    endpoint: `/grupa/${id}/historia`,
    method: 'GET',
  };
};

export const fetchGroupPermissionHistory: UseQueryFn<string, PaginatedResponse<GroupPermissionHistory>> = (id) => {
  return {
    endpoint: `/grupa/${id}/historia/uprawnienia`,
    method: 'GET',
  };
};

export const archiveGroup: MutationFn<string, Group> = (id) => {
  return {
    endpoint: `/grupa/${id}/archiwizuj`,
    method: 'POST',
  };
};

export const unarchiveGroup: MutationFn<string, Group> = (id) => {
  return {
    endpoint: `/grupa/${id}/przywroc`,
    method: 'POST',
  };
};

export const archiveMultiGroups: MutationFn<{ body: string[] }, void> = ({ body }) => {
  return {
    endpoint: '/batch/grupa/archiwizuj',
    method: 'POST',
    body,
  };
};

export const unarchiveMultiGroups: MutationFn<{ body: string[] }, void> = ({ body }) => {
  return {
    endpoint: '/batch/grupa/przywroc',
    method: 'POST',
    body,
  };
};
