import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useAddAnnouncementAssignedOwnersValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    ids: yup
      .array()
      .min(1, formatMessage({ id: AppMessages['common.validation.required'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
  });
  return { schema };
};
