import { useEffect } from 'react';
import { useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

import { addMultiCells } from 'api/groups/groups';
import { QueryKeyEnum } from 'core/global.enum';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useModalState } from 'hooks/useModalState/useModalState';

import { useAddMultiCellsValidation } from './AddMultiCells.validation';
import { AddMultiCellsFormBody, AddMultiCellsContainerProps } from './AddMultiCells.types';
import { AddMultiCells } from './AddMultiCells';

export const AddMultiCellsContainer = ({ checkedGroups }: AddMultiCellsContainerProps) => {
  const { formatMessage } = useLocale();
  const { schema } = useAddMultiCellsValidation();
  const queryClient = useQueryClient();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const methods = useForm<AddMultiCellsFormBody>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const { mutate: onAddMultiCells, isLoading } = useMutation(addMultiCells, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.GROUPS]);
      toast.dark(formatMessage({ id: AppMessages['groups.multiAction.addCell.successMessage'] }));
      onModalClose();
    },
  });

  const onSubmit = (body: AddMultiCellsFormBody) => {
    onAddMultiCells({ idGrup: checkedGroups.map(({ id }) => id), idKomorek: body.komorki?.map(({ id }) => id) });
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...methods}>
      <AddMultiCells
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
};
