import { Controller, useFormContext } from 'react-hook-form';
import { Modal, Button, Select } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { PermissionEnum } from 'api/permissions/permissions.enum';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';
import { Form } from 'ui/form/Form';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';

import { AddGroupGlcPermissionFormBody, AddGroupGlcPermissionProps } from './AddGroupGlcPermission.types';
import * as Styled from './AddGroupGlcPermission.styles';

export const AddGroupGlcPermission = ({
  onModalOpen,
  onModalClose,
  isLoading,
  onSubmit,
  isModalOpen,
  servers,
  setSelectedServerId,
  selectedServerId,
  isLoadingGlcGroups,
  glcGroups,
  isLoadingServers,
}: AddGroupGlcPermissionProps) => {
  const { formatMessage } = useLocale();
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption();

  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    watch,
    setValue,
  } = useFormContext<AddGroupGlcPermissionFormBody>();

  const isTkdNotSelected = !watch('idGlcs')?.length;

  const filteredGlcGroupsBySelectedServer = glcGroups.filter((glc) => glc.rcp.id === selectedServerId);
  const getGlcOptions = () => {
    return filteredGlcGroupsBySelectedServer.map((glc) => ({
      label: glc.nazwa,
      value: glc.id,
      number: glc.idGlc,
    }));
  };

  const { serverOptions, permissionOptions } = useAppSelectOptions({ servers });

  return (
    <>
      <Button onClick={onModalOpen} variant="contained">
        {formatMessage({ id: AppMessages['group.reader.permissions.addPermission.button'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['group.reader.permissions.addPermission.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={isTkdNotSelected || isLoading || !!Object.keys(errors).length}
      >
        <Styled.FormWrapper>
          <Select
            required
            value={selectedServerId}
            onChange={(e) => setSelectedServerId(e.target.value as string)}
            label={formatMessage({ id: AppMessages['group.reader.permissions.field.server.label'] })}
            options={serverOptions}
          />

          <Controller
            name="idGlcs"
            control={control}
            render={({ field }) => (
              <MultiAutocomplete
                {...field}
                required
                withLimitedTags
                stopRotatePopupIndicator
                inputSize="big"
                limitTags={1}
                onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                options={getGlcOptions()}
                loading={isLoadingGlcGroups || isLoadingServers}
                loadingText={formatMessage({ id: AppMessages['common.loading'] })}
                label={formatMessage({ id: AppMessages['group.glc.permissions.field.reader.label'] })}
                noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
                placeholder={formatMessage({ id: AppMessages['group.reader.permissions.field.reader.placeholder'] })}
                renderOption={renderMultiAutocompleteOption}
              />
            )}
          />

          <Form.ControlledSelect
            name="rejestrujOdbicie"
            control={control}
            disabled={isTkdNotSelected}
            isError={!!errors.rejestrujRcp?.message}
            onChange={(e) => {
              setValue('rejestrujOdbicie', e.target.value as PermissionEnum, { shouldDirty: true });
              errors.rejestrujRcp?.message && trigger('rejestrujRcp');
            }}
            label={formatMessage({ id: AppMessages['group.reader.permissions.field.registerRecord.label'] })}
            data-testId="odbicie-select"
            options={permissionOptions}
          />

          <Form.ControlledSelect
            name="otwierajZamek"
            control={control}
            disabled={isTkdNotSelected}
            isError={!!errors.rejestrujRcp?.message}
            onChange={(e) => {
              setValue('otwierajZamek', e.target.value as PermissionEnum, { shouldDirty: true });
              errors.rejestrujRcp?.message && trigger('rejestrujRcp');
            }}
            label={formatMessage({ id: AppMessages['group.reader.permissions.field.openLock.label'] })}
            data-testId="zamek-select"
            options={permissionOptions}
          />

          <Form.ControlledSelect
            name="rejestrujRcp"
            control={control}
            disabled={isTkdNotSelected}
            onChange={(e) => setValue('rejestrujRcp', e.target.value as PermissionEnum, { shouldDirty: true })}
            withHelperText
            errorMessage={errors.rejestrujRcp?.message}
            isError={!!errors.rejestrujRcp?.message}
            label={formatMessage({ id: AppMessages['group.reader.permissions.field.registerRcp.label'] })}
            data-testId="rcp-select"
            options={permissionOptions}
          />
        </Styled.FormWrapper>
      </Modal>
    </>
  );
};
