import { Table } from 'ui/table/Table';

import { OwnerIndividualPermissionsTableRowProps } from './OwnerIndividualPermissionsTableRow.types';

export const OwnerIndividualPermissionsTableRow = ({ permission }: OwnerIndividualPermissionsTableRowProps) => {
  return (
    <>
      <Table.RowItem>{permission.tkd.numerLogicznyKontrolera}</Table.RowItem>

      <Table.RowItem> {permission.tkd.nazwa}</Table.RowItem>

      <Table.RowTagItem
        tag={permission.rejestrujOdbicie}
        testId={`${permission.tkd.nazwa}-odbicie-${permission.rejestrujOdbicie}`}
        isIndividualPermissionTag
      />

      <Table.RowTagItem
        tag={permission.otwierajZamek}
        testId={`${permission.tkd.nazwa}-zamek-${permission.otwierajZamek}`}
        isIndividualPermissionTag
      />

      <Table.RowTagItem
        tag={permission.rejestrujRcp}
        testId={`${permission.tkd.nazwa}-rcp-${permission.rejestrujRcp}`}
        isIndividualPermissionTag
      />
    </>
  );
};
