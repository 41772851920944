import { useEffect, useState } from 'react';
import { useQueryClient } from 'shared-ui';
import { useLocation } from 'react-router-dom';

import { AppRoute, AppRouteWithParams } from 'routing/AppRoute.enum';
import { Owner } from 'api/owners/owners.types';
import { SelectedOwnerContext } from '../selectedOwnerContext/SelectedOwnerContext';
import { OwnerDetailsTabEnum } from '../selectedOwnerContext/SelectedOwnerContext.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { fetchOwnerDetails } from 'api/owners/owners';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useOwnerArchivedView } from 'hooks/useIsOwnerArchivedView/useOwnersArchivedView';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';

import { SelectedOwnerContextControllerProps } from './SelectedOwnerContextController.types';

export const SelectedOwnerContextController = ({ children }: SelectedOwnerContextControllerProps) => {
  const { isOwnersArchivedView } = useOwnerArchivedView();
  const [selectedOwnerId, setSelectedOwnerId] = useState<string>();
  const [lastActiveSelectedOwnerId, setLastActiveSelectedOwnerId] = useState<string>();
  const [lastArchivedSelectedOwnerId, setLastArchivedSelectedOwnerId] = useState<string>();
  const [selectedOwnerDetailsTab, setSelectedOwnerDetailsTab] = useState(OwnerDetailsTabEnum.BASIC);
  const isOwnerHistoryView = useIsExactPath(AppRouteWithParams.ownerHistory.path);
  const isOwnerEffectivePermissionsHistoryView = useIsExactPath(
    AppRouteWithParams.ownerPermissionsEffectiveHistory.path,
  );
  const location = useLocation();
  const ownerIdFromHistory = isOwnerHistoryView ? location.pathname.split(`${AppRoute.ownerHistory}/`)[1] : '';
  const ownerIdFromEffectivePermissionsHistory =
    isOwnerEffectivePermissionsHistoryView && location.pathname.split(`${AppRoute.ownerHistory}/`)[1]
      ? location.pathname.split(`${AppRoute.ownerHistory}/`)[1].split('/')[0]
      : '';

  const ownerIdToDisplayInDetails =
    ownerIdFromHistory ||
    ownerIdFromEffectivePermissionsHistory ||
    (isOwnersArchivedView ? lastArchivedSelectedOwnerId : lastActiveSelectedOwnerId);

  const queryClient = useQueryClient();

  const { data: selectedOwner, refetch } = useQuery(
    [QueryKeyEnum.OWNER_DETAILS, selectedOwnerId],
    () => fetchOwnerDetails(selectedOwnerId),
    {
      enabled: !!selectedOwnerId,
    },
  );

  useEffect(() => {
    setSelectedOwnerId(ownerIdToDisplayInDetails);
  }, [ownerIdToDisplayInDetails]);

  useEffect(() => {
    setSelectedOwnerDetailsTab(OwnerDetailsTabEnum.BASIC);
  }, [isOwnersArchivedView]);

  const setSelectedOwner = (owner: Owner | undefined, likeClickAction?: boolean, withTabReset?: boolean) => {
    if (!owner || (owner.id === selectedOwner?.id && selectedOwnerId && likeClickAction)) {
      selectedOwner && queryClient.setQueryData([QueryKeyEnum.OWNER_DETAILS, selectedOwner.id], null);

      isOwnersArchivedView ? setLastArchivedSelectedOwnerId(undefined) : setLastActiveSelectedOwnerId(undefined);

      return setSelectedOwnerId(undefined);
    }

    if (selectedOwner?.id !== owner.id || withTabReset) setSelectedOwnerDetailsTab(OwnerDetailsTabEnum.BASIC);

    setSelectedOwnerId(owner.id);

    if (isOwnerHistoryView) {
      owner.archiwalny ? setLastArchivedSelectedOwnerId(owner.id) : setLastActiveSelectedOwnerId(owner.id);
    } else {
      isOwnersArchivedView ? setLastArchivedSelectedOwnerId(owner.id) : setLastActiveSelectedOwnerId(owner.id);
    }

    selectedOwnerId && refetch();
  };

  const resetSelectedOwner = () => {
    setLastActiveSelectedOwnerId(undefined);
    setLastArchivedSelectedOwnerId(undefined);
    setSelectedOwnerId(undefined);
    setSelectedOwner(undefined);
  };

  return (
    <SelectedOwnerContext.Provider
      value={{
        selectedOwner,
        setSelectedOwner,
        selectedOwnerDetailsTab,
        setSelectedOwnerDetailsTab,
        selectedOwnerId,
        setSelectedOwnerId,
        lastActiveSelectedOwnerId,
        lastArchivedSelectedOwnerId,
        setLastActiveSelectedOwnerId,
        setLastArchivedSelectedOwnerId,
        resetSelectedOwner,
      }}
    >
      {children}
    </SelectedOwnerContext.Provider>
  );
};
