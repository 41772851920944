import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useErrorHandler, APIError, ClientError, useQueryClient, useLoaderToast } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { addOwnerIndividualPermissions } from 'api/permissions/permissions';
import { fetchServers } from 'api/servers/servers';
import { fetchTerminals } from 'api/terminals/terminals';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useSelectedOwnerIndividualPermission } from 'hooks/useSelectedOwnerIndividualPermission/useSelectedOwnerIndividualPermission';
import { GroupTerminalsPermissionsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { DEFAULT_PERMISSIONS } from 'core/global.variables';
import { useResetPermissionsFormValues } from 'hooks/useResetPermissionsFormValues/useResetPermissionsFormValues';
import { useModalState } from 'hooks/useModalState/useModalState';

import { useAddOwnerIndividualPermissionValidation } from './AddOwnerIndividualPermission.validation';
import {
  AddOwnerIndividualPermissionFormBody,
  AddOwnerIndividualPermissionContainerProps,
} from './AddOwnerIndividualPermission.types';
import { AddOwnerIndividualPermission } from './AddOwnerIndividualPermission';

export const AddOwnerIndividualPermissionContainer = ({ ownerId }: AddOwnerIndividualPermissionContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { load: loadAddIndividualPermission, update: updateAddIndividualPermission } =
    useLoaderToast('addIndividualPermissions');
  const [selectedServerId, setSelectedServerId] = useState('');
  const [terminalsQuery] = useState('');
  const { schema } = useAddOwnerIndividualPermissionValidation();
  const { selectedOwnerIndividualPermission, setSelectedOwnerIndividualPermission } =
    useSelectedOwnerIndividualPermission();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { handleError } = useErrorHandler();

  const methods = useForm<AddOwnerIndividualPermissionFormBody>({
    defaultValues: {
      ...DEFAULT_PERMISSIONS,
      idTkds: [],
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { data: serversResponse, isLoading: isLoadingServers } = useQuery(
    [QueryKeyEnum.OWNER_PERMISSIONS_INDIVIDUAL_SERVERS],
    () => fetchServers(),
    {
      onSuccess: (serversResponse) => {
        if (serversResponse.data.length) {
          setSelectedServerId(serversResponse.data[0].id);
        }
      },
    },
  );

  const { data: terminalsResponse, isInitialLoading: isLoadingTerminals } = useQuery(
    [QueryKeyEnum.TERMINALS, selectedServerId, terminalsQuery],
    () => fetchTerminals(selectedServerId),
    {
      args: {
        zaakceptowane: true,
        sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
      },
      enabled: !!selectedServerId,
    },
  );

  const { mutate: onAddOwnerIndividualPermission, isLoading } = useMutation(addOwnerIndividualPermissions, {
    onMutate: ({ body }) => {
      const message =
        body.idTkds.length > 1
          ? 'permissions.individual.addPermissionMulti.loader'
          : 'permissions.individual.addPermission.loader';

      loadAddIndividualPermission(formatMessage({ id: AppMessages[message] }));
    },

    onSuccess: (permissions, { body }) => {
      const updatedPermission = permissions.find((permission) =>
        permission.id === selectedOwnerIndividualPermission?.id ? permission : undefined,
      );
      updatedPermission && setSelectedOwnerIndividualPermission(updatedPermission);

      queryClient.invalidateQueries([QueryKeyEnum.OWNER_PERMISSIONS_INDIVIDUAL_SERVERS]);
      queryClient.invalidateQueries([QueryKeyEnum.OWNER_PERMISSIONS_INDIVIDUAL]);

      const message =
        body.idTkds.length > 1
          ? 'permissions.individual.addPermissionMulti.success'
          : 'permissions.individual.addPermission.success';

      updateAddIndividualPermission(formatMessage({ id: AppMessages[message] }));

      onModalClose();
    },
    onError: (error: ClientError<APIError>) => {
      handleError({ error, updateToastCallback: updateAddIndividualPermission });
    },
  });

  const onSubmit = (body: AddOwnerIndividualPermissionFormBody) => {
    onAddOwnerIndividualPermission({
      id: ownerId,
      body: {
        ...body,
        idTkds: body.idTkds.map(({ value }) => `${value}`),
      },
    });
  };

  useResetPermissionsFormValues({ selectedValues: methods.watch('idTkds'), setFormValue: methods.setValue });

  useEffect(() => {
    methods.reset();
  }, [isModalOpen, selectedServerId]);

  return (
    <FormProvider {...methods}>
      <AddOwnerIndividualPermission
        onSubmit={onSubmit}
        isModalOpen={isModalOpen}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
        isLoading={isLoading}
        servers={serversResponse?.data || []}
        selectedServerId={selectedServerId}
        setSelectedServerId={setSelectedServerId}
        isLoadingTerminals={isLoadingTerminals}
        terminals={terminalsResponse?.data || []}
        isLoadingServers={isLoadingServers}
      />
    </FormProvider>
  );
};
