import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useOwnerArchivedView } from 'hooks/useIsOwnerArchivedView/useOwnersArchivedView';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';

export const useLocaleStoreOwnersQuery = () => {
  const { isOwnersArchivedView } = useOwnerArchivedView();

  const ownersQueryLocalStorageName = isOwnersArchivedView
    ? QueryKeyLocalStorageEnum.ARCHIVED_OWNERS
    : QueryKeyLocalStorageEnum.OWNERS;

  const {
    storedQueries: { [ownersQueryLocalStorageName]: ownersQuery },
    setQuery,
  } = useLocaleStoreQuery();

  const setOwnersQuery = (updatedOwnersQuery: Partial<typeof ownersQuery>) => {
    setQuery(ownersQueryLocalStorageName, {
      ...ownersQuery,
      ...updatedOwnersQuery,
    });
  };

  return {
    ownersQuery,
    setOwnersQuery,
    ownersQueryLocalStorageName: ownersQueryLocalStorageName as QueryKeyLocalStorageEnum.OWNERS,
  };
};
