import { useState } from 'react';
import { Pagination, ShowUp } from 'shared-ui';

import { ExpandableList } from 'ui/expandableList/ExpandableList';
import { findServerToExpand } from 'helpers/findServerToExpand';
import { Announcement } from 'api/announcements/announcements.types';
import { AnnouncementsMultiActions } from '../announcementsMultiActions/AnnouncementsMultiActions';

import { AnnouncementsTableContainer } from './announcementsTerminals/AnnouncementsTableContainer';
import { AnnouncementsListProps } from './AnnouncementsList.types';

export const AnnouncementsList = ({
  servers,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  announcementsCount,
  setAnnouncementsCount,
  announcementsTableSearchQuery,
}: AnnouncementsListProps) => {
  const [checkedAnnouncements, setCheckedAnnouncements] = useState<Announcement[]>([]);

  return (
    <>
      <Pagination
        page={page}
        setPage={setPage}
        count={announcementsCount}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      <ShowUp show={checkedAnnouncements.length > 1}>
        <AnnouncementsMultiActions
          setCheckedAnnouncements={setCheckedAnnouncements}
          checkedAnnouncements={checkedAnnouncements}
        />
      </ShowUp>

      <ExpandableList
        data={servers || []}
        isLoading={isLoading}
        title={(server) => server.nazwa}
        initiallyExpandedItem={findServerToExpand(servers || [])}
        toggleItemExpansion={(expandedServer) => {
          setPage(0);
          if (!expandedServer) setAnnouncementsCount(0);
        }}
        renderExpandableItemBody={(server, expandedServer) => (
          <AnnouncementsTableContainer
            key={server.id}
            expandedServer={expandedServer}
            setAnnouncementsCount={setAnnouncementsCount}
            checkedAnnouncements={checkedAnnouncements}
            setCheckedAnnouncements={setCheckedAnnouncements}
            announcementsTableSearchQuery={announcementsTableSearchQuery}
          />
        )}
      />
    </>
  );
};
