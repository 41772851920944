import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import { TopBar as TopBarBase } from 'shared-ui';

export const TopBar = styled(TopBarBase)(
  () => css`
    margin-bottom: 30px;
  `,
);

export const TopBarButton = styled(TopBarBase.Button)(
  () => css`
    margin-left: 10px;
  `,
);

export const PageGrid = styled.div`
  display: grid;
  grid-template-columns: 0.65fr 0.35fr;
  gap: 30px;
`;

export const GridLeftColumn = styled.div`
  grid-column: 1/2;
`;

export const GridRightColumn = styled.div`
  grid-column: 2/3;
`;

export const SectionTitle = styled(Typography)(
  ({ theme: { palette } }) => css`
    border-bottom: 3px solid ${palette.grey[300]};
    padding-bottom: 16px;
  `,
);

export const SectionBox = styled.div(
  ({ theme: { palette, shape } }) => css`
    background-color: ${palette.common.white};
    border-radius: ${shape.borderRadius}px;
    padding: 16px;
    margin-top: 16px;
  `,
);

export const Break = styled.div`
  margin-top: 30px;
`;
