import { Controller, useFormContext } from 'react-hook-form';
import debounce from 'debounce';
import { Modal, Button } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';

import { AddGroupAssignedOwnersProps, AddOwnersToGroupsFormBody } from './AddGroupAssignedOwners.types';
import * as Styled from './AddGroupAssignedOwners.styles';

export const AddPosition = ({
  onSubmit,
  isModalOpen,
  onModalClose,
  isLoading,
  onModalOpen,
  owners,
  isLoadingOwners,
  setOwnersQuery,
  group,
  ownersQuery,
}: AddGroupAssignedOwnersProps) => {
  const { formatMessage } = useLocale();
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption({
    idPrefix: formatMessage({
      id: AppMessages['group.addOwner.field.cell.optionLabel'],
    }),
  });
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<AddOwnersToGroupsFormBody>();

  const getOptions = () => {
    return owners.map((owner) => ({
      label: `${owner.imie} ${owner.nazwisko}`,
      value: owner.id,
      number: owner.nrEwidencyjny,
    }));
  };

  const handleOwnerSearch = debounce((search: string) => {
    setOwnersQuery(search);
  }, 300);

  return (
    <>
      <Button onClick={onModalOpen} variant="contained" color="primary">
        {formatMessage({ id: AppMessages['group.addOwner.button'] })}
      </Button>
      <Modal
        header={formatMessage({ id: AppMessages['group.addOwner.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid || isLoading}
      >
        <Styled.Wrapper>
          <Styled.GroupName>
            {formatMessage({ id: AppMessages['group.addOwner.group'] })} {group?.nazwa}
          </Styled.GroupName>
          <Controller
            name="idPkds"
            control={control}
            render={({ field }) => (
              <MultiAutocomplete
                {...field}
                required
                stopRotatePopupIndicator
                inputSize="big"
                limitTags={1}
                onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                inputValue={ownersQuery}
                onInputChange={(e, value) => e && handleOwnerSearch(value)}
                options={getOptions()}
                loading={isLoadingOwners}
                loadingText={formatMessage({ id: AppMessages['common.loading'] })}
                label={formatMessage({ id: AppMessages['group.addOwner.field.cell.label'] })}
                placeholder={formatMessage({
                  id: AppMessages['common.list.placeholderWithSearch'],
                })}
                noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
                withLimitedTags
                renderOption={renderMultiAutocompleteOption}
              />
            )}
          />
        </Styled.Wrapper>
      </Modal>
    </>
  );
};
