import { GlcTerminalsMultiActionsProps } from './GlcTerminalsMultiActions.types';
import * as Styled from './GlcTerminalsMultiActions.styles';
import { DeleteMultiGlcTerminalsContainer } from './deleteGlcTerminals/DeleteMultiGlcTerminalsContainer';

export const GlcTerminalsMultiActions = ({
  checkedGlcTerminals,
  setCheckedGlcTerminals,
}: GlcTerminalsMultiActionsProps) => {
  return (
    <Styled.Container>
      <DeleteMultiGlcTerminalsContainer
        checkedGlcTerminals={checkedGlcTerminals}
        setCheckedGlcTerminals={setCheckedGlcTerminals}
      />
    </Styled.Container>
  );
};
