import { useEffect } from 'react';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';
import { QueryKeyEnum } from 'core/global.enum';
import { AppMessages } from 'i18n/messages';
import { useSelectedGroup } from 'hooks/useSelectedGroup/useSelectedGroup';
import { Group, UpdateGroupBody } from 'api/groups/groups.types';
import { updateGroup } from 'api/groups/groups';
import { useMutation } from 'hooks/useMutation/useMutation';
import { AppRouteWithParams } from 'routing/AppRoute.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';

import { GroupDetails } from './GroupDetails';
import { GroupDetailsContainerProps } from './GroupDetails.types';
import { useGroupDetailsValidation } from './GroupDetails.validation';

export const GroupDetailsContainer = ({ selectedGroup }: GroupDetailsContainerProps) => {
  const { formatMessage } = useLocale();
  const { setSelectedGroup } = useSelectedGroup();
  const { schema } = useGroupDetailsValidation();
  const queryClient = useQueryClient();
  const isHistoryView = useIsExactPath(AppRouteWithParams.groupsHistory.path);

  const { mutate: onUpdateOwner, isLoading } = useMutation(updateGroup, {
    onSuccess: (_, { body }) => {
      setSelectedGroup({ ...selectedGroup, ...body });

      if (isHistoryView) {
        queryClient.invalidateQueries([QueryKeyEnum.GROUP_HISTORY]);
      } else {
        queryClient.invalidateQueries([QueryKeyEnum.GROUPS]);
      }

      toast.dark(formatMessage({ id: AppMessages['group.details.success'] }));
    },
  });

  const formMethods = useForm<UpdateGroupBody>({
    mode: 'all',
    defaultValues: selectedGroup,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    formMethods.reset(selectedGroup);
  }, [selectedGroup]);

  useEffect(() => {
    setSelectedGroup(selectedGroup);
  }, []);

  const onSubmit = (body: Group) => {
    onUpdateOwner({ id: selectedGroup.id, body: { ...selectedGroup, ...body } });
  };

  return (
    <FormProvider {...formMethods}>
      <GroupDetails selectedGroup={selectedGroup} onSubmit={onSubmit} isLoading={isLoading} />
    </FormProvider>
  );
};
