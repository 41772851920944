import { useEffect, useState } from 'react';
import { useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { fetchTerminals } from 'api/terminals/terminals';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useMutation } from 'hooks/useMutation/useMutation';
import { RandomControlTerminalsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { useModalState } from 'hooks/useModalState/useModalState';
import { addRandomControlTerminals } from 'api/control/control';
import { fetchServers } from 'api/servers/servers';

import { useAddRandomControlTerminalsValidation } from './AddRandomControlTerminals.validation';
import {
  AddRandomControlTerminalsFormBody,
  AddRandomControlTerminalsContainerProps,
} from './AddRandomControlTerminals.types';
import { AddRandomControlTerminals } from './AddRandomControlTerminals';

export const AddRandomControlTerminalsContainer = ({ controlId }: AddRandomControlTerminalsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { schema } = useAddRandomControlTerminalsValidation();
  const [selectedServerId, setSelectedServerId] = useState('');

  const methods = useForm<AddRandomControlTerminalsFormBody>({
    defaultValues: {
      idTkds: [],
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { data: serversResponse, isLoading: isLoadingServers } = useQuery([QueryKeyEnum.SERVERS], fetchServers, {
    onSuccess: (serversResponse) => {
      if (serversResponse.data.length) {
        setSelectedServerId(serversResponse.data[0].id);
      }
    },
  });

  const { data: terminalsResponse, isInitialLoading: isLoadingTerminals } = useQuery(
    [QueryKeyEnum.TERMINALS, selectedServerId],
    () => fetchTerminals(selectedServerId),
    {
      args: {
        zaakceptowane: true,
        sort: [RandomControlTerminalsIdSortEnum.ASC],
        controlId,
      },
      enabled: !!selectedServerId,
    },
  );

  const { mutate: onAddRandomControlTerminals, isLoading } = useMutation(addRandomControlTerminals, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.RANDOM_CONTROL_TERMINALS]);
      queryClient.invalidateQueries([QueryKeyEnum.TERMINALS, selectedServerId]);

      toast.dark(formatMessage({ id: AppMessages['control.random.terminals.add.terminal.success'] }));

      onModalClose();
    },
  });

  const onSubmit = (body: AddRandomControlTerminalsFormBody) => {
    onAddRandomControlTerminals({
      id: controlId,
      body: body.idTkds.map(({ value }) => `${value}`),
    });
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen, selectedServerId]);

  return (
    <FormProvider {...methods}>
      <AddRandomControlTerminals
        onSubmit={onSubmit}
        isModalOpen={isModalOpen}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
        isLoading={isLoading}
        isLoadingTerminals={isLoadingTerminals}
        terminals={terminalsResponse?.data || []}
        selectedServerId={selectedServerId}
        servers={serversResponse?.data || []}
        setSelectedServerId={setSelectedServerId}
        isLoadingServers={isLoadingServers}
      />
    </FormProvider>
  );
};
