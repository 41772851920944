import { useLocale, useModalState, useMutation, useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { deleteLogotype } from 'api/logotype/logotype';
import { AppMessages } from 'i18n/messages';
import { QueryKeyEnum } from 'core/global.enum';

import { DeleteLogotype } from './DeleteLogotype';

export const DeleteLogotypeContainer = () => {
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalClose, onModalOpen } = useModalState();
  const queryClient = useQueryClient();

  const { mutate: onDeleteLogo } = useMutation(deleteLogotype, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.LOGOTYPE]);
      onModalClose();
      toast.dark(formatMessage({ id: AppMessages['definitions.logotype.addFile.deleteSuccess'] }));
    },
  });

  const onSubmit = () => {
    onDeleteLogo();
  };

  return (
    <DeleteLogotype
      isModalOpen={isModalOpen}
      onModalOpen={onModalOpen}
      onModalClose={onModalClose}
      onSubmit={onSubmit}
    />
  );
};
