import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionButton } from 'ui/actionButton/ActionButton';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { UnarchiveOwnerProps } from './UnarchiveOwner.types';

export const UnarchiveOwner = ({
  selectedOwner,
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
}: UnarchiveOwnerProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <ActionButton type="unarchive" onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['owner.unarchive.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={onSubmit}
        submitButtonDisabled={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['owner.unarchive.submitButton'] })}
      >
        <>
          <ActionModal.Subtitle>
            {formatMessage({ id: AppMessages['owner.unarchive.text'] })}{' '}
            {`${selectedOwner.imie || ''} ${selectedOwner.nazwisko || ''}`}
          </ActionModal.Subtitle>

          <ActionModal.Text>{formatMessage({ id: AppMessages['owner.unarchive.confirm'] })}</ActionModal.Text>
        </>
      </ActionModal>
    </>
  );
};
