import { ReactComponent as ReturnIcon } from 'assets/icons/return.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { ReturnMultiCardsProps } from './ReturnMultiCards.types';
import * as Styled from './ReturnMultiCards.styles';

export const ReturnMultiCards = ({
  checkedCards,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
}: ReturnMultiCardsProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Styled.ReturnButton
        variant="outlined"
        color="primary"
        size="medium"
        startIcon={<ReturnIcon />}
        onClick={onModalOpen}
      >
        {formatMessage({ id: AppMessages['cards.multiAction.return.button'] })}
      </Styled.ReturnButton>

      <ActionModal
        header={formatMessage({ id: AppMessages['cards.multiAction.return.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={onSubmit}
        submitButtonDisabled={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['cards.multiAction.return.actionButton'] })}
      >
        <>
          <ActionModal.Subtitle>
            {formatMessage({ id: AppMessages['cards.multiAction.return.subtitle'] })} {checkedCards.length}
          </ActionModal.Subtitle>

          <ActionModal.Text>{formatMessage({ id: AppMessages['cards.multiAction.return.text'] })}</ActionModal.Text>

          <ActionModal.Text>{formatMessage({ id: AppMessages['common.reversibleAction'] })}</ActionModal.Text>
        </>
      </ActionModal>
    </>
  );
};
