import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';

export const Subtitle = styled(Typography)`
  margin-bottom: 24px;
`;

export const Text = styled(Typography)<{ bold?: boolean }>(
  ({ bold }) => css`
    margin-bottom: 0;
    font-weight: ${bold ? '700' : '500'};
  `,
);
