import { useEffect } from 'react';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { DEFAULT_PERMISSIONS } from 'core/global.variables';
import { editOwnerIndividualPermissions } from 'api/permissions/permissions';
import { useSelectedOwnerIndividualPermission } from 'hooks/useSelectedOwnerIndividualPermission/useSelectedOwnerIndividualPermission';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { EditOwnerIndividualPermissions } from './EditOwnerIndividualPermissions';
import {
  EditOwnerIndividualPermissionsContainerProps,
  EditOwnerIndividualPermissionsFormBody,
} from './EditOwnerIndividualPermissions.types';
import { useEditOwnerIndividualPermissionsValidation } from './EditOwnerIndividualPermissions.validation';

export const EditOwnerIndividualPermissionsContainer = ({
  checkedPermissions,
  ownerId,
}: EditOwnerIndividualPermissionsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedOwnerIndividualPermission, setSelectedOwnerIndividualPermission } =
    useSelectedOwnerIndividualPermission();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onEditOwnerIndividualPermissions, isLoading } = useMutation(editOwnerIndividualPermissions, {
    onSuccess: (_, body) => {
      if (
        selectedOwnerIndividualPermission &&
        checkedPermissions.some(({ id }) => id === selectedOwnerIndividualPermission?.id)
      ) {
        setSelectedOwnerIndividualPermission({ ...selectedOwnerIndividualPermission, ...body });
      }

      queryClient.invalidateQueries([QueryKeyEnum.OWNER_PERMISSIONS_INDIVIDUAL]);
      queryClient.invalidateQueries([QueryKeyEnum.OWNER_PERMISSIONS_INDIVIDUAL_SERVERS]);
      toast.dark(formatMessage({ id: AppMessages['permissions.multiAction.edit.success'] }));
      onModalClose();
    },
  });

  const { schema } = useEditOwnerIndividualPermissionsValidation();
  const formMethods = useForm<EditOwnerIndividualPermissionsFormBody>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      ...DEFAULT_PERMISSIONS,
    },
  });

  useEffect(() => {
    formMethods.reset();
  }, [isModalOpen]);

  const onSubmit = (formBody: EditOwnerIndividualPermissionsFormBody) => {
    onEditOwnerIndividualPermissions({
      id: ownerId,
      body: {
        ...formBody,
        idPermissions: checkedPermissions.map(({ id }) => `${id}`),
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <EditOwnerIndividualPermissions
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        isLoading={isLoading}
        onSubmit={onSubmit}
        checkedPermissions={checkedPermissions}
      />
    </FormProvider>
  );
};
