import { PermissionEnum } from 'api/permissions/permissions.enum';

export const DRAWER_OPEN_WIDTH = 264;

export const DRAWER_CLOSE_WIDTH = 104;

export const MAX_AMOUNT_OF_SERVERS = 10;

export const JUST_LETTERS_REGEX = /^[^0-9<>%$]*$/;

export const PHONE_REGEX = new RegExp(`^([-|+[0-9]{1})?[0-9 ]*$`);

export const NUMBER_REGEX = new RegExp(/^\d+$/);

export const MAX_IMPORT_FILE_SIZE_BYTES = 15000000;

export const MAX_IMPORT_FILES = 1;

export const DEFAULT_PERMISSIONS = {
  rejestrujOdbicie: PermissionEnum.NIEZDEFINIOWANO,
  otwierajZamek: PermissionEnum.NIEZDEFINIOWANO,
  rejestrujRcp: PermissionEnum.NIEZDEFINIOWANO,
} as const;

export const ACCEPTED_IMPORT_FILE_TYPES = {
  'text/plain': ['.txt'],
  'text/csv': ['.csv'],
};

export const ACCEPTED_IMPORT_IMAGE_TYPES = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
};

export const MAX_ANNOUNCEMENT_LENGTH = 64;
