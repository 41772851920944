import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { CardTypeEnum, CardStatusEnum, CardBlockTypeEnum, ProblemTkdDescriptionEnum } from 'api/cards/cards.enum';
import { CardBlockTypeDescriptionEnum, CardStatusDescriptionEnum, CardTypeDescriptionEnum } from '../Cards.enum';
import {
  CardNumberSortEnum,
  CardOwnerSortEnum,
  CardsQueryKeysEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { Table } from 'ui/table/Table';
import { StatusTableItem } from 'ui/statusTableItem/StatusTableItem';

import { CardsTableHeaderProps } from './CardsTableHeader.types';
import * as Styled from './CardsTableHeader.styles';

export const CardsTableHeader = ({ cardsQueryLocalStorageName }: CardsTableHeaderProps) => {
  const { formatMessage } = useLocale();

  const problemTkdFilterOptions = [
    {
      name: formatMessage({ id: AppMessages[ProblemTkdDescriptionEnum.true] }),
      filterBy: 'true',
    },
    {
      name: formatMessage({ id: AppMessages[ProblemTkdDescriptionEnum.false] }),
      filterBy: 'false',
    },
  ];

  return (
    <>
      <Table.HeaderSortItem
        queryHeader={formatMessage({ id: AppMessages['cards.table.header.number'] })}
        queryKey={cardsQueryLocalStorageName}
        sortBy={CardNumberSortEnum}
      />

      <Table.HeaderFilterItem
        queryKey={cardsQueryLocalStorageName}
        queryName={CardsQueryKeysEnum.PROBLEM_TKD}
        filterItems={problemTkdFilterOptions}
        queryHeader={formatMessage({ id: AppMessages['cards.table.header.reader'] })}
        queryHeaderDescriptions={ProblemTkdDescriptionEnum}
        headerWrapper={(header) => <Styled.ProblemTkdHeaderText noWrap>{header}</Styled.ProblemTkdHeaderText>}
      />

      <Table.HeaderFilterItem
        queryKey={cardsQueryLocalStorageName}
        queryName={CardsQueryKeysEnum.TYPE}
        filterItems={Object.values(CardTypeEnum).map((cardType) => ({
          name: formatMessage({ id: CardTypeDescriptionEnum[cardType] }),
          filterBy: cardType,
        }))}
        queryHeader={formatMessage({ id: AppMessages['cards.table.header.type'] })}
        queryHeaderDescriptions={CardTypeDescriptionEnum}
      />

      <Table.HeaderFilterItem
        queryKey={cardsQueryLocalStorageName}
        queryName={CardsQueryKeysEnum.STATUS}
        filterItems={Object.values(CardStatusEnum).map((status) => ({
          name: <StatusTableItem status={status} />,
          filterBy: status,
        }))}
        queryHeader={formatMessage({ id: AppMessages['cards.table.header.status'] })}
        queryHeaderDescriptions={CardStatusDescriptionEnum}
      />

      <Table.HeaderFilterItem
        queryKey={cardsQueryLocalStorageName}
        queryName={CardsQueryKeysEnum.BLOCKADE}
        filterItems={Object.values(CardBlockTypeEnum).map((blockType) => ({
          name: formatMessage({ id: CardBlockTypeDescriptionEnum[blockType] }),
          filterBy: blockType,
        }))}
        queryHeader={formatMessage({ id: AppMessages['cards.table.header.blocked'] })}
        queryHeaderDescriptions={CardBlockTypeDescriptionEnum}
      />

      <Table.HeaderSortItem
        queryHeader={formatMessage({ id: AppMessages['cards.table.header.owner'] })}
        queryKey={cardsQueryLocalStorageName}
        sortBy={CardOwnerSortEnum}
      />
    </>
  );
};
