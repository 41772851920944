import { useState } from 'react';

import { fetchPositions } from 'api/positions/positions';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';

import { PositionPopupAutocomplete } from './PositionPopupAutocomplete';

export const PositionPopupAutocompleteContainer = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const { data: searchResponse, isLoading } = useQuery([QueryKeyEnum.POSITIONS, searchQuery], () =>
    fetchPositions({ query: searchQuery }),
  );

  return (
    <PositionPopupAutocomplete setSearchQuery={setSearchQuery} positions={searchResponse || []} isLoading={isLoading} />
  );
};
