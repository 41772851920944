import { Controller, useFormContext } from 'react-hook-form';
import { isJustLetters } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { Input } from 'ui/input/Input';
import { AppMessages } from 'i18n/messages';
import { AddUserFormBody } from '../AddUser.types';

import * as Styled from './AddUserPersonalDataForm.styles';

export const AddUserPersonalDataForm = () => {
  const { formatMessage } = useLocale();
  const {
    control,
    formState: { errors },
  } = useFormContext<AddUserFormBody>();

  return (
    <>
      <Controller
        name="imie"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            value={field.value || ''}
            onChange={(e) => {
              if (!isJustLetters(e.target.value)) return;

              field.onChange(e);
            }}
            required
            label={formatMessage({ id: AppMessages['users.addUser.field.firstName.label'] })}
            placeholder={formatMessage({ id: AppMessages['users.addUser.field.firstName.placeholder'] })}
            errorMessage={errors?.imie?.message}
          />
        )}
      />

      <Controller
        name="nazwisko"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            value={field.value || ''}
            onChange={(e) => {
              if (!isJustLetters(e.target.value)) return;

              field.onChange(e);
            }}
            required
            label={formatMessage({ id: AppMessages['users.addUser.field.lastName.label'] })}
            placeholder={formatMessage({ id: AppMessages['users.addUser.field.lastName.placeholder'] })}
            errorMessage={errors?.nazwisko?.message}
          />
        )}
      />

      <Styled.DescriptionInputWrapper>
        <Controller
          name="opis"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label={formatMessage({ id: AppMessages['users.addUser.field.description.label'] })}
              placeholder={formatMessage({ id: AppMessages['users.addUser.field.description.placeholder'] })}
              errorMessage={errors?.opis?.message}
            />
          )}
        />
      </Styled.DescriptionInputWrapper>

      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            label={formatMessage({ id: AppMessages['users.addUser.field.email.label'] })}
            placeholder={formatMessage({ id: AppMessages['users.addUser.field.email.placeholder'] })}
            errorMessage={errors?.email?.message}
          />
        )}
      />
    </>
  );
};
