import { Table } from 'ui/table/Table';

import { OwnerEffectiveTerminalsPermissionsTableRowProps } from './OwnerEffectiveTerminalsPermissionsTableRow.types';

export const OwnerEffectiveTerminalsPermissionsTableRow = ({
  permission,
}: OwnerEffectiveTerminalsPermissionsTableRowProps) => {
  return (
    <>
      <Table.RowItem>{permission.tkd.numerLogicznyKontrolera}</Table.RowItem>

      <Table.RowItem>{permission.tkd.nazwa}</Table.RowItem>

      <Table.RowTagItem
        testId={`${permission.tkd.nazwa}-odbicie-${permission.rejestrujOdbicie}`}
        tag={permission.rejestrujOdbicie}
      />

      <Table.RowTagItem
        testId={`${permission.tkd.nazwa}-zamek-${permission.otwierajZamek}`}
        tag={permission.otwierajZamek}
      />

      <Table.RowTagItem
        testId={`${permission.tkd.nazwa}-rcp-${permission.rejestrujRcp}`}
        tag={permission.rejestrujRcp}
      />
    </>
  );
};
