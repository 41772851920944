import { AppRoute } from 'routing/AppRoute.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';

import { ArchiveMultiGlcContainer } from './archiveMultiGlc/ArchiveMultiGlcContainer';
import { UnarchiveMultiGlcContainer } from './unarchiveMultiGlc/UnarchiveMultiGlcContainer';
import { GlcMultiActionsProps } from './GlcMultiActions.types';
import * as Styled from './GlcMultiActions.styles';

export const GlcMultiActions = ({ checkedGlc, setCheckedGlc }: GlcMultiActionsProps) => {
  const isArchivedView = useIsExactPath(AppRoute.glcArchived);
  const isArchiveMultiGlcActionEnable = checkedGlc.every((glc) => !glc.archiwalny && !glc.tkds?.length);
  const isUnarchiveMultiGlcActionEnable = checkedGlc.every((group) => group.archiwalny);

  return (
    <Styled.Container>
      {!isArchivedView && (
        <>
          {isArchiveMultiGlcActionEnable && (
            <ArchiveMultiGlcContainer checkedGlc={checkedGlc} setCheckedGlc={setCheckedGlc} />
          )}
        </>
      )}
      {isUnarchiveMultiGlcActionEnable && (
        <UnarchiveMultiGlcContainer checkedGlc={checkedGlc} setCheckedGlc={setCheckedGlc} />
      )}
    </Styled.Container>
  );
};
