import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

const MIN_CONTROL_FREQUENCY = 1;
const MAX_CONTROL_FREQUENCY = 10000;

export const useUpdateOwnersControlFormValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    notMoreOftenThan: yup
      .number()
      .typeError(formatMessage({ id: AppMessages['common.validation.required'] }))
      .transform((curr, orig) => (orig === '' ? 0 : curr))
      .notOneOf([0], formatMessage({ id: AppMessages['common.validation.required'] }))
      .min(
        MIN_CONTROL_FREQUENCY,
        formatMessage(
          { id: AppMessages['definitions.control.validation.range'] },
          { from: MIN_CONTROL_FREQUENCY, to: MAX_CONTROL_FREQUENCY },
        ),
      )
      .max(
        MAX_CONTROL_FREQUENCY,
        formatMessage(
          { id: AppMessages['definitions.control.validation.range'] },
          { from: MIN_CONTROL_FREQUENCY, to: MAX_CONTROL_FREQUENCY },
        ),
      ),

    notLessOftenThan: yup
      .number()
      .typeError(formatMessage({ id: AppMessages['common.validation.required'] }))
      .transform((curr, orig) => (orig === '' ? 0 : curr))
      .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      .min(
        MIN_CONTROL_FREQUENCY,
        formatMessage(
          { id: AppMessages['definitions.control.validation.range'] },
          { from: MIN_CONTROL_FREQUENCY, to: MAX_CONTROL_FREQUENCY },
        ),
      )
      .max(
        MAX_CONTROL_FREQUENCY,
        formatMessage(
          { id: AppMessages['definitions.control.validation.range'] },
          { from: MIN_CONTROL_FREQUENCY, to: MAX_CONTROL_FREQUENCY },
        ),
      )
      .test(
        'notLessOftenThan',
        formatMessage({ id: AppMessages['definitions.control.validation.grater'] }),
        (notLessOftenThan, { parent: { notMoreOftenThan } }) => {
          if (!notLessOftenThan) return true;

          return notLessOftenThan >= notMoreOftenThan;
        },
      ),
  });

  return { schema };
};
