import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { EventExportFormatSignEnum, EventExportFormatTypeEnum } from 'api/eventsExportFormat/eventsExportFormats.enum';
import { EventExportFormatParametersValuesEnum } from '../../AddEventExportFormat.enum';

import { ParameterSelectProps } from './ParameterSelect.types';
import { ParameterSignValuesDescriptionEnum, ParameterTypeValuesDescriptionEnum } from './ParameterSelect.enum';
import * as Styled from './ParameterSelect.styles';

export const ParameterSelect = ({ parameter, eventsExportFormatConfig, isPreviewPageMode }: ParameterSelectProps) => {
  const { formatMessage } = useLocale();
  const { control, watch } = useFormContext();

  const isParameterEnabled = watch(`${parameter}.enabled`);

  const numberOfFillToValues = () => {
    if (parameter === EventExportFormatParametersValuesEnum.NUMER_KARTY) return 20;
    if (parameter === EventExportFormatParametersValuesEnum.CZYTNIK) return 4;
    if (parameter === EventExportFormatParametersValuesEnum.STREFA) return 2;
    if (parameter === EventExportFormatParametersValuesEnum.TRYB) return 2;
    else return 0;
  };

  const fillToValues = Array.from({ length: numberOfFillToValues() }, (_, i) => i + 1);

  const shouldShowFillToInput = [
    EventExportFormatParametersValuesEnum.NUMER_KARTY,
    EventExportFormatParametersValuesEnum.STREFA,
    EventExportFormatParametersValuesEnum.CZYTNIK,
    EventExportFormatParametersValuesEnum.TRYB,
  ].includes(parameter);
  const shouldShowSignInput = [EventExportFormatParametersValuesEnum.NUMER_KARTY].includes(parameter);
  const shouldShowTypeInput = [EventExportFormatParametersValuesEnum.RCP].includes(parameter);
  const shouldShowFormatInput = [EventExportFormatParametersValuesEnum.DATA].includes(parameter);

  return (
    <>
      {shouldShowFillToInput && (
        <>
          <Styled.SelectLabel>
            {formatMessage({ id: AppMessages['definitions.events.addExportFormat.parameters.label.fillTo'] })}
          </Styled.SelectLabel>

          <Controller
            control={control}
            name={`${parameter}.dopelnijDo`}
            render={({ field }) => (
              <Styled.ShortSelect disabled={!isParameterEnabled || isPreviewPageMode} {...field}>
                {fillToValues.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Styled.ShortSelect>
            )}
          />
        </>
      )}

      {shouldShowSignInput && (
        <>
          <Styled.SelectLabel>
            {formatMessage({ id: AppMessages['definitions.events.addExportFormat.parameters.label.sign'] })}
          </Styled.SelectLabel>

          <Controller
            control={control}
            name={`${parameter}.znak`}
            render={({ field }) => (
              <Styled.ShortSelect disabled={!isParameterEnabled || isPreviewPageMode} {...field}>
                {Object.values(EventExportFormatSignEnum).map((value) => (
                  <MenuItem key={value} value={value}>
                    {formatMessage({ id: ParameterSignValuesDescriptionEnum[value] })}
                  </MenuItem>
                ))}
              </Styled.ShortSelect>
            )}
          />
        </>
      )}

      {shouldShowFormatInput && (
        <>
          <Styled.SelectLabel>
            {formatMessage({ id: AppMessages['definitions.events.addExportFormat.parameters.label.format'] })}
          </Styled.SelectLabel>

          <Controller
            control={control}
            name={`${parameter}.format`}
            render={({ field }) => (
              <Styled.LongSelect disabled={!isParameterEnabled || isPreviewPageMode} {...field}>
                {eventsExportFormatConfig?.date.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Styled.LongSelect>
            )}
          />
        </>
      )}

      {shouldShowTypeInput && (
        <>
          <Styled.SelectLabel>
            {formatMessage({ id: AppMessages['definitions.events.addExportFormat.parameters.label.type'] })}
          </Styled.SelectLabel>

          <Controller
            control={control}
            name={`${parameter}.rodzaj`}
            render={({ field }) => (
              <Styled.LongSelect disabled={!isParameterEnabled || isPreviewPageMode} {...field}>
                {Object.values(EventExportFormatTypeEnum).map((value) => (
                  <MenuItem key={value} value={value}>
                    {formatMessage({ id: ParameterTypeValuesDescriptionEnum[value] })}
                  </MenuItem>
                ))}
              </Styled.LongSelect>
            )}
          />
        </>
      )}
    </>
  );
};
