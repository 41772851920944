import { TerminalStatusEnum } from 'api/terminals/terminals.enum';
import {
  QueryKeyLocalStorageEnum,
  TerminalIdSortEnum,
  TerminalsQueryKeysEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';
import { TerminalStatusDescriptionEnum } from '../Terminals.enum';
import { StatusTableItem } from 'ui/statusTableItem/StatusTableItem';

export const TerminalsTableHeader = () => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.TERMINALS}
        sortBy={TerminalIdSortEnum}
        queryHeader={formatMessage({ id: AppMessages['devices.readers.header.id'] })}
      />

      <Table.HeaderItem>{formatMessage({ id: AppMessages['devices.readers.header.name'] })}</Table.HeaderItem>

      <Table.HeaderItem>{formatMessage({ id: AppMessages['devices.readers.header.connected'] })}</Table.HeaderItem>

      <Table.HeaderItem>{formatMessage({ id: AppMessages['devices.readers.header.available'] })}</Table.HeaderItem>

      <Table.HeaderFilterItem
        queryKey={QueryKeyLocalStorageEnum.TERMINALS}
        queryName={TerminalsQueryKeysEnum.STATUS}
        filterItems={Object.values(TerminalStatusEnum).map((status) => ({
          name: <StatusTableItem status={status} />,
          filterBy: status,
        }))}
        queryHeader={formatMessage({ id: AppMessages['devices.readers.header.status'] })}
        queryHeaderDescriptions={TerminalStatusDescriptionEnum}
      />

      <Table.HeaderSearchFilterItem filterBy="glcs-tkd">
        {formatMessage({ id: AppMessages['devices.readers.header.glc'] })}
      </Table.HeaderSearchFilterItem>
    </>
  );
};
