import { useState } from 'react';

import { useQuery } from 'hooks/useQuery/useQuery';

import { FormAutocompleteContainerProps } from './FormAutocomplete.types';
import { FormAutocomplete } from './FormAutocomplete';

export const FormAutocompleteContainer = <T extends { id: string }, K>({
  queryFunction,
  queryKey,
  additionalKeys,
  args,
  noDataCallback,
  ...rest
}: FormAutocompleteContainerProps<T, K>) => {
  const [searchQuery, setSearchQuery] = useState('');

  const { data: searchResponse, isLoading } = useQuery(
    [queryKey, searchQuery, ...(additionalKeys ? additionalKeys : [])],
    queryFunction,
    {
      args: { query: searchQuery, ...args, limit: 100 } as K,
      ...(additionalKeys && { enabled: !!additionalKeys?.length }),
      onSuccess: (response) => {
        if (!response) {
          return noDataCallback && noDataCallback();
        }

        const data = Array.isArray(response) ? response : response.data;

        if (!data.length) {
          noDataCallback && noDataCallback();
        }
      },
    },
  );

  const getData = () => {
    if (!searchResponse) return [];

    return Array.isArray(searchResponse) ? searchResponse : searchResponse.data;
  };

  return <FormAutocomplete {...rest} data={getData()} isLoading={isLoading} setSearchQuery={setSearchQuery} />;
};
