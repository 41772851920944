import { Checkbox } from 'shared-ui';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

import { MultiAutocompleteOptionProps } from './MultiAutocompleteOption.types';
import * as Styled from './MultiAutocompleteOption.styles';

export const MultiAutocompleteOption = ({
  props,
  option,
  state,
  idPrefix,
  withoutIdPrefix,
}: MultiAutocompleteOptionProps) => {
  const { formatMessage } = useLocale();

  return (
    <li {...props} key={`${option.label}-${option.value}`}>
      <Styled.OptionWrapper>
        <Checkbox checked={state.selected} color="primary" />

        <div>
          {withoutIdPrefix ? null : (
            <Styled.OptionNumber>
              {idPrefix || formatMessage({ id: AppMessages['common.id'] })} {option.number}
            </Styled.OptionNumber>
          )}

          <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
        </div>
      </Styled.OptionWrapper>
    </li>
  );
};
