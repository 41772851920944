import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { Owner } from 'api/owners/owners.types';
import { RcpEnum } from 'app/events/eventsFilters/EventsFilters.enum';
import { useMinValidationDate } from 'hooks/useMinValidationDate/useMinValidationDate';
import { useUpdateOwnerDetails } from 'hooks/useUpdateOwnerDetails/useUpdateOwnerDetails';

import { OwnerSystemDetails } from './OwnerSystemDetails';
import { OwnerSystemDetailsContainerProps, OwnerSystemDetailsForm } from './OwnerSystemDetails.types';
import { useOwnerSystemDetailsValidation } from './OwnerSystemDetails.validation';

export const OwnerSystemDetailsContainer = ({ selectedOwner, isEditDisabled }: OwnerSystemDetailsContainerProps) => {
  const { selectedOwner: globalSelectedOwner } = useSelectedOwner();

  const resetToSelected = (selected: Owner) => {
    return {
      nrEwidencyjny: selected.nrEwidencyjny ? String(selected.nrEwidencyjny) : '',
      createdAt: selected.createdAt || '',
      rcp: selected.rcp ? RcpEnum.TRUE : RcpEnum.FALSE,
      nrEwidencyjnyZewnetrzny: selected.nrEwidencyjnyZewnetrzny ? String(selected.nrEwidencyjnyZewnetrzny) : '',
      dataOd: selectedOwner.archiwalny ? '' : selectedOwner.dataOd || '',
      dataDo: selectedOwner.archiwalny ? '' : selectedOwner.dataDo || '',
    };
  };

  const { minValidationDate } = useMinValidationDate();
  const schema = useOwnerSystemDetailsValidation(minValidationDate, selectedOwner.dataOd);

  const formMethods = useForm<OwnerSystemDetailsForm>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: resetToSelected(selectedOwner),
  });

  const { onUpdateOwnerSubmit, isLoadingUpdateOwner } = useUpdateOwnerDetails(selectedOwner, formMethods.setError);

  useEffect(() => {
    formMethods.reset(resetToSelected(selectedOwner));
  }, [globalSelectedOwner]);

  const onSubmit = (body: OwnerSystemDetailsForm) => {
    onUpdateOwnerSubmit({
      ...selectedOwner,
      ...body,
      rcp: !!Number(body.rcp),
      createdAt: selectedOwner.createdAt,
      nrEwidencyjnyZewnetrzny: body.nrEwidencyjnyZewnetrzny || '',
      nrEwidencyjny: body.nrEwidencyjny,
      dataOd: (body.dataOd as Date) || null,
      dataDo: (body.dataDo as Date) || null,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <OwnerSystemDetails
        isLoadingUpdateOwner={isLoadingUpdateOwner}
        selectedOwner={selectedOwner}
        onSubmit={onSubmit}
        resetToSelected={resetToSelected}
        isEditDisabled={isEditDisabled}
      />
    </FormProvider>
  );
};
