import { useState, useEffect } from 'react';
import { ErrorBoundary, usePagination } from 'shared-ui';

import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { GlcQueryKeysEnum, QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { QueryKeyEnum } from 'core/global.enum';
import { AppRoute } from 'routing/AppRoute.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { fetchGlc } from 'api/glc/glc';

import { Glc } from './Glc';

export const GlcContainer = () => {
  const isArchivedView = useIsExactPath(AppRoute.glcArchived);

  const glcQueryLocalStorageName = isArchivedView
    ? QueryKeyLocalStorageEnum.ARCHIVED_GLC
    : QueryKeyLocalStorageEnum.ACTIVE_GLC;

  const {
    storedQueries: { [glcQueryLocalStorageName]: glcQuery },
  } = useLocaleStoreQuery();

  const [tableSearchQuery, setTableSearchQuery] = useState('');
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();

  const { data: glcResponse, isLoading } = useQuery(
    [QueryKeyEnum.GLC, page, rowsPerPage, isArchivedView, glcQuery, tableSearchQuery],
    fetchGlc,
    {
      args: {
        [GlcQueryKeysEnum.LIMIT]: rowsPerPage,
        [GlcQueryKeysEnum.ARCHIVED]: !!isArchivedView,
        [GlcQueryKeysEnum.OFFSET]: rowsPerPage * page,
        [GlcQueryKeysEnum.ID]: tableSearchQuery,
        ...glcQuery,
      },
    },
  );

  useEffect(() => {
    setPage(0);
  }, [isArchivedView, tableSearchQuery, glcQuery]);

  return (
    <ErrorBoundary>
      <Glc
        glcResponse={glcResponse}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        isArchivedView={!!isArchivedView}
        glcQueryLocalStorageName={glcQueryLocalStorageName}
        glcQuery={glcQuery}
        setTableSearchQuery={setTableSearchQuery}
      />
    </ErrorBoundary>
  );
};
