import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { deleteWantedMulti } from 'api/wanted/wanted';

import { DeleteWantedMulti } from './DeleteWantedMulti';
import { DeleteWantedMultiContainerProps } from './DeleteWantedMulti.types';

export const DeleteWantedMultiContainer = ({ checkedOwners, setCheckedOwners }: DeleteWantedMultiContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteWantedMulti, isLoading } = useMutation(deleteWantedMulti, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.WANTED]);
      toast.dark(formatMessage({ id: AppMessages['wanted.multiAction.delete.success'] }));
      setCheckedOwners([]);
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteWantedMulti({ body: checkedOwners.map(({ id }) => id) });
  };

  return (
    <DeleteWantedMulti
      checkedOwners={checkedOwners}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
