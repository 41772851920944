import { useState } from 'react';

import { OwnerRegistrationNumberSortEnum, OwnersQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { fetchOwners } from 'api/owners/owners';
import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';

import { AddGroupDefinitionForm } from './AddGroupDefinitionForm';

export const AddGroupDefinitionFormContainer = () => {
  const [ownersQuery, setOwnersQuery] = useState('');

  const { data: ownersResponse, isLoading: isLoadingOwners } = useQuery(
    [QueryKeyEnum.OWNERS, ownersQuery],
    fetchOwners,
    {
      args: {
        query: ownersQuery,
        sort: [OwnerRegistrationNumberSortEnum.ASC],
        [OwnersQueryKeysEnum.ARCHIWALNY]: false,
      },
    },
  );

  return (
    <AddGroupDefinitionForm
      owners={ownersResponse?.data || []}
      isLoadingOwners={isLoadingOwners}
      setOwnersQuery={setOwnersQuery}
      ownersQuery={ownersQuery}
    />
  );
};
