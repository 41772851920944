import { ServerStatusEnum } from 'api/servers/servers.eum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useSelectedTerminal } from 'hooks/useSelectedTerminal/useSelectedTerminal';
import { useSelectedServer } from 'hooks/useSelectedServer/useSelectedServer';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';

import { TerminalsTableHeader } from './terminalsTableHeader/TerminalsTableHeader';
import { TerminalsProps } from './Terminals.types';
import { TerminalsTableRow } from './terminalsTableRow/TerminalsTableRow';

export const Terminals = ({
  terminals,
  isLoading,
  serverStatus,
  checkedTerminals,
  setCheckedTerminals,
}: TerminalsProps) => {
  const { formatMessage } = useLocale();
  const { selectedTerminal, setSelectedTerminal } = useSelectedTerminal();
  const { setSelectedServer } = useSelectedServer();

  const terminalsRowsDisabled = [ServerStatusEnum.ODLACZONY, ServerStatusEnum.AWARIA].includes(serverStatus);

  return (
    <Table
      testIdKey="nazwa"
      gridTemplateColumns="0.7fr 1.4fr 1fr 1fr 1fr 0.8fr"
      data={terminals || []}
      nothingToShowText={formatMessage({ id: AppMessages['devices.readers.nothingToShow'] })}
      onClickRow={(terminal) => {
        setSelectedTerminal(selectedTerminal?.id === terminal.id ? undefined : terminal);
        setSelectedServer(undefined);
      }}
      activeRowId={selectedTerminal?.id}
      rowsDisabled={terminalsRowsDisabled}
      isLoading={isLoading}
      checkboxSelection={{
        checked: checkedTerminals,
        toggleChecked: setCheckedTerminals,
        withoutCheckedElementsBar: true,
      }}
      renderHeader={() => <TerminalsTableHeader />}
      renderRow={(terminal) => <TerminalsTableRow terminal={terminal} terminalsRowsDisabled={terminalsRowsDisabled} />}
      customTableHeight={() => undefined}
    />
  );
};
