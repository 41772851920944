import { format } from 'date-fns';
import { DoubleTextElipsisWithTooltip, useObserver, Tooltip } from 'shared-ui';

import { DateFormat } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { truncate } from 'helpers/truncate';
import { Table } from 'ui/table/Table';
import { AppMessages } from 'i18n/messages';
import { EventModDescriptionEnum } from '../Events.enum';

import { EventsTableRowProps } from './EventsTableRow.types';
import * as Styled from './EventsTableRow.styles';

export const EventsTableRow = ({ event, displayFullTable }: EventsTableRowProps) => {
  const { formatMessage } = useLocale();
  const { tableWidth } = useObserver();
  const ownerFullName = `${event?.posiadacz?.imie || ''} ${event?.posiadacz?.nazwisko || ''}`;

  return (
    <>
      <Table.RowItem>{event.idTkd}</Table.RowItem>

      <Tooltip
        placement="bottom-start"
        disableHoverListener={truncate(event.numerKarty) === `${event.numerKarty}`}
        title={event.numerKarty}
      >
        <Table.RowItem>{truncate(event.numerKarty)}</Table.RowItem>
      </Tooltip>

      {displayFullTable && (
        <Table.RowItem>
          <DoubleTextElipsisWithTooltip
            fullText={ownerFullName}
            firstLineText={event?.posiadacz?.imie}
            secondLineText={event?.posiadacz?.nazwisko}
            width={tableWidth}
          />
        </Table.RowItem>
      )}

      <Table.RowItem>
        <Styled.Bold>{formatMessage({ id: EventModDescriptionEnum[event.mod] })} </Styled.Bold>
      </Table.RowItem>

      <Table.RowItem>
        <Styled.Bold>{event.tryb}</Styled.Bold>
      </Table.RowItem>

      <Table.RowItem>
        <Styled.Bold>
          {event.rcp
            ? formatMessage({ id: AppMessages['common.yes'] })
            : formatMessage({ id: AppMessages['common.no'] })}
        </Styled.Bold>
      </Table.RowItem>

      <Table.RowItem>
        <Styled.Bold>
          {event.rewizja
            ? formatMessage({ id: AppMessages['common.yes'] })
            : formatMessage({ id: AppMessages['common.no'] })}
        </Styled.Bold>
      </Table.RowItem>

      <Table.RowItem>
        <Styled.Bold>
          {event.poszukiwany
            ? formatMessage({ id: AppMessages['common.yes'] })
            : formatMessage({ id: AppMessages['common.no'] })}
        </Styled.Bold>
      </Table.RowItem>

      <Table.RowItem>
        <div>{format(new Date(event.data), DateFormat.DATE)}</div>
        <div>{format(new Date(event.data), DateFormat.TIME)}</div>
      </Table.RowItem>
    </>
  );
};
