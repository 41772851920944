import styled from 'styled-components';

import { ReactComponent as TrashIconBase } from 'assets/icons/primary-trash.svg';
import { ReactComponent as PenIconBase } from 'assets/icons/primary-pen.svg';

export const TrashIcon = styled(TrashIconBase)`
  cursor: pointer;
`;

export const PenIcon = styled(PenIconBase)`
  cursor: pointer;
`;
