import * as yup from 'yup';
import { isValid } from 'date-fns';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useUpdateExpiryDateMultiCardsOrOwnersValidation = (minDate: Date) => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape(
    {
      waznoscOd: yup
        .date()
        .min(minDate, formatMessage({ id: AppMessages['common.validation.date.past'] }))
        .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }))
        .when('waznoscDo', (validTo) => {
          if (validTo && isValid(validTo)) {
            return yup
              .date()
              .min(minDate, formatMessage({ id: AppMessages['common.validation.date.past'] }))
              .max(validTo, formatMessage({ id: AppMessages['common.validation.date.invalidRange'] }))
              .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }));
          }
          return yup
            .date()
            .min(minDate, formatMessage({ id: AppMessages['common.validation.date.past'] }))
            .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }));
        })

        .required(formatMessage({ id: AppMessages['common.validation.date.format'] })),
      waznoscDo: yup
        .date()
        .typeError(formatMessage({ id: AppMessages['common.validation.date.format'] }))
        .min(
          yup.ref('waznoscOd'),
          formatMessage({ id: AppMessages['cards.multiAction.expiryDate.endDate.validation.value'] }),
        )
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .optional(),
    },
    [['waznoscOd', 'waznoscDo']],
  );

  return { schema };
};
