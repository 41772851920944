import { useState, useEffect } from 'react';
import { useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

import { fetchOwners } from 'api/owners/owners';
import { addMultiOwners } from 'api/groups/groups';
import { QueryKeyEnum } from 'core/global.enum';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useQuery } from 'hooks/useQuery/useQuery';
import { AppMessages } from 'i18n/messages';
import { OwnerRegistrationNumberSortEnum, OwnersQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { useModalState } from 'hooks/useModalState/useModalState';

import { useAddMultiOwnersValidation } from './AddMultiOwners.validation';
import { AddMultiOwnersFormBody, AddMultiOwnersContainerProps } from './AddMultiOwners.types';
import { AddMultiCells } from './AddMultiOwners';

export const AddMultiOwnersContainer = ({ checkedGroups }: AddMultiOwnersContainerProps) => {
  const [ownersQuery, setOwnersQuery] = useState('');
  const { formatMessage } = useLocale();
  const { schema } = useAddMultiOwnersValidation();
  const queryClient = useQueryClient();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const methods = useForm<AddMultiOwnersFormBody>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const { mutate: onAddMultiOwners, isLoading } = useMutation(addMultiOwners, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.GROUPS]);
      toast.dark(formatMessage({ id: AppMessages['groups.multiAction.addOwner.successMessage'] }));
      onModalClose();
    },
  });

  const { data: ownersResponse, isLoading: isLoadingOwners } = useQuery(
    [QueryKeyEnum.OWNERS, ownersQuery],
    fetchOwners,
    {
      args: {
        query: ownersQuery,
        offset: 0,
        limit: 100,
        [OwnersQueryKeysEnum.ARCHIWALNY]: false,
        sort: [OwnerRegistrationNumberSortEnum.ASC],
      },
    },
  );
  const owners = ownersResponse?.data || [];

  const onSubmit = (body: AddMultiOwnersFormBody) => {
    onAddMultiOwners({
      idGrup: checkedGroups.map(({ id }) => id),
      idPkds: body.pkds?.map(({ value }) => value as string),
    });
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...methods}>
      <AddMultiCells
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        isLoading={isLoading}
        onSubmit={onSubmit}
        owners={owners}
        isLoadingOwners={isLoadingOwners}
        setOwnersQuery={setOwnersQuery}
        ownersQuery={ownersQuery}
      />
    </FormProvider>
  );
};
