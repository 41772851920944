import { useContext } from 'react';

import { SelectedOwnerIndividualPermissionContext } from 'context/selectedOwnerIndividualPermission/selectedOwnerIndividualPermissionContext/SelectedOwnerIndividualPermissionContext';

export const useSelectedOwnerIndividualPermission = () => {
  const context = useContext(SelectedOwnerIndividualPermissionContext);

  if (context === undefined) {
    throw new Error(
      'SelectedOwnerIndividualPermissionContext is unavailable, make sure you are using SelectedOwnerPermissionContextController',
    );
  }

  return context;
};
