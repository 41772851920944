import { Table as TableBase, TableProps } from 'shared-ui';

import HeaderFilterItem from './headerFilterItem/HeaderFilterItem';
import HeaderMultiFilterItem from './headerMultiFilterItem/HeaderMultiFilterItem';
import HeaderSearchFilterItem from './headerSearchFilterItem/HeaderSearchFilterItem';
import HeaderSortItem from './headerSortItem/HeaderSortItem';
import RowCellsItem from './rowCellsItem/RowCellsItem';

export const Table = <T extends { id: string }>(props: TableProps<T>) => <TableBase {...props} />;

Table.RowItem = TableBase.RowItem;
Table.HeaderItem = TableBase.HeaderItem;
Table.RowCheckboxItem = TableBase.RowCheckboxItem;
Table.RowTagItem = TableBase.RowTagItem;
Table.RowCellsItem = RowCellsItem;
Table.HeaderFilterItem = HeaderFilterItem;
Table.HeaderMultiFilterItem = HeaderMultiFilterItem;
Table.HeaderSearchFilterItem = HeaderSearchFilterItem;
Table.HeaderSortItem = HeaderSortItem;
