import { FileWithPath } from 'react-dropzone';
import { UseQueryFn, MutationFn } from 'shared-ui';

import { DownloadExampleFileResponse } from 'api/owners/owners.types';
import { PaginatedResponse } from 'core/global.types';

import {
  AddCardBody,
  Card,
  CardsQuery,
  AddManyCardsBody,
  CardHistory,
  AssignCardOwnerBody,
  AddManyCardsResponse,
  AddCardsFromFileResponse,
  UpdateCardPinCodeBody,
  BlockCardBody,
  BlockMultiCardsBody,
  UpdateExpiryDateMultiCardsBody,
  ReturnMultiCardsBody,
  SearchCardQuery,
  CardWithOwner,
  EditMultiCardsBody,
  ReturnDateMultiCardsBody,
} from './cards.types';

export const fetchCards: UseQueryFn<CardsQuery, PaginatedResponse<Card>> = () => {
  return {
    endpoint: `/kad`,
  };
};

export const addCard: MutationFn<{ body: AddCardBody }, Card> = ({ body }) => {
  return {
    endpoint: '/kad',
    method: 'POST',
    body,
  };
};

export const addManyCards: MutationFn<{ body: AddManyCardsBody }, AddManyCardsResponse> = ({ body }) => {
  return {
    endpoint: '/kad/wiele',
    method: 'POST',
    body,
  };
};

export const assignCardToOwner: MutationFn<{ id: string; body: Omit<AssignCardOwnerBody, 'pinConfirm'> }, Card> = ({
  id,
  body,
}) => {
  const validBody = Object.fromEntries(Object.entries(body).filter(([_, v]) => v != null));

  return {
    endpoint: `/kad/${id}/przypisz`,
    method: 'POST',
    body: validBody as AssignCardOwnerBody,
  };
};

export const fetchCardHistory: UseQueryFn<string, PaginatedResponse<CardHistory>> = (id) => {
  return {
    endpoint: `/kad/${id}/historia`,
  };
};

export const archiveCard: MutationFn<string, Card> = (id) => {
  return {
    endpoint: `/kad/${id}/archiwizuj`,
    method: 'POST',
  };
};

export const fetchCardDetails: UseQueryFn<string, Card> = (id) => {
  return {
    endpoint: `/kad/${id}`,
  };
};

export const archiveMultiCards: MutationFn<{ body: string[] }, Card> = ({ body }) => {
  return {
    endpoint: '/kad/archiwizuj/wiele',
    method: 'POST',
    body,
  };
};

export const unarchiveCard: MutationFn<string, Card> = (id) => {
  return {
    endpoint: `/kad/${id}/przywroc`,
    method: 'POST',
  };
};

export const unarchiveMultiCards: MutationFn<{ body: string[] }, Card> = ({ body }) => {
  return {
    endpoint: '/kad/przywroc/wiele',
    method: 'POST',
    body,
  };
};

export const addCardsFromFile: MutationFn<{ body: FileWithPath }, AddCardsFromFileResponse> = ({ body }) => {
  const formData = new FormData();
  formData.append('file', body);

  return {
    endpoint: '/kad/import',
    method: 'POST',
    body: formData,
    file: true,
  };
};

export const validateCardsFromFile: MutationFn<{ body: FileWithPath }, AddCardsFromFileResponse> = ({ body }) => {
  const formData = new FormData();
  formData.append('file', body);

  return {
    endpoint: '/kad/import/validate',
    method: 'POST',
    body: formData,
    file: true,
  };
};

export const updateCardDetails: MutationFn<{ id: string; body: Card }, void> = ({ id, body }) => {
  return {
    endpoint: `/kad/${id}`,
    method: 'PUT',
    body,
  };
};

export const deleteCard: MutationFn<string, void> = (id) => {
  return {
    endpoint: `/kad/${id}`,
    method: 'DELETE',
  };
};

export const deleteMultiCards: MutationFn<{ body: string[] }, void> = ({ body }) => {
  return {
    endpoint: '/batch/kad',
    method: 'DELETE',
    body,
  };
};

export const updateCardPinCode: MutationFn<{ id: string; body: UpdateCardPinCodeBody }, Card> = ({ id, body }) => {
  return {
    endpoint: `/kad/${id}/pin`,
    method: 'POST',
    body,
  };
};

export const blockCard: MutationFn<{ id: string; body: BlockCardBody }, Card> = ({ id, body }) => {
  return {
    endpoint: `/kad/${id}/blokuj`,
    method: 'POST',
    body,
  };
};

export const blockMultiCards: MutationFn<{ body: BlockMultiCardsBody }, Card> = ({ body }) => {
  return {
    endpoint: '/batch/kad/blokuj',
    method: 'POST',
    body,
  };
};

export const updateExipryDateMultiCards: MutationFn<{ body: UpdateExpiryDateMultiCardsBody }, void> = ({ body }) => {
  return {
    endpoint: '/batch/kad/waznosc',
    method: 'POST',
    body,
  };
};

export const unblockCard: MutationFn<string, Card> = (id) => {
  return {
    endpoint: `/kad/${id}/odblokuj`,
    method: 'POST',
  };
};

export const returnCard: MutationFn<{ id: string }, Card> = ({ id }) => {
  return {
    endpoint: `/kad/${id}/zwroc`,
    method: 'POST',
  };
};

export const unblockMultiCards: MutationFn<{ body: string[] }, Card> = ({ body }) => {
  return {
    endpoint: '/batch/kad/odblokuj',
    method: 'POST',
    body,
  };
};

export const returnMultiCards: MutationFn<{ body: ReturnMultiCardsBody }, void> = ({ body }) => {
  return {
    endpoint: '/batch/kad/zwroc',
    method: 'POST',
    body,
  };
};

export const searchCard: UseQueryFn<SearchCardQuery, PaginatedResponse<Card>> = () => {
  return {
    endpoint: `/kad/szukaj`,
  };
};

export const fetchCardsWithOwners: UseQueryFn<SearchCardQuery, PaginatedResponse<CardWithOwner>> = () => {
  return {
    endpoint: `/kad/pkd`,
  };
};

export const editMultiCards: MutationFn<{ body: EditMultiCardsBody }, Card> = ({ body }) => {
  return {
    endpoint: '/batch/kad',
    method: 'PUT',
    body,
  };
};

export const returnDateMultiCards: MutationFn<{ body: ReturnDateMultiCardsBody }, void> = ({ body }) => {
  return {
    endpoint: '/batch/kad/dataZwrotu',
    method: 'POST',
    body,
  };
};

export const downloadCardsImportExample: MutationFn<void, DownloadExampleFileResponse> = () => {
  return {
    endpoint: '/kad/import/example',
    method: 'POST',
  };
};
