import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ClientError, APIError, useErrorHandler, useQueryClient, useLoaderToast } from 'shared-ui';

import { useMutation } from 'hooks/useMutation/useMutation';
import { addUser } from 'api/users/users';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { QueryKeyEnum } from 'core/global.enum';
import { useModalState } from 'hooks/useModalState/useModalState';

import { AddUser } from './AddUser';
import { useAddUserValidation } from './AddUser.validation';
import { AddUserFormBody } from './AddUser.types';

export const AddUserContainer = () => {
  const { formatMessage } = useLocale();
  const { schema } = useAddUserValidation();
  const queryClient = useQueryClient();
  const { load: loadAddUser, update: updateLoadAddUser, close: closeLoadAddUser } = useLoaderToast('addUser');
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { handleError } = useErrorHandler();

  const { mutate: onAddUser, isLoading: isLoadingAddUser } = useMutation(addUser, {
    onMutate: () => loadAddUser(formatMessage({ id: AppMessages['users.addUser.loader'] })),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.USERS]);
      updateLoadAddUser(formatMessage({ id: AppMessages['users.addUser.success'] }));
      onModalClose();
    },
    onError: (error: ClientError<APIError>) => {
      const errorFields = error.response?.data.fields;

      const isUsernameExistsError = errorFields?.find(({ type, field }) => type === 'exists' && field === 'username');
      const isEmailExistsError = errorFields?.find(({ type, field }) => type === 'exists' && field === 'email');

      if (isUsernameExistsError) {
        closeLoadAddUser();
        return methods.setError('username', {
          message: formatMessage({ id: AppMessages['users.addUser.validation.field.name.exist'] }),
        });
      }

      if (isEmailExistsError) {
        closeLoadAddUser();
        return methods.setError('email', {
          message: formatMessage({ id: AppMessages['users.addUser.validation.field.email.exist'] }),
        });
      }

      handleError({ error, updateToastCallback: updateLoadAddUser });
    },
  });

  const methods = useForm<AddUserFormBody>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const onSubmit = (body: AddUserFormBody) => {
    onAddUser(body);
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen]);

  useEffect(() => {
    if (methods.watch('password') === methods.watch('passwordConfirmation')) {
      methods.clearErrors('passwordConfirmation');
    } else {
      methods.trigger('passwordConfirmation');
    }
  }, [methods.watch('password')]);

  return (
    <FormProvider {...methods}>
      <AddUser
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        onSubmit={onSubmit}
        isLoadingAddUser={isLoadingAddUser}
      />
    </FormProvider>
  );
};
