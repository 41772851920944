import { useFormContext } from 'react-hook-form';
import { Avatar } from '@mui/material';
import { AddPhotoModal } from 'shared-ui';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { ActionButton } from 'ui/actionButton/ActionButton';

import { OwnerPhotoProps } from './OwnerPhoto.types';
import * as Styled from './OwnerPhoto.styles';

export const OwnerPhoto = ({
  isModalOpen,
  onModalOpen,
  onModalClose,
  onSubmitAddOwnerPhoto,
  onRemovePhoto,
  isEditDisabled,
  isFetchingOwnerPhoto,
}: OwnerPhotoProps) => {
  const { formatMessage } = useLocale();

  const { watch } = useFormContext();

  const photoFile = watch('photoFile');

  return (
    <>
      <Styled.OwnerPhotoWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['owner.details.basic.photo'] })}</Styled.DetailName>
        <Styled.PlaceholderWrapper>
          {photoFile ? (
            <Avatar
              src={URL.createObjectURL(photoFile)}
              sx={{ width: 96, height: 96, ...(isEditDisabled && { filter: 'grayscale(100%)' }) }}
              variant="rounded"
            />
          ) : (
            <Styled.PhotoPlaceholder>
              {!isFetchingOwnerPhoto && <ActionButton type="add" onClick={onModalOpen} disabled={isEditDisabled} />}
            </Styled.PhotoPlaceholder>
          )}
          <Styled.PhotoDetailsWrapper>
            {photoFile && !isFetchingOwnerPhoto && (
              <ActionButton type="delete" onClick={onRemovePhoto} disabled={isEditDisabled} customTooltipText="" />
            )}
            <Styled.PhotoDetails isVisible={!photoFile && !isFetchingOwnerPhoto}>
              <Styled.PhotoDetail>
                {formatMessage({ id: AppMessages['owner.details.basic.photo.formats'] })}
              </Styled.PhotoDetail>
              <Styled.PhotoDetail>
                {formatMessage({ id: AppMessages['owner.details.basic.photo.resolution'] })}
              </Styled.PhotoDetail>
              <Styled.PhotoDetail>
                {formatMessage({ id: AppMessages['owner.details.basic.photo.size'] })}
              </Styled.PhotoDetail>
            </Styled.PhotoDetails>
          </Styled.PhotoDetailsWrapper>
        </Styled.PlaceholderWrapper>
      </Styled.OwnerPhotoWrapper>

      <AddPhotoModal open={isModalOpen} onClose={onModalClose} onSubmit={onSubmitAddOwnerPhoto} clearOnClose />
    </>
  );
};
