import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { addOwnerPhoto } from 'api/owners/owners';
import { useMutation } from 'hooks/useMutation/useMutation';

import { BasicDataForm } from './BasicDataForm';

export const BasicDataFormContainer = () => {
  const { setValue } = useFormContext();

  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const [pohotoFile, setPhotoFile] = useState<Blob | undefined>(undefined);

  const { mutate: onAddOwnerPhoto } = useMutation(addOwnerPhoto, {
    onSuccess: (data, body) => {
      if (!data.zdjecieMime && !data.zdjecieUrl) return;

      setPhotoFile(body.file);
      setValue('zdjecieMime', data.zdjecieMime);
      setValue('zdjecieUrl', data.zdjecieUrl);
      setIsPhotoModalOpen(false);
    },
  });

  const onSubmitAddOwnerPhoto = async (file: Blob | undefined) => {
    if (file) {
      onAddOwnerPhoto({ file });
    } else {
      setPhotoFile(undefined);
      setValue('zdjecieMime', '');
      setValue('zdjecieUrl', '');
      setIsPhotoModalOpen(false);
    }
  };

  return (
    <BasicDataForm
      onSubmitAddOwnerPhoto={onSubmitAddOwnerPhoto}
      photoFile={pohotoFile}
      isPhotoModalOpen={isPhotoModalOpen}
      onOpenPhotoModal={() => setIsPhotoModalOpen(true)}
      onClosePhotoModal={() => setIsPhotoModalOpen(false)}
    />
  );
};
