import { Controller, useFormContext } from 'react-hook-form';
import { Button, isJustLetters } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import * as Styled from '../OwnerDetails.styles';
import { FormAutocompleteContainer } from 'reusable/formAutocomplete/FormAutocompleteContainer';
import { QueryKeyEnum } from 'core/global.enum';
import { fetchPositions } from 'api/positions/positions';
import { PositionNameSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';

import { OwnerBasicDetailsProps, OwnerBasicDetailsForm } from './OwnerBasicDetails.types';
import { OwnerPhotoContainer } from './ownerPhoto/OwnerPhotoContainer';

export const OwnerBasicDetails = ({
  isLoadingUpdateOwner,
  selectedOwner,
  onSubmit,
  refetchOwnerPhoto,
  resetToSelected,
  isEditDisabled,
  isFetchingOwnerPhoto,
}: OwnerBasicDetailsProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isValid, errors },
  } = useFormContext<OwnerBasicDetailsForm>();

  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)}>
      <OwnerPhotoContainer isEditDisabled={isEditDisabled} isFetchingOwnerPhoto={isFetchingOwnerPhoto} />

      <Controller
        name="imie"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            disabled={isEditDisabled}
            onChange={(e) => {
              if (!isJustLetters(e.target.value)) return;

              field.onChange(e);
            }}
            placeholder={formatMessage({ id: AppMessages['owner.details.basic.name.placeholder'] })}
            label={formatMessage({ id: AppMessages['owner.details.basic.name'] })}
            errorMessage={errors?.imie?.message}
            withoutHelperText
          />
        )}
      />
      <Controller
        name="nazwisko"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            disabled={isEditDisabled}
            onChange={(e) => {
              if (!isJustLetters(e.target.value)) return;

              field.onChange(e);
            }}
            placeholder={formatMessage({ id: AppMessages['owner.details.basic.lastName.placeholder'] })}
            label={formatMessage({ id: AppMessages['owner.details.basic.lastName'] })}
            errorMessage={errors?.nazwisko?.message}
            withoutHelperText
          />
        )}
      />
      <Controller
        name="drugieImie"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            disabled={isEditDisabled}
            onChange={(e) => {
              if (!isJustLetters(e.target.value)) return;

              field.onChange(e);
            }}
            placeholder={formatMessage({ id: AppMessages['owner.details.basic.secondName.placeholder'] })}
            label={formatMessage({ id: AppMessages['owner.details.basic.secondName'] })}
            errorMessage={errors?.drugieImie?.message}
            withoutHelperText
          />
        )}
      />

      <Controller
        control={control}
        name="firma"
        render={({ field }) => (
          <OrganizationsTreeSelect
            {...field}
            onChange={(val) => (val ? field.onChange(val) : field.onChange(''))}
            value={typeof field.value === 'object' ? field.value : undefined}
            placeholder={formatMessage({ id: AppMessages['owners.addOwner.select.placeholder'] })}
            label={formatMessage({ id: AppMessages['owner.details.basic.company'] })}
            disabled={isEditDisabled}
          />
        )}
      />

      <Controller
        control={control}
        name="komorka"
        render={({ field }) => (
          <OrganizationsTreeSelect
            {...field}
            multiple
            onChange={(val) => (Array.isArray(val) && val.length ? field.onChange(val) : field.onChange(''))}
            value={Array.isArray(field.value) ? field.value : undefined}
            placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
            label={formatMessage({ id: AppMessages['owner.details.basic.cell'] })}
            disabled={isEditDisabled}
          />
        )}
      />

      <FormAutocompleteContainer
        formFieldName="stanowisko"
        queryKey={QueryKeyEnum.POSITIONS}
        queryFunction={fetchPositions}
        createOption={(position) => ({
          label: position.nazwa,
          value: position.id,
        })}
        handleOptionSelect={(option) => ({
          nazwa: option.label,
          id: option.value,
        })}
        args={{
          sort: [PositionNameSortEnum.ASC],
        }}
        label={formatMessage({ id: AppMessages['owner.details.basic.position'] })}
        disabled={isEditDisabled}
      />

      {isDirty && (
        <Styled.ButtonsWrapper>
          <Button
            size="small"
            onClick={() => {
              reset(resetToSelected(selectedOwner));
              refetchOwnerPhoto();
            }}
            color="primary"
          >
            {formatMessage({ id: AppMessages['owner.details.button.cancel'] })}
          </Button>

          <Button
            type="submit"
            disabled={!isValid}
            isLoading={isLoadingUpdateOwner}
            size="small"
            variant="contained"
            color="primary"
          >
            {formatMessage({ id: AppMessages['owner.details.button.save'] })}
          </Button>
        </Styled.ButtonsWrapper>
      )}
    </Styled.Form>
  );
};
