import { useContext } from 'react';

import { QueryContext } from 'context/query/queryContext/QueryContext';

export const useLocaleStoreQuery = () => {
  const context = useContext(QueryContext);

  if (context === undefined) {
    throw new Error('useLocaleStoreQuery must be used within an QueryContextController');
  }

  return context;
};
