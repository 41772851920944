export const enum EventExportFormatParametersValuesEnum {
  NUMER_KARTY = 'numerKarty',
  DATA = 'data',
  MOD = 'mod',
  TRYB = 'tryb',
  STAN_WEJSCIA = 'stanWejscia',
  RCP = 'rcp',
  STREFA = 'strefa',
  POSZUKIWANY = 'poszukiwany',
  REWIZJA = 'rewizja',
  CZYTNIK = 'czytnik',
}

export enum AddEventExportFormatPageModeEnum {
  ADD = 'add',
  EDIT = 'edit',
  PREVIEW = 'preview',
}
