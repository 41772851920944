import { EventEnum, EventModEnum } from 'api/events/events.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Server } from 'api/servers/servers.types';
import { PermissionDesctiptionEnum, PermissionEnum } from 'api/permissions/permissions.enum';
import { EventModDescriptionEnum } from 'app/events/Events.enum';
import { EVENT_MODE_NUMBERS } from 'app/events/Events.variables';
import {
  EventDescriptionEnum,
  RcpDescriptionEnum,
  RcpEnum,
  YesNoFiltersEnum,
} from 'app/events/eventsFilters/EventsFilters.enum';
import { UserStatusDescriptionEnum, UserStatusEnum, UserTypeDescriptionEnum, UserTypeEnum } from 'api/users/users.enum';
import { CardTypeEnum } from 'api/cards/cards.enum';
import { CardTypeDescriptionEnum } from 'app/cards/Cards.enum';
import { CardBlockedDescriptionEnum, CardBlockedEnum } from 'reusable/addCard/AddCard.enum';
import { EventExportFormat, EventsExportFormatConfig } from 'api/eventsExportFormat/eventsExportFormats.types';
import { GlcValueEnum } from 'app/groups/addGroup/AddGroup.enum';
import { GroupTypeDescriptionEnum } from 'app/groups/Groups.enum';
import { GroupTypeEnum } from 'api/groups/groups.enum';
import { AnnouncementModDescriptionEnum, AnnouncementModEnum } from 'api/announcements/announcements.enum';

export const useAppSelectOptions = (args?: {
  servers?: Server[];
  eventsExportFormatConfig?: EventsExportFormatConfig;
  eventsExportFormats?: EventExportFormat[];
}) => {
  const { formatMessage } = useLocale();

  const permissionOptions = Object.values(PermissionEnum).map((option) => ({
    value: option,
    label: formatMessage({ id: PermissionDesctiptionEnum[option] }),
  }));

  const modOptions = Object.values(EventModEnum).map((mod) => ({
    value: mod,
    label: formatMessage({ id: EventModDescriptionEnum[mod] }),
  }));

  const modeOptions = EVENT_MODE_NUMBERS.map((mode) => ({
    value: mode,
    label: String(mode),
  }));

  const rcpOptions = Object.values(RcpEnum).map((option) => ({
    value: option,
    label: formatMessage({ id: RcpDescriptionEnum[option] }),
  }));

  const eventOptions = Object.values(EventEnum).map((action) => ({
    value: action,
    label: formatMessage({ id: EventDescriptionEnum[action] }),
  }));

  const yesNoOptions = [
    {
      value: YesNoFiltersEnum.YES,
      label: formatMessage({ id: AppMessages['common.yes'] }),
    },
    {
      value: YesNoFiltersEnum.NO,
      label: formatMessage({ id: AppMessages['common.no'] }),
    },
  ];

  const userStatusOptions = Object.values(UserStatusEnum).map((status) => ({
    value: status,
    label: formatMessage({ id: UserStatusDescriptionEnum[status] }),
  }));

  const userTypeOptions = Object.values(UserTypeEnum).map((type) => ({
    value: type,
    label: formatMessage({ id: UserTypeDescriptionEnum[type] }),
  }));

  const cardTypeOptions = Object.values(CardTypeEnum).map((type) => ({
    value: type,
    label: formatMessage({ id: CardTypeDescriptionEnum[type] }),
  }));

  const cardBlockedOptions = Object.values(CardBlockedEnum).map((blocked) => ({
    value: blocked,
    label: formatMessage({ id: CardBlockedDescriptionEnum[blocked] }),
  }));

  const groupTypeOptions = Object.values(GroupTypeEnum).map((type) => ({
    value: type,
    label: formatMessage({ id: GroupTypeDescriptionEnum[type] }),
  }));

  const glcOptions = [
    {
      value: GlcValueEnum.YES,
      label: formatMessage({ id: AppMessages['common.yes'] }),
    },
    {
      value: GlcValueEnum.NO,
      label: formatMessage({ id: AppMessages['common.no'] }),
    },
  ];

  const serverOptions =
    args && args.servers
      ? args.servers.map((server) => ({
          value: server.id,
          label: server.nazwa,
        }))
      : [];

  const separatorOptions =
    args && args.eventsExportFormatConfig
      ? args.eventsExportFormatConfig?.separator.map((value) => ({
          label: value,
          value,
        }))
      : [];

  const endlineOptions =
    args && args.eventsExportFormatConfig
      ? args.eventsExportFormatConfig?.endline.map((value) => ({
          label: JSON.stringify(value).replace('"', '').replace('"', ''),
          value,
        }))
      : [];

  const eventsExportFormatsOptions =
    args && args.eventsExportFormats
      ? args.eventsExportFormats.map((eventExportFormat) => ({
          value: eventExportFormat.id,
          label: eventExportFormat.nazwa,
        }))
      : [];

  const announcementModOptions = Object.values(AnnouncementModEnum).map((mod) => ({
    label: formatMessage({ id: AnnouncementModDescriptionEnum[mod] }).toUpperCase(),
    value: mod,
  }));

  return {
    modOptions,
    modeOptions,
    rcpOptions,
    eventOptions,
    yesNoOptions,
    serverOptions,
    permissionOptions,
    userStatusOptions,
    userTypeOptions,
    cardTypeOptions,
    cardBlockedOptions,
    separatorOptions,
    endlineOptions,
    eventsExportFormatsOptions,
    groupTypeOptions,
    glcOptions,
    announcementModOptions,
  };
};
