import { Table } from 'ui/table/Table';

import { TerminalsTableRowProps } from './TerminalsTableRow.types';
import { DeleteTerminalContainer } from './deleteTerminal/DeleteTerminalContainer';

export const TerminalsTableRow = ({ terminal }: TerminalsTableRowProps) => {
  return (
    <>
      <Table.RowItem>{terminal.numerLogicznyKontrolera}</Table.RowItem>
      <Table.RowItem>{terminal.nazwa}</Table.RowItem>

      <DeleteTerminalContainer selectedTerminal={terminal} />
    </>
  );
};
