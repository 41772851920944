import { useParams } from 'react-router-dom';

import { fetchGroupGlcPermissions } from 'api/permissions/permissions';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';

import { GroupGlc } from './GroupGlc';
import { GroupGlcContainerProps } from './GroupGlc.types';

export const GroupGlcContainer = ({
  server,
  expandedServer,
  checkedPermissions,
  setCheckedPermissions,
  setPermissionsCount,
}: GroupGlcContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const groupId = id || '';
  const {
    storedQueries: { groupGlcPermissions: groupGlcPermissionsQuery },
  } = useLocaleStoreQuery();

  const { data: permissionsResponse } = useQuery(
    [QueryKeyEnum.GROUP_GLC_PERMISSIONS, groupId, expandedServer?.id, groupGlcPermissionsQuery],
    () => fetchGroupGlcPermissions({ groupId, serverId: expandedServer?.id || server.id }),
    {
      args: groupGlcPermissionsQuery,
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setPermissionsCount(response.count);
      },
    },
  );

  return (
    <GroupGlc
      permissionsResponse={permissionsResponse}
      checkedPermissions={checkedPermissions}
      setCheckedPermissions={setCheckedPermissions}
    />
  );
};
