export enum ParameterSignValuesDescriptionEnum {
  ZERO = 'definitions.events.addExportFormat.parameters.sign.zero',
  SPACJA = 'definitions.events.addExportFormat.parameters.sign.space',
}

export enum ParameterTypeValuesDescriptionEnum {
  WSZYSTKIE = 'definitions.events.addExportFormat.parameters.type.all',
  TAK = 'definitions.events.addExportFormat.parameters.type.yes',
  NIE = 'definitions.events.addExportFormat.parameters.type.no',
}
