import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePagination } from 'shared-ui';

import { fetchServersForControl } from 'api/servers/servers';
import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';

import { InstantControlTerminals } from './InstantControlTerminals';

export const InstantControlTerminalsContainer = () => {
  const { id: ownerId } = useParams<{ id: string }>();

  const {
    storedQueries: { [QueryKeyLocalStorageEnum.INSTANT_CONTROL_TERMINALS]: instantControlTerminalsQuery },
    setQuery,
  } = useLocaleStoreQuery();

  const [terminalsCount, setTerminalsCount] = useState(0);
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();

  const { data: serversResponse, isLoading } = useQuery([QueryKeyEnum.INSTANT_CONTROL_TERMINALS, ownerId], () =>
    fetchServersForControl(ownerId),
  );

  useEffect(() => {
    setQuery(QueryKeyLocalStorageEnum.INSTANT_CONTROL_TERMINALS, {
      ...instantControlTerminalsQuery,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    });
  }, [page, rowsPerPage]);

  return (
    <InstantControlTerminals
      servers={serversResponse?.data}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      terminalsCount={terminalsCount}
      setTerminalsCount={setTerminalsCount}
    />
  );
};
