import { useState, useEffect } from 'react';
import { ShowUp } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Owner } from 'api/owners/owners.types';
import { Table } from 'ui/table/Table';

import { RandomControlTableHeader } from './randomControlTableHeader/RandomControlTableHeader';
import { RandomControlTableRow } from './randomControlTableRow/RandomControlTableRow';
import { RandomControlProps } from './RandomControl.types';
import { RandomControlMultiActions } from './randomControlMultiActions/RandomControlMultiActions';

export const RandomControl = ({
  ownersResponse,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  randomControlQuery,
}: RandomControlProps) => {
  const [checkedRandomControlOwners, setCheckedRandomControlOwners] = useState<Owner[]>([]);
  const { formatMessage } = useLocale();

  useEffect(() => {
    setCheckedRandomControlOwners([]);
  }, [randomControlQuery]);

  return (
    <Table
      isLoading={isLoading}
      pagination={{
        page,
        setPage,
        count: ownersResponse?.count || 0,
        rowsPerPage,
        setRowsPerPage,
      }}
      renderMultiActions={() => (
        <ShowUp show={checkedRandomControlOwners.length > 1}>
          <RandomControlMultiActions
            checkedOwners={checkedRandomControlOwners}
            setCheckedOwners={setCheckedRandomControlOwners}
          />
        </ShowUp>
      )}
      gridTemplateColumns="minmax(140px, 1fr) minmax(190px, 1fr) 1fr 1fr minmax(100px, 1fr) 100px"
      data={ownersResponse?.data || []}
      nothingToShowText={formatMessage({ id: AppMessages['control.random.nothingToShow'] })}
      checkboxSelection={{
        checked: checkedRandomControlOwners,
        toggleChecked: setCheckedRandomControlOwners,
      }}
      renderHeader={() => <RandomControlTableHeader />}
      renderRow={(owner) => <RandomControlTableRow owner={owner} />}
    />
  );
};
