import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

import { AppMessages } from 'i18n/messages';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { updateOrganization } from 'api/organizations/organizations';
import { getObjectInNestedObjectByKey } from 'helpers/getObjectInNestedObjectByKey';
import { useMutation } from 'hooks/useMutation/useMutation';
import { Organization } from 'api/organizations/organizations.types';

import { OrganizationDetails } from './OrganizationDetails';
import { OrganizationDetailsContainerProps, UpdateOrganizationFormBody } from './OrganizationDetails.types';
import { useOrganizationDetailsValidation } from './OrganizationDetails.validation';
import { getParentElement } from './OrganizationDetails.utils';

export const OrganizationDetailsContainer = ({
  mainOrganization,
  selectedOrganization,
}: OrganizationDetailsContainerProps) => {
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();
  const parentElement = getParentElement(mainOrganization, selectedOrganization);
  const { schema } = useOrganizationDetailsValidation(mainOrganization, selectedOrganization, parentElement);

  const organizations = queryClient.getQueryData<Organization[]>([QueryKeyEnum.ORGANIZATIONS]);

  const { mutate: onUpdateOrganization, isLoading } = useMutation(updateOrganization, {
    onSuccess: (_, { body }) => {
      queryClient.invalidateQueries([QueryKeyEnum.ORGANIZATIONS]);

      const isParentChanged = body.parent && body.parent !== selectedOrganization.id;
      const isNameAndCodeChanged = selectedOrganization.nazwa !== body.nazwa && selectedOrganization.kod !== body.kod;
      const isOnlyNameChanged = selectedOrganization.nazwa !== body.nazwa && selectedOrganization.kod === body.kod;
      const isOnlyCodeChanged = selectedOrganization.kod !== body.kod && selectedOrganization.nazwa === body.nazwa;

      if (isNameAndCodeChanged) {
        toast.dark(formatMessage({ id: AppMessages['definitions.organization.details.edit.success.default'] }));
        return;
      }

      if (isOnlyNameChanged) {
        toast.dark(formatMessage({ id: AppMessages['definitions.organization.details.edit.success.name'] }));
        return;
      }

      if (isOnlyCodeChanged) {
        toast.dark(formatMessage({ id: AppMessages['definitions.organization.details.edit.success.code'] }));
        return;
      }

      if (isParentChanged) {
        toast.dark(formatMessage({ id: AppMessages['definitions.organization.details.edit.success.parent'] }));
        return;
      }
    },
  });

  const formMethods = useForm<UpdateOrganizationFormBody>({
    mode: 'all',
    defaultValues: { ...selectedOrganization, parent: parentElement },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const updatedOrganizationItem = getObjectInNestedObjectByKey(
      mainOrganization,
      'id',
      selectedOrganization.id,
    )?.object;

    formMethods.reset({ ...updatedOrganizationItem, parent: parentElement });
  }, [selectedOrganization, mainOrganization]);

  const onSubmit = (body: UpdateOrganizationFormBody) => {
    onUpdateOrganization({
      id: selectedOrganization.id,
      body: { nazwa: body.nazwa, kod: body.kod, parent: body.parent?.id || null },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <OrganizationDetails
        mainOrganization={mainOrganization}
        isLoading={isLoading}
        selectedOrganization={selectedOrganization}
        onSubmit={onSubmit}
        parentElement={parentElement}
        organizations={organizations || []}
      />
    </FormProvider>
  );
};
