import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { updateServer } from 'api/servers/servers';
import { Server } from 'api/servers/servers.types';
import { PaginatedResponse } from 'core/global.types';
import { QueryKeyEnum } from 'core/global.enum';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { ServerDetailsEditContainerProps } from './ServerDetailsEdit.types';
import { ServerDetailsEdit } from './ServerDetailsEdit';

export const ServerDetailsEditContainer = ({ selectedServer, servers }: ServerDetailsEditContainerProps) => {
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();

  const { mutate: onUpdateServer, isLoading } = useMutation(updateServer, {
    onSuccess: (resServer) => {
      queryClient.setQueryData<PaginatedResponse<Server> | undefined>([QueryKeyEnum.SERVERS], (current) => {
        if (!current) return;

        toast.dark(formatMessage({ id: AppMessages['devices.details.edit.success'] }));

        return {
          ...current,
          data: current.data.map((server) => (selectedServer.id === server.id ? resServer : server)),
        };
      });
    },
  });

  const onSubmit = (body: Server) => {
    onUpdateServer({
      id: selectedServer.id,
      body: { ...body, port: Number(body.port), timeout: Number(body.timeout) },
    });
  };

  return (
    <ServerDetailsEdit selectedServer={selectedServer} isLoading={isLoading} onSubmit={onSubmit} servers={servers} />
  );
};
