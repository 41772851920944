import { ClientError, APIError, useQueryClient, useErrorHandler } from 'shared-ui';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { UseFormSetError } from 'react-hook-form';

import { OwnerSystemDetailsForm } from 'app/owners/ownerDetails/ownerSystemDetails/OwnerSystemDetails.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { updateOwnerDetails } from 'api/owners/owners';
import { QueryKeyEnum } from 'core/global.enum';
import { AppMessages } from 'i18n/messages';
import { Owner } from 'api/owners/owners.types';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { useMutation } from 'hooks/useMutation/useMutation';

export const useUpdateOwnerDetails = (selectedOwner: Owner, setError?: UseFormSetError<OwnerSystemDetailsForm>) => {
  const { formatMessage } = useLocale();
  const { setSelectedOwner } = useSelectedOwner();
  const { handleError } = useErrorHandler();
  const { id: ownerId } = useParams<{ id: string | undefined }>();
  const queryClient = useQueryClient();

  const { mutate: onUpdateOwner, isLoading: isLoadingUpdateOwner } = useMutation(updateOwnerDetails, {
    onSuccess: (_, { body }) => {
      setSelectedOwner({ ...selectedOwner, ...body });
      if (ownerId) {
        queryClient.invalidateQueries([QueryKeyEnum.OWNER_HISTORY, ownerId]);
      } else {
        queryClient.invalidateQueries([QueryKeyEnum.OWNERS]);
      }

      toast.dark(formatMessage({ id: AppMessages['owner.details.update.success'] }));
    },
    onError: (error: ClientError<APIError>) => {
      const errorFields = error.response?.data.fields;

      const isRegistrationNumberExistsError = errorFields?.find(
        ({ type, field }) => type === 'exists' && field === 'nrEwidencyjny',
      );
      const isExternalRegistrationNumberExistsError = errorFields?.find(
        ({ type, field }) => type === 'exists' && field === 'nrEwidencyjnyZewnetrzny',
      );

      if (isRegistrationNumberExistsError) {
        return (
          setError &&
          setError('nrEwidencyjny', {
            message: formatMessage({ id: AppMessages['owner.details.system.number.validation.exist'] }),
          })
        );
      }

      if (isExternalRegistrationNumberExistsError) {
        return (
          setError &&
          setError('nrEwidencyjnyZewnetrzny', {
            message: formatMessage({
              id: AppMessages['owner.details.system.internalNumber.validation.exist'],
            }),
          })
        );
      }

      handleError({ error, omitAPIErrorMessage: true });
    },
  });

  const onUpdateOwnerSubmit = (body: Owner) => {
    onUpdateOwner({ id: selectedOwner.id, body: { ...selectedOwner, ...body } });
  };

  return { onUpdateOwnerSubmit, isLoadingUpdateOwner };
};
