import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { archiveOwner } from 'api/owners/owners';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { ArchiveOwner } from './ArchiveOwner';
import { ArchiveOwnerContainerProps } from './ArchiveOwner.types';

export const ArchiveOwnerContainer = ({ selectedOwner, setSelectedOwner }: ArchiveOwnerContainerProps) => {
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onArchiveOwner, isLoading } = useMutation(archiveOwner, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.OWNERS]);
      setSelectedOwner(undefined);

      toast.dark(formatMessage({ id: AppMessages['owner.archive.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onArchiveOwner(selectedOwner.id);
  };

  return (
    <ArchiveOwner
      selectedOwner={selectedOwner}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
