import styled, { css } from 'styled-components';

export const AutocompleteWrapper = styled.div`
  min-width: 300px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px 10px;
`;

export const Input = styled.input(
  ({ theme: { palette } }) => css`
    color: ${palette.text.primary};
    font-size: 14px;
    margin-top: 2px;
    background-color: transparent;
    border: none;
    outline: none;

    ::placeholder {
      color: ${palette.text.primary};
      font-size: 12px;
    }
  `,
);

export const OptionsWrapper = styled.div`
  max-height: 320px;
`;

export const Option = styled.li(
  ({ theme: { palette } }) => css`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    color: ${palette.grey[900]};
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid ${palette.grey[500]};
    cursor: pointer;

    &:first-child {
      border-top: 1px solid ${palette.grey[500]};
    }

    &:last-child {
      border-bottom: none;
    }
  `,
);
