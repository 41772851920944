import { ReactNode } from 'react';

import * as Styled from './FormGrid.styles';

export const FormGrid = ({
  children,
  gridTemplateColumns,
  gap,
}: {
  children: ReactNode;
  gridTemplateColumns?: string;
  gap?: string;
}) => {
  return (
    <Styled.Grid $gridTemplateColumns={gridTemplateColumns} $gap={gap}>
      {children}
    </Styled.Grid>
  );
};
