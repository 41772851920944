import styled, { css } from 'styled-components';
import { Typography, Tab as MaterialTab, Tabs as MaterialTabs } from '@mui/material';
import { TabPanel as MaterialTabPanel } from '@mui/lab';

export const DetailsItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  margin-bottom: 24px;
`;

export const DetailName = styled(Typography)(
  ({ theme: { palette } }) => css`
    font-weight: 700;
    color: ${palette.text.secondary};
  `,
);

export const DetailText = styled(Typography)(
  ({ theme: { palette } }) => css`
    color: ${palette.grey[700]};
  `,
);

export const Tab = styled(({ className, ...props }) => <MaterialTab {...props} classes={{ root: className }} />)(
  ({ theme: { palette } }) => css`
    background-color: ${palette.grey[300]};
    font-weight: 600;
    border-radius: 16px;
    padding: 6px 10px;
    font-size: 14px;
    border: 1px solid ${palette.grey[300]};
    min-height: auto;
    min-width: auto;
    margin-bottom: 6px;
    height: 32px;

    &.Mui-selected {
      background-color: ${palette.common.white};
      border-color: ${palette.primary.main};
      color: ${palette.text.primary};
    }
  `,
);

export const TabsWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Tabs = styled(MaterialTabs)`
  min-height: 80px;
  div {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  span {
    background-color: transparent;
  }
`;

export const TabPanel = styled(MaterialTabPanel)`
  padding: 0;
  margin: 0 0 32px;
  overflow: auto;
  position: relative;
  height: 100%;
`;

export const ButtonsWrapper = styled.div(
  ({ theme: { palette } }) => css`
    position: sticky;
    padding: 24px 0;
    bottom: 0px;
    display: flex;
    justify-content: space-around;
    background-color: ${palette.common.white};
    z-index: 1;
  `,
);

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 22px;
`;
