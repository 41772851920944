import { useContext } from 'react';

import { SelectedGlcContext } from 'context/selectedGlc/selectedGlcContext/SelectedGlcContext';

export const useSelectedGlc = () => {
  const context = useContext(SelectedGlcContext);

  if (context === undefined) {
    throw new Error('SelectedGlcContext is unavailable, make sure you are using SelectedGlcContextController');
  }

  return context;
};
