import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const DateInfo = styled(Typography)(
  ({ theme: { palette } }) => css`
    font-size: 12px;
    font-weight: 700;
    color: ${palette.grey[800]};
    margin-top: 8px;
  `,
);
