import { calculateTextWidth } from './calculateTextWidth';

export const checkWordSpecialCharsWidth = (word: string) => {
  const specialChars = /[.,?!""''+=()]/;
  let beginSpecialCharWidth = 0;
  let endSpecialCharWidth = 0;

  let i = 0;
  while (i < word.length && specialChars.test(word[i])) {
    beginSpecialCharWidth += calculateTextWidth(word[i]);
    i++;
  }

  let j = word.length - 1;
  while (j >= 0 && specialChars.test(word[j])) {
    endSpecialCharWidth += calculateTextWidth(word[j]);
    j--;
  }

  return {
    beginSpecialCharWidth,
    endSpecialCharWidth,
  };
};
