import { useState, useEffect } from 'react';
import { useQueryClient } from 'shared-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { updateCardPinCode } from 'api/cards/cards';
import { useMutation } from 'hooks/useMutation/useMutation';
import { AppRouteWithParams } from 'routing/AppRoute.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';

import { PinCodeAssign } from './PinCodeAssign';
import { PinCodeAssignContainerProps, AssignCardPinForm } from './PinCodeAssign.types';
import { usePinCodeAssignValidation } from './PinCodeAssign.validation';

export const PinCodeAssignContainer = ({
  title,
  cardId,
  cardNumber,
  isDisabled,
  actionText,
  successText,
  renderActionComponent,
  setSelectedCard,
}: PinCodeAssignContainerProps) => {
  const [isPinCodeAssignModalOpen, setIsPinCodeAssignModalOpen] = useState(false);
  const isHistoryView = useIsExactPath(AppRouteWithParams.cardHistory.path);
  const queryClient = useQueryClient();

  const { schema } = usePinCodeAssignValidation();
  const formMethods = useForm<AssignCardPinForm>({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { mutate: onAssignPinCode, isLoading: isLoadingAssignPin } = useMutation(updateCardPinCode, {
    onSuccess: (updatedCard) => {
      queryClient.invalidateQueries([isHistoryView ? QueryKeyEnum.CARD_HISTORY : QueryKeyEnum.CARDS]);
      setSelectedCard(updatedCard);
      setIsPinCodeAssignModalOpen(false);
      toast.dark(successText);
    },
  });

  useEffect(() => {
    formMethods.reset();
  }, [isPinCodeAssignModalOpen]);

  const handleFormSubmit = ({ pin }: AssignCardPinForm) => {
    onAssignPinCode({ id: cardId, body: { pin } });
  };

  return (
    <FormProvider {...formMethods}>
      <PinCodeAssign
        actionText={actionText}
        title={title}
        cardNumber={cardNumber}
        handleFormSubmit={handleFormSubmit}
        isPinCodeAssignModalOpen={isPinCodeAssignModalOpen}
        onCloseAssignModal={() => setIsPinCodeAssignModalOpen(false)}
        onOpenAssignModal={() => setIsPinCodeAssignModalOpen(true)}
        isLoadingAssignPin={isLoadingAssignPin}
        isDisabled={isDisabled}
        renderActionComponent={renderActionComponent}
      />
    </FormProvider>
  );
};
