import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout, ShowUp, TopBar, Pagination, DetailsDrawer } from 'shared-ui';

import { GroupPermission } from 'api/permissions/permissions.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedGroupTerminalPermission } from 'hooks/useSelectedGroupTerminalPermission/useSelectedGroupTerminalPermission';
import { ExpandableList } from 'ui/expandableList/ExpandableList';
import { findServerToExpand } from 'helpers/findServerToExpand';
import { AppRoute } from 'routing/AppRoute.enum';

import { GroupTerminalsPermissionMultiActions } from './GroupTerminalsPermissionsMultiActions/GroupTerminalsPermissionsMultiActions';
import { GroupTerminalPermissionDetailsContainer } from './groupTerminalPermissionDetails/GroupTerminalPermissionDetailsContainer';
import { AddGroupTerminalPermissionContainer } from './addGroupTerminalPermission/AddGroupTerminalPermissionContainer';
import { GroupTerminalsContainer } from './groupTerminals/GroupTerminalsContainer';
import { GroupTerminalsPermissionsProps } from './GroupTerminalsPermissions.types';

export const GroupTerminalsPermissions = ({
  servers,
  isLoading,
  permissionsCount,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  groupName,
  setPermissionsCount,
}: GroupTerminalsPermissionsProps) => {
  const { formatMessage } = useLocale();
  const { id } = useParams<{ id: string }>();
  const groupId = id || '';
  const { selectedGroupTerminalPermission } = useSelectedGroupTerminalPermission();

  const [checkedPermissions, setCheckedPermissions] = useState<GroupPermission[]>([]);

  return (
    <PageLayout
      header={`${formatMessage({ id: AppMessages['group.readers.permissions.title'] })} ${groupName}`}
      withBackButton={{
        onClick: (navigate) => navigate(AppRoute.groups),
      }}
      renderContent={() => (
        <>
          <TopBar gridTemplateColumns="1fr">
            <TopBar.ButtonsWrapper>
              <AddGroupTerminalPermissionContainer groupId={groupId} />
            </TopBar.ButtonsWrapper>
          </TopBar>

          <ShowUp show={checkedPermissions.length > 1}>
            <GroupTerminalsPermissionMultiActions
              groupId={groupId}
              checkedPermissions={checkedPermissions}
              setCheckedPermissions={setCheckedPermissions}
            />
          </ShowUp>

          <Pagination
            page={page}
            setPage={setPage}
            count={permissionsCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />

          <ExpandableList
            data={servers || []}
            isLoading={isLoading}
            nothingToShowText={formatMessage({ id: AppMessages['group.readers.permissions.nothingToShow'] })}
            title={(server) => server.nazwa}
            initiallyExpandedItem={findServerToExpand(servers || [])}
            toggleItemExpansion={(expandedServer) => {
              setPage(0);
              setCheckedPermissions([]);
              if (!expandedServer) setPermissionsCount(0);
            }}
            renderExpandableItemBody={(server, expandedServer) => (
              <GroupTerminalsContainer
                key={server.id}
                server={server}
                expandedServer={expandedServer}
                checkedPermissions={checkedPermissions}
                setCheckedPermissions={setCheckedPermissions}
                setPermissionsCount={setPermissionsCount}
              />
            )}
          />
        </>
      )}
      renderDetails={() => (
        <DetailsDrawer
          isOpen={!!selectedGroupTerminalPermission}
          header={formatMessage({ id: AppMessages['group.reader.permission.details.title'] })}
        >
          {selectedGroupTerminalPermission && (
            <GroupTerminalPermissionDetailsContainer
              groupId={groupId}
              selectedPermission={selectedGroupTerminalPermission}
            />
          )}
        </DetailsDrawer>
      )}
    />
  );
};
