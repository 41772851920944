export enum EventExportFormatSignEnum {
  ZERO = 'ZERO',
  SPACJA = 'SPACJA',
}

export enum EventExportFormatTypeEnum {
  TAK = 'TAK',
  NIE = 'NIE',
  WSZYSTKIE = 'WSZYSTKIE',
}
