import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export enum YesNoFilterItemsDescriptionEnum {
  true = 'common.yes',
  false = 'common.no',
}

export const useYesNoFilterItems = () => {
  const { formatMessage } = useLocale();

  const yesNoFilterItems = [
    {
      name: formatMessage({ id: AppMessages['common.yes'] }),
      filterBy: 'true',
    },
    {
      name: formatMessage({ id: AppMessages['common.no'] }),
      filterBy: 'false',
    },
  ];

  return { yesNoFilterItems };
};
