import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useErrorHandler, useLoaderToast, ClientError, useQueryClient } from 'shared-ui';

import { editOwnerIndividualPermission } from 'api/permissions/permissions';
import { AppMessages } from 'i18n/messages';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useSelectedOwnerIndividualPermission } from 'hooks/useSelectedOwnerIndividualPermission/useSelectedOwnerIndividualPermission';
import { useMutation } from 'hooks/useMutation/useMutation';

import { OwnerIndividualPermissionDetails } from './OwnerIndividualPermissionDetails';
import {
  OwnerIndividualPermissionDetailsContainerProps,
  OwnerIndividualPermissionDetailsEditForm,
} from './OwnerIndividualPermissionDetails.types';
import { useOwnerPermissionsDetailsValidation } from './OwnerIndividualPermissionDetails.validation';

export const OwnerIndividualPermissionDetailsContainer = ({
  selectedPermission,
  ownerId,
}: OwnerIndividualPermissionDetailsContainerProps) => {
  const { formatMessage } = useLocale();
  const { setSelectedOwnerIndividualPermission } = useSelectedOwnerIndividualPermission();
  const queryClient = useQueryClient();
  const { load: loadUpdateEvent, update: updateUpdateEvent } = useLoaderToast(`permission-${selectedPermission.id}`);
  const { schema } = useOwnerPermissionsDetailsValidation();
  const { handleError } = useErrorHandler();

  const { mutate: onUpdateOwnerPermission, isLoading } = useMutation(editOwnerIndividualPermission, {
    onMutate: () => loadUpdateEvent(formatMessage({ id: AppMessages['event.details.form.loader'] })),
    onSuccess: (_, { body }) => {
      setSelectedOwnerIndividualPermission({ ...selectedPermission, ...body });
      queryClient.invalidateQueries([QueryKeyEnum.OWNER_PERMISSIONS_INDIVIDUAL]);
      updateUpdateEvent(formatMessage({ id: AppMessages['permission.individual.details.edit.success'] }));
    },
    onError: (error: ClientError<Error>) => {
      handleError({ error, updateToastCallback: updateUpdateEvent });
    },
  });

  const formMethods = useForm<OwnerIndividualPermissionDetailsEditForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      otwierajZamek: selectedPermission.otwierajZamek,
      rejestrujOdbicie: selectedPermission.rejestrujOdbicie,
      rejestrujRcp: selectedPermission.rejestrujRcp,
    },
  });

  useEffect(() => {
    formMethods.reset(selectedPermission);
  }, [selectedPermission]);

  const onSubmit = (body: OwnerIndividualPermissionDetailsEditForm) => {
    onUpdateOwnerPermission({
      permissionId: selectedPermission.id,
      pkdId: ownerId,
      body: { idTkd: selectedPermission.tkd.id, ...body },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <OwnerIndividualPermissionDetails
        isLoading={isLoading}
        selectedPermission={selectedPermission}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
};
