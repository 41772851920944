import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';

import { FormStylesProps } from './OwnerDetails.types';

export const DetailsItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  margin-bottom: 24px;
`;

export const DetailName = styled(Typography)(
  ({ theme: { palette } }) => css`
    font-weight: 700;
    color: ${palette.text.secondary};
  `,
);

export const DetailText = styled(Typography)(
  ({ theme: { palette } }) => css`
    color: ${palette.grey[700]};
  `,
);

export const ButtonsWrapper = styled.div(
  ({ theme: { palette } }) => css`
    position: sticky;
    padding: 24px 0;
    bottom: 0px;
    display: flex;
    justify-content: space-around;
    background-color: ${palette.common.white};
    z-index: 1;
  `,
);

export const Form = styled.form<FormStylesProps>(
  ({ $withoutGap }) => css`
    display: flex;
    flex-direction: column;
    gap: ${$withoutGap ? 0 : '22px'};
  `,
);

export const OptionLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  padding: 6px;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 22px;
`;
