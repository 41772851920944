import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';
import * as Styled from '../CardsMultiActions.styles';

import { DeleteMultiCardsProps } from './DeleteMultiCards.types';

export const DeleteMultiCards = ({
  checkedCards,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
}: DeleteMultiCardsProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Styled.DeleteButton
        variant="outlined"
        color="primary"
        size="medium"
        startIcon={<TrashIcon />}
        onClick={onModalOpen}
      >
        {formatMessage({ id: AppMessages['cards.multiAction.delete.button'] })}
      </Styled.DeleteButton>

      <ActionModal
        header={formatMessage({ id: AppMessages['cards.multiAction.delete.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        submitButtonDisabled={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['cards.multiAction.delete.actionButton'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['cards.multiAction.delete.subtitle'] })} {checkedCards.length}
        </ActionModal.Subtitle>

        <ActionModal.Text>{formatMessage({ id: AppMessages['cards.multiAction.delete.text'] })}</ActionModal.Text>

        <ActionModal.Text>{formatMessage({ id: AppMessages['common.irreversibleAction'] })}</ActionModal.Text>
      </ActionModal>
    </>
  );
};
