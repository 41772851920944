import * as yup from 'yup';
import Big from 'big.js';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { SingleOrManyEnum } from '../AddCard.enum';

const MAX_CARD_NUMBER = new Big('18446744073709551615');

export const useAddCardFormValidation = () => {
  const { formatMessage } = useLocale();
  const maxCardsLengthRange = 1000;

  const schema = yup.object().shape({
    singleOrMany: yup.string().optional(),
    nazwa: yup
      .string()
      .max(30, formatMessage({ id: AppMessages['cards.addCard.validation.name.tooLong'] }))
      .optional(),
    opis: yup
      .string()
      .max(60, formatMessage({ id: AppMessages['cards.addCard.validation.description.tooLong'] }))
      .optional(),
    numerKarty: yup
      .string()
      .test(
        'max number check',
        formatMessage(
          { id: AppMessages['cards.addCard.validation.cardNumber.tooLong'] },
          { maxCardNumber: MAX_CARD_NUMBER.toString() },
        ),
        (number) => {
          if (number && number.length < MAX_CARD_NUMBER.toString().length) return true;

          if (number) {
            const cardNumber = new Big(number);

            return !cardNumber.gt(MAX_CARD_NUMBER);
          }

          return true;
        },
      )
      .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      .when('singleOrMany', {
        is: (singleOrMany: SingleOrManyEnum) => singleOrMany === SingleOrManyEnum.MANY,
        then: () => yup.string().optional(),
      }),
    numerOd: yup
      .string()
      .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      .test(
        'max number check',
        formatMessage(
          { id: AppMessages['cards.addCard.validation.cardNumber.tooLong'] },
          { maxCardNumber: MAX_CARD_NUMBER.toString() },
        ),
        (number) => {
          if (number && number.length < MAX_CARD_NUMBER.toString().length) return true;

          if (number) {
            const cardNumber = new Big(number);

            return !cardNumber.gt(MAX_CARD_NUMBER);
          }

          return true;
        },
      )
      .when('singleOrMany', {
        is: (singleOrMany: SingleOrManyEnum) => singleOrMany === SingleOrManyEnum.SINGLE,
        then: () => yup.string().optional(),
      })
      .test(
        'numerOd',
        formatMessage({ id: AppMessages['cards.addCard.many.maxCards'] }),
        (numerOd: string | undefined, { parent: { numerDo } }) => {
          if (!numerOd || !numerDo) return true;
          if (new Big(numerDo).minus(new Big(numerOd)).gt(maxCardsLengthRange)) return false;
          else return true;
        },
      )
      .test(
        'numerOd',
        formatMessage({ id: AppMessages['cards.addCard.validation.many.numbers.invalidRange'] }),
        (numerOd: string | undefined, { parent: { numerDo } }) => {
          if (!numerOd || !numerDo) return true;
          if (new Big(numerOd).gt(new Big(numerDo))) return false;
          else return true;
        },
      ),
    numerDo: yup
      .string()
      .required(formatMessage({ id: AppMessages['common.validation.required'] }))
      .test(
        'max number check',
        formatMessage(
          { id: AppMessages['cards.addCard.validation.cardNumber.tooLong'] },
          { maxCardNumber: MAX_CARD_NUMBER.toString() },
        ),
        (number) => {
          if (number && number.length < MAX_CARD_NUMBER.toString().length) return true;

          if (number) {
            const cardNumber = new Big(number);

            return !cardNumber.gt(MAX_CARD_NUMBER);
          }

          return true;
        },
      )
      .when('singleOrMany', {
        is: (singleOrMany: SingleOrManyEnum) => singleOrMany === SingleOrManyEnum.SINGLE,
        then: () => yup.string().optional(),
      })
      .test(
        'numerDo',
        formatMessage({ id: AppMessages['cards.addCard.validation.many.numbers.invalidRange'] }),
        (numerDo: string | undefined, { parent: { numerOd } }) => {
          if (!numerDo || !numerOd) return true;
          if (new Big(numerOd).gt(new Big(numerDo))) return false;
          else return true;
        },
      ),
  });

  return { schema };
};
