import { useState } from 'react';
import { useQueryClient } from 'shared-ui';
import { useLocation } from 'react-router-dom';

import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchGroupDetails } from 'api/groups/groups';
import { QueryKeyEnum } from 'core/global.enum';
import { useGroupsArchivedView } from 'hooks/useGroupsArchivedView/useGroupsArchivedView';
import { AppRoute } from 'routing/AppRoute.enum';
import { Group } from 'api/groups/groups.types';
import { SelectedGroupContext } from '../selectedGroupContext/SelectedGroupContext';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';

import { SelectedGroupContextControllerProps } from './SelectedGroupContextController.types';

export const SelectedGroupContextController = ({ children }: SelectedGroupContextControllerProps) => {
  const [selectedGroupId, setSelectedGroupId] = useState<string>();
  const { isGroupsArchivedView } = useGroupsArchivedView();
  const queryClient = useQueryClient();
  const [lastActiveSelectedGroupId, setLastActiveSelectedGroupId] = useState<string>();
  const [lastArchivedSelectedGroupId, setLastArchivedSelectedGroupId] = useState<string>();
  const isGroupHistoryView = useIsExactPath(AppRoute.groupHistory);
  const location = useLocation();
  const groupIdFromHistory = isGroupHistoryView ? location.pathname.split(`${AppRoute.groupHistory}/`)[1] : '';

  const groupIdToDisplayInDetails =
    groupIdFromHistory || (isGroupsArchivedView ? lastArchivedSelectedGroupId : lastActiveSelectedGroupId);

  const { data: selectedGroup } = useQuery(
    [QueryKeyEnum.GROUP_DETAILS, groupIdToDisplayInDetails],
    () => fetchGroupDetails(groupIdToDisplayInDetails),
    {
      enabled: !!groupIdToDisplayInDetails,
    },
  );

  const setSelectedGroup = (group: Group | undefined, likeClickAction?: boolean) => {
    if (!group || (group.id === selectedGroup?.id && selectedGroupId && likeClickAction)) {
      selectedGroup && queryClient.setQueryData([QueryKeyEnum.GROUP_DETAILS, selectedGroup.id], null);

      isGroupsArchivedView ? setLastArchivedSelectedGroupId(undefined) : setLastActiveSelectedGroupId(undefined);

      return setSelectedGroupId(undefined);
    }

    setSelectedGroupId(group.id);

    isGroupsArchivedView ? setLastArchivedSelectedGroupId(group.id) : setLastActiveSelectedGroupId(group.id);

    queryClient.setQueryData([QueryKeyEnum.GROUP_DETAILS, group.id], group);
    queryClient.invalidateQueries([QueryKeyEnum.GROUP_DETAILS, group.id]);
  };

  const resetSelectedGroup = () => {
    setLastActiveSelectedGroupId(undefined);
    setLastArchivedSelectedGroupId(undefined);
    setSelectedGroupId(undefined);
    setSelectedGroup(undefined);
  };

  return (
    <SelectedGroupContext.Provider
      value={{
        selectedGroupId,
        setSelectedGroupId,
        selectedGroup,
        setSelectedGroup,
        lastActiveSelectedGroupId,
        lastArchivedSelectedGroupId,
        setLastActiveSelectedGroupId,
        setLastArchivedSelectedGroupId,
        resetSelectedGroup,
      }}
    >
      {children}
    </SelectedGroupContext.Provider>
  );
};
