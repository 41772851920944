import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { deleteOwner } from 'api/owners/owners';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { useMutation } from 'hooks/useMutation/useMutation';
import { AppRoute, AppRouteWithParams } from 'routing/AppRoute.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteOwner } from './DeleteOwner';
import { DeleteOwnerContainerProps } from './DeleteOwner.types';

export const DeleteOwnerContainer = ({ selectedOwner }: DeleteOwnerContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { setSelectedOwner } = useSelectedOwner();
  const navigate = useNavigate();
  const isHistoryView = useIsExactPath(AppRouteWithParams.ownerHistory.path);
  const isHistoryEffectivePermissionsView = useIsExactPath(AppRouteWithParams.ownerPermissionsEffectiveHistory.path);
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteCard, isLoading } = useMutation(deleteOwner, {
    onSuccess: () => {
      setSelectedOwner(undefined);
      queryClient.invalidateQueries([QueryKeyEnum.OWNERS]);
      toast.dark(formatMessage({ id: AppMessages['owner.delete.success'] }));
      onModalClose();
      (isHistoryView || isHistoryEffectivePermissionsView) && navigate(AppRoute.owners);
    },
  });

  const onSubmit = () => {
    onDeleteCard(selectedOwner.id);
  };

  return (
    <DeleteOwner
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
      selectedOwner={selectedOwner}
    />
  );
};
