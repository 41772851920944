import {
  GlcTerminalsIdSortEnum,
  GlcTerminalsNameSortEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';

export const GlcTerminalsTableHeader = () => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.GLC_TERMINALS}
        sortBy={GlcTerminalsIdSortEnum}
        queryHeader={formatMessage({ id: AppMessages['glc.terminals.header.id'] })}
      />

      <Table.HeaderSortItem
        queryKey={QueryKeyLocalStorageEnum.GLC_TERMINALS}
        sortBy={GlcTerminalsNameSortEnum}
        queryHeader={formatMessage({ id: AppMessages['glc.terminals.header.name'] })}
      />
    </>
  );
};
