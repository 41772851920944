import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useErrorHandler, APIError, ClientError, useQueryClient } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { QueryKeyEnum } from 'core/global.enum';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { updateUserDetails } from 'api/users/users';
import { useSelectedUser } from 'hooks/useSelectedUser/useSelectedUser';
import { User } from 'api/users/users.types';

import { UserDetails } from './UserDetails';
import { UserDetailsContainerProps } from './UserDetails.types';

export const UserDetailsContainer = ({ selectedUser }: UserDetailsContainerProps) => {
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();
  const { setSelectedUser } = useSelectedUser();
  const { handleError } = useErrorHandler();

  const {
    mutate: onUpdateUser,
    isLoading: isLoadingUpdateOwner,
    error,
  } = useMutation(updateUserDetails, {
    onSuccess: (_, { body }) => {
      setSelectedUser({ ...selectedUser, ...body });
      queryClient.invalidateQueries([QueryKeyEnum.USERS]);
      toast.dark(formatMessage({ id: AppMessages['user.details.update.success'] }));
    },
    onError: (error: ClientError<APIError>) => {
      const isFieldsError = error.response?.data.fields?.length;

      if (isFieldsError) return;

      handleError({ error });
    },
  });

  useEffect(() => {
    setSelectedUser(selectedUser);
  }, []);

  const onUpdateUserSubmit = (body: User) => {
    onUpdateUser({ id: selectedUser.id, body: { ...selectedUser, ...body } });
  };

  return (
    <UserDetails
      selectedUser={selectedUser}
      onUpdateUserSubmit={onUpdateUserSubmit}
      isLoadingUpdateUser={isLoadingUpdateOwner}
      errorFields={error?.response?.data?.fields}
    />
  );
};
