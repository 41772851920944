import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { archiveMultiCards } from 'api/cards/cards';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { useModalState } from 'hooks/useModalState/useModalState';

import { ArchiveMultiCards } from './ArchiveMultiCards';
import { ArchiveMultiCardsContainerProps } from './ArchiveMultiCards.types';

export const ArchiveMultiCardsContainer = ({ checkedCards }: ArchiveMultiCardsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedCard, setSelectedCard, setLastActiveSelectedCardId } = useSelectedCard();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onArchiveMultiCards, isLoading } = useMutation(archiveMultiCards, {
    onSuccess: () => {
      if (selectedCard && checkedCards.some(({ id }) => id === selectedCard?.id)) {
        setSelectedCard(undefined);
        setLastActiveSelectedCardId(undefined);
      }

      queryClient.invalidateQueries([QueryKeyEnum.CARDS]);
      toast.dark(formatMessage({ id: AppMessages['cards.multiAction.archive.success'] }));
      onModalClose();
    },
  });

  const onSubmit = () => {
    onArchiveMultiCards({ body: checkedCards.map(({ id }) => id) });
  };

  return (
    <ArchiveMultiCards
      checkedCards={checkedCards}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
