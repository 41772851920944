import styled from 'styled-components';

export const IconWrapper = styled.div`
  margin-left: 16px;
  margin-top: 4px;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  align-items: start;
  min-height: 120px;
`;

export const SubmitWrapper = styled.div`
  height: 100%;
  display: grid;
  justify-items: end;
  margin-top: 28px;
`;
