import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { unarchiveMultiGlc } from 'api/glc/glc';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useSelectedGlc } from 'hooks/useSelectedGlc/useSelectedGlc';
import { useModalState } from 'hooks/useModalState/useModalState';

import { UnarchiveMultiGlc } from './UnarchiveMultiGlc';
import { UnarchiveMultiGlcContainerProps } from './UnarchiveMultiGlc.types';

export const UnarchiveMultiGlcContainer = ({ checkedGlc, setCheckedGlc }: UnarchiveMultiGlcContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedGlc, setSelectedGlc } = useSelectedGlc();

  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onUnarchiveMultiGlc, isLoading } = useMutation(unarchiveMultiGlc, {
    onSuccess: () => {
      if (selectedGlc && checkedGlc.some(({ id }) => id === selectedGlc?.id)) {
        setSelectedGlc(undefined);
      }

      queryClient.invalidateQueries([QueryKeyEnum.GLC]);
      toast.dark(formatMessage({ id: AppMessages['glc.multiAction.unarchive.successMessage'] }));
      setCheckedGlc([]);
      onModalClose();
    },
  });

  const onSubmit = () => {
    onUnarchiveMultiGlc({ body: checkedGlc.map(({ id }) => id) });
  };

  return (
    <UnarchiveMultiGlc
      checkedGlc={checkedGlc}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
