import { useLocale, useModalState, useMutation, useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { addLogotype } from 'api/logotype/logotype';
import { AppMessages } from 'i18n/messages';
import { QueryKeyEnum } from 'core/global.enum';

import { AddLogotype } from './AddLogotype';

export const AddLogotypeContainer = () => {
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalClose, onModalOpen } = useModalState();
  const queryClient = useQueryClient();

  const { mutate: onAddLogo } = useMutation(addLogotype, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.LOGOTYPE]);
      onModalClose();
      toast.dark(formatMessage({ id: AppMessages['definitions.logotype.addFile.addSuccess'] }));
    },
  });

  const onSubmit = (file: Blob | undefined) => {
    if (!file) return;

    onAddLogo({ file });
  };

  return (
    <AddLogotype isModalOpen={isModalOpen} onModalOpen={onModalOpen} onModalClose={onModalClose} onSubmit={onSubmit} />
  );
};
