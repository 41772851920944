import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary, PageLayout, TopBar } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { SearchQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { ControlTypeEnum } from 'api/control/control.enum';

import { InstantControlContainer } from './instantControl/InstantControlContainer';
import { ControlBar } from './controlBar/ControlBar';
import { ControlHistoryContainer } from './controlHistory/ControlHistoryContainer';
import { RandomControlContainer } from './randomControl/RandomControlContainer';
import { AddControlContainer } from './addControl/AddControlContainer';

export const Control = () => {
  const { formatMessage } = useLocale();

  const [instantControlTableSearchQuery, setInstantControlTableSearchQuery] = useState('');
  const [randomControlTableSearchQuery, setRandomControlTableSearchQuery] = useState('');

  const location = useLocation();

  const controlBarProps = {
    [AppRoute.controlRandom]: {
      searchQueryKey: SearchQueryKeysEnum.CONTROL_RANDOM as const,
      setTableSearchQuery: setRandomControlTableSearchQuery,
      children: (
        <TopBar.ButtonsWrapper>
          <AddControlContainer type={ControlTypeEnum.LOSOWA} />
        </TopBar.ButtonsWrapper>
      ),
    },
    [AppRoute.controlInstant]: {
      searchQueryKey: SearchQueryKeysEnum.CONTROL_INSTANT as const,
      setTableSearchQuery: setInstantControlTableSearchQuery,
      children: (
        <TopBar.ButtonsWrapper>
          <AddControlContainer type={ControlTypeEnum.NATYCHMIASTOWA} />
        </TopBar.ButtonsWrapper>
      ),
    },
    [AppRoute.controlHistory]: {
      searchQueryKey: SearchQueryKeysEnum.CONTROL_INSTANT as const,
    },
  }[location.pathname] || { searchQueryKey: SearchQueryKeysEnum.CONTROL_INSTANT };

  const currentPage = {
    [AppRoute.controlInstant]: (
      <InstantControlContainer instantControlTableSearchQuery={instantControlTableSearchQuery} />
    ),
    [AppRoute.controlRandom]: <RandomControlContainer randomControlTableSearchQuery={randomControlTableSearchQuery} />,
    [AppRoute.controlHistory]: <ControlHistoryContainer />,
  }[location.pathname];

  return (
    <ErrorBoundary>
      <PageLayout
        header={formatMessage({ id: AppMessages['control.title'] })}
        renderContent={() => (
          <>
            <ControlBar {...controlBarProps} />

            {currentPage}
          </>
        )}
      />
    </ErrorBoundary>
  );
};
