import { Table } from 'ui/table/Table';

import { GroupGlcTableRowProps } from './GroupGlcTableRow.types';

export const GroupGlcTableRow = ({ permission }: GroupGlcTableRowProps) => {
  return (
    <>
      <Table.RowItem>{permission.glc.idGlc}</Table.RowItem>

      <Table.RowItem>{permission.glc.nazwa}</Table.RowItem>

      <Table.RowTagItem
        testId={`${permission.glc.idGlc}-odbicie-${permission.rejestrujOdbicie}`}
        tag={permission.rejestrujOdbicie}
      />

      <Table.RowTagItem
        testId={`${permission.glc.idGlc}-zamek-${permission.otwierajZamek}`}
        tag={permission.otwierajZamek}
      />

      <Table.RowTagItem
        testId={`${permission.glc.idGlc}-rcp-${permission.rejestrujRcp}`}
        tag={permission.rejestrujRcp}
      />
    </>
  );
};
