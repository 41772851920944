import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { CardThumbnail } from 'ui/cardThumbnail/CardThumbnail';
import { AppRoute } from 'routing/AppRoute.enum';
import { ReturnCardContainer } from 'reusable/returnCard/ReturnCardContainer';
import { CardsQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';
import { CardBlockTypeEnum } from 'api/cards/cards.enum';

import { OwnerCardsDetailsProps } from './OwnerCardsDetails.types';
import * as Styled from './OwnerCardsDetails.styles';
import { CardAssignContainer } from './cardAssign/CardAssignContainer';

export const OwnerCardsDetails = ({ selectedOwner, isEditDisabled }: OwnerCardsDetailsProps) => {
  const { formatMessage } = useLocale();

  return (
    <Styled.OwnerCardsDetailsWrapper>
      <Styled.CardsWrapper>
        {selectedOwner.kads?.length ? (
          selectedOwner.kads.map((card) => (
            <Styled.CardWrapper key={card.id}>
              <CardThumbnail
                cardNumber={card.numerKarty}
                blocked={card.blokada !== CardBlockTypeEnum.ODBLOKOWANA}
                cardType={card.typKarty}
                blockTime={card.czasBlokady}
                issuedTime={card.czasWydania}
                isTkdProblem={card.problemTkd}
                archived={card.archiwalna}
                isIssued
              />
              <Styled.CardButtonsWrapper>
                <Styled.Link
                  to={{
                    pathname: AppRoute.cards,
                    search: `?${CardsQueryKeysEnum.ID}=${card.id}`,
                  }}
                >
                  <Styled.CardButton variant="outlined" color="primary" size="medium">
                    {formatMessage({ id: AppMessages['owner.details.cards.button.details'] })}
                  </Styled.CardButton>
                </Styled.Link>
                <ReturnCardContainer
                  isOwnerView
                  cardId={card.id}
                  cardNumber={card.numerKarty}
                  renderActionComponent={(openModal, isDisabled) => (
                    <Styled.CardButton
                      disabled={isDisabled}
                      onClick={openModal}
                      variant="outlined"
                      color="primary"
                      size="medium"
                    >
                      {formatMessage({ id: AppMessages['owner.details.cards.button.return'] })}
                    </Styled.CardButton>
                  )}
                />
              </Styled.CardButtonsWrapper>
            </Styled.CardWrapper>
          ))
        ) : (
          <Styled.NoCards>{formatMessage({ id: AppMessages['owner.details.cards.empty'] })}</Styled.NoCards>
        )}
      </Styled.CardsWrapper>
      <CardAssignContainer ownerId={selectedOwner.id} isEditDisabled={isEditDisabled} />
    </Styled.OwnerCardsDetailsWrapper>
  );
};
