import { Controller, useFormContext } from 'react-hook-form';
import { Button, Datepicker } from 'shared-ui';

import { ReactComponent as ReturnDateIcon } from 'assets/icons/return-date.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';
import { ReturnDateCardBody } from 'api/cards/cards.types';
import { getEndOfToday } from 'helpers/getEndOfToday';
import { handleValidToDate } from 'helpers/handleValidToDate';
import * as Styled from '../CardsMultiActions.styles';

import { ReturnDateMultiCardsProps } from './ReturnDateMultiCards.types';

export const ReturnDateMultiCards = ({
  checkedCards,
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
  minDate,
}: ReturnDateMultiCardsProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useFormContext<ReturnDateCardBody>();

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<ReturnDateIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['cards.multiAction.returnDate.button'] })}
      </Button>

      <ActionModal
        header={formatMessage({ id: AppMessages['cards.multiAction.returnDate.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={isLoading || !isValid}
        submitButtonText={formatMessage({ id: AppMessages['common.save'] })}
      >
        <ActionModal.Subtitle>
          {formatMessage({ id: AppMessages['cards.multiAction.returnDate.subtitle'] })} {checkedCards.length}
        </ActionModal.Subtitle>

        <Styled.Wrapper>
          <Controller
            name="dataZwrotu"
            control={control}
            render={({ field }) => (
              <Datepicker
                {...field}
                value={watch('dataZwrotu')}
                label={formatMessage({ id: AppMessages['cards.multiAction.returnDate.datepicker.label'] })}
                errorMessage={errors?.dataZwrotu?.message}
                withCalendarIcon
                withDatepickerPopover
                isClickable
                popoverOrigin={{
                  vertical: -80,
                  horizontal: 60,
                }}
                minDate={minDate}
                customSetDateFormat={handleValidToDate}
                customDateFormatOnOpen={getEndOfToday}
              />
            )}
          />
        </Styled.Wrapper>
      </ActionModal>
    </>
  );
};
