import { useEffect } from 'react';
import { APIError, ClientError, useErrorHandler, useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';
import { QueryKeyEnum } from 'core/global.enum';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { updateAnnouncement } from 'api/announcements/announcements';
import { useSelectedAnnouncement } from 'hooks/useSelectedAnnouncement/useSelectedAnnouncement';

import { AnnouncementDetails } from './AnnouncementDetails';
import { AnnouncementDetailsContainerProps, UpdateAnnouncementFormBody } from './AnnouncementDetails.types';
import { useAnnouncementDetailsValidation } from './AnnouncementDetails.validation';

export const AnnouncementDetailsContainer = ({ selectedAnnouncement }: AnnouncementDetailsContainerProps) => {
  const { formatMessage } = useLocale();
  const { setSelectedAnnouncement } = useSelectedAnnouncement();
  const { schema } = useAnnouncementDetailsValidation();
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  const { mutate: onUpdateAnnouncement, isLoading } = useMutation(updateAnnouncement, {
    onSuccess: (_, { body }) => {
      setSelectedAnnouncement({ ...selectedAnnouncement, ...body });

      queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENTS]);
      queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENTS_SEARCH]);

      toast.dark(formatMessage({ id: AppMessages['announcements.details.form.success'] }));
    },
    onError: (error: ClientError<APIError>) => {
      const errorFields = error.response?.data.fields;

      const isAnnouncementExistsError = errorFields?.find(({ type, field }) => type === 'exists' && field === 'nazwa');

      if (isAnnouncementExistsError) {
        return formMethods.setError('nazwa', {
          message: formatMessage({ id: AppMessages['announcement.add.name.validation'] }),
        });
      }

      handleError({ error, omitAPIErrorMessage: true });
    },
  });

  const formMethods = useForm<UpdateAnnouncementFormBody>({
    mode: 'all',
    defaultValues: {
      ...selectedAnnouncement,
      potwierdzenie: selectedAnnouncement?.potwierdzenie ? 'true' : 'false',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    formMethods.reset({
      ...selectedAnnouncement,
      potwierdzenie: selectedAnnouncement?.potwierdzenie ? 'true' : 'false',
    });
  }, [selectedAnnouncement]);

  useEffect(() => {
    setSelectedAnnouncement(selectedAnnouncement);
  }, []);

  const onSubmit = (body: UpdateAnnouncementFormBody) => {
    onUpdateAnnouncement({
      id: selectedAnnouncement.id,
      body: {
        ...selectedAnnouncement,
        ...body,
        czasKomunikatu: body.czasKomunikatu ? Number(body.czasKomunikatu) : 0,
        potwierdzenie: body?.potwierdzenie === 'true',
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <AnnouncementDetails selectedAnnouncement={selectedAnnouncement} onSubmit={onSubmit} isLoading={isLoading} />
    </FormProvider>
  );
};
