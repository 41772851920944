import { EventExportFormatSignEnum, EventExportFormatTypeEnum } from 'api/eventsExportFormat/eventsExportFormats.enum';
import { AddEventExportFormatBody, EventsExportFormatConfig } from 'api/eventsExportFormat/eventsExportFormats.types';

import { AddEventExportFormatPageModeEnum, EventExportFormatParametersValuesEnum } from './AddEventExportFormat.enum';

export type AddEventExportFormatProps = {
  onSubmit: (body: AddEventExportFormatBody) => void;
  isLoading: boolean;
  isLoadingAddExportFormat: boolean;
  eventsExportFormatConfig: EventsExportFormatConfig | undefined;
  eventExportFormatPreview: string | undefined;
  isLoadingPreviewEventExportFormat: boolean;
  onPreviewEventExportFormat: (body: AddEventExportFormatBody) => void;
  isPreviewPageMode: boolean;
  isEditPageMode: boolean;
};

export type AddEventExportFormatUrlParams = {
  id: string | undefined;
  mode: AddEventExportFormatPageModeEnum;
};

export const addEventExportFormatDefaultFormValues = {
  nazwa: '',
  separator: '',
  endline: '',
  [EventExportFormatParametersValuesEnum.NUMER_KARTY]: {
    enabled: false,
    position: 0,
    dopelnijDo: 1,
    znak: EventExportFormatSignEnum.ZERO,
  },
  [EventExportFormatParametersValuesEnum.DATA]: {
    enabled: false,
    position: 1,
    format: '',
  },
  [EventExportFormatParametersValuesEnum.MOD]: {
    enabled: false,
    position: 2,
  },
  [EventExportFormatParametersValuesEnum.TRYB]: {
    enabled: false,
    position: 3,
    dopelnijDo: 1,
  },
  [EventExportFormatParametersValuesEnum.STAN_WEJSCIA]: {
    enabled: false,
    position: 4,
  },
  [EventExportFormatParametersValuesEnum.RCP]: {
    enabled: false,
    position: 5,
    rodzaj: EventExportFormatTypeEnum.WSZYSTKIE,
  },
  [EventExportFormatParametersValuesEnum.STREFA]: {
    enabled: false,
    position: 6,
    dopelnijDo: 1,
  },
  [EventExportFormatParametersValuesEnum.POSZUKIWANY]: {
    enabled: false,
    position: 7,
  },
  [EventExportFormatParametersValuesEnum.REWIZJA]: {
    enabled: false,
    position: 8,
  },
  [EventExportFormatParametersValuesEnum.CZYTNIK]: {
    enabled: false,
    position: 9,
    dopelnijDo: 1,
  },
};
