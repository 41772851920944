import { useState } from 'react';

import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { fetchPositions } from 'api/positions/positions';
import { useQuery } from 'hooks/useQuery/useQuery';

import { Positions } from './Positions';

export const PositionsContainer = () => {
  const [positionsTableSearchQuery, setPositionsTableSearchQuery] = useState('');
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.POSITIONS]: positionsQuery },
  } = useLocaleStoreQuery();

  const { data: positionsResponse, isLoading } = useQuery(
    [QueryKeyEnum.POSITIONS, positionsTableSearchQuery, positionsQuery],
    () => fetchPositions(),
    { args: { query: positionsTableSearchQuery, ...positionsQuery } },
  );

  return (
    <Positions
      setPositionsTableSearchQuery={setPositionsTableSearchQuery}
      positionsResponse={positionsResponse}
      isLoading={isLoading}
    />
  );
};
