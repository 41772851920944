import { useLocale } from 'shared-ui';

import { AppMessages } from 'i18n/messages';
import { ADD_LOGO_DROPZONE_WIDTH } from '../Logotype';
import { ActionModal } from 'ui/actionModal/ActionModal';
import * as Styled from '../Logotype.styles';

import { DeleteLogotypeProps } from './DeleteLogotype.types';

export const DeleteLogotype = ({ isModalOpen, onModalClose, onModalOpen, onSubmit }: DeleteLogotypeProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Styled.AddLogotypeButton variant="contained" onClick={onModalOpen} $width={ADD_LOGO_DROPZONE_WIDTH}>
        {formatMessage({ id: AppMessages['common.delete'] })}
      </Styled.AddLogotypeButton>

      <ActionModal
        header={formatMessage({ id: AppMessages['definitions.logotype.deleteFile.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        onSubmit={onSubmit}
        submitButtonText={formatMessage({ id: AppMessages['common.delete'] })}
        size="medium"
      >
        <ActionModal.Text>
          {formatMessage({ id: AppMessages['definitions.logotype.deleteFile.text'] })}
        </ActionModal.Text>
      </ActionModal>
    </>
  );
};
