import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useEditMultiCardsValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape(
    {
      nazwa: yup
        .string()
        .nullable()
        .notRequired()
        .when('nazwa', {
          is: (value: string) => value?.length,
          then: (rule) =>
            rule
              .min(3, formatMessage({ id: AppMessages['cards.multiAction.edit.name.minLength.validation'] }))
              .max(20, formatMessage({ id: AppMessages['cards.multiAction.edit.name.maxLength.validation'] })),
        }),
      opis: yup
        .string()
        .required('')
        .max(60, formatMessage({ id: AppMessages['card.details.form.description.validation.tooLong'] }))
        .when('nazwa', {
          is: (value: string) => value?.length,
          then: (rule) =>
            rule
              .optional()
              .max(60, formatMessage({ id: AppMessages['card.details.form.description.validation.tooLong'] })),
        }),
    },
    [
      ['nazwa', 'nazwa'],
      ['opis', 'opis'],
    ],
  );

  return { schema };
};
