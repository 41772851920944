import { useState } from 'react';
import { useQueryClient } from 'shared-ui';
import { useLocation } from 'react-router-dom';

import { QueryKeyEnum } from 'core/global.enum';
import { SelectedGlcContext } from '../selectedGlcContext/SelectedGlcContext';
import { Glc } from 'api/glc/glc.types';
import { fetchGlcDetails } from 'api/glc/glc';
import { useQuery } from 'hooks/useQuery/useQuery';
import { AppRoute, AppRouteWithParams } from 'routing/AppRoute.enum';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';

import { SelectedGlcContextControllerProps } from './SelectedGlcContextController.types';

export const SelectedGlcContextController = ({ children }: SelectedGlcContextControllerProps) => {
  const [selectedGlcId, setSelectedGlcId] = useState<string>();
  const isArchivedView = useIsExactPath(AppRoute.glcArchived);
  const isGlcHistoryView = useIsExactPath(AppRoute.glcHistory);
  const isGlcTerminalsView = useIsExactPath(AppRouteWithParams.glcTerminals.path);
  const queryClient = useQueryClient();
  const [lastActiveSelectedGlcId, setLastActiveSelectedGlcId] = useState<string>();
  const [lastArchivedSelectedGlcId, setLastArchivedSelectedGlcId] = useState<string>();

  const location = useLocation();
  const glcIdFromHistory = isGlcHistoryView ? location.pathname.split(`${AppRoute.glcHistory}/`)[1] : '';
  const glcIdFromTerminals = isGlcTerminalsView ? location.pathname.split(`${AppRoute.glcTerminals}/`)[1] : '';

  const glcIdToDisplayInDetails =
    glcIdFromTerminals || glcIdFromHistory || (isArchivedView ? lastArchivedSelectedGlcId : lastActiveSelectedGlcId);

  const { data: selectedGlc } = useQuery(
    [QueryKeyEnum.GLC_DETAILS, glcIdToDisplayInDetails],
    () => fetchGlcDetails(glcIdToDisplayInDetails),
    {
      enabled: !!glcIdToDisplayInDetails,
    },
  );

  const setSelectedGlc = (glc: Glc | undefined, likeClickAction?: boolean) => {
    if (!glc || (glc.id === selectedGlc?.id && selectedGlcId && likeClickAction)) {
      selectedGlc && queryClient.setQueryData([QueryKeyEnum.GLC_DETAILS, selectedGlc.id], null);

      isArchivedView ? setLastArchivedSelectedGlcId(undefined) : setLastActiveSelectedGlcId(undefined);

      return setSelectedGlcId(undefined);
    }

    setSelectedGlcId(glc.id);

    isArchivedView ? setLastArchivedSelectedGlcId(glc.id) : setLastActiveSelectedGlcId(glc.id);

    queryClient.setQueryData([QueryKeyEnum.GLC_DETAILS, glc.id], glc);
    queryClient.invalidateQueries([QueryKeyEnum.GLC_DETAILS, glc.id]);
  };

  const resetSelectedGlc = () => {
    setLastActiveSelectedGlcId(undefined);
    setLastArchivedSelectedGlcId(undefined);
    setSelectedGlcId(undefined);
    setSelectedGlc(undefined);
  };

  return (
    <SelectedGlcContext.Provider
      value={{
        selectedGlcId,
        setSelectedGlcId,
        selectedGlc,
        setSelectedGlc,
        lastActiveSelectedGlcId,
        lastArchivedSelectedGlcId,
        setLastActiveSelectedGlcId,
        setLastArchivedSelectedGlcId,
        resetSelectedGlc,
      }}
    >
      {children}
    </SelectedGlcContext.Provider>
  );
};
