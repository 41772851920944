export enum QueryKeyEnum {
  SERVERS = 'SERVERS',
  TERMINALS = 'TERMINALS',
  CARDS = 'CARDS',
  CARD_HISTORY = 'CARD_HISTORY',
  CARD_DETAILS = 'CARD_DETAILS',
  CARD_SEARCH = 'CARD_SEARCH',
  OWNERS = 'OWNERS',
  OWNERS_SEARCH = 'OWNERS_SEARCH',
  OWNER_DETAILS = 'OWNER_DETAILS',
  OWNER_HISTORY = 'OWNER_HISTORY',
  OWNER_HISTORY_PHOTO_OLD = 'OWNER_HISTORY_PHOTO_OLD',
  OWNER_HISTORY_PHOTO_NEW = 'OWNER_HISTORY_PHOTO_NEW',
  RANDOM_CONTROL_OWNERS = 'RANDOM_CONTROL_OWNERS',
  EVENTS = 'EVENTS',
  CARDS_WITH_OWNERS = 'CARDS_WITH_OWNERS',
  POSITIONS = 'POSITIONS',
  POSITIONS_SEARCH = 'POSITIONS_SEARCH',
  EVENTS_EXPORT_FORMAT = 'EVENTS_EXPORT_FORMAT',
  EVENT_EXPORT_FORMAT_DETAILS = 'EVENT_EXPORT_FORMAT_DETAILS',
  EVENTS_EXPORT_FORMAT_CONFIG = 'EVENTS_EXPORT_FORMAT_CONFIG',
  ORGANIZATIONS = 'ORGANIZATIONS',
  ORGANIZATIONS_SEARCH = 'ORGANIZATIONS_SEARCH',
  OWNER_PHOTO = 'OWNER_PHOTO',
  USERS = 'USERS',
  OWNER_PERMISSIONS_EFFECTIVE_SERVERS = 'OWNER_PERMISSIONS_EFFECTIVE_SERVERS',
  OWNER_PERMISSIONS_INDIVIDUAL_SERVERS = 'OWNER_PERMISSIONS_INDIVIDUAL_SERVERS',
  OWNER_PERMISSIONS_INDIVIDUAL = 'OWNER_PERMISSIONS_INDIVIDUAL',
  OWNER_PERMISSIONS_EFFECTIVE = 'OWNER_PERMISSIONS_EFFECTIVE',
  OWNER_GROUPS = 'OWNER_GROUPS',
  OWNER_GROUPS_SEARCH = 'OWNER_GROUPS_SEARCH',
  GROUPS = 'GROUPS',
  GROUP_HISTORY = 'GROUP_HISTORY',
  GROUP_PERMISSIONS_HISTORY = 'GROUP_PERMISSIONS_HISTORY',
  GROUP_DETAILS = 'GROUP_DETAILS',
  GROUPS_SEARCH = 'GROUPS_SEARCH',
  GROUP_TERMINALS_PERMISSIONS_SERVERS = 'GROUP_TERMINALS_PERMISSIONS_SERVERS',
  GROUP_TERMINALS_PERMISSIONS = 'GROUP_TERMINALS_PERMISSIONS',
  GROUP_GLC_PERMISSIONS_SERVERS = 'GROUP_GLC_PERMISSIONS_SERVERS',
  GROUP_GLC_PERMISSIONS = 'GROUP_GLC_PERMISSIONS',
  GROUP_ASSIGNED_OWNERS = 'GROUP_ASSIGNED_OWNERS',
  INSTANT_CONTROL_OWNERS = 'INSTANT_CONTROL_OWNERS',
  INSTANT_CONTROL_TERMINALS = 'INSTANT_CONTROL_TERMINALS',
  RANDOM_CONTROL_TERMINALS = 'INSTANT_CONTROL_TERMINALS',
  GLC = 'GLC',
  GLC_HISTORY = 'GLC_HISTORY',
  GLC_DETAILS = 'GLC_DETAILS',
  GLC_SEARCH = 'GLC_SEARCH',
  GLC_TERMINALS = 'GLC_TERMINALS',
  APP_VERSION = 'APP_VERSION',
  LOGGED_USER_DETAILS = 'LOGGED_USER_DETAILS',
  SETTINGS = 'SETTINGS',
  OWNERS_CONTROL = 'OWNERS_CONTROL',
  TERMINALS_WITH_DISPLAY = 'TERMINALS_WITH_DISPLAY',
  CONTROL_HISTORY = 'CONTROL_HISTORY',
  WANTED = 'WANTED',
  WANTED_HISTORY = 'WANTED_HISTORY',
  WANTED_TERMINALS = 'WANTED_TERMINALS',
  ANNOUNCEMENTS = 'ANNOUNCEMENTS',
  ANNOUNCEMENTS_SEARCH = 'ANNOUNCEMENTS_SEARCH',
  ANNOUNCEMENT_DETAILS = 'ANNOUNCEMENT_DETAILS',
  ANNOUNCEMENTS_HISTORY = 'ANNOUNCEMENTS_HISTORY',
  ANNOUNCEMENT_MODS = 'ANNOUNCEMENT_MODS',
  ANNOUNCEMENT_MODES = 'ANNOUNCEMENT_MODES',
  ANNOUNCEMENT_ASSIGNED_OWNERS = 'ANNOUNCEMENT_ASSIGNED_OWNERS',
  LOGOTYPE = 'LOGOTYPE',
}

export enum DateFormat {
  FULL = 'yyyy/MM/dd HH:mm:ss',
  DATE = 'yyyy/MM/dd',
  TIME = 'HH:mm:ss',
}

export enum HistoryActionEnum {
  ZABLOKOWANO = 'ZABLOKOWANO',
  ODBLOKOWANO = 'ODBLOKOWANO',
  ZWROCONO = 'ZWROCONO',
  WYDANO = 'WYDANO',
  ZARCHIWIZOWANO = 'ZARCHIWIZOWANO',
  PRZYWROCONO = 'PRZYWROCONO',
  DODANO = 'DODANO',
  ZEDYTOWANO = 'ZEDYTOWANO',
  USUNIETO = 'USUNIETO',
}

export enum GroupHistoryActionEnum {
  ZARCHIWIZOWANO = 'ZARCHIWIZOWANO',
  PRZYWROCONO = 'PRZYWROCONO',
  DODANO = 'DODANO',
  ZEDYTOWANO = 'ZEDYTOWANO',
}
