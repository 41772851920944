import { useSelectedAnnouncement } from 'hooks/useSelectedAnnouncement/useSelectedAnnouncement';
import { useSelectedCard } from 'hooks/useSelectedCard/useSelectedCard';
import { useSelectedEvent } from 'hooks/useSelectedEvent/useSelectedEvent';
import { useSelectedGlc } from 'hooks/useSelectedGlc/useSelectedGlc';
import { useSelectedGroup } from 'hooks/useSelectedGroup/useSelectedGroup';
import { useSelectedGroupGlcPermission } from 'hooks/useSelectedGroupGlcPermission/useSelectedGroupGlcPermission';
import { useSelectedGroupTerminalPermission } from 'hooks/useSelectedGroupTerminalPermission/useSelectedGroupTerminalPermission';
import { useSelectedOrganization } from 'hooks/useSelectedOrganization/useSelectedOrganization';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { useSelectedOwnerIndividualPermission } from 'hooks/useSelectedOwnerIndividualPermission/useSelectedOwnerIndividualPermission';
import { useSelectedPosition } from 'hooks/useSelectedPosition/useSelectedPosition';
import { useSelectedServer } from 'hooks/useSelectedServer/useSelectedServer';
import { useSelectedTerminal } from 'hooks/useSelectedTerminal/useSelectedTerminal';
import { useSelectedUser } from 'hooks/useSelectedUser/useSelectedUser';

export const useResetSelected = () => {
  const { resetSelectedCard } = useSelectedCard();
  const { setSelectedEvent } = useSelectedEvent();
  const { resetSelectedGroup } = useSelectedGroup();
  const { setSelectedOrganization } = useSelectedOrganization();
  const { resetSelectedOwner } = useSelectedOwner();
  const { setSelectedOwnerIndividualPermission } = useSelectedOwnerIndividualPermission();
  const { setSelectedPosition } = useSelectedPosition();
  const { setSelectedServer } = useSelectedServer();
  const { setSelectedTerminal } = useSelectedTerminal();
  const { setSelectedUser } = useSelectedUser();
  const { setSelectedGlc } = useSelectedGlc();
  const { setSelectedGroupTerminalPermission } = useSelectedGroupTerminalPermission();
  const { setSelectedGroupGlcPermission } = useSelectedGroupGlcPermission();
  const { setSelectedAnnouncement } = useSelectedAnnouncement();

  const allSetSelectedHooks = [
    resetSelectedCard,
    setSelectedEvent,
    resetSelectedGroup,
    setSelectedOrganization,
    resetSelectedOwner,
    setSelectedOwnerIndividualPermission,
    setSelectedPosition,
    setSelectedServer,
    setSelectedTerminal,
    setSelectedUser,
    setSelectedGlc,
    setSelectedGroupTerminalPermission,
    setSelectedGroupGlcPermission,
    setSelectedAnnouncement,
  ];

  const resetAllSelected = () => {
    allSetSelectedHooks.forEach((setSelectedHook) => setSelectedHook && setSelectedHook(undefined));
  };

  return { resetAllSelected };
};
