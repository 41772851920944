import { Controller, FieldValues } from 'react-hook-form';
import { Select } from 'shared-ui';

import { ControlledSelectProps } from './ControlledSelect.types';

export const ControlledSelect = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  ...props
}: ControlledSelectProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => <Select {...field} {...props} ref={field.ref} />}
    />
  );
};
