import { InputLabel } from '@mui/material';
import styled, { css } from 'styled-components';
import { Button } from 'shared-ui';

import { ReactComponent as PhotoIconBase } from 'assets/icons/picture.svg';

const photoAreaSize = 206;

export const PhotoWrapper = styled.div`
  grid-column: 3/4;
  grid-row: 2/5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PhotoLabel = styled(InputLabel)`
  margin-bottom: 11px;
  font-weight: 700;
  width: ${photoAreaSize}px;
`;

export const PhotoArea = styled.div(
  ({ theme: { palette } }) => css`
    width: ${photoAreaSize}px;
    height: ${photoAreaSize}px;
    background-color: ${palette.grey[100]};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  `,
);

export const UploadedPhoto = styled.img`
  width: 100%;
  height: 100%;
`;

export const PhotoIcon = styled(PhotoIconBase)`
  margin-bottom: 12px;
`;

export const AddImageButton = styled(Button)`
  width: ${photoAreaSize}px;
  margin-top: 16px;
`;

export const ActionSelectLabel = styled(InputLabel)`
  transform: translate(14.5px, 14px) scale(1);
`;
