import { useState } from 'react';
import { Popover } from 'shared-ui';

import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import {
  AnnouncementAssignedOwnersQueryKeysEnum,
  QueryKeyLocalStorageEnum,
} from 'context/query/queryContext/QueryContext.enum';
import * as Styled from '../AnnouncementAssignedOwnersTableHeader.styles';

import { PositionAssignedOwnersHeaderItemProps } from './PositionAssignedOwnersHeaderItem.types';
import { PositionPopupAutocompleteContainer } from './positionPopupAutocomplete/PositionPopupAutocompleteContainer';

export const PositionAssignedOwnersHeaderItem = ({ children }: PositionAssignedOwnersHeaderItemProps) => {
  const [headerFilterEl, setHeaderFilterEl] = useState<HTMLDivElement | undefined>(undefined);

  const {
    storedQueries: { [QueryKeyLocalStorageEnum.ANNOUNCEMENT_ASSIGNED_OWNERS]: announcementAssignedOwnersQuery },
    setQuery: setOwnersQuery,
  } = useLocaleStoreQuery();

  return (
    <Styled.HeaderItem>
      <Styled.HeaderItemSortable $isActive={!!headerFilterEl} onClick={(e) => setHeaderFilterEl(e.currentTarget)}>
        {children}
        {!!announcementAssignedOwnersQuery?.stanowisko?.length ? (
          <RemoveIcon
            onClick={(e) => {
              e.stopPropagation();
              setOwnersQuery(QueryKeyLocalStorageEnum.GROUP_ASSIGNED_OWNERS, {
                ...announcementAssignedOwnersQuery,
                [AnnouncementAssignedOwnersQueryKeysEnum.STANOWISKO]: [],
              });
            }}
          />
        ) : (
          <DropdownIcon />
        )}
      </Styled.HeaderItemSortable>

      {headerFilterEl && (
        <Popover anchorEl={headerFilterEl} onClose={() => setHeaderFilterEl(undefined)}>
          <PositionPopupAutocompleteContainer />
        </Popover>
      )}
    </Styled.HeaderItem>
  );
};
