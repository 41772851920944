import styled, { css } from 'styled-components';

import { ReactComponent as AcceptIconBase } from 'assets/icons/accept-primary.svg';
import { ReactComponent as RealoadIconBase } from 'assets/icons/reload-primary.svg';

import { IconStylesProps } from './DevicesMultiActions.types';

export const Container = styled.div`
  margin-top: 16px;
`;

export const AcceptIcon = styled(AcceptIconBase)<IconStylesProps>(
  ({ disabled }) => css`
    opacity: ${disabled ? 0.5 : 1};
  `,
);

export const RealoadIcon = styled(RealoadIconBase)<IconStylesProps>(
  ({ disabled }) => css`
    opacity: ${disabled ? 0.5 : 1};
  `,
);
