import { FilterOptionsState } from '@mui/material';
import { Option } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useQuery } from 'hooks/useQuery/useQuery';
import { GroupTerminalsPermissionsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { fetchAllTerminals, fetchTerminals } from 'api/terminals/terminals';
import * as Styled from '../TableSearch.styles';

export const useSearchByTerminals = (optionsQuery: string, isEnabled: boolean, serverId?: string) => {
  const { formatMessage } = useLocale();

  const { data: terminalsResponse, isInitialLoading } = useQuery(
    [QueryKeyEnum.GLC_SEARCH, optionsQuery],
    fetchAllTerminals,
    {
      args: {
        zaakceptowane: true,
        sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
      },
      enabled: isEnabled && !serverId,
    },
  );

  const { data: terminalsRcpResponse, isInitialLoading: isLoadingTerminals } = useQuery(
    [QueryKeyEnum.TERMINALS, serverId],
    () => fetchTerminals(serverId),
    {
      args: {
        zaakceptowane: true,
        sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
      },
      enabled: !!serverId,
    },
  );

  const terminals = (serverId ? terminalsRcpResponse?.data : terminalsResponse?.data) || [];

  const createOptions = () => {
    return terminals.map((terminal) => ({
      value: terminal.id,
      label: terminal.nazwa,
      id: terminal.numerLogicznyKontrolera,
    }));
  };

  const filterOptions = (options: Option[], { inputValue }: FilterOptionsState<Option>) => {
    const standarizedInputValue = inputValue.toLowerCase();

    return options.filter(
      ({ label, value, ...rest }) =>
        label.toLowerCase().includes(standarizedInputValue) ||
        `${value}`.toLowerCase().includes(standarizedInputValue) ||
        `${rest?.id}`.toLowerCase().includes(standarizedInputValue),
    );
  };

  const createOptionLabel = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <li {...optionProps}>
        <Styled.OptionNumber>
          {formatMessage({ id: AppMessages['common.id'] })}{' '}
          {terminals.find((glc) => glc.id === option.value)?.numerLogicznyKontrolera}
        </Styled.OptionNumber>

        <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
      </li>
    );
  };

  return { isLoading: isInitialLoading || isLoadingTerminals, createOptions, createOptionLabel, filterOptions };
};
