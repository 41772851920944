import { useQuery } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { fetchLogotype } from 'api/logotype/logotype';

import { Logo } from './Logo';

export const LogoContainer = () => {
  const { data: logo, isError } = useQuery([QueryKeyEnum.LOGOTYPE], fetchLogotype, { handleErrorManually: true });

  return <Logo logo={!isError ? logo : undefined} />;
};
