import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { deleteMultiRandomControl } from 'api/control/control';

import { DeleteMultiRandomControl } from './DeleteMultiRandomControl';
import { DeleteMultiRandomControlContainerProps } from './DeleteMultiRandomControl.types';

export const DeleteMultiRandomControlContainer = ({
  checkedOwners,
  setCheckedOwners,
}: DeleteMultiRandomControlContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteMultiOwners, isLoading } = useMutation(deleteMultiRandomControl, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.RANDOM_CONTROL_OWNERS]);
      toast.dark(formatMessage({ id: AppMessages['control.random.multiAction.delete.success'] }));
      setCheckedOwners([]);
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteMultiOwners({ body: checkedOwners.map(({ id }) => id) });
  };

  return (
    <DeleteMultiRandomControl
      checkedOwners={checkedOwners}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
