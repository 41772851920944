import { Typography, ListItem as MaterialListItem } from '@mui/material';
import styled, { css } from 'styled-components';
import { hexWithOpacity } from 'shared-ui';
import { CloseGrayIcon } from 'shared-ui';

import { ReactComponent as ArrowRightIconBase } from 'assets/icons/arrow-right-card-history.svg';
import { ReactComponent as EmptyPictureIconBase } from 'assets/icons/empty-picture.svg';

const EXPANDABLE_ITEM_RIGHT_SPACE = 50;

export const ListItem = styled(MaterialListItem)(
  ({ theme: { palette, shape } }) => css`
    background-color: ${palette.common.white};
    border-radius: ${shape.borderRadius}px;
    margin-bottom: 8px;
    padding: 0px;
    flex-wrap: wrap;
  `,
);

export const ListItemHeaderWrapper = styled.div(
  ({ theme: { palette } }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 24px;
    border-bottom: 2px solid ${hexWithOpacity(palette.grey[900], 0.15)};
  `,
);

export const FiltersWrapper = styled.div`
  margin-top: 16px;
`;

export const Filters = styled.div<{ $gridTemplateColumns?: string }>(
  ({ $gridTemplateColumns }) => css`
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: ${$gridTemplateColumns ? $gridTemplateColumns : '1fr 1fr 1fr 100px'};
    gap: 16px;
  `,
);

export const ClearFiltersButtonWrapper = styled(Typography)`
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  justify-self: flex-end;
  grid-column: auto/-1;
`;

export const ClearFiltersIcon = styled(CloseGrayIcon)`
  margin-left: 10px;
  margin-bottom: 4px;
`;

export const BoldText = styled(Typography)`
  font-weight: 700;
`;

export const ListItemBodyWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
`;

export const HistoryItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 170px auto;
  gap: 24px;
`;

export const FieldTitle = styled(Typography)`
  font-weight: 700;
`;

export const ModifiedFieldTitle = styled(Typography)`
  font-weight: 700;
  align-self: center;
`;

export const ModifiedItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ModifiedLongItemWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const TextItem = styled(Typography)`
  padding-left: 5px;
`;

const fieldBaseStyles = css(
  ({
    theme: {
      palette,
      shape: { borderRadius },
    },
  }) => css`
    background-color: ${palette.grey['A400']};
    border-radius: ${borderRadius}px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    min-height: 48px;
  `,
);

export const ModifiedItem = styled(Typography)`
  ${fieldBaseStyles}
  min-width: 184px;
`;

export const ModifiedOldItem = styled(Typography)(
  ({ theme: { palette } }) => css`
    ${fieldBaseStyles}
    color: ${palette.grey[400]};
    min-width: 184px;
  `,
);

export const ModifiedLongItem = styled.div`
  ${fieldBaseStyles}
  width: 100%;
`;

export const ModifiedLongOldItem = styled(Typography)(
  ({ theme: { palette } }) => css`
    ${fieldBaseStyles}
    color: ${palette.grey[400]};
    width: 100%;
  `,
);

export const ArrowRightIcon = styled(ArrowRightIconBase)`
  position: absolute;
  left: -40px;
  top: 0;
`;

export const ModifiedLongNewItemWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const ModifiedPhotoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ModifiedPhotoItem = styled.span<{ $image?: string; $faded?: boolean }>(
  ({ $image, $faded }) => css`
    ${fieldBaseStyles}
    width: 96px;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(${$image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: ${$faded ? 0.5 : 1};
  `,
);

export const EmptyPictureIcon = styled(EmptyPictureIconBase)`
  opacity: 0.4;
`;

export const HistoryListDetailsLoaderWrapper = styled.div(
  ({ theme: { palette } }) => css`
    position: sticky;
    top: 0;
    right: 0;
    width: 400px;
    background-color: ${palette.common.white};
    height: 100vh;
  `,
);

export const PinDot = styled.span(
  ({ theme: { palette } }) => css`
    display: inline-block;
    background-color: ${palette.grey[400]};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 7px;
  `,
);

export const ActionListContainer = styled.div<{ maxListHeight: number }>(
  ({ maxListHeight }) => css`
    max-height: ${maxListHeight}px;
    overflow: auto;
  `,
);

export const ArrowRightWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ExpandablePermissionsList = styled.ul<{ maxListHeight: number }>(
  ({ maxListHeight }) => css`
    padding-left: 0;
    max-height: ${maxListHeight}px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 12px 0px;
  `,
);

export const ExpandablePermissionsListItem = styled.li`
  grid-column: 2/3;
`;

export const PermissionEntryContainer = styled.li`
  display: grid;
  grid-template-columns: 170px auto;
  align-items: center;
  justify-content: right;
  margin-right: ${EXPANDABLE_ITEM_RIGHT_SPACE}px;
`;

export const PermissionEntryTitle = styled(Typography)`
  display: flex;
  align-items: center;
`;

export const PermissionEntryField = styled.div`
  ${fieldBaseStyles}
  min-width: 280px;
  padding-left: 3px;
`;

export const ExpandableItemFieldWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const ExpandableArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${EXPANDABLE_ITEM_RIGHT_SPACE}px;
`;

export const ExpandableBody = styled.ul`
  display: grid;
  gap: 12px;
  margin-top: 12px;
`;
