import { useLocale } from 'hooks/useLocale/useLocale';
import { Table } from 'ui/table/Table';
import { AppMessages } from 'i18n/messages';
import {
  QueryKeyLocalStorageEnum,
  RandomControlTerminalsIdSortEnum,
  RandomControlTerminalsNameSortEnum,
} from 'context/query/queryContext/QueryContext.enum';

import { RandomControlTerminalsRowsProps } from './RandomControlTerminalsRows.types';

export const RandomControlTerminalsRows = ({
  terminalsResponse,
  setCheckedTerminals,
  checkedTerminals,
}: RandomControlTerminalsRowsProps) => {
  const { formatMessage } = useLocale();

  return (
    <Table
      gridTemplateColumns="200px 1fr"
      data={terminalsResponse?.data || []}
      nothingToShowText={formatMessage({ id: AppMessages['control.random.terminals.nothingToShow'] })}
      checkboxSelection={{
        checked: checkedTerminals,
        toggleChecked: setCheckedTerminals,
        withoutCheckedElementsBar: true,
      }}
      customTableHeight={() => undefined}
      renderHeader={() => (
        <>
          <Table.HeaderSortItem
            queryKey={QueryKeyLocalStorageEnum.RANDOM_CONTROL_TERMINALS}
            sortBy={RandomControlTerminalsIdSortEnum}
            queryHeader={formatMessage({ id: AppMessages['control.random.terminals.table.header.id'] })}
          />

          <Table.HeaderSortItem
            queryKey={QueryKeyLocalStorageEnum.RANDOM_CONTROL_TERMINALS}
            sortBy={RandomControlTerminalsNameSortEnum}
            queryHeader={formatMessage({ id: AppMessages['control.random.terminals.table.header.readerName'] })}
          />
        </>
      )}
      renderRow={(terminal) => (
        <>
          <Table.RowItem>{terminal.numerLogicznyKontrolera}</Table.RowItem>

          <Table.RowItem>{terminal.nazwa}</Table.RowItem>
        </>
      )}
    />
  );
};
