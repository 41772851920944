import { Controller, useFormContext } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { AddUserFormBody } from '../AddUser.types';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { Form } from 'ui/form/Form';

export const AddUserBasicDataForm = () => {
  const { formatMessage } = useLocale();

  const {
    control,
    formState: { errors },
  } = useFormContext<AddUserFormBody>();

  const { userStatusOptions, userTypeOptions } = useAppSelectOptions();

  return (
    <>
      <Controller
        name="username"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            required
            label={formatMessage({ id: AppMessages['users.addUser.field.name.label'] })}
            placeholder={formatMessage({ id: AppMessages['users.addUser.field.name.placeholder'] })}
            errorMessage={errors?.username?.message}
            autoComplete="new-password"
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            required
            label={formatMessage({ id: AppMessages['users.addUser.field.password.label'] })}
            placeholder={formatMessage({ id: AppMessages['users.addUser.field.password.placeholder'] })}
            errorMessage={errors?.password?.message}
            type="password"
            autoComplete="new-password"
          />
        )}
      />

      <Form.ControlledSelect
        control={control}
        name="status"
        required
        label={formatMessage({ id: AppMessages['users.addUser.field.status.label'] })}
        withHelperText
        errorMessage={errors?.status?.message}
        placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
        options={userStatusOptions}
      />

      <Controller
        name="passwordConfirmation"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            required
            label={formatMessage({ id: AppMessages['users.addUser.field.passwordConfirmation.label'] })}
            placeholder={formatMessage({ id: AppMessages['users.addUser.field.passwordConfirmation.placeholder'] })}
            errorMessage={errors?.passwordConfirmation?.message}
            type="password"
          />
        )}
      />

      <Form.ControlledSelect
        control={control}
        name="typ"
        required
        label={formatMessage({ id: AppMessages['users.addUser.field.type.label'] })}
        withHelperText
        errorMessage={errors?.typ?.message}
        placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
        options={userTypeOptions}
      />
    </>
  );
};
