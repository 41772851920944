import { Controller, useFormContext } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';
import { BlockCardBody } from 'api/cards/cards.types';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import { ActionButton } from 'ui/actionButton/ActionButton';
import { ActionModal } from 'ui/actionModal/ActionModal';

import { BlockCardProps } from './BlockCard.types';

export const BlockCard = ({
  selectedCard,
  onSubmit,
  onModalClose,
  onModalOpen,
  isModalOpen,
  isLoading,
}: BlockCardProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useFormContext<BlockCardBody>();

  return (
    <>
      <ActionButton type="block" onClick={onModalOpen} />

      <ActionModal
        header={formatMessage({ id: AppMessages['card.block.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={isLoading || !isValid}
        submitButtonText={formatMessage({ id: AppMessages['card.block.actionButton'] })}
      >
        <>
          <ActionModal.Subtitle>
            {formatMessage({ id: AppMessages['card.block.subtitle'] })} {selectedCard.numerKarty}
          </ActionModal.Subtitle>

          <Controller
            name="powodBlokady"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={formatMessage({ id: AppMessages['card.block.reason.label'] })}
                placeholder={formatMessage({ id: AppMessages['card.block.reason.placeholder'] })}
                required
              />
            )}
          />
        </>
      </ActionModal>
    </>
  );
};
