import { MutationFn, UseQueryFn } from 'shared-ui';

import { PaginatedResponse } from 'core/global.types';

import {
  AddEventExportFormatBody,
  EditEventExportFormatBody,
  EventExportFormat,
  EventsExportFormatConfig,
  PreviewEventExportFormatResponse,
} from './eventsExportFormats.types';

export const fetchEventsExportFormats: UseQueryFn<unknown, PaginatedResponse<EventExportFormat>> = () => {
  return {
    endpoint: `/zdarzenia/export/format`,
  };
};

export const fetchEventExportFormatDetails: UseQueryFn<string, EventExportFormat> = (id) => {
  return {
    endpoint: `/zdarzenia/export/format/${id}`,
  };
};

export const addEventExportFormat: MutationFn<AddEventExportFormatBody, void> = (body) => {
  return {
    endpoint: `/zdarzenia/export/format`,
    method: 'POST',
    body,
  };
};

export const editEventExportFormat: MutationFn<EditEventExportFormatBody, void> = ({ id, body }) => {
  return {
    endpoint: `/zdarzenia/export/format/${id}`,
    method: 'PUT',
    body,
  };
};

export const deleteEventExportFormat: MutationFn<string, void> = (id) => {
  return {
    endpoint: `/zdarzenia/export/format/${id}`,
    method: 'DELETE',
  };
};

export const previewEventExportFormat: MutationFn<AddEventExportFormatBody, PreviewEventExportFormatResponse> = (
  body,
) => {
  return {
    endpoint: `/zdarzenia/export/podglad`,
    method: 'POST',
    body,
  };
};

export const fetchEventsExportFormatConfig: UseQueryFn<unknown, EventsExportFormatConfig> = () => {
  return {
    endpoint: `/zdarzenia/settings`,
  };
};
