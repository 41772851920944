import { Controller, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Datepicker } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { getEndOfToday } from 'helpers/getEndOfToday';
import { AddEventExportFormatPageModeEnum } from 'app/definitions/eventsExportFormats/addEventExportFormat/AddEventExportFormat.enum';
import { AppRoute, AppRouteWithParams } from 'routing/AppRoute.enum';
import { useExportEventForm } from 'hooks/useExportEventForm/useExportEventForm';
import { ExportEventsBody } from 'api/events/events.types';
import { useIsExactPath } from 'hooks/useIsExactPath/useIsExactPath';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { Form } from 'ui/form/Form';

import { ExportEventsProps } from './ExportEvents.types';
import * as Styled from './ExportEvents.styles';
import { ServerOptions } from './ExportEvents.enum';

export const ExportEvents = ({
  onSubmit,
  isModalOpen,
  onModalOpen,
  onModalClose,
  isLoading,
  servers,
  eventsExportFormats,
  maxDate,
}: ExportEventsProps) => {
  const { formatMessage } = useLocale();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useFormContext<ExportEventsBody>();
  const navigate = useNavigate();
  const { setExportEventFormState } = useExportEventForm();
  const isHistoryView = useIsExactPath(AppRoute.eventsHistory);

  const selectedExportFormat = watch('formatId');

  const { serverOptions, eventsExportFormatsOptions } = useAppSelectOptions({ servers, eventsExportFormats });

  return (
    <>
      <Button variant="outlined" color="primary" onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['events.exportEvents.title'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['events.exportEvents.title'] })}
        open={isModalOpen}
        onClose={isLoading ? () => null : onModalClose}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid || isLoading}
        cancelButtonDisabled={isLoading}
        submitButtonText={formatMessage({ id: AppMessages['events.exportEvents.submitButton'] })}
      >
        <>
          <Form.Grid>
            <Form.ControlledSelect
              name="nazwaRcp"
              control={control}
              required
              withHelperText
              disabled={isLoading}
              placeholder={formatMessage({ id: AppMessages['events.exportEvents.server.placeholder'] })}
              label={formatMessage({ id: AppMessages['events.exportEvents.server.label'] })}
              options={[
                ...serverOptions,
                {
                  value: ServerOptions.EMPTY,
                  label: formatMessage({ id: AppMessages['events.exportEvents.server.option.empty'] }),
                },
              ]}
            />

            <Styled.FormatWrapper>
              <Form.ControlledSelect
                name="formatId"
                control={control}
                required
                withHelperText
                disabled={isLoading}
                placeholder={formatMessage({ id: AppMessages['events.exportEvents.fileFormat.placeholder'] })}
                label={formatMessage({ id: AppMessages['events.exportEvents.fileFormat.label'] })}
                options={eventsExportFormatsOptions}
              />

              <Styled.FormatDetailsButton
                onClick={() => {
                  setExportEventFormState(watch());
                  navigate(
                    AppRouteWithParams.eventsExportFormats.get({
                      id: selectedExportFormat,
                      mode: AddEventExportFormatPageModeEnum.PREVIEW,
                    }),
                    { state: { isRedirectFromExportEvents: true, isHistoryView } },
                  );
                }}
                disabled={!selectedExportFormat || isLoading}
                data-testid="redirect-button"
              >
                <ArrowRightIcon />
              </Styled.FormatDetailsButton>
            </Styled.FormatWrapper>

            <Controller
              name="dataOd"
              control={control}
              render={({ field }) => (
                <Datepicker
                  {...field}
                  required
                  disabled={isLoading}
                  label={formatMessage({ id: AppMessages['events.exportEvents.dateFrom.label'] })}
                  withCalendarIcon
                  withDatepickerPopover
                  isClickable={!isLoading}
                  errorMessage={errors?.dataOd?.message}
                  maxDate={maxDate}
                  popoverOrigin={{
                    vertical: -80,
                    horizontal: 60,
                  }}
                />
              )}
            />

            <Controller
              name="dataDo"
              control={control}
              render={({ field }) => (
                <Datepicker
                  {...field}
                  required
                  disabled={isLoading}
                  label={formatMessage({ id: AppMessages['events.exportEvents.dateTo.label'] })}
                  minDate={watch('dataOd')}
                  withCalendarIcon
                  withDatepickerPopover
                  isClickable={!isLoading}
                  maxDate={maxDate}
                  errorMessage={errors?.dataDo?.message}
                  popoverOrigin={{
                    vertical: -80,
                    horizontal: 60,
                  }}
                  customDateFormatOnOpen={getEndOfToday}
                />
              )}
            />
          </Form.Grid>
        </>
      </Modal>
    </>
  );
};
