import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePagination } from 'shared-ui';

import { fetchServersForGroupTerminalsPermissions } from 'api/servers/servers';
import { fetchGroupDetails } from 'api/groups/groups';
import { QueryKeyEnum } from 'core/global.enum';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useSelectedGroup } from 'hooks/useSelectedGroup/useSelectedGroup';
import { useQuery } from 'hooks/useQuery/useQuery';

import { GroupTerminalsPermissions } from './GroupTerminalsPermissions';

export const GroupTerminalsPermissionsContainer = () => {
  const { id: groupId } = useParams<{ id: string }>();
  const { selectedGroup } = useSelectedGroup();
  const {
    storedQueries: { groupTerminalsPermissions: groupTerminalsPermissionsQuery },
    setQuery,
  } = useLocaleStoreQuery();

  const [permissionsCount, setPermissionsCount] = useState(0);
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();

  const { data: serversResponse, isLoading } = useQuery(
    [QueryKeyEnum.GROUP_TERMINALS_PERMISSIONS_SERVERS, groupId],
    () => fetchServersForGroupTerminalsPermissions(groupId),
  );

  const { data: groupDetails } = useQuery([QueryKeyEnum.GROUP_DETAILS, groupId], () => fetchGroupDetails(groupId), {
    enabled: !selectedGroup,
  });

  useEffect(() => {
    setQuery(QueryKeyLocalStorageEnum.GROUP_TERMINALS_PERMISSIONS, {
      ...groupTerminalsPermissionsQuery,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    });
  }, [page, rowsPerPage]);

  return (
    <GroupTerminalsPermissions
      servers={serversResponse?.data}
      isLoading={isLoading}
      permissionsCount={permissionsCount}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      groupName={selectedGroup?.nazwa || groupDetails?.nazwa || ''}
      setPermissionsCount={setPermissionsCount}
    />
  );
};
