import { ClientError, APIError, MutationFn, UseQueryFn } from 'shared-ui';

import { LoginBody, LoginResponse, LoginErrorResponse, AppVersionResponse } from './auth.types';

export const onLogin: MutationFn<LoginBody, LoginResponse, ClientError<APIError<LoginErrorResponse>>> = (body) => {
  return {
    endpoint: '/auth/login',
    method: 'POST',
    body,
  };
};

export const getAppVersion: UseQueryFn<void, AppVersionResponse> = () => {
  return {
    endpoint: `/version`,
  };
};
