import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { User } from 'api/users/users.types';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

import { OwnerPersonalDetails } from './UserPersonalDetails';
import { UserPersonalDetailsContainerProps, UserPersonalDetailsForm } from './UserPersonalDetails.types';
import { useUserPersonalDetailsValidation } from './UserPersonalDetails.validation';

export const UserPersonalDetailsContainer = ({
  isLoadingUpdateUser,
  selectedUser,
  onUpdateUserSubmit,
  errorFields,
}: UserPersonalDetailsContainerProps) => {
  const { formatMessage } = useLocale();
  const { schema } = useUserPersonalDetailsValidation();

  const resetToSelected = (selected: User) => {
    return {
      imie: selected.imie || '',
      nazwisko: selected.nazwisko || '',
      email: selected.email || '',
      opis: selected.opis || '',
    };
  };

  const formMethods = useForm<UserPersonalDetailsForm>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: resetToSelected(selectedUser),
  });

  useEffect(() => {
    formMethods.reset(resetToSelected(selectedUser));
  }, [selectedUser]);

  useEffect(() => {
    const isEmailExistsError = errorFields?.find(({ type, field }) => type === 'exists' && field === 'email');

    if (isEmailExistsError) {
      return formMethods.setError('email', {
        message: formatMessage({ id: AppMessages['user.details.personal.email.existsValidation'] }),
      });
    }
  }, [errorFields]);

  const onSubmit = (body: UserPersonalDetailsForm) => {
    onUpdateUserSubmit({
      ...selectedUser,
      ...body,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <OwnerPersonalDetails
        isLoadingUpdateUser={isLoadingUpdateUser}
        selectedUser={selectedUser}
        onSubmit={onSubmit}
        resetToSelected={resetToSelected}
      />
    </FormProvider>
  );
};
