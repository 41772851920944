import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { ServerActions } from './serverActions/ServerActions';
import { ServerDetailsProps } from './ServerDetails.types';
import * as Styled from './ServerDetails.styles';

export const ServerDetails = ({ selectedServer }: ServerDetailsProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <ServerActions selectedServer={selectedServer} />

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['devices.details.name'] })}</Styled.DetailName>
        <Styled.DetailText> {selectedServer.nazwa} </Styled.DetailText>
      </Styled.DetailsItemWrapper>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['devices.details.addressIP'] })}</Styled.DetailName>
        <Styled.DetailText> {selectedServer.host} </Styled.DetailText>
      </Styled.DetailsItemWrapper>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['devices.details.port'] })}</Styled.DetailName>
        <Styled.DetailText> {selectedServer.port} </Styled.DetailText>
      </Styled.DetailsItemWrapper>

      <Styled.DetailsItemWrapper>
        <Styled.DetailName>{formatMessage({ id: AppMessages['devices.details.timeout'] })}</Styled.DetailName>
        <Styled.DetailText> {selectedServer.timeout.toFixed(0)} ms</Styled.DetailText>
      </Styled.DetailsItemWrapper>
    </>
  );
};
