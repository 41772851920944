import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ClientError, APIError, useErrorHandler, useQueryClient, useLoaderToast } from 'shared-ui';

import { useQuery } from 'hooks/useQuery/useQuery';
import { useModalState } from 'hooks/useModalState/useModalState';
import { QueryKeyEnum } from 'core/global.enum';
import { fetchTerminals } from 'api/terminals/terminals';
import { fetchServers } from 'api/servers/servers';
import { GroupTerminalsPermissionsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { useMutation } from 'hooks/useMutation/useMutation';
import { addGlc } from 'api/glc/glc';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

import { useAddGlcValidation } from './AddGlc.validation';
import { AddGlcFormBody } from './AddGlc.types';
import { AddGlc } from './AddGlc';

export const AddGlcContainer = () => {
  const { formatMessage } = useLocale();
  const { handleError } = useErrorHandler();
  const queryClient = useQueryClient();
  const [terminalsQuery] = useState('');

  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { load: loadAddGlc, update: updateLoadAddGlc, close: closeLoadAddGlc } = useLoaderToast('addGroup');

  const { schema } = useAddGlcValidation();
  const formMethods = useForm<AddGlcFormBody>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const { serwerId } = formMethods.watch();

  const { mutate: onAddGlc, isLoading: isLoadingAddGlc } = useMutation(addGlc, {
    onMutate: () => loadAddGlc(formatMessage({ id: AppMessages['glc.addGlc.loader'] })),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.GLC]);
      queryClient.invalidateQueries([QueryKeyEnum.TERMINALS, serwerId, terminalsQuery]);
      onModalClose();

      updateLoadAddGlc(
        formatMessage({
          id: AppMessages['glc.addGlc.successMessage'],
        }),
      );
    },
    onError: (error: ClientError<APIError>) => {
      const errorFields = error.response?.data.fields;

      const isGlcIdExistsError = errorFields?.find(({ type, field }) => type === 'exists' && field === 'idGlc');

      if (isGlcIdExistsError) {
        closeLoadAddGlc();
        return formMethods.setError('idGlc', {
          message: formatMessage({ id: AppMessages['glc.addGlc.field.validation.id'] }),
        });
      }

      handleError({ error, updateToastCallback: updateLoadAddGlc });
    },
  });

  const { data: serversResponse } = useQuery([QueryKeyEnum.SERVERS], fetchServers);
  const servers = serversResponse?.data || [];

  useEffect(() => {
    servers.length && formMethods.reset({ serwerId: servers[0].id });
  }, [servers]);

  const { data: terminalsResponse, isInitialLoading: isLoadingTerminals } = useQuery(
    [QueryKeyEnum.TERMINALS, serwerId, terminalsQuery],
    () => fetchTerminals(serwerId),
    {
      args: {
        zaakceptowane: true,
        sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
        withoutDirectGroupPermission: true,
        withoutGlc: true,
      },
      enabled: !!serwerId,
    },
  );

  const onSubmit = (body: AddGlcFormBody) => {
    onAddGlc({
      ...body,
      tkds: body.tkds ? body.tkds.map(({ value }) => ({ id: value as string })) : undefined,
    });
  };

  useEffect(() => {
    formMethods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...formMethods}>
      <AddGlc
        isModalOpen={isModalOpen}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
        onSubmit={onSubmit}
        isLoadingAddGlc={isLoadingAddGlc}
        servers={servers}
        terminals={terminalsResponse?.data || []}
        isLoadingTerminals={isLoadingTerminals}
      />
    </FormProvider>
  );
};
