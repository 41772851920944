import { useParams } from 'react-router-dom';

import { fetchOwnerIndividualPermissions } from 'api/permissions/permissions';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useOwnerQueryKeys } from 'hooks/useOwnerQueryKeys/useOwnerQueryKeys';

import { OwnerIndividualTerminalsPermissions } from './OwnerIndividualTerminalsPermissions';
import { OwnerIndividualTerminalsPermissionsContainerProps } from './OwnerIndividualTerminalsPermissions.types';

export const OwnerIndividualTerminalsPermissionsContainer = ({
  server,
  expandedServer,
  checkedPermissions,
  setCheckedPermissions,
  setPermissionsCount,
}: OwnerIndividualTerminalsPermissionsContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const ownerId = id || '';
  const { ownerPermissionsIndividualQueryKey } = useOwnerQueryKeys();

  const {
    storedQueries: { [ownerPermissionsIndividualQueryKey]: ownerPermissionsIndividualQuery },
  } = useLocaleStoreQuery();

  const { data: permissionsResponse } = useQuery(
    [QueryKeyEnum.OWNER_PERMISSIONS_INDIVIDUAL, ownerId, expandedServer?.id, ownerPermissionsIndividualQuery],
    () => fetchOwnerIndividualPermissions({ ownerId, serverId: expandedServer?.id || server.id }),
    {
      args: ownerPermissionsIndividualQuery,
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setPermissionsCount(response.count);
      },
    },
  );

  return (
    <OwnerIndividualTerminalsPermissions
      server={server}
      permissionsResponse={permissionsResponse}
      checkedPermissions={checkedPermissions}
      setCheckedPermissions={setCheckedPermissions}
    />
  );
};
