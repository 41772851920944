import { useState } from 'react';
import { toast } from 'react-toastify';
import { FileWithPath } from 'react-dropzone';
import { useErrorHandler, APIError, useQueryClient, ClientError, useLoaderToast } from 'shared-ui';

import {
  addOwnersWithPhotos,
  addOwnersWithPhotosImportFile,
  addOwnersWithPhotosImportPhotos,
  addOwnersWithPhotosValidateFileWithPhotos,
} from 'api/owners/owners';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { QueryKeyEnum } from 'core/global.enum';
import { ValidateOwnersImportFileErrorResponse } from 'api/owners/owners.types';

import { AddOwnerImportWithPhoto } from './AddOwnerImportWithPhoto';
import { AddOwnerImportWithPhotoContainerProps } from './AddOwnerImportWithPhoto.types';

export const AddOwnerImportWithPhotoContainer = ({
  isModalOpen,
  onModalClose,
  onClickBackButton,
}: AddOwnerImportWithPhotoContainerProps) => {
  const { formatMessage } = useLocale();
  const queryClient = useQueryClient();

  const { load: loadAddOwnerWithPhotos, close: closeAddOwnersWithPhotos } = useLoaderToast('addOwnerWithPhotos');
  const { handleError } = useErrorHandler();

  const [file, setFile] = useState<FileWithPath>();
  const [photos, setPhotos] = useState<FileWithPath[]>();
  const [fileError, setFileError] = useState<string>();
  const [photosError, setPhotosError] = useState<string>();
  const [importFileName, setImportFileName] = useState<string>();
  const [downloadLink, setDownloadLink] = useState('');
  const [filesNotMatch, setFilesNotMatch] = useState(false);

  const handleFileErrors = ({ response }: ClientError<APIError<ValidateOwnersImportFileErrorResponse>>) => {
    if (response?.data.type === 'pkds_limit') {
      setFileError(response?.data?.error);
      return;
    }

    createDownloadLink(response?.data?.message);
  };

  const { mutate: validateFileWithPhotos, isLoading: isLoadingValidateFileWithPhotos } = useMutation(
    addOwnersWithPhotosValidateFileWithPhotos,
    {
      onSuccess: ({ filesNotMatch, bledyImportu }) => {
        setFilesNotMatch(filesNotMatch);

        if (bledyImportu) {
          createDownloadLink(bledyImportu);
        } else {
          setDownloadLink('');
          setFilesNotMatch(false);
        }
      },
      onError: (error: unknown) => handleError({ error }),
    },
  );

  const { mutate: onAddFile, isLoading: isLoadingAddFile } = useMutation(addOwnersWithPhotosImportFile, {
    onSuccess: (response, file) => {
      setFile(file);
      setImportFileName(response.importFileName);
    },
    onError: handleFileErrors,
  });

  const { mutate: onAddPhotos, isLoading: isLoadingAddPhotos } = useMutation(addOwnersWithPhotosImportPhotos, {
    onSuccess: (_response, photos) => {
      setPhotos(photos);
      importFileName && validateFileWithPhotos(importFileName);
    },
    onError: (error: unknown) => handleError({ error }),
  });

  const { mutate: onAddOwnersWithPhotos, isLoading: isLoadingAddOwnersWithPhotos } = useMutation(addOwnersWithPhotos, {
    onMutate: () =>
      loadAddOwnerWithPhotos(formatMessage({ id: AppMessages['owners.addOwner.importWithPhoto.loadingText'] })),
    onSuccess: ({ liczbaDoDodania, liczbaDodanych, liczbaDoModyfikacji, liczbaZmodyfikowanych, bledyImportu }) => {
      if (!(liczbaDoDodania === 0 && liczbaDodanych === 0)) {
        toast.dark(
          formatMessage(
            { id: AppMessages['owners.addOwner.import.importFile.successMessage'] },
            {
              addedNumber: liczbaDodanych,
              fromNumber: liczbaDoDodania,
            },
          ),
        );
      }

      if (!(liczbaDoModyfikacji === 0 && liczbaZmodyfikowanych === 0)) {
        toast.dark(
          formatMessage(
            { id: AppMessages['owners.addOwner.import.importFile.modifiedMessage'] },
            {
              modifiedNumber: liczbaZmodyfikowanych,
              fromNumber: liczbaDoModyfikacji,
            },
          ),
        );
      }

      queryClient.invalidateQueries([QueryKeyEnum.OWNERS]);

      if (bledyImportu) {
        createDownloadLink(bledyImportu);
      } else {
        onModalClose();
        setDownloadLink('');
        setFilesNotMatch(false);
      }
    },
    onError: (error: unknown) => handleError({ error }),
    onSettled: () => closeAddOwnersWithPhotos(),
  });

  const createDownloadLink = (uploadErrors?: string) => {
    if (!uploadErrors) return;

    const data = new Blob([uploadErrors], { type: 'text/plain' });

    if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink);

    setDownloadLink(window.URL.createObjectURL(data));
  };

  const onSubmit = () => {
    if (!importFileName) return;

    onAddOwnersWithPhotos(importFileName);
  };

  return (
    <AddOwnerImportWithPhoto
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onClickBackButton={onClickBackButton}
      onAddFile={onAddFile}
      onAddPhotos={onAddPhotos}
      isLoadingAddFile={isLoadingAddFile}
      isLoadingAddPhotos={isLoadingAddPhotos || isLoadingValidateFileWithPhotos}
      file={file}
      setFile={setFile}
      photos={photos}
      setPhotos={setPhotos}
      fileError={fileError}
      setFileError={setFileError}
      photosError={photosError}
      setPhotosError={setPhotosError}
      downloadLink={downloadLink}
      setDownloadLink={setDownloadLink}
      filesNotMatch={filesNotMatch}
      setFilesNotMatch={setFilesNotMatch}
      onSubmit={onSubmit}
      isLoadingAddOwnersWithPhotos={isLoadingAddOwnersWithPhotos}
    />
  );
};
