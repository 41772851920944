import { useSelectedOwnerIndividualPermission } from 'hooks/useSelectedOwnerIndividualPermission/useSelectedOwnerIndividualPermission';

import { DeleteOwnerPermissionContainer } from './deleteOwnerIndividualPermission/DeleteOwnerIndividualPermissionContainer';
import * as Styled from './OwnerIndividualPermissionActions.styles';

export const OwnerIndividualPermissionActions = () => {
  const { selectedOwnerIndividualPermission } = useSelectedOwnerIndividualPermission();

  return (
    <Styled.ActionButtonWrapper>
      {selectedOwnerIndividualPermission && (
        <DeleteOwnerPermissionContainer selectedPermission={selectedOwnerIndividualPermission} />
      )}
    </Styled.ActionButtonWrapper>
  );
};
