import { Modal, Dropzone } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ACCEPTED_IMPORT_FILE_TYPES, MAX_IMPORT_FILES, MAX_IMPORT_FILE_SIZE_BYTES } from 'core/global.variables';
import { useOnDropRejected } from 'hooks/useOnDropRejected/useOnDropRejected';
import { DownloadExampleFile } from 'ui/downloadExampleFile/DownloadExampleFile';

import { AddOwnerImportProps } from './AddOwnerImport.types';

export const AddOwnerImport = ({
  file,
  setFile,
  setFileError,
  fileError,
  isModalOpen,
  onModalClose,
  onClickBackButton,
  onAddFile,
  isLoadingAddFile,
  downloadLink,
}: AddOwnerImportProps) => {
  const { formatMessage } = useLocale();
  const { onDropRejected } = useOnDropRejected(setFileError);

  return (
    <Modal
      header={formatMessage({ id: AppMessages['owners.addOwner.title'] })}
      submitButtonText={formatMessage({ id: AppMessages['common.add'] })}
      onClose={onModalClose}
      open={isModalOpen}
      size="medium"
      type="form"
      onSubmit={onAddFile}
      submitButtonDisabled={!file || !!downloadLink || !!fileError}
      withBackButton
      onClickBackButton={onClickBackButton}
    >
      <div data-testid="add-owner-import">
        <Dropzone
          file={file}
          onClickRemoveButton={() => {
            setFile(undefined);
          }}
          dropzoneDescription={formatMessage({
            id: AppMessages['owners.addOwner.import.importFile.description'],
          })}
          isLoading={isLoadingAddFile}
          error={
            downloadLink ? formatMessage({ id: AppMessages['owners.addOwner.import.error.downloadReport'] }) : fileError
          }
          addedFileSubtext={file && `${(file.size / 1000000).toFixed(2)} MB / 15 MB`}
          downloadLink={downloadLink}
          rootDropzoneProps={{
            multiple: false,
            maxFiles: MAX_IMPORT_FILES,
            maxSize: MAX_IMPORT_FILE_SIZE_BYTES,
            accept: ACCEPTED_IMPORT_FILE_TYPES,
            onDrop: (acceptedFiles, rejectedFiles) => {
              if (rejectedFiles.length) return;

              setFileError(undefined);
              setFile(acceptedFiles[0]);
            },
            onDropRejected: onDropRejected,
          }}
          title={formatMessage({ id: AppMessages['owners.addOwner.import.importFile'] })}
          titleAddonEnd={<DownloadExampleFile exampleType="owners" />}
        />
      </div>
    </Modal>
  );
};
