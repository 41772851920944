import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedGroupGlcPermission } from 'hooks/useSelectedGroupGlcPermission/useSelectedGroupGlcPermission';
import { deleteMultiGroupGlcPermissions } from 'api/permissions/permissions';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { DeleteGroupGlcPermissions } from './DeleteGroupGlcPermissions';
import { DeleteGroupGlcPermissionsContainerProps } from './DeleteGroupGlcPermissions.types';

export const DeleteGroupGlcPermissionsContainer = ({
  checkedPermissions,
  setCheckedPermissions,
  groupId,
}: DeleteGroupGlcPermissionsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedGroupGlcPermission, setSelectedGroupGlcPermission } = useSelectedGroupGlcPermission();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteMultiGroupGlcPermissions, isLoading } = useMutation(deleteMultiGroupGlcPermissions, {
    onSuccess: () => {
      if (selectedGroupGlcPermission && checkedPermissions.some(({ id }) => id === selectedGroupGlcPermission?.id)) {
        setSelectedGroupGlcPermission(undefined);
      }

      queryClient.invalidateQueries([QueryKeyEnum.GROUP_GLC_PERMISSIONS_SERVERS]);
      queryClient.invalidateQueries([QueryKeyEnum.GROUP_GLC_PERMISSIONS]);
      toast.dark(formatMessage({ id: AppMessages['permissions.multiAction.delete.success'] }));
      setCheckedPermissions([]);
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteMultiGroupGlcPermissions({
      id: groupId,
      body: { idPermissions: checkedPermissions.map(({ id }) => id) },
    });
  };

  return (
    <DeleteGroupGlcPermissions
      checkedPermissions={checkedPermissions}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
