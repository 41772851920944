import { Controller, useFormContext } from 'react-hook-form';
import { Button, isJustLetters } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';
import * as Styled from '../UserDetails.styles';

import { UserPersonalDetailsProps, UserPersonalDetailsForm } from './UserPersonalDetails.types';

export const OwnerPersonalDetails = ({
  isLoadingUpdateUser,
  selectedUser,
  onSubmit,
  resetToSelected,
}: UserPersonalDetailsProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    reset,

    formState: { isDirty, isValid, errors },
  } = useFormContext<UserPersonalDetailsForm>();

  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)}>
      <Styled.FieldsWrapper>
        <Controller
          name="imie"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              onChange={(e) => {
                if (!isJustLetters(e.target.value)) return;

                field.onChange(e);
              }}
              value={field.value || ''}
              placeholder={formatMessage({ id: AppMessages['user.details.personal.name.placeholder'] })}
              label={formatMessage({ id: AppMessages['user.details.personal.name'] })}
              errorMessage={errors?.imie?.message}
            />
          )}
        />
        <Controller
          name="nazwisko"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              onChange={(e) => {
                if (!isJustLetters(e.target.value)) return;

                field.onChange(e);
              }}
              value={field.value || ''}
              placeholder={formatMessage({ id: AppMessages['user.details.personal.lastName.placeholder'] })}
              label={formatMessage({ id: AppMessages['user.details.personal.lastName'] })}
              errorMessage={errors?.nazwisko?.message}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              value={field.value || ''}
              placeholder={formatMessage({ id: AppMessages['user.details.personal.email.placeholder'] })}
              label={formatMessage({ id: AppMessages['user.details.personal.email'] })}
              errorMessage={errors?.email?.message}
            />
          )}
        />

        <Controller
          name="opis"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              placeholder={formatMessage({ id: AppMessages['user.details.personal.description.placeholder'] })}
              label={formatMessage({ id: AppMessages['user.details.personal.description'] })}
              multiline
              inputProps={{ spellCheck: false }}
              maxRows={4}
              errorMessage={errors?.opis?.message}
            />
          )}
        />
      </Styled.FieldsWrapper>

      {isDirty && (
        <Styled.ButtonsWrapper>
          <Button
            size="small"
            onClick={() => {
              reset(resetToSelected(selectedUser));
            }}
            color="primary"
          >
            {formatMessage({ id: AppMessages['owner.details.button.cancel'] })}
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={isLoadingUpdateUser}
            size="small"
            variant="contained"
            color="primary"
          >
            {formatMessage({ id: AppMessages['owner.details.button.save'] })}
          </Button>
        </Styled.ButtonsWrapper>
      )}
    </Styled.Form>
  );
};
