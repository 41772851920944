import { ReactComponent as AddIcon } from 'assets/icons/add-active.svg';
import { useSelectedOrganization } from 'hooks/useSelectedOrganization/useSelectedOrganization';
import { EXPAND_ORGANIZATION_ITEM_TAGS, MAX_TREE_LEVEL } from '../Organization.variables';
import { useModalState } from 'hooks/useModalState/useModalState';

import { OrganizationItemProps } from './OrganizationItem.types';
import * as Styled from './OrganizationItem.styles';
import { AddOrganizationItemContainer } from './addOrganizationItem/AddOrganizationItemContainer';

export const OrganizationItem = ({ mainOrganization, organization, level = 1 }: OrganizationItemProps) => {
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { selectedOrganization, setSelectedOrganization } = useSelectedOrganization();

  const isAddOrganizationItemAvailable = level < MAX_TREE_LEVEL;

  return (
    <>
      <Styled.TreeItem
        onClick={(e) => {
          if (!EXPAND_ORGANIZATION_ITEM_TAGS.includes((e.target as HTMLElement)?.tagName)) {
            setSelectedOrganization(selectedOrganization?.id === organization.id ? undefined : organization);
          }
        }}
        key={organization.id}
        nodeId={organization.id}
        label={
          <Styled.LabelWrapper>
            <Styled.TextWrapper>
              <Styled.Code>{organization.kod}</Styled.Code>
              <Styled.Name>{organization.nazwa}</Styled.Name>
            </Styled.TextWrapper>
            {isAddOrganizationItemAvailable && (
              <Styled.AddButton
                data-testid={`add-element-${organization.kod}`}
                onClick={(e) => {
                  e.stopPropagation();
                  onModalOpen();
                }}
              >
                <AddIcon />
              </Styled.AddButton>
            )}
          </Styled.LabelWrapper>
        }
      >
        {Array.isArray(organization.children)
          ? organization.children.map((node) => (
              <OrganizationItem
                mainOrganization={mainOrganization}
                key={node.id}
                level={1 + level}
                organization={node}
              />
            ))
          : null}
      </Styled.TreeItem>

      {isAddOrganizationItemAvailable && (
        <AddOrganizationItemContainer
          mainOrganization={mainOrganization}
          organization={organization}
          isModalOpen={isModalOpen}
          onModalClose={onModalClose}
        />
      )}
    </>
  );
};
