import { MutationFn, UseQueryFn } from 'shared-ui';

import { UsersQuery } from 'context/query/queryContext/QueryContext.types';
import { PaginatedResponse } from 'core/global.types';

import { AddUserBody, ChangePasswordBody, ChangePasswordWithConfirmationBody, User } from './users.types';

export const fetchUsers: UseQueryFn<UsersQuery, PaginatedResponse<User>> = () => {
  return {
    endpoint: `/user`,
  };
};

export const updateUserDetails: MutationFn<{ id: string; body: User }, User> = ({ id, body }) => {
  return {
    endpoint: `/user/${id}`,
    method: 'PUT',
    body,
  };
};

export const addUser: MutationFn<AddUserBody, User> = (body) => {
  return {
    endpoint: `/user`,
    method: 'POST',
    body,
  };
};

export const changeUserPassword: MutationFn<{ username: string; body: ChangePasswordBody }, User> = ({
  username,
  body,
}) => {
  return {
    endpoint: `/user/${username}/password/noconfirm`,
    method: 'PUT',
    body,
  };
};

export const changeUserPasswordWithConfirmation: MutationFn<
  { username: string; body: ChangePasswordWithConfirmationBody },
  void
> = ({ username, body }) => {
  return {
    endpoint: `/user/${username}/password`,
    method: 'PUT',
    body,
  };
};

export const fetchLoggedUserDetails: UseQueryFn<void, User> = () => {
  return {
    endpoint: `/me`,
  };
};
