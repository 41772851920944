import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const DetailsItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.75fr 1fr;
  align-items: center;
  margin-bottom: 24px;
`;

export const DetailName = styled(Typography)(
  ({ theme: { palette } }) => css`
    font-weight: 700;
    color: ${palette.text.secondary};
    margin-right: 20px;
  `,
);

export const DetailText = styled(Typography)(
  ({ theme: { palette } }) => css`
    color: ${palette.grey[700]};
    word-break: break-all;
  `,
);

export const DetailTextStrong = styled(Typography)(
  ({ theme: { palette } }) => css`
    color: ${palette.grey[900]};
    font-weight: 700;
    text-transform: uppercase;
  `,
);

export const DetailTextWithBackground = styled(Typography)(
  ({ theme: { palette, shape } }) => css`
    display: inline-block;
    background-color: ${palette.grey[100]};
    color: ${palette.grey[900]};
    border-radius: ${shape.borderRadius}px;
    padding: 6px 0;
    font-weight: 700;
    min-width: 40px;
    text-align: center;
  `,
);

export const DescriptionText = styled(Typography)(
  ({ theme: { palette } }) => css`
    color: ${palette.grey[700]};
    margin-top: 6px;
    margin-bottom: 24px;
  `,
);

export const ActionButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
`;
