import { useEffect } from 'react';
import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';

import { editMultiOwners } from 'api/owners/owners';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { EditMultiOwners } from './EditMultiOwners';
import { EditMultiOwnersContainerProps, EditMultiOwnersForm } from './EditMultiOwners.types';
import { RcpOptionsEnum } from './EditMultiOwners.enum';

export const EditMultiOwnersContainer = ({ checkedOwners }: EditMultiOwnersContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedOwner, setSelectedOwner } = useSelectedOwner();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onEditMultiOwners, isLoading } = useMutation(editMultiOwners, {
    onSuccess: (_, body) => {
      if (selectedOwner && checkedOwners.some(({ id }) => id === selectedOwner?.id)) {
        setSelectedOwner({ ...selectedOwner, ...body });
      }

      queryClient.invalidateQueries([QueryKeyEnum.OWNERS]);
      toast.dark(formatMessage({ id: AppMessages['owners.multiAction.edit.success'] }));
      onModalClose();
    },
  });

  const formMethods = useForm<EditMultiOwnersForm>({
    mode: 'all',
  });

  useEffect(() => {
    formMethods.reset();
  }, [isModalOpen]);

  const onSubmit = (formBody: EditMultiOwnersForm) => {
    const rcp = formBody.rcp === RcpOptionsEnum.TRUE ? true : formBody.rcp === RcpOptionsEnum.FALSE ? false : undefined;

    onEditMultiOwners({ pkds: checkedOwners.map(({ id }) => id), ...formBody, rcp });
  };

  return (
    <FormProvider {...formMethods}>
      <EditMultiOwners
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
};
