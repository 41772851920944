import { useNavigate } from 'react-router';
import { DoubleTextElipsisWithTooltip, ProblemIconWithTooltip, useObserver } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';
import { AppRouteWithParams } from 'routing/AppRoute.enum';

import { RandomControlTableRowProps } from './RandomControlTableRow.types';
import { DeleteRandomControlContainer } from './deleteRandomControl/DeleteRandomControlContainer';
import * as Styled from './RandomControlTableRow.styles';

export const RandomControlTableRow = ({ owner }: RandomControlTableRowProps) => {
  const { formatMessage } = useLocale();
  const { tableWidth } = useObserver();
  const navigate = useNavigate();

  return (
    <>
      <Table.RowItem>{owner.skdId}</Table.RowItem>

      <Table.RowItem>
        <DoubleTextElipsisWithTooltip
          fullText={`${owner.nazwisko} ${owner.imie}`}
          firstLineText={owner?.imie}
          secondLineText={owner?.nazwisko}
          width={tableWidth}
        />
      </Table.RowItem>

      <Table.RowItem>
        <ProblemIconWithTooltip
          isError={owner.problemTkd}
          tooltip={formatMessage({ id: AppMessages['control.notUpdated.info'] })}
        />
      </Table.RowItem>

      <Table.RowCellsItem cells={owner.komorka} />

      <Table.RowItem>{owner.nrEwidencyjny}</Table.RowItem>

      <Table.RowItem>
        <Styled.Wrapper>
          <Styled.TerminalsIcon
            onClick={() => navigate(AppRouteWithParams.controlRandomReaders.get({ id: owner.id }))}
          />
          <DeleteRandomControlContainer owner={owner} />
        </Styled.Wrapper>
      </Table.RowItem>
    </>
  );
};
