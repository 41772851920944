import { IconButton as MaterialIconButton } from '@mui/material';
import styled, { css } from 'styled-components';
import { Button } from 'shared-ui';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ActionButtonsWrapper = styled.div`
  width: 600px;
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

export const ActionButton = styled(Button)`
  width: auto;
  height: auto;
  min-width: 70px;
  font-size: 12px;
  padding: 6px 12px;
`;

export const IconButton = styled(MaterialIconButton)`
  position: absolute;
  right: 5px;
`;

export const DatePickersWrapper = styled.div`
  display: flex;
`;

export const DatePickerWrapper = styled.div(
  ({ theme: { palette } }) => css`
    & .MuiPickerStaticWrapper-content {
      background-color: ${palette.common.white};
    }
  `,
);
