import styled, { css } from 'styled-components';

import { ReactComponent as YesIconBase } from 'assets/icons/yes.svg';
import { ReactComponent as NoIconBase } from 'assets/icons/no.svg';

const iconStyles = css`
  display: flex;
  align-items: center;
`;

export const YesIcon = styled(YesIconBase)`
  ${iconStyles}
`;

export const NoIcon = styled(NoIconBase)`
  ${iconStyles}
`;
