import { fetchEventsExportFormats } from 'api/eventsExportFormat/eventsExportFormats';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';

import { EventsExportFormats } from './EventsExportFormats';

export const EventsExportFormatsContainer = () => {
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.EVENTS_EXPORT_FORMAT]: eventsExportFormatQuery },
  } = useLocaleStoreQuery();

  const { data: eventsExportFormatsResponse, isLoading } = useQuery(
    [QueryKeyEnum.EVENTS_EXPORT_FORMAT, eventsExportFormatQuery],
    () => fetchEventsExportFormats(),
    { args: eventsExportFormatQuery },
  );

  return <EventsExportFormats eventsExportFormatsResponse={eventsExportFormatsResponse?.data} isLoading={isLoading} />;
};
