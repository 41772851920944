import { Typography } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 50%;
`;

export const GroupName = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 24px;
`;
