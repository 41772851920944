import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

import { ServerStatusProps } from './ServerStatus.types';

const statusBackgroundColor = (status: ServerStatusProps['status']) => {
  return {
    POLACZONY: '#C0ECC3',
    ODLACZONY: '#E8E8E8',
    AWARIA: '#ECC0C0',
  }[status];
};

const statusTextColor = (status: ServerStatusProps['status']) => {
  return {
    POLACZONY: '#00950B',
    ODLACZONY: '#495171',
    AWARIA: '#DB4949',
  }[status];
};

export const Container = styled.div<ServerStatusProps>(
  ({ status, theme: { shape } }) => css`
    background-color: ${statusBackgroundColor(status)};
    width: 115px;
    height: 24px;
    border-radius: ${shape.borderRadius}px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
);

export const Text = styled(Typography)<ServerStatusProps>(
  ({ status }) => css`
    color: ${statusTextColor(status)};
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 0px;
  `,
);
