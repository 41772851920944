import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { useSelectedAnnouncement } from 'hooks/useSelectedAnnouncement/useSelectedAnnouncement';
import { deleteMultiAnnouncements } from 'api/announcements/announcements';

import { DeleteMultiAnnouncements } from './DeleteMultiAnnouncements';
import { DeleteMultiAnnouncementsContainerProps } from './DeleteMultiAnnouncements.types';

export const DeleteMultiAnnouncementsContainer = ({
  checkedAnnouncements,
  setCheckedAnnouncements,
}: DeleteMultiAnnouncementsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedAnnouncement, setSelectedAnnouncement } = useSelectedAnnouncement();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onDeleteMultiAnnouncements, isLoading } = useMutation(deleteMultiAnnouncements, {
    onSuccess: () => {
      if (selectedAnnouncement && checkedAnnouncements.some(({ id }) => id === selectedAnnouncement?.id)) {
        setSelectedAnnouncement(undefined);
      }

      queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENTS]);
      queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENTS_SEARCH]);
      toast.dark(formatMessage({ id: AppMessages['announcements.multiAction.delete.successMessage'] }));
      setCheckedAnnouncements([]);
      onModalClose();
    },
  });

  const onSubmit = () => {
    onDeleteMultiAnnouncements({ body: checkedAnnouncements.map(({ id }) => id) });
  };

  return (
    <DeleteMultiAnnouncements
      checkedAnnouncements={checkedAnnouncements}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
