import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { QueryKeyLocalStorageEnum, EventExportFormatNameSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { Table } from 'ui/table/Table';

import * as Styled from './EventsExportFormatsTableHeader.styles';

export const EventsExportFormatsTableHeader = () => {
  const { formatMessage } = useLocale();

  return (
    <Styled.HeaderWrapper>
      <Table.HeaderSortItem
        queryHeader={formatMessage({ id: AppMessages['definitions.events.header.name'] })}
        queryKey={QueryKeyLocalStorageEnum.EVENTS_EXPORT_FORMAT}
        sortBy={EventExportFormatNameSortEnum}
      />
    </Styled.HeaderWrapper>
  );
};
