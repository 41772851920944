import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useGroupsArchivedView } from 'hooks/useGroupsArchivedView/useGroupsArchivedView';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';

export const useLocaleStoreGroupsQuery = () => {
  const { isGroupsArchivedView } = useGroupsArchivedView();

  const groupsQueryLocalStorageName = isGroupsArchivedView
    ? QueryKeyLocalStorageEnum.ARCHIVED_GROUPS
    : QueryKeyLocalStorageEnum.GROUPS;

  const {
    storedQueries: { [groupsQueryLocalStorageName]: groupsQuery },
    setQuery,
  } = useLocaleStoreQuery();

  const setGroupsQuery = (updatedGroupsQuery: Partial<typeof groupsQuery>) => {
    setQuery(groupsQueryLocalStorageName, {
      ...groupsQuery,
      ...updatedGroupsQuery,
    });
  };

  return {
    setGroupsQuery,
    groupsQuery,
    groupsQueryLocalStorageName: groupsQueryLocalStorageName as QueryKeyLocalStorageEnum.GROUPS,
  };
};
