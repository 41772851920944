import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const AutocompleteWrapper = styled.div`
  width: 50%;
  padding-right: 25px;
`;

export const AutocompleteLabel = styled(Typography)`
  margin-bottom: 13px;
`;

export const SeparatingLine = styled.div(
  ({ theme: { palette } }) => css`
    height: 2px;
    background-color: ${palette.grey[100]};
    margin-bottom: 24px;
    margin-top: 35px;
  `,
);
