import { useEffect } from 'react';
import { ClientError, APIError, useQueryClient, useErrorHandler } from 'shared-ui';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { copyGroup } from 'api/groups/groups';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useSelectedGroup } from 'hooks/useSelectedGroup/useSelectedGroup';
import { useModalState } from 'hooks/useModalState/useModalState';

import { CopyGroup } from './CopyGroup';
import { CopyGroupContainerProps, CopyGroupFormBody } from './CopyGroup.types';
import { useCopyGroupValidation } from './CopyGroup.validation';

export const CopyGroupContainer = ({ selectedGroup }: CopyGroupContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { setSelectedGroup } = useSelectedGroup();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { schema } = useCopyGroupValidation();
  const { handleError } = useErrorHandler();

  const { mutate: onCopyGroup, isLoading } = useMutation(copyGroup, {
    onSuccess: () => {
      setSelectedGroup(undefined);
      queryClient.invalidateQueries([QueryKeyEnum.GROUPS]);
      toast.dark(formatMessage({ id: AppMessages['group.copy.success'] }));
      onModalClose();
    },
    onError: (error: ClientError<APIError>) => {
      const errorFields = error.response?.data.fields;

      const isIdExistsError = errorFields?.find(({ type, field }) => type === 'exists' && field === 'idGrupy');

      if (isIdExistsError) {
        return methods.setError('idGrupy', { message: formatMessage({ id: AppMessages['group.copy.error.exists'] }) });
      }

      handleError({ error, omitAPIErrorMessage: true });
    },
  });

  const methods = useForm<CopyGroupFormBody>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: { idGrupy: '', nazwa: '' },
  });

  useEffect(() => {
    methods.reset({ idGrupy: '', nazwa: '' });
  }, [isModalOpen, selectedGroup]);

  const onSubmit = (formBody: CopyGroupFormBody) => {
    onCopyGroup({ body: { oldId: selectedGroup.id, ...formBody } });
  };

  return (
    <FormProvider {...methods}>
      <CopyGroup
        selectedGroup={selectedGroup}
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
};
