import { Controller, useFormContext } from 'react-hook-form';
import { Button, Autocomplete } from 'shared-ui';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ActionModal } from 'ui/actionModal/ActionModal';
import { Input } from 'ui/input/Input';
import { FormAutocompleteContainer } from 'reusable/formAutocomplete/FormAutocompleteContainer';
import { QueryKeyEnum } from 'core/global.enum';
import { fetchPositions } from 'api/positions/positions';
import { PositionNameSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';
import * as Styled from '../OwnersMultiActions.styles';

import { RcpOptionsDescriptionEnum, RcpOptionsEnum } from './EditMultiOwners.enum';
import { EditMultiOwnersForm, EditMultiOwnersProps } from './EditMultiOwners.types';

export const EditMultiOwners = ({
  isModalOpen,
  onSubmit,
  isLoading,
  onModalClose,
  onModalOpen,
}: EditMultiOwnersProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useFormContext<EditMultiOwnersForm>();

  const formValues = watch();
  const isValueSelected = Object.values(formValues).some((value) => (Array.isArray(value) ? !!value.length : !!value));

  const rcpOptions = Object.values(RcpOptionsEnum).map((option) => ({
    label: formatMessage({ id: RcpOptionsDescriptionEnum[option] }),
    value: option,
  }));

  const rcpValue = (value: RcpOptionsEnum) => ({
    label: formatMessage({ id: RcpOptionsDescriptionEnum[value] }),
    value,
  });

  return (
    <>
      <Button variant="outlined" color="primary" size="medium" startIcon={<EditIcon />} onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['owners.multiAction.edit.button'] })}
      </Button>

      <ActionModal
        header={formatMessage({ id: AppMessages['owners.multiAction.edit.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        size="medium"
        onSubmit={handleSubmit(onSubmit)}
        isLoading={isLoading}
        submitButtonDisabled={!isValueSelected || isLoading}
        submitButtonText={formatMessage({ id: AppMessages['owners.multiAction.edit.submitButton'] })}
      >
        <Styled.EditMultiOwnersWrapper>
          <Controller
            control={control}
            name="komorka"
            render={({ field }) => (
              <OrganizationsTreeSelect
                {...field}
                multiple
                placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
                label={formatMessage({ id: AppMessages['owners.multiAction.edit.cell'] })}
              />
            )}
          />

          <FormAutocompleteContainer
            formFieldName="stanowisko"
            queryKey={QueryKeyEnum.POSITIONS}
            queryFunction={fetchPositions}
            createOption={(position) => ({
              label: position.nazwa,
              value: position.id,
            })}
            handleOptionSelect={(option) => ({
              nazwa: option.label,
              id: option.value,
            })}
            args={{
              sort: [PositionNameSortEnum.ASC],
            }}
            label={formatMessage({ id: AppMessages['owners.multiAction.edit.field.position'] })}
            placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
          />

          <Controller
            name="rcp"
            control={control}
            defaultValue={undefined}
            render={({ field }) => (
              <Autocomplete
                {...field}
                onChange={(_e, option) => {
                  option ? field.onChange(option.value) : field.onChange(null);
                }}
                value={field.value ? rcpValue(field.value) : null}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={rcpOptions}
                renderOption={(props, option) =>
                  option.value ? (
                    <li {...props} key={option.value}>
                      <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
                    </li>
                  ) : null
                }
                placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
                label={formatMessage({ id: AppMessages['owners.multiAction.edit.field.rcp'] })}
              />
            )}
          />

          <Controller
            control={control}
            name="firma"
            render={({ field }) => (
              <OrganizationsTreeSelect
                {...field}
                label={formatMessage({ id: AppMessages['owners.multiAction.edit.field.company'] })}
                placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
              />
            )}
          />

          <Controller
            name="opis"
            control={control}
            render={({ field }) => (
              <Styled.OwnersDescriptionWrapper>
                <Input
                  {...field}
                  value={field.value || ''}
                  placeholder={formatMessage({
                    id: AppMessages['owners.multiAction.edit.field.description.placeholder'],
                  })}
                  label={formatMessage({ id: AppMessages['owners.multiAction.edit.field.description'] })}
                  errorMessage={errors?.opis?.message}
                  withoutHelperText
                />
              </Styled.OwnersDescriptionWrapper>
            )}
          />
        </Styled.EditMultiOwnersWrapper>
      </ActionModal>
    </>
  );
};
