import { useState } from 'react';

import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';

export const useControlHistorySearchQuery = () => {
  const {
    storedQueries: { controlHistory: controlHistoryQuery },
  } = useLocaleStoreQuery();

  const [tkdSearchQuery, setTkdSearchQuery] = useState(controlHistoryQuery?.tkd?.label || '');
  const [executorSearchQuery, setExecutorSearchQuery] = useState(controlHistoryQuery?.query?.label || '');
  const [ownerSearchQuery, setOwnerSearchQuery] = useState(controlHistoryQuery?.posiadacz?.label || '');

  const clearSearchQueries = () => {
    setTkdSearchQuery('');
    setExecutorSearchQuery('');
    setOwnerSearchQuery('');
  };

  return {
    tkdSearchQuery,
    setTkdSearchQuery,
    executorSearchQuery,
    setExecutorSearchQuery,
    ownerSearchQuery,
    setOwnerSearchQuery,
    clearSearchQueries,
  };
};
