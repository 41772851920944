import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePagination } from 'shared-ui';

import { fetchServersForOwnerEffectivePermissions } from 'api/servers/servers';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useOwnerQueryKeys } from 'hooks/useOwnerQueryKeys/useOwnerQueryKeys';

import { OwnerEffectivePermissions } from './OwnerEffectivePermissions';

export const OwnerEffectivePermissionsContainer = () => {
  const { id: ownerId } = useParams<{ id: string }>();
  const { ownerPermissionsEffectiveQueryKey } = useOwnerQueryKeys();
  const {
    storedQueries: { [ownerPermissionsEffectiveQueryKey]: ownerPermissionsEffectiveQuery },
    setQuery: setOwnerPermissionsQuery,
  } = useLocaleStoreQuery();

  const [permissionsCount, setPermissionsCount] = useState(0);
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination();

  const { data: serversResponse, isLoading } = useQuery(
    [QueryKeyEnum.OWNER_PERMISSIONS_EFFECTIVE_SERVERS, ownerId, ownerPermissionsEffectiveQuery],
    () => fetchServersForOwnerEffectivePermissions(ownerId),
    {
      omitErrorCodes: [404],
    },
  );

  useEffect(() => {
    setOwnerPermissionsQuery(ownerPermissionsEffectiveQueryKey, {
      ...ownerPermissionsEffectiveQuery,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    });
  }, [page, rowsPerPage]);

  const { limit, offset, ...query } = ownerPermissionsEffectiveQuery;
  useEffect(() => {
    setPage(0);
  }, [JSON.stringify(query)]);

  return (
    <OwnerEffectivePermissions
      servers={serversResponse?.data || []}
      isLoading={isLoading}
      permissionsCount={permissionsCount}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      setPermissionsCount={setPermissionsCount}
    />
  );
};
