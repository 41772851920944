import { useEffect, useState } from 'react';
import { useQueryClient } from 'shared-ui';

import { fetchTerminals } from 'api/terminals/terminals';
import { Server } from 'api/servers/servers.types';
import { QueryKeyEnum } from 'core/global.enum';
import { PaginatedResponse } from 'core/global.types';
import { useQuery } from 'hooks/useQuery/useQuery';
import {
  GlcNumberSortEnum,
  GlcQueryKeysEnum,
  GroupTerminalsPermissionsIdSortEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { fetchGlc } from 'api/glc/glc';

import { AddGroupPermissionsForm } from './AddGroupPermissionsForm';

export const AddGroupPermissionsFormContainer = () => {
  const queryClient = useQueryClient();
  const [selectedServerId, setSelectedServerId] = useState('');
  const [terminalsQuery] = useState('');

  const servers = queryClient.getQueryData<PaginatedResponse<Server>>([QueryKeyEnum.SERVERS])?.data || [];

  useEffect(() => {
    servers.length && setSelectedServerId(servers[0].id);
  }, [servers]);

  const { data: terminalsResponse, isInitialLoading: isLoadingTerminals } = useQuery(
    [QueryKeyEnum.TERMINALS, selectedServerId, terminalsQuery],
    () => fetchTerminals(selectedServerId),
    {
      args: {
        zaakceptowane: true,
        sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
        withoutGlc: true,
      },
      enabled: !!selectedServerId,
    },
  );

  const { data: glcResponse, isInitialLoading: isLoadingGlcGroups } = useQuery([QueryKeyEnum.GLC], fetchGlc, {
    args: {
      sort: [GlcNumberSortEnum.ASC],
      withoutDirectGroupPermission: true,
      [GlcQueryKeysEnum.ARCHIVED]: false,
      [GlcQueryKeysEnum.READER]: true,
    },
    enabled: !!selectedServerId,
  });

  return (
    <AddGroupPermissionsForm
      servers={servers}
      selectedServerId={selectedServerId}
      setSelectedServerId={setSelectedServerId}
      terminals={terminalsResponse?.data || []}
      isLoadingTerminals={isLoadingTerminals}
      glcGroups={glcResponse?.data || []}
      isLoadingGlcGroups={isLoadingGlcGroups}
    />
  );
};
