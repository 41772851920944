import * as Styled from './WantedTerminalsMultiActions.styles';
import { WantedTerminalsMultiActionsProps } from './WantedTerminalsMultiActions.types';
import { DeleteWantedTerminalsContainer } from './deleteWantedTerminals/DeleteWantedTerminalsContainer';

export const WantedTerminalsMultiActions = ({
  checkedTerminals,
  setCheckedTerminals,
  wantedId,
  terminalsCount,
}: WantedTerminalsMultiActionsProps) => {
  return (
    <Styled.Container>
      <DeleteWantedTerminalsContainer
        checkedTerminals={checkedTerminals}
        setCheckedTerminals={setCheckedTerminals}
        wantedId={wantedId}
        terminalsCount={terminalsCount}
      />
    </Styled.Container>
  );
};
