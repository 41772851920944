import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';

import { archiveMultiOwners } from 'api/owners/owners';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useSelectedOwner } from 'hooks/useSelectedOwner/useSelectedOwner';
import { useModalState } from 'hooks/useModalState/useModalState';
import { useMutation } from 'hooks/useMutation/useMutation';

import { ArchiveMultiOwners } from './ArchiveMultiOwners';
import { ArchiveMultiOwnersContainerProps } from './ArchiveMultiOwners.types';

export const ArchiveMultiOwnersContainer = ({ checkedOwners, setCheckedOwners }: ArchiveMultiOwnersContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { selectedOwner, setSelectedOwner, setLastActiveSelectedOwnerId } = useSelectedOwner();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();

  const { mutate: onArchiveMultiOwners, isLoading } = useMutation(archiveMultiOwners, {
    onSuccess: ({ okUuids }, { body }) => {
      if (selectedOwner && checkedOwners.some(({ id }) => id === selectedOwner?.id)) {
        setSelectedOwner(undefined);
        setLastActiveSelectedOwnerId(undefined);
      }
      queryClient.invalidateQueries([QueryKeyEnum.OWNERS]);
      setCheckedOwners([]);
      onModalClose();

      const archived = okUuids.length;
      const total = body.length;

      if (archived !== total) {
        toast.dark(
          formatMessage({ id: AppMessages['owners.multiAction.archive.successPartial'] }, { archived, total }),
        );
        toast.dark(formatMessage({ id: AppMessages['owners.multiAction.archive.assigned'] }));
        return;
      }

      toast.dark(formatMessage({ id: AppMessages['owners.multiAction.archive.successAll'] }));
    },
  });

  const onSubmit = () => {
    onArchiveMultiOwners({ body: checkedOwners.map(({ id }) => id) });
  };

  return (
    <ArchiveMultiOwners
      checkedOwners={checkedOwners}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      onModalOpen={onModalOpen}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};
