import { useFormContext } from 'react-hook-form';
import { Modal, Button } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { AddUserPersonalDataForm } from './addUserPersonalDataForm/AddUserPersonalDataForm';
import { AddUserBasicDataForm } from './addUserBasicDataForm/AddUserBasicDataForm';
import { AddUserFormBody, AddUserProps } from './AddUser.types';
import * as Styled from './AddUser.styles';

export const AddUser = ({ isModalOpen, onModalClose, onModalOpen, onSubmit, isLoadingAddUser }: AddUserProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    formState: { isValid },
  } = useFormContext<AddUserFormBody>();

  return (
    <>
      <Button variant="contained" color="primary" onClick={onModalOpen}>
        {formatMessage({ id: AppMessages['users.addUser'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['users.addUser'] })}
        onClose={onModalClose}
        open={isModalOpen}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={isLoadingAddUser || !isValid}
      >
        <Styled.Grid>
          <Styled.SubHeader>{formatMessage({ id: AppMessages['users.addUser.basicData.title'] })}</Styled.SubHeader>

          <AddUserBasicDataForm />

          <Styled.SubHeader>{formatMessage({ id: AppMessages['users.addUser.personalData.title'] })}</Styled.SubHeader>

          <AddUserPersonalDataForm />
        </Styled.Grid>
      </Modal>
    </>
  );
};
