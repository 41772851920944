import { calculateTextWidth } from './calculateTextWidth';

export const splitTextByWidth = (str: string, maxWidth: number): string[] => {
  return str
    .split(/(\s+)/)
    .reduce((result: string[], textPart: string) => {
      const currentLine = result.pop() || '';
      const testLine = currentLine + textPart;
      const testWidth = calculateTextWidth(testLine);

      if (testWidth <= maxWidth) {
        result.push(testLine);
      } else {
        if (currentLine.trim()) {
          result.push(currentLine);
        }
        result.push(textPart.trimStart());
      }

      return result;
    }, [])
    .map((line) => line.trimEnd());
};
