import { useEffect, useState } from 'react';
import { useQueryClient, APIError, ClientError, useLoaderToast } from 'shared-ui';
import { FileWithPath } from 'react-dropzone';

import { addCardsFromFile, validateCardsFromFile } from 'api/cards/cards';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { AddCardsErrorResponse } from 'api/cards/cards.types';

import { AddCard } from './AddCard';
import { AddCardContainerProps } from './AddCard.types';

export const AddCardContainer = ({
  renderActionComponent,
  isModalOpen,
  setIsModalOpen,
  isSingleCardAdd,
  onAddSingleCardSuccess,
}: AddCardContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const {
    load: loadAddCardFromFile,
    update: updateAddCardFromFile,
    close: closeAddCardFromFile,
  } = useLoaderToast('disconnecting');

  const [downloadLink, setDownloadLink] = useState('');
  const [uploadErrors, setUploadErrors] = useState<string>();
  const [limitUploadError, setLimitUploadError] = useState<string>();
  const [file, setFile] = useState<FileWithPath>();

  const resetUploadErrors = () => {
    setUploadErrors(undefined);
    setLimitUploadError(undefined);
  };

  const handleFileImportErrors = ({ response }: ClientError<APIError<AddCardsErrorResponse>>) => {
    setFile(undefined);

    if (response?.data.type === 'kads_limit') {
      setLimitUploadError(response?.data?.error);
      return;
    }

    setUploadErrors(response?.data?.message);
  };

  const {
    mutate: onValidateCardsFromFile,
    isError: isFileValidationError,
    isLoading: isValidatingFile,
  } = useMutation(validateCardsFromFile, {
    onMutate: resetUploadErrors,
    onError: handleFileImportErrors,
  });

  const { mutate: onAddCardFromFile } = useMutation(addCardsFromFile, {
    onMutate: () => loadAddCardFromFile(formatMessage({ id: AppMessages['cards.addCard.many.loader'] })),
    onSuccess: (response) => {
      updateAddCardFromFile(
        formatMessage(
          { id: AppMessages['cards.addCard.many.successMessage'] },
          {
            addedNumber: response.liczbaDodanych,
            fromNumber: response.liczbaTworzonych,
          },
        ),
      );
      queryClient.invalidateQueries([QueryKeyEnum.CARDS]);
      if (response.bledyImportu) {
        setUploadErrors(response?.bledyImportu);
      } else {
        setIsModalOpen(false);
      }
    },
    onError: handleFileImportErrors,
    onSettled: () => closeAddCardFromFile(),
  });

  const onSubmitAddCardFromFile = () => {
    file && onAddCardFromFile({ body: file });
  };

  useEffect(() => {
    file && onValidateCardsFromFile({ body: file });
  }, [file]);

  useEffect(() => {
    setFile(undefined);
    resetUploadErrors();
  }, [isModalOpen]);

  const createDownloadLink = () => {
    if (!uploadErrors) return;

    const data = new Blob([uploadErrors], { type: 'text/plain' });

    if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink);

    setDownloadLink(window.URL.createObjectURL(data));
  };

  useEffect(() => {
    createDownloadLink();
  }, [uploadErrors]);

  return (
    <AddCard
      file={file}
      setFile={setFile}
      onSubmitAddCardFromFile={onSubmitAddCardFromFile}
      uploadErrors={uploadErrors}
      downloadLink={downloadLink}
      setDownloadLink={setDownloadLink}
      isModalOpen={isModalOpen}
      onModalClose={() => setIsModalOpen(false)}
      onModalOpen={() => setIsModalOpen(true)}
      renderActionComponent={renderActionComponent}
      isSingleCardAdd={isSingleCardAdd}
      onAddSingleCardSuccess={onAddSingleCardSuccess}
      limitUploadError={limitUploadError}
      isFileValidationError={isFileValidationError}
      isValidatingFile={isValidatingFile}
    />
  );
};
