import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLoaderToast, useErrorHandler, ClientError, useQueryClient } from 'shared-ui';

import { editGroupGlcPermission } from 'api/permissions/permissions';
import { AppMessages } from 'i18n/messages';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useSelectedGroupGlcPermission } from 'hooks/useSelectedGroupGlcPermission/useSelectedGroupGlcPermission';
import { useMutation } from 'hooks/useMutation/useMutation';

import { GroupGlcPermissionDetails } from './GroupGlcPermissionDetails';
import {
  GroupGlcPermissionDetailsContainerProps,
  GroupGlcPermissionDetailsEditForm,
} from './GroupGlcPermissionDetails.types';
import { useGroupGlcPermissionDetailsValidation } from './GroupGlcPermissionDetails.validation';

export const GroupGlcPermissionDetailsContainer = ({
  selectedPermission,
  groupId,
}: GroupGlcPermissionDetailsContainerProps) => {
  const { formatMessage } = useLocale();
  const { setSelectedGroupGlcPermission } = useSelectedGroupGlcPermission();
  const queryClient = useQueryClient();
  const { load: loadEditPermission, update: updateEditPermission } = useLoaderToast(
    `permission-${selectedPermission.id}`,
  );
  const { schema } = useGroupGlcPermissionDetailsValidation();
  const { handleError } = useErrorHandler();

  const { mutate: onEditPermission, isLoading } = useMutation(editGroupGlcPermission, {
    onMutate: () => loadEditPermission(formatMessage({ id: AppMessages['group.reader.permission.details.loader'] })),
    onSuccess: (_, { body }) => {
      setSelectedGroupGlcPermission({ ...selectedPermission, ...body });
      queryClient.invalidateQueries([QueryKeyEnum.GROUP_GLC_PERMISSIONS_SERVERS]);
      queryClient.invalidateQueries([QueryKeyEnum.GROUP_GLC_PERMISSIONS]);
      updateEditPermission(formatMessage({ id: AppMessages['group.reader.permission.details.edit.success'] }));
    },
    onError: (error: ClientError<Error>) => {
      handleError({ error, updateToastCallback: updateEditPermission });
    },
  });

  const formMethods = useForm<GroupGlcPermissionDetailsEditForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      otwierajZamek: selectedPermission.otwierajZamek,
      rejestrujOdbicie: selectedPermission.rejestrujOdbicie,
      rejestrujRcp: selectedPermission.rejestrujRcp,
    },
  });

  useEffect(() => {
    formMethods.reset(selectedPermission);
  }, [selectedPermission]);

  const onSubmit = (body: GroupGlcPermissionDetailsEditForm) => {
    onEditPermission({
      groupId: groupId,
      permissionId: selectedPermission.id,
      body: { idGlc: selectedPermission.glc.id, ...body },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <GroupGlcPermissionDetails isLoading={isLoading} selectedPermission={selectedPermission} onSubmit={onSubmit} />
    </FormProvider>
  );
};
