import { RadioGroup as MaterialRadioGroup, FormControlLabel } from '@mui/material';
import styled, { css } from 'styled-components';

import { FormStylesProps } from './AddCardForm.types';

export const FormGrid = styled.div<FormStylesProps>(
  ({ isFileLoaded }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: reapeat(5, 1fr);
    opacity: ${isFileLoaded ? 0.4 : 1};
    position: relative;
  `,
);

export const ButtonsWrapper = styled.div(
  ({ theme: { palette } }) => css`
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: ${palette.common.white};
    padding-top: 40px;
    z-index: 1000;
  `,
);

export const RadioGroup = styled(MaterialRadioGroup)`
  gap: 50px;
  margin-top: 25px;
  margin-bottom: 30px;
`;

export const ControlLabel = styled(FormControlLabel)(
  ({ theme: { palette } }) => css`
    & .css-1ao9ffk-MuiButtonBase-root-MuiRadio-root.Mui-disabled {
      color: ${palette.grey[500]};
    }
  `,
);

export const CardNumberInputWrapper = styled.div<{ $isFullTextWidth?: boolean }>(
  ({ $isFullTextWidth }) => css`
    grid-column: 1/2;
    grid-row: 1/2;
    margin-bottom: 10px;
    margin-right: 12px;

    ${$isFullTextWidth &&
    css`
      grid-column: 1/3;

      & > div {
        & > div {
          width: 50%;
        }
      }
    `}
  `,
);

export const CardNumberToInputWrapper = styled.div`
  grid-column: 2/3;
  grid-row: 1/2;
  margin-bottom: 10px;
  margin-right: 12px;
`;

export const CardNameInputWrapper = styled.div`
  grid-column: 1/3;
  grid-row: 2/2;
  margin-bottom: 10px;
`;

export const CardTypeInputWrapper = styled.div`
  grid-column: 1/2;
  grid-row: 3/4;
  margin-right: 12px;
`;

export const CardBlockedInputWrapper = styled.div`
  grid-column: 2/3;
  grid-row: 3/4;
  margin-left: 12px;
  margin-bottom: 32px;
`;

export const CardDescriptionInputWrapper = styled.div`
  grid-column: 1/3;
  grid-row: 4/5;
  margin-bottom: 5px;
`;
