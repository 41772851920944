import { useEffect } from 'react';
import { useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { ClientError, APIError } from 'shared-ui';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useErrorHandler } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AddPositionBody, Position } from 'api/positions/positions.types';
import { addPosition } from 'api/positions/positions';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';

import { AddPosition } from './AddPosition';
import { useAddPositionValidation } from './AddPosition.validation';

export const AddPositionContainer = () => {
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const positions = queryClient.getQueryData<Position[]>([QueryKeyEnum.POSITIONS, '']) || [];
  const { schema } = useAddPositionValidation(positions);
  const { handleError } = useErrorHandler();

  const methods = useForm<AddPositionBody>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      nazwa: '',
    },
  });

  const { mutate: onAddPosition, isLoading } = useMutation(addPosition, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.POSITIONS]);
      toast.dark(formatMessage({ id: AppMessages['definitions.position.addPosition.success'] }));
      onModalClose();
    },
    onError: (error: ClientError<APIError>) => {
      const errorFields = error.response?.data.fields;

      const isPositionExistsError = errorFields?.find(({ type, field }) => type === 'exists' && field === 'nazwa');

      if (isPositionExistsError) {
        return methods.setError('nazwa', {
          message: formatMessage({ id: AppMessages['definitions.position.details.name.validation'] }),
        });
      }

      handleError({ error, omitAPIErrorMessage: true });
    },
  });

  const onSubmit = (body: AddPositionBody) => {
    onAddPosition(body);
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...methods}>
      <AddPosition
        onSubmit={onSubmit}
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        isLoading={isLoading}
      />
    </FormProvider>
  );
};
